import {
  MenuItem,
  MenuItemProps,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';

type ContextActionItemProps<
  RootComponent extends React.ElementType = 'li',
  AdditionalProps = unknown,
> = MenuItemProps<RootComponent, AdditionalProps> & {
  icon: React.ReactElement;
};

export const ContextActionItem = <
  RootComponent extends React.ElementType = 'li',
  AdditionalProps = unknown,
>({
  icon,
  children,
  ...rest
}: ContextActionItemProps<RootComponent, AdditionalProps>) => {
  return (
    <MenuItem {...rest} sx={{ '&:is(a):hover': { color: 'inherit' } }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <SvgIcon fontSize="small">{icon}</SvgIcon>
        <Typography>{children}</Typography>
      </Stack>
    </MenuItem>
  );
};
