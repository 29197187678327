import { endpoints } from '@rossum/api-client';
import { CreateHookFromTemplatePayload } from '@rossum/api-client/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { createExtensionFulfilled } from '../../redux/modules/extensions/actions';
import { throwError } from '../../redux/modules/messages/actions';
import { QUERY_KEY_HOOKS } from './useHooks';

type HookMutationVariables = {
  payload: CreateHookFromTemplatePayload;
};

export const useCreateHookFromTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload }: HookMutationVariables) =>
      api.request(endpoints.hooks.createFromTemplate(payload)),

    onSuccess: hook => {
      queryClient.invalidateQueries([QUERY_KEY_HOOKS]);
      dispatch(createExtensionFulfilled(hook));
    },

    onError: () => {
      dispatch(throwError('extensionUpdateFailed'));
    },
  });
};
