import { Serie } from '@rossum/api-client/annotations';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  colorPrimary,
  colorSuccess,
  gray,
  grayLight,
} from '../../../../components/Graphs/constants';
import StackedBarGraph, {
  StackedBarGraphProps,
} from '../../../../components/Graphs/StackedBar';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { parse } from '../../../../lib/url';
import { State } from '../../../../types/state';
import { GranularityT } from '../Granularity';

type OwnProps = {
  intl: IntlShape;
  isTmpState: boolean;
  children?: ReactNode;
  series: Array<Serie>;
};

const mapStateToProps = (
  {
    router: {
      location: { search },
    },
  }: State,
  { intl, isTmpState, series }: OwnProps
): StackedBarGraphProps<
  'rejectedAutomaticallyCount' | 'rejectedManuallyCount'
> => ({
  name: 'rejection' as const,
  bars: [
    {
      name: 'rejectedManuallyCount' as const,
      color: isTmpState ? gray : colorPrimary,
    },
    {
      name: 'rejectedAutomaticallyCount' as const,
      color: isTmpState ? grayLight : colorSuccess,
    },
  ],
  data: series.map(
    ({
      values: { rejectedAutomaticallyCount, rejectedManuallyCount },
      beginDate,
      endDate,
    }) => ({
      rejectedManuallyCount: rejectedManuallyCount ?? undefined,
      rejectedAutomaticallyCount: rejectedAutomaticallyCount ?? undefined,
      date: dateFormatByGranularity(
        intl.locale,
        get(parse(search), 'groupBy') as GranularityT,
        beginDate,
        endDate
      ),
    })
  ),
  formatLabel: !isTmpState
    ? s => intl.formatMessage({ id: `components.graphs.rejection.series.${s}` })
    : undefined,
});

export default injectIntl(
  connect(mapStateToProps)(
    StackedBarGraph<'rejectedAutomaticallyCount' | 'rejectedManuallyCount'>
  )
);
