import { ArrowDropDown } from '@rossum/ui/icons';
import {
  IconButton,
  Menu,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { Avatar } from '@rossum/ui/material';
import { first } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Email } from '../api-client';
import { truncateEmail } from '../helpers';

type AuthorSummaryProps = {
  email: Email;
};

const MENU_WIDTH = 400;
const typeOfDetailClassName = 'typeOfDetail';
const contentOfDetailClassName = 'contentOfDetail';

const EmailSummaryItem = ({
  type,
  content,
}: {
  type: string;
  content: string;
}) => (
  <Stack direction="row" spacing={1}>
    <Typography className={typeOfDetailClassName} variant="body2">
      {type}
    </Typography>
    <Typography className={contentOfDetailClassName} variant="body2">
      {content}
    </Typography>
  </Stack>
);

const AuthorSummary = ({ email }: AuthorSummaryProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    from: { email: fromEmail, name: fromName },
    to,
    cc,
    bcc,
  } = email;

  const [firstName, lastName] = fromName?.split(' ') || [];
  const userInitials = `${first(firstName || fromEmail)}${
    first(lastName) || ''
  }`.toUpperCase();

  const { truncatedEmail, showTooltip } = truncateEmail(fromEmail);
  const emailRecipientGroups = [
    { list: to, translationKey: 'to' },
    { list: cc, translationKey: 'cc' },
    { list: bcc, translationKey: 'bcc' },
  ] as const;

  return (
    <Stack direction="row" alignItems="center">
      <Avatar
        sx={{
          padding: 1,
          ...{
            width: 32,
            height: 32,
            backgroundColor: (theme: Theme) => theme.palette.text.primary,
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme =>
              theme.palette.getContrastText(theme.palette.text.primary),
          }}
        >
          {userInitials}
        </Typography>
      </Avatar>
      <Stack sx={{ marginLeft: 1, minWidth: 0 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip title={showTooltip ? fromEmail : ''}>
            <Stack>
              <Typography variant="subtitle2" color="text.secondary" noWrap>
                {`${fromName || truncatedEmail}`}
              </Typography>
              {fromName && (
                <Typography variant="body2" color="text.disabled" noWrap>
                  {`<${truncatedEmail}>`}
                </Typography>
              )}
            </Stack>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: 'components.authorSummary.emailRecipients.toggleTooltip',
            })}
          >
            <IconButton
              size="small"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <ArrowDropDown />
            </IconButton>
          </Tooltip>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{
            paper: {
              sx: {
                width: MENU_WIDTH,
              },
            },
          }}
        >
          <Stack
            spacing={0.5}
            p={1}
            sx={{
              color: 'text.secondary',
              [`& .${typeOfDetailClassName}`]: {
                flex: 0.1,
                textAlign: 'right',
              },
              [`& .${contentOfDetailClassName}`]: {
                flex: 0.9,
                textAlign: 'left',
                textWrap: 'wrap',
              },
            }}
          >
            <EmailSummaryItem
              type={`${intl.formatMessage({
                id: `components.authorSummary.from`,
              })}: `}
              content={fromEmail}
            />
            {emailRecipientGroups.map(({ list, translationKey }) =>
              list.length ? (
                <EmailSummaryItem
                  key={translationKey}
                  type={`${intl.formatMessage({
                    id: `components.authorSummary.${translationKey}`,
                  })}: `}
                  content={list
                    .map(({ email: mail, name }) => {
                      return mail && name
                        ? `${name} <${mail}>`
                        : mail ?? name ?? 'N/A';
                    })
                    .join(', ')}
                />
              ) : null
            )}
          </Stack>
        </Menu>
      </Stack>
    </Stack>
  );
};

export { AuthorSummary };
