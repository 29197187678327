import { createReactComponent } from '@tabler/icons-react';

export const IconCustomChevronUpbar = createReactComponent(
  'outline',
  'customChevronUpbar',
  'CustomChevronUpbar',
  [
    [
      'path',
      {
        key: 'customChevronUpbar-path',
        d: 'M18.25 6.25L6.25 6.25M18.25 17.5L12.5 12.5L6.25 17.5',
      },
    ],
  ]
);
