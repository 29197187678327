// Generated by ts-to-zod
import { z } from 'zod';
import { logSchema } from '../models/log.schema';

export const logsQuerySchema = z.object({
  timestampAfter: z.string().optional(),
  timestampBefore: z.string().optional(),
  search: z.string().optional(),
  hook: z.array(z.string()).optional(),
  requestId: z.string().optional(),
  logLevel: z.string().optional(),
  statusCode: z.array(z.string()).optional(),
  queue: z.array(z.string()).optional(),
  annotation: z.array(z.string()).optional(),
  email: z.array(z.string()).optional(),
  pageSize: z.string().optional(),
});

export const logsSchema = z.object({
  results: z.array(logSchema),
});
