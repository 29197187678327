import { Hook } from '@rossum/api-client/hooks';
import { DetailDrawer } from '@rossum/ui';
import { Stack, Tooltip, Typography } from '@rossum/ui/material';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ExtendedHook } from '../helpers';
import { GraphDataGroupedPerEvents } from '../hooks';
import UpdateDependenciesForm from './UpdateDependenciesForm';

export const DRAWER_WIDTH = 448;

type UpdateDependenciesDrawerProps = {
  graphDataGroupedPerEvents: GraphDataGroupedPerEvents;
  onClose: () => void;
  selectedQueueId?: number;
  drawerState: null | {
    selectedEvents: ExtendedHook['events'] | null;
    selectedElement: React.RefObject<HTMLDivElement> | undefined;
    selectedExtension: ExtendedHook | null;
  };
  originalExtensions: Hook[] | undefined;
};

const UpdateDependenciesDrawer = ({
  drawerState,
  onClose,
  selectedQueueId,
  graphDataGroupedPerEvents,
  originalExtensions,
}: UpdateDependenciesDrawerProps) => {
  const intl = useIntl();

  const scrollToElement = useCallback(
    (el: React.RefObject<HTMLDivElement> | undefined) => {
      if (el?.current) {
        el.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    },
    []
  );

  const handleCloseEscPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleCloseEscPress);

    return () => {
      document.removeEventListener('keydown', handleCloseEscPress);
    };
  }, [handleCloseEscPress]);

  const selectedExtension = drawerState?.selectedExtension ?? null;
  const selectedEvents = drawerState?.selectedEvents ?? null;
  const selectedElement = drawerState?.selectedElement ?? undefined;

  const filtredByEvents = graphDataGroupedPerEvents.filter(({ eventNames }) =>
    eventNames.find(event => selectedEvents?.includes(event))
  );

  const graphData = filtredByEvents.flatMap(e => e.graphData);

  const extensionsGraphNodesMap = filtredByEvents.map(
    e => e.extensionGraphNodesMap
  );

  const extensionName = `${selectedExtension?.name}`;

  return (
    <DetailDrawer
      variant="persistent"
      PaperProps={{
        elevation: 2,
        sx: {
          width: DRAWER_WIDTH,
          '.DetailDrawer-bodyBox': {
            height: '100%',
          },
        },
      }}
      title={
        <Stack sx={{ maxWidth: 350 }}>
          <Tooltip title={extensionName} enterNextDelay={500}>
            <Typography noWrap sx={{ textOverflow: 'ellipsis' }} variant="h5">
              {extensionName}
            </Typography>
          </Tooltip>
          <Typography variant="h6" color="text.disabled">
            {intl.formatMessage({
              id: 'containers.queueExtensions.dialog.updateDependencies.title',
            })}
          </Typography>
        </Stack>
      }
      open={!!selectedExtension}
      onClose={() => onClose()}
      onTransitionEnd={() =>
        !!selectedExtension && scrollToElement(selectedElement)
      }
    >
      <UpdateDependenciesForm
        originalExtensions={originalExtensions}
        key={`${selectedExtension}`}
        extensionGraphNodesMap={extensionsGraphNodesMap[0]}
        extensions={graphData.flat()}
        selectedExtension={selectedExtension}
        onClose={onClose}
        selectedQueueId={selectedQueueId}
      />
    </DetailDrawer>
  );
};

export default UpdateDependenciesDrawer;
