import { Box, BoxProps, styled } from '@rossum/ui/material';
import { clamp } from 'remeda';
import { usePageSpaceContext } from '../DocumentPage/PageSpaceContext';
import { Grab } from '../icons/Grab';
import {
  DRAG_HANDLE_SIZE,
  GRID_LINE_WIDTH,
  LABEL_HORIZONTAL_PADDING,
} from './constants';
import { useGridContext } from './GridContext';
import { useDraggableInteraction } from './useDraggableInteraction';
import { moveGridByGridHandle } from './utils';

export type GridDragHandleProps = Omit<
  BoxProps,
  'onDrag' | 'onDragStart' | 'onDragEnd'
> & {
  onMoved: () => void;
};

const StyledDragHandle = styled(Box)(({ theme }) => ({
  width: DRAG_HANDLE_SIZE,
  height: DRAG_HANDLE_SIZE,
  position: 'absolute',
  left: -DRAG_HANDLE_SIZE - GRID_LINE_WIDTH / 2 - LABEL_HORIZONTAL_PADDING,
  top: -DRAG_HANDLE_SIZE - GRID_LINE_WIDTH / 2 - LABEL_HORIZONTAL_PADDING,
  borderRadius: 3,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const GridDragHandle = ({
  onMoved,
  ...restBoxProps
}: GridDragHandleProps) => {
  const { pageWidth, pageHeight } = usePageSpaceContext();

  const {
    gridState,
    gridDraftState: [_, setDraftState],
    interactionInProgress,
  } = useGridContext();

  const { isDragging, handleMouseDown } = useDraggableInteraction({
    onDragStart: () => setDraftState(gridState),
    onDrag: diff =>
      setDraftState(
        moveGridByGridHandle(gridState, [
          clamp(Math.round(diff[0]), {
            min: -gridState.columns[0].leftPosition,
            max:
              pageWidth - gridState.width - gridState.columns[0].leftPosition,
          }),
          clamp(Math.round(diff[1]), {
            min: -gridState.rows[0].topPosition,
            max: pageHeight - gridState.height - gridState.rows[0].topPosition,
          }),
        ])
      ),
    onDragEnd: () => {
      onMoved();
      setDraftState(null);
    },
  });

  return (
    <StyledDragHandle
      {...restBoxProps}
      onMouseDownCapture={handleMouseDown}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        pointerEvents: interactionInProgress && !isDragging ? 'none' : 'all',
      }}
    >
      <Grab />
    </StyledDragHandle>
  );
};
