import { Stack, Typography } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { DatapointColumn, RowModel } from '../rows/helpers';
import { CellIcons } from './CellIcons';
import { CellValueSkeleton } from './CellValueSkeleton';
import {
  resolveAutomationBlockersPerDatapoints,
  resolveMessagesPerDatapoints,
} from './utils';

export const formulaPreviewClassName = 'current-formula-preview-cell';

const DatapointCell = ({
  value,
}: {
  value: NonNullable<RowModel[DatapointColumn]>;
}) => {
  const intl = useIntl();
  const [first, ...rest] = value.datapoints;
  const restLength = rest.length;

  const firstValue =
    first && 'content' in first && first.content
      ? first.content.value
      : undefined;

  const resolvedMessages = resolveMessagesPerDatapoints(value);

  const resolvedAutomationBlockers =
    resolveAutomationBlockersPerDatapoints(value);

  return (
    <Stack
      component="span"
      direction="row"
      sx={{
        [`.${formulaPreviewClassName} &`]: {
          color: 'success.main',
        },
      }}
    >
      <Stack component="span" direction="row" spacing={0.5}>
        <CellIcons
          type={null}
          messages={resolvedMessages}
          automationBlockers={resolvedAutomationBlockers}
        />
        {restLength ? (
          <Typography variant="body2" component="span" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'features.queueSettings.fields.formulaPreview.table.moreLines',
              },
              { count: restLength }
            )}
          </Typography>
        ) : (
          <Typography variant="body2" component="span">
            {firstValue}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const datapointCellRenderer = (
  p: GridRenderCellParams<RowModel, RowModel[DatapointColumn]>
) => {
  return p.value === undefined || p.row.isFetching ? (
    <CellValueSkeleton />
  ) : (
    <DatapointCell value={p.value} />
  );
};

export { datapointCellRenderer };
