import { escapeRegExp } from 'lodash';
import { User } from '../../../types/user';
import { getName } from '../user/helpers';

export const filterUsers = (search: string, users: User[]) =>
  users.filter(
    ({ firstName, lastName, username, email }: User) =>
      `${firstName} ${lastName} ${username} ${email}`
        .toUpperCase()
        .search(escapeRegExp(search.toUpperCase())) > -1
  );

export const usersToOptions = (users: User[]) =>
  users.map(user => ({
    label: getName(user) ?? '',
    value: user.id,
  }));
