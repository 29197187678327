import { yupResolver } from '@hookform/resolvers/yup';
import { Url } from '@rossum/api-client';
import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import {
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormHelperText,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useMountTransition } from '../../../../../../../../components/UI/MountTransition/useMountTransition';
import { DEDICATED_ENGINES_TRAINING_LINK } from '../../../../../../../../constants/values';
import { link } from '../../../../../../../../lib/formaterValues';
import { EngineStatusChip } from '../../../../../../components/EngineStatusChip';
import { QUERY_KEY_DEDICATED_ENGINE_SCHEMA } from '../../../../../../hooks/useDedicatedEngineSchema';
import { usePatchDedicatedEngineSchema } from '../../../../../../hooks/usePatchDedicatedEngineSchema';
import { EngineSchemaLockedMessage } from '../EngineSchemaLockedMessage';
import { QueueSelection } from './QueueSelection';
// case for unifying API and form schemas - yup or zod could both be good candidates?
// also the issue with NestedValue for fields with array-like or object-like values persists
// looking for a forms master
const queueSelectionSchema = (intl: IntlShape) =>
  yup.object({
    trainingQueues: yup
      .array()
      .of(yup.mixed())
      .min(
        1,
        intl.formatMessage({ id: 'components.queueSelectionStep.formError' })
      ),
  });

type QueueSelectionStepProps = {
  engine: DedicatedEngine;
  engineSchema: DedicatedEngineSchema;
  onNext: () => void;
  onError: (e?: unknown) => void;
};

const QueueSelectionStep = ({
  engine,
  engineSchema,
  onNext,
  onError,
}: QueueSelectionStepProps) => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    trainingQueues: Url[];
  }>({
    mode: 'onChange',
    // TODO: Define how to use cache values as defaultValues
    defaultValues: { trainingQueues: engineSchema.content.trainingQueues },
    resolver: yupResolver(queueSelectionSchema(intl)),
  });

  const isLocked = engine.status !== 'draft';

  const { mutate, status } = usePatchDedicatedEngineSchema();

  const queryClient = useQueryClient();

  // just toggle this off when you want to complete this step. Actual step completion is handled in onExited
  // on this transition
  const [transitionProps, transitionOut] = useMountTransition();

  const handleNextClick = useCallback(() => {
    // if editing is locked, don't save anything and just switch to next step
    if (isLocked) {
      transitionOut(onNext);
      return;
    }
    // call handle submit so we get validation from RHF
    handleSubmit(({ trainingQueues }) => {
      // Manage state of stepper manually, open to ideas on how to improve this
      mutate(
        {
          engineSchemaId: engineSchema.id,
          engineSchemaPatchModel: {
            content: { ...engineSchema.content, trainingQueues },
          },
        },
        {
          onSuccess: newSchema => {
            // this is why queryKey factories could be useful
            queryClient.setQueryData(
              [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, engineSchema.id, {}],
              newSchema
            );
            // transition out, then fire onNext, unmounting is then handled from parent
            transitionOut(onNext);
          },
          onError: () => {
            onError();
          },
        }
      );
    })();
  }, [
    engineSchema.content,
    engineSchema.id,
    handleSubmit,
    isLocked,
    mutate,
    onError,
    onNext,
    queryClient,
    transitionOut,
  ]);

  return (
    <Stack spacing={3} divider={<Divider />}>
      <Fade {...transitionProps}>
        <Stack spacing={3}>
          <Stack spacing={2} alignItems="center" sx={{ pl: 8, pr: 8 }}>
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'components.queueSelectionStep.title',
              })}
            </Typography>
            <EngineStatusChip status={engine.status} />
            <Typography variant="body2" color="text.secondary" align="center">
              {intl.formatMessage({
                id: 'components.queueSelectionStep.description',
              })}
            </Typography>
            {isLocked && <EngineSchemaLockedMessage />}
          </Stack>
          <Controller
            control={control}
            name="trainingQueues"
            render={({ field, fieldState }) => (
              <Stack spacing={2} alignItems="center">
                {status === 'error' && (
                  <FormHelperText error>
                    {intl.formatMessage({
                      id: 'components.queueSelectionStep.fetchingError',
                    })}
                  </FormHelperText>
                )}
                {fieldState.error && (
                  <FormHelperText error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
                <QueueSelection
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isLocked || engineSchema.content.fields.length > 0}
                />
              </Stack>
            )}
          />
        </Stack>
      </Fade>
      <Stack
        direction="row"
        spacing={2}
        sx={{ p: 4, pt: 1 }}
        justifyContent="space-between"
      >
        {/* This is here so that space-between works as expected */}
        <Button
          variant="outlined"
          color="secondary"
          sx={{ visibility: 'hidden' }}
        >
          {intl.formatMessage({ id: 'components.stepWorkflowButtons.back' })}
        </Button>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mr: 1, ml: 1, flexShrink: 0 }}
        >
          {intl.formatMessage(
            {
              id: 'containers.settings.engineDetail.helpText',
            },
            {
              link: link(DEDICATED_ENGINES_TRAINING_LINK),
            }
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          endIcon={
            status === 'loading' ? (
              <CircularProgress color="inherit" size={16} />
            ) : null
          }
          disabled={!isValid || status === 'loading'}
        >
          {intl.formatMessage({ id: 'components.stepWorkflowButtons.next' })}
        </Button>
      </Stack>
    </Stack>
  );
};

export { QueueSelectionStep };
