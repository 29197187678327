import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { isEmbedded } from '../../../../constants/config';
import { GlobalErrorFallbackScreen } from '../GlobalErrorFallbackScreen';

type GlobalErrorBoundaryProps = {
  children?: ReactNode;
};

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  const handleReset = () => {
    if (isEmbedded()) {
      window.location.reload();
    } else {
      window.location.replace(`/`);
    }
  };

  const handleError = (error: unknown) => {
    if (window.playwrightError && error instanceof Error) {
      window.playwrightError(error);
    }
  };

  return (
    <Sentry.ErrorBoundary
      onReset={handleReset}
      onError={handleError}
      fallback={({ resetError }) => (
        <GlobalErrorFallbackScreen
          // TODO: What about this
          lastError={null}
          resetErrorBoundary={() => resetError()}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
