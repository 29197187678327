import { Rectangle2DCoordinates } from '../document-canvas/utils/geometry';

/**
 * This fn checks for the cases where the rectangles are definitely not intersecting.
 * @params `rectA`, `rectB` represented as `[x1, y1, x2, y2]` are coordinates of boxes
 * @returns boolean
 */
export const areBoxesIntersecting = (
  rectA: Rectangle2DCoordinates,
  rectB: Rectangle2DCoordinates
) => {
  const isLeftOfA = rectA[0] > rectB[2];
  const isRightOfA = rectA[2] < rectB[0];
  const isBelowA = rectA[3] < rectB[1];
  const isAboveA = rectA[1] > rectB[3];

  return !(isLeftOfA || isRightOfA || isBelowA || isAboveA);
};
