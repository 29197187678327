// Fix bug with pluralization on Safari 12 and iOS12
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { IntlLocale } from '../../i18n';
import { getTranslationsByLocale } from '../../i18n/translations';
import { State } from '../../types/state';
import { handleIntlProviderError } from '../App/handleIntlProviderError';

export const messagesCache: Record<string, Record<string, string>> = {};

const ConnectedIntlProvider = ({ children }: { children: ReactNode }) => {
  const locale = useSelector((state: State) => state.locale.locale);
  const messages = getMessages(locale);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages}
      onError={handleIntlProviderError}
    >
      {children}
    </IntlProvider>
  );
};

const getMessages = (locale: IntlLocale) => {
  if (messagesCache[locale]) {
    return messagesCache[locale];
  }

  throw loadMessages(locale);
};

async function loadMessages(locale: IntlLocale) {
  const messages = await getTranslationsByLocale(locale);
  messagesCache[locale] = messages;
  return messages;
}

export default ConnectedIntlProvider;
