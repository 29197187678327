import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';

export const RULE_TEMPLATES_QUERY_KEY = 'rule_templates';

export const useListRuleTemplates = () => {
  const api = useApiClient();

  return useQuery({
    queryKey: [RULE_TEMPLATES_QUERY_KEY, 'list'],
    queryFn: () => api.request(endpoints.ruleTemplates.list({})),
  });
};
