import {
  Chip,
  TextField,
  TextFieldProps,
  Typography,
} from '@rossum/ui/material';
import { validate as validateEmail } from 'email-validator';
import { ReactNode, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import FormTypeahead from '../FormTypeahead';
import { FormControlProps } from '../utils';

type EmailAddressValidationTypeaheadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormControlProps<TFieldValues, TName> & {
  label: string;
  endAdornment?: ReactNode;
  options?: string[];
  validate?: (email: string) => boolean;
  inputProps?: TextFieldProps['inputProps'];
};

const EmailAddressValidationTypeahead = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  endAdornment,
  options,
  validate = validateEmail,
  inputProps,
}: EmailAddressValidationTypeaheadProps<TFieldValues, TName>) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <FormTypeahead
      disablePortal
      name={name}
      control={control}
      options={options ?? []}
      freeSolo
      multiple
      open={!!inputValue.length}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography fontSize="small">{option}</Typography>
        </li>
      )}
      renderTags={(_value, getTagProps) =>
        _value.map((chip, i) => (
          <Chip
            {...getTagProps({ index: i })}
            color={validate(chip) ? 'default' : 'error'}
            label={chip}
            key={`chip-${chip}`}
          />
        ))
      }
      inputValue={inputValue}
      onInputChange={(_, newValue) => setInputValue(newValue)}
      renderInput={controllerProps => params => (
        <TextField
          {...params}
          autoFocus
          label={label}
          size="small"
          error={Boolean(controllerProps.fieldState.error)}
          helperText={
            controllerProps.fieldState.error
              ? controllerProps.fieldState.error.message
              : ''
          }
          inputProps={{ ...inputProps, ...params.inputProps }}
          InputProps={{
            ...params.InputProps,
            style: endAdornment ? { paddingRight: 120 } : {},
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default EmailAddressValidationTypeahead;
