import { Tooltip } from '@rossum/ui/material';
import { GridRenderCellParams, GridRowModel } from '@rossum/ui/x-data-grid-pro';
import { useFormattedDate } from '../../../utils/hooks/useFormattedDate';

const RenderDate = (props: GridRenderCellParams<GridRowModel, Date>) => {
  const { value } = props;

  const { formattedDate, formattedDateTime } = useFormattedDate(value);

  return (
    <Tooltip title={formattedDateTime} placement="bottom-start">
      <span>{formattedDate}</span>
    </Tooltip>
  );
};

export { RenderDate };
