import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { findDatapointById } from '../../../../redux/modules/datapoints/navigation/findDatapointIndex';
import { schemaMapSelector } from '../../../../redux/modules/schema/schemaMapSelector';
import { MatchedTriggerRulesPerLevel } from '../../../../types/datapoints';
import { State } from '../../../../types/state';

// re-using the same logic as in the original code
export const useMatchedRules = (
  matchedTriggerRules: MatchedTriggerRulesPerLevel
) => {
  const schemaMap = useSelector(schemaMapSelector);

  const allDatapoints = useSelector((state: State) => state.datapoints.content);

  const matchedRules = useMemo(
    () => [
      ...matchedTriggerRules.annotationLevel.map(rule => ({
        type: rule.type,
        ruleValue: rule.type === 'page_count' ? rule.threshold : rule.regex,
      })),
      ...Object.values(matchedTriggerRules.datapointLevel).flatMap(
        ([datapointRule]) => {
          if (!datapointRule) {
            return [];
          }

          const datapoint = findDatapointById(allDatapoints, datapointRule.id);

          if (!datapoint) {
            return [];
          }

          const schema = schemaMap.get(datapoint.schemaId);

          return {
            type: 'datapoint' as const,
            id: datapoint.id,
            label: schema?.label ?? datapoint.schemaId,
            ruleValue: schema?.options
              ? schema.options.find(option => {
                  return option.value === datapointRule.value;
                })?.label
              : datapointRule.value,
          };
        }
      ),
    ],
    [matchedTriggerRules, schemaMap, allDatapoints]
  );

  return matchedRules;
};
