import { Trigger } from '@rossum/api-client/triggers';
import { get } from 'lodash';
import { push, replace } from 'redux-first-history';
import { combineEpics, ofType } from 'redux-observable';
import { isTruthy, unique } from 'remeda';
import { EMPTY, from, merge, of, timer, zip } from 'rxjs';
import {
  catchError,
  debounce,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';
import { endpoints } from '../../../../../../libs/api-client';
import { queueListQuerySchema } from '../../../../../../libs/api-client/src/queues/endpoints/list.schema';
import { QUERY_KEY_HOOKS_UNPAGINATED } from '../../../business/hooks/useUnpaginatedHooks';
import { apiUrl } from '../../../constants/config';
import { collectionSize } from '../../../constants/values';
import { defaultTabStatuses } from '../../../containers/AnnotationList/helpers';
import { getQueueHooksQuery } from '../../../containers/Extensions/DependenciesGraph/helpers';
import { errorHandler, tryRepeatRequestHandler } from '../../../lib/api';
import { api } from '../../../lib/apiClient';
import {
  isNonEmptyString,
  isNotNullOrUndefined,
} from '../../../lib/typeGuards';
import { getCurrentQueueId, parse } from '../../../lib/url';
import { PaginatedList } from '../../../types/pagination';
import { User } from '../../../types/user';
import { invalidateQueryClientCache } from '../../middlewares/queryClientMiddleware';
import { signOut } from '../auth/actions';
import { createInboxFulfilled } from '../inbox/actions';
import { throwError, throwInfo } from '../messages/actions';
import {
  alertNetworkOutage,
  enterQueue,
  enterQueueEditor,
  setApplicationTabVisibility,
} from '../ui/actions';
import { makeEpic } from '../utils';
import {
  allQueuesWereFetched,
  createQueueFulfilled,
  deleteQueueFulfilled,
  fetchQueue,
  fetchQueueFulfilled,
  fetchQueues,
  fetchQueuesFulfilled,
  queueNotFound,
  removeQueueFromState,
  updateQueueDetail,
  updateQueueDetailFailed,
  updateQueueDetailFulfilled,
  updateUsersOnQueue,
} from './actions';

const excludeKeysFromConversion = ['counts'];

const query = {
  pageSize: collectionSize,
};

const UPDATE_DEFAULT_EMAIL_TEMPLATE_FULFILLED =
  'UPDATE_DEFAULT_EMAIL_TEMPLATE_FULFILLED';

export const fetchQueuesEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchQueues)),
    pluck('meta'),
    switchMap(({ url }) => {
      const querySearchString = url ? new URL(url).search : null;
      const querySearchParsed = querySearchString
        ? parse(querySearchString, {
            parseNumbers: true,
            parseBooleans: true,
          })
        : null;

      const queryValidated = queueListQuerySchema.safeParse(querySearchParsed);

      if (querySearchParsed && !queryValidated.success) {
        return of(throwError('queueError'));
      }

      return from(
        api.request(
          endpoints.queues.list(
            querySearchParsed && queryValidated.success
              ? queryValidated.data
              : { pageSize: collectionSize }
          )
        )
      ).pipe(
        takeUntil(action$.pipe(filter(isActionOf([signOut])))),
        map(fetchQueuesFulfilled),
        catchError(
          // this still fetches queues with authGetJSON$ tho :-/
          tryRepeatRequestHandler(
            url || `${apiUrl}/queues`,
            url ? {} : { query, excludeKeysFromConversion },
            fetchQueuesFulfilled,
            [getType(signOut)]
          )
        ),
        catchError(errorHandler)
      );
    })
  )
);

const fetchQueueEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchQueue)),
    pluck('meta', 'id'),
    mergeMap(id =>
      from(
        api.request(
          endpoints.queues.list({
            id: [id],
          })
        )
      ).pipe(
        takeUntil(action$.pipe(ofType(signOut))),
        map(({ results }) => {
          if (results.length === 0) {
            return queueNotFound();
          }

          const [queue] = results;

          return fetchQueueFulfilled(queue);
        }),
        catchError(errorHandler)
      )
    )
  )
);

const redirectOnQueueNotFoundEpic = makeEpic(action$ =>
  action$.pipe(filter(isActionOf(queueNotFound)), mapTo(replace('/')))
);

const removeQueueFromStateEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([removeQueueFromState])),
    map(action => action.payload),
    mergeMap(({ queueId }) => {
      const fulfilled = deleteQueueFulfilled(queueId);
      const message = throwInfo('queueDeleted', undefined);

      return of(fulfilled, message);
    })
  )
);
export const fetchNextQueuesPageEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchQueuesFulfilled)),
    pluck('payload', 'pagination', 'next'),
    filter(isNonEmptyString),
    takeUntil(action$.pipe(filter(isActionOf(alertNetworkOutage)))),
    map(url => fetchQueues({ url }))
  )
);

export const allQueuesWereFetchedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchQueuesFulfilled)),
    pluck('payload', 'pagination', 'next'),
    filter(next => !next),
    map(() => allQueuesWereFetched())
  )
);

export const fetchQueuesWhenActiveTabEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(setApplicationTabVisibility)),
    pluck('payload'),
    takeUntil(action$.pipe(filter(isActionOf(alertNetworkOutage)))),
    filter(isTruthy),
    filter(
      () =>
        state$.value.router.location.pathname.includes('/annotations') ||
        state$.value.router.location.pathname.includes('/emails')
    ),
    map(() => fetchQueues())
  )
);

const assureQueueDetailEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([enterQueue, enterQueueEditor])),
    map(() => state$.value),
    map(
      ({
        router: {
          location: { pathname },
        },
        queues,
      }) => [getCurrentQueueId(pathname), queues.list] as const
    ),
    filter(([id, queues]) => !queues.find(queue => queue.id === id)),
    map(([id]) => id),
    filter(isNotNullOrUndefined),
    map(id => fetchQueue(id))
  )
);

const updateQueueDetailEpic = makeEpic((action$, state$, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateQueueDetail)),
    mergeMap(({ payload, meta: { id } }) => {
      const queue = state$.value.queues.list.find(queue => queue.id === id);

      if (!queue) {
        return EMPTY;
      }

      return authPatch$(
        get(queue, 'url'),
        get(payload, 'settings')
          ? { ...payload, settings: { ...queue.settings, ...payload.settings } }
          : payload
      ).pipe(
        map(() => updateQueueDetailFulfilled(id, payload)),
        catchError(error =>
          of(updateQueueDetailFailed(id, error)).pipe(catchError(errorHandler))
        )
      );
    })
  )
);

const updateUsersOnQueueEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(updateUsersOnQueue)),
    map(
      ({ meta: { url, adding, queueId }, payload: results }) =>
        [
          url || `${apiUrl}/users`,
          url
            ? {}
            : {
                query: {
                  search: parse(state$.value.router.location.search).search,
                  pageSize: 100,
                  page: 1,
                  deleted: false,
                },
              },
          results || [],
          adding,
          queueId,
        ] as const
    ),
    mergeMap(([url, _query, _results, adding, queueId]) =>
      // TODO find the response type
      authGetJSON$<PaginatedList<User>>(url, _query).pipe(
        map(({ results, ...response }) => ({
          ...response,
          results: results.map(result => result.url),
        })),
        map(({ pagination: { next }, results }) => {
          if (next) {
            return updateUsersOnQueue({
              adding,
              queueId,
              url: next,
              results: [..._results, ...results],
            });
          }

          const currentUsers = get(
            state$.value.queues.list.find(queue => queue.id === queueId),
            'users',
            []
          );
          const selectedUsers = [..._results, ...results];
          const users = adding
            ? unique([...currentUsers, ...selectedUsers])
            : currentUsers.filter(_url => !selectedUsers.includes(_url));
          return updateQueueDetail(queueId, { users });
        })
      )
    )
  )
);

const enableDefaultEmailTemplateOnCreateQueueEpic = makeEpic(
  (action$, _, { authGetJSON$, authPatch$ }) =>
    zip(
      action$.pipe(
        filter(isActionOf(createQueueFulfilled)),
        pluck('payload'),
        filter(isTruthy)
      ),
      action$.pipe(
        filter(isActionOf(createInboxFulfilled)),
        pluck('payload'),
        filter(isTruthy)
      )
    ).pipe(
      mergeMap(([queue]) =>
        authGetJSON$<PaginatedList<Trigger>>(`${apiUrl}/triggers`, {
          query: {
            queue: queue.id,
            event: 'annotation_created',
          },
        }).pipe(
          map(triggers => triggers.results[0]?.emailTemplates?.[0]),
          filter(url => !!url),
          mergeMap(url =>
            authPatch$(url, {
              automate: true,
            }).pipe(
              map(() => ({
                type: UPDATE_DEFAULT_EMAIL_TEMPLATE_FULFILLED,
              }))
            )
          ),
          catchError(errorHandler)
        )
      )
    )
);

// TODO: This epic is highly suspicious because it redirects to old dashboard path
// It runs only in onboarding flow. Is onboarding flow working?
const onQueueCreationEpic = makeEpic((action$, state$) =>
  merge(
    action$.pipe(
      filter(isActionOf(createQueueFulfilled)),
      filter(action => !action.meta.skipRedirect),
      switchMap(queueAction =>
        action$.pipe(
          filter(isActionOf(createInboxFulfilled)),
          filter(({ meta }) => meta.createdWithQueue),
          filter(
            inboxAction =>
              queueAction.payload.url === inboxAction.payload.queues[0]
          ),
          map(() => queueAction)
        )
      )
    )
  ).pipe(
    map(({ payload: queue }) => {
      return push({
        pathname: `/annotations/${queue.id}`,
        search: `?ordering=createdAt&page=1&status=${defaultTabStatuses.join(
          ','
        )}`,
        hash: state$.value.router.location.hash,
      });
    })
  )
);

const updateQueueOnQueueUpdateEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateQueueDetailFulfilled)),
    filter(() =>
      state$.value.router.location.pathname.includes('/annotations')
    ),
    debounce(() => timer(500)),
    map(() => fetchQueues())
  )
);

const invalidateQueueHooksQueryEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateQueueDetailFulfilled)),
    filter(() => state$.value.router.location.pathname.includes('/extensions')),
    map(({ meta: { id } }) =>
      invalidateQueryClientCache([
        QUERY_KEY_HOOKS_UNPAGINATED,
        getQueueHooksQuery(id),
      ])
    )
  )
);

export default combineEpics(
  allQueuesWereFetchedEpic,
  assureQueueDetailEpic,
  fetchNextQueuesPageEpic,
  fetchQueueEpic,
  fetchQueuesEpic,
  onQueueCreationEpic,
  redirectOnQueueNotFoundEpic,
  updateQueueDetailEpic,
  updateQueueOnQueueUpdateEpic,
  updateUsersOnQueueEpic,
  fetchQueuesWhenActiveTabEpic,
  enableDefaultEmailTemplateOnCreateQueueEpic,
  removeQueueFromStateEpic,
  invalidateQueueHooksQueryEpic
);
