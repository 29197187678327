import { get } from 'lodash';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { authPatch$, errorHandler } from '../../../../lib/api';
import { EpicDependencies } from '../../../../lib/apiTypes';
import {
  isReplaceSuggestedOperation,
  MultivalueDatapointData,
  SectionDatapointData,
  SimpleDatapointData,
} from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { RootActionType } from '../../../rootActions';
import { pagesSelector } from '../../annotation/selectors';
import { complexLineItemsEnabledSelector } from '../../ui/selectors';
import { recountDatapointPositionFulfilled } from '../actions';
import { addSchemaToSuggestedMultivalue } from '../helpers';
import {
  acceptSuggestedOperationsAction,
  acceptSuggestedOperationsFulfilledAction,
  recountSuggestedOperationPositionAction,
  recountSuggestedOperationPositionFulfilledAction,
} from './actions';

export const acceptSuggestedOperationsEpic = (
  action$: ActionsObservable<RootActionType>,
  state$: StateObservable<State>,
  { authPost$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf(acceptSuggestedOperationsAction)),
    mergeMap(
      ({
        meta: {
          datapointPath: [sectionId, multivalueId],
        },
      }) => {
        const operations = Object.values(
          state$.value.datapoints.suggestedOperations
        ).filter(op => op.source === 'extension');
        const { url } = state$.value.annotation;

        const ids = operations.map(({ id }) => id);

        return authPost$<{ content: Array<SectionDatapointData> }>(
          `${url}/content/operations`,
          { operations },
          { query: { deprecatedFields: false } }
        ).pipe(
          map(({ content }) => {
            // Magic items feature is applicable only for multivalue datapoints
            const multivalue = content
              .find(({ id }) => id === sectionId)
              ?.children.find(
                ({ id }) => id === multivalueId
              ) as MultivalueDatapointData;

            const schema = state$.value.schema.content;

            if (!schema) {
              return EMPTY;
            }

            return acceptSuggestedOperationsFulfilledAction(
              ids,
              addSchemaToSuggestedMultivalue(schema, multivalue)
            );
          }),
          catchError(errorHandler)
        );
      }
    )
  );

const recountSuggestedOperationPositionEpic = (
  action$: ActionsObservable<RootActionType>,
  state$: StateObservable<State>,
  { authPost$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf([recountSuggestedOperationPositionAction])),
    pluck('meta'),
    map((id: number) => state$.value.datapoints.suggestedOperations[id]),
    filter(isReplaceSuggestedOperation),
    mergeMap(
      ({
        id,
        value: {
          content: { page: pageNumber, position },
        },
      }) => {
        const {
          annotation: { url },
        } = state$.value;

        const page = pagesSelector(state$.value).find(
          p => p.number === pageNumber
        );

        const complexLineItemsEnabled = complexLineItemsEnabledSelector(
          state$.value
        );

        return authPost$(
          `${url}/content/${id}/select${
            complexLineItemsEnabled ? '' : '?skip_update=true'
          }`,
          {
            rectangle: position,
            page: page?.url,
          }
        ).pipe(
          mergeMap(response => {
            if (complexLineItemsEnabled) {
              return authPatch$<SimpleDatapointData>(`${url}/content/${id}`, {
                validationSources: ['human'],
              }).pipe(
                map(patchResponse =>
                  recountDatapointPositionFulfilled(patchResponse)
                ),
                catchError(errorHandler)
              );
            }

            return of(
              recountSuggestedOperationPositionFulfilledAction(
                get(response, ['content', 'value'], ''),
                id
              )
            );
          }),
          catchError(errorHandler)
        );
      }
    )
  );

export default combineEpics(
  acceptSuggestedOperationsEpic,
  recountSuggestedOperationPositionEpic
);
