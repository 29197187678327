import { Skeleton, Stack } from '@rossum/ui/material';
import { take } from 'lodash';
import { ReactNode, useState } from 'react';
import { range } from 'remeda';
import { CollapsibleListHeader } from './CollapsibleListHeader';
import { ShowMoreButton } from './ShowMoreButton';

type CollapsibleListProps<T> = {
  items: T[] | undefined;
  displayCount: number;
  children: (items: T[]) => ReactNode;
};

export const CollapsibleList = <T,>({
  items,
  displayCount,
  children,
}: CollapsibleListProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  if (!items) {
    return (
      <Stack>
        {range(0, displayCount).map(n => (
          <Skeleton key={n} height={40} width="100%" sx={{ mt: 0 }} />
        ))}
      </Stack>
    );
  }

  if (items && items.length < 1) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <CollapsibleListHeader count={items?.length} />
      {children(expanded ? items : take(items, displayCount))}
      {items && items.length > displayCount && (
        <ShowMoreButton onClick={setExpanded} expanded={expanded} />
      )}
    </Stack>
  );
};
