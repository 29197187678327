import { ExtensionsView } from '../../../types/extensions';
import { SelectedEventT } from '../DependenciesGraph/types';
import { myExtensionsPath } from '../helpers';

export type MyExtensionsQuery = {
  page: string;
  pageSize: string;
  name?: string;
  queues?: string;
  events: SelectedEventT;
  view: ExtensionsView;
};
export const myExtensionsDefaultQuery = {
  events: 'all',
  page: '1',
  pageSize: '15',
  view: 'list',
} as const;

export const getMyExtensionsPathWithQuery = (
  query: Partial<MyExtensionsQuery> = myExtensionsDefaultQuery
) => {
  const pathQuery = { ...myExtensionsDefaultQuery, ...query };
  const queryString = new URLSearchParams(pathQuery).toString();

  return `${myExtensionsPath()}?${queryString}`;
};
