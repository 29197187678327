import { Components, Theme } from '../../material';

export const muiButton = (theme: Theme): Components['MuiButton'] => ({
  variants: [
    {
      props: { variant: 'contained' },
      style: {
        border: '1px solid transparent', // for keeping the same height as outlined
        boxShadow: 'none',
        ':hover': { boxShadow: 'none' },
      },
    },
    {
      props: {
        size: 'small',
      },
      style: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.3334,
        letterSpacing: 0,
        padding: '4px 12px',
      },
    },
    {
      props: {
        size: 'medium',
      },
      style: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.429,
        letterSpacing: 0,
        padding: '8px 16px',
      },
    },
    {
      props: {
        size: 'large',
      },
      style: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        letterSpacing: 0,
        padding: '12px 24px',
      },
    },
  ],
});
