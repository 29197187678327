import { Collapse } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import {
  hasMatchedTriggerRules,
  matchedTriggerRulesSelector,
} from '../../../../redux/modules/datapoints/selector';
import { DeleteRecommendation } from './DeleteRecommendation';

export const useDeleteRecommendations = () => {
  const matchedTriggerRules = useSelector(matchedTriggerRulesSelector);

  const displayDeleteRecommendation =
    hasMatchedTriggerRules(matchedTriggerRules);

  return (
    <Collapse in={displayDeleteRecommendation}>
      {displayDeleteRecommendation ? (
        <DeleteRecommendation matchedTriggerRules={matchedTriggerRules} />
      ) : (
        <span />
      )}
    </Collapse>
  );
};
