import { sortBy } from 'lodash';
import * as R from 'remeda';
import {
  DEV_FEATURES_ENABLED,
  isEmbedded,
  reactAppLocale,
} from '../constants/config';
import { IntlLocale } from '.';

export const LOCALE_LOCALSTORAGE_KEY = 'locale';

export const fallbackLocale = 'en';
export const defaultLocaleKeys: IntlLocale[] = [
  fallbackLocale,
  'cs',
  'de',
  'es',
  'fr',
  'ja',
  'pt-br',
  'zh',
];
const onlyEmbeddedLocaleKeys: IntlLocale[] = [
  'da',
  'fi',
  'hr',
  'hu',
  'it',
  'nb',
  'nl',
  'pl',
  'pt',
  'sl',
  'sq',
  'sr',
  'sv',
  'tr',
];

export const supportedLocales = [
  ...defaultLocaleKeys,
  ...onlyEmbeddedLocaleKeys,
];
const locales = process.env.REACT_APP_LOCALES || supportedLocales;

const availableLocales =
  DEV_FEATURES_ENABLED || isEmbedded() ? supportedLocales : defaultLocaleKeys;

const browserLocale = window.navigator.languages.reduce<string | undefined>(
  (bestLocale, preferredLocale) =>
    bestLocale ||
    R.pipe(
      locales,
      a => (Array.isArray(a) ? a : [a]),
      R.find(supportedLocale =>
        preferredLocale.toLowerCase().includes(supportedLocale)
      )
    ),
  undefined
);

export const getDefaultLocale = ({
  omitStorage,
}: { omitStorage?: boolean } = {}): IntlLocale =>
  getLocaleKey(
    omitStorage ? undefined : localStorage.getItem(LOCALE_LOCALSTORAGE_KEY)
  ) ||
  getLocaleKey(browserLocale) ||
  getLocaleKey(reactAppLocale) ||
  getLocaleKey(process.env.REACT_APP_LOCALE) ||
  fallbackLocale;

export const getLocaleKey = (
  locale: string | undefined | null
): IntlLocale | undefined => {
  if (!locale) return undefined;

  return availableLocales.find(
    availableLocale => availableLocale.toLowerCase() === locale.toLowerCase()
  );
};

const localeLabels = {
  cs: 'Czech',
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  ja: 'Japanese',
  zh: 'Chinese Simplified',
  'pt-br': 'Portuguese (Brazil)',
  da: 'Danish',
  fi: 'Finnish',
  hr: 'Croatian',
  hu: 'Hungarian',
  it: 'Italian',
  nb: 'Norwegian Bokmål',
  nl: 'Dutch',
  pl: 'Polish',
  pt: 'Portuguese',
  sl: 'Slovenian',
  sq: 'Albanian',
  sr: 'Serbian',
  sv: 'Swedish',
  tr: 'Turkish',
} as Record<string, string>;

export const localeOptions = sortBy(
  availableLocales.map(value => {
    const label =
      localeLabels[value] ||
      // eslint-disable-next-line no-console
      console.warn(`Locale label missing for ${value}`) ||
      value;

    return { value, label };
  }),
  'label'
);
