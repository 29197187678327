import { Autocomplete, TextField } from '@rossum/ui/material';
import { forwardRef, Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePretrainedFields } from '../hooks/usePretrainedFields';
import { AutocompleteGroup } from './autocomplete/AutocompleteGroup';
import { AutocompleteItem } from './autocomplete/AutocompleteItem';
import { AutocompleteNoResults } from './autocomplete/AutocompleteNoResults';

const supportedGroups = [
  'amounts',
  'barcodes',
  'documentAttributes',
  'identifiers',
  'lineItemsTable',
  'qrCodes',
  'taxDetailsTable',
] as const;

// List of fields that are properly translated on ELIS side.
const supportedPretrainedFields = [
  'account_num',
  'bank_num',
  'iban',
  'bic',
  'const_sym',
  'spec_sym',
  'var_sym',
  'terms',
  'payment_method',
  'customer_id',
  'date_due',
  'date_issue',
  'date_uzp',
  'document_id',
  'order_id',
  'recipient_address',
  'recipient_dic',
  'recipient_ic',
  'recipient_name',
  'recipient_vat_id',
  'recipient_delivery_name',
  'recipient_delivery_address',
  'sender_address',
  'sender_dic',
  'sender_ic',
  'sender_name',
  'sender_vat_id',
  'sender_email',
  'sender_order_id',
  'delivery_note_id',
  'supply_place',
  'currency',
  'document_type',
  'language',
  'payment_method_type',
  'amount_due',
  'amount_rounding',
  'amount_total',
  'amount_paid',
  'amount_total_base',
  'amount_total_tax',
  'tax_detail_base',
  'tax_detail_rate',
  'tax_detail_tax',
  'tax_detail_total',
  'tax_detail_code',
  'table_column_code',
  'table_column_description',
  'table_column_quantity',
  'table_column_uom',
  'table_column_rate',
  'table_column_tax',
  'table_column_amount_base',
  'table_column_amount',
  'table_column_amount_total_base',
  'table_column_amount_total',
  'table_column_other',
  'barcode_qrcode',
  'barcode_ean2',
  'barcode_ean5',
  'barcode_ean8',
  'barcode_ean13',
  'barcode_code39',
  'barcode_code93',
  'barcode_code128',
  'barcode_isbn10',
  'barcode_isbn13',
  'barcode_upca',
  'barcode_upce',
  'barcode_databar',
  'barcode_databar_exp',
  'barcode_codabar',
  'barcode_pdf417',
  'barcode_sqcode',
  'barcode_i25',
] as const;

const toGroupKey = (group: string) => {
  return (
    {
      Identifiers: 'identifiers',
      'Document attributes': 'documentAttributes',
      Amounts: 'amounts',
      'Tax details table': 'taxDetailsTable',
      'Line items table': 'lineItemsTable',
      Barcodes: 'barcodes',
      'QR codes': 'qrCodes',
    } as const
  )[group];
};

export const PretrainedFieldsInput = forwardRef(
  (
    {
      value,
      tabular,
      onChange,
      disabled,
    }: {
      value: string | null;
      tabular?: boolean;
      onChange: (
        newValue: string | null,
        dataType: {
          type: string;
          subtype: string;
          tabular: boolean;
          multiline: string;
        } | null
      ) => void;
      disabled?: boolean;
    },
    ref
  ) => {
    const intl = useIntl();
    const { data } = usePretrainedFields();

    // To generate fields for the translation file:

    // console.log(
    //   data?.results
    //     .flatMap(row => [
    //       `"features.engines.uptrainField.${row.name}.label": "${row.label}",`,
    //       `"features.engines.uptrainField.${row.name}.description": "${
    //         row.description ? row.description.replaceAll('"', '\\"') : '{empty}'
    //       }",`,
    //     ])
    //     .join('\n')
    // );

    const [inputValue, setInputValue] = useState('');

    const emptyOption = useMemo(
      () => ({
        label: intl.formatMessage({
          id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId.empty.label',
        }),
        name: null,
        section: null,
      }),
      [intl]
    );

    // When tabular is set (not undefined), then filter out only valid pre-trained fields.
    const options = useMemo(
      () => [
        emptyOption,
        ...(data?.results.filter(
          result => tabular === undefined || result.tabular === tabular
        ) ?? []),
      ],
      [data?.results, emptyOption, tabular]
    );

    const selectedItem = useMemo(
      () => options.find(field => field && field.name === value) ?? emptyOption,
      [emptyOption, options, value]
    );

    const getLabel = ({ name }: { name: string }) => {
      const field = supportedPretrainedFields.find(field => field === name);
      return field
        ? intl.formatMessage({
            id: `features.engines.uptrainField.${field}.label`,
          })
        : intl.formatMessage(
            {
              id: `features.engines.uptrainField.unknownLabel`,
            },
            { name }
          );
    };

    const getDescription = ({
      description,
      name,
    }: {
      description: string;
      name: string;
    }) => {
      const field = supportedPretrainedFields.find(field => field === name);
      return field
        ? intl.formatMessage(
            {
              id: `features.engines.uptrainField.${field}.description`,
            },
            { empty: '' }
          )
        : description;
    };

    return (
      <Autocomplete
        disabled={disabled}
        ref={ref}
        id="field.preTrainedFieldId"
        value={selectedItem}
        disableClearable
        onChange={(_, newValue) => {
          if (newValue) {
            onChange(newValue.name, newValue.name ? newValue : null);
          }
        }}
        onInputChange={(_, enteredText) => {
          setInputValue(enteredText);
        }}
        inputValue={inputValue}
        options={options}
        getOptionLabel={option =>
          option.name
            ? getLabel(option)
            : intl.formatMessage({
                id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId.empty.label',
              })
        }
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId',
            })}
          />
        )}
        groupBy={option => option.section ?? ''}
        renderGroup={params => {
          const group = supportedGroups.find(
            group => group === toGroupKey(params.group)
          );

          return (
            <Fragment key={params.key}>
              {group && (
                <AutocompleteGroup>
                  {intl.formatMessage({
                    id: `features.engines.uptrainGroup.${group}`,
                  })}
                </AutocompleteGroup>
              )}

              {params.children}
            </Fragment>
          );
        }}
        renderOption={(params, option) => (
          <AutocompleteItem
            MenuItemProps={params}
            key={option.name}
            label={
              option.name
                ? getLabel(option)
                : intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId.empty.label',
                  })
            }
            description={
              option.name
                ? getDescription(option)
                : intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId.empty.description',
                  })
            }
          />
        )}
        noOptionsText={<AutocompleteNoResults inputValue={inputValue} />}
      />
    );
  }
);
