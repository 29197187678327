import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../../lib/apiClient';
import { throwError } from '../../../../redux/modules/messages/actions';
import { ATTACHMENTS_RELATIONS_QUERY_KEY } from './useFetchAttachmentRelation';

const DELETE_ATTACHMENT_RELATION_KEY = 'delete-attachment-relation';

export const useDeleteAttachmentRelation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [DELETE_ATTACHMENT_RELATION_KEY],
    mutationFn: (relationId: ID) =>
      api.request(endpoints.relations.delete(relationId)),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ATTACHMENTS_RELATIONS_QUERY_KEY],
      });
    },

    onError: () => {
      dispatch(throwError('deleteAttachmentError'));
    },
  });
};
