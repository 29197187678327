import { Url } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { parse } from '../../../lib/url';
import { DOCUMENTS_QUERY } from '../../../redux/modules/localStorage/actions';
import { useJsonParse } from '../../../utils/hooks/useJsonParse';
import { useStoredState } from '../../../utils/hooks/useStoredState';
import {
  getQueueId,
  validateLevel,
} from '../../document-list/hooks/useDashboardQuery';

// TODO: @rules/templates this all logic is kinda hacky
export const useDefaultQueue = ({
  schemasToAdd,
  rulesSchema,
  queues,
}: {
  schemasToAdd: Url[];
  rulesSchema: Url | undefined;
  queues: Queue[] | undefined;
}) => {
  const [query, _] = useStoredState({
    name: DOCUMENTS_QUERY,
    defaultValues: {},
    stringKeys: ['filtering', 'level'],
    parseFn: queryString => parse(queryString, { arrayFormat: 'none' }),
  });

  const [existingFilter] = useJsonParse<GridFilterModel>(
    query?.filtering ?? ''
  );

  const level = validateLevel(query.level);

  const currentQueueId = getQueueId(existingFilter, level);

  const defaultQueue = useMemo(() => {
    if (queues && queues.length > 0) {
      const firstQueue = queues[0];
      const lastDashboardQueue = queues.find(
        queue => queue.id === currentQueueId
      );
      const firstRuleQueue = queues.find(queue => queue.schema === rulesSchema);
      const firstSchemaToAddQueue = queues.find(
        queue => queue.schema === schemasToAdd[0]
      );

      return (
        firstRuleQueue ??
        firstSchemaToAddQueue ??
        lastDashboardQueue ??
        firstQueue
      );
    }

    return null;
  }, [currentQueueId, queues, rulesSchema, schemasToAdd]);

  return defaultQueue;
};
