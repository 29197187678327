import './normalize.css';
import './index.sass';
import './utils.sass';
import 'rxjs';
import './lib/getDataCy';
import '@rossum/ui/theme/fonts';
import { isIE } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import App from './containers/App';
import onLoadScript from './onLoadScripts';
import store from './redux/configureStore';

if (!isIE) {
  onLoadScript(store);
  const root = createRoot(document.getElementById('root'));
  root.render(<App />);
}
