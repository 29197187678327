import { useIntl } from 'react-intl';
import { useInfiniteEngines } from '../hooks/useInfiniteEngines';
import { AuroraEngineTile } from './AuroraEngineTile';
import { Paragraphs } from './Paragraphs';
import { ShowMoreButton } from './ShowMoreButton';
import { TilesList } from './TilesList';
import { TilesListEmptyState } from './TilesListEmptyState';
const SplittersList = () => {
  const intl = useIntl();

  const {
    data: engines,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status,
  } = useInfiniteEngines({
    pageSize: 10,
    ordering: ['-id'],
    type: 'splitter',
  });

  const allEngines = engines?.pages.flatMap(page => page.results);

  return (
    <TilesList
      title={intl.formatMessage({
        id: 'features.engines.list.splitter.title',
      })}
      items={allEngines}
      renderTile={engine => (
        <AuroraEngineTile key={engine.id} engine={engine} />
      )}
      status={status}
      emptyState={
        <TilesListEmptyState
          title={intl.formatMessage({
            id: 'features.engines.list.noEngines.title',
          })}
          subtitle={
            <Paragraphs>
              {intl.formatMessage({
                id: 'features.engines.list.splitter.noEngines',
              })}
            </Paragraphs>
          }
        />
      }
      buttons={
        hasNextPage
          ? [
              <ShowMoreButton
                key="show-more-results"
                onClick={fetchNextPage}
                isFetching={isFetching}
              />,
            ]
          : []
      }
    />
  );
};

export { SplittersList };
