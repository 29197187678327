import { cs, da, de, enGB, enUS, es, fi, fr, hr, hu, it, ja, nb, nl, pl, pt, ptBR, sl, sq, sr, sv, tr,zhCN } from 'date-fns/locale';
import { IntlLocale } from '.';

// custom mapping to our locale keys
// load only relevant locales for ELIS, not all available
export const dateFnsLocales: Record<IntlLocale | 'enUS', Locale> = {
  da,
  de,
  es,
  fi,
  fr,
  hr,
  hu,
  it,
  cs,
  ja,
  nb,
  nl,
  pl,
  pt,
  sl,
  sq,
  sr,
  sv,
  tr,
  en: enGB,
  enUS,
  zh: zhCN,
  'pt-br': ptBR,
};

export type DateFnsLocale = keyof typeof dateFnsLocales;
