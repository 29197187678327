import jsonMap from 'json-source-map';

// helpers for detecting error position taken from jsoneditor textmode
type PathWithLocation = {
  path: string;
  line: number;
  column: number;
};

type JSONMap = {
  pointers: {
    [key: string]: {
      [key: string]: { value: string; line: number; column: number };
    };
  };
};

const getLocationsForPaths = (
  text: string,
  paths: Array<string>
): Array<PathWithLocation> => {
  try {
    if (!paths || !paths.length) {
      return [];
    }
    const jsmap: JSONMap = jsonMap.parse(text);

    return paths.reduce<Array<PathWithLocation>>((acc, path) => {
      const pointer = jsmap.pointers[path];

      return pointer
        ? [
            ...acc,
            {
              path,
              line: pointer.key
                ? pointer.key.line
                : pointer.value
                  ? pointer.value.line
                  : 0,
              column: pointer.key
                ? pointer.key.column
                : pointer.value
                  ? pointer.value.column
                  : 0,
            },
          ]
        : acc;
    }, []);
  } catch {
    return [];
  }
};

export default {
  getLocationsForPaths,
};
