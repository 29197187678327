import { endpoints } from '@rossum/api-client';
import { Hook } from '@rossum/api-client/hooks';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useUnpaginatedHooks } from '../../../business/hooks/useUnpaginatedHooks';
import { api } from '../../../lib/apiClient';
import {
  ExtensionEventAction,
  normalizedEventsWithoutInvocation,
} from '../lib/helpers';
import {
  createGraphFunctionsInput,
  ExtendedHook,
  ExtensionsGraphData,
  ExtensionsRelationMap,
  getGraphData,
  getQueueHooksQuery,
  mergeParentsAndChildren,
  normalizeEventsInExtensions,
} from './helpers';

type UseGraphHooks = {
  queueId?: number;
  enabled?: boolean;
};

export type GraphDataGroupedPerEvents = Array<{
  eventNames: ExtensionEventAction[];
  graphData: ExtensionsGraphData;
  extensionGraphNodesMap: ExtensionsRelationMap;
}>;

export const useGraphHooks = ({ queueId, enabled }: UseGraphHooks) => {
  const {
    data: extensions,
    isLoading,
    isFetching,
  } = useUnpaginatedHooks(getQueueHooksQuery(queueId), {
    cacheTime: 0, // removes the cache on unmounting component
    enabled,
  });

  const graphDataGroupedPerEvents = useMemo(() => {
    const withNormalizedEvents = normalizeEventsInExtensions(extensions ?? []);

    const graphDataForAllEvents = normalizedEventsWithoutInvocation.map(
      event => {
        const graphFunctionsInput = createGraphFunctionsInput(
          withNormalizedEvents,
          event
        );

        return {
          eventNames: [event],
          graphData: getGraphData(graphFunctionsInput),
          extensionGraphNodesMap: mergeParentsAndChildren(graphFunctionsInput),
        };
      }
    );

    return graphDataForAllEvents.reduce<GraphDataGroupedPerEvents>(
      (acc, event) => {
        const equalDataIndex = acc.findIndex(accEvent =>
          isEqual(accEvent.graphData, event.graphData)
        );

        if (equalDataIndex === -1) {
          return [...acc, event];
        }

        const head = acc.slice(0, equalDataIndex);
        const [equalData, ...tail] = acc.slice(equalDataIndex);

        return [
          ...head,
          {
            ...equalData,
            eventNames: [...equalData.eventNames, ...event.eventNames],
          },
          ...tail,
        ];
      },
      []
    );
  }, [extensions]);

  return {
    graphDataGroupedPerEvents,
    isLoading,
    isFetching,
    originalExtensions: extensions,
  };
};

export const useUpdateDependenciesHook = () => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({
      selectedExtensionUrl,
      extensionsToAdd,
      extensionsToRemove,
      originalExtensions,
    }: {
      selectedExtensionUrl: ExtendedHook['url'] | undefined;
      extensionsToAdd: ExtendedHook[];
      extensionsToRemove: ExtendedHook[];
      originalExtensions: Hook[] | undefined;
    }) => {
      if (
        !(extensionsToAdd.length || extensionsToRemove.length) ||
        !selectedExtensionUrl
      ) {
        return Promise.reject(new Error('no_updates'));
      }
      const addRequests = extensionsToAdd.map(extension => {
        const originalExtension = originalExtensions?.find(
          e => e.url === extension.url
        );
        const _runAfter = originalExtension?.runAfter ?? [];
        const runAfter = [..._runAfter, selectedExtensionUrl];

        return extension?.id
          ? api.request(
              endpoints.hooks.patch(extension.id, {
                runAfter,
                type: extension.type,
              })
            )
          : null;
      });
      const removeRequests = extensionsToRemove.map(extension => {
        const originalExtension = originalExtensions?.find(
          e => e.url === extension.url
        );
        const _runAfter = originalExtension?.runAfter ?? [];
        const runAfter = _runAfter.filter(url => url !== selectedExtensionUrl);
        return extension?.id
          ? api.request(
              endpoints.hooks.patch(extension.id, {
                runAfter,
                type: extension.type,
              })
            )
          : null;
      });

      return Promise.all([...addRequests, ...removeRequests]);
    },
  });

  return { mutateAsync, isLoading };
};
