export const Logo = ({ size = 30 }: { size?: number }) => (
  <svg viewBox="0 0 30 30" width={size} height={size}>
    <linearGradient
      id="logoGradient"
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1="17"
      x2="30"
      y2="17"
      gradientTransform="matrix(1 0 0 -1 0 32)"
    >
      <stop offset="0.1426" stopColor="#3AC0FF" />
      <stop offset="1" stopColor="#2774FF" />
    </linearGradient>
    <path
      width={size}
      height={size}
      fill="url(#logoGradient)"
      d="M15,3.1C8.4,3.1,3.1,8.4,3.1,15S8.4,26.9,15,26.9S26.9,21.6,26.9,15S21.6,3.1,15,3.1z M15,30C6.7,30,0,23.3,0,15S6.7,0,15,0s15,6.7,15,15S23.3,30,15,30L15,30z"
    />
  </svg>
);
