import { TriggerEventType } from '@rossum/api-client/triggers';
import * as R from 'remeda';
import {
  MQLAnd,
  MQLElemMatch,
  MQLIn,
  MQLNot,
  MQLRegex,
  parseMQLAnd,
} from '../../../../business/triggers/mappers/mql';

type RegexType = string;
type SchemaIdType = string;

// Client model

export const triggerEvents = [
  'document_received',
  'document_extracted',
  'document_confirmed',
  'document_exported',
  'any_field_missing',
  'at_least_one_missing',
] as const;

export type TriggerClientEvent = (typeof triggerEvents)[number];

export const triggerConditionOperators = ['contains', 'notContains'] as const;

type TriggerConditionOperator = (typeof triggerConditionOperators)[number];

type TriggerClientModelCondition<T = RegexType> = {
  field: string;
  operator: TriggerConditionOperator;
  value: T;
};

export type TriggerClientModel<T = RegexType> = {
  id?: number;
  event: TriggerClientEvent;
  fields?: string[];
  condition?: TriggerClientModelCondition<T>;
};

type MissingFieldsType = MQLElemMatch<MQLIn<SchemaIdType>>;
type FieldValueItem<T = RegexType> =
  | MQLNot<MQLRegex<T>>
  | MQLRegex<T>
  | ExistsValue;
type FieldValue<T = RegexType> = MQLAnd<FieldValueItem<T>>;
type FieldKey = `field.${SchemaIdType}`;
type ExistsValue = { $exists: true };

type RequiredFieldMissingCondition = { required_field_missing: true };
type MissingFieldsCondition = { missing_fields: MissingFieldsType };
export type FieldCondition<T = RegexType> = Record<FieldKey, FieldValue<T>>;

type RootCondition =
  | RequiredFieldMissingCondition
  | MissingFieldsCondition
  | FieldCondition;

export type TriggerConditionApiModel =
  | MQLAnd<RootCondition>
  | Record<string, never>;

const isRequiredFieldMissingCondition = (
  condition: RootCondition
): condition is RequiredFieldMissingCondition => {
  return (
    'required_field_missing' in condition &&
    condition.required_field_missing === true
  );
};

const isMissingFieldsCondition = (
  condition: RootCondition
): condition is MissingFieldsCondition => {
  return 'missing_fields' in condition;
};

const isFieldCondition = (
  condition: RootCondition
): condition is FieldCondition => {
  return Object.keys(condition).every(key => key.startsWith('field.'));
};

const isExistsValue = (
  value: FieldValueItem<unknown>
): value is ExistsValue => {
  return '$exists' in value;
};

const eventMapping: Record<TriggerClientEvent, TriggerEventType> = {
  document_received: 'annotation_created',
  document_confirmed: 'annotation_confirmed',
  document_extracted: 'annotation_imported',
  document_exported: 'annotation_exported',
  at_least_one_missing: 'annotation_imported',
  any_field_missing: 'annotation_imported',
};

const inverseEventMapping: Record<TriggerEventType, TriggerClientEvent | null> =
  {
    annotation_created: 'document_received',
    annotation_confirmed: 'document_confirmed',
    annotation_imported: 'document_extracted',
    annotation_exported: 'document_exported',
    email_with_no_processable_attachments: null,
    validation: null,
  };

export const toClientCondition = <T>(
  apiCondition: FieldCondition<T> | undefined
): TriggerClientModelCondition<T> | undefined => {
  if (apiCondition === undefined) {
    return undefined;
  }
  const key = Object.keys(apiCondition)[0] as keyof FieldCondition;
  if (key) {
    const regex = parseMQLAnd(apiCondition[key]).find(
      (value): value is Exclude<typeof value, ExistsValue> =>
        !isExistsValue(value)
    );

    if (!regex) {
      return undefined;
    }

    const field = key.replace(/^field\./, '');
    const operator = '$not' in regex ? 'notContains' : 'contains';
    const value = '$not' in regex ? regex.$not.$regex : regex.$regex;
    return { field, operator, value };
  }
  return undefined;
};

const toClientEvent = (
  eventType: TriggerEventType,
  conditions: RootCondition[]
): TriggerClientEvent | null => {
  // Here, i need to differentiate by trigger conditions.
  if (eventType === 'annotation_imported') {
    if (conditions.some(isRequiredFieldMissingCondition)) {
      return 'any_field_missing';
    }
    if (conditions.some(isMissingFieldsCondition)) {
      return 'at_least_one_missing';
    }
    return 'document_extracted';
  }

  // I can find the key by value for the other cases (result is unique).
  // Null fallback in case there is an event that front-end doesn't know.
  return inverseEventMapping[eventType] ?? null;
};

export const toTriggerClientModel = (
  id: number | undefined,
  triggerEvent: TriggerEventType,
  triggerCondition: TriggerConditionApiModel | null
): TriggerClientModel | null => {
  const conditions =
    triggerCondition && '$and' in triggerCondition
      ? parseMQLAnd(triggerCondition)
      : [];
  const missingFields = conditions.find(isMissingFieldsCondition)
    ?.missing_fields.$elemMatch.$in;
  const fieldCondition = conditions
    .filter(isFieldCondition)
    .map(toClientCondition)?.[0];
  const clientEvent = toClientEvent(triggerEvent, conditions);

  return clientEvent !== null
    ? {
        id,
        event: clientEvent,
        fields: missingFields,
        condition: fieldCondition,
      }
    : null;
};

const toTriggerApiFieldCondition = (
  condition: TriggerClientModelCondition
): FieldCondition | null => {
  const { field, operator, value } = condition;
  const key: FieldKey = `field.${field}`;
  const conditionalNot =
    operator === 'notContains'
      ? <T>(clause: T) => ({ $not: clause })
      : R.identity();

  const fieldCondition = field
    ? {
        [key]: {
          $and: [{ $exists: true as const }, conditionalNot({ $regex: value })],
        },
      }
    : null;
  return fieldCondition;
};

export const toTriggerApiModel = (
  clientModel: TriggerClientModel
): { event: TriggerEventType; condition: TriggerConditionApiModel } => {
  const anyFieldMissingCondition = clientModel.event ===
    'any_field_missing' && { required_field_missing: true };

  const atLeastOneMissingCondition = clientModel.event ===
    'at_least_one_missing' &&
    clientModel.fields && {
      missing_fields: { $elemMatch: { $in: clientModel.fields } },
    };

  const fieldCondition =
    clientModel.event !== 'document_received' &&
    clientModel.condition &&
    toTriggerApiFieldCondition(clientModel.condition);

  const clauses: RootCondition[] = R.pipe(
    [anyFieldMissingCondition, atLeastOneMissingCondition, fieldCondition],
    R.filter(R.isTruthy)
  );

  return {
    event: eventMapping[clientModel.event],
    condition: clauses.length ? { $and: clauses } : {},
  };
};
