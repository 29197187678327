import { Engine } from '@rossum/api-client/engines';
import { Dialog } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import {
  DUPLICATE_ENGINE_FORM_ID,
  DuplicateEngineForm,
} from './DuplicateEngineForm';

type DuplicateEngineDialogProps = {
  engine: Engine;
  open: boolean;
  closeDialog: () => void;
};

const DuplicateEngineDialog = ({
  engine,
  open,
  closeDialog,
}: DuplicateEngineDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      sx={{ transition: 'smooth' }}
      PaperProps={{
        sx: { width: 566, minHeight: 213, position: 'fixed' },
        elevation: 2,
      }}
    >
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'features.engines.dialog.duplicateEngine.title',
        })}
        onCancel={closeDialog}
        dialogColor="secondary"
        dialogActions={
          <ActionDialogActions
            dataCyConfirm="duplicateEngine-confirm-btn"
            confirmButtonText={intl.formatMessage({
              id: 'features.engines.dialog.duplicateEngine.confirm',
            })}
            confirmButtonProps={{
              color: 'primary',
              type: 'submit',
              form: DUPLICATE_ENGINE_FORM_ID,
            }}
          />
        }
        dataCy="duplicateEngine-cancel-btn"
      >
        {open ? (
          <DuplicateEngineForm engine={engine} closeDialog={closeDialog} />
        ) : null}
      </ActionDialog>
    </Dialog>
  );
};

export { DuplicateEngineDialog };
