import { getIDFromUrl } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as R from 'remeda';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { reasoningFieldsEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { formulaFieldsFeatureSelector } from '../../../pricing/selectors';
import { FieldFormSkeleton } from '../../../queue-settings/fields/components/form/FieldFormSkeleton';
import { ItemContent } from '../../../queue-settings/fields/components/ItemContent';
import { FieldsFormModel } from '../../../queue-settings/fields/form-model/formModels';
import { toSchemaPatchPayload } from '../../../queue-settings/fields/form-model/transformations/toSchema';
import { useQueues } from '../../../queues/hooks/useQueues';
import {
  FieldManagerCreateFieldParams,
  fieldManagerPath,
} from '../../constants';
import { useFieldManagerData } from '../../hooks/useFieldManagerData';
import { usePatchSchemas } from '../../hooks/usePatchSchemas';
import { CreateFieldHeader } from './CreateFieldHeader';

export const CreateFieldForm = () => {
  const { push, goBack } = useHistory();
  const { params } = useRouteMatch<FieldManagerCreateFieldParams>();

  const { schemas } = useFieldManagerData();

  const formulaFieldsEnabled = useSelector(formulaFieldsFeatureSelector);

  const reasoningFieldsEnabled = useSelector(reasoningFieldsEnabledSelector);

  const schema = useMemo(() => {
    const { schemaId } = params;

    if (!schemaId || !Number(schemaId)) return undefined;

    return schemas.find(({ id }) => id === Number(schemaId));
  }, [params, schemas]);

  const { data: queues, status: queuesStatus } = useQueues({
    id: schema?.queues.map(url => getIDFromUrl(url)),
  });

  const parentId = useMemo(() => {
    const { parentId } = params;

    if (!parentId) return undefined;

    return parentId;
  }, [params]);

  const { mutation } = usePatchSchemas();
  const queryClient = useQueryClient();

  const handleSubmit = (data: FieldsFormModel) => {
    if (parentId) {
      const updatedSchema = toSchemaPatchPayload(schema, {
        op: 'add',
        parentId,
        formModel: data,
      });
      if (updatedSchema) {
        mutation.mutate([updatedSchema], {
          onSuccess: ({ fulfilled }) => {
            fulfilled.forEach(newSchema => {
              queryClient.setQueryData(['schema', newSchema.url], newSchema);
            });

            push(fieldManagerPath());
          },
        });
      }
    }
  };

  return (
    <PageLayoutV2
      renderHeader={headerParams =>
        schema ? (
          <CreateFieldHeader
            isLoading={mutation.isLoading}
            goBack={goBack}
            schema={schema}
            {...headerParams}
          />
        ) : null
      }
    >
      <Stack>
        {parentId && schema && queuesStatus === 'success' ? (
          <ItemContent
            parentId={parentId}
            data={null}
            schema={schema}
            queues={queues.results}
            onSubmit={data => handleSubmit(data)}
            onDelete={R.doNothing}
            onChildrenReorder={R.doNothing}
            onQuickAction={R.doNothing}
            formulaFieldsEnabled={formulaFieldsEnabled}
            reasoningFieldsEnabled={reasoningFieldsEnabled}
          />
        ) : (
          <FieldFormSkeleton />
        )}
      </Stack>
    </PageLayoutV2>
  );
};
