import { Url } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { SnakeToCamel } from '@rossum/api-client/utils';
import { useSelector } from 'react-redux';
import { isStatusVisible } from '../../containers/AnnotationList/types';
import { useWorkflowStepNames } from '../../features/document-list-base/hooks/useWorkflowStepNames';
import { State } from '../../types/state';
import { RequestDetailChip } from '../../ui/status/RequestDetailChip';
import StatusChip from '../../ui/status/StatusChip';

type DocumentStatusProps = {
  annotationId: number;
  annotationUrl: Url;
  status: SnakeToCamel<AnnotationStatus> | undefined;
  modifier:
    | {
        id: number;
        username: string;
      }
    | string
    | undefined;
};

export const DocumentStatus = ({
  annotationId,
  annotationUrl,
  status,
  modifier,
}: DocumentStatusProps) => {
  const userId = useSelector((state: State) => state.user.id);

  const { data: workflowStepNames } = useWorkflowStepNames(
    annotationId,
    status
  );

  const stepName =
    status === 'inWorkflow' && workflowStepNames?.stepNames
      ? workflowStepNames.stepNames?.[annotationUrl]
      : undefined;

  const isValidModifer = typeof modifier !== 'string';

  const isOwned = isValidModifer ? modifier?.id === userId : false;

  if (status === undefined || !isStatusVisible(status)) {
    return null;
  }

  return stepName && status === 'inWorkflow' ? (
    <RequestDetailChip name={stepName} status="progress" />
  ) : (
    <StatusChip
      status={status}
      isOwned={isOwned}
      modifier={isValidModifer ? modifier?.username : undefined}
    />
  );
};
