import { ErrorOutline as ErrorOutlineIcon } from '@rossum/ui/icons';
import { WarningAmber as WarningAmberIcon } from '@rossum/ui/icons';
import { Stack, styled } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { getColor } from '../helpers';

const ColoredWrapper = styled(Stack)<{ usedPercentage: number }>(
  ({ usedPercentage, theme }) => ({
    borderRadius: 4,
    backgroundColor: theme.palette[getColor(usedPercentage)].main,
    padding: theme.spacing(1),
  })
);

type OverusedBannerProps = {
  usedPercentage: number;
};

const OverusedBanner = ({ usedPercentage }: OverusedBannerProps) => {
  const intl = useIntl();
  const isOverused = usedPercentage >= 100;

  return (
    <ColoredWrapper
      usedPercentage={usedPercentage}
      direction="row"
      alignItems="center"
      gap={1}
    >
      {isOverused ? <ErrorOutlineIcon /> : <WarningAmberIcon />}
      {intl.formatMessage({
        id: isOverused
          ? 'containers.billing.overview.overusedError'
          : 'containers.billing.overview.overusedWarning',
      })}
    </ColoredWrapper>
  );
};

export { OverusedBanner };
