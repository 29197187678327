import { GridRowsProp } from '@rossum/ui/x-data-grid-pro';
import { countBy } from 'lodash';
import { useMemo } from 'react';
import { groupBy, unique } from 'remeda';
import {
  AnalyzedFields,
  FieldMetadata,
  FieldProperties,
  OverviewGridRowModel,
} from '../../../types/fields';
import { Queue } from '../../../types/queue';
import { FlatSchemaWithQueues } from '../../../types/schema';

const getSchemasWithQueuesAndOccurrences = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
) => {
  const fieldDetails = flatSchemasWithQueues.flatMap(({ flattenedContent }) =>
    flattenedContent.map(({ id, label, category, ...rest }) => {
      return {
        fieldId: id,
        label,
        category,
        type: 'type' in rest ? rest.type : undefined,
        ...('uiConfiguration' in rest && {
          uiConfigurationType: rest.uiConfiguration?.type,
        }),
      };
    })
  );

  const fieldDetailsWithOccurrences = groupBy(fieldDetails, d => d.fieldId);

  const valueOccurrences = Object.fromEntries(
    Object.entries(fieldDetailsWithOccurrences).map(([fieldId, items]) => {
      return [
        fieldId,

        {
          labels: countBy(items, 'label'),
          category: countBy(items, 'category'),
          type: countBy(items, 'type'),
          uiConfigurationType: countBy(items, 'uiConfigurationType'),
        },
      ];
    })
  );

  return flatSchemasWithQueues.flatMap(schema => {
    return {
      ...schema,
      flattenedContentWithOccurrences: schema.flattenedContent.map(c => {
        return {
          flatSchemaItem: c,
          metadata: {
            labelWithOccurrences: valueOccurrences[c.id]?.labels,
            categoryWithOccurrences: valueOccurrences[c.id]?.category,
            typeWithOccurrences: valueOccurrences[c.id]?.type,
            uiConfigurationTypeWithOccurrences:
              valueOccurrences[c.id]?.uiConfigurationType,
          },
        };
      }),
    };
  });
};

type UseMemoAddOccurrencesInSchemas = ReturnType<
  typeof useMemoAddOccurrencesInSchemas
>;

const useMemoAddOccurrencesInSchemas = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
) =>
  useMemo(
    () => getSchemasWithQueuesAndOccurrences(flatSchemasWithQueues),
    [flatSchemasWithQueues]
  );

const analyzeQueueProperty = (
  analyzedFields: AnalyzedFields,
  id: string,
  value?: Queue[]
): FieldProperties => {
  if (value) {
    return {
      ...analyzedFields[id],
      queues: {
        values: unique(
          (analyzedFields[id]?.queues?.values || []).concat(value)
        ),
      },
    };
  }
  return analyzedFields[id] ?? {};
};

const analyzeMetadataProperty = (
  analyzedFields: AnalyzedFields,
  id: string,
  value?: FieldMetadata
): FieldProperties => {
  if (value) {
    return {
      ...analyzedFields[id],
      metadata: {
        values: value,
      },
    };
  }
  return analyzedFields[id] ?? {};
};

const getAnalyzedFields = (
  flatSchemasWithQueuesAndOccurrences: UseMemoAddOccurrencesInSchemas
): AnalyzedFields => {
  const analyzedFields: AnalyzedFields = {};

  flatSchemasWithQueuesAndOccurrences.forEach(schema => {
    schema.flattenedContentWithOccurrences.forEach(
      ({ flatSchemaItem, metadata }) => {
        const { id } = flatSchemaItem;
        analyzedFields[id] = analyzeQueueProperty(
          analyzedFields,
          id,
          schema.queues
        );
        analyzedFields[id] = analyzeMetadataProperty(
          analyzedFields,
          id,
          metadata
        );
      }
    );
  });

  return analyzedFields;
};

const transformFieldsToRows = (
  analyzedFields: AnalyzedFields
): OverviewGridRowModel[] => {
  const analyzedFieldsMap = Object.entries(analyzedFields);
  return analyzedFieldsMap.map(([schemaId, properties], index) => ({
    id: index,
    fieldId: schemaId,
    queues: properties.queues?.values,
    metadata: properties.metadata?.values,
  }));
};

export const useMemoOverviewFields = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
): GridRowsProp => {
  const schemasWithOccurences = useMemoAddOccurrencesInSchemas(
    flatSchemasWithQueues
  );
  return useMemo(() => {
    if (flatSchemasWithQueues.length) {
      const analyzedFields = getAnalyzedFields(schemasWithOccurences);

      return transformFieldsToRows(analyzedFields);
    }
    return [];
  }, [flatSchemasWithQueues, schemasWithOccurences]);
};
