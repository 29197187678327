import { Typography } from '@rossum/ui/material';
import { VerticalContainer } from './VerticalContainer';

type SidebarItemLabelProps = {
  label: string;
  sublabel?: string;
  variant?: 'primary' | 'secondary';
  isRequired?: boolean;
};

export const SidebarItemLabel = ({
  label,
  sublabel,
  variant = 'primary',
  isRequired = false,
}: SidebarItemLabelProps) => {
  const labelNode = (
    <Typography
      variant="body2"
      color="text.secondary"
      whiteSpace="pre-wrap"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      {label}
      {isRequired ? (
        <Typography color="error" component="span">
          *
        </Typography>
      ) : null}
    </Typography>
  );

  const sublabelNode = (
    <Typography
      variant="caption"
      color="text.secondary"
      whiteSpace="pre-wrap"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      {sublabel}
    </Typography>
  );

  return (
    <VerticalContainer
      dense
      sx={{
        opacity: theme =>
          variant === 'secondary' ? theme.palette.action.disabledOpacity : 1,
      }}
    >
      {labelNode}
      {sublabel ? sublabelNode : null}
    </VerticalContainer>
  );
};
