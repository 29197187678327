import { Button, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import * as animationData from './animations/empty_state.json';

type Props = {
  onReset: () => void;
};

export const ErrorPage = ({ onReset }: Props) => {
  const intl = useIntl();

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh',
        gap: 2,
      }}
    >
      <Lottie
        loop
        play
        animationData={animationData}
        style={{
          width: '8vw',
          height: '8vw',
        }}
      />
      <Typography variant="h5" sx={{ color: 'text.secondary', my: 2 }}>
        {intl.formatMessage({
          id: 'containers.allDocuments.emptyState.isError.title',
        })}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
        {intl.formatMessage({
          id: 'containers.allDocuments.emptyState.isError.text',
        })}
      </Typography>

      <Button onClick={onReset} variant="contained" color="secondary">
        {intl.formatMessage({
          id: 'containers.allDocuments.emptyState.isError.button',
        })}
      </Button>
    </Stack>
  );
};
