import { RuleTemplate } from '../models/ruleTemplate';
import { ruleTemplateSchema } from '../models/ruleTemplate.schema';
import { ruleTemplateCreatePayloadSchema } from './create.schema';

export type RuleTemplateCreatePayload = Partial<RuleTemplate>;

export const create = (payload: RuleTemplateCreatePayload) => {
  return {
    endpoint: `/rule_templates`,
    method: 'POST',
    responseSchema: ruleTemplateSchema,
    payloadSchema: ruleTemplateCreatePayloadSchema,
    payload,
  } as const;
};
