import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../dialog-title/DialogTitle';

type DeleteConfirmationDialogProps = {
  title: ReactNode;
  description?: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
};

export const DeleteConfirmationDialog = ({
  title,
  description,
  onConfirm,
  onCancel,
  open,
}: DeleteConfirmationDialogProps) => {
  const intl = useIntl();
  return (
    <Dialog
      data-cy="delete-confirmation-dialog"
      open={open}
      onClose={() => onCancel()}
      sx={{ transition: 'smooth' }}
      PaperProps={{
        sx: { width: 480, minHeight: 213, position: 'fixed' },
        elevation: 2,
      }}
    >
      <>
        <DialogTitle title={title} onClose={() => onCancel()} />
        {description ? (
          <DialogContent sx={{ mx: 4, mt: 4, p: 0 }}>
            <Typography variant="body2">{description}</Typography>
          </DialogContent>
        ) : null}
        <DialogActions sx={{ pb: 4, pt: 2.5, mr: 4, pr: 0 }}>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            data-cy="delete-confirmation-dialog-cancel-btn"
            onClick={() => onCancel()}
          >
            {intl.formatMessage({
              id: 'components.deleteConfirmationDialog.cancel',
            })}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            data-cy="delete-confirmation-dialog-confirm-btn"
            onClick={() => onConfirm()}
          >
            {intl.formatMessage({
              id: 'components.deleteConfirmationDialog.confirm',
            })}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
