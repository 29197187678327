import { endpoints, withSideload } from '@rossum/api-client';
import {
  Annotation,
  AnnotationListSideload,
} from '@rossum/api-client/annotations';
import { ListResponse } from '@rossum/api-client/utils';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import {
  DataGridConditionModel,
  encodeMqlQuery,
} from '../../../document-list-base/mql/mql';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../../../pricing/utils';

const DEFAULT_ORDERING = '-created_at';
const SEARCH_ANNOTATIONS_QUERY_KEY = 'search-annotations';

type SearchAnnotationsProps = {
  query: { pageSize: number };
  filtering: DataGridConditionModel;
  uniqKey: string | null;
  sideloadRelations?: true;
};

type AttachmentsAnnotationListWithSideload = ListResponse<Annotation> &
  // These are always present
  Pick<AnnotationListSideload, 'documents' | 'modifiers'> &
  // These are present if relevant
  Partial<Pick<AnnotationListSideload, 'relations' | 'childRelations'>>;

export const useFilteredAnnotations = ({
  query,
  filtering: filteringOriginal,
  uniqKey,
  sideloadRelations,
}: SearchAnnotationsProps) => {
  const filtering = {
    ...filteringOriginal,
    items: [...filteringOriginal.items, ACCESSIBLE_ANNOTATIONS_FILTER_ITEM],
  };

  const mqlQuery = encodeMqlQuery(filtering);

  const querySuffix = filtering.items.map(
    ({ field, operator }) => `${uniqKey}-${field}-${operator}`
  );

  return useQuery<AttachmentsAnnotationListWithSideload>({
    queryKey: [SEARCH_ANNOTATIONS_QUERY_KEY, querySuffix],

    queryFn: () =>
      api.request(
        withSideload(
          endpoints.annotations.search(
            { ...query, ordering: DEFAULT_ORDERING },
            { query: mqlQuery }
          ),
          {
            modifiers: true,
            documents: true,
            relations: sideloadRelations,
          }
        )
      ),

    enabled: !!uniqKey,
  });
};
