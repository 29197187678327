import { AxiosResponse } from 'axios';
import { MessageResponse } from 'libs/mdh-api-client/src';

export const transformNameIntoId = (name: string) => {
  try {
    return btoa(name);
  } catch {
    return undefined;
  }
};

export const transformIdIntoName = (name: string) => {
  try {
    return atob(name);
  } catch {
    return undefined;
  }
};

export const removeIdColumnKeys = (array: string[]) =>
  array.filter(s => s !== 'id' && s !== '_id');

export const getTaskIdFromResponse = (
  response: AxiosResponse<MessageResponse>
) => {
  return response.headers.location
    ? response.headers.location.split('/').pop() ?? null
    : null;
};
