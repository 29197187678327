import { DetailDrawer } from '@rossum/ui';
import { Button, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { ReactNode, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

type PreviewDrawerProps = {
  onClose: () => void;
  open: boolean;
  title: string;
  subtitle?: string;
  content: ReactNode;
  elevation?: number;
  actions?: ReactNode;
};

export const PreviewDrawer = ({
  onClose,
  open,
  title,
  subtitle,
  content,
  elevation,
  actions,
}: PreviewDrawerProps) => {
  const intl = useIntl();

  const handleCloseEscPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleCloseEscPress);

    return () => {
      document.removeEventListener('keydown', handleCloseEscPress);
    };
  }, [handleCloseEscPress]);

  return (
    <DetailDrawer
      PaperProps={{
        elevation: elevation ?? 4,
        sx: { maxWidth: '95%' },
      }}
      title={
        <Stack sx={{ maxWidth: 350 }}>
          <Tooltip title={title} enterNextDelay={500}>
            <Typography noWrap sx={{ textOverflow: 'ellipsis' }} variant="h5">
              {title}
            </Typography>
          </Tooltip>
          <Typography variant="h6" color="text.disabled">
            {subtitle}
          </Typography>
        </Stack>
      }
      open={open}
      onClose={() => onClose()}
    >
      <Stack
        padding={3}
        height="100%"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack sx={{ flex: '1 1 100%', overflowY: 'scroll' }}>{content}</Stack>
        <Stack sx={{ flex: '0 0 auto' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {actions || (
              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                data-cy="enum-options-drawer-cancel-btn"
              >
                {intl.formatMessage({
                  id: 'features.fieldManager.overview.ui.previewDrawer.button.close',
                })}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </DetailDrawer>
  );
};
