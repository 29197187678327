import { Url } from '@rossum/api-client';
import {
  SchemaAction,
  SchemaRule,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { LinearProgress, Stack } from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridNoRowsOverlay,
  GridPaginationModel,
} from '@rossum/ui/x-data-grid-pro';
import { merge } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { range } from 'remeda';
import {
  commonDataGridStyles,
  virtualScrollerOnResizeStyles,
} from '../../../ui/data-grid/styles';
import { CustomNoRowsOverlay } from './components/CustomNoRowsOverlay';
import { useCalculatePreview } from './hooks/useCalculatePreview';
import { useCalculateRulesPreview } from './hooks/useCalculateRulesPreview';
import { useColumns } from './hooks/useColumns';
import { useUpdateColumWidth } from './hooks/useColumnWidths';
import {
  DEFAULT_PAGE_SIZE,
  useGetAnnotationsForPreview,
} from './hooks/useGetAnnotationsForPreview';
import {
  actionsColumn,
  conditionsColumn,
  documentsColumn,
  globals,
} from './rows/helpers';
import { useRows } from './rows/useRows';

const PAGE_SIZES = [DEFAULT_PAGE_SIZE, 10, 15, 20];

// Use lodash instead of remeda for performance reasons
const gridStyles = merge(
  { ...commonDataGridStyles, ...virtualScrollerOnResizeStyles },
  {
    // fix cropped empty NoRowsOverlay
    [`.${gridClasses.virtualScroller}`]: {
      minHeight: 105,
    },
  }
);

export type FormulaPreviewGridProps = {
  queueUrls: Url[];
  currentFormulaId: string;
  schemaForPreview: SchemaSection[];
  schemaRules?: Partial<SchemaRule>[];
  type: 'rule' | 'formula';
  disabled?: boolean;
  renderAction?: (action: SchemaAction) => ReactNode;
};

export const FormulaPreviewGrid = ({
  queueUrls,
  currentFormulaId,
  schemaForPreview,
  schemaRules,
  type,
  disabled,
  renderAction,
}: FormulaPreviewGridProps) => {
  const intl = useIntl();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  });

  const annotationsQuery = useGetAnnotationsForPreview(
    queueUrls,
    paginationModel.pageSize
  );

  const annotationUrls = useMemo(() => {
    return (
      annotationsQuery.data?.pages.flatMap(page =>
        page.results.map(({ url }) => url)
      ) ?? []
    );
  }, [annotationsQuery.data]);

  const evaluationQueriesFormula = useCalculatePreview(
    annotationUrls,
    schemaForPreview,
    disabled || type === 'rule'
  );

  const evaluationQueriesRules = useCalculateRulesPreview(
    annotationUrls,
    schemaForPreview,
    disabled || type === 'formula',
    schemaRules
  );

  const evaluationQueries =
    type === 'formula' ? evaluationQueriesFormula : evaluationQueriesRules;

  const columns = useColumns({
    currentFormulaId,
    schemaForPreview,
    type,
    renderAction,
  });

  const pageData = useMemo(
    () => annotationsQuery.data?.pages[paginationModel.page],
    [annotationsQuery.data?.pages, paginationModel.page]
  );

  const rows = useRows({
    pageSize: paginationModel.pageSize,
    columns,
    annotations: pageData?.results,
    documents: pageData?.documents,
    evaluationQueriesFormula:
      type === 'formula' ? evaluationQueriesFormula : undefined,
    evaluationQueriesRules:
      type === 'rule' ? evaluationQueriesRules : undefined,
  });

  const isEvaluating = evaluationQueries.some(
    query => query.isLoading && query.isFetching
  );

  const paginationNextIconButtonProps = useMemo(
    () => ({
      sx: annotationsQuery.isFetching
        ? {
            color: 'text.disabled',
            pointerEvents: 'none',
          }
        : {},
    }),
    [annotationsQuery.isFetching]
  );

  const updateColumnWidth = useUpdateColumWidth();

  return (
    <Stack sx={{ background: theme => theme.palette.background.paper, mt: 2 }}>
      <DataGridPro
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: isEvaluating ? CustomNoRowsOverlay : GridNoRowsOverlay,
        }}
        slotProps={{
          noRowsOverlay: {
            sx: { backgroundColor: 'inherit' },
          },
          loadingOverlay: {
            color: 'secondary',
          },
          pagination: {
            nextIconButtonProps: paginationNextIconButtonProps,
            sx: {
              // hide pagination when there are no rows
              display: rows.length === 0 ? 'none' : 'inherit',
              [`> * p`]: {
                margin: 0,
              },
            },
          },
        }}
        localeText={
          rows.length === 0
            ? {
                noRowsLabel: intl.formatMessage({
                  id: 'features.queueSettings.fields.formulaPreview.noAnnotations',
                }),
              }
            : undefined
        }
        autoHeight
        loading={
          annotationsQuery.isFetching ||
          evaluationQueries.some(query => query.isFetching)
        }
        columns={columns}
        rows={
          pageData && isEvaluating
            ? range(0, pageData.results.length).map(i => ({
                id: `fake-row-${i}`,
                annotationUrl: `${i}`,
                [documentsColumn]: undefined,
                [globals]: {
                  actions: undefined,
                  messages: undefined,
                  automationBlockers: undefined,
                  conditions: undefined,
                },
                isFetching: true,
              }))
            : rows
        }
        onColumnWidthChange={updateColumnWidth}
        density="compact"
        disableColumnMenu
        disableColumnReorder
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={newModel => {
          setPaginationModel(newModel);

          if (
            annotationsQuery.data &&
            annotationsQuery.data.pages.length <= newModel.page
          ) {
            annotationsQuery.fetchNextPage();
          }
        }}
        rowCount={annotationsQuery.data?.pages[0]?.pagination.total ?? 0}
        pageSizeOptions={PAGE_SIZES}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: [documentsColumn, conditionsColumn, actionsColumn],
          },
        }}
        sx={gridStyles}
      />
    </Stack>
  );
};
