import { get } from 'lodash';
import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import { combineLatest, from } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { collectionSize } from '../../../constants/values';
import { usersPath } from '../../../containers/Users/helpers';
import { errorHandler } from '../../../lib/api';
import { isNonEmptyObject } from '../../../lib/typeGuards';
import { getIDFromString, parse } from '../../../lib/url';
import { User } from '../../../types/user';
import { fetchGroupsFulfilled } from '../groups/actions';
import { getRoleId } from '../groups/helpers';
import { updateQueueDetailFulfilled } from '../queues/actions';
import { enterUser } from '../ui/actions';
import {
  pathnameContainsQueueUsers,
  pathnameContainsUsers,
  pathnameRequiresUsers,
} from '../user/helpers';
import { isActionOf, locationChange, makeEpic } from '../utils';
import {
  clearUsers,
  deleteUserFulfilled,
  fetchActiveUsersCount,
  fetchActiveUsersCountFulfilled,
  fetchAdminsCountFulfilled,
  fetchAllUsers,
  fetchAllUsersFulfilled,
  fetchUserDetail,
  fetchUserDetailFulfilled,
  fetchUsers,
  fetchUsersFulfilled,
  updateUserDetail,
  updateUserDetailFulfilled,
} from './actions';
import { FetchUsersFulfilledPayload } from './types';

const fetchUsersEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchUsers)),
    pluck('meta', 'query'),
    map(
      query =>
        query || parse(get(state$.value, ['router', 'location', 'search']))
    ),
    filter(isNonEmptyObject),
    switchMap(query =>
      authGetJSON$<FetchUsersFulfilledPayload>(`${apiUrl}/users`, {
        query: { ...query, deleted: false },
      }).pipe(
        takeUntil(action$.pipe(filter(isActionOf(enterUser)))),
        map(fetchUsersFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const fetchUsersOnQueryChangeEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(locationChange)),
    map(({ payload: { location } }) => location),
    distinctUntilChanged(
      ({ search }, { search: _search }) => search === _search
    ),
    filter(location => pathnameContainsUsers(location)),
    map(({ search }) => parse(search)),
    filter(isNonEmptyObject),
    map(query => fetchUsers(query))
  )
);

const fetchAdminsCountEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  combineLatest(
    action$.pipe(filter(isActionOf(fetchUsersFulfilled))),
    action$.pipe(filter(isActionOf(fetchGroupsFulfilled)))
  ).pipe(
    map(() => get(state$.value, ['router', 'location'])),
    filter(({ pathname }) => pathnameContainsQueueUsers(pathname)),
    map(({ search }) => parse(search)),
    map(query => ({
      ...query,
      groups: getRoleId('admin', state$.value.groups),
      pageSize: 1,
      page: 1,
      fields: 'id',
      deleted: false,
    })),
    switchMap(query =>
      authGetJSON$<FetchUsersFulfilledPayload>(`${apiUrl}/users`, {
        query,
      }).pipe(
        map(response => fetchAdminsCountFulfilled(response.pagination.total)),
        catchError(errorHandler)
      )
    )
  )
);

const clearUsersOnExitUsersEpic = makeEpic(action$ =>
  action$.pipe(
    filter(
      isActionOf([
        fetchUsersFulfilled,
        fetchUserDetailFulfilled,
        fetchAllUsersFulfilled,
      ])
    ),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(locationChange)),
        pluck('payload', 'location'),
        filter(location => !pathnameRequiresUsers(location)),
        first(),
        mapTo(clearUsers())
      )
    )
  )
);

const fetchActiveUsersCountEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchActiveUsersCount)),
    mergeMap(() =>
      authGetJSON$<FetchUsersFulfilledPayload>(`${apiUrl}/users`, {
        query: { isActive: true, pageSize: 1, fields: 'id', deleted: false },
      }).pipe(
        map(response =>
          fetchActiveUsersCountFulfilled(response.pagination.total)
        ),
        catchError(errorHandler)
      )
    )
  )
);

const startFetchingActiveUsersEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([fetchUsers, updateUserDetailFulfilled, deleteUserFulfilled])
    ),
    filter(() => state$.value.router.location.pathname === usersPath()),
    mapTo(fetchActiveUsersCount())
  )
);

const assureUserDetailEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(enterUser)),
    map(() => state$.value),
    map(
      ({
        router: {
          location: { pathname },
        },
        users,
      }) => [getIDFromString(pathname), users.list] as const
    ),
    filter(([id, users]) => !users.find(user => user.id === id)),
    map(([userId]) => userId && fetchUserDetail(userId))
  )
);

const fetchUserDetailEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchUserDetail)),
    pluck('payload'),
    mergeMap(id =>
      authGetJSON$<User>(`${apiUrl}/users/${id}`).pipe(
        map(fetchUserDetailFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const updateUserDetailEpic = makeEpic((action$, _, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateUserDetail)),
    mergeMap(({ meta: { id, withMessage = false }, payload }) =>
      authPatch$<User>(`${apiUrl}/users/${id}`, payload).pipe(
        map(user => updateUserDetailFulfilled(user, { withMessage })),
        catchError(errorHandler)
      )
    )
  )
);

const updateUsersOnQueueUpdateEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateQueueDetailFulfilled)),
    filter(() => pathnameContainsUsers(state$.value.router.location)),
    mapTo(fetchUsers({}))
  )
);

const fetchAllUsersEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchAllUsers)),
    pluck('payload', 'query'),
    mergeMap((query = {}) =>
      authGetJSON$<FetchUsersFulfilledPayload>(`${apiUrl}/users`, {
        query: { ...query, pageSize: collectionSize, deleted: false },
      }).pipe(
        mergeMap(({ results, pagination: { next, previous } }) =>
          from(
            R.pipe(
              [
                next && fetchAllUsers(parse(next.split('?')[1])),
                fetchAllUsersFulfilled(results, !previous, !next),
              ],
              R.filter(R.isTruthy)
            )
          )
        ),
        catchError(errorHandler)
      )
    )
  )
);

export default combineEpics(
  assureUserDetailEpic,
  clearUsersOnExitUsersEpic,
  fetchActiveUsersCountEpic,
  fetchUserDetailEpic,
  fetchUsersEpic,
  fetchUsersOnQueryChangeEpic,
  updateUserDetailEpic,
  startFetchingActiveUsersEpic,
  updateUsersOnQueueUpdateEpic,
  fetchAdminsCountEpic,
  fetchAllUsersEpic
);
