import { Stack, Tooltip, Typography } from '@rossum/ui/material';
import { format, formatDistanceToNow } from 'date-fns';
import { ReactNode } from 'react';

const TOOLTIP_DELAY = 600;

type CardInfoProps = {
  date: string | undefined;
  fromTooltip: string | undefined;
  fromText: string | undefined;
  appendix?: ReactNode;
};

export const CardInfo = ({
  appendix,
  fromTooltip,
  fromText,
  date,
}: CardInfoProps) => {
  const formattedDate = date
    ? format(new Date(date), 'ccc, hh:mm aa, dd/MM/yyyy')
    : '';

  const distanceToNow = date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip
        enterDelay={TOOLTIP_DELAY}
        title={`${formattedDate || ''}`}
        arrow
        placement="bottom"
        sx={{ flexShrink: 0 }}
        componentsProps={{
          tooltip: {
            onClickCapture: e => e.stopPropagation(),
          },
        }}
      >
        <Typography variant="caption" color="text.secondary" fontWeight="bold">
          {distanceToNow}
        </Typography>
      </Tooltip>
      {fromText && (
        <Tooltip
          enterDelay={TOOLTIP_DELAY}
          title={fromTooltip ?? ''}
          arrow
          placement="bottom"
          componentsProps={{
            tooltip: {
              onClickCapture: e => e.stopPropagation(),
            },
          }}
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              marginLeft: '3px !important',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {fromText}
          </Typography>
        </Tooltip>
      )}

      {appendix}
    </Stack>
  );
};
