import { useTheme } from '@rossum/ui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { SIDEBAR_WIDTH } from '../../../constants/values';
import styles from './style.module.sass';

type Props = {
  children: ReactNode;
  dynamic?: boolean;
  hidden?: boolean;
  direction?: 'default' | 'reverse';
  id?: string;
};

const ControlBar = ({
  children,
  dynamic = false,
  hidden = false,
  direction = 'default',
  id,
}: Props) => {
  const theme = useTheme();

  return (
    <div
      id={id}
      className={clsx(
        styles.ControlBar,
        dynamic && styles.ControlBarDynamic,
        hidden && styles.ControlBarHidden,
        direction === 'reverse' && styles.ControlBarReverse
      )}
      style={{
        color: theme.palette.text.primary,
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
      }}
    >
      {children}
    </div>
  );
};

export default ControlBar;
