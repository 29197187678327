import { Chip, Stack } from '@rossum/ui/material';
import { gridClasses, GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { isArray } from 'remeda';
import { text } from '../../../constants';
import { GridRowModel } from '../../rows/rowTypes';

type ChipsCellProps = GridRenderCellParams<
  GridRowModel,
  Array<string> | string
>;

const ChipsCell = ({ value }: ChipsCellProps) => {
  if (isArray(value) && value?.length) {
    return (
      <Stack spacing={1} direction="row">
        {value?.map(chipLabel => (
          // CHECK Changed from tiny
          <Chip key={chipLabel} label={chipLabel} />
        ))}
      </Stack>
    );
  }
  return (
    <div className={gridClasses.cellContent}>
      {value === text.cellNotEditable ? value : null}
    </div>
  );
};

export const renderChipsCell = (params: ChipsCellProps) => (
  <ChipsCell {...params} />
);
