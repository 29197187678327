import { Url } from '../../utils/codecUtils';
import { hookSchema } from '../models/hook.schema';
import { FunctionConfig, WebhookConfig } from '../models/hookConfig';
import { ExtensionSource } from '../models/hookUtils';
import { createHookPayloadSchema } from './create.schema';

export type CreateHookPayload = CreateWebHookPayload | CreateFunctionPayload;

export type CreateWebHookPayload = {
  name: string;
  description?: string;
  type: 'webhook';
  queues: Array<Url>;
  events: Array<Url>;
  config: WebhookConfig;
  sideload?: Array<string>;
  tokenOwner?: Url | null;
  secretsSchema?: Record<string, unknown>;
  settingsSchema?: Record<string, unknown>;
  settings?: Record<string, unknown>;
  secrets?: Record<string, unknown>;
  extensionSource?: ExtensionSource;
};

export type CreateFunctionPayload = {
  name: string;
  description?: string;
  type: 'function';
  queues: Url[];
  events: string[];
  config: FunctionConfig;
  sideload?: Array<string>;
  runAfter?: Url[] | [];
  tokenOwner?: Url | null;
  secretsSchema?: Record<string, unknown>;
  settingsSchema?: Record<string, unknown>;
  settings?: Record<string, unknown>;
  secrets?: Record<string, unknown>;
  extensionSource?: ExtensionSource;
};

export const create = (payload: CreateHookPayload) => {
  return {
    endpoint: `/hooks`,
    method: 'POST',
    responseSchema: hookSchema,
    payloadSchema: createHookPayloadSchema,
    payload,
  } as const;
};
