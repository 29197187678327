import { Grid, GridSize, Stack, styled, Typography } from '@mui/material';
import React from 'react';

export type FieldLabelLayout =
  | 'none'
  | 'floating'
  | 'horizontal'
  | 'vertical'
  | { label: GridSize; field: GridSize };

export type FieldLabelProps = {
  htmlFor?: React.LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  labelProps?: Omit<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    'htmlFor' | 'children'
  >;
  layout?: FieldLabelLayout;
  required?: boolean;
  label: React.ReactNode;
  endAdornment?: React.ReactNode;
  /** Field to be labeled */
  children?: React.ReactNode;
};

const RequiredIndicator = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: theme.spacing(0.25),
}));

const StyledLabel = styled('label')(() => ({
  display: 'block',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: 500,
}));

const RenderedLabel = ({
  htmlFor,
  labelProps,
  required,
  label,
  endAdornment,
}: Omit<FieldLabelProps, 'layout' | 'children'>) => (
  <StyledLabel htmlFor={htmlFor} {...labelProps}>
    {/* For now adornment will always be to the left of label even if it wraps. It is
    a question for UX whether it should rather be part of Typography and wrap with it */}
    <Stack component="span" direction="row" spacing={1} alignItems="center">
      <StyledTypography>
        {label}
        {required && <RequiredIndicator>*</RequiredIndicator>}
      </StyledTypography>
      {endAdornment}
    </Stack>
  </StyledLabel>
);

export const FieldLabel = ({
  htmlFor,
  labelProps,
  layout = 'vertical',
  required,
  label,
  endAdornment,
  children,
}: FieldLabelProps) => {
  if (layout === 'none' || layout === 'floating') {
    return <>{children}</>;
  }

  if (layout === 'horizontal' || layout === 'vertical') {
    return (
      <Stack
        direction={layout === 'horizontal' ? 'row' : 'column'}
        spacing={layout === 'horizontal' ? 2 : 0}
      >
        <RenderedLabel
          htmlFor={htmlFor}
          labelProps={labelProps}
          required={required}
          label={label}
          endAdornment={endAdornment}
        />
        {children}
      </Stack>
    );
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={layout.label} display="flex" alignItems="center">
        <RenderedLabel
          htmlFor={htmlFor}
          labelProps={labelProps}
          required={required}
          label={label}
          endAdornment={endAdornment}
        />
      </Grid>
      <Grid item xs={layout.field}>
        {children}
      </Grid>
    </Grid>
  );
};
