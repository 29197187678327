import {
  EmailSendPayloadAddress,
  EmailTemplate,
  EmailTemplateType,
  EmailTemplateValues,
} from '@rossum/api-client/emailTemplates';
import { useCallback } from 'react';
import { unique } from 'remeda';
import { Email } from '../../../api-client';
import { AttachmentT } from '../../EmailAttachment/helpers';
import { EmailResponseFormMode, SendEmailFormData } from '../types';
import { composeFwdMessage } from '../utils';
import { useRenderTemplate } from './useRenderTemplate';

type UseGetFormValuesParams = {
  mode: EmailResponseFormMode;
  rootEmail: Email | undefined;
  availableAttachments: AttachmentT[];
  contextAnnotationUrl: string | undefined;
};

type EmailResponseFormModeConfig = {
  replyToSender?: boolean;
  canAttachDocuments?: boolean;
  forwardRootEmail?: boolean;
  defaultTemplateType?: EmailTemplateType;
  keyword?: string;
};

export const EDITOR_STATE_EMPTY_VALUE = '<p></p>\n';

const configs: Record<EmailResponseFormMode, EmailResponseFormModeConfig> = {
  reply: {
    replyToSender: true,
    keyword: 'Re:',
  },
  forward: {
    canAttachDocuments: true,
    forwardRootEmail: true,
    keyword: 'Fwd:',
  },
  rejectEmail: {
    replyToSender: true,
    canAttachDocuments: true,
    defaultTemplateType: 'email_with_no_processable_attachments',
  },
  forwardDocument: {
    forwardRootEmail: true,
    canAttachDocuments: true,
    keyword: 'Fwd:',
  },
  rejectDocument: {
    replyToSender: true,
    canAttachDocuments: true,
    defaultTemplateType: 'rejection_default',
  },
  postponeDocument: {
    replyToSender: true,
    canAttachDocuments: true,
    keyword: 'Postponed document:',
  },
};

const createEmailFormData = ({
  mode,
  rootEmail,
  availableAttachments,
  templateValues,
}: {
  mode: EmailResponseFormMode;
  rootEmail: Email | undefined;
  availableAttachments: AttachmentT[];
  templateValues: EmailTemplateValues | undefined;
}): SendEmailFormData => {
  const config = configs[mode];
  const replyToSender =
    rootEmail && config.replyToSender ? [rootEmail.from.email] : [];

  const mapAddresses = (
    emails: EmailSendPayloadAddress[] | undefined
  ): string[] => emails?.map(({ email }) => email) ?? [];

  const fwdMessage =
    rootEmail && config.forwardRootEmail
      ? composeFwdMessage(rootEmail)
      : undefined;

  const firstAttachment: AttachmentT | undefined = availableAttachments[0];
  const rootEmailSubject = rootEmail ? rootEmail.subject : undefined;
  const filename = firstAttachment
    ? firstAttachment.document.originalFileName
    : undefined;
  const attachments = availableAttachments
    .filter(attachment => attachment.annotation)
    .map(attachment => attachment.document.url);
  const subject = `${config.keyword} ${rootEmailSubject ?? filename}`;

  const message =
    templateValues?.message ?? fwdMessage ?? EDITOR_STATE_EMPTY_VALUE;

  return {
    // We should be able to remove `replyToSender` after B/E release.
    to: unique([...mapAddresses(templateValues?.to), ...replyToSender]),
    cc: mapAddresses(templateValues?.cc),
    bcc: mapAddresses(templateValues?.bcc),
    message,
    subject: templateValues?.subject ?? subject,
    attachments: config.canAttachDocuments ? attachments : [],
  };
};

export const useGetFormValues = ({
  mode,
  rootEmail,
  availableAttachments,
  contextAnnotationUrl,
}: UseGetFormValuesParams) => {
  const { mutateAsync: renderTemplateAsync, isLoading } = useRenderTemplate();

  const getFormValues = useCallback(
    async (template: EmailTemplate | undefined) => {
      const documentList = availableAttachments.map(
        attachment => attachment.document.url
      );

      // This determines how {{annotation.content.value.XX}} variables are replaced.
      // Usually, use the annotation from the context.
      // On email thread list, use just the list of annotations from root email.

      const annotationList = contextAnnotationUrl
        ? [contextAnnotationUrl]
        : availableAttachments.flatMap(attachment =>
            attachment.annotation ? [attachment.annotation.url] : []
          );

      const templateValues = template
        ? await renderTemplateAsync({
            templateId: template.id,
            payload: {
              documentList,
              parentEmail: rootEmail?.url,
              annotationList,
            },
          })
        : undefined;

      return {
        templateValues: createEmailFormData({
          mode,
          rootEmail,
          availableAttachments,
          templateValues,
        }),
      };
    },
    [
      availableAttachments,
      contextAnnotationUrl,
      mode,
      renderTemplateAsync,
      rootEmail,
    ]
  );

  return { getFormValues, isLoading };
};
