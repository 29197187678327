import { HttpError } from '@rossum/api-client/errors';
import { ErrorEvent, EventHint } from '@sentry/react';
import { AxiosError } from 'axios';
import { AjaxError } from 'rxjs/ajax';
import {
  getApiErrorRequestMethod,
  getApiErrorStatusCode,
  getApiErrorUrl,
  inferEndpointGroup,
} from '../utils';

const getFingerprint = (error: unknown): string[] => {
  if (
    error instanceof HttpError ||
    error instanceof AjaxError ||
    error instanceof AxiosError
  ) {
    const endpointGroup = inferEndpointGroup(getApiErrorUrl(error));
    const code = getApiErrorStatusCode(error);
    const method = getApiErrorRequestMethod(error);

    return [endpointGroup, `${code}`, method];
  }

  return [];
};

// Force Sentry to group by endpoint and status code instead of just all HttpErrors together
// or randomly by stack trace
// this should make every combinations of [endpoint, code] into its own issue group
export const withFingerprint = ([event, hint]: [ErrorEvent, EventHint]): [
  ErrorEvent,
  EventHint,
] => {
  const { originalException } = hint;

  const fingerprint = getFingerprint(originalException);

  return [
    {
      ...event,
      fingerprint: ['{{ default }}', ...fingerprint],
    },
    hint,
  ];
};
