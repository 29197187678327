import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { RuleTemplate } from '@rossum/api-client/rule-templates';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import pLimit from 'p-limit';
import { pick } from 'remeda';
import { useApiClient } from '../../../lib/apiClient';
import { RULES_QUERY_KEY } from '../../rules/hooks/rulesQueryKeys';

export const useBulkUpdateRules = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  const concurrencyLimit = 5;
  const limit = pLimit(concurrencyLimit);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RULES_QUERY_KEY],
      });
    },
    mutationFn: async ({
      template,
      schemasToAdd,
      rulesToSync,
    }: {
      template: RuleTemplate;
      schemasToAdd: Url[];
      rulesToSync: Url[];
    }) => {
      const templateValues = pick(template, [
        'name',
        'description',
        'triggerCondition',
        'ruleActions',
      ]);

      return Promise.all([
        ...schemasToAdd.map(schema =>
          limit(() =>
            api.request(
              endpoints.rules.create({
                ...templateValues,
                schema,
                ruleTemplate: template.url,
                // When adding a new rule to the schema, lets enable synchronization by default
                synchronizedFromTemplate: true,
                enabled: true,
              })
            )
          )
        ),
        ...rulesToSync.map(rule =>
          limit(() =>
            api.request(
              endpoints.rules.patch(getIDFromUrl(rule), {
                ...templateValues,
                ruleTemplate: template.url,
              })
            )
          )
        ),
      ]);
    },
  });
};
