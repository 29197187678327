import { createSelector } from 'reselect';
import {
  isTabSelected,
  Tab,
  tabs,
} from '../../../components/NavBar/navigationStructure';
import { changesSavedRegex } from '../../../containers/ChangesSaved/constants';
import { State } from '../../../types/state';

const pathnameSelector = (state: State) => state.router.location.pathname;

export const addValueActiveSelector = (state: State) =>
  state.router.location.hash === '#addValue';

export const currentTabSelector = createSelector(
  (state: State) => state.router.location,
  (location): Tab | undefined => tabs.find(t => isTabSelected(t, location))
);

export const hideAppBarSelector = createSelector(
  pathnameSelector,
  state => !state.user.username,
  (pathName, userNotFetched) => {
    if (userNotFetched) return true;
    if (pathName.match(/^\/embedded\/document/)) return true;
    if (pathName.match(/^\/document\//)) return true;
    if (pathName.match(/^\/document-v2\//)) return true;
    if (pathName.match(/^\/queues\/\d+\/settings\/schema/)) return true;
    if (pathName.match(/^\/extensions\/my-extensions\/\d+\/code/)) return true;
    if (pathName.match(/\/organizationLoading/)) return true;
    if (pathName.match(/\/timeExpired/)) return true;
    if (pathName.match(changesSavedRegex)) return true;
    if (pathName.match(/\/maintenance/)) return true;
    if (pathName.match(/^\/requests\//)) return true;

    return false;
  }
);
