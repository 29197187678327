import {
  IconBan,
  IconCheck,
  IconClock,
  IconCustomFolderMove,
  IconDownload,
  IconLink,
  IconMailShare,
  IconPlayerSkipBack,
  IconPlayerSkipForward,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import { createSvgIcon, SvgIcon, SvgIconProps } from '@rossum/ui/material';
import { SidebarFooterActionId } from './utils';

const actionIcons: Record<SidebarFooterActionId, typeof SvgIcon> = {
  postpone: createSvgIcon(<IconClock />, 'IconClock'),
  reject: createSvgIcon(<IconBan />, 'IconBan'),
  bypass: createSvgIcon(<IconCheck />, 'IconCheck'),
  move: createSvgIcon(<IconCustomFolderMove />, 'IconCustomFolderMove'),
  link: createSvgIcon(<IconLink />, 'IconLink'),
  email: createSvgIcon(<IconMailShare />, 'IconMailShare'),
  download: createSvgIcon(<IconDownload />, 'IconDownload'),
  delete: createSvgIcon(<IconTrash />, 'IconTrash'),
  next: createSvgIcon(<IconPlayerSkipForward />, 'IconPlayerSkipForward'),
  previous: createSvgIcon(<IconPlayerSkipBack />, 'IconPlayerSkipBack'),
};

type SidebarFooterActionIconProps = SvgIconProps & {
  actionId: SidebarFooterActionId;
};

export const SidebarFooterActionIcon = ({
  actionId,
  ...rest
}: SidebarFooterActionIconProps) => {
  const Icon = actionIcons[actionId];

  return <Icon {...rest} />;
};
