import { Url } from '@rossum/api-client';
import { Annotation } from '@rossum/api-client/annotations';
import { EvaluateFormulasResponse } from '@rossum/api-client/internal';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import { UseQueryResult } from '@tanstack/react-query';
import * as R from 'remeda';
import { useCalculatePreview } from '../hooks/useCalculatePreview';
import {
  columnsPrefix,
  constructRows,
  getDatapointsData,
  RowModel,
} from './helpers';

type TransformDataToRowsFormulaParams = {
  columns: Array<GridColDef<RowModel>>;
  annotations: Annotation[];
  documentsMap: Record<string, string>;
  evaluationQueriesFormula: ReturnType<typeof useCalculatePreview>;
};

export const transformDataToRowsFormula = ({
  columns,
  annotations,
  documentsMap,
  evaluationQueriesFormula,
}: TransformDataToRowsFormulaParams): Array<RowModel> => {
  const evaluationQueriesByAnnotationUrl: Record<
    Url,
    UseQueryResult<EvaluateFormulasResponse>
  > = Object.assign(
    {},
    ...evaluationQueriesFormula.flatMap(query =>
      query.data ? [{ [query.data.annotationUrl]: query }] : []
    )
  );

  return annotations.flatMap<RowModel>(({ url: annotationUrl, document }) => {
    const datapointColumns = columns.filter(c =>
      c.field.startsWith(`${columnsPrefix}.`)
    );

    const evaluationQuery = evaluationQueriesByAnnotationUrl[annotationUrl];
    const evaluationData = evaluationQuery?.data;

    const messagesMap = evaluationData
      ? R.groupBy(evaluationData.messages, message => message.id ?? undefined)
      : {};
    const automationBlockersMap = evaluationData
      ? R.groupBy(evaluationData.automationBlockers, ab => ab.id)
      : {};

    const datapointsData = getDatapointsData(
      evaluationQuery?.data?.annotationContent,
      datapointColumns,
      columnsPrefix
    );

    return evaluationData
      ? constructRows({
          annotationUrl,
          document,
          documentsMap,
          datapointColumns,
          datapointsData,
          evaluationData,
          isFetching: evaluationQuery?.isFetching ?? false,
          messagesMap,
          automationBlockersMap,
        })
      : [];
  });
};
