import { Stack, Typography } from '@rossum/ui/material';
import { Document } from '../../../types/document';
import { TruncatedName } from '../../../ui/truncated-name/TruncatedName';

type DocumentInformationProps = {
  document: Document | undefined;
  annotationsInStack: number;
  currentAnnotationIndex: number;
};

export const DocumentInformation = ({
  document,
  annotationsInStack,
  currentAnnotationIndex,
}: DocumentInformationProps) => {
  const displayNavigationStack =
    currentAnnotationIndex !== -1 && annotationsInStack > 1;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: 'baseline', overflow: 'hidden' }}
    >
      <Typography
        variant="h6"
        component="span"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 450,
          flexShrink: 1,
        }}
      >
        <TruncatedName name={document?.originalFileName ?? ''} />
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          alignItems: 'baseline',
          // flexBasis: '40%',
          flexGrow: 1,
        }}
      >
        {displayNavigationStack ? (
          <Typography variant="body2" color="text.secondary">
            <strong>{currentAnnotationIndex + 1}</strong>/{annotationsInStack}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
