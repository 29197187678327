import { NotInterested } from '@rossum/ui/icons';
import { Badge, Chip, Stack, Typography } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { EmailMultiple } from '../../../../components/icons/EmailMultiple';
import { TransformedEmailThread } from '../helpers';

const SubjectCell = ({
  row: emailThread,
}: GridRenderCellParams<TransformedEmailThread>) => {
  const intl = useIntl();

  const {
    subject: emailSubject,
    meta: { hasNewReplies, hasReplies, rejected },
  } = emailThread;

  const subject =
    emailSubject && emailSubject.length > 0
      ? emailSubject
      : intl.formatMessage({ id: 'containers.documents.emails.noSubject' });

  return (
    <Stack
      gap={1.5}
      direction="row"
      color="text.secondary"
      alignItems="center"
      width={1}
    >
      {rejected && (
        <Chip
          color="error"
          icon={<NotInterested fontSize="small" />}
          label={intl.formatMessage({
            id: 'containers.documents.emails.rejected',
          })}
          data-cy="email-rejected-tag"
        />
      )}

      {hasReplies && (
        <Badge color="primary" variant="dot" invisible={!hasNewReplies}>
          <EmailMultiple fontSize="small" />
        </Badge>
      )}

      <Typography
        component="div"
        variant="body2"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {subject}
      </Typography>
    </Stack>
  );
};

export { SubjectCell };
