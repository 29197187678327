import { createReactComponent } from '@tabler/icons-react';

export const IconCustomBoltPause = createReactComponent(
  'outline',
  'customBoltPause',
  'CustomBoltPause',
  [
    [
      'path',
      {
        key: 'iconCustomBoltPause-path',
        d: 'M17 17V22M21 17V22M16.8 13.025L19 10H13V3L5 14H10V21L13 18',
      },
    ],
  ]
);
