import { Queue } from '@rossum/api-client/queues';
import { State } from '../../../types/state';
import { Workspace } from '../../../types/workspace';

export const workspacesSelector = (state: State) => state.workspaces.list;

export const findWorkspaceByQueue = (
  workspaces: Array<Workspace>,
  { url }: Queue
): Workspace | null | undefined =>
  workspaces.find(({ queues }) => queues.includes(url));
