import { createReactComponent } from '@tabler/icons-react';

export const IconCustomSuggest = createReactComponent(
  'outline',
  'customSuggest',
  'CustomSuggest',
  [
    [
      'path',
      {
        key: 'customSuggest-path',
        d: 'M4 15.36V14.96C4 14.6949 4.10536 14.4405 4.29314 14.2527C4.48093 14.0649 4.73531 13.9596 5.00034 13.9596H6M11 13.9596H13M18 13.9596H19C19.2647 13.9596 19.5191 14.0649 19.7069 14.2527C19.8946 14.4405 20 14.6949 20 14.96V15.36M20 18.6V19C20 19.2647 19.8946 19.5191 19.7069 19.7069C19.5191 19.8946 19.2647 20 19 20H18M13 20H11M6 20H5C4.73531 20 4.48093 19.8946 4.29314 19.7069C4.10536 19.5191 4 19.2647 4 19V18.6M4 6C4 5.44961 4.21068 4.92292 4.58554 4.54806C4.9604 4.1732 5.48709 3.96252 6.03748 3.96252H18.0375C18.5879 3.96252 19.1146 4.1732 19.4895 4.54806C19.8643 4.92292 20.075 5.44961 20.075 6V8C20.075 8.55039 19.8643 9.07708 19.4895 9.45194C19.1146 9.8268 18.5879 10.0375 18.0375 10.0375H6.03748C5.48709 10.0375 4.9604 9.8268 4.58554 9.45194C4.21068 9.07708 4 8.55039 4 8V6Z',
      },
    ],
  ]
);
