import update from 'immutability-helper';
import {
  compact,
  filter,
  find,
  findIndex,
  first,
  get,
  includes,
  isEqual,
  last,
  omit,
  pick,
  sortBy,
} from 'lodash';
import { firstBy, groupBy, pipe } from 'remeda';
import { BboxParams } from '../../../lib/spaceConvertor';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { ID } from '../../../types/basic';
import {
  AddSuggestedOperation,
  AnyDatapointData,
  AnyDatapointDataST,
  Children,
  DatapointsST,
  Grid,
  isAddSuggestedOperation,
  MultivalueDatapointData,
  MultivalueDatapointDataST,
  NestedDatapointST,
  SidebarDatapointST,
  SimpleDatapointData,
  SimpleDatapointDataST,
  SuggestedOperation,
  TupleDatapointData,
  TupleDatapointDataST,
} from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';
import { isBboxVisible } from '../schema/helpers';
import { findLastIndex, insertArrayInArray, updateInArray } from '../utils';
import { findDatapointById } from './navigation/findDatapointIndex';
import { findNextColumnInTuple } from './navigation/navigationStop';
import { BoundedDatapoint, ValidateFulfilledPayload } from './types';

export const isDatapointHidden = (
  dataHidden?: boolean,
  schemaHidden?: boolean
) => dataHidden ?? schemaHidden ?? false;

const isNestedDatapointValidated = (
  { children }: TupleDatapointDataST | MultivalueDatapointDataST,
  datapoints: AnyDatapointDataST[],
  humanValidation?: boolean
): boolean =>
  humanValidation
    ? !children.some(
        ({ index }) => !isDatapointHumanValidated(datapoints)(datapoints[index])
      )
    : !children.some(
        ({ index }) => !isDatapointValidated(datapoints)(datapoints[index])
      );

const isSimpleDatapointValidated = (
  { validationSources, hidden, schema }: SimpleDatapointDataST,
  humanValidation?: boolean
): boolean =>
  humanValidation
    ? includes(validationSources, 'human') ||
      isDatapointHidden(hidden, schema && schema.hidden)
    : isDatapointHidden(hidden, schema && schema.hidden) ||
      !!validationSources?.length;

export const isCategoryNested = (category: string) =>
  includes(['section', 'multivalue', 'tuple'], category);

export const isDatapointValidated =
  (datapoints: Array<AnyDatapointDataST>) =>
  (datapoint: AnyDatapointDataST): boolean =>
    datapoint
      ? isCategoryNested(datapoint.category)
        ? isNestedDatapointValidated(
            datapoint as TupleDatapointDataST | MultivalueDatapointDataST,
            datapoints
          )
        : isSimpleDatapointValidated(datapoint as SimpleDatapointDataST)
      : false;

export const isDatapointHumanValidated =
  (datapoints: Array<AnyDatapointDataST>) =>
  (datapoint: AnyDatapointDataST): boolean =>
    datapoint
      ? isCategoryNested(datapoint.category)
        ? isNestedDatapointValidated(
            datapoint as TupleDatapointDataST | MultivalueDatapointDataST,
            datapoints,
            true
          )
        : isSimpleDatapointValidated(datapoint as SimpleDatapointDataST, true)
      : false;

const isDatapointSimpleMultivalue = (
  schemas?: AnyDatapointSchema[],
  datapointSchema?: AnyDatapointSchema
) => {
  if (datapointSchema && datapointSchema.children && schemas) {
    const {
      category,
      children: [child],
    } = datapointSchema;

    return (
      category === 'multivalue' &&
      get(find(schemas, { id: child }), 'category') !== 'tuple'
    );
  }

  return false;
};

export const addMeta = (
  datapoints: AnyDatapointDataST[],
  schemas?: AnyDatapointSchema[],
  addedSchemas?: AnyDatapointSchema[]
) =>
  datapoints.map(datapoint => {
    const { meta, schema: _schema, ...rest } = datapoint;

    const schema =
      _schema ||
      (addedSchemas &&
        addedSchemas.find(
          (addedSchema: AnyDatapointSchema) =>
            addedSchema?.id === datapoint.schemaId
        ));

    if (datapoint.meta.sidebarDatapoint) {
      const isHumanValidated = isDatapointHumanValidated(datapoints)(datapoint);
      return {
        ...rest,
        schema,
        meta: {
          ...meta,
          isValidated:
            isHumanValidated || isDatapointValidated(datapoints)(datapoint),
          isHumanValidated,
          isSimpleMultivalue: isDatapointSimpleMultivalue(
            schemas,
            datapoint.schema
          ),
        },
      };
    }

    return {
      ...rest,
      schema,
      meta,
    };
  });

export const flattenDatapoints = (
  datapoints: { content: AnyDatapointData[] },
  complexLineItemsEnabled: boolean,
  startIndex = 0,
  parentId: number | undefined = undefined
) => {
  const flattened = datapoints.content.reduce(
    (acc, tree) => {
      const flattenNode = flattenDatapointTree(
        tree,
        complexLineItemsEnabled,
        parentId,
        false,
        startIndex + acc.datapoints.length
      );

      return {
        datapoints: [...acc.datapoints, ...flattenNode.datapoints],
        meta: [...acc.meta, { id: flattenNode.id, index: flattenNode.index }],
      };
    },
    { datapoints: [] as AnyDatapointDataST[], meta: [] as Children[] }
  );

  return {
    ...datapoints,
    content: flattened.datapoints,
    newChilds: flattened.meta,
  };
};

const addChildrenToParent =
  (parentIndex: number, newChilds: Children[]) =>
  (datapoints: AnyDatapointDataST[]) =>
    updateInArray(
      datapoints,
      parentIndex,
      (parentDatapoint: AnyDatapointDataST) =>
        'children' in parentDatapoint
          ? {
              ...parentDatapoint,
              children: [...parentDatapoint.children, ...newChilds],
            }
          : parentDatapoint
    );

export const addNewDatapoints = ({
  payload,
  meta,
  complexLineItemsEnabled,
}: {
  payload: {
    newDatapoints: Array<AnyDatapointData>;
    datapoints: Array<AnyDatapointDataST>;
    schemas: Array<AnyDatapointSchema>;
  };
  meta: {
    parentId: ID;
    parentIndex: number;
  };
  complexLineItemsEnabled: boolean;
}): Array<AnyDatapointDataST> => {
  const { newDatapoints, datapoints, schemas } = payload;
  const { parentId, parentIndex } = meta;

  const lastIndex = findLastIndex(datapoints, parentIndex);
  const { content, newChilds } = flattenDatapoints(
    { content: newDatapoints },
    complexLineItemsEnabled,
    lastIndex + 1,
    parentId
  );

  const datapointsWithSchemas = content.map(
    (datapoint): AnyDatapointDataST => ({
      ...datapoint,
      schema: find(schemas, { id: datapoint.schemaId }),
    })
  );

  return pipe(
    datapoints,
    updateIndexes({
      startIndex: lastIndex + 1,
      amount: content.length,
    }),
    addChildrenToParent(parentIndex, newChilds),
    insertArrayInArray(lastIndex + 1, datapointsWithSchemas)
  );
};

export const getSidebarDatapoint =
  (datapoints: AnyDatapointDataST[]) =>
  (datapoint: AnyDatapointDataST): SidebarDatapointST | undefined => {
    if (datapoint.meta.sidebarDatapoint) return datapoint as SidebarDatapointST;

    const targetDatapoint = datapoints.find(
      ({ id }) => datapoint.meta.parentId === id
    );

    if (!targetDatapoint) {
      return undefined;
    }

    return getSidebarDatapoint(datapoints)(targetDatapoint);
  };

export const getDataForBatchUpdate = (datapoint: SimpleDatapointDataST) => {
  return {
    content: pick(datapoint.content, ['value', 'page', 'position']),
    validationSources: ['human'],
  };
};

export const isSimpleDatapoint = (
  datapoint: AnyDatapointDataST
): datapoint is SimpleDatapointDataST =>
  !!datapoint &&
  datapoint.category === 'datapoint' &&
  !!datapoint.content &&
  datapoint.content.position !== undefined;

export const isTableDatapoint = (
  datapoint: AnyDatapointDataST
): datapoint is MultivalueDatapointDataST =>
  datapoint &&
  datapoint.category === 'multivalue' &&
  !datapoint.meta.isSimpleMultivalue &&
  !!datapoint.grid;

const isNested = (
  datapoint: AnyDatapointDataST
): datapoint is NestedDatapointST =>
  datapoint && (datapoint as NestedDatapointST).children !== undefined;

export const removeAllChildren = (
  state: DatapointsST,
  parentIndex: number,
  options: { removeParent?: boolean } = {}
) => {
  const parent = state.content[parentIndex];
  const rowsAmounts: number = get(parent, 'children.length', 0);

  const columnsAmount: number = get(
    state.content[parentIndex + 1],
    'children.length',
    0
  );

  const startIndex = parentIndex + (options.removeParent ? 0 : 1);

  const endIndex =
    startIndex +
    rowsAmounts +
    rowsAmounts * columnsAmount +
    (options.removeParent ? 1 : 0);

  return update(
    update(
      update(state, {
        content: {
          [parentIndex]: {
            children: {
              $set: [],
            },
            meta: {
              isValidated: {
                $set: true,
              },
              isHumanValidated: {
                $set: true,
              },
            },
          },
        },
      }),
      {
        content: datapoints =>
          datapoints.slice(0, startIndex).concat(datapoints.slice(endIndex)),
      }
    ),
    {
      content: datapoints =>
        updateIndexes({
          startIndex,
          amount: startIndex - endIndex,
          removeChildId: options.removeParent ? parent.id : undefined,
        })(datapoints),
    }
  );
};

const updateChildrenIndexes =
  ({
    startIndex,
    amount,
    removeChildId,
  }: {
    startIndex: number;
    amount: number;
    removeChildId?: ID;
  }) =>
  (children: Array<Children>) =>
    children.reduce<Array<Children>>((acc, child) => {
      if (removeChildId && removeChildId === child.id) return acc;

      return [
        ...acc,
        {
          ...child,
          index: child.index < startIndex ? child.index : child.index + amount,
        },
      ];
    }, []);

export const updateIndexes =
  ({
    startIndex,
    amount,
    removeChildId,
  }: {
    startIndex: number;
    amount: number;
    removeChildId?: ID;
  }) =>
  (datapoints: Array<AnyDatapointDataST>): Array<AnyDatapointDataST> =>
    datapoints
      .slice(0, startIndex)
      .map(datapoint =>
        isNested(datapoint)
          ? {
              ...datapoint,
              children: updateChildrenIndexes({
                startIndex,
                amount,
                removeChildId,
              })(datapoint.children),
            }
          : datapoint
      )
      .concat(
        datapoints.slice(startIndex).map(datapoint =>
          isNested(datapoint)
            ? {
                ...datapoint,
                meta: {
                  ...datapoint.meta,
                  index: datapoint.meta.index + amount,
                },
                children: updateChildrenIndexes({
                  startIndex,
                  amount,
                  removeChildId,
                })(datapoint.children),
              }
            : {
                ...datapoint,
                meta: {
                  ...datapoint.meta,
                  index: datapoint.meta.index + amount,
                },
              }
        )
      );

// TODO the name doesn't seem to be correct - it handles non-multivalue datapoints
export const updateSimpleDatapoint = (
  state: DatapointsST,
  _datapoint: AnyDatapointData
) =>
  update(state, {
    content: {
      [findIndex(state.content, { id: _datapoint.id })]: datapoint =>
        ({
          ...datapoint,
          ..._datapoint,
        }) as AnyDatapointDataST,
    },
  });

export const fillPathTail = (
  datapoints: Array<AnyDatapointDataST>,
  path: Array<number>
): Array<number> => {
  const datapoint = find(datapoints, { id: last(path) });

  if (datapoint && isNested(datapoint) && datapoint.children.length) {
    const nextSelectableId =
      datapoint.category === 'tuple'
        ? findNextColumnInTuple({
            datapoints,
            currentTuple: datapoint,
            // we want to start at index 0 and we add 1 to index inside findNextColumnInTuple
            startIndex: -1,
            isForward: true,
          })
        : datapoint.children[0].id;

    return nextSelectableId
      ? fillPathTail(datapoints, [...path, nextSelectableId])
      : // in case of all unselectable children, we will not select any datapoint and stay on multivalue
        path;
  }

  return path;
};

export const fillPathHead = (
  datapoints: Array<AnyDatapointDataST>,
  path: Array<number>
): Array<number> => {
  const parentId = get(find(datapoints, { id: Number(first(path)) }), [
    'meta',
    'parentId',
  ]);

  return parentId ? fillPathHead(datapoints, [parentId, ...path]) : path;
};

interface FlattenDatapointTree {
  (
    tree: AnyDatapointData,
    complexLineItemsEnabled: boolean,
    parentId?: ID,
    sidebarDatapoint?: boolean,
    index?: number
  ): { datapoints: AnyDatapointDataST[]; index: number; id: number };
}

interface DatapointsAndChildren {
  datapoints: AnyDatapointDataST[];
  children: Children[];
}

const isMultivalueTree = (
  tree: AnyDatapointData
): tree is MultivalueDatapointData =>
  (tree as MultivalueDatapointData).category === 'multivalue';

const sortChildren = (
  children: TupleDatapointData[],
  grid: { parts: Grid[] }
) => {
  const sortedTupleIds = grid.parts.reduce(
    (acc, grid) => ({
      ...acc,
      ...grid.rows.reduce(
        (rows, { tupleId }, rowIndex) => ({
          ...rows,
          [`${tupleId}`]: { page: grid.page, rowIndex },
        }),
        {}
      ),
    }),
    {}
  );

  return children
    .slice()
    .sort(
      (a, b) =>
        get(sortedTupleIds, [a.id, 'page'], Infinity) -
          get(sortedTupleIds, [b.id, 'page'], Infinity) ||
        get(sortedTupleIds, [a.id, 'rowIndex'], Infinity) -
          get(sortedTupleIds, [b.id, 'rowIndex'], Infinity)
    );
};

export const flattenDatapointTree: FlattenDatapointTree = (
  tree,
  complexLineItemsEnabled,
  parentId = undefined,
  sidebarDatapoint = false,
  index = 0
) => {
  const meta = { parentId, sidebarDatapoint, index };

  if ('children' in tree) {
    const sortedChildren =
      isMultivalueTree(tree) && tree.grid && !complexLineItemsEnabled
        ? sortChildren(tree.children as TupleDatapointData[], tree.grid)
        : tree.children;

    const { datapoints, children } =
      sortedChildren.reduce<DatapointsAndChildren>(
        (acc, child) => {
          const childTree = flattenDatapointTree(
            child,
            complexLineItemsEnabled,
            tree.id,
            tree.category === 'section',
            acc.datapoints.length + index + 1
          );

          return {
            datapoints: [...acc.datapoints, ...childTree.datapoints],
            children: [
              ...acc.children,
              { id: childTree.id, index: childTree.index },
            ],
          };
        },
        { datapoints: [], children: [] }
      );

    const datapoint = {
      ...tree,
      meta,
      children,
    };

    return {
      datapoints: [datapoint, ...datapoints],
      index,
      id: tree.id,
    };
  }

  return {
    datapoints: [{ ...tree, meta }],
    index,
    id: tree.id,
  };
};

export const performSchemaIdSwaps =
  (
    operations: Array<{
      source: SimpleDatapointDataST;
      target: SimpleDatapointDataST;
    }>,
    schemaMap: ReadonlyMap<string, AnyDatapointSchema>
  ) =>
  (datapoints: AnyDatapointDataST[]) => {
    const changedDatapointsMap = Object.fromEntries(
      operations.flatMap(op => [
        [
          op.source.id,
          {
            ...op.source,
            content: {
              ...op.source.content,
              value: schemaMap.get(op.source.schemaId)?.defaultValue ?? '',
              position: null,
              page: null,
            },
          },
        ],
        [
          op.target.id,
          {
            ...op.target,
            content: {
              ...op.target.content,
              ...pick(op.source.content, ['value', 'position', 'page']),
            },
          },
        ],
      ])
    );

    return datapoints.map(dp => changedDatapointsMap[dp.id] ?? dp);
  };

export type TupleCoordinates = {
  page: number | null;
  top?: number;
  left?: number;
};

export const getTupleCoordsForTuple = (
  // The types are a bit messy here, but it's determined by the caller
  tupleChildren: Array<AnyDatapointDataST> | Array<AnyDatapointData>
): TupleCoordinates | null => {
  if (!tupleChildren || !tupleChildren.length) {
    return null;
  }

  const coordinates = compact(
    tupleChildren.map(dp =>
      dp.category !== 'datapoint' || !dp.content?.page || !dp.content.position
        ? null
        : {
            page: dp.content.page,
            top: dp.content.position[1],
            left: dp.content.position[0],
          }
    )
  );

  if (!coordinates.length) {
    // If it's a ghost row, try to use ocrPosition for sorting (it was determined when creating the ghost row)
    const firstChild = tupleChildren[0];
    if (
      firstChild.category === 'datapoint' &&
      firstChild.content?.ocrPosition &&
      firstChild.content?.page
    ) {
      return {
        page: firstChild.content.page,
        top: firstChild.content.ocrPosition[1],
        left: Number.MAX_SAFE_INTEGER,
      };
    }

    return {
      page: Number.MAX_SAFE_INTEGER,
      top: Number.MAX_SAFE_INTEGER,
      left: 0,
    };
  }

  // Sort by the number of datapoints on the page (descending),
  // if there are multiple pages with the same number of datapoints, take the first one
  const pageWithMajorityOfDatapoints = Number(
    sortBy(Object.entries(groupBy(coordinates, c => c.page)), [
      ([, v]) => -1 * v.length,
      ([page]) => Number(page),
    ])[0][0]
  );

  const relevantDatapoints = coordinates.filter(
    c => c.page === pageWithMajorityOfDatapoints
  );

  const top = firstBy(relevantDatapoints, dp => dp.top)?.top;
  const left = firstBy(relevantDatapoints, dp => dp.left)?.left;
  return typeof top === 'number' && typeof left === 'number'
    ? { page: pageWithMajorityOfDatapoints, top, left }
    : null;
};

let virtualDatapointId = 0;

const getVirtualDatapointId = () => {
  virtualDatapointId -= 1;
  return virtualDatapointId;
};

export const isVirtualDatapoint = (id: number) => id < 0;

export const createVirtualDatapoint = (
  operation: Pick<SimpleDatapointData, 'schemaId' | 'validationSources'> & {
    content: Partial<SimpleDatapointData['content']>;
  },
  options: AddVirtualDatapointsOptions
): SimpleDatapointData => {
  return {
    id:
      options.idToKeep?.suggestedDatapoint === operation
        ? options.idToKeep.datapointId
        : getVirtualDatapointId(),
    category: 'datapoint',
    content: { value: '', page: null, position: null, ...operation.content },
    schemaId: operation.schemaId,
    validationSources: operation.validationSources,
    hidden: false,
  };
};

export const createVirtualTuple = (
  operation: AddSuggestedOperation,
  schemaId: string,
  options: AddVirtualDatapointsOptions
): TupleDatapointData => {
  return {
    id:
      options.idToKeep &&
      operation.value.some(v => options.idToKeep?.suggestedDatapoint === v)
        ? options.idToKeep.tupleId
        : getVirtualDatapointId(),
    category: 'tuple',
    schemaId,
    hidden: false,
    children: operation.value.map(dp => createVirtualDatapoint(dp, options)),
  };
};

const createSimpleDatapointDataFromState = (
  state: State,
  datapointIndex: number
): SimpleDatapointData | null => {
  const datapoint = state.datapoints.content[datapointIndex];

  if (datapoint.category !== 'datapoint') {
    return null;
  }

  return { ...omit(datapoint, ['schema', 'meta']) };
};

const createTupleDatapointDataFromState = (
  state: State,
  tupleIndex: number
): TupleDatapointData | null => {
  const tuple = state.datapoints.content[tupleIndex];

  if (tuple.category !== 'tuple') {
    return null;
  }

  return {
    ...omit(tuple, ['schema', 'meta']),
    children: compact(
      tuple.children.map(child =>
        createSimpleDatapointDataFromState(state, child.index)
      )
    ),
  };
};

type AddVirtualDatapointsOptions = {
  // When replacing old suggestions with new ones,
  // we might need to keep the virtual ID of the selected datapoint,
  // so that it won't cause any issues with validation dialog (disappearing and appearing again)
  idToKeep?: {
    suggestedDatapoint: AddSuggestedOperation['value'][number];
    datapointId: number;
    tupleId: number;
  };
};

export const shouldShowGhostRow = (
  tupleIds: Array<number>,
  cliEnabled: boolean
) => cliEnabled && tupleIds.length === 1 && isVirtualDatapoint(tupleIds[0]);

const getTupleType = (tuple: TupleDatapointData) => {
  const isVirtual = isVirtualDatapoint(tuple.id);
  const hasContent = tuple.children.some(
    dp =>
      dp.category === 'datapoint' &&
      dp.content &&
      (dp.content.position || dp.content.value)
  );
  const hasOcrPosition = tuple.children.some(
    dp => dp.category === 'datapoint' && dp.content && dp.content.ocrPosition
  );

  return { isVirtual, hasContent, hasOcrPosition };
};

export const isGhostRow = (tuple: TupleDatapointData) => {
  const type = getTupleType(tuple);
  return type.isVirtual && !type.hasContent && !type.hasOcrPosition;
};

export const isInsertedLine = (tuple: TupleDatapointData) => {
  const type = getTupleType(tuple);
  return type.isVirtual && !type.hasContent && type.hasOcrPosition;
};

export const isSuggestedTuple = (tuple: TupleDatapointData) => {
  const type = getTupleType(tuple);
  return type.isVirtual && type.hasContent;
};

export const getTupleDatapointData = (
  tuple: TupleDatapointDataST,
  allDatapoints: AnyDatapointDataST[]
): TupleDatapointData => {
  return {
    ...tuple,
    children: allDatapoints
      .filter(isSimpleDatapoint)
      .filter(dp => dp.meta.parentId === tuple.id),
  };
};

export const isBoundedDatapoint = (
  dp: AnyDatapointDataST
): dp is BoundedDatapoint =>
  isSimpleDatapoint(dp) &&
  isBboxVisible(dp.schema) &&
  dp.content?.page !== null &&
  dp.content?.position !== null;

// if `suggestedOperations` return a line item, add that line item into `updatedDatapoints`
export const addVirtualDatapointsForSuggestedOperations = (
  response: ValidateFulfilledPayload,
  state: State,
  options: AddVirtualDatapointsOptions,
  selectedDatapointId?: number
): ValidateFulfilledPayload => {
  const {
    updatedDatapoints,
    suggestedOperations: responseSuggestedOperations,
  } = response;

  const suggestedOperations = compact(
    responseSuggestedOperations.map(op => {
      const isReplaceOperationForSelectedDatapoint =
        op.id === selectedDatapointId && op.op === 'replace';

      // For the selected datapoint - don't overwrite existing replace operations/ignore a new one
      return isReplaceOperationForSelectedDatapoint
        ? state.datapoints.suggestedOperations[op.id]
        : op;
    })
  );

  const lineItems = filter(state.datapoints.content, isTableDatapoint);

  const relevantOperations = groupBy(
    suggestedOperations.filter(isAddSuggestedOperation),
    op => op.id
  );

  const changedUpdatedDatapoints: AnyDatapointData[] = lineItems.flatMap(dp => {
    const multivalueId = dp.id;

    const operations = relevantOperations[dp.id] ?? [];

    const existingMultivalue = find(
      lineItems,
      (dp): dp is MultivalueDatapointDataST => dp.id === Number(multivalueId)
    );

    if (!existingMultivalue) return [];

    const childrenSchemaId = existingMultivalue.schema?.children?.[0];

    return [
      {
        ...omit(existingMultivalue, ['schema', 'meta']),
        children: sortBy(
          [
            ...compact(
              existingMultivalue.children.map(child =>
                createTupleDatapointDataFromState(state, child.index)
              )
            ).filter(
              // Ghost rows won't be replaced by suggestions,
              // so we want to keep them.
              // We also don't want to clear any operations when adding ghost row
              tuple =>
                (response.suggestedOperations.length === 1 &&
                  response.suggestedOperations[0].op === 'add' &&
                  response.suggestedOperations[0].value[0].content
                    .ocrPosition) ||
                !isVirtualDatapoint(tuple.id) ||
                isGhostRow(tuple)
            ),
            ...(childrenSchemaId
              ? operations.map(op =>
                  createVirtualTuple(op, childrenSchemaId, options)
                )
              : []),
          ],
          [
            tuple => getTupleCoordsForTuple(tuple.children)?.page,
            tuple => getTupleCoordsForTuple(tuple.children)?.top,
          ]
        ),
      },
    ];
  });

  return {
    ...response,
    suggestedOperations,
    // TODO post MVP: This should probably be closer to deep merge than replace
    // if another extension actually changes the multivalue, we now ignore it
    updatedDatapoints: [...updatedDatapoints, ...changedUpdatedDatapoints],
  };
};

export const isNonEmptyDatapoint = (dp: AnyDatapointDataST | undefined) => {
  return dp && dp.category === 'datapoint' && dp.content && dp.content.value;
};

export const isDatapointWithoutPosition = (
  dp: AnyDatapointDataST | undefined
) => {
  return !!(
    dp &&
    dp.category === 'datapoint' &&
    dp.content &&
    dp.content.value &&
    dp.content.position === null
  );
};

// get IDs of all simple datapoints which are descendants of `dp` (could be tuple, multivalue, simplemultivalue etc)
// perf?
export const getDescendendDatapointIds = (
  allDatapoints: AnyDatapointDataST[],
  dp: AnyDatapointDataST
): number[] => {
  // simple case
  if (dp.category === 'datapoint') {
    return [dp.id];
  }

  // for tuples, only map to children IDs
  if (dp.category === 'tuple') {
    return dp.children.map(c => c.id);
  }

  // for sections/multivalues, recurse and flatmap
  return dp.children.flatMap(c =>
    getDescendendDatapointIds(allDatapoints, allDatapoints[c.index])
  );
};

export const calculateInsertedTuplePosition = (
  datapoints: AnyDatapointDataST[],
  tuple: TupleDatapointDataST
): { page: number; position: BboxParams } | undefined => {
  const tupleChildren = datapoints
    .filter(isSimpleDatapoint)
    .filter(datapoint => datapoint.meta.parentId === tuple.id);

  const page = Math.max(
    ...tupleChildren
      .map(child => child.content?.page)
      .filter(isNotNullOrUndefined)
  );

  const getTuplePositions = (tuple: TupleDatapointDataST) => {
    if (isInsertedLine(getTupleDatapointData(tuple, datapoints))) {
      return tupleChildren
        .map(child => child.content?.ocrPosition)
        .filter(isNotNullOrUndefined)
        .slice(0, 1);
    }

    const boundedChildren = tupleChildren.filter(isBoundedDatapoint);
    const children = boundedChildren.filter(c => c.content.page === page);
    return children.map(c => c.content.position);
  };

  const positions = getTuplePositions(tuple);

  if (positions.length === 0) {
    return undefined;
  }

  const span = [
    Math.min(...positions.map(p => p[0])),
    Math.min(...positions.map(p => p[1])),
    Math.max(...positions.map(p => p[2])),
    Math.max(...positions.map(p => p[3])),
  ] as const;

  const position: BboxParams = [span[0], span[1] + 1, span[2], span[1] + 1];

  return { page, position };
};

export const isRelevantSuggestedOperation = (
  state: DatapointsST,
  operation: SuggestedOperation
) => {
  // we do not store `add` operations
  if (operation.op === 'add') return false;

  const datapoint = findDatapointById(state.content, operation.id);

  // operations are only valid for existing simple datapoints
  if (!datapoint || datapoint.category !== 'datapoint') return false;

  // we do not care about suggestions for hidden datapoints
  if (datapoint.schema?.hidden) {
    return false;
  }

  const datapointIsAlreadyNonEmpty = !!datapoint.content?.value;

  if (datapointIsAlreadyNonEmpty) {
    return false;
  }

  // we ignore operations which give no new information wrt page/position/value
  // so they don't clutter the UI
  const { page, position, value } = operation.value.content;

  const noChange =
    page === datapoint.content?.page &&
    isEqual(position, datapoint.content?.position) &&
    value === datapoint.content?.value;

  if (noChange) return false;

  return true;
};

export const getColumnLabelsWithPosition = (
  tuplesOnPage: (readonly [number, SimpleDatapointDataST[]])[],
  transposedTuples: SimpleDatapointDataST[][],
  schemaMap: ReadonlyMap<string, AnyDatapointSchema>,
  page: number
) =>
  tuplesOnPage.length
    ? compact(
        transposedTuples
          // only care about columns that:
          //  1. Are NOT hidden (assuming all columns have the same schema now === columnDatapoints[0].schemaId is enough)
          //  2. Have at least one box set
          .filter(
            columnDatapoints =>
              columnDatapoints.some(
                dp => !!dp?.content?.position && dp.content?.page === page
              ) && schemaMap.get(columnDatapoints[0].schemaId)?.hidden !== true
          )
          .map(columnDatapoints => {
            const top =
              firstBy(
                tuplesOnPage[0][1].filter(c => c?.content?.page === page),
                dp => dp.content?.position?.[1] ?? Infinity
              )?.content?.position?.[1] ?? 0;

            const left =
              firstBy(
                columnDatapoints.filter(c => c?.content?.page === page),
                dp => dp.content?.position?.[0] ?? Infinity
              )?.content?.position?.[0] ?? 0;

            return [
              // all columns have same schema now
              columnDatapoints[0].schemaId,
              {
                label: schemaMap.get(columnDatapoints[0].schemaId)?.label,
                // top is based on first row minimum top
                top,
                // left is minimum of all row lefts
                left,
              },
            ] as const;
          })
          .sort((a, b) => a[1].left - b[1].left)
      )
    : [];
