import update from 'immutability-helper';
import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { AnnotationState } from '../../../types/annotation';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { fetchNoteFulfilled } from '../notes/actions';
import { leaveValidation } from '../ui/actions';
import {
  clearAnnotationData,
  fetchAnnotationFulfilled,
  fetchSuggestedEditFulfilled,
  nextAnnotableAnnotation,
  nextAnnotation,
  refetchAnnotationEmailsFulfilled,
} from './actions';

const initialState: AnnotationState = {
  assignedAt: null,
  createdAt: null,
  content: null,
  document: null,
  duplicates: null,
  edit: null,
  email: null,
  emailThread: null,
  exportedAt: null,
  exportingAt: null,
  hasEmailThreadWithNewReplies: false,
  hasEmailThreadWithReplies: false,
  id: null,
  labels: [],
  metadata: {},
  modifiedAt: null,
  modifier: null,
  note: null,
  pages: [],
  queue: null,
  relatedEmails: [],
  relations: null,
  rirPollId: null,
  schema: null,
  sideloads: {
    automationBlockers: [],
    document: undefined,
    modifier: undefined,
    relations: [],
    queue: undefined,
    workspace: undefined,
    confirmedBy: undefined,
    exportedBy: undefined,
    pages: [],
    labels: [],
  },
  status: null,
  suggestedEdit: null,
  url: null,
  restrictedAccess: false,
};

const annotationReducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(clearAnnotationData):
    case getType(signOut):
    case getType(nextAnnotation):
    case getType(nextAnnotableAnnotation):
    case getType(fetchMembershipTokenFulfilled):
    case getType(leaveValidation):
      return initialState;

    case getType(fetchAnnotationFulfilled):
      return {
        ...action.payload.annotation,
        sideloads: { ...action.payload.sideload },
      };

    case getType(fetchSuggestedEditFulfilled):
      return update(state, { suggestedEdit: { $set: action.payload } });

    case getType(refetchAnnotationEmailsFulfilled):
      return update(state, {
        emailThread: { $set: action.payload.emailThread },
        relatedEmails: { $set: action.payload.relatedEmails },
      });

    case getType(fetchNoteFulfilled): {
      const note = R.findLast(
        action.payload.results,
        note => note.type === 'rejection'
      );

      return update(state, { note: { $set: note ?? null } });
    }

    default:
      return state;
  }
};

export default annotationReducer;
