import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { notify } from '../../toaster';
import { useDatasetContext } from '../context';
import { datasetSettingsPath, datasetsPath } from '../routes';
import { transformNameIntoId } from '../utils';
import { useCreateDataset } from './useCreateDataset';
import { useDeleteDataset } from './useDeleteDataset';
import { GET_DATASETS_QUERY_KEYS } from './useGetDatasets';

type Params = {
  oldName: string;
  newName: string;
  data: DSResultsResponseResultsItem[];
};
export const useRecreateDataset = () => {
  const intl = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutateAsync: createDataset } = useCreateDataset();
  const { mutateAsync: deleteDataset } = useDeleteDataset();

  const { registerTask } = useDatasetContext();

  return useMutation({
    mutationKey: ['@MDH', 'recreate-dataset'] as const,
    mutationFn: async ({ oldName, newName, data }: Params) => {
      const response = await deleteDataset({ datasetName: oldName });

      if (response.taskId)
        registerTask({
          taskId: response.taskId,
          datasetName: oldName,
          type: 'delete',
          onSuccess: () => {
            createDataset({ datasetName: newName, data }).then(res => {
              if (res.taskId)
                registerTask({
                  taskId: res.taskId,
                  type: 'create',
                  datasetName: newName,
                  onSuccess: () => {
                    const oldId = transformNameIntoId(oldName);
                    const urlIsExpired = oldId
                      ? history.location.pathname === datasetSettingsPath(oldId)
                      : false;

                    if (urlIsExpired) history.push(datasetsPath());

                    queryClient.invalidateQueries({
                      queryKey: GET_DATASETS_QUERY_KEYS,
                    });

                    notify.success({
                      title: intl.formatMessage({
                        id: 'features.datasets.context.taskNotification.recreate.success.title',
                      }),
                    });
                  },
                });
            });
          },
        });
    },
  });
};
