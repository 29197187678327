import { BillingHistory } from '@rossum/api-client/billing';
import { ErrorOutlineRounded } from '@rossum/ui/icons';
import { Chip, Paper, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import AuroraChip from '../../../ui/aurora/AuroraChip';
import { getColor, getPercentage } from '../helpers';
import { BillingPeriodProgress } from './BillingPeriodProgress';
type Props = {
  historyContent: BillingHistory;
};

type EngineUsageData = {
  title: string;
  used: number;
  purchased: number;
  isAurora?: boolean;
};

export const AiEnginesUsageProgress = ({ historyContent }: Props) => {
  const intl = useIntl();

  const {
    values: {
      extractedPagesWithLearning,
      extractedPagesWithoutLearning,
      purchasedExtractedPagesWithLearning,
      purchasedExtractedPagesWithoutLearning,
      splitPagesWithLearning,
      purchasedSplitPagesWithLearning,
      splitPagesWithoutLearning,
      purchasedSplitPagesWithoutLearning,
      ocrOnlyPages,
      purchasedOcrOnlyPages,
      purchasedDocuments,
      extractedDocumentsWithLearning,
      purchasedExtractedDocumentsWithLearning,
      extractedDocumentsWithoutLearning,
      purchasedExtractedDocumentsWithoutLearning,
      splitDocumentsWithLearning,
      splitDocumentsWithoutLearning,
      purchasedSplitDocumentsWithLearning,
      purchasedSplitDocumentsWithoutLearning,
      ocrOnlyDocuments,
      purchasedOcrOnlyDocuments,
    },
    beginDate,
    endDate,
  } = historyContent;

  const isContractBasedOnDocuments = purchasedDocuments !== 0;

  const unitKey = isContractBasedOnDocuments ? 'documents' : 'pages';

  const matchingSpendings: Array<EngineUsageData> = [
    {
      title: intl.formatMessage({
        id: `containers.billing.overview.aiEngines.${unitKey}.extracted`,
      }),
      used: isContractBasedOnDocuments
        ? extractedDocumentsWithLearning
        : extractedPagesWithLearning,
      purchased: isContractBasedOnDocuments
        ? purchasedExtractedDocumentsWithLearning
        : purchasedExtractedPagesWithLearning,
      isAurora: true,
    },
    {
      title: intl.formatMessage({
        id: `containers.billing.overview.aiEngines.${unitKey}.extracted`,
      }),
      used: isContractBasedOnDocuments
        ? extractedDocumentsWithoutLearning
        : extractedPagesWithoutLearning,
      purchased: isContractBasedOnDocuments
        ? purchasedExtractedDocumentsWithoutLearning
        : purchasedExtractedPagesWithoutLearning,
      isAurora: false,
    },
    {
      title: intl.formatMessage({
        id: `containers.billing.overview.aiEngines.${unitKey}.split`,
      }),
      used: isContractBasedOnDocuments
        ? splitDocumentsWithLearning
        : splitPagesWithLearning,
      purchased: isContractBasedOnDocuments
        ? purchasedSplitDocumentsWithLearning
        : purchasedSplitPagesWithLearning,
      isAurora: true,
    },
    {
      title: intl.formatMessage({
        id: `containers.billing.overview.aiEngines.${unitKey}.split`,
      }),
      used: isContractBasedOnDocuments
        ? splitDocumentsWithoutLearning
        : splitPagesWithoutLearning,
      purchased: isContractBasedOnDocuments
        ? purchasedSplitDocumentsWithoutLearning
        : purchasedSplitPagesWithoutLearning,
      isAurora: false,
    },
    {
      title: intl.formatMessage({
        id: `containers.billing.overview.aiEngines.${unitKey}.ocrOnly`,
      }),
      used: isContractBasedOnDocuments ? ocrOnlyDocuments : ocrOnlyPages,
      purchased: isContractBasedOnDocuments
        ? purchasedOcrOnlyDocuments
        : purchasedOcrOnlyPages,
    },
  ];

  return (
    <Stack spacing={3}>
      <Typography variant="h6">
        {intl.formatMessage({
          id: 'containers.billing.overview.aiEngines.title',
        })}
      </Typography>

      <Stack spacing={3} component={Paper} p={3}>
        {matchingSpendings.map(({ title, isAurora, used, purchased }) => {
          const usedPercentage = purchased !== 0 ? used / purchased : 0;

          return (
            <Stack key={`${title}${isAurora ? '-aurora' : ''}`} spacing={3}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignSelf="flex-start"
              >
                <Typography variant="h6">{title}</Typography>
                {isAurora !== undefined ? (
                  isAurora ? (
                    <AuroraChip />
                  ) : (
                    <Chip
                      label={intl.formatMessage({
                        id: 'components.engineCard.aurora.disabled',
                      })}
                    />
                  )
                ) : null}
              </Stack>

              <UsageInfo
                used={used}
                purchased={purchased}
                usedPercentage={usedPercentage}
                unitKey={unitKey}
              />

              <BillingPeriodProgress
                currentPeriod={{ beginDate, endDate }}
                usedPercentage={usedPercentage * 100}
              />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const UsageInfo = ({
  used,
  purchased,
  usedPercentage,
  unitKey,
}: {
  used: number;
  purchased: number;
  usedPercentage: number;
  unitKey: 'documents' | 'pages';
}) => {
  const intl = useIntl();

  const iconColor = getColor(usedPercentage * 100);
  const isOverLimit = usedPercentage > 1;

  const remainingPercentage = Math.abs(1 - usedPercentage);
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography variant="body1">
          {used} {`(${getPercentage(usedPercentage * 100)})`}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {intl.formatMessage({
            id: `containers.billing.overview.aiEngines.${unitKey}.used`,
          })}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        {isOverLimit ? (
          <ErrorOutlineRounded color={iconColor} fontSize="large" />
        ) : null}

        <Stack textAlign="right">
          <Typography variant="body1">
            {Math.abs(purchased - used)}{' '}
            {`(${getPercentage(remainingPercentage * 100)})`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {intl.formatMessage({
              id: `containers.billing.overview.aiEngines.${unitKey}.${isOverLimit ? 'overLimit' : 'remaining'}`,
            })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
