import { IconSettings } from '@rossum/ui/icons/tabler';
import {
  alpha,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
} from '@rossum/ui/material';
import {
  DataGridPro,
  GridRow,
  GridRowParams,
  GridRowProps,
  GridSortItem,
  useGridApiRef,
} from '@rossum/ui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { fromEntries, pick, pipe } from 'remeda';
import { v4 } from 'uuid';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { commonDataGridStyles } from '../../../ui/data-grid/styles';
import { LeavingDialog } from '../../../ui/leaving-dialog/LeavingDialog';
import { notify } from '../../toaster';
import { NotFound } from '../components/NotFound';
import { useDatasetContext } from '../context';
import { DatasetsHeader } from '../header';
import {
  GET_DATASET_DETAILS_QUERY_KEYS,
  useGetDatasetDetails,
} from '../hooks/useGetDatasetDetails';
import { useGetDatasetParams } from '../hooks/useGetDatasetParams';
import { useUpdateDataset } from '../hooks/useUpdateDataset';
import {
  datasetSettingsPath,
  datasetsPath,
  datasetTableRoute,
} from '../routes';
import { removeIdColumnKeys, transformIdIntoName } from '../utils';
import { EditedRow, RowWithIndex } from './types';
import { ACTIONS_COLUMN_NAME, useColumns } from './useColumns';
import { useRows } from './useRows';
import { useTableHandlers } from './useTableHandlers';

export const DatasetTable = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { currentDatasetId, currentDatasetName } = useGetDatasetParams({
    route: datasetTableRoute(),
  });

  const apiRef = useGridApiRef();

  const { datasetTasks, registerTask } = useDatasetContext();

  const [sortItem, setSortItem] = useState<GridSortItem | null>(null);
  const [editedRows, setEditedRows] = useState<EditedRow[]>([]);

  const { data, isLoading } = useGetDatasetDetails({
    name: currentDatasetId ? transformIdIntoName(currentDatasetId) : undefined,
  });

  const isDatasetMissing = data ? data.length === 0 : false;

  const { mutate: updateDataset, isLoading: isUpdatingDataset } =
    useUpdateDataset();

  const isTaskPolling = datasetTasks.some(
    task => task.datasetName === currentDatasetName
  );

  const isUpdating = isTaskPolling || isUpdatingDataset;

  const handleDuplicateRow = useCallback(
    (params: GridRowParams<RowWithIndex>) => {
      setEditedRows(prev =>
        prev.concat({ ...params.row, id: v4(), status: 'duplicated' })
      );
    },
    []
  );

  const handleDeleteRow = useCallback((params: GridRowParams<RowWithIndex>) => {
    setEditedRows(prev => {
      const editedRowsMap = new Map(prev.map(r => [r.id, r]));
      const existing = editedRowsMap.get(params.row.id);

      if (!existing) return [...prev, { ...params.row, status: 'deleted' }];

      // if its new or duplicated that means its not in the original data and can be filtered out
      return existing.status === 'new' || existing.status === 'duplicated'
        ? prev.filter(r => r.id !== params.row.id)
        : prev.map(r => ({ ...r, status: 'deleted' }));
    });
  }, []);

  const columns = useColumns({
    data,
    disabled: isUpdating,
    onDuplicate: handleDuplicateRow,
    onDelete: handleDeleteRow,
  });

  const { rows, rowsWithId } = useRows({ sortItem, editedRows, data });

  const handleAddLine = () => {
    const id = v4();
    const newControlledRow: EditedRow = {
      ...fromEntries(
        removeIdColumnKeys(
          columns.flatMap(c => (c.field === ACTIONS_COLUMN_NAME ? [] : c.field))
        ).map(name => [name, ''])
      ),
      id,
      index: 0,
      status: 'new',
    };

    setEditedRows(prev => prev.concat(newControlledRow));
    // grid does not yet know about the new line so we "wait" a bit before attempting to focus
    setTimeout(() =>
      apiRef.current.startRowEditMode({ id, fieldToFocus: columns[0]?.field })
    );
  };

  const handleSaveDataset = () => {
    const columnNames = columns.flatMap(c =>
      c.field === ACTIONS_COLUMN_NAME ? [] : [c.field]
    );
    const normalizedTable = rows.map(r => pipe(r, pick(columnNames)));

    if (normalizedTable.length === 0) {
      notify.warning({
        title: intl.formatMessage({
          id: 'features.datasets.table.notification.emptyTable.title',
        }),
        description: intl.formatMessage({
          id: 'features.datasets.table.notification.emptyTable.description',
        }),
      });
      return;
    }

    if (currentDatasetName) {
      updateDataset(
        {
          datasetName: currentDatasetName,
          data: normalizedTable,
        },
        {
          onSuccess: response => {
            if (response.taskId) {
              registerTask({
                type: 'update',
                taskId: response.taskId,
                datasetName: response.datasetName,
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      ...GET_DATASET_DETAILS_QUERY_KEYS,
                      currentDatasetName,
                    ],
                  });
                  setEditedRows([]);
                  notify.success({
                    title: intl.formatMessage(
                      {
                        id: 'features.datasets.context.taskNotification.update.success.title',
                      },
                      { datasetName: currentDatasetName }
                    ),
                  });
                },
                onFailed: () => {
                  notify.error({
                    title: intl.formatMessage(
                      {
                        id: 'features.datasets.context.taskNotification.update.error.title',
                      },
                      { datasetName: currentDatasetName }
                    ),
                  });
                },
              });
            }
          },
        }
      );
    }
  };

  const { handleCellKeydown, handleProcessRowUpdate, handleSortModelChange } =
    useTableHandlers({
      apiRef,
      columns,
      rowsWithId,
      setEditedRows,
      setSortItem,
      data,
    });

  const firstColumn = columns[0];

  return (
    <PageLayoutV2
      fullWidth
      renderHeader={props => (
        <DatasetsHeader
          {...props}
          title={!isDatasetMissing ? currentDatasetName ?? '' : ''}
          description=""
          breadcrumbs={
            currentDatasetName
              ? [
                  {
                    label: currentDatasetName,
                  },
                ]
              : []
          }
          onBackButtonClicked={() => {
            history.push(datasetsPath());
          }}
          buttons={
            !isDatasetMissing
              ? [
                  <IconButton
                    key={v4()}
                    component={Link}
                    to={
                      currentDatasetId
                        ? datasetSettingsPath(currentDatasetId)
                        : ''
                    }
                  >
                    <SvgIcon>
                      <IconSettings />
                    </SvgIcon>
                  </IconButton>,
                  <Button
                    key={v4()}
                    variant="outlined"
                    color="secondary"
                    onClick={handleAddLine}
                    disabled={isUpdating}
                  >
                    {intl.formatMessage({
                      id: 'features.datasets.table.header.button.addLine',
                    })}
                  </Button>,
                  <Button
                    key={v4()}
                    variant="contained"
                    disabled={!editedRows.length || isUpdating}
                    endIcon={isUpdating ? <CircularProgress size={14} /> : null}
                    onClick={handleSaveDataset}
                  >
                    {intl.formatMessage({
                      id: 'features.datasets.table.header.button.save',
                    })}
                  </Button>,
                ]
              : []
          }
        />
      )}
    >
      <LeavingDialog when={!isTaskPolling && !!editedRows.length} />
      {!isDatasetMissing ? (
        <Stack
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: t => `${t.shape.borders.large}px`,
            overflow: 'hidden',
            position: 'relative',
          }}
          flex={1}
          m={2}
        >
          <DataGridPro
            initialState={
              firstColumn
                ? {
                    sorting: {
                      sortModel: [{ field: firstColumn.field, sort: 'asc' }],
                    },
                  }
                : {}
            }
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            loading={isLoading}
            pinnedColumns={{ right: ['actions'] }}
            onCellKeyDown={handleCellKeydown}
            editMode="row"
            processRowUpdate={handleProcessRowUpdate}
            onSortModelChange={handleSortModelChange}
            disableRowSelectionOnClick
            disableColumnSelector
            slots={{
              row: (props: GridRowProps) => {
                return isUpdating ? (
                  <Stack
                    sx={{
                      pointerEvents: 'none',
                      color: 'text.disabled',
                      backgroundColor: t =>
                        alpha(
                          t.palette.background.default,
                          t.palette.action.disabledOpacity
                        ),
                    }}
                  >
                    <GridRow {...props} />
                  </Stack>
                ) : editedRows.some(er => er.id === props.row?.id) ? (
                  <Stack
                    sx={{ backgroundColor: t => t.palette.action.selected }}
                  >
                    <GridRow {...props} />
                  </Stack>
                ) : (
                  <GridRow {...props} />
                );
              },
            }}
            sx={{
              ...commonDataGridStyles,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              px: 4,
              py: 3,
            }}
            localeText={{
              footerTotalRows: intl.formatMessage({
                id: 'components.dataGrid.footerTotalRows',
              }),
            }}
          />
        </Stack>
      ) : (
        <NotFound />
      )}
    </PageLayoutV2>
  );
};
