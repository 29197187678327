import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
} from '../../icons/tabler';
import { alpha, Components, SvgIcon, Theme } from '../../material';
import { blend } from '../../system';

export const muiAlert = (theme: Theme): Components['MuiAlert'] => ({
  defaultProps: {
    iconMapping: {
      success: (
        <SvgIcon fontSize="inherit">
          <IconCircleCheck />
        </SvgIcon>
      ),
      warning: (
        <SvgIcon fontSize="inherit">
          <IconAlertTriangle />
        </SvgIcon>
      ),
      error: (
        <SvgIcon fontSize="inherit">
          <IconAlertOctagon />
        </SvgIcon>
      ),
      info: (
        <SvgIcon fontSize="inherit">
          <IconInfoCircle />
        </SvgIcon>
      ),
    },
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === 'filled'
        ? {
            backgroundColor:
              theme.palette[
                ownerState.color || ownerState.severity || 'success'
              ].main,
            color: theme.palette.getContrastText(
              theme.palette[
                ownerState.color || ownerState.severity || 'success'
              ].main
            ),
          }
        : // for now we don't really distinguish between these, UX team is on it
          ownerState.variant === 'standard' || ownerState.variant === 'outlined'
          ? {
              backgroundColor: blend(
                theme.palette.background.default,
                theme.palette[
                  ownerState.color || ownerState.severity || 'success'
                ].main,
                0.1
              ),
              borderColor:
                theme.palette[
                  ownerState.color || ownerState.severity || 'success'
                ].main,
              borderStyle: 'solid',
              borderWidth: 1,
              color: theme.palette.text.primary,
            }
          : {}),
    }),
  },
});
