import { Chip } from '@rossum/ui/material';
import React from 'react';
import { OverviewGridRowModel } from '../../types/fields';
import { DataForQueuesDialog } from './QueuesDialog';

export const QueuesDialogLink =
  (
    setRowForQueuesDialog: React.Dispatch<
      React.SetStateAction<DataForQueuesDialog | null>
    >
  ) =>
  ({
    row,
    value,
  }: {
    row: OverviewGridRowModel;
    value?: OverviewGridRowModel['queues'];
  }) => (
    <Chip
      label={value?.length}
      onClick={e => {
        e.stopPropagation();
        setRowForQueuesDialog({
          fieldId: row.fieldId,
          queues: row.queues ?? [],
        });
      }}
      data-cy="fm-queues-dialog-link"
    />
  );
