import { range } from 'lodash';
import { CSSProperties } from 'react';
import { firstBy } from 'remeda';

const ANY_NUMBER = '10';
const EXTRA_WIDTH_FOR_SAFETY = 5;

let canvas: HTMLCanvasElement;

const getTextWidth = (text: string, font: CSSProperties['font']): number => {
  if (!canvas) canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font ?? ANY_NUMBER;
  const metrics = context.measureText(text);
  return metrics.width;
};

export const getFontSize = (
  text: string,
  containerWidth: number,
  fontFamily: string
): { fontSize: number; width: number } | undefined => {
  // Split text into lines and find the longest line
  // for sorting, second argument of getTextWidth could be any font property
  // important for sorting is that the value is the same during comparison
  const [longestLine = ''] = text
    .split('\n')
    .sort(
      (x, y) =>
        (getTextWidth(y, ANY_NUMBER) ?? 0) - (getTextWidth(x, ANY_NUMBER) ?? 0)
    );

  // Generate a range of font sizes from 12 to 50
  const fontSizes = range(12, 51, 0.5); // Include 50

  // Calculate the text widths for the longest line at each font size
  const widths = fontSizes.map(size => {
    const width = getTextWidth(longestLine, `${size}px ${fontFamily}`);
    const difference = containerWidth - width;
    return {
      size,
      width,
      // To always round the text size down so that it fits.
      difference: difference > 0 ? difference : Infinity,
    };
  });

  const w = firstBy(widths, w => w.difference);

  return w
    ? {
        fontSize: w.size,
        width: w.width + EXTRA_WIDTH_FOR_SAFETY,
      }
    : undefined;
};
