import { LinearProgress, SxProps, Theme } from '@rossum/ui/material';

export const UpdateIndicator = ({ sx }: { sx?: SxProps<Theme> }) => {
  return (
    <LinearProgress
      color="primary"
      sx={{
        position: 'absolute',
        width: '100%',
        left: 0,
        ...sx,
      }}
    />
  );
};
