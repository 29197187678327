import { Serie } from '@rossum/api-client/annotations';
import { get, round } from 'lodash';
import { ReactNode } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import BarGraph from '../../../../components/Graphs/BarGraph';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { parse } from '../../../../lib/url';
import { State } from '../../../../types/state';
import { GranularityT } from '../Granularity';

type OwnProps = {
  intl: IntlShape;
  isTmpState: boolean;
  series: Array<Serie>;
  children: ReactNode;
  total: number;
};

const mapStateToProps = (
  {
    router: {
      location: { search },
    },
  }: State,
  { intl, series }: OwnProps
) => ({
  name: 'correctionsPerDocumentAvg' as const,
  typeOfY: 'number' as const,
  data: series.map(
    ({ values: { correctionsPerDocumentAvg }, beginDate, endDate }) => ({
      correctionsPerDocumentAvg: round(correctionsPerDocumentAvg || 0, 2),
      date: dateFormatByGranularity(
        intl.locale,
        get(parse(search), 'groupBy') as GranularityT,
        beginDate,
        endDate
      ),
    })
  ),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl(connect(mapStateToProps)(BarGraph as any));
