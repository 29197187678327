import { Label } from '@rossum/api-client/labels';
import { Checkbox, CircularProgress, Stack } from '@rossum/ui/material';
import { TruncatedLabelMenuText } from './TruncatedLabelMenuText';
type Props = {
  label: Label;
  checked: boolean;
  isLoading?: boolean;
  indeterminate?: boolean;
};

const sharedStyles = { py: 0.6, ml: -1.5, width: 38, flex: '0 0 auto' };

const LabelMenuItem = ({ indeterminate, isLoading, checked, label }: Props) => (
  <>
    {isLoading ? (
      <Stack sx={sharedStyles} alignItems="center">
        <CircularProgress size={20} />
      </Stack>
    ) : (
      <Checkbox
        size="small"
        sx={sharedStyles}
        checked={checked}
        indeterminate={indeterminate}
        tabIndex={-1}
        disableRipple
        inputProps={{ 'aria-labelledby': `label-${label.name}` }}
      />
    )}
    <TruncatedLabelMenuText name={label.name} />
  </>
);

export { LabelMenuItem };
