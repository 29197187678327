import { HttpError } from '@rossum/api-client/errors';
import { Mutation, Query } from '@tanstack/react-query';
import { pipe } from 'lodash/fp';
import { push, replace } from 'redux-first-history';
import { isTruthy, pickBy } from 'remeda';
import { AjaxError } from 'rxjs/ajax';
import { isEmbedded } from '../constants/config';
import { ErrorPageRouterState } from '../features/error-page';
import { logoutFromWorkflows } from '../features/workflows';
import { signOut } from '../redux/modules/auth/actions';
import { clearAuthToken, getAuthToken } from './token';

export const checkStatus = (response: Response) =>
  response.status >= 200 && response.status < 300
    ? Promise.resolve(response)
    : Promise.reject(new Error(response.statusText || String(response.status)));

export const report = (
  error: AjaxError | { status: string },
  options?: Record<string, unknown>
) => {
  if (window.Sentry) {
    window.Sentry.captureException(error, { extra: { ...options } });
  }

  return true;
};

export const reportQueryError = (error: unknown, query?: Query) => {
  const { queryKey } = query || {};

  if (window.Sentry) {
    window.Sentry.captureException(error, { extra: { queryKey } });
  }
};

// we can test this when we have a mutation
export const reportMutationError = (
  error: unknown,
  { mutation }: { mutation: Mutation<unknown, unknown, unknown, unknown> }
) => {
  const {
    options: { mutationKey },
  } = mutation;

  if (window.Sentry) {
    window.Sentry.captureException(error, {
      extra: {
        mutationKey,
      },
    });
  }
};

const jsonOptions = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const rossumTrackingOrigin = {
  'X-Rossum-Request-Origin': 'web_app',
};

export const jsonWithTrackingHeaders = {
  ...jsonOptions,
  ...rossumTrackingOrigin,
};

export const getAuthorizationHeader = (overwriteToken?: string) => {
  const token = overwriteToken ?? getAuthToken();

  if (token !== null) {
    return { Authorization: `Token ${token}` } as const;
  }

  return undefined;
};

export const withAuthorization = (
  headers: Record<string, string> | undefined
): Record<string, string> => ({
  ...getAuthorizationHeader(),
  ...rossumTrackingOrigin,
  ...headers,
});

const withJSON = (header: Record<string, string>) => ({
  ...jsonOptions,
  ...header,
});

export const createAuthJSONHeaders = pipe(
  withAuthorization,
  withJSON,
  pickBy(isTruthy)
) as (headers?: Record<string, string>) => Record<string, string>;

export const handleThrottleError = (error: unknown) => {
  if (error instanceof HttpError && 'code' in error && error.code === 429) {
    const retryAfterHeader = error.headers?.['Retry-After'];

    const throttleState: ErrorPageRouterState = {
      from: window.location.pathname,
      initialCountdown: retryAfterHeader
        ? parseInt(retryAfterHeader, 10)
        : undefined,
    };

    window.store.dispatch(
      push('/error?reason=too-many-requests', throttleState)
    );
  }
};

export const handleAuthError = (error: unknown) => {
  if (
    error &&
    typeof error === 'object' &&
    'code' in error &&
    error.code === 401
  ) {
    clearAuthToken();
    logoutFromWorkflows();

    if (isEmbedded()) {
      window.store.dispatch(replace('/timeExpired'));
    } else {
      window.store.dispatch(signOut({ handled: true }));
    }
  }
};
