import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grow,
  Link,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { FocusEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { useQueueToSchemaDatapointMap } from '../../../../business/queues/useQueueToSchemaDatapointMap';
import EmailAddressValidationTypeahead from '../../../../components/ReactHookForm/controls/EmailAddressValidationTypeaheadControl';
import SwitchControl from '../../../../components/ReactHookForm/controls/SwitchControl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import WysiwygControl from '../../../../components/ReactHookForm/controls/WysiwygControl/WysiwygControl';
import { throwInfo } from '../../../../redux/modules/messages/actions';
import { useSaveEmailTemplateAndTrigger } from '../hooks/useSaveEmailTemplateAndTrigger';
import { TriggerClientModel } from '../mappers/templateMapper';
import {
  emailTemplateFormSchema,
  validateEmailWithVariables,
} from './EmailTemplateForm.schemas';
import { EmailTemplateTriggerControl } from './EmailTemplateTriggerControl';
import { EmailTemplateVariablesButton } from './EmailTemplateVariablesButton';
import { EmailTemplateWysiwygVariablesButton } from './EmailTemplateWysiwygVariablesButton';
import {
  automationPerEmailTemplateType,
  EmailTemplateDrawerAction,
  EmailTemplateFormModel,
  isDefaultEmailTemplateType,
  isRejectionEmailTemplateType,
  resolveDefaultValues,
  resolveTemplateTypeFromDrawerAction,
} from './helpers';
import {
  cheatSheetConfigBase,
  cheatSheetConfigEmailNotification,
} from './TemplateValueCheatsheetItem/cheatSheetConfig';

// TODO - When annotation variables are done, we should allow any such variable

type EmailTemplateFieldValues =
  | 'to'
  | 'cc'
  | 'bcc'
  | 'name'
  | 'subject'
  | 'message';

const EmailTemplateForm = ({
  queueUrl,
  action,
  trigger,
  onClose,
  isTrial,
}: {
  queueUrl: string;
  action: EmailTemplateDrawerAction;
  trigger: TriggerClientModel | null;
  onClose: () => void;
  isTrial: boolean;
}) => {
  const intl = useIntl();
  const queueId = getIDFromUrl(queueUrl);
  const dispatch = useDispatch();
  const [ccVisible, setCcVisible] = useState(
    !!(action.type === 'update' && action.emailTemplate.cc?.length)
  );
  const [bccVisible, setBccVisible] = useState(
    !!(action.type === 'update' && action.emailTemplate.bcc?.length)
  );

  const emailTemplateType = resolveTemplateTypeFromDrawerAction(action);
  const isRejectionTemplate = isRejectionEmailTemplateType(emailTemplateType);
  const isDefaultTemplate = isDefaultEmailTemplateType(emailTemplateType);
  const defaultValues = resolveDefaultValues(action, trigger, intl);

  const { data: queueToSchemaDatapointMap } = useQueueToSchemaDatapointMap(
    [queueId],
    'headerField'
  );

  const fieldOptions = queueToSchemaDatapointMap?.[queueId]?.datapoints ?? [];

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
    setValue,
    trigger: triggerValidation,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(emailTemplateFormSchema(intl, fieldOptions)),
  });

  const watchValues = watch();

  useEffect(() => {
    triggerValidation('automate');
  }, [watchValues.cc, watchValues.bcc, watchValues.to, triggerValidation]);

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const handleAppendInputVariable = ({
    variableName,
    field,
    fieldWatchValue,
  }: {
    variableName: string;
    field: EmailTemplateFieldValues;
    fieldWatchValue: string | string[];
  }) => {
    const newValue = `${fieldWatchValue
      .toString()
      .substring(0, cursorPosition)}${variableName}${fieldWatchValue
      .toString()
      .substring(cursorPosition)}`;
    setValue(field, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setCursorPosition(newValue.length);
  };

  const handleAppendTypeaheadVariable = ({
    variableName,
    field,
    fieldWatchValue,
  }: {
    variableName: string;
    field: EmailTemplateFieldValues;
    fieldWatchValue: string[] | string;
  }) => {
    const newValue = [...fieldWatchValue, variableName];
    setValue(field, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setCursorPosition(newValue.length);
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCursorPosition(e.target.selectionStart ?? 0);
  };

  const { save, isLoading, isError, regexError } =
    useSaveEmailTemplateAndTrigger(queueUrl);
  // Triggers part of form
  const automate = watch('automate');

  useEffect(() => {
    if (regexError) {
      setError('trigger.condition.value', {
        message: intl.formatMessage(
          {
            id: 'components.emailTemplateForm.triggerCondition.value.regexError',
          },
          { message: regexError }
        ),
      });
    }
  }, [intl, regexError, setError]);

  const submitForm = (formModel: EmailTemplateFormModel) => {
    save(action, formModel, () => {
      dispatch(throwInfo('inbox.updated'));
      onClose();
    });
  };

  const cheatSheetConfig = isRejectionTemplate
    ? cheatSheetConfigBase
    : cheatSheetConfigEmailNotification;

  const automationConfig = automationPerEmailTemplateType[emailTemplateType];

  return (
    <form id="email-template-form" onSubmit={handleSubmit(submitForm)}>
      <Stack spacing={4}>
        <TextFieldControl
          disabled={isDefaultTemplate || isTrial}
          ControllerProps={{ control, name: 'name' }}
          FieldLabelProps={{
            label: intl.formatMessage({
              id: 'components.emailTemplateForm.name.label',
            }),
          }}
          InputProps={{
            onBlur: handleBlur,
          }}
          data-cy="email-template-name-input"
        />
        <Divider />
        {!isTrial && (
          <EmailAddressValidationTypeahead
            control={control}
            name="to"
            defaultValue={[]}
            label={intl.formatMessage({
              id: 'components.emailResponseForm.fields.edit.to.label',
            })}
            validate={e => validateEmailWithVariables(e, fieldOptions)}
            endAdornment={
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  // This margin space must be deducted from the input field to prevent overflow
                  mr: 4,
                  position: 'absolute',
                  right: 0,
                }}
              >
                <Link
                  variant="body2"
                  color={ccVisible ? 'primary' : 'inherit'}
                  sx={{
                    mx: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => setCcVisible(!ccVisible)}
                >
                  {intl.formatMessage({
                    id: 'components.emailResponseForm.fields.edit.buttons.copy.label',
                  })}
                </Link>
                <Link
                  variant="body2"
                  color={bccVisible ? 'primary' : 'inherit'}
                  sx={{
                    mx: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => setBccVisible(!bccVisible)}
                >
                  {intl.formatMessage({
                    id: 'components.emailResponseForm.fields.edit.buttons.blindCopy.label',
                  })}
                </Link>
                <EmailTemplateVariablesButton
                  cheatSheetConfig={cheatSheetConfig}
                  annotationFields={fieldOptions}
                  onValueSelect={(variableName: string) =>
                    handleAppendTypeaheadVariable({
                      variableName,
                      field: 'to',
                      fieldWatchValue: watchValues.to,
                    })
                  }
                  sx={{ borderRadius: '50%' }}
                />
              </Stack>
            }
            inputProps={{ sx: { mr: 4 } }}
          />
        )}
        {ccVisible && !isTrial && (
          <EmailAddressValidationTypeahead
            control={control}
            name="cc"
            label={intl.formatMessage({
              id: 'components.emailResponseForm.fields.edit.cc.label',
            })}
            validate={e => validateEmailWithVariables(e, fieldOptions)}
            endAdornment={
              <Stack
                sx={{
                  mr: 4,
                  position: 'absolute',
                  right: 0,
                }}
              >
                <EmailTemplateVariablesButton
                  cheatSheetConfig={cheatSheetConfig}
                  annotationFields={fieldOptions}
                  onValueSelect={(variableName: string) =>
                    handleAppendTypeaheadVariable({
                      variableName,
                      field: 'cc',
                      fieldWatchValue: watchValues.cc,
                    })
                  }
                  sx={{ borderRadius: '50%' }}
                />
              </Stack>
            }
          />
        )}
        {bccVisible && !isTrial && (
          <EmailAddressValidationTypeahead
            control={control}
            name="bcc"
            label={intl.formatMessage({
              id: 'components.emailResponseForm.fields.edit.bcc.label',
            })}
            validate={e => validateEmailWithVariables(e, fieldOptions)}
            endAdornment={
              <Stack
                sx={{
                  mr: 4,
                  position: 'absolute',
                  right: 0,
                }}
              >
                <EmailTemplateVariablesButton
                  cheatSheetConfig={cheatSheetConfig}
                  annotationFields={fieldOptions}
                  onValueSelect={(variableName: string) =>
                    handleAppendTypeaheadVariable({
                      variableName,
                      field: 'bcc',
                      fieldWatchValue: watchValues.bcc,
                    })
                  }
                  sx={{ borderRadius: '50%' }}
                />
              </Stack>
            }
          />
        )}
        <TextFieldControl
          disabled={isTrial}
          ControllerProps={{ control, name: 'subject' }}
          FieldLabelProps={{
            label: intl.formatMessage({
              id: 'components.emailTemplateForm.subject.label',
            }),
          }}
          InputProps={{
            onBlur: handleBlur,
            endAdornment: (
              <EmailTemplateVariablesButton
                cheatSheetConfig={cheatSheetConfig}
                annotationFields={fieldOptions}
                onValueSelect={(variableName: string) =>
                  handleAppendInputVariable({
                    variableName,
                    field: 'subject',
                    fieldWatchValue: watchValues.subject,
                  })
                }
                disabled={isTrial}
                sx={{ borderRadius: '50%' }}
              />
            ),
          }}
          data-cy="email-template-subject-input"
        />
        <WysiwygControl
          disabled={isTrial}
          ControllerProps={{ control, name: 'message' }}
          FieldLabelProps={{
            label: intl.formatMessage({
              id: 'components.emailTemplateForm.message.label',
            }),
          }}
          initialValue={defaultValues.message}
          customButtons={[
            <EmailTemplateWysiwygVariablesButton
              cheatSheetConfig={cheatSheetConfig}
              annotationFields={fieldOptions}
              key="wysiwyg-template-variables"
            />,
          ]}
        />
        {automationConfig.canAutomate && (
          <>
            <Stack spacing={1}>
              <SwitchControl
                ControllerProps={{ control, name: 'automate' }}
                disabled={isTrial}
                label={intl.formatMessage({
                  id: 'containers.settings.queues.email.automaticProcessing.switch.label',
                })}
                size="small"
                data-cy="email-template-automation-switch"
              />
              <Typography variant="caption" color="text.secondary">
                {intl.formatMessage({
                  id: 'components.emailTemplateForm.automationNotice',
                })}
              </Typography>
            </Stack>
            {!!automationConfig.allowedTriggers.length && (
              <EmailTemplateTriggerControl
                ControllerProps={{
                  control,
                  nameMap: {
                    event: 'trigger.event',
                    fields: 'trigger.fields',
                    condition: {
                      __rootField: 'trigger.condition',
                      field: 'trigger.condition.field',
                      operator: 'trigger.condition.operator',
                      value: 'trigger.condition.value',
                    },
                  },
                }}
                fieldOptions={fieldOptions}
                triggerEvents={automationConfig.allowedTriggers}
                disabled={isTrial || !automate}
              />
            )}
            <SwitchTransition>
              <Grow key={isError ? 'error' : 'all-good'}>
                {isError ? (
                  <Alert variant="filled" severity="error">
                    {intl.formatMessage({
                      id: 'components.emailTemplateForm.error',
                    })}
                  </Alert>
                ) : (
                  <span style={{ display: 'none' }} />
                )}
              </Grow>
            </SwitchTransition>
          </>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            data-cy="email-template-cancel-btn"
          >
            {intl.formatMessage({ id: 'components.emailTemplateForm.cancel' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={
              isLoading && <CircularProgress size={16} color="inherit" />
            }
            disabled={isLoading || !isValid || isTrial}
            data-cy="email-template-save-btn"
          >
            {intl.formatMessage({ id: 'components.emailTemplateForm.submit' })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export { EmailTemplateForm };
