import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { TrapFocus } from '@rossum/ui/material';
import { TooltipRenderProps } from 'react-joyride';
import RuiThemeProvider from '../../../RuiThemeProvider';
import { ProductTourStep } from '../../../types/productTour';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';

type TourTooltipDialogProps = {
  step: ProductTourStep;
  onSkip: () => void;
  onNext: () => void;
  onFinish: () => void;
} & Omit<TooltipRenderProps, 'step'>;

export const TourTooltipDialog = ({
  tooltipProps,
  step,
  skipProps,
  continuous,
  primaryProps,
  isLastStep,
  index,
  size,
  onSkip,
  onNext,
  onFinish,
}: TourTooltipDialogProps) => {
  const theme = useTheme();

  return (
    <TrapFocus disableEnforceFocus open>
      <Box
        {...tooltipProps}
        sx={{
          ':focus': {
            outline: 'none',
          },
          borderRadius: 2,
          overflow: 'auto',
        }}
      >
        <RuiThemeProvider
          theme={theme.palette.mode === 'dark' ? 'light' : 'dark'}
          isRoot={false}
        >
          <Paper
            sx={{
              width: 430,
              mt: 0,
            }}
          >
            <Stack spacing={3}>
              <DialogTitle
                title={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {step.title}
                    <Typography variant="body2">
                      {`${index + 1}/${size}`}
                    </Typography>
                  </Stack>
                }
              />

              <DialogContent
                sx={{
                  pb: 0,
                }}
              >
                {step.media}
                {typeof step.content === 'string' ? (
                  <Typography variant="body2">{step.content}</Typography>
                ) : (
                  step.content
                )}
              </DialogContent>
              <DialogActions sx={{ px: 3, pb: 3, pt: 0 }} disableSpacing>
                <Stack spacing={1.5} direction="row">
                  {/* Joyride adds `title` to Buttons in `primaryProps/skipProps` automatically
                      Just removing them because our Buttons don't have title based on our current accessibility
                      standards */}
                  {continuous && (
                    <Button
                      {...primaryProps}
                      onClick={isLastStep ? onFinish : onNext}
                      variant="contained"
                      sx={{ boxShadow: 0 }}
                      title={undefined}
                    >
                      {primaryProps.title}
                    </Button>
                  )}
                  {step.showSkipButton && (
                    <Button
                      {...skipProps}
                      onClick={onSkip}
                      variant="outlined"
                      color="secondary"
                      title={undefined}
                    >
                      {skipProps.title}
                    </Button>
                  )}
                </Stack>
              </DialogActions>
            </Stack>
          </Paper>
        </RuiThemeProvider>
      </Box>
    </TrapFocus>
  );
};
