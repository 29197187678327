import { getIDFromUrl } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { formatNumberToLocale } from '../../../lib/helpers';
import { BillingVolume } from '../helpers';
import { BillingEntity } from '../hooks/useBillingEntity';
import { LabeledValueInRow } from './LabeledValueInRow';
type OrganizationOverviewProps = {
  billingEntity: BillingEntity;
  volume: BillingVolume | null;
};

const OrganizationOverview = ({
  billingEntity,
  volume,
}: OrganizationOverviewProps) => {
  const { locale } = useIntl();
  return (
    <Stack flexDirection="row" gap={2}>
      <LabeledValueInRow
        label={`containers.billing.overview.${
          billingEntity.type === 'organization_group'
            ? 'organizationGroup'
            : 'organization'
        }`}
        delimiter=":"
        value={billingEntity.name}
      />
      <LabeledValueInRow
        label={`containers.billing.overview.${
          billingEntity.type === 'organization_group'
            ? 'organizationGroupId'
            : 'organizationId'
        }`}
        delimiter=":"
        value={`${getIDFromUrl(
          billingEntity.type === 'organization_group'
            ? billingEntity.organizationGroupUrl
            : billingEntity.organizationUrl
        )}`}
      />
      {volume && (
        <LabeledValueInRow
          label={
            volume.unit === 'pages'
              ? 'containers.billing.overview.purchasedPages'
              : 'containers.billing.overview.purchasedDocuments'
          }
          delimiter=":"
          value={formatNumberToLocale(volume.purchased, locale).toString()}
        />
      )}
    </Stack>
  );
};

export { OrganizationOverview };
