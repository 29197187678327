// eslint-disable-next-line max-classes-per-file
import { customHighlighters } from './customHighlighters';

const PythonMode = window.ace?.acequire('ace/mode/python').Mode;
const PythonHighlightRules = new PythonMode().HighlightRules;
const PythonHighlightRulesRules = new PythonHighlightRules().$rules;

// TODO: What to do here?
// eslint-disable-next-line no-unsafe-optional-chaining
class FormulaHighlightRules extends window.ace?.acequire(
  'ace/mode/python_highlight_rules'
).PythonHighlightRules {
  constructor() {
    super();
    // @ts-expect-error
    this.$rules = {
      ...PythonHighlightRulesRules,
      start: [...customHighlighters, ...PythonHighlightRulesRules.start],
    };
  }
}

class FormulaPythonMode extends PythonMode {
  constructor() {
    super();
    // @ts-expect-error
    this.HighlightRules = FormulaHighlightRules;
  }
}

export { FormulaPythonMode };
