import { clamp } from 'remeda';
import { Vector } from '../../lib/spaceConvertor';
import { usePageSpaceContext } from '../DocumentPage/PageSpaceContext';
import { MIN_GRID_SIZE } from './constants';
import { useGridContext } from './GridContext';
import { ResizingEdge } from './utils';

// Returns a function that converst a webPx diff to PDF pixels and clamps it
// for each individual direction
export const useResizeClamps = (
  resizingEdge: ResizingEdge
): ((diff: Vector) => Vector) => {
  const { gridState } = useGridContext();

  const { pageWidth, pageHeight } = usePageSpaceContext();

  const topClamp = (dy: number): number =>
    clamp(Math.round(dy), {
      min: -gridState.rows[0].topPosition,
      max: gridState.height - MIN_GRID_SIZE,
    });

  const rightClamp = (dx: number): number =>
    clamp(Math.round(dx), {
      min: -gridState.width + MIN_GRID_SIZE,
      max: pageWidth - gridState.width - gridState.columns[0].leftPosition,
    });

  const bottomClamp = (dy: number): number =>
    clamp(Math.round(dy), {
      min: -gridState.height + MIN_GRID_SIZE,
      max: pageHeight - gridState.rows[0].topPosition - gridState.height,
    });

  const leftClamp = (dx: number): number =>
    clamp(Math.round(dx), {
      min: -gridState.columns[0].leftPosition,
      max: gridState.width - MIN_GRID_SIZE,
    });

  const noDeltaClamp = (_d: number) => 0;

  const clampMap: Record<
    ResizingEdge,
    [(x: number) => number, (y: number) => number]
  > = {
    top: [noDeltaClamp, topClamp],
    'top-right': [rightClamp, topClamp],
    right: [rightClamp, noDeltaClamp],
    'bottom-right': [rightClamp, bottomClamp],
    bottom: [noDeltaClamp, bottomClamp],
    'bottom-left': [leftClamp, bottomClamp],
    left: [leftClamp, noDeltaClamp],
    'top-left': [leftClamp, topClamp],
  };

  return (diff: Vector) => [
    clampMap[resizingEdge][0](diff[0]),
    clampMap[resizingEdge][1](diff[1]),
  ];
};
