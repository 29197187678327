import { endpoints } from '@rossum/api-client';
import { RuleTemplate } from '@rossum/api-client/rule-templates';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RULE_TEMPLATES_QUERY_KEY } from './useListRuleTemplates';

export const useCreateRuleTemplate = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([RULE_TEMPLATES_QUERY_KEY]);
    },
    mutationFn: (ruleTemplatePayload: Partial<RuleTemplate>) =>
      api.request(endpoints.ruleTemplates.create(ruleTemplatePayload)),
  });
};
