import { PersonRemove } from '@rossum/ui/icons';
import { Button, Dialog, Stack } from '@rossum/ui/material';
import UserIcon from 'mdi-react/UserIcon';
import { useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ContentWrapper from '../../components/ContentWrapper/index';
import PageLayout from '../../components/PageLayout/index';
import Scrollable from '../../components/Scrollable';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import { getRoleName } from '../../redux/modules/groups/helpers';
import {
  PartialUser,
  updateUser as updateUserAction,
} from '../../redux/modules/user/actions';
import { getName } from '../../redux/modules/user/helpers';
import { GroupRole } from '../../types/group';
import { State } from '../../types/state';
import { User } from '../../types/user';
import FeatureFlagToggle from '../../unleash/FeatureFlagToggle';
import DeleteUserDialog from '../Users/components/DeleteUser/DeleteUserDialog';
import Section from './components/Section';
import ChangePasswordForm from './containers/ChangePasswordForm';
import PersonalInformationForm from './containers/PersonalInformationForm';
import Sidebar from './containers/Sidebar';
import styles from './style.module.sass';

type OwnProps = {
  user: User;
  role?: GroupRole;
  updateUser: (_payload: PartialUser, _meta: object) => void;
} & RouteComponentProps<{ section: string }>;
type IntlProps = { intl: IntlShape };

type Props = OwnProps & IntlProps;

const PersonalInfo = ({
  updateUser,
  user,
  role,
  intl,
  match: {
    params: { section },
  },
  location: { key },
}: Props) => {
  const [open, setOpen] = useState(false);

  const activeRoute = { section, key };

  return (
    <PageLayout data-page-title="personal-info">
      <Sidebar />
      <ContentWrapper>
        <Scrollable>
          <div className={styles.Content}>
            <div className={styles.Header}>
              <div className={styles.AvatarRound}>
                <UserIcon />
              </div>
              <div className={styles.Name}>
                {getName(user)}
                <div className={styles.Email}>
                  {user.email || user.username}
                </div>
              </div>
            </div>
            <Stack spacing={8}>
              <Section
                title="containers.personalInfo.personalInformation"
                id="personalInfo"
                activeRoute={activeRoute}
              >
                <PersonalInformationForm
                  updateUser={updateUser}
                  intl={intl}
                  user={user}
                />
              </Section>
              <Section
                title="containers.personalInfo.changePassword"
                id="changePassword"
                activeRoute={activeRoute}
              >
                <ChangePasswordForm username={user.username} />
              </Section>

              {DEV_FEATURES_ENABLED ? (
                <Section
                  title="containers.personalInfo.changeFeatureFlags"
                  id="changeFeatureFlags"
                  activeRoute={activeRoute}
                >
                  <FeatureFlagToggle />
                </Section>
              ) : null}
            </Stack>

            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(true)}
              sx={{ mt: 4 }}
              startIcon={<PersonRemove />}
            >
              {intl.formatMessage({
                id: 'containers.personalInfo.deleteAccount.button',
              })}
            </Button>
            <Dialog
              open={open}
              PaperProps={{
                sx: { width: 480, minHeight: 213, position: 'fixed' },
                elevation: 2,
              }}
              onClose={() => setOpen(false)}
              sx={{ transition: 'smooth' }}
            >
              {open ? (
                <DeleteUserDialog
                  user={{ ...user, role }}
                  onCancel={() => setOpen(false)}
                />
              ) : null}
            </Dialog>
          </div>
        </Scrollable>
      </ContentWrapper>
    </PageLayout>
  );
};

const mapStateToProps = (state: State) => {
  const { user } = state;
  const role = getRoleName(user.groups, state.groups);

  return { user, role };
};

const mapDispatchToProps = {
  updateUser: updateUserAction,
};

export default injectIntl<'intl', OwnProps & IntlProps>(
  connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
);
