import { QueryStats as QueryStatsIcon } from '@rossum/ui/icons';
import InfoPlaceholder from '../../../../components/UI/InfoPlaceHolder';
import { LocalizationKeys } from '../../../../i18n';

type EmptyChartProps = {
  title: LocalizationKeys;
  text?: LocalizationKeys;
};

const EmptyChart = ({ title, text }: EmptyChartProps) => (
  <InfoPlaceholder
    title={title}
    text={text}
    icon={<QueryStatsIcon />}
    inTable
  />
);

export { EmptyChart };
