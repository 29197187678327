import { Hook } from '@rossum/api-client/hooks';
import { useIntl } from 'react-intl';
import { useDeleteHook } from '../../business/hooks/useDeleteHook';
import { boldText } from '../../lib/formaterValues';
import { DeleteConfirmationDialog } from '../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';

export const DeleteExtensionDialog = ({
  extension,
  open,
  onClose,
}: {
  extension: Hook | undefined;
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  const { mutate } = useDeleteHook();

  return (
    <DeleteConfirmationDialog
      title={intl.formatMessage({
        id: 'features.queueSettings.rules.deleteExtensionConfirmation.title',
      })}
      description={intl.formatMessage(
        {
          id: 'features.queueSettings.rules.deleteExtensionConfirmation.text',
        },
        {
          name: extension?.name ?? '',
          queues: extension?.queues.length ?? 0,
          bold: boldText,
        }
      )}
      open={open}
      onCancel={() => onClose()}
      onConfirm={() => {
        if (extension) {
          mutate({ hookId: extension.id }, { onSuccess: () => onClose() });
        }
      }}
    />
  );
};
