import { getIDFromUrl } from '@rossum/api-client';
import { Workspace } from '@rossum/api-client/workspaces';
import { useMemo } from 'react';
import * as R from 'remeda';
import { useGetQueues } from '../../hooks/useGetQueues';
import { useGetWorkspaces } from '../../hooks/useGetWorkspaces';
import { Queue } from '../../types/queue';

export const useWorkspacesWithQueues = () => {
  const queuesQuery = useGetQueues();
  const workspaceQueries = useGetWorkspaces();

  const queues = useMemo(
    () => queuesQuery.data?.pages.flatMap(page => page.results) || [],
    [queuesQuery.data]
  );

  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );

  const queueObject = useMemo(() => {
    return queues?.reduce<Record<number, Queue>>((acc, queue) => {
      return {
        ...acc,
        [queue.id]: queue,
      };
    }, {});
  }, [queues]);

  const allWorkspacesWithQueues = useMemo(
    () =>
      workspaces
        ?.map(workspace => {
          const { queues: workspaceQueues, metadata, ...rest } = workspace;
          const queuesOfWorkspace = R.pipe(
            workspaceQueues?.map(queueUrl => {
              const queueId = getIDFromUrl(queueUrl);
              return queueObject?.[queueId] || undefined;
            }),
            R.filter(R.isTruthy)
          );

          return {
            ...rest,
            queues: queuesOfWorkspace,
          };
        })
        ?.sort((a, b) => a.name.localeCompare(b.name)),

    [queueObject, workspaces]
  );

  const workspacesWithQueues = useMemo(() => {
    return allWorkspacesWithQueues?.filter(
      workspace => workspace.queues.length && workspace.queues[0]
    );
  }, [allWorkspacesWithQueues]);

  return {
    workspacesWithQueues,
    allWorkspacesWithQueues,
    queues,
    isLoading: queuesQuery.isLoading,
  };
};
