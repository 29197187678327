import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { VALUE_INPUT_WIDTH } from '../../../../document-list-base/mql/constants';
import { isFilterContextSimple } from '../../../../document-list-base/mql/helpers';
import { getSimpleFilterComponent } from '../../componentMap';
import { InputSelectorProps } from './types';

const selectLabelKey = 'filtering-value-select-label';

export const MultiSelectInput = ({
  filterContext,
  column,
  handleFilterChange,
  autoFocus,
}: InputSelectorProps) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  // we only expect the value to be an array for this component and we restrict the type here to please MUI's Select component
  const controlledValue = Array.isArray(filterContext.value)
    ? filterContext.value
    : [];

  const [selectedValues, setSelectedValues] =
    useState<string[]>(controlledValue);

  // when we make a selection and apply those selections the global state is updated, therefore controlledValue is updated.
  // To display the latest selected values we make sure selectedValues is up to date.
  if (controlledValue.join('') !== selectedValues.join('')) {
    setSelectedValues(controlledValue);
  }

  const FilterComponent = getSimpleFilterComponent({
    column,
    filterContext,
  });

  const selectLabel = intl.formatMessage({
    id: 'containers.filtering.labels.value',
  });

  return (
    <FormControl sx={{ width: VALUE_INPUT_WIDTH }}>
      <InputLabel size="small" id={selectLabelKey}>
        {selectLabel}
      </InputLabel>
      <Select
        value={selectedValues}
        labelId={selectLabelKey}
        open={isOpen}
        multiple
        input={<OutlinedInput autoFocus={autoFocus} label={selectLabel} />}
        renderValue={values =>
          column.valueOptions
            ?.filter(option => values.includes(option.value))
            .map(({ label }) => label)
            .join(', ')
        }
        label={selectLabel}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        size="small"
        MenuProps={{
          slotProps: {
            paper: {
              elevation: 8,
            },
          },
        }}
      >
        {isOpen && FilterComponent ? (
          <FilterComponent
            filterItem={{ column, filterContext }}
            onClose={() => setIsOpen(false)}
            applyFilter={newFilterContext => {
              if (isFilterContextSimple(newFilterContext)) {
                handleFilterChange(newFilterContext);
                setIsOpen(false);
              }
            }}
            isInAdvancedFilter
          />
        ) : null}
      </Select>
    </FormControl>
  );
};
