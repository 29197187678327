import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const USER_KEY = 'user';

export const useUser = () => {
  return useQuery({
    queryKey: [USER_KEY],
    queryFn: () => api.request(endpoints.authentication.user()),
  });
};
