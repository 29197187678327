import { getIDFromUrl } from '@rossum/api-client';
import { Dialog, Stack, styled } from '@rossum/ui/material';
import { GridLogicOperator } from '@rossum/ui/x-data-grid-pro';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { annotationSelector } from '../../redux/modules/annotation/selectors';
import { parseAndValidate } from '../../utils/jsonParse';
import { filteringSchema } from '../document-list-base/mql/mql';
import { useQueues } from '../queues/hooks/useQueues';
import { QuickSearchAutocomplete } from './QuickSearchAutocomplete';
import { useQuickSearchContext } from './QuickSearchContext';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  backdropFilter: 'blur(5px)',
}));

export const QuickSearch = () => {
  const { open, setOpen } = useQuickSearchContext();

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === 'k' &&
        (event.metaKey === true || event.ctrlKey === true)
      ) {
        setOpen(prevOpen => !prevOpen);
      }
    },
    [setOpen]
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const level = searchParams.get('level');

  const filtering = parseAndValidate(
    searchParams.get('filtering'),
    filteringSchema,
    {
      items: [],
      logicOperator: GridLogicOperator.And,
    }
  );

  const validationScreenAnnotation = useSelector(annotationSelector);

  const queueIdForValidationScreen = validationScreenAnnotation
    ? getIDFromUrl(validationScreenAnnotation.queue)
    : undefined;

  const queueIdForSettings: string | undefined =
    location.pathname.match('/queues/([0-9]+)')?.[1];

  const queueIdForDashboard: string | undefined =
    level === 'queue'
      ? filtering.items.find(
          item => item.field === 'queue' && item.operator === 'isAnyOf'
        )?.value[0]
      : undefined;

  const queueIdString =
    queueIdForSettings ?? queueIdForDashboard ?? queueIdForValidationScreen;

  const queueId = queueIdString ? Number(queueIdString) : NaN;

  const { data: queues } = useQueues(
    { id: [queueId] },
    { enabled: !Number.isNaN(queueId) }
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      onKeyDown={e => e.stopPropagation()}
    >
      <Stack p={0.5}>
        {open ? (
          <QuickSearchAutocomplete
            onClose={() => setOpen(false)}
            queue={queues?.results[0]}
          />
        ) : null}
      </Stack>
    </StyledDialog>
  );
};
