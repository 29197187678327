import { endpoints, ID } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RULES_QUERY_KEY } from './rulesQueryKeys';

export const useGetRule = (ruleId: ID) => {
  const api = useApiClient();

  return useQuery({
    queryKey: [RULES_QUERY_KEY, 'detail', ruleId],
    queryFn: () => api.request(endpoints.rules.get(ruleId)),
  });
};
