// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const ruleListQuerySchema = paginationQuerySchema.and(
  z.object({
    schema: idSchema.optional(),
    ruleTemplate: idSchema.optional(),
  })
);
