// libraries
import { Message } from '@rossum/api-client/shared';
import { get, last } from 'lodash';
import { LegacyRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unique } from 'remeda';
// styles
import { assertNever } from '../../lib/typeUtils';
import { DatapointAutomationBlocker } from '../../redux/modules/annotation/types';
import {
  deleteAllDatapoints,
  deleteDatapointAndNavigate,
  updateDatapointValue,
} from '../../redux/modules/datapoints/actions';
import {
  getUITypeFromSchema,
  isFieldEditable,
  isFieldSelectable,
} from '../../redux/modules/schema/helpers';
import { schemaMapSelector } from '../../redux/modules/schema/schemaMapSelector';
// types
import { SimpleDatapointDataST } from '../../types/datapoints';
import UiFieldTypeMessage from '../Datapoint/components/UiFieldTypeMessage';
import { validationTickColor } from '../Datapoint/helpers';
import IconWithTooltip from '../DatapointMessage/IconWithTooltip';
import DatapointTooltip, {
  getTooltipTypeWithHighestPriority,
} from '../DatapointTooltip';
import AddValueButton from './components/AddValueButton';
import DeleteSelectedButton from './components/DeleteSelectedButton';
// components
import Value, { BatchSelectionParams } from './components/Value';
import ValueInput from './components/ValueInput';
import styles from './styles.module.sass';

type Props = {
  allBlockers: Record<string, DatapointAutomationBlocker[]>;
  childrenSchemaId: string;
  datapointPath: Array<number>;
  datapoints: Array<SimpleDatapointDataST>;
  documentAutomated: boolean;
  editingDatapointValue: boolean;
  leavingButtonFocused: boolean;
  maxOccurrences: number | null | undefined;
  messages?: { [key: number]: Message };
  minOccurrences: number | null | undefined;
  myPath: Array<number>;
  readOnly: boolean;
  setRef: LegacyRef<HTMLDivElement>;
  multivalueIndex: number;
};

const SimpleMultiValue = ({
  childrenSchemaId,
  datapointPath,
  datapoints,
  documentAutomated,
  editingDatapointValue,
  leavingButtonFocused,
  maxOccurrences,
  messages,
  minOccurrences,
  myPath,
  readOnly,
  setRef,
  allBlockers,
  multivalueIndex,
}: Props) => {
  const dispatch = useDispatch();

  const onChange =
    ({ id, content, meta: { index } }: SimpleDatapointDataST) =>
    (value: string) =>
      dispatch(
        updateDatapointValue(
          { index, id, oldValue: content?.value, reason: 'input-sidebar' },
          value
        )
      );

  const [batchSelection, setBatchSelection] = useState<Array<number>>([]);
  const currentDatapointId = last(datapointPath);
  const disableDelete = !!minOccurrences && minOccurrences >= datapoints.length;
  const disableAdd = !!maxOccurrences && maxOccurrences <= datapoints.length;
  const isEmpty = datapoints.length === 0;
  const datapointIDs = datapoints.map(datapoint => datapoint.id);

  const handleBatchSelection = useCallback(
    (params: BatchSelectionParams) => {
      const { type } = params;

      switch (type) {
        case 'oneByOne': {
          // REMOVE IT, if datapoint is already batch selected
          if (params.isAlreadyIncluded) {
            return setBatchSelection(
              batchSelection.filter(item => item !== params.datapointId)
            );
          }

          // ADD IT, in case is not already batch selected
          return setBatchSelection(
            unique([...batchSelection, params.datapointId])
          );
        }

        case 'multiple': {
          const selectedIndex = datapointIDs.findIndex(
            datapointID => datapointID === datapointPath[2]
          );

          const batchSelectedIndex = datapointIDs.findIndex(
            datapointID => datapointID === params.datapointId
          );

          const limits = [selectedIndex, batchSelectedIndex];

          return setBatchSelection(
            datapointIDs.filter((_datapoint, index) => {
              return (
                index <= Math.max(...limits) && index >= Math.min(...limits)
              );
            })
          );
        }

        case 'forcedContent':
          return setBatchSelection(params.payload);
        default:
          return assertNever(type);
      }
    },
    [batchSelection, datapointIDs, datapointPath]
  );

  useEffect(() => {
    if (currentDatapointId) {
      handleBatchSelection({
        type: 'forcedContent',
        payload: [currentDatapointId],
      });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDatapointId]);

  const deleteSelectedDatapoints = () => {
    // if all dataPoints are selected, let's use only one delete children request
    // otherwise delete one by one

    if (batchSelection.length === datapoints.length) {
      dispatch(deleteAllDatapoints(multivalueIndex));
    } else {
      batchSelection.forEach(datapoint => {
        dispatch(deleteDatapointAndNavigate([...myPath, datapoint]));
      });
    }

    setBatchSelection([]);
  };

  const schemasMap = useSelector(schemaMapSelector);
  const simpleMultivalueChildrenSchema = schemasMap.get(childrenSchemaId);
  const uiFieldType = getUITypeFromSchema(simpleMultivalueChildrenSchema);
  const isEditable = isFieldEditable(simpleMultivalueChildrenSchema);

  const isSelectable = isFieldSelectable(simpleMultivalueChildrenSchema);

  return (
    <>
      {!isEmpty &&
        datapoints.map(datapoint => {
          const automationBlockers = allBlockers[datapoint.schemaId];
          const displayAutomationBlockers = !!automationBlockers;

          const tooltipType = getTooltipTypeWithHighestPriority({
            displayAutomationBlockers,
            tickIconColor: null,
          });

          const isAlreadyIncluded = batchSelection.includes(datapoint.id);

          return (
            <div key={datapoint.id} className={styles.ItemOuter}>
              <div className={styles.MessagesWrapper}>
                {messages && messages[datapoint.id] && (
                  <IconWithTooltip
                    message={messages[datapoint.id]}
                    active
                    isSimpleMultivalue
                  />
                )}
                {tooltipType === 'automationBlockers' && (
                  <DatapointTooltip
                    data={datapoint}
                    tickIconColor={null}
                    automationBlockers={automationBlockers}
                    active={false}
                    tooltipType={tooltipType}
                  />
                )}
              </div>
              <UiFieldTypeMessage
                uiFieldType={uiFieldType}
                readOnly={readOnly}
                fieldEditable={isEditable}
              >
                {datapoint.id === currentDatapointId && datapoint.content ? (
                  <ValueInput
                    id="magic-line-source"
                    displayAutomationBlockers={false}
                    data={datapoint.content}
                    datapointPath={datapointPath}
                    disableDelete={disableDelete}
                    documentAutomated={documentAutomated}
                    inFooter={false}
                    myPath={[...myPath, datapoint.id]}
                    onChange={onChange(datapoint)}
                    readOnly={readOnly}
                    isEditable={isEditable}
                    isSelectable={isSelectable}
                    setRef={setRef}
                    schemaId={childrenSchemaId}
                    editingDatapointValue={editingDatapointValue}
                    tickIconColor={
                      !displayAutomationBlockers
                        ? validationTickColor(datapoint, true)
                        : null
                    }
                    datapointData={datapoint}
                    uiFieldType={uiFieldType}
                    schema={simpleMultivalueChildrenSchema}
                  />
                ) : (
                  <Value
                    displayAutomationBlockers={false}
                    active={false}
                    disableDelete={disableDelete}
                    documentAutomated={documentAutomated}
                    id={datapoint.id}
                    parentPath={myPath}
                    readOnly={readOnly}
                    value={get(datapoint, ['content', 'value'])}
                    tickIconColor={
                      !displayAutomationBlockers
                        ? validationTickColor(datapoint, false)
                        : null
                    }
                    datapointData={datapoint}
                    handleBatchSelection={handleBatchSelection}
                    isAlreadyIncluded={isAlreadyIncluded}
                    isSelectable={isSelectable}
                    uiFieldType={uiFieldType}
                  />
                )}
              </UiFieldTypeMessage>
            </div>
          );
        })}
      {!readOnly && batchSelection.length <= 1 && isSelectable && (
        <AddValueButton
          focused={leavingButtonFocused}
          disableAdd={disableAdd}
          parentIndex={multivalueIndex}
          resetBatchSelection={() =>
            handleBatchSelection({
              type: 'forcedContent',
              payload: [],
            })
          }
        />
      )}
      {!readOnly && batchSelection.length > 1 && (
        <DeleteSelectedButton handleDeleteSelected={deleteSelectedDatapoints} />
      )}
    </>
  );
};

export default SimpleMultiValue;
