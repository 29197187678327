import { LanguageCode } from 'humanize-duration';
import { mapValues } from 'remeda';
import embedded_en from './en/embedded_en.json';
import en from './en/en.json';

export const defaultLocales = { ...en, ...embedded_en };

const localizationKeys = {
  main: Object.keys(en),
  embedded: Object.keys(embedded_en),
};

export const markValuesWithLineNumbers = (
  locales: Record<string, string>
) => {
  if (localStorage.getItem('localizationToolsEnabled') !== 'true') {
    return locales;
  }

  return mapValues(locales, (value, key) => {
    const findIn = (allKeys: string[], type: string) => {
      const index = allKeys.indexOf(key);
      if (index >= 0) {
        // line numbers are 1-based and first line is always "{", that's why +2
        return `[${type}${index + 2}] ${value}`;
      }
      return undefined;
    };

    return (
      findIn(localizationKeys.main, '') ??
      findIn(localizationKeys.embedded, 'E') ??
      value
    );
  });
};


export const intlLocales = ["en", "zh", "cs", "da", "de", "es", "fi", "fr", "hr", "hu", "it", "ja", "nb", "nl", "pl", "pt", "pt-br", "sl", "sq", "sr", "sv", "tr"] as const;
export type IntlLocale = typeof intlLocales[number];

export const humanizeDurationLocaleMap: Record<string, LanguageCode> = {
  zh: 'zh_CN',
  'pt-br': 'pt',
};

export type NeverTranslated<K extends string = '%'> = TranslationEnv["enabled"] extends false ? never : `missing:${K}`;
export type LocalizationKeys = TranslationEnv["enabled"] extends false ?
  | keyof (typeof defaultLocales) : NeverTranslated;
