import { alpha, Checkbox, Chip, Stack, Typography } from '@rossum/ui/material';
import {
  difference,
  every,
  flatten,
  get,
  includes,
  intersection,
  isArray,
  some,
  union,
  without,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../i18n';
import SearchInput from '../../ui/search-input/SearchInput';
import Scrollable from '../Scrollable';
import Collapse from '../UI/Collapse';
import styles from './styles.module.sass';

export type OptionT<T extends number | string = number> = {
  label: string;
  value: T | Array<{ label: string; value: T }>;
};

type Props<T extends number | string> = {
  dataCy?: string;
  titleId?: LocalizationKeys;
  options: Array<OptionT<T>>;
  onSearch: (search: string) => void;
  onPick: (values: Array<T>) => void;
  isSearched: boolean;
  picked: Array<T>;
  noResultsPlaceholder?: React.ReactNode;
};

const createOption =
  <T extends number | string>({
    picked,
    onPick,
  }: Pick<Props<T>, 'picked' | 'onPick'>) =>
  ({ label, value }: { label: string; value: T }) => (
    <div className={styles.Option} key={String(value)}>
      <span>{label}</span>
      <Checkbox
        sx={{ p: 0, flexShrink: 0 }}
        size="small"
        id={String(value)}
        onChange={() =>
          onPick(
            includes(picked, value)
              ? without(picked, value)
              : [...picked, value]
          )
        }
        value={label}
        checked={includes(picked, value)}
      />
    </div>
  );

const Picker = <T extends number | string>({
  dataCy,
  isSearched,
  titleId,
  picked: propsPicked,
  options,
  onSearch,
  onPick,
  noResultsPlaceholder,
}: Props<T>) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  useEffect(() => onSearch(search), [onSearch, search]);

  const optionValues = options.map(({ value }: OptionT<T>) =>
    isArray(value) ? value.map(({ value }) => value) : value
  );
  const allValues = flatten(optionValues);
  const totalOptions = allValues.length;
  const picked = intersection(propsPicked, allValues) as T[];
  const allPicked = picked.length === totalOptions;

  return (
    <Stack
      flex="2"
      sx={{
        overflow: 'hidden',
        borderBottom: theme => `1px solid ${theme.palette.other.muted}`,
        '&:nth-of-type(2)': {
          flex: 3,
        },
      }}
    >
      <Stack
        spacing={1}
        sx={{
          mx: 1.5,
          pb: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 1,
            minHeight: 29,
            height: 17,
          }}
        >
          <Stack
            direction="row"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              pr: 1,
            }}
          >
            <Typography variant="body2" fontWeight="bold" lineHeight="1.1">
              {titleId
                ? intl.formatMessage({
                    id: titleId,
                  })
                : ''}
            </Typography>
            {totalOptions > 1 && (
              <span
                data-cy={dataCy}
                onClick={() => onPick(allPicked ? [] : allValues)}
              >
                <Typography
                  textAlign="right"
                  fontWeight="bold"
                  sx={{
                    cursor: 'pointer',
                    fontSize: 13,
                    transition: 'color 0.2s',
                    ml: 1.5,
                    color: theme => theme.palette.text.disabled,
                    '&:hover': {
                      color: theme => alpha(theme.palette.text.disabled, 0.6),
                    },
                  }}
                >
                  {intl.formatMessage({
                    id: allPicked
                      ? 'components.picker.deselectAll'
                      : 'components.picker.selectAll',
                  })}
                </Typography>
              </span>
            )}
          </Stack>
          {!!totalOptions && (
            <Chip label={`${picked.length} / ${totalOptions}`} />
          )}
        </Stack>
        <SearchInput
          onChange={setSearch}
          value={search}
          sx={{ width: '100%' }}
        />
      </Stack>
      {totalOptions ? (
        <Scrollable>
          <div className={styles.Options}>
            {options.map(({ label, value }: OptionT<T>) => {
              const isNested = Array.isArray(value);
              const values = (
                isNested ? value.map(v => v.value) : [value]
              ) as T[];
              const semiChecked = some(values, val => includes(picked, val));
              const checked =
                !!picked.length && every(values, val => includes(picked, val));
              const key = values.join('');
              return isNested ? (
                <Collapse
                  headerClassName={styles.CollapsibleOption}
                  nameComponent={({ name }: Record<'name', string>) => (
                    <span className={styles.WorkspaceName}>{name}</span>
                  )}
                  name={label}
                  childrenLength={get(value, 'length')}
                  forceControlElements
                  defaultExpand={semiChecked}
                  forceExpand={isSearched}
                  smallArrow
                  key={key}
                  controlElements={
                    <div className={styles.NestedControls}>
                      <Checkbox
                        size="small"
                        indeterminate={!checked && semiChecked}
                        onChange={() =>
                          onPick(
                            checked
                              ? difference(picked, values)
                              : union(picked, values)
                          )
                        }
                        id={key}
                        checked={checked}
                      />
                    </div>
                  }
                >
                  <div>{value.map(createOption({ onPick, picked }))}</div>
                </Collapse>
              ) : (
                createOption({ onPick, picked })({ label, value })
              );
            })}
          </div>
        </Scrollable>
      ) : (
        noResultsPlaceholder
      )}
    </Stack>
  );
};

export default Picker;
