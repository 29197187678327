import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AnnotationsActionModal from '../../../../../components/AnnotationsActionModal';
import { isEmbedded } from '../../../../../constants/config';
import { DrawerConfig } from '../../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { snakeToCamel } from '../../../../../lib/keyConvertor';
import { rejectAnnotation } from '../../../../../redux/modules/annotation/actions';
import {
  annotationSelector,
  rejectionEnabledSelector,
} from '../../../../../redux/modules/annotation/selectors';
import { postponeAnnotations } from '../../../../../redux/modules/annotations/actions';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import { Annotation } from '../../../../../types/annotation';
import { State } from '../../../../../types/state';
import { SidebarFooterAction } from './utils';

type UsePostponeAndRejectActionResult = {
  modal: JSX.Element | null;
  reject: SidebarFooterAction | null;
  postpone: SidebarFooterAction | null;
};

export const usePostponeAndRejectAction = (
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void
): UsePostponeAndRejectActionResult => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const annotation = useSelector(annotationSelector);

  const restrictedAccess = !!annotation?.restrictedAccess;

  const annotationStatus = annotation ? snakeToCamel(annotation.status) : null;

  const isConfirming = useSelector((state: State) => state.ui.confirming);

  const isReadOnly = useSelector((state: State) => state.ui.readOnly);

  const [actionModalOpen, setActionModalOpen] = useState<
    'annotationSkip' | 'reject' | null
  >(null);

  const isPostponable =
    !restrictedAccess &&
    (annotationStatus === 'confirmed' || !(isConfirming || isReadOnly));

  const rejectionEnabled = useSelector(rejectionEnabledSelector);

  const embeddedConfig = useSelector((state: State) => state.ui.embeddedConfig);

  const showActionModal = useCallback((modal: 'annotationSkip' | 'reject') => {
    setActionModalOpen(modal);
  }, []);

  const hideActionModal = useCallback(() => {
    setActionModalOpen(null);
  }, []);

  const handlePostponeClick = useCallback(() => {
    showActionModal('annotationSkip');
  }, [showActionModal]);

  const handleRejectClick = useCallback(() => {
    showActionModal('reject');
  }, [showActionModal]);

  const postponeAndRejectActions = useMemo(
    () => (annotationToInteractWith: Annotation) => ({
      annotationSkip: {
        onPrimaryActionClick: () => {
          dispatch(
            pauseValidation({
              nextAction: postponeAnnotations([annotationToInteractWith.url]),
              trigger: 'postponeAnnotation',
              reason: 'surveys',
            })
          );
        },
        onSecondaryActionClick: () => {
          onEmailThreadOpen({
            defaultFormMode: 'postponeDocument',
            highlightedEmailUrl: annotationToInteractWith.email ?? undefined,
          });
        },
      },
      reject: {
        onPrimaryActionClick: () => {
          onEmailThreadOpen({
            defaultFormMode: 'rejectDocument',
            highlightedEmailUrl: annotationToInteractWith.email ?? undefined,
          });
        },
        onSecondaryActionClick: () => {
          dispatch(rejectAnnotation(annotationToInteractWith.url, true));
        },
      },
    }),
    [dispatch, onEmailThreadOpen]
  );

  const postponeAndRejectModals = useMemo(
    () =>
      actionModalOpen && annotation ? (
        <AnnotationsActionModal
          action={actionModalOpen}
          annotationsLength={1}
          onClose={hideActionModal}
          onPrimaryActionClick={
            postponeAndRejectActions(annotation)[actionModalOpen]
              .onPrimaryActionClick
          }
          onSecondaryActionClick={
            !isEmbedded()
              ? postponeAndRejectActions(annotation)[actionModalOpen]
                  .onSecondaryActionClick
              : undefined
          }
          secondaryButtonText={
            !isEmbedded()
              ? intl.formatMessage({
                  id: `components.modal.${actionModalOpen}.secondary`,
                })
              : undefined
          }
          dataCyCancel={`${actionModalOpen}-dialog-cancel-btn`}
          dataCySecondary={
            !isEmbedded()
              ? `${actionModalOpen}-dialog-secondary-btn`
              : undefined
          }
          dataCyConfirm={`${actionModalOpen}-dialog-primary-btn`}
        />
      ) : null,
    [
      actionModalOpen,
      annotation,
      hideActionModal,
      intl,
      postponeAndRejectActions,
    ]
  );

  const postponeVisible =
    !isEmbedded() || (isEmbedded() && embeddedConfig?.postponeUrl);

  const rejectVisible = !isEmbedded() && rejectionEnabled;

  const result = useMemo(() => {
    return {
      modal: postponeAndRejectModals,
      postpone: postponeVisible
        ? {
            id: 'postpone' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.postpone',
            }),
            disabled: !annotation || !isPostponable,
            onClick: handlePostponeClick,
            dataCy: 'annotation-sidebar-postpone',
          }
        : null,
      reject: rejectVisible
        ? {
            id: 'reject' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.reject',
            }),
            disabled:
              !annotation ||
              restrictedAccess ||
              annotationStatus !== 'reviewing',
            onClick: handleRejectClick,
            dataCy: 'annotation-detail-reject-btn',
          }
        : null,
    };
  }, [
    annotation,
    annotationStatus,
    handlePostponeClick,
    handleRejectClick,
    intl,
    isPostponable,
    postponeAndRejectModals,
    postponeVisible,
    rejectVisible,
    restrictedAccess,
  ]);

  return result;
};
