import { Hook } from '@rossum/api-client/hooks';
import { Typography, useTheme } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../lib/helpers';
import IFrameError from './components/IFrameError';
import { IFrameLoading } from './components/IFrameLoading';
import { getConfigAppOrigin } from './helpers';
import { usePostMessages } from './hooks/usePostMessages';

type Props = {
  configAppUrl: string;
  selectedExtension: Hook;
  onLoad: (value: boolean) => void;
  isLoaded: boolean;
  setShouldRefetch: (shouldRefetch: boolean) => void;
  onClose: () => void;
  setShouldLeaveSafely: (shouldLeaveSafely: boolean) => void;
};

const ConfigAppContainer = ({
  configAppUrl,
  selectedExtension,
  onLoad,
  isLoaded,
  setShouldRefetch,
  onClose,
  setShouldLeaveSafely,
}: Props) => {
  const theme = useTheme();
  const configAppUrlAbsolute = toAbsoluteUrl(configAppUrl);
  const configAppOrigin = getConfigAppOrigin(configAppUrlAbsolute);
  const intl = useIntl();

  const isFullscreen =
    selectedExtension.config.app?.displayMode === 'fullscreen';

  usePostMessages({
    configAppOrigin,
    selectedExtension,
    setShouldRefetch,
    onClose,
    setShouldLeaveSafely,
  });

  return (
    <>
      <iframe
        style={{
          display: 'block',
          position: 'relative',
          zIndex: 1,
          border: 'none',
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
        src={configAppUrlAbsolute}
        title="Config App"
        onLoad={() => onLoad(true)}
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      />
      {isLoaded ? (
        <IFrameError />
      ) : (
        <IFrameLoading
          message={
            <Typography variant="h5">
              {intl.formatMessage({
                id: isFullscreen
                  ? 'containers.settings.extensions.configApp.fullscreenLoading'
                  : 'containers.settings.extensions.configApp.isLoading',
              })}
            </Typography>
          }
        />
      )}
    </>
  );
};

export default ConfigAppContainer;
