import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { Organization } from '../../../types/organization';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import {
  fetchOrganizationAdminsFulfilled,
  fetchOrganizationFulfilled,
  updateOrganization,
} from './actions';

const initialState: Partial<Organization> = {
  id: undefined,
  isTrial: undefined,
  oidcProvider: undefined,
  organizationGroup: undefined,
  metadata: {},
  name: '',
  uiSettings: {},
  url: '',
  users: [],
  workspaces: [],
  admins: [],
  sandbox: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchOrganizationFulfilled):
      return R.mergeDeep(state, action.payload);

    case getType(fetchOrganizationAdminsFulfilled):
      return { ...state, admins: action.payload.results };

    case getType(updateOrganization):
      return R.mergeDeep(state, action.payload);

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
