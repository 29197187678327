import { useIntl } from 'react-intl';
import {
  Breadcrumb,
  Breadcrumbs,
} from '../../components/BreadcrumbsV2/Breadcrumbs';
import { myExtensionsPath } from './helpers';

export const ExtensionsBreadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const intl = useIntl();

  const extensionsBreadcrumbs = [
    {
      to: myExtensionsPath(),
      label: intl.formatMessage({ id: 'components.appBar.extensions' }),
    },
    ...breadcrumbs,
  ];

  return <Breadcrumbs breadcrumbs={extensionsBreadcrumbs} />;
};
