import { z } from 'zod';

type ErrorKeys = 'unknown' | 'invalidTargetLanguage';
type GetTranslationErrorResult = { errorKey: ErrorKeys };

const objectSchema = z.record(z.unknown());
const stringArraySchema = z.array(z.string());

export const getTranslationError = (
  error: unknown
): GetTranslationErrorResult => {
  const parsedError = objectSchema.safeParse(error);

  if (!parsedError.success) return { errorKey: 'unknown' };

  return Object.entries(parsedError.data).reduce<GetTranslationErrorResult>(
    (acc, [key, value]) => {
      const parsed = stringArraySchema.safeParse(value);

      if (!parsed.success) return acc;

      if (
        key === 'target_language' &&
        parsed.data.some(v =>
          v.toLocaleLowerCase().includes('is not a valid choice')
        )
      ) {
        acc.errorKey = 'invalidTargetLanguage';
        return acc;
      }

      return acc;
    },
    { errorKey: 'unknown' }
  );
};
