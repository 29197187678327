import { ApplyLabelPayload, Label } from '@rossum/api-client/labels';
import {
  CircularProgress,
  Dialog,
  MenuItem,
  MenuList,
  Stack,
} from '@rossum/ui/material';
import { useIsMutating } from '@tanstack/react-query';
import { intersection, without } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { throwError } from '../../redux/modules/messages/actions';
import { MENU_MAX_HEIGHT } from './components/constants';
import { EmptyListLabels } from './components/EmptyListLabels';
import { CreateLabelDialog } from './components/LabelDialogs';
import { LabelMenuActions } from './components/LabelMenuActions';
import { LabelMenuItem } from './components/LabelMenuItem';
import { Loading } from './components/Loading';
import { useRequestUnpaginatedLabels } from './hooks/useRequestLabels';
import { MUTATION_KEY_LABELS_APPLY } from './hooks/useRequestLabelsApply';

type OnLabelArgument = {
  payload: ApplyLabelPayload;
  onSuccess?: () => void;
  onError?: () => void;
};

export type OnLabelAction = (args: OnLabelArgument) => void;

type AnnotationsLabelsListProps = {
  annotations: { labels: Array<string>; url: string }[];
  onLabelsChanged: OnLabelAction;
  onClose: () => void;
  isClosing?: boolean;
};

const defaultOperations: Required<ApplyLabelPayload['operations']> = {
  add: [],
  remove: [],
};

export const AnnotationsLabelsList = ({
  annotations,
  onLabelsChanged,
  onClose,
  isClosing = false,
}: AnnotationsLabelsListProps) => {
  const menuListRef = useRef<HTMLUListElement>(null);
  const [createdLabel, setCreatedLabel] = useState<Label | null>(null);

  const [operations, setOperations] =
    useState<Required<ApplyLabelPayload['operations']>>(defaultOperations);

  const changedLabels = [...operations.add, ...operations.remove];

  const initialState = useMemo(() => {
    const annotationsLabels = annotations.map(({ labels }) => labels);

    const allChecked = intersection(...annotationsLabels);
    const indeterminates = without(annotationsLabels.flat(), ...allChecked);

    return {
      indeterminates,
      allChecked,
    };
  }, [annotations]);

  const labelsAreApplying = !!useIsMutating({
    mutationKey: [MUTATION_KEY_LABELS_APPLY],
  });

  const { data, isInitialLoading: isLoading } = useRequestUnpaginatedLabels();

  const dispatch = useDispatch();
  const applyLabels = () => {
    const annotationUrls = annotations.map(({ url }) => url);
    const payload = {
      operations,
      objects: { annotations: annotationUrls },
    };

    onLabelsChanged({
      payload,
      onSuccess: () => {
        setOperations(defaultOperations);
        onClose();
      },
      onError: () => {
        dispatch(throwError('labelApplyError'));
      },
    });
  };

  const [showCreateLabelDialog, setShowCreateLabelDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (createdLabel && menuListRef.current && data) {
      // Using data.length, and not data.length -1, ensures that we scroll just at the top edge
      // of the createdLabel.
      const relativePosition =
        data.findIndex(label => label.id === createdLabel.id) / data.length;

      // see https://github.com/facebook/react/issues/23396 for why we don't use scrollIntoView
      menuListRef.current.scrollTo({
        top: menuListRef.current.scrollHeight * relativePosition,
        behavior: 'smooth',
      });
    }
  }, [createdLabel, data]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : data?.length ? (
        <Stack minHeight="inherit">
          <MenuList
            ref={menuListRef}
            sx={{
              maxHeight: MENU_MAX_HEIGHT - 100,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {data.map(label => {
              const isChanged = changedLabels.includes(label.url);
              const checkedProps = isChanged
                ? {
                    checked: operations.add.includes(label.url),
                    indeterminate: undefined,
                  }
                : {
                    checked: initialState.allChecked.includes(label.url),
                    indeterminate: initialState.indeterminates.includes(
                      label.url
                    ),
                  };

              const shouldAppend =
                (isChanged &&
                  initialState.indeterminates.includes(label.url)) ||
                initialState.allChecked.includes(label.url);

              const handleChange = () => {
                if (checkedProps.checked) {
                  return setOperations(prev => ({
                    add: without(prev.add, label.url),
                    remove: shouldAppend
                      ? [...prev.remove, label.url]
                      : prev.remove,
                  }));
                }
                return setOperations(prev => ({
                  add: shouldAppend ? prev.add : [...prev.add, label.url],
                  remove: without(prev.remove, label.url),
                }));
              };

              return (
                <MenuItem key={label.id} dense onClick={handleChange}>
                  <LabelMenuItem label={label} {...checkedProps} />
                </MenuItem>
              );
            })}
          </MenuList>

          <LabelMenuActions
            shouldShowApplyButton={isClosing || !!changedLabels.length}
            applyButtonProps={{
              endIcon: labelsAreApplying && (
                <CircularProgress size={18} color="inherit" />
              ),
              onClick: applyLabels,
              disabled: changedLabels.length === 0,
            }}
            onCreateLabel={() => setShowCreateLabelDialog(true)}
          />
        </Stack>
      ) : (
        <EmptyListLabels>
          <LabelMenuActions
            shouldShowApplyButton={false}
            onCreateLabel={() => setShowCreateLabelDialog(true)}
          />
        </EmptyListLabels>
      )}
      <Dialog open={showCreateLabelDialog} PaperProps={{ elevation: 2 }}>
        <CreateLabelDialog
          onCancel={() => setShowCreateLabelDialog(false)}
          onSuccess={newLabel => {
            setOperations(prev => ({
              ...prev,
              add: [...prev.add, newLabel.url],
            }));
            setCreatedLabel(newLabel);
          }}
        />
      </Dialog>
    </>
  );
};
