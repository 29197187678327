import { Organization } from '@rossum/api-client/organization';
import { escapeRegExp } from 'lodash';

export const filterUserOrganizations = (
  search: string,
  userOrganizations: Organization[]
) =>
  userOrganizations.filter(
    ({ name, id }: Organization) =>
      `${name} ${id}`.toUpperCase().search(escapeRegExp(search.toUpperCase())) >
      -1
  );
