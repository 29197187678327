import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { GeneratePayloadPayload } from '@rossum/api-client/hooks';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { throwError } from '../../../redux/modules/messages/actions';

export const QUERY_KEY_GENERATE_PAYLOAD = 'hook-generate-payload';

// even though it's a POST call I don't believe this is a mutation but a query
export const useGeneratePayload = (
  hookId: number,
  payload: GeneratePayloadPayload
) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY_GENERATE_PAYLOAD, hookId],
    queryFn: () => {
      const api_payload = endpoints.hooks.generatePayload(hookId, payload);
      const request = api.request(api_payload);
      return request;
    },
    enabled: false,

    // retry with a 2 second delay as long as 409 is returned
    retry: (_failureCount, error) =>
      error instanceof HttpError && error.code === 409,

    retryDelay: 2 * 1000,
    cacheTime: 0,

    onError: (error: Record<string, unknown>) => {
      dispatch(
        throwError('generatePayloadError', undefined, {
          response: JSON.stringify(error.data),
        })
      );
    },
  });
};
