import { useEffect } from 'react';

export const usePreventSwipeNavigation = () => {
  useEffect(() => {
    const style = document.createElement('style');
    // boldly set overscroll-behavior to contain for all elements, be careful with usage
    style.textContent = `
      html, html * {
        overscroll-behavior: contain !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
};
