import { clamp } from 'remeda';

type CalculateGapsOptions = {
  maxSize: number;
  minSize: number;
  scaleFactor: number;
};

/**
 * Places items of sizes between maxSize and minSize into positions if possible.
 * If there is a lot of space between positions, it approaches the maxSize.
 * If there is very little space, it takes up to minSize and moves the subsequent items.
 * Furthermore, it can be scaled to some zoom level (is there a better way to compose this?).
 */
export const calculateGaps = (
  positions: number[],
  options: CalculateGapsOptions
) => {
  const { maxSize, minSize, scaleFactor } = options;
  return positions.reduce<{ offset: number; position: number; size: number }[]>(
    (acc, pos, index, arr) => {
      const prev = acc[index - 1] ?? { offset: 0, position: 0, size: 0 };
      const nextPos = arr[index + 1];
      const position = Math.max(prev.position + prev.size / scaleFactor, pos);
      const size = nextPos
        ? clamp(Math.abs(position - nextPos) * scaleFactor, {
            min: minSize,
            max: maxSize,
          })
        : maxSize;

      const offset = (position - pos) * scaleFactor;
      return [...acc, { position, size, offset }];
    },
    []
  );
};
