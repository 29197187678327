import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useExtensionConfigAppContext } from './context/configAppContext';

const ConfigAppButton = () => {
  const intl = useIntl();
  const { openConfigApp, isFullScreen, name } = useExtensionConfigAppContext();

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={openConfigApp}
      data-cy="extension-config-app-button"
      sx={{
        maxWidth: 340,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block',
      }}
    >
      {intl.formatMessage(
        {
          id: isFullScreen
            ? 'containers.settings.extensions.configApp.open'
            : 'containers.settings.extensions.configApp.configure',
        },
        { name }
      )}
    </Button>
  );
};

export default ConfigAppButton;
