import { Chip, Tab, Tabs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { rulesEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { queueSettingsPages } from '../fields/constants';

export const QueueSettingsTabs = ({
  value,
  parentPath,
}: {
  value: (typeof queueSettingsPages)[number];
  parentPath: string;
}) => {
  const intl = useIntl();

  const { push } = useHistory();

  const rulesEnabled = useSelector(rulesEnabledSelector);
  const availablePages = rulesEnabled
    ? queueSettingsPages
    : queueSettingsPages.filter(page => page !== 'rules');

  return (
    <Tabs
      value={value}
      onChange={(_, newValue) => push(`${parentPath}/${newValue}`)}
    >
      {availablePages.map(page => (
        <Tab
          key={page}
          label={intl.formatMessage({
            id: `containers.settings.queues.queue.${page}`,
          })}
          value={page}
          data-cy={`queue-settings-header-tab-${page}`}
          sx={{ minHeight: '48px' }}
          icon={
            page === 'rules' ? (
              <Chip
                label={intl.formatMessage({ id: 'component.betaChip.label' })}
                color="warning"
              />
            ) : undefined
          }
          iconPosition="end"
        />
      ))}
    </Tabs>
  );
};
