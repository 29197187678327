import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { organizationSelector } from '../../../../../redux/modules/organization/selectors';
import { isUserAnnotatorBetaSelector } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { State } from '../../../../../types/state';
import { useDownloadOriginalDocuments } from '../../../../tasks/hooks/useDownloadOriginalDocuments';
import { SidebarFooterAction } from './utils';

type UseDownloadActionResult = {
  action: SidebarFooterAction | null;
};

export const useDownloadAction = (
  annotation: Annotation
): UseDownloadActionResult => {
  const intl = useIntl();

  const { restrictedAccess } = annotation;

  const fileDocument = useSelector(
    (state: State) => state.annotation.sideloads.document
  );

  const { mutate: downloadOriginalDocuments } = useDownloadOriginalDocuments();

  const disableDownloadOriginal = useSelector(
    (state: State) =>
      organizationSelector(state).uiSettings?.features
        ?.disableDownloadOriginal ?? false
  );

  const isUserAnnotatorBeta = useSelector(isUserAnnotatorBetaSelector);

  const handleDownloadOriginal = useCallback(() => {
    if (fileDocument) {
      downloadOriginalDocuments({ documents: [fileDocument.url] });
    }
  }, [downloadOriginalDocuments, fileDocument]);

  const downloadVisible =
    (isEmbedded() && !disableDownloadOriginal) ||
    (!isEmbedded() && !disableDownloadOriginal && !isUserAnnotatorBeta);

  const result = useMemo(() => {
    return {
      action: downloadVisible
        ? {
            id: 'download' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.download',
            }),
            disabled: !fileDocument || restrictedAccess,
            onClick: handleDownloadOriginal,
            dataCy: 'annotation-sidebar-download',
          }
        : null,
    };
  }, [
    downloadVisible,
    fileDocument,
    handleDownloadOriginal,
    intl,
    restrictedAccess,
  ]);

  return result;
};
