import {
  IconCustomChevronDownBar,
  IconCustomChevronUpbar,
} from '@rossum/ui/icons/tabler';
import {
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

type PageNavigationProps = {
  currentPage: number;
  totalPages: number;
  onNavigate: (page: number) => void;
};

export const PageNavigation = ({
  currentPage,
  totalPages,
  onNavigate,
}: PageNavigationProps) => {
  const theme = useTheme();

  const intl = useIntl();

  const [hasFocus, setHasFocus] = useState(false);

  const [pageInputState, setPageInputState] = useState(currentPage.toString());

  const handlePageInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(e => {
      setPageInputState(e.target.value);
    }, []);

  const handleNavigate = useCallback(
    (page: number) => {
      if (page < 1 || page > totalPages) {
        return;
      }

      onNavigate(page);
    },
    [onNavigate, totalPages]
  );

  const handleFocus = useCallback(() => {
    setHasFocus(true);
    setPageInputState(currentPage.toString());
  }, [currentPage]);

  const handleBlur = useCallback(() => {
    setHasFocus(false);
    setPageInputState(currentPage.toString());
  }, [currentPage]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    e => {
      e.stopPropagation();
      if (e.key === 'Enter' || e.key === 'Tab') {
        handleNavigate(Number(pageInputState));
      }
    },
    [handleNavigate, pageInputState]
  );

  // Jump buttons
  const isOnLastPage = currentPage === totalPages;

  const handleJumpClick = useCallback(() => {
    if (isOnLastPage) {
      handleNavigate(1);
    } else {
      handleNavigate(totalPages);
    }
  }, [handleNavigate, isOnLastPage, totalPages]);

  return (
    <Stack spacing={1} direction="row">
      <TextField
        size="small"
        variant="outlined"
        autoComplete="off"
        type="number"
        value={hasFocus ? pageInputState : currentPage}
        onChange={hasFocus ? handlePageInputChange : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <Stack
              spacing={0.5}
              sx={{
                pointerEvents: 'none',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                /{totalPages}
              </Typography>
            </Stack>
          ),
          sx: {
            ...theme.typography.body2,
          },
        }}
        inputProps={{
          min: 1,
          max: totalPages,
          style: {
            padding: '4px 2px',
            textAlign: 'right',
            width: '3ch',
            fontSize: '1.4rem',
          },
        }}
        sx={{
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        }}
      />
      {totalPages > 1 ? (
        <Tooltip
          title={intl.formatMessage({
            id: isOnLastPage
              ? 'components.topbarV2.actions.goToFirstPage'
              : 'components.topbarV2.actions.goToLastPage',
          })}
        >
          <IconButton size="small">
            <SvgIcon fontSize="small" onClick={handleJumpClick}>
              {isOnLastPage ? (
                <IconCustomChevronUpbar />
              ) : (
                <IconCustomChevronDownBar />
              )}
            </SvgIcon>
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
};
