import * as R from 'remeda';

// Utils to define MQL types.
export type MQLElemMatch<T> = { $elemMatch: T };
export type MQLIn<T> = { $in: T[] };
export type MQLRegex<T> = { $regex: T };
export type MQLNot<T> = { $not: T };
export type MQLOr<T> = { $or: T[] };
export type MQLGt<T = number> = { $gt: T };
export type MQLAnd<T> = { $and: T[] } | { $and: Record<string, T> };

export const parseMQLAnd = <T>(value: T | MQLAnd<T> | undefined): T[] =>
  value
    ? typeof value === 'object' && '$and' in value
      ? R.isArray(value)
        ? (value.$and as T[])
        : Object.values(value.$and)
      : [value]
    : [];

export const parseMQLOr = <T>(value: T | MQLOr<T> | undefined): T[] =>
  value
    ? typeof value === 'object' && '$or' in value
      ? R.isArray(value)
        ? (value.$or as T[])
        : Object.values(value.$or)
      : [value]
    : [];
