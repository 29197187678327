import { useEffect, useState } from 'react';

export const useKeyPressed = ({
  keys,
  metaKey,
  options = { enabled: true },
}: {
  keys: string[];
  metaKey?: 'ctrlKey' | 'altKey' | 'shiftKey';
  options?: { enabled?: boolean };
}) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const doesMetaKeyPermit = metaKey ? e[metaKey] : true;
      if (doesMetaKeyPermit && keys.some(key => e.key === key)) {
        setKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      const doesMetaKeyPermit = metaKey ? e[metaKey] : true;
      if (doesMetaKeyPermit && keys.some(key => e.key === key)) {
        setKeyPressed(false);
      }
    };

    const resetKeyPress = () => {
      setKeyPressed(false);
    };

    if (options.enabled) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      window.addEventListener('focus', resetKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('focus', resetKeyPress);
    };
  }, [metaKey, keys, options.enabled]);

  return keyPressed;
};
