import { Stack } from '@rossum/ui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Scrollable from '../Scrollable';

const CONTAINER_WIDTH = 1024;
const DEFAULT_HEADER_HEIGHT = 180;

type PageLayoutV2Props = {
  children: ReactNode;
  renderHeader?: (params: {
    scrollableDivRef: React.RefObject<HTMLDivElement>;
  }) => ReactNode;
  renderFooter?: () => ReactNode;
  fullWidth?: boolean;
};

export const PageLayoutV2 = ({
  children,
  renderHeader,
  renderFooter,
  fullWidth,
}: PageLayoutV2Props) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // `headerHeight` sets the top padding of the content.
  // It acts as an invisible wrapper that maintains the height of the expanded header,
  // even when the header collapses, preventing the content from shifting.
  const [headerHeight, setHeaderHeight] = useState<number>(
    DEFAULT_HEADER_HEIGHT
  );

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [location]);

  return (
    <Scrollable ref={scrollableDivRef}>
      <Stack
        justifyContent="space-between"
        sx={{
          minHeight: '100%',
          backgroundColor: t => t.palette.background.default,
        }}
      >
        <Stack minHeight="100%" flexGrow={1}>
          <Stack
            minHeight={headerHeight}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              pointerEvents: 'none', // useful when header is collapsed for content under wrapper to be clickable
            }}
          >
            <Stack ref={headerRef} sx={{ pointerEvents: 'auto' }}>
              {renderHeader?.({ scrollableDivRef }) ?? null}
            </Stack>
          </Stack>
          <Stack width={fullWidth ? 1 : CONTAINER_WIDTH} mx="auto" flex={1}>
            {children}
          </Stack>
        </Stack>
        {renderFooter ? (
          <Stack
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: t => t.palette.background.default,
            }}
          >
            {renderFooter()}
          </Stack>
        ) : null}
      </Stack>
    </Scrollable>
  );
};
