import { getIDFromUrl } from '@rossum/api-client';
import { Box, Button, Chip, ListItemProps, Stack } from '@rossum/ui/material';
import { paperBackgroundMap } from '@rossum/ui/theme';
import deepEqual from 'fast-deep-equal/es6/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { assertNever } from '../../../../lib/typeUtils';
import { datapointPathSelector } from '../../../../redux/modules/datapoints/selector';
import {
  sidebarFieldIdsEnabledSelector,
  sidebarHiddenFieldsVisibleSelector,
} from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { SidebarItemLabel } from '../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../shared/SidebarItemLayout';
import {
  SidebarMultivalueChildFieldModel,
  SidebarMultivalueFieldModel,
} from '../useSidebarData';
import { MultivalueChildItem } from './MultivalueChildItem';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { MultivalueInactiveChildren } from './shared/MultivalueInactiveChildren';
import {
  addRange,
  findClosestInSet,
  getFieldsSettingsPath,
  removeAdjacentRanges,
} from './utils';

type MultivalueItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarMultivalueFieldModel;
  active: boolean;
  selected?: boolean;
  focused?: boolean;
  disabled: boolean;
  addValueActive: boolean;
  onClick: (datapointId: number) => void;
  onChange: (item: SidebarMultivalueChildFieldModel, newValue: string) => void;
  onAddChild: (parentIndex: number) => void;
  onDeleteChild: (pathToDelete: Array<number>) => void;
  onDeleteAll: (parentIndex: number) => void;
};

type BatchSelectionParams =
  | {
      type: 'toggle';
      isAlreadyIncluded: boolean;
      datapointIndex: number;
    }
  | {
      type: 'range';
      datapointIndex: number;
    }
  | {
      type: 'select';
      datapointIndex: number;
    }
  | {
      type: 'clear';
    };

const MultivalueItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    active,
    focused,
    selected,
    addValueActive,
    onClick,
    onChange,
    onAddChild,
    onDeleteChild,
    onDeleteAll,
    disabled,
    ...listItemProps
  }: MultivalueItemProps) => {
    const currentDatapointPath = useSelector(datapointPathSelector);

    const intl = useIntl();

    const handleClick = useCallback(() => {
      onClick(item.id);
    }, [item.id, onClick]);

    // batch selection
    const rangeSelectionAnchorRef = useRef<number | null>(null);
    const setRangeAnchor = useCallback((anchor: number | null) => {
      rangeSelectionAnchorRef.current = anchor;
    }, []);
    const [batchSelection, setBatchSelection] = useState<Set<number>>(
      new Set()
    );

    const handleBatchSelection = useCallback(
      (params: BatchSelectionParams) => {
        const { type } = params;

        switch (type) {
          case 'toggle': {
            return setBatchSelection(prevSelection => {
              // REMOVE IT, if datapoint is already batch selected
              if (params.isAlreadyIncluded) {
                prevSelection.delete(params.datapointIndex);
                const newAnchor = findClosestInSet(
                  prevSelection,
                  params.datapointIndex
                );
                setRangeAnchor(newAnchor);
              } else {
                // ADD clicked datapoint, in case is not already batch selected
                prevSelection.add(params.datapointIndex);
                setRangeAnchor(params.datapointIndex);
              }

              return new Set(prevSelection);
            });
          }

          case 'range': {
            return setBatchSelection(prevSelection => {
              const anchor =
                // rangeSelectionAnchorRef.current set by click
                rangeSelectionAnchorRef.current ??
                // active child index when navigating with keyboard
                item.children.findIndex(
                  childItem => childItem.id === currentDatapointPath[2]
                ) ??
                // fallback to first child
                0;
              const updatedSet = R.pipe(
                prevSelection,
                removeAdjacentRanges(params.datapointIndex),
                removeAdjacentRanges(anchor),
                addRange(params.datapointIndex, anchor)
              );

              return new Set(updatedSet);
            });
          }

          case 'select': {
            setRangeAnchor(params.datapointIndex);
            return setBatchSelection(new Set([params.datapointIndex]));
          }

          case 'clear': {
            setRangeAnchor(null);
            return setBatchSelection(new Set([]));
          }

          default:
            return assertNever(type);
        }
      },
      [currentDatapointPath, item.children, setRangeAnchor]
    );

    const handleChildClick = useCallback(
      (params: {
        event: React.MouseEvent<HTMLElement>;
        datapointId: number;
        datapointIndex: number;
        selected: boolean;
        active: boolean;
      }) => {
        const isClickWithMeta =
          params.event.ctrlKey || params.event.metaKey || params.event.shiftKey;

        if (isClickWithMeta) {
          const activeChildId = currentDatapointPath[2];
          if (activeChildId) {
            // unselect currently active child datapoint
            handleClick();
          }

          if (params.event.ctrlKey || params.event.metaKey) {
            // META(COMMAND on MAC) and CTRL keys on rest of OS - add or remove datapoints one by one
            handleBatchSelection({
              type: 'toggle',
              datapointIndex: params.datapointIndex,
              isAlreadyIncluded: params.selected,
            });
          } else if (params.event.shiftKey) {
            // SHIFT key - add multiple datapoints in macos Finder style
            // deselect all text selections pottentially caused by Shift+click
            window.getSelection()?.removeAllRanges();
            handleBatchSelection({
              type: 'range',
              datapointIndex: params.datapointIndex,
            });
          }
        } else {
          // keep one selected when simple click
          handleBatchSelection({
            type: 'select',
            datapointIndex: params.datapointIndex,
          });

          // select datapoint if not meta click
          if (!params.active) {
            onClick(params.datapointId);
          }
        }
      },
      [currentDatapointPath, handleClick, handleBatchSelection, onClick]
    );

    // clears previous selection & focus on active item
    useEffect(() => {
      if (active) {
        window.getSelection()?.removeAllRanges();
      }
    }, [active]);

    useEffect(() => {
      if (batchSelection.size) {
        const handleResetSelectionOnEscPress = (e: KeyboardEvent) => {
          // Any key navigation + escape need to clear selection
          if (e.key === 'Escape' || e.key === 'Enter' || e.key === 'Tab') {
            // We need to capture escape otherwise it would deselect the entire datapoint
            if (e.key === 'Escape') {
              e.stopPropagation();
            }

            handleBatchSelection({ type: 'clear' });
          }
        };

        document.addEventListener('keydown', handleResetSelectionOnEscPress, {
          capture: true,
        });

        return () => {
          document.removeEventListener(
            'keydown',
            handleResetSelectionOnEscPress,
            { capture: true }
          );
        };
      }

      return () => {};
    }, [active, batchSelection.size, handleBatchSelection]);

    const hiddenFieldsVisible = useSelector(sidebarHiddenFieldsVisibleSelector);

    const isHidden = item.hidden && hiddenFieldsVisible;

    const isConfirmed = useMemo(() => {
      return item.validationSources.includes('human');
    }, [item.validationSources]);

    const iconVariant = useMemo(() => {
      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed]);

    const iconColor = useMemo(() => {
      if (isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed]);

    const isDisabled = disabled;

    const handleDeleteAllClick = useCallback(
      () => onDeleteAll(item.meta.datapointIndex),
      [item.meta.datapointIndex, onDeleteAll]
    );

    const handleDeleteActiveChildClick = useCallback(() => {
      onDeleteChild(currentDatapointPath);
    }, [currentDatapointPath, onDeleteChild]);

    const handleDeleteSelected = useCallback(() => {
      // TODO: @sidebarV2 - this is E!E copied from original sidebar implementation
      // if all dataPoints are selected, let's use only one delete children request
      // otherwise delete one by one
      if (batchSelection.size === item.children.length) {
        handleDeleteAllClick();
      } else {
        batchSelection.forEach(datapointIndex => {
          const childId = item.children[datapointIndex]?.id;
          if (childId) {
            onDeleteChild(currentDatapointPath.slice(0, 2).concat(childId));
          }
        });
      }

      handleBatchSelection({ type: 'clear' });
    }, [
      batchSelection,
      currentDatapointPath,
      handleBatchSelection,
      handleDeleteAllClick,
      item.children,
      onDeleteChild,
    ]);

    const handleAddClick = useCallback(
      () => onAddChild(item.meta.datapointIndex),
      [item.meta.datapointIndex, onAddChild]
    );

    const handleAddKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          onAddChild(item.meta.datapointIndex);
        }
      },
      [item.meta.datapointIndex, onAddChild]
    );

    const addButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      const button = addButtonRef.current;
      if (button) {
        if (addValueActive) {
          button.focus();
        } else {
          button.blur();
        }
      }
    }, [addValueActive]);

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <>
        <MagicContainer
          preventScroll={!!batchSelection.size}
          active={active}
          focused={focused}
          selected={selected}
          hasMagicLine={false}
          disabled={isDisabled}
          sidebarScrollableRef={sidebarScrollableRef}
          onClick={active ? undefined : handleClick}
          {...listItemProps}
        >
          <Stack sx={{ width: '100%' }}>
            <SidebarItemLayout
              iconSlot={
                <ItemContextActions
                  item={item}
                  active={active}
                  disabled={isDisabled}
                  iconProps={{
                    type: 'multiValue',
                    variant: iconVariant,
                    color: iconColor,
                  }}
                  fieldSettingsPath={getFieldsSettingsPath(
                    queueId,
                    // multivalues use children schema id for field settings route
                    item.childrenSchemaId
                  )}
                  variant={isHidden ? 'secondary' : 'primary'}
                />
              }
              labelSlot={
                <SidebarItemLabel
                  label={item.label}
                  sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
                  variant={isHidden ? 'secondary' : 'primary'}
                />
              }
              valueSlot={
                <Box sx={{ py: 1, pl: 1 }}>
                  <Chip label={item.children.length} />
                </Box>
              }
              decorationSlot={
                item.messagesStats ? (
                  <MessageIndicator messagesStats={item.messagesStats} />
                ) : null
              }
            />
            {active ? <Messages messages={item.messages} /> : null}
            {active || (focused && !active) ? null : (
              <MultivalueInactiveChildren childItems={item.children} />
            )}
          </Stack>
        </MagicContainer>
        {focused ? (
          <>
            {item.children.map((child, index) => {
              const childActive = currentDatapointPath.includes(child.id);
              const childSelected = batchSelection.has(index) && !childActive;

              return (
                <MultivalueChildItem
                  key={child.id}
                  active={childActive}
                  focused
                  onChange={onChange}
                  onClick={handleChildClick}
                  sidebarScrollableRef={sidebarScrollableRef}
                  annotation={annotation}
                  item={child}
                  index={index}
                  selected={childSelected}
                  disabled={isDisabled}
                />
              );
            })}
            {isDisabled ? null : (
              <Stack
                direction="row-reverse"
                spacing={1}
                alignItems="center"
                sx={{
                  p: 1,
                  pl: 2,
                  // TODO: @ui put paper backgrounds in theme
                  backgroundColor: theme =>
                    paperBackgroundMap[theme.palette.mode][4],
                }}
              >
                {!!batchSelection.size && currentDatapointPath.length === 2 ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDeleteSelected}
                  >
                    {intl.formatMessage({
                      id: 'components.sidebarv2.multivalue.deleteSelected',
                    })}
                  </Button>
                ) : (
                  <>
                    <Button
                      ref={addButtonRef}
                      variant="contained"
                      onClick={handleAddClick}
                      onKeyDown={handleAddKeyDown}
                    >
                      {intl.formatMessage({
                        id: 'components.sidebarv2.multivalue.add',
                      })}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={currentDatapointPath.length !== 3}
                      onClick={handleDeleteActiveChildClick}
                    >
                      {intl.formatMessage({
                        id: 'components.sidebarv2.multivalue.delete',
                      })}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleDeleteAllClick}
                    >
                      {intl.formatMessage({
                        id: 'components.sidebarv2.multivalue.deleteAll',
                      })}
                    </Button>
                  </>
                )}
              </Stack>
            )}
          </>
        ) : null}
      </>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

MultivalueItem.displayName = 'MultivalueItem';

export { MultivalueItem };
