import { yupResolver } from '@hookform/resolvers/yup';
import {
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
  Hook,
  HookPatchPayload,
  rossumStore,
} from '@rossum/api-client/hooks';
import { InfoOutlined } from '@rossum/ui/icons';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { get, isEqual, orderBy } from 'lodash';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { pickBy } from 'remeda';
import * as yup from 'yup';
import { usePatchHook } from '../../../../business/hooks/usePatchHook';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import ValidationInput from '../../../../components/ReactHookForm/ValidationInput';
import {
  docsLinks,
  EXTERNAL_CRONTAB_VALIDATOR,
} from '../../../../constants/values';
import { QueueMultiSelect } from '../../../../features/queues/select-queue/QueueMultiSelect';
import { italicText, linebreak, link } from '../../../../lib/formaterValues';
import { useLocalStorageBooleanFlag } from '../../../../lib/hooks';
import { sanitizeLinks } from '../../../../lib/htmlSanitization';
import { selectedExtensionSelector } from '../../../../redux/modules/extensions/selectors';
import { EXTENSIONS_ADVANCED_SETTINGS_EXPANDED } from '../../../../redux/modules/localStorage/actions';
import {
  enterExtension,
  leaveExtension,
} from '../../../../redux/modules/ui/actions';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { Url } from '../../../../types/basic';
import { HookFormObjectValue } from '../../../../types/CustomTypes/HookFormObject';
import { State } from '../../../../types/state';
import { Users } from '../../../../types/users';
import { Header, HeaderTitle } from '../../../../ui/header/Header';
import { LeavingDialog } from '../../../../ui/leaving-dialog/LeavingDialog';
import { ConfigAppProvider } from '../../../Extensions/containers/ConfigApp/context/ConfigAppProvider';
import AdvancedSettings from '../../../Extensions/containers/CreateExtension/components/AdvancedSettings';
import { DeleteExtensionDialog } from '../../../Extensions/DeleteExtensionDialog';
import { ExtensionsBreadcrumbs } from '../../../Extensions/ExtensionsBreadcrumbs';
import { myExtensionsPath } from '../../../Extensions/helpers';
import { getIcon, validateCronString } from '../../../Extensions/lib/helpers';
import { useJSONfield } from '../../../Extensions/lib/useJSONfield';
import FormLabel from '../../../User/components/FormLabel';
import { EventMultiSelect } from '../../events/components/EventMultiSelect';
import {
  isPublicFunction,
  isPublicWebhook,
  webhookUrlValidation,
} from '../../helpers';
import styles from '../../style.module.sass';
import { ExtensionHeaderActions } from './ExtensionHeaderActions';
import {
  ExtensionData,
  getDefaultValues,
  getSecretsWithPlaceholders,
  NO_CHANGE_SECRET_PLACEHOLDER,
} from './helpers';
import ViewCodePart from './ViewCodePart';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

const SAVE_EXTENSION_FORM_ID = 'save-extension-form';

type StateProps = {
  organizationUsers: Users;
  selectedExtension: Hook | undefined;
};

type OwnProps = {
  url: string;
};

type DispatchProps = {
  onEnter: typeof enterExtension;
  onLeave: typeof leaveExtension;
};

type Props = DispatchProps & OwnProps & StateProps;

const sortQueues = (queues: Array<Url>) => orderBy(queues, undefined, ['asc']);

const validationSchema = (intl: IntlShape) =>
  yup.object().shape({
    active: yup.boolean().required(),
    description: yup
      .string()
      .max(
        1000,
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.description.tooLong',
        })
      )
      .notRequired()
      .nullable(),
    events: yup.array().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.events.required',
      })
    ),
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.name.required',
      })
    ),
    queues: yup.array(),
    type: yup.string().required('required'),
    secret: yup.string().nullable().notRequired(),
    url: yup.string().when('type', {
      is: extensionWebhookType,
      then: yup.string().when(['private'], {
        is: true,
        then: yup.string().nullable().notRequired(),
        otherwise: webhookUrlValidation(intl),
      }),
      otherwise: yup.string().nullable().notRequired(),
    }),
    sideload: yup.array(),
    tokenOwner: yup.string().nullable(),
    settings: yup.object(),
    runtime: yup.string(),
    schedule: yup
      .string()
      .when('events', (events: ExtensionEvent[], schema: yup.StringSchema) =>
        events.includes('invocation.scheduled')
          ? schema.test(
              'wrongFormat',
              intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.schedule.wrongFormat',
              }),
              validateCronString
            )
          : schema
      ),
  });

const ExtensionSettings = ({
  organizationUsers,
  selectedExtension,
  url,
}: Props & { selectedExtension: Hook }) => {
  const [showSecret, setShowSecret] = useState(false);

  const isFromStore = selectedExtension.extensionSource === rossumStore;
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useLocalStorageBooleanFlag(EXTENSIONS_ADVANCED_SETTINGS_EXPANDED, false);
  const intl = useIntl();

  const { push, location } = useHistory<{
    backLink?: string;
  }>();

  const returnLink = location.state?.backLink || myExtensionsPath();

  const pickChangedSecrets = (secrets: HookFormObjectValue | undefined) =>
    pickBy(
      secrets || {},
      secretValue => secretValue !== NO_CHANGE_SECRET_PLACEHOLDER
    );

  const secretsWithPlaceholders = useMemo(
    () =>
      getSecretsWithPlaceholders({
        secretsSchemaProperties: selectedExtension.secretsSchema?.properties,
        settingsSchemaProperties: selectedExtension.settingsSchema?.properties,
      }),
    [
      selectedExtension.secretsSchema?.properties,
      selectedExtension.settingsSchema?.properties,
    ]
  );

  const extensionIsPublicFunction = isPublicFunction(selectedExtension);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setError,
    trigger,
  } = useForm<ExtensionData>({
    mode: 'onTouched',
    defaultValues: getDefaultValues(selectedExtension),
    resolver: yupResolver(validationSchema(intl)),
  });
  const watchFields = watch();

  // used as fallback in case of some queues being in deleting state
  // so we do not need to filter assigned queues
  const allQueuesLoaded = useSelector(
    (state: State) => state.workspaces.isLoaded && state.queues.loaded
  );

  const useWebhookPrivateFields = isPublicWebhook(selectedExtension);

  const useFunctionCode = isPublicFunction(selectedExtension);

  const noChanges =
    !allQueuesLoaded ||
    isEqual(
      {
        ...watchFields,
        description: watchFields.description || '',
        queues: sortQueues(watchFields.queues),
        secret: watchFields.secret || '',
      },
      {
        schedule: selectedExtension.config.schedule?.cron ?? '',
        active: selectedExtension.active,
        type: selectedExtension.type,
        name: selectedExtension.name,
        description: selectedExtension.description || '',
        events: selectedExtension.events,
        queues: sortQueues(selectedExtension.queues),
        url: (useWebhookPrivateFields && selectedExtension.config.url) || '',
        secret:
          (useWebhookPrivateFields && selectedExtension.config.secret) || '',
        tokenOwner: selectedExtension.tokenOwner,
        settings: selectedExtension.settings,
        sideload: selectedExtension.sideload,
        secrets: secretsWithPlaceholders,
        private: selectedExtension.config.private,
        runtime: useFunctionCode ? selectedExtension.config.runtime : undefined,
        payloadLoggingEnabled:
          selectedExtension.config.payloadLoggingEnabled ?? false,
      }
    );

  const { mutate, isLoading } = usePatchHook();

  const [deletingExtension, setDeletingExtension] = useState(false);

  const onSubmit = (mutateOptions?: { onSuccess: () => void }) =>
    handleSubmit(
      ({
        url,
        secret,
        secrets,
        queues,
        schedule,
        runtime,
        payloadLoggingEnabled,
        ...data
      }: ExtensionData) => {
        const scheduleConfig = schedule ? { schedule: { cron: schedule } } : {};
        const runtimeConfig = extensionIsPublicFunction ? { runtime } : {};

        const extension = {
          ...data,
          queues,
          settingsSchema: data.settingsSchema as Record<string, unknown>,
          secretsSchema: data.secretsSchema as Record<string, unknown>,
          secrets: pickChangedSecrets(secrets) as Record<string, unknown>,

          ...(useWebhookPrivateFields
            ? {
                config: {
                  url,
                  secret: secret || null,
                  payloadLoggingEnabled,
                  ...scheduleConfig,
                },
              }
            : {
                config: {
                  payloadLoggingEnabled,
                  ...scheduleConfig,
                  ...runtimeConfig,
                },
              }),
        };

        return mutate(
          {
            hookId: selectedExtension.id,
            payload: extension,
            meta: {
              // do not display message when user is retrying to save failed function
              withMessage:
                selectedExtension.type === 'function' &&
                selectedExtension.status !== 'failed',
            },
          },
          mutateOptions
        );
      }
    );

  const updateExtensionDetail = (payload: HookPatchPayload) =>
    mutate({
      hookId: selectedExtension.id,
      payload,
      meta: { withMessage: false },
    });

  const settingsJSONField = useJSONfield<ExtensionData>({
    name: 'settings',
    trigger,
    setError,
    control,
    defaultValue: selectedExtension.settings as Record<string, object>,
    saveField: (name, value) =>
      updateExtensionDetail({ [name]: value, type: selectedExtension.type }),
    schema: selectedExtension.settingsSchema,
  });

  const secretsJSONField = useJSONfield<ExtensionData>({
    name: 'secrets',
    trigger,
    setError,
    control,
    schema: selectedExtension.secretsSchema,
  });

  const showSecretsSection = !(
    isFromStore && !selectedExtension.secretsSchema?.properties
  );

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          {...params}
          onBackButtonClicked={() => push(returnLink)}
          title={hasScrolled => (
            <Stack direction="row" alignItems="center" spacing={1}>
              <HeaderTitle
                hasScrolled={hasScrolled}
                title={intl.formatMessage({
                  id: `containers.settings.extensions.title.${
                    isFromStore ? 'fromStore' : selectedExtension.type
                  }`,
                })}
              />
              {selectedExtension.type === extensionFunctionType && (
                <div className={styles.FunctionIcon}>
                  {getIcon(
                    get(selectedExtension, 'type'),
                    get(selectedExtension, ['config', 'runtime']),
                    { size: 24 }
                  )}
                </div>
              )}
              {isFromStore && (
                <Chip
                  label={intl.formatMessage({
                    id: 'containers.settings.extensions.title.fromStore.tag',
                  })}
                  color="info"
                />
              )}
            </Stack>
          )}
          description={null}
          breadcrumbs={
            <ExtensionsBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: `containers.settings.extensions.title.${
                      isFromStore ? 'fromStore' : selectedExtension.type
                    }`,
                  }),
                },
              ]}
            />
          }
          buttons={[
            <ExtensionHeaderActions
              key="extension-header-actions"
              selectedExtension={selectedExtension}
              isValid={isValid}
              onSubmit={onSubmit()}
              control={control}
            />,
            <Button
              key="delete-extension-button"
              variant="outlined"
              disabled={get(selectedExtension, 'status') === 'pending'}
              onClick={() => setDeletingExtension(true)}
              data-cy="extensions-delete-extension"
            >
              {intl.formatMessage({
                id: 'containers.settings.extensions.deleteExtension',
              })}
            </Button>,
            <Button
              key="save-extension-button"
              variant="contained"
              startIcon={isLoading ? <CircularProgress size={18} /> : null}
              disabled={
                get(selectedExtension, 'status') === 'pending' ||
                !isValid ||
                isLoading ||
                noChanges
              }
              data-cy={`extensions-${selectedExtension.type}-confirm-button`}
              type="submit"
              form={SAVE_EXTENSION_FORM_ID}
            >
              {intl.formatMessage({
                id: 'containers.settings.queues.save',
              })}
            </Button>,
          ]}
        />
      )}
    >
      <Stack py={4}>
        <HelmetComponent
          dynamicName={selectedExtension.name}
          translationKey="features.routes.pageTitles.extensions.edit"
        />
        <Paper
          sx={{ px: 30, py: 4 }}
          component="form"
          id={SAVE_EXTENSION_FORM_ID}
          onSubmit={onSubmit({
            onSuccess: () => {
              secretsJSONField.onChange(
                JSON.stringify(secretsWithPlaceholders, null, 2)
              );
            },
          })}
        >
          {isFromStore && (
            <div className={styles.UpperPart}>
              <div className={styles.UpperPartTitle}>
                {getIcon(
                  selectedExtension.type,
                  isPublicFunction(selectedExtension)
                    ? selectedExtension.config?.runtime
                    : undefined,
                  { size: 20 }
                )}
                {selectedExtension.name}
              </div>
              <div className={styles.FromStoreDescription}>
                {selectedExtension.description}
              </div>
            </div>
          )}
          {isFromStore && selectedExtension.guide && (
            <Box
              className={styles.UpperPart}
              sx={{
                '& button': {
                  color: theme =>
                    `${theme.palette.primary.contrastText} !important`,
                  backgroundColor: theme =>
                    `${theme.palette.primary.main} !important`,
                },
              }}
            >
              <div className={styles.UpperPartTitle}>
                <FormattedMessage id="containers.settings.extensions.guide" />
              </div>
              <div
                className={styles.Guide}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedExtension.guide, {
                    ADD_TAGS: ['iframe'],
                    ADD_ATTR: ['allow', 'allowfullscreen'],
                  }),
                }}
              />
            </Box>
          )}
          {!isFromStore && useFunctionCode && (
            <ViewCodePart
              selectedExtension={selectedExtension}
              isFromStore={isFromStore}
              routeUrl={url}
            />
          )}
          <div className={styles.FormLabel}>
            <FormattedMessage
              id={
                isFromStore
                  ? 'containers.settings.extensions.createExtension.yourName.label'
                  : 'containers.settings.extensions.createExtension.name.label'
              }
            />
          </div>
          <div className={styles.NameInputWidthLimiter}>
            <ValidationInput<ExtensionData>
              control={control}
              name="name"
              getErrorMessage={(_, { message }) => message ?? ''}
              placeholder={intl.formatMessage({
                id: `containers.settings.extensions.createExtension.name.placeholder`,
              })}
              dataCy="extensions-name-input"
            />
          </div>
          {!isFromStore && (
            <>
              <div className={styles.LabelWithOptional}>
                <div className={styles.FormLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.description.label" />
                </div>
                <span className={styles.Optional}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
                </span>
              </div>
              <TextFieldControl
                multiline
                size="small"
                minRows={4}
                ControllerProps={{ control, name: 'description' }}
                placeholder={intl.formatMessage({
                  id: 'containers.settings.extensions.createExtension.description.placeholder',
                })}
                data-cy="extensions-description-input"
              />
              <div className={styles.FormLabel}>
                <FormLabel>
                  <Stack direction="row" gap={0.5}>
                    <FormattedMessage id="containers.settings.extensions.createExtension.events.label" />
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="containers.settings.extensions.function.events.tooltip"
                          values={{
                            link: link(docsLinks.webhookEvents, {
                              color: 'white',
                            }),
                            linebreak,
                          }}
                        />
                      }
                      placement="top"
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </Stack>
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.events.subLabel" />
              </div>
              <Controller
                control={control}
                name="events"
                render={({ field }) => <EventMultiSelect {...field} />}
              />
              <div className={styles.ErrorMessagePlaceholder}>
                {errors.events && (
                  <span
                    data-cy="error-message-eventsDropdown"
                    className={styles.ErrorMessage}
                  >
                    <FormattedMessage id="containers.settings.extensions.createExtension.events.required" />
                  </span>
                )}
              </div>
            </>
          )}
          {watchFields.events.includes('invocation.scheduled') && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.schedule.label" />
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="containers.settings.extensions.createExtension.schedule.tooltip"
                        values={{
                          link: link(EXTERNAL_CRONTAB_VALIDATOR, {
                            color: 'white',
                          }),
                          linebreak,
                        }}
                      />
                    }
                    placement="top"
                  >
                    <InfoOutlined />
                  </Tooltip>
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.schedule.subLabel" />
              </div>
              <ValidationInput
                control={control}
                name="schedule"
                placeholder="*/10 * * * *"
                dataCy="extensions-webhook-schedule-input"
                getErrorMessage={(_, { message }) => message ?? ''}
              />
            </>
          )}
          <div className={styles.FormLabel}>
            <FormattedMessage id="containers.settings.extensions.createExtension.queues.label" />
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage
              id="containers.settings.extensions.createExtension.queues.subLabel"
              values={{
                linebreak,
                italicText,
              }}
            />
          </div>
          <Controller
            control={control}
            name="queues"
            render={({ field }) => (
              <QueueMultiSelect {...field} withLabel={false} />
            )}
          />
          {useWebhookPrivateFields && (
            <>
              <div className={styles.FormLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.label" />
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.subLabel" />
              </div>
              <ValidationInput<ExtensionData>
                name="url"
                control={control}
                getErrorMessage={(_, { message }) => message ?? ''}
                dataCy="extensions-webhook-url-input"
                placeholder={intl.formatMessage({
                  id: `containers.settings.extensions.createExtension.webhook.url.placeholder`,
                })}
              />
              <div className={styles.LabelWithOptional}>
                <div>
                  <div className={styles.FormLabel}>
                    <FormattedMessage id="containers.settings.extensions.createExtension.webhook.sharedSecret.label" />
                  </div>
                  <div className={styles.SubLabel}>
                    <FormattedMessage
                      id="containers.settings.extensions.createExtension.webhook.sharedSecret.subLabel"
                      values={{ link: link(docsLinks.validatingPayloads) }}
                    />
                  </div>
                </div>
                <span className={styles.Optional}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
                </span>
              </div>
              <ValidationInput<ExtensionData>
                autoComplete="new-password"
                name="secret"
                type={showSecret ? 'text' : 'password'}
                rightIconProps={{
                  onClick: () => setShowSecret(!showSecret),
                  'data-cy': 'eye-icon',
                }}
                rightIcon={showSecret ? <EyeOffIcon /> : <EyeIcon />}
                dataCy="extensions-webhook-secret-input"
                control={control}
                getErrorMessage={(_, { message }) => message ?? ''}
                placeholder={intl.formatMessage({
                  id: `containers.settings.extensions.createExtension.webhook.secret.placeholder`,
                })}
              />
            </>
          )}
          <AdvancedSettings<ExtensionData>
            control={control}
            allOrgUsers={organizationUsers.list}
            showAdvancedSettings={showAdvancedSettings}
            setShowAdvancedSettings={setShowAdvancedSettings}
            users={organizationUsers.list}
            settingsJSONField={settingsJSONField}
            secretsJSONField={secretsJSONField}
            isFromStore={isFromStore}
            readMoreUrl={selectedExtension.readMoreUrl}
            name={selectedExtension.name}
            selectedExtension={selectedExtension}
            showSecretsSection={showSecretsSection}
          />
          {isFromStore && useFunctionCode && (
            <ViewCodePart
              selectedExtension={selectedExtension}
              isFromStore={isFromStore}
              routeUrl={url}
            />
          )}
          <LeavingDialog when={!noChanges} />
        </Paper>
      </Stack>

      <DeleteExtensionDialog
        open={deletingExtension}
        onClose={() => setDeletingExtension(false)}
        extension={selectedExtension}
      />
    </PageLayoutV2>
  );
};

const ExtensionSettingsWithConfigAppContext = (props: Props) => {
  const { selectedExtension, onLeave, onEnter } = props;

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    onEnter();

    return () => {
      onLeave();
    };
  }, [onEnter, onLeave]);

  if (!selectedExtension) return null;

  return (
    <ConfigAppProvider selectedExtension={selectedExtension}>
      {timestamp => {
        return (
          <ExtensionSettings
            {...props}
            selectedExtension={selectedExtension}
            key={timestamp}
          />
        );
      }}
    </ConfigAppProvider>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  organizationUsers: state.users,
  selectedExtension: selectedExtensionSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onEnter: () => dispatch(enterExtension()),
  onLeave: () => dispatch(leaveExtension()),
});

const ExtensionSettingsRoute = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(ExtensionSettingsWithConfigAppContext);

export default ExtensionSettingsRoute;
