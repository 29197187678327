import { getIDFromUrl } from '@rossum/api-client';
import { LinkOffRounded, LinkRounded } from '@rossum/ui/icons';
import {
  Button,
  List,
  ListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { link } from '../../../lib/formaterValues';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { useEngine } from '../hooks/useEngine';
import { useEngineFields } from '../hooks/useEngineFields';
import { engineDetailPath, engineFieldDetailPath } from '../paths';
import { TilesListEmptyState } from './TilesListEmptyState';
import { renderOption } from './TypesSubtypesInput';

type FieldConfigurationProps = {
  children: ReactNode;
  engineFieldName: string;
  engineUrl: string;
};

export const FieldConfiguration = ({
  children,
  engineFieldName,
  engineUrl,
}: FieldConfigurationProps) => {
  const intl = useIntl();

  const { data: engine } = useEngine(getIDFromUrl(engineUrl));
  const { data: fields } = useEngineFields({
    engine: getIDFromUrl(engineUrl),
    name: engineFieldName,
  });

  const field = engineFieldName ? fields?.results[0] : null;

  return (
    <ContentGroup
      title={
        <Stack direction="row" gap={1}>
          {intl.formatMessage({
            id: 'features.engines.fieldConfiguration.title',
          })}
          {field ? (
            <LinkRounded color="success" data-cy="indicator-connected" />
          ) : (
            <Tooltip
              arrow
              placement="top"
              title={intl.formatMessage({
                id: 'features.engines.fieldConfiguration.notConnected.tooltip',
              })}
            >
              <LinkOffRounded color="error" data-cy="indicator-not-connected" />
            </Tooltip>
          )}
        </Stack>
      }
      description={
        field && engine
          ? intl.formatMessage(
              {
                id: `features.engines.fieldConfiguration.connected.description`,
              },
              {
                link: link(
                  engineFieldDetailPath(getIDFromUrl(engineUrl), field.id)
                ),
              }
            )
          : null
      }
      isFullWidthLayout={!(field && engine)}
    >
      {field && engine ? (
        <>
          <List>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: `features.engines.fieldConfiguration.engineName`,
                  })}
                  :
                </Typography>
                <Typography
                  component={Link}
                  to={engineDetailPath(engine.id)}
                  color="text.primary"
                  data-cy="connected-engine-name"
                >
                  {engine.name}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.name',
                  })}
                  :
                </Typography>
                <Typography
                  component={Link}
                  to={engineFieldDetailPath(engine.id, field.id)}
                  color="text.primary"
                  data-cy="connected-field-name"
                >
                  {field.label}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.type.field.dataType',
                  })}
                  :
                </Typography>
                <Typography color="text.primary">
                  {renderOption(
                    {
                      type: field.type,
                      subtype: field.subtype ?? null,
                    },
                    intl
                  )}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.preTrainedFieldId',
                  })}
                  :
                </Typography>
                <Typography color="text.primary">
                  {field.preTrainedFieldId ?? '-'}
                </Typography>
              </Stack>
            </ListItem>
          </List>

          <Paper
            elevation={8}
            sx={{
              borderRadius: 1,
              p: 2,
            }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'features.engines.fieldConfiguration.info.description',
                })}
              </Typography>
              <Button
                component={Link}
                to={engineFieldDetailPath(getIDFromUrl(engineUrl), field.id)}
                variant="text"
                color="secondary"
                size="small"
              >
                {intl.formatMessage({
                  id: 'features.engines.fieldConfiguration.info.button',
                })}
              </Button>
            </Stack>
          </Paper>

          {children}
        </>
      ) : (
        <TilesListEmptyState
          title={intl.formatMessage({
            id: `features.engines.fieldConfiguration.notConnected.title`,
          })}
          subtitle={intl.formatMessage({
            id: `features.engines.fieldConfiguration.notConnected.subtitle`,
          })}
        />
      )}
    </ContentGroup>
  );
};
