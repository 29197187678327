import { Close } from '@mui/icons-material';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import React from 'react';

export type DetailDrawerProps = Omit<DrawerProps, 'title'> & {
  /** Gets rendered inside a `Typography` variant `h5` */
  title?: React.ReactNode;
};

const PREFIX = 'DetailDrawer';

export const detailDrawerClasses = {
  body: `${PREFIX}-bodyBox`,
  title: `${PREFIX}-titlePaper`,
};

// TODO: This needs elevations first because it is supposed to be of elevation 0 but that is now almost black
export const DetailDrawer = ({
  title,
  children,
  PaperProps,
  open,
  onClose,
  ...restDrawerProps
}: DetailDrawerProps) => (
  <Drawer
    {...restDrawerProps}
    open={open}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
      square: true,
      ...PaperProps,
      sx: { width: 800, ...PaperProps?.sx },
    }}
  >
    <Paper
      square
      elevation={8}
      sx={{ px: 3, py: 2 }}
      className={detailDrawerClasses.title}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton
          color="inherit"
          onClick={e => onClose?.(e, 'backdropClick')}
        >
          <Close />
        </IconButton>
      </Stack>
    </Paper>
    <Stack
      direction="column"
      className={detailDrawerClasses.body}
      sx={{ overflowY: 'auto' }}
    >
      {children}
    </Stack>
  </Drawer>
);
