import { Hook } from '@rossum/api-client/hooks';
import { CircularProgress, Stack, Typography } from '@rossum/ui/material';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledToggle from '../../../../components/ReactHookForm/Toggle';
import { boldText, white } from '../../../../lib/formaterValues/index';
import { ExtensionData } from './helpers';
import { RetryButton } from './RetryButton';

type ExtensionHeaderActionsProps = {
  selectedExtension: Hook;
  isValid: boolean;
  onSubmit: () => void;
  control: Control<ExtensionData>;
};

export const ExtensionHeaderActions = ({
  selectedExtension,
  isValid,
  onSubmit,
  control,
}: ExtensionHeaderActionsProps) => {
  const intl = useIntl();

  return (
    <Stack>
      {selectedExtension.type === 'function' &&
      selectedExtension.status === 'failed' ? (
        <Stack
          marginLeft="auto"
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <RetryButton onButtonClick={onSubmit} disabled={!isValid} />
        </Stack>
      ) : selectedExtension.type === 'function' &&
        selectedExtension.status === 'pending' ? (
        <Stack
          ml={2}
          spacing={2}
          direction="row"
          alignItems="center"
          data-cy={`extensions-${selectedExtension.type}-pending-message`}
          color="text.secondary"
        >
          <Stack>
            <CircularProgress size={20} color="inherit" />
          </Stack>
          <Typography variant="subtitle2">
            {intl.formatMessage({
              id: `components.editor.infoMessage.pending.function`,
            })}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: 3 }}>
          <ControlledToggle<ExtensionData>
            dataCy="extensions-toggle"
            control={control}
            name="active"
          />
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {intl.formatMessage(
              {
                id: `containers.settings.extensions.activity.${
                  selectedExtension.active ? 'active' : 'inactive'
                }`,
              },
              { boldText, white }
            )}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
