import { Box, Chip, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { EngineQueuesInfo } from '../hooks/useInfiniteQueueStatsForEngine';
import { QueueChipsSkeleton } from './skeletons/QueueChipsSkeleton';

const VISIBLE_CHIPS_COUNT = 3;

type EngineQueuesProps = {
  queuesInfo: EngineQueuesInfo;
  isLoading: boolean;
};

const EngineQueues = ({ queuesInfo, isLoading }: EngineQueuesProps) => {
  const intl = useIntl();

  if (isLoading) {
    return <QueueChipsSkeleton />;
  }

  if (!queuesInfo.totalCount) {
    return (
      <Stack alignItems="flex-start">
        <Chip
          label={
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'features.engines.engineDetail.noQueue',
              })}
            </Typography>
          }
        />
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      {queuesInfo.info
        .slice(0, VISIBLE_CHIPS_COUNT)
        .map(({ queue, workspace }) => (
          <Chip
            key={queue}
            label={
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                divider={<>-</>}
                gap={0.5}
                sx={{
                  '& p': {
                    minWidth: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                <Typography variant="body2">{workspace}</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {queue}
                </Typography>
              </Stack>
            }
            sx={{
              maxWidth: 250,
            }}
          />
        ))}

      {queuesInfo.totalCount > VISIBLE_CHIPS_COUNT && (
        <Tooltip
          arrow
          title={
            <Stack spacing={1} alignItems="flex-start">
              {queuesInfo.info.slice(VISIBLE_CHIPS_COUNT).map(info => (
                <Box
                  key={`${info.workspace}-${info.queue}`}
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <Typography variant="caption">
                    {info.workspace}
                    <Box component="span" mx={0.5}>
                      -
                    </Box>
                  </Typography>
                  <Typography variant="caption" fontWeight="bold">
                    {info.queue}
                  </Typography>
                </Box>
              ))}

              {queuesInfo.totalCount &&
              queuesInfo.totalCount > queuesInfo.info.length ? (
                <Typography
                  component="div"
                  variant="caption"
                  align="left"
                  sx={{ pt: 1 }}
                >
                  {intl.formatMessage(
                    {
                      id: 'components.engineCardQueues.more',
                    },
                    {
                      count: queuesInfo.totalCount - queuesInfo.info.length,
                    }
                  )}
                </Typography>
              ) : null}
            </Stack>
          }
        >
          <Chip
            label={
              <Typography variant="body2">
                +{`${queuesInfo.totalCount - VISIBLE_CHIPS_COUNT}`}
              </Typography>
            }
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export { EngineQueues };
