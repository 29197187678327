import {
  CancelOutlined,
  CheckCircleOutline,
  HourglassTop,
} from '@rossum/ui/icons';
import { Chip } from '@rossum/ui/material';
import { ForwardedRef, forwardRef, ReactElement } from 'react';

type ChipStatus = 'success' | 'progress' | 'error';
type RequestDetailChipProps = {
  status: ChipStatus;
  name: string | undefined;
};

const iconByStatus: Record<ChipStatus, ReactElement> = {
  success: (
    <CheckCircleOutline
      fontSize="small"
      sx={{
        color: theme => `${theme.palette.success.main} !important`,
      }}
    />
  ),
  error: (
    <CancelOutlined
      fontSize="small"
      sx={{
        color: theme => `${theme.palette.error.main} !important`,
      }}
    />
  ),
  progress: (
    <HourglassTop
      fontSize="small"
      sx={{
        color: theme => `${theme.palette.warning.main} !important`,
      }}
    />
  ),
};

export const RequestDetailChip = forwardRef(
  (
    { status, name, ...rest }: RequestDetailChipProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Chip
      ref={ref}
      // To display tooltip from parent
      {...rest}
      sx={{ cursor: 'default' }}
      icon={iconByStatus[status]}
      label={name ?? '??'}
    />
  )
);
