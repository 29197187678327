import { CircularProgress, Stack } from '@rossum/ui/material';
import { MENU_WIDTH } from './constants';

const Loading = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ minWidth: MENU_WIDTH, minHeight: 150 }}
  >
    <CircularProgress />
  </Stack>
);

export { Loading };
