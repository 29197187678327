import { ID } from '../../utils/codecUtils';
import { ruleTemplateSchema } from '../models/ruleTemplate.schema';

export const get = (ruleId: ID) => {
  return {
    endpoint: `/rule_templates/${ruleId}`,
    method: 'GET',
    responseSchema: ruleTemplateSchema,
  } as const;
};
