import { HookTemplate } from '@rossum/api-client/hookTemplates';
import { DetailDrawer } from '@rossum/ui';
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { get } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useCreateHookFromTemplate } from '../../../business/hooks/useCreateHookFromTemplate';
import { sanitizeLinks } from '../../../lib/htmlSanitization';
import { buildAuthLink } from '../../../lib/url';
import {
  userIsSupportSelector,
  userSelector,
} from '../../../redux/modules/user/selectors';
import { fullscreenConfigAppPath } from '../containers/ConfigApp/helpers';
import { extensionDetailPath, extensionsStorePath } from '../helpers';
import { getIcon } from '../lib/helpers';
import { ContactDialog } from './ContactDialog/ContactDialog';
import styles from './style.module.sass';
import { useCheckIfAlreadyInstalled } from './useCheckIfAlreadyInstalled';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

export type ExtendedHookTemplate = HookTemplate & {
  tags: string;
  restricted: boolean;
};

type Props = {
  extension: ExtendedHookTemplate | null;
  onClose: () => void;
};

const ExtensionDrawer = ({ onClose, extension }: Props) => {
  const [openDialogType, setOpenDialogType] = useState<
    'contactUs' | 'requestAccess' | undefined
  >(undefined);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userIsSupport = useSelector(userIsSupportSelector);

  const { mutate, isLoading: creating } = useCreateHookFromTemplate();

  const intl = useIntl();

  const { installedHookId, hookTemplateStatus } =
    useCheckIfAlreadyInstalled(extension);

  const actionInProgress =
    creating || hookTemplateStatus === 'installable-checking';

  return (
    // TODO: This will get fixed when elevations are refactored and then drawer is adjusted in RUI
    <DetailDrawer
      PaperProps={{
        elevation: 2,
        sx: {
          '.DetailDrawer-bodyBox': {
            height: '100%',
          },
        },
      }}
      title={
        extension ? (
          <Stack direction="row" spacing={2} alignItems="center">
            {getIcon(extension.type, get(extension.config, 'runtime'), {})}
            <Typography variant="h5">{extension.name}</Typography>
          </Stack>
        ) : undefined
      }
      open={extension !== null}
      keepMounted={false}
      onClose={onClose}
    >
      {extension && (
        <Stack
          padding={3}
          height="100%"
          spacing={2}
          justifyContent="space-between"
          data-cy="extension-drawer-content"
        >
          <div
            className={styles.StoreDescription}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(extension.storeDescription),
            }}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Button
              variant="outlined"
              color="secondary"
              data-cy="extensions-contact-us"
              onClick={() => setOpenDialogType('contactUs')}
            >
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.contactUs',
              })}
            </Button>
            {extension.restricted ||
            extension.installAction === 'request_access' ? (
              <Button
                variant="contained"
                color="primary"
                data-cy="extensions-request-access"
                onClick={() => setOpenDialogType('requestAccess')}
              >
                {intl.formatMessage({
                  id: 'containers.settings.extensions.store.requestAccess',
                })}
              </Button>
            ) : extension.externalUrl ? (
              <Tooltip
                title={intl.formatMessage({
                  id: 'containers.settings.extensions.store.useExtensionTooltip.external',
                })}
              >
                <Button
                  variant="contained"
                  data-cy="extensions-try-extension"
                  href={buildAuthLink(extension.externalUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.TryExtensionButton}
                >
                  {intl.formatMessage({
                    id: 'containers.settings.extensions.store.useExtension',
                  })}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  hookTemplateStatus === 'not-installable'
                    ? intl.formatMessage({
                        id: 'containers.settings.extensions.store.useExtensionTooltip.serverless',
                      })
                    : ''
                }
              >
                <Button
                  variant="contained"
                  disabled={actionInProgress}
                  data-cy="extensions-try-extension"
                  onClick={
                    installedHookId
                      ? () =>
                          dispatch(
                            push(
                              `${extensionDetailPath(installedHookId)}${fullscreenConfigAppPath}`,
                              {
                                backLink: extensionsStorePath(),
                              }
                            )
                          )
                      : () =>
                          mutate({
                            payload: {
                              events: extension.events,
                              hookTemplate: extension.url,
                              name: extension.name,
                              queues: [],
                              tokenOwner:
                                extension.useTokenOwner && !userIsSupport
                                  ? user.url
                                  : null,
                            },
                          })
                  }
                  startIcon={
                    actionInProgress && (
                      <CircularProgress color="inherit" size={14} />
                    )
                  }
                >
                  {intl.formatMessage({
                    id:
                      hookTemplateStatus === 'not-installable'
                        ? 'containers.settings.extensions.store.useExtension'
                        : `containers.settings.extensions.store.${hookTemplateStatus}`,
                  })}
                </Button>
              </Tooltip>
            )}
          </Stack>
          <ContactDialog
            type={openDialogType}
            open={openDialogType !== undefined}
            onClose={() => setOpenDialogType(undefined)}
            extensionName={extension.name}
          />
        </Stack>
      )}
    </DetailDrawer>
  );
};

export default ExtensionDrawer;
