import { Box, Chip, Stack, Typography } from '@rossum/ui/material';
import { memo, useMemo } from 'react';
import { text } from '../constants';
import { StringWithOccurrences } from '../ui/StringWithOccurrences';
import { ValueIcon } from '../ui/ValueIcon';
import { ValueWithOccurrence } from './ValueWithOccurrence';

type GridCellValueProps = {
  value: Record<string, string | number> | '-' | undefined;
  icon?: boolean;
};

export const AggregatedValuesWithOccurrences = memo(
  ({ value, icon }: GridCellValueProps) => {
    const valueEmpty = value === '-';

    const entries = useMemo(() => {
      return value && !valueEmpty ? Object.entries(value) : [];
    }, [value, valueEmpty]);

    const onlyOneEntryWithUndefinedValue =
      entries.length === 1 && entries[0]?.[0] === 'undefined';

    return (
      <Stack width="100%" direction="row" spacing={1}>
        <Box
          width="100%"
          data-cy="wrapper"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {onlyOneEntryWithUndefinedValue || valueEmpty ? (
            <Typography component="span" variant="body2">
              {text.cellNotEditable}
            </Typography>
          ) : (
            entries.map(([aggregatedValue, occurrences], i) => {
              return [
                icon ? (
                  <ValueWithOccurrence
                    value={aggregatedValue}
                    occurrences={occurrences}
                    key={aggregatedValue}
                    icon={<ValueIcon value={aggregatedValue} />}
                  />
                ) : (
                  <StringWithOccurrences
                    primaryValue={aggregatedValue}
                    secondaryValue={occurrences}
                    key={aggregatedValue}
                  />
                ),

                i < entries.length - 1 ? ', ' : null,
              ];
            })
          )}
        </Box>
        {entries.length > 1 ? (
          <Stack maxWidth="100%">
            <Chip label={entries.length} />
          </Stack>
        ) : null}
      </Stack>
    );
  }
);
