import { TranslateAnnotationResponse } from '@rossum/api-client/annotations';
import { styled, Typography } from '@rossum/ui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { clamp } from 'remeda';
import { currentDatapointSelector } from '../../../redux/modules/datapoints/selector';
import { isBoundedDatapoint } from '../../../redux/modules/datapoints/typedHelpers';
import { SearchResultBox } from '../document-canvas/components/SearchResultBox';
import {
  applyPadding,
  rectangleFromCoordinates,
} from '../document-canvas/utils/geometry';
import { useDocumentStore } from '../document-store/DocumentStore';
import { areBoxesIntersecting } from './utils';

type TranslationBoxesLayerProps = {
  translations: TranslateAnnotationResponse['results'][number]['items'];
};

const StyledForeignObject = styled('foreignObject')(({ theme }) => ({
  overflow: 'visible',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0,
  },
}));

export const TranslationBoxesLayer = memo(
  ({ translations }: TranslationBoxesLayerProps) => {
    const searchValue = useDocumentStore(
      state => state.translationState.searchValue
    );

    const activeDatapoint = useSelector(currentDatapointSelector);

    const populateClickEvent = useCallback((event: MouseEvent) => {
      if (!event.target) return;
      const clickedElements = document
        .elementsFromPoint(event.clientX, event.clientY)
        .filter(el => el.classList.contains('bounding-box'));

      const behindElement = clickedElements[0];

      if (behindElement)
        behindElement.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            clientX: event.clientX,
            clientY: event.clientY,
          })
        );
    }, []);

    const translationBoxes = useMemo(() => {
      const selectedPosition =
        activeDatapoint && isBoundedDatapoint(activeDatapoint)
          ? activeDatapoint.content.position
          : null;

      return translations.map(translation => {
        // remove translations underneath selection
        if (
          selectedPosition &&
          areBoxesIntersecting(translation.position, selectedPosition)
        )
          return null;

        const translationPosition = translation.position.join(',');

        const rect = rectangleFromCoordinates(translation.position);
        const { x, y, width, height } = applyPadding(rect, 1);

        return (
          <React.Fragment key={translationPosition}>
            <StyledForeignObject
              x={x}
              y={y}
              width={width}
              height={height}
              onClick={populateClickEvent}
            >
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1,
                  fontSize: clamp(height - 4, { min: 12, max: 45 }),
                  backgroundColor: 'white',
                  whiteSpace: 'nowrap',
                  color: t => t.palette.common.black,
                  userSelect: 'none',
                  width: 'fit-content',
                }}
              >
                {translation.text}
              </Typography>
            </StyledForeignObject>
          </React.Fragment>
        );
      });
    }, [activeDatapoint, populateClickEvent, translations]);

    // this can be improved.
    // We can highlight only the searched values instead of the whole bounding box.
    // We can enable navigating through the results as well, but for now this will do.
    const translationSearchBoxes = useMemo(() => {
      if (!searchValue) return null;
      const matchingTranslations = translations.filter(({ text }) =>
        text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );
      return matchingTranslations.map(translation => {
        return (
          <SearchResultBox
            key={translation.position.join(',')}
            position={translation.position}
            current={false}
          />
        );
      });
    }, [searchValue, translations]);

    return (
      <>
        {translationBoxes}
        {translationSearchBoxes}
      </>
    );
  }
);
