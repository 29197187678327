import { GridSortItem } from '@rossum/ui/x-data-grid-pro';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useMemo } from 'react';
import { sort } from 'remeda';
import { v4 } from 'uuid';
import { EditedRow, Row, RowWithIndex } from './types';

type Props = {
  data: DSResultsResponseResultsItem[] | undefined;
  editedRows: EditedRow[];
  sortItem: GridSortItem | null;
};

export const useRows = ({ editedRows, sortItem, data }: Props) => {
  const rowsWithId = useMemo<Row[]>(() => {
    if (!data) return [];

    return data.map(row => ({
      ...row,
      id: v4(),
      status: 'idle',
    }));
  }, [data]);

  const rows = useMemo<RowWithIndex[]>(() => {
    const combinedRows = editedRows.reduce((acc, row) => {
      if (row.status === 'deleted') return acc.filter(r => r.id !== row.id);

      if (row.status === 'duplicated')
        return [...acc.slice(0, row.index), row, ...acc.slice(row.index)];

      if (row.status === 'edited')
        return acc.map(r => (r.id === row.id ? row : r));

      if (row.status === 'new') return [row, ...acc];

      return acc;
    }, rowsWithId);

    const sortedRows = sortItem?.sort
      ? sort(combinedRows, (a, b) => {
          const currentItem = a[sortItem.field];
          const nextItem = b[sortItem.field];

          if (!currentItem || !nextItem) return 0;

          if (sortItem.sort === 'asc')
            return currentItem.toString().localeCompare(nextItem.toString());

          return nextItem.toString().localeCompare(currentItem.toString());
        })
      : combinedRows;

    const result = sortedRows.map((row, index) => ({ ...row, index }));

    return result;
  }, [editedRows, rowsWithId, sortItem?.field, sortItem?.sort]);

  return { rows, rowsWithId };
};
