import { HttpError } from '@rossum/api-client/errors';
import { AxiosError } from 'axios';
import { AjaxError } from 'rxjs/ajax';
import { absoluteApiUrl } from '../constants/config';
import { toAbsoluteUrl } from '../lib/helpers';

export const inferEndpointGroup = (url: string | null | undefined): string => {
  if (!url) {
    return 'Unknown';
  }

  const absoluteUrl = toAbsoluteUrl(url);

  try {
    const { pathname } = new URL(absoluteUrl);
    const { pathname: apiUrlPathname } = new URL(absoluteApiUrl);
    return pathname.replace(apiUrlPathname, '').replace(/\d+/g, '*');
  } catch {
    return `Invalid Url - requestUrl: ${absoluteUrl} - apiUrl: ${absoluteApiUrl}`;
  }
};

export const getApiErrorRequestMethod = (
  error: HttpError | AxiosError | AjaxError
) => {
  if (error instanceof HttpError) {
    return error.method.toUpperCase();
  }

  if (error instanceof AxiosError) {
    return error.config.method ? error.config.method.toUpperCase() : 'UNKNOWN';
  }

  if (error instanceof AjaxError) {
    return error.request.method
      ? error.request.method.toUpperCase()
      : 'UNKNOWN';
  }

  return 'UNKNOWN';
};

export const getApiErrorStatusCode = (error: unknown) => {
  if (error instanceof AxiosError) {
    return error.response?.status ?? null;
  }

  if (error instanceof AjaxError) {
    return error.status;
  }

  if (error instanceof HttpError) {
    return error.code;
  }

  return null;
};

// Return request URL or at least endpoint in case of HttpError
export const getApiErrorUrl = (error: unknown): string | null => {
  if (error instanceof AxiosError) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return error.request?.responseUrl ?? null;
  }

  if (error instanceof AjaxError) {
    return error.request.url ?? null;
  }

  if (error instanceof HttpError) {
    return error.endpoint ?? null;
  }

  return null;
};
