import { InfoOutlined } from '@rossum/ui/icons';
import { Paper, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { boldText, linebreak } from '../../../lib/formaterValues';
import { GraphName } from '../types';

const GRAPH_HEIGHT = 400;

type GraphsContainerProps = {
  children: ReactNode;
  graphName?: GraphName;
  fullWidth?: boolean;
};

const GraphsContainer = ({
  children,
  graphName,
  fullWidth,
}: GraphsContainerProps) => {
  const intl = useIntl();

  return (
    <Stack
      component={Paper}
      position="relative"
      justifyContent="flex-start"
      height={GRAPH_HEIGHT}
      p={2}
      sx={{
        width: t => (fullWidth ? '100%' : `calc(50% - ${t.spacing(1)})`),
      }}
    >
      {graphName && (
        <Stack direction="row" mb={2}>
          <Typography variant="body2" fontWeight={600}>
            <FormattedMessage id={`components.graphs.${graphName}.title`} />
          </Typography>
          <Tooltip
            title={
              intl.formatMessage(
                {
                  id: `components.graphs.${graphName}.tooltip`,
                },
                {
                  linebreak,
                  boldText,
                }
              ) ?? ''
            }
            placement="top"
            componentsProps={{
              tooltip: { sx: { textAlign: 'left' } },
            }}
          >
            <InfoOutlined
              fontSize="small"
              color="disabled"
              sx={{ ml: 0.5, cursor: 'pointer' }}
            />
          </Tooltip>
        </Stack>
      )}
      <Stack height={1} justifyContent="space-between">
        {children}
      </Stack>
    </Stack>
  );
};

export default GraphsContainer;
