import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import {
  isDatapointField,
  isSimpleMultivalueField,
} from '@rossum/api-client/dedicatedEngineSchema';
import { SplitButton } from '@rossum/rossum-ui/SplitButton';
import { Check } from '@rossum/ui/icons';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grow,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SwitchTransition, TransitionGroup } from 'react-transition-group';
import { useMountTransition } from '../../../../../../../../components/UI/MountTransition/useMountTransition';
import { DEDICATED_ENGINES_TRAINING_LINK } from '../../../../../../../../constants/values';
import { boldText, link } from '../../../../../../../../lib/formaterValues';
import { EngineStatusChip } from '../../../../../../components/EngineStatusChip';
import { QUERY_KEY_DEDICATED_ENGINE_SCHEMA } from '../../../../../../hooks/useDedicatedEngineSchema';
import { usePatchDedicatedEngineSchema } from '../../../../../../hooks/usePatchDedicatedEngineSchema';
import { usePredictDedicatedEngineSchema } from '../../../../../../hooks/usePredictEngineSchema';
import { EngineSchemaLockedMessage } from '../EngineSchemaLockedMessage';
import { EngineSchemaObjectCard } from './components/EngineSchemaObjectCard';
import { HeaderFieldForm } from './components/HeaderFieldForm';
import { LineItemForm } from './components/LineItemForm';
import {
  addFieldPayload,
  deleteFieldPayload,
  editFieldPayload,
  headerFieldFormDefaultValues,
  HeaderFieldFormModel,
  lineItemFormDefaultValues,
  LineItemFormModel,
  viewToFormModel,
  viewToPayloadModel,
} from './utils';

const fieldTypes = ['headerField', 'lineItem'] as const;

// trigger pipeline

type FieldsMappingStepProps = {
  engine: DedicatedEngine;
  engineSchema: DedicatedEngineSchema;
  onNext: () => void;
  onPrevious: () => void;
  onError?: (e?: unknown) => void;
};

const FieldsMappingStep = ({
  engine,
  engineSchema,
  onNext,
  onPrevious,
}: FieldsMappingStepProps) => {
  const intl = useIntl();

  const queryClient = useQueryClient();

  const [transitionProps, transitionOut] = useMountTransition();

  const handlePreviousClick = useCallback(
    () => transitionOut(onPrevious),
    [onPrevious, transitionOut]
  );

  const handleNextClick = useCallback(
    () => transitionOut(onNext),
    [onNext, transitionOut]
  );

  const {
    mutate: patchSchema,
    mutateAsync: patchSchemaAsync,
    status,
    reset,
  } = usePatchDedicatedEngineSchema();

  // When entering this step with an empty schema we try to predict it automatically
  // by calling /predict and on success, saving it immediately to allow further changes
  // this might deserve more attention later

  // TODO: Deep dive into RQ, maybe we can infer this state automatically?
  // right now it might be tricky since status of 'patchSchema' also changes when doing individual changes
  // TODO: When redesigning data flow for this feature, do optimistic updates better, this is
  // improvised, theoretically buggy and practically a callback hell
  // utilize mutation hooks (onMutate, onError, onSettled)
  const [showPredictionBanner, setShowPredictionBanner] = useState<
    'success' | 'error' | null
  >(null);

  const { mutateAsync: predictSchemaAsync, status: predictSchemaStatus } =
    usePredictDedicatedEngineSchema();

  // how to cancel mutation if we unmount
  useEffect(() => {
    if (engine.status === 'draft' && !engineSchema.content.fields.length) {
      predictSchemaAsync({
        trainingQueues: engineSchema.content.trainingQueues,
      })
        .then(predictedSchema => {
          // optimistically update, save should never fail in theory
          // avoids inconsistent state where there is no loader but no schema yet either
          queryClient.setQueryData(
            [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, engineSchema.id, {}],
            predictedSchema
          );

          return patchSchemaAsync({
            engineSchemaId: engineSchema.id,
            engineSchemaPatchModel: {
              content: {
                ...predictedSchema.content,
                fields: predictedSchema.content.fields.map(viewToPayloadModel),
              },
            },
          }).then(_finalSchema => {
            // invalidate just in case - UI remains disabled until verified
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, engineSchema.id],
            });
            setShowPredictionBanner('success');
          });
        })
        .catch(() => {
          queryClient.setQueryData(
            [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, engineSchema.id, {}],
            engineSchema
          );
          setShowPredictionBanner('error');
        });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Adding new field logic */
  const [addingNew, setAddingNew] = useState<
    (typeof fieldTypes)[number] | null
  >(null);

  const isLocked = engine.status !== 'draft';

  const AddNewButtonUI = (
    <Stack>
      <SplitButton
        variant="contained"
        color="primary"
        defaultLabel={intl.formatMessage({
          id: 'components.fieldsMappingStep.addButton.label',
        })}
        actions={[
          {
            label: intl.formatMessage({
              id: 'components.fieldsMappingStep.fieldType.headerField',
            }),
            callback: () => setAddingNew('headerField'),
          },
          {
            label: intl.formatMessage({
              id: 'components.fieldsMappingStep.fieldType.lineItem',
            }),
            callback: () => setAddingNew('lineItem'),
          },
        ]}
      />
    </Stack>
  );

  const handleAddField = useCallback(
    (formValue: HeaderFieldFormModel | LineItemFormModel) => {
      patchSchema(
        {
          engineSchemaId: engineSchema.id,
          engineSchemaPatchModel: addFieldPayload(engineSchema, formValue),
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(
              [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id, {}],
              response
            );
            setAddingNew(null);
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id],
            });
          },
        }
      );
    },
    [engineSchema, patchSchema, queryClient]
  );

  const handleDeleteEmpty = useCallback(() => {
    reset();
    setAddingNew(null);
  }, [reset]);

  /** Editing field logic */
  // index of field being edited
  const [editingAt, setEditingAt] = useState<number | null>(null);

  const handleEditField = useCallback(
    (formValue: HeaderFieldFormModel | LineItemFormModel, index: number) => {
      patchSchema(
        {
          engineSchemaId: engineSchema.id,
          engineSchemaPatchModel: editFieldPayload(
            engineSchema,
            formValue,
            index
          ),
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(
              [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id, {}],
              response
            );
            setEditingAt(null);
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id],
            });
          },
        }
      );
    },
    [engineSchema, patchSchema, queryClient]
  );

  /** Deleting field logic */
  const handleDeleteField = useCallback(
    (index: number) => {
      patchSchema(
        {
          engineSchemaId: engineSchema.id,
          engineSchemaPatchModel: deleteFieldPayload(engineSchema, index),
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(
              [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id, {}],
              response
            );
            setEditingAt(null);
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_DEDICATED_ENGINE_SCHEMA, response.id],
            });
          },
        }
      );
    },
    [engineSchema, patchSchema, queryClient]
  );

  const handleCancelEdit = useCallback(() => {
    setEditingAt(null);
    reset();
  }, [reset]);

  return (
    <Stack spacing={3} divider={<Divider />}>
      <Fade {...transitionProps}>
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ width: '100%', pl: 8, pr: 8 }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography variant="h5">
              {intl.formatMessage({ id: 'components.fieldsMappingStep.title' })}
            </Typography>
            <EngineStatusChip status={engine.status} />
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
            >
              {intl.formatMessage({
                id: 'components.fieldsMappingStep.description',
              })}
            </Typography>
            {isLocked && <EngineSchemaLockedMessage />}
            <SwitchTransition>
              <Grow key={showPredictionBanner ?? 'none'} appear unmountOnExit>
                {showPredictionBanner === 'success' ? (
                  <Alert
                    variant="filled"
                    severity="info"
                    sx={{ width: '100%' }}
                  >
                    <AlertTitle>
                      {intl.formatMessage({
                        id: 'components.fieldsMappingStep.prediction.success.title',
                      })}
                    </AlertTitle>
                    <Stack spacing={2}>
                      <Typography variant="body2">
                        {intl.formatMessage(
                          {
                            id: 'components.fieldsMappingStep.prediction.success.message',
                          },
                          {
                            boldText,
                          }
                        )}
                      </Typography>
                      <Button
                        variant="outlined"
                        endIcon={<Check />}
                        color="inherit"
                        sx={{ alignSelf: 'flex-start' }}
                        onClick={() => setShowPredictionBanner(null)}
                      >
                        {intl.formatMessage({
                          id: 'components.fieldsMappingStep.prediction.success.button',
                        })}
                      </Button>
                    </Stack>
                  </Alert>
                ) : showPredictionBanner === 'error' ? (
                  <Alert
                    variant="filled"
                    severity="warning"
                    sx={{ width: '100%' }}
                  >
                    <AlertTitle>
                      {intl.formatMessage({
                        id: 'components.fieldsMappingStep.prediction.error.title',
                      })}
                    </AlertTitle>
                    <Stack spacing={2}>
                      <Typography variant="body2">
                        {intl.formatMessage(
                          {
                            id: 'components.fieldsMappingStep.prediction.error.message',
                          },
                          {
                            boldText,
                          }
                        )}
                      </Typography>
                      <Button
                        variant="outlined"
                        endIcon={<Check />}
                        color="inherit"
                        sx={{ alignSelf: 'flex-start' }}
                        onClick={() => setShowPredictionBanner(null)}
                      >
                        {intl.formatMessage({
                          id: 'components.fieldsMappingStep.prediction.error.button',
                        })}
                      </Button>
                    </Stack>
                  </Alert>
                ) : (
                  <span style={{ display: 'none' }} />
                )}
              </Grow>
            </SwitchTransition>
          </Stack>
          {predictSchemaStatus === 'loading' ? (
            <CircularProgress />
          ) : (
            <Stack spacing={2} sx={{ width: '100%' }} alignItems="center">
              <TransitionGroup style={{ width: '100%' }} component={null}>
                {engineSchema.content.fields.map((field, i) => (
                  <Grow
                    key={
                      isSimpleMultivalueField(field)
                        ? `${i}-${field.children.engineOutputId}`
                        : `${i}-${field.engineOutputId}`
                    }
                  >
                    <Box sx={{ width: '100%' }}>
                      <SwitchTransition>
                        <Grow key={editingAt === i ? 'form' : 'card'}>
                          <Box>
                            {editingAt === i ? (
                              isDatapointField(field) ||
                              isSimpleMultivalueField(field) ? (
                                <HeaderFieldForm
                                  defaultValues={viewToFormModel(field)}
                                  engineSchema={engineSchema}
                                  index={i}
                                  disabled={status === 'loading'}
                                  onSubmit={value => handleEditField(value, i)}
                                  onCancel={handleCancelEdit}
                                  submissionError={status === 'error'}
                                />
                              ) : (
                                <LineItemForm
                                  engineSchema={engineSchema}
                                  index={i}
                                  defaultValues={viewToFormModel(field)}
                                  onSubmit={value => handleEditField(value, i)}
                                  onCancel={handleCancelEdit}
                                  disabled={status === 'loading'}
                                  submissionError={status === 'error'}
                                />
                              )
                            ) : (
                              <EngineSchemaObjectCard
                                key={
                                  isSimpleMultivalueField(field)
                                    ? `${i}-${field.children.engineOutputId}`
                                    : `${i}-${field.engineOutputId}`
                                }
                                field={field}
                                disabled={
                                  isLocked ||
                                  editingAt !== null ||
                                  addingNew !== null ||
                                  status === 'loading'
                                }
                                actions={[
                                  {
                                    id: 'edit',
                                    label: intl.formatMessage({
                                      id: 'components.headerFieldCard.actions.edit',
                                    }),
                                    callback: () => setEditingAt(i),
                                  },
                                  {
                                    id: 'delete',
                                    label: intl.formatMessage({
                                      id: 'components.headerFieldCard.actions.delete',
                                    }),
                                    callback: () => handleDeleteField(i),
                                  },
                                ]}
                                fieldType="headerField"
                              />
                            )}
                          </Box>
                        </Grow>
                      </SwitchTransition>
                    </Box>
                  </Grow>
                ))}
              </TransitionGroup>
              <SwitchTransition>
                <Grow key={addingNew ?? 'none'} appear unmountOnExit>
                  {addingNew === 'headerField' ? (
                    <Box sx={{ width: '100%' }}>
                      <HeaderFieldForm
                        engineSchema={engineSchema}
                        defaultValues={headerFieldFormDefaultValues(
                          engineSchema
                        )}
                        onSubmit={handleAddField}
                        onCancel={handleDeleteEmpty}
                        disabled={status === 'loading'}
                        submissionError={status === 'error'}
                      />
                    </Box>
                  ) : addingNew === 'lineItem' ? (
                    <Box sx={{ width: '100%' }}>
                      <LineItemForm
                        engineSchema={engineSchema}
                        defaultValues={lineItemFormDefaultValues()}
                        onSubmit={handleAddField}
                        onCancel={handleDeleteEmpty}
                        disabled={status === 'loading'}
                        submissionError={status === 'error'}
                      />
                    </Box>
                  ) : !isLocked && addingNew === null && editingAt === null ? (
                    AddNewButtonUI
                  ) : (
                    <span />
                  )}
                </Grow>
              </SwitchTransition>
            </Stack>
          )}
        </Stack>
      </Fade>
      <Stack
        direction="row"
        spacing={2}
        sx={{ p: 4, pt: 1 }}
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handlePreviousClick}
          disabled={editingAt !== null || addingNew !== null}
        >
          {intl.formatMessage({ id: 'components.stepWorkflowButtons.back' })}
        </Button>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mr: 1, ml: 1, flexShrink: 0 }}
        >
          {intl.formatMessage(
            {
              id: 'containers.settings.engineDetail.helpText',
            },
            {
              link: link(DEDICATED_ENGINES_TRAINING_LINK),
            }
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          disabled={editingAt !== null || addingNew !== null}
        >
          {intl.formatMessage({ id: 'components.stepWorkflowButtons.next' })}
        </Button>
      </Stack>
    </Stack>
  );
};

export { FieldsMappingStep };
