import { combineEpics } from 'redux-observable';
import { isTruthy } from 'remeda';
import {
  filter,
  first,
  map,
  mapTo,
  pluck,
  skip,
  switchMap,
} from 'rxjs/operators';
import { isEmbedded } from '../../../constants/config';
import {
  annotationExpired,
  deleteAnnotationFulfilled,
  postponeAnnotationFulfilled,
  rejectAnnotation,
  rejectAnnotationFulfilled,
} from '../annotation/actions';
import { deleteAnnotations, postponeAnnotations } from '../annotations/actions';
import { createDatapointsFulfilled } from '../datapoints/actions';
import { createExtensionFulfilled } from '../extensions/actions';
import {
  createQueueFulfilled,
  queueNotFound,
  updateQueueDetailFulfilled,
} from '../queues/actions';
import { updateSchemaFulfilled } from '../schema/actions';
import { updateUserFulfilled } from '../user/actions';
import { pathnameContainsQueueUsers } from '../user/helpers';
import { updateUserDetailFulfilled } from '../users/actions';
import { isActionOf, makeEpic } from '../utils';
import { createWorkspaceFulfilled } from '../workspaces/actions';
import { throwError, throwInfo } from './actions';

const showDeleteInformationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(deleteAnnotations)),
    switchMap(({ meta: { urls } }) =>
      action$.pipe(
        filter(isActionOf(deleteAnnotationFulfilled)),
        pluck('meta', 'withMessage'),
        filter(withMessage => withMessage),
        skip(urls.length - 1),
        first(),
        filter(() => !isEmbedded()),
        map(() =>
          throwInfo('annotationDeleted', undefined, { count: urls.length })
        )
      )
    )
  )
);

const showPostponeInformationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(postponeAnnotations)),
    switchMap(({ meta: { urls } }) =>
      action$.pipe(
        filter(isActionOf(postponeAnnotationFulfilled)),
        pluck('meta', 'withMessage'),
        filter(withMessage => withMessage),
        skip(urls.length - 1),
        first(),
        filter(() => !isEmbedded()),
        map(() =>
          throwInfo('annotationPostponed', undefined, { count: urls.length })
        )
      )
    )
  )
);

const showRejectInformationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(rejectAnnotation)),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(rejectAnnotationFulfilled)),
        pluck('meta', 'withMessage'),
        filter(withMessage => !!withMessage),
        filter(() => !isEmbedded()),
        map(() => throwInfo('rejectAnnotation', undefined, { count: 1 }))
      )
    )
  )
);

export const showDatapointsEmptyResponseEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(createDatapointsFulfilled)),
    pluck('payload', 'content'),
    filter(content => !content.length),
    mapTo(throwError('operationTimeout'))
  )
);

const showExpirationInformationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(annotationExpired)),
    mapTo(throwInfo('timeOut', { timeOut: 0 }))
  )
);

const schemaUpdatedConfirmationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(updateSchemaFulfilled)),
    pluck('meta', 'withMessage'),
    filter(isTruthy),
    mapTo(throwInfo('schemaUpdated'))
  )
);

const extensionCreatedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(createExtensionFulfilled)),
    filter(({ payload }) => payload.config.app?.displayMode !== 'fullscreen'),
    mapTo(throwInfo('extensionCreated'))
  )
);

const workspaceCreatedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(createWorkspaceFulfilled)),
    filter(({ meta }) => meta.withMessage),
    mapTo(throwInfo('workspaceCreated'))
  )
);

const queueUpdatedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateQueueDetailFulfilled)),
    filter(
      () =>
        !(
          pathnameContainsQueueUsers(state$.value.router.location.pathname) ||
          state$.value.router.location.pathname.includes('/extensions') ||
          state$.value.router.location.pathname.includes('/annotations') ||
          state$.value.router.location.pathname.includes('/documents')
        )
    ),
    mapTo(throwInfo('queueUpdated'))
  )
);

const userDetailUpdatedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([updateUserDetailFulfilled, updateUserFulfilled])),
    pluck('meta', 'withMessage'),
    filter(isTruthy),
    mapTo(throwInfo('userUpdated'))
  )
);

const queueCreatedEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(createQueueFulfilled)),
    filter(({ meta }) => !!meta.withMessage),
    pluck('payload', 'name'),
    map(name => throwInfo('queueCreated', undefined, { name }))
  )
);

const queueNotFoundMessageEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(queueNotFound)),
    mapTo(throwError('queueNotFound'))
  )
);

export default combineEpics(
  schemaUpdatedConfirmationEpic,
  showDatapointsEmptyResponseEpic,
  showDeleteInformationEpic,
  showExpirationInformationEpic,
  showPostponeInformationEpic,
  showRejectInformationEpic,
  queueUpdatedEpic,
  userDetailUpdatedEpic,
  workspaceCreatedEpic,
  queueCreatedEpic,
  queueNotFoundMessageEpic,
  extensionCreatedEpic
);
