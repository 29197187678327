import { Url } from '@rossum/api-client';
import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  UseControllerProps,
} from 'react-hook-form';
import { BillingQueuePicker, QueueFilter } from './BillingQueuePicker';

type CheckShapeAtPath<
  FormState extends FieldValues,
  P extends Path<FormState>,
  ExpectedShape,
> = FieldPathValue<FormState, P> extends ExpectedShape ? P : never;

type PathsOfShape<Shape, FormState extends FieldValues> = {
  [P in Path<FormState>]: CheckShapeAtPath<FormState, P, Shape>;
}[Path<FormState>];

type ControlledQueuePickerProps<T extends FieldValues> = {
  ControllerProps: UseControllerProps<
    T,
    PathsOfShape<QueueFilter | undefined, T>
  >;
  organizationUrl: Url | undefined;
};

const ControlledQueuePicker = <T extends FieldValues>({
  ControllerProps,
  organizationUrl,
}: ControlledQueuePickerProps<T>) => {
  return (
    <Controller
      {...ControllerProps}
      render={({ field }) => {
        return (
          <BillingQueuePicker
            organizationUrl={organizationUrl}
            onChange={field.onChange}
            initialValue={field.value as QueueFilter | undefined}
          />
        );
      }}
    />
  );
};

export { ControlledQueuePicker };
