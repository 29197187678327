import { IconChevronDown, IconChevronUp, IconX } from '@rossum/ui/icons/tabler';
import {
  IconButton,
  Paper,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'remeda';
import {
  displaySearchPanel,
  getSearchResults,
  nextSearchResult,
  previousSearchResult,
} from '../../../redux/modules/search/actions';
import { State } from '../../../types/state';
import { useDocumentStore } from '../document-store/DocumentStore';

type Props = {
  open: boolean;
  shouldSearchTranslations: boolean;
};

export const SearchPanel = ({ open, shouldSearchTranslations }: Props) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const setTranslationState = useDocumentStore(
    state => state.translationActions.setTranslationState
  );

  const searchState = useSelector((state: State) => state.search);

  const amountOfResults = searchState.results.length;
  const currrentResult = amountOfResults
    ? searchState.currentResultIndex + 1
    : 0;

  const debouncer = R.debounce(
    phrase => {
      dispatch(getSearchResults(phrase ?? ''));
    },
    { waitMs: 300 }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (shouldSearchTranslations) {
      setTranslationState({ searchValue: e.target.value });
    } else debouncer.call(e.target.value);
  };

  const handleClose = () => {
    dispatch(displaySearchPanel(false));
    setTranslationState({ searchValue: '' });
  };

  useEffect(() => {
    if (open && searchInput.current) {
      searchInput.current.focus();
    }
  }, [open]);

  // Slide has transform applied to its direct child which is why we need container with a different transform to properly center the component.
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 50,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: t => t.zIndex.appBar,
      }}
    >
      <Slide in={open} unmountOnExit mountOnEnter>
        <Stack
          component={Paper}
          elevation={4}
          sx={{
            px: 2,
            py: 1,
            gap: 1,
          }}
          alignItems="center"
          direction="row"
          data-cy="search-panel"
        >
          <TextField
            // reset value on close or switch
            key={`${open}-${shouldSearchTranslations}`}
            inputRef={searchInput}
            size="small"
            onChange={handleInputChange}
            inputProps={{
              'data-cy': 'search-input',
            }}
            InputProps={{
              endAdornment: !shouldSearchTranslations ? (
                <Typography
                  data-cy="search-input-results"
                  variant="body2"
                  color="text.disabled"
                >{`${currrentResult}/${amountOfResults}`}</Typography>
              ) : null,
            }}
          />

          {!shouldSearchTranslations ? (
            <>
              <IconButton
                size="small"
                onClick={() => dispatch(previousSearchResult())}
              >
                <SvgIcon fontSize="small">
                  <IconChevronUp />
                </SvgIcon>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => dispatch(nextSearchResult())}
              >
                <SvgIcon fontSize="small">
                  <IconChevronDown />
                </SvgIcon>
              </IconButton>
            </>
          ) : null}
          <IconButton size="small" onClick={handleClose}>
            <SvgIcon fontSize="small">
              <IconX />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Slide>
    </Stack>
  );
};
