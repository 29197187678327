import { SchemaSection } from '@rossum/api-client/schemas';
import { Divider, Stack } from '@rossum/ui/material';
import { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import SearchInput from '../../../ui/search-input/SearchInput';
import { getNonTupleSchemaFields } from '../FormulaEditor/helpers';
import { OnInsertCallback } from './insertion-helpers';
import { MenuListFields } from './MenuListFields';
import { fuzzySearchMultipleWords } from './searchMatching';

const Suggester = ({
  onInsert,
  schemaContent,
}: {
  onInsert: OnInsertCallback;
  schemaContent: SchemaSection[];
}) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const firstMenuItemRef = useRef<HTMLLIElement | null>(null);

  const activeTab = 'fields';

  const schemaItemsWithSections = useRef(
    getNonTupleSchemaFields(schemaContent)
  ).current;

  const schemaItems = useRef(
    schemaItemsWithSections.flatMap(schemaItem =>
      schemaItem.category !== 'section' ? [schemaItem] : []
    )
  ).current;

  const filteredSchemaItems = useMemo(() => {
    return fuzzySearchMultipleWords(schemaItems, ['label', 'id'], searchValue);
  }, [schemaItems, searchValue]);

  return (
    <Stack divider={<Divider />} sx={{ background: 'inherit' }}>
      <Stack sx={{ p: 2 }} spacing={1}>
        <SearchInput
          onChange={setSearchValue}
          onKeyDown={e => {
            if (e.key === 'ArrowDown') {
              firstMenuItemRef.current?.focus();
            }
          }}
          value={searchValue}
          placeholder={intl.formatMessage({
            id: 'features.formulas.suggester.placeholder',
          })}
          sx={{
            width: '100%',
          }}
          InputProps={{
            sx: {
              fontSize: ({ typography }) => typography.body2.fontSize,
            },
            autoComplete: 'off',
            autoFocus: true,
          }}
        />
      </Stack>
      {activeTab === 'fields' && (
        <MenuListFields
          search={searchValue}
          schemaItems={
            searchValue ? filteredSchemaItems : schemaItemsWithSections
          }
          onInsert={onInsert}
          setFirstRef={firstMenuItemRef}
        />
      )}
    </Stack>
  );
};

export { Suggester };
