import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { organizationSchema } from '../models/organization.schema';
import { listOrganizationQuerySchema } from './list.schema';

export type ListOrganizationQuery = PaginationQuery & {
  includeMembershipOrganizations?: boolean;
};

export const list = (query: ListOrganizationQuery) => {
  return {
    endpoint: `/organizations`,
    method: 'GET',
    responseSchema: listResponse(organizationSchema),
    query,
    querySchema: listOrganizationQuerySchema,
  } as const;
};
