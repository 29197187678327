import {
  IconCustomSuggest,
  IconCustomSuggestCheck,
  IconCustomSuggestFile,
  IconCustomSuggestX,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { chain } from 'lodash';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useAcceptSuggestions } from '../../../../components/DocumentPage/useAcceptSuggestions';
import { useCurrentPage } from '../../../../components/LineItemsControls/components/SuggestButton/useCurrentPage';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import { boldText } from '../../../../lib/formaterValues';
import { isNotNullOrUndefined } from '../../../../lib/typeGuards';
import { pagesSelector } from '../../../../redux/modules/annotation/selectors';
import {
  deleteAllDatapoints,
  discardSuggestions,
  suggestTable,
} from '../../../../redux/modules/datapoints/actions';
import { hasSuggestionsSelector } from '../../../../redux/modules/datapoints/suggestedOperations/selector';
import { isVirtualDatapoint } from '../../../../redux/modules/datapoints/typedHelpers';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { MultivalueDatapointDataST } from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { AuroraPromo } from '../../complex-line-items-tour/AuroraPromo';
import { AuroraTour } from '../../complex-line-items-tour/AuroraTour';

const LineItemsControls = ({
  tupleIds,
  currentDatapoint,
}: {
  tupleIds: Array<number>;
  currentDatapoint: MultivalueDatapointDataST;
}) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const amount = tupleIds.filter(id => !isVirtualDatapoint(id))?.length;

  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const { acceptSuggestions, canAcceptSuggestions, loading } =
    useAcceptSuggestions({ multivalueId: currentDatapoint.id });

  // Delete all
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onDeleteAll = useCallback(() => {
    dispatch(deleteAllDatapoints(currentDatapoint.meta.index));
  }, [currentDatapoint.meta.index, dispatch]);

  const onCloseDeleteDialog = useCallback(() => setOpenDeleteDialog(false), []);

  // Discard suggestions
  const canDiscardSuggestions = useSelector(hasSuggestionsSelector);

  const discardSuggestionsCallback = useCallback(() => {
    dispatch(discardSuggestions());
  }, [dispatch]);

  // Suggest next pages
  const waitingForSuggestions = useSelector(
    (state: State) => state.datapoints.waitingForSuggestions
  );

  const pages = useSelector(pagesSelector);

  const { pageIndex: visiblePageIndex, pageNumber: currentPage } =
    useCurrentPage();

  const suggestNextPages = useCallback(() => {
    if (!waitingForSuggestions && currentPage) {
      const pagesChunks = chain(pages)
        .slice(visiblePageIndex)
        .map(page => page.number)
        .filter(isNotNullOrUndefined)
        .chunk(5)
        .value();

      dispatch(
        suggestTable({
          multivalueId: currentDatapoint.id,
          pagesChunks,
        })
      );
    }
  }, [
    currentDatapoint.id,
    currentPage,
    dispatch,
    pages,
    visiblePageIndex,
    waitingForSuggestions,
  ]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        position="relative"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Tooltip
          title={intl.formatMessage({
            id: 'components.sidebarV2.lineItemsActions.deleteAll.label',
          })}
        >
          <span>
            <IconButton
              size="small"
              data-cy="line-items-delete-all-btn"
              disabled={amount < 1 || waitingForSuggestions || loading}
              onClick={() => setOpenDeleteDialog(true)}
            >
              <SvgIcon fontSize="small">
                <IconTrash />
              </SvgIcon>
            </IconButton>
          </span>
        </Tooltip>
        {complexLineItemsEnabled ? (
          <>
            <Tooltip
              title={intl.formatMessage({
                id: 'components.sidebarV2.lineItemsActions.rejectSuggestions.label',
              })}
            >
              <span>
                <IconButton
                  size="small"
                  disabled={
                    !canDiscardSuggestions || waitingForSuggestions || loading
                  }
                  onClick={() => {
                    discardSuggestionsCallback();
                  }}
                  data-cy="line-items-reject-suggestions"
                >
                  <SvgIcon fontSize="small">
                    <IconCustomSuggestX />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage({
                id: 'components.sidebarV2.lineItemsActions.suggestNextPages.label',
              })}
            >
              <span>
                <IconButton
                  size="small"
                  disabled={waitingForSuggestions || loading}
                  onClick={() => {
                    suggestNextPages();
                  }}
                  data-cy="line-items-suggest-next-pages"
                >
                  <SvgIcon fontSize="small">
                    <IconCustomSuggestFile />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage({
                id: 'components.sidebarV2.lineItemsActions.suggest.label',
              })}
            >
              <span>
                <IconButton
                  size="small"
                  disabled={amount < 1 || waitingForSuggestions || loading}
                  data-cy="line-items-suggest"
                  onClick={() => {
                    if (!(amount < 1 || waitingForSuggestions) && currentPage) {
                      dispatch(
                        suggestTable({
                          multivalueId: currentDatapoint.id,
                          pagesChunks: [[currentPage]],
                        })
                      );
                    }
                  }}
                >
                  <SvgIcon fontSize="small">
                    <IconCustomSuggest />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              sx={{ minWidth: 0, px: 1 }}
              disabled={!canAcceptSuggestions}
              onClick={acceptSuggestions}
              data-cy="line-items-accept-suggestions"
            >
              {loading || waitingForSuggestions ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <SvgIcon fontSize="small">
                  <IconCustomSuggestCheck />
                </SvgIcon>
              )}
            </Button>
          </>
        ) : null}
      </Stack>
      {/* Delete dialog */}
      {/* TODO: Move translations after removing old sidebar */}
      <Dialog
        open={openDeleteDialog}
        PaperProps={{
          sx: { width: 480, minHeight: 220, position: 'fixed' },
          elevation: 2,
        }}
        onClose={onCloseDeleteDialog}
        sx={{ transition: 'smooth' }}
      >
        <ActionDialog
          dialogTitle={intl.formatMessage({
            id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.title',
          })}
          onCancel={onCloseDeleteDialog}
          dialogColor="primary"
          dialogActions={
            <ActionDialogActions
              confirmButtonProps={{
                onClick: () => {
                  onCloseDeleteDialog();
                  onDeleteAll();
                },
                color: 'primary',
              }}
              confirmButtonText={intl.formatMessage({
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.confirm.button',
              })}
              cancelButtonText={intl.formatMessage({
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.cancel.button',
              })}
              dataCyConfirm="line-items-delete-all-confirm-btn"
              dataCyCancel="line-items-delete-all-cancel-btn"
            />
          }
          dataCy="close-dialog-btn"
        >
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.text',
              },
              {
                boldText,
                amount,
              }
            )}
          </Typography>
        </ActionDialog>
      </Dialog>
      {complexLineItemsEnabled ? <AuroraTour /> : <AuroraPromo />}
    </>
  );
};

export { LineItemsControls };
