import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCustomBoltPause,
  IconInfoSquareRounded,
} from '@rossum/ui/icons/tabler';
import { Badge, SvgIcon } from '@rossum/ui/material';
import {
  BLOCKERS_COLOR,
  MessagesStatsWithBlockers,
} from '../../sidebar-infosections/automation-blockers/helpers';

type MessageIndicatorProps = {
  messagesStats: MessagesStatsWithBlockers | undefined;
};

const IconsMap = {
  error: IconAlertOctagon,
  warning: IconAlertTriangle,
  info: IconInfoSquareRounded,
  blocker: IconCustomBoltPause,
};

export const MessageIndicator = ({ messagesStats }: MessageIndicatorProps) => {
  if (!messagesStats) {
    return null;
  }

  const Icon = IconsMap[messagesStats.highestSeverity];

  return (
    <Badge
      max={9}
      badgeContent={messagesStats.count}
      invisible={messagesStats.count === 1}
      // TODO: @ui change `default` variant in theme overrides
      color="secondary"
      slotProps={{
        badge: {
          style: {
            minWidth: 16,
            height: 16,
            padding: '0 4px',
          },
        },
      }}
    >
      {/* TODO: @sidebarV2 Make it same as SchemaFieldIcon */}
      <SvgIcon
        component={Icon}
        color={
          messagesStats.highestSeverity === 'blocker'
            ? BLOCKERS_COLOR
            : messagesStats.highestSeverity
        }
        sx={{ fill: 'none' }}
        // TODO: @ui correct fontSize should be `medium` - fix when we set correct icon sizes from design system
        fontSize="small"
      />
    </Badge>
  );
};
