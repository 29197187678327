import {
  Redirect,
  Route,
  RouteChildrenProps,
  Switch,
  useRouteMatch,
} from 'react-router';
import {
  RuleTemplatePage,
  RuleTemplatePageDetail,
} from './pages/RuleTemplatePage';
import { RuleTemplatesPage } from './pages/RuleTemplatesPage';

type RulesRouteProps = RouteChildrenProps;

export const RuleTemplatesRoute = ({ location }: RulesRouteProps) => {
  const match = useRouteMatch();
  return (
    <Switch location={location}>
      <Route path={`${match.path}`} exact>
        <RuleTemplatesPage />
      </Route>
      <Route path={`${match.path}/new`} exact>
        <RuleTemplatePage />
      </Route>
      <Route path={`${match.path}/:ruleTemplateId?/detail`} exact>
        {routeChildrenProps => {
          const ruleTemplateId =
            routeChildrenProps.match?.params.ruleTemplateId;
          return (
            <RuleTemplatePageDetail
              ruleTemplateId={Number(ruleTemplateId)}
              parentPath={match.url}
            />
          );
        }}
      </Route>
      <Route path={`${match.path}`}>
        <Redirect
          to={{
            pathname: `${match.url}`,
            state: location.state,
          }}
        />
      </Route>
    </Switch>
  );
};
