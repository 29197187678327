import type { TextFieldProps } from '@rossum/ui/material';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { forwardRef, Fragment, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { DataType, useTypesAndSubtypes } from '../hooks/useTypesAndSubtypes';
import { AutocompleteGroup } from './autocomplete/AutocompleteGroup';
import { AutocompleteItem } from './autocomplete/AutocompleteItem';
import { AutocompleteNoResults } from './autocomplete/AutocompleteNoResults';

const renderGroupLabel = (option: DataType, intl: IntlShape) =>
  intl.formatMessage({
    id: `features.engines.fieldDetail.type.field.dataType.group.${option.type}`,
  });

const renderSubtypeLabel = (option: DataType, intl: IntlShape) =>
  intl.formatMessage({
    id: `features.engines.fieldDetail.type.field.dataType.subType.${option.id ?? 'string.default'}.label`,
  });

const renderSubtypeDescription = (option: DataType, intl: IntlShape) =>
  intl.formatMessage({
    id: `features.engines.fieldDetail.type.field.dataType.subType.${option.id ?? 'string.default'}.description`,
  });

export const renderOption = (option: DataType, intl: IntlShape) => {
  return `${renderGroupLabel(option, intl)} - ${renderSubtypeLabel(option, intl)}`;
};

export const TypesSubtypesInput = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      TextFieldProps,
    }: {
      value: DataType;
      onChange: (newValue: DataType) => void;
      disabled: boolean;
      TextFieldProps: Pick<TextFieldProps, 'helperText' | 'error'>;
    },
    ref: React.ForwardedRef<unknown>
  ) => {
    const intl = useIntl();
    const { typesAndSubtypes } = useTypesAndSubtypes();
    const [inputValue, setInputValue] = useState('');

    // Generate en.json entries
    // console.log(
    //   `${typesAndSubtypes
    //     .map(ts => ts.subtype || 'default')
    //     .map(
    //       s =>
    //         `"features.engines.fieldDetail.type.field.dataType.subType.${s}.label": "",
    //          "features.engines.fieldDetail.type.field.dataType.subType.${s}.description": "",`
    //     )
    //     .join('\n')}`
    // );

    const selectedItem = useMemo(
      () =>
        typesAndSubtypes.find(
          option =>
            option.type === value.type && option.subtype === value.subtype
        ) ?? { type: 'string' as const, subtype: null },
      [typesAndSubtypes, value.subtype, value.type]
    );

    return (
      <Autocomplete
        id="field.dataType"
        ref={ref}
        value={selectedItem}
        disableClearable
        onChange={(_, newValue) => {
          if (newValue) {
            onChange(newValue);
          }
        }}
        onInputChange={(_, enteredText) => {
          setInputValue(enteredText);
        }}
        inputValue={inputValue}
        options={typesAndSubtypes}
        getOptionLabel={option => (option ? renderOption(option, intl) : '')}
        isOptionEqualToValue={(option, currentValue) =>
          option.type === currentValue.type &&
          option.subtype === currentValue.subtype
        }
        renderInput={params => (
          <TextField
            {...params}
            {...TextFieldProps}
            label={intl.formatMessage({
              id: 'features.engines.fieldDetail.type.field.dataType',
            })}
          />
        )}
        groupBy={option => option.type ?? ''}
        renderGroup={params => {
          const option = typesAndSubtypes.find(
            option => option.type === params.group
          );

          return (
            <Fragment key={params.key}>
              {option && (
                <AutocompleteGroup>
                  {renderGroupLabel(option, intl)}
                </AutocompleteGroup>
              )}
              {params.children}
            </Fragment>
          );
        }}
        renderOption={(params, option) => (
          <AutocompleteItem
            MenuItemProps={params}
            key={`${option.type}/${option.subtype}`}
            label={renderSubtypeLabel(option, intl)}
            description={renderSubtypeDescription(option, intl)}
          />
        )}
        noOptionsText={<AutocompleteNoResults inputValue={inputValue} />}
        disabled={disabled}
      />
    );
  }
);
