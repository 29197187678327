import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { State as Statistics } from '../../../types/statistics';
import { RootActionType } from '../../rootActions';
import { fetchStatisticsFulfilled, showTmpStatistics } from './actions';

const initialState: Statistics = {
  series: [],
  totals: {
    importedCount: 0,
    deletedCount: 0,
    exportedCount: 0,
    turnaroundAvgS: 0,
    correctionsPerDocumentAvg: 0,
    exportedOnTimeCount: 0,
    exportedLateCount: 0,
    timePerDocumentAvgS: 0,
    rejectedAutomaticallyCount: 0,
    rejectedManuallyCount: 0,
  },
  isTmpState: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(showTmpStatistics):
      return R.pipe(
        state,
        R.mergeDeep(action.payload),
        R.merge({ isTmpState: true })
      );
    case getType(fetchStatisticsFulfilled):
      return R.pipe(
        state,
        R.mergeDeep(action.payload),
        R.merge({ isTmpState: false })
      );
    default:
      return state;
  }
};

export default reducer;
