import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { MenuItem } from '@rossum/ui/material';
import update from 'immutability-helper';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as R from 'remeda';
import { partition } from 'remeda';
import { throwInfo } from '../../../../../redux/modules/messages/actions';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { PreviewDrawer } from '../../ui/PreviewDrawer';
import { getProcessRowUpdate, UpdatedSchemasMap } from '../getProcessRowUpdate';
import { GridRowModel, GridRowModelKeys } from '../rows/rowTypes';
import { ExpandableItem } from './components/ExpandableItem';
import { getOptions, getVariantLabel } from './helpers';

type EditColumnDrawerProps = {
  onClose: () => void;
  dataForBulkEditDrawer: {
    // Bulk edit is available only for options and formula for now
    field: Extract<GridRowModelKeys, 'options' | 'formula'>;
    aggregations?: Set<string>;
    rows?: GridRowModel[];
  } | null;
  setUpdatedSchemas: React.Dispatch<React.SetStateAction<UpdatedSchemasMap>>;
  setRows: React.Dispatch<React.SetStateAction<GridRowModel[]>>;
  rows: GridRowModel[];
  flatSchemasWithQueues: FlatSchemaWithQueues[];
};

const getUpdatedRow = (
  row: GridRowModel,
  field: Extract<GridRowModelKeys, 'options' | 'formula'>,
  selectedVariant: string
): GridRowModel => {
  if (row.category === 'datapoint' && row.type !== 'button') {
    if (field === 'options') {
      const optionsToUpdate = getOptions(selectedVariant);

      return 'options' in row
        ? { ...row, options: optionsToUpdate }
        : {
            ...row,
            options: optionsToUpdate,
            type: 'enum',
          };
    }
    if (field === 'formula') {
      return 'formula' in row
        ? { ...row, formula: selectedVariant }
        : {
            ...row,
            formula: selectedVariant,
            rirFieldNames: [],
            uiConfiguration: {
              type: 'formula',
              edit: 'disabled',
            },
          };
    }
    return row;
  }

  return row;
};

export const EditColumnDrawer = ({
  onClose,
  dataForBulkEditDrawer,
  setUpdatedSchemas,
  setRows,
  rows,
  flatSchemasWithQueues,
}: EditColumnDrawerProps) => {
  const intl = useIntl();
  const field = dataForBulkEditDrawer?.field;

  const [filteredRows, unselectableRows] = useMemo(() => {
    const rowsToEdit = dataForBulkEditDrawer?.rows ?? rows;
    // Only allow to edit rows which are of category === datapoint
    return partition(rowsToEdit, row => row.category === 'datapoint') ?? [];
  }, [dataForBulkEditDrawer?.rows, rows]);

  const variants = useMemo(() => {
    if (!filteredRows || !field) {
      return undefined;
    }

    const grouped = filteredRows.reduce<
      Record<
        string,
        {
          value: string | undefined;
          rows: Array<GridRowModel>;
        }
      >
    >((acc, curr) => {
      const fieldValue =
        field === 'formula'
          ? 'formula' in curr
            ? curr.formula
            : undefined
          : 'options' in curr
            ? JSON.stringify(curr.options)
            : undefined;

      const fieldValueKey = fieldValue ?? 'undefined';

      if (acc[fieldValueKey]) {
        if ((acc[fieldValueKey]?.rows ?? []).includes(curr)) {
          return acc;
        }
        return update(acc, {
          [fieldValueKey]: { rows: { $push: [curr] } },
        });
      }
      return {
        ...acc,
        [fieldValueKey]: {
          value: fieldValue ? fieldValueKey : undefined,
          rows: [curr],
        },
      };
    }, {});

    return grouped;
  }, [field, filteredRows]);

  const fieldVariants = useMemo(
    () => (variants ? Object.entries(variants) : []),
    [variants]
  );

  const [selectedRows, setSelectedRows] = useState<GridRowModel[]>([]);

  const countOfUpdatedQueues = selectedRows.filter(selectedRow =>
    filteredRows.some(filteredRow => selectedRow.id === filteredRow.id)
  ).length;

  const [selectedValueVariant, setSelectedValueVariant] = useState<
    string | null
  >(null);

  const [drawerError, setDrawerError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const closeDrawer = useCallback(() => {
    setSelectedRows([]);
    setSelectedValueVariant(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (!selectedValueVariant) {
      setDrawerError(
        intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.emptyReplaceValue.error',
        })
      );
    }

    if (selectedRows.length === 0) {
      setDrawerError(
        intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.emptyVariantsToBeReplaced.error',
        })
      );
    }

    if (selectedValueVariant && selectedRows.length > 0) {
      setDrawerError(null);
    }

    if (field && selectedValueVariant) {
      const processUpdate = getProcessRowUpdate({
        setRows,
        setUpdatedSchemas,
        schemas: flatSchemasWithQueues,
      });

      selectedRows.forEach((selectedRow, index, array) => {
        const newRow = getUpdatedRow(selectedRow, field, selectedValueVariant);

        processUpdate(newRow, selectedRow);

        if (index === array.length - 1) {
          closeDrawer();
          dispatch(
            throwInfo('editColumns', undefined, { count: countOfUpdatedQueues })
          );
        }
      });
    }
  }, [
    closeDrawer,
    countOfUpdatedQueues,
    dispatch,
    field,
    flatSchemasWithQueues,
    intl,
    selectedRows,
    selectedValueVariant,
    setRows,
    setUpdatedSchemas,
  ]);

  return (
    <PreviewDrawer
      onClose={closeDrawer}
      open={!!dataForBulkEditDrawer}
      title={
        dataForBulkEditDrawer?.field === 'formula'
          ? intl.formatMessage({
              id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.emptyVariantsToBeReplaced.title.formula',
            })
          : intl.formatMessage({
              id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.emptyVariantsToBeReplaced.title.enum',
            })
      }
      subtitle={intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.emptyVariantsToBeReplaced.selectedQueues',
        },
        {
          count: filteredRows.length,
          variants: fieldVariants.length,
        }
      )}
      elevation={4}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={closeDrawer}>
            {intl.formatMessage({
              id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.button.close',
            })}
          </Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({
              id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.button.replace',
            })}
          </Button>
        </>
      }
      content={
        <Stack divider={<Divider sx={{ my: 2 }} />}>
          <Stack spacing={2}>
            <Stack spacing={0.5}>
              <Typography variant="h6">
                {intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.variants.subtitle',
                })}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.variants.description',
                })}
              </Typography>
            </Stack>
            <List>
              {field && fieldVariants
                ? [
                    <ListItem key="select-all" disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setDrawerError(null);
                          return filteredRows.every(row =>
                            selectedRows.includes(row)
                          )
                            ? setSelectedRows([])
                            : setSelectedRows(filteredRows);
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={filteredRows.every(row =>
                              selectedRows.includes(row)
                            )}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            filteredRows.every(row =>
                              selectedRows.includes(row)
                            )
                              ? intl.formatMessage({
                                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.unSelectAll',
                                })
                              : intl.formatMessage({
                                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.selectAll',
                                })
                          }
                        />
                      </ListItemButton>
                    </ListItem>,
                    ...fieldVariants.map(([fieldVariant, variantData]) => {
                      const label = getVariantLabel(
                        variantData.rows?.[0],
                        field
                      );
                      const variantRows = variantData.rows;

                      return (
                        <ExpandableItem
                          key={fieldVariant}
                          variant={field}
                          label={label}
                          onClick={() => {
                            setDrawerError(null);
                            return variantRows.every(variantRow =>
                              selectedRows.includes(variantRow)
                            )
                              ? setSelectedRows(
                                  selectedRows.filter(
                                    selectedRow =>
                                      !variantRows.includes(selectedRow)
                                  )
                                )
                              : setSelectedRows(prev =>
                                  R.unique([...prev, ...variantRows])
                                );
                          }}
                          fieldVariant={fieldVariant}
                          optionSelected={variantRows.every(variantRow =>
                            selectedRows.includes(variantRow)
                          )}
                          countOfQueuesWithVariant={
                            variantData.rows?.length ?? 0
                          }
                        />
                      );
                    }),
                    ...(unselectableRows.length > 0
                      ? [
                          <ListItem
                            key={`${unselectableRows[0]?.id}`}
                            secondaryAction={unselectableRows.length ?? 0}
                            disablePadding
                            sx={{
                              pointerEvents: 'none',
                            }}
                          >
                            <ListItemButton dense>
                              <ListItemText
                                primary={intl.formatMessage({
                                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.listItemText',
                                })}
                                primaryTypographyProps={{
                                  color: 'text.disabled',
                                }}
                              />
                            </ListItemButton>
                          </ListItem>,
                        ]
                      : []),
                  ]
                : null}
            </List>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <Typography variant="h6">
                  {intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.replace.subtitle',
                  })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.replace.description',
                  })}
                </Typography>
              </Stack>
              <TextField
                select
                value={selectedValueVariant ?? ''}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.enumFormulaEditing.editColumnDrawer.replace.label',
                })}
                onChange={e => {
                  setDrawerError(null);
                  return setSelectedValueVariant(e.target.value);
                }}
              >
                {field &&
                  fieldVariants.map(([_, variant]) => (
                    <MenuItem key={`${variant.value}`} value={variant.value}>
                      {getVariantLabel(variant.rows?.[0], field)}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            {drawerError ? (
              <Typography variant="body2" color="error">
                {drawerError}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      }
    />
  );
};
