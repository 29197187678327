// Generated by ts-to-zod
import { z } from 'zod';
import { schemaRuleActionSchema } from '../../rules/models/schemaRule.schema';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const ruleTemplateSchema = z.object({
  id: idSchema,
  url: urlSchema,
  name: z.string(),
  description: z.string(),
  triggerCondition: z.string(),
  triggerConditionSummary: z.string().optional(),
  enabled: z.boolean(),
  ruleActions: z.array(schemaRuleActionSchema),
});
