import { pipe } from 'lodash/fp';
import * as R from 'remeda';
import { partition } from 'remeda';
import { AttachmentT } from './EmailAttachment/helpers';

type WithChildren<T> = {
  children: T[];
  item: T;
};

export type WithDepth<T> = {
  depth: number;
  item: T;
};

// Convert a flat array into parent children pairs.
const findChildren = <T, R extends string>(
  items: T[],
  getId: (t: T) => R,
  getParent: (t: T) => R | null
): WithChildren<T>[] => {
  const [rootItems, childItems] = partition(
    items,
    item => getParent(item) === null
  );

  const rootAttachmentIds = rootItems.map(item => getId(item));
  const itemsWithoutParent = childItems
    .filter(item => !rootAttachmentIds.includes(getParent(item)!))
    .map(item => ({ item, children: [] }));

  const childAttachmentsByParent = R.groupBy(
    childItems,
    item => getParent(item) ?? undefined
  );

  return [
    ...itemsWithoutParent,
    ...rootItems.map(item => ({
      item,
      children: childAttachmentsByParent[getId(item)] || [],
    })),
  ];
};

// Flatten rows with children, and put the parents with children to the end.
const flattenRows = <T>(attachmentsWithChildren: WithChildren<T>[]) => {
  const [withChildren, withoutChildren] = partition(
    attachmentsWithChildren,
    attachment => attachment.children.length > 0
  );

  return [...withoutChildren, ...withChildren].reduce<WithDepth<T>[]>(
    (acc, curr) => {
      return [
        ...acc,
        { item: curr.item, depth: 0 },
        ...curr.children.map(child => ({ item: child, depth: 1 })),
      ];
    },
    []
  );
};

export const orderFlatTree = pipe(findChildren, flattenRows);

// Convert a flat array into parent children pairs.
export const getAttachmentRows = (
  attachments: AttachmentT[]
): WithDepth<AttachmentT>[] =>
  orderFlatTree(
    attachments,
    attachment => attachment.document.url,
    attachment => attachment.document.parent
  );
