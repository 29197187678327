import { endpoints, ID, withSideload } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import {
  TransformedAnnotation,
  useTransformAnnotations,
} from '../../../document-list-base/hooks/useTransformAnnotation';
import { allowedStatuses } from '../../../document-list-base/mql/constants';
import { FETCH_ATTACHMENT_INTERVAL } from '../helpers';

const DOC_ATTACHMENTS_QUERY_KEY = 'doc-attachments';

export const useFetchAttachments = (
  attachmentIds: ID[]
): {
  attachments: TransformedAnnotation[];
  isLoading: boolean;
  isError: boolean;
} => {
  const {
    data,
    isInitialLoading: isAttachmentsLoading,
    isError,
  } = useQuery({
    queryKey: [DOC_ATTACHMENTS_QUERY_KEY, ...attachmentIds],

    queryFn: () =>
      api.request(
        withSideload(
          endpoints.annotations.list({
            pageSize: attachmentIds.length,
            id: attachmentIds,
            status: allowedStatuses,
          }),
          {
            documents: true,
            modifiers: true,
            relations: true,
          }
        )
      ),

    enabled: !!attachmentIds.length,
    keepPreviousData: true,
    refetchInterval: FETCH_ATTACHMENT_INTERVAL,
  });

  const { transformedAnnotations, isLoading: isTransforming } =
    useTransformAnnotations({
      annotations: data?.results,
      documents: data?.documents,
      modifiers: data?.modifiers,
      relations: data?.relations,
    });

  const isLoading = isAttachmentsLoading || isTransforming;

  return {
    attachments: transformedAnnotations ?? [],
    isLoading,
    isError,
  };
};
