import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { collectionSize } from '../../../constants/values';
import { errorHandler } from '../../../lib/api';
import {
  isNonEmptyString,
  isNotNullOrUndefined,
} from '../../../lib/typeGuards';
import { Workspace } from '../../../types/workspace';
import { signOut } from '../auth/actions';
import { fetchQueueFulfilled } from '../queues/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  fetchWorkspace,
  fetchWorkspaceFulfilled,
  fetchWorkspaces,
  fetchWorkspacesFulfilled,
  updateWorkspace,
  updateWorkspaceFulfilled,
} from './actions';
import { FetchWorkspaceFulfilledPayload } from './types';

const fetchWorkspacesEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchWorkspaces)),
    pluck('meta', 'url'),
    map(url => url || `${apiUrl}/workspaces`),
    switchMap(url =>
      authGetJSON$<FetchWorkspaceFulfilledPayload>(url, {
        query: { pageSize: collectionSize },
      }).pipe(
        takeUntil(action$.pipe(filter(isActionOf(signOut)))),
        map(fetchWorkspacesFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const fetchNextWorkspacesPageEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchWorkspacesFulfilled)),
    pluck('payload', 'pagination', 'next'),
    filter(isNonEmptyString),
    map(fetchWorkspaces)
  )
);

const fetchQueueDetailWorkspaceEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchQueueFulfilled)),
    filter(
      () => !state$.value.router.location.pathname.includes('/annotations')
    ),
    map(({ payload: { workspace } }) => workspace),
    filter(isNotNullOrUndefined),
    map(fetchWorkspace)
  )
);

const fetchWorkspaceEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchWorkspace)),
    pluck('meta', 'url'),
    switchMap(url =>
      authGetJSON$<Workspace>(url).pipe(
        map(fetchWorkspaceFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const updateWorkspaceEpic = makeEpic((action$, _, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateWorkspace)),
    mergeMap(({ meta: { id }, payload }) =>
      authPatch$(`${apiUrl}/workspaces/${id}`, payload).pipe(
        mapTo(updateWorkspaceFulfilled()),
        catchError(errorHandler)
      )
    )
  )
);

export default combineEpics(
  fetchNextWorkspacesPageEpic,
  fetchQueueDetailWorkspaceEpic,
  fetchWorkspaceEpic,
  fetchWorkspacesEpic,
  updateWorkspaceEpic
);
