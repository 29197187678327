import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { GlobalStyles, Stack, Typography, useTheme } from '@rossum/ui/material';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import { ReactNode, useEffect, useState } from 'react';
import ReactAce from 'react-ace/lib/ace';
import { IAceEditor } from 'react-ace/lib/types';
import { Noop } from 'react-hook-form';
import { useCommands } from './completers/useCommands';
import { useDefaultCompleters } from './completers/useDefaultCompleters';
import { FormulaEditorToolbar } from './components/FormulaEditorToolbar';
import {
  formulaEditorStyles,
  getGlobalStylesOverrides,
} from './formulaEditorStyles';
import { FormulaPythonMode } from './syntax-mode/FormulaPythonMode';
const LINE_HEIGHT = 16;
const MIN_VISIBLE_LINES = 10.6;
const MAX_VISIBLE_LINES = 30.6; // using .6 to display a bit of cutoff line
const SHOW_GUTTERS = false;

type Props = {
  value: string | undefined;
  onChange: (value: string) => void;
  onFocus?: () => void;
  renderButtons?: ({ editor }: { editor: IAceEditor }) => ReactNode;
  config?: {
    maxVisibleLines?: number;
    minVisibleLines?: number;
    showGutters?: boolean;
  };
  currentFormulaId?: string;
  fields: (SchemaSection | SchemaItem)[];
  placeholder?: string;
  onBlur: Noop;
};

const FormulaEditor = ({
  onFocus,
  onChange,
  value,
  renderButtons,
  config,
  currentFormulaId,
  fields,
  placeholder,
  onBlur,
}: Props) => {
  const theme = useTheme();

  // using state to cause a re-render in multivalue datapoints
  const [editorRef, setEditorRef] = useState<ReactAce | null>(null);

  const defaultCompleters = useDefaultCompleters(fields);

  const commands = useCommands(fields, defaultCompleters, currentFormulaId);

  useEffect(() => {
    const formulaPythonMode = new FormulaPythonMode();
    editorRef?.editor
      .getSession()
      .setMode(
        formulaPythonMode as unknown as ReturnType<
          IAceEditor['session']['getMode']
        >
      );
    langTools.setCompleters(defaultCompleters);

    return () => {
      langTools.setCompleters([
        langTools.snippetCompleter,
        langTools.textCompleter,
        langTools.keyWordCompleter,
      ]);
    };
  }, [editorRef, defaultCompleters]);

  const aceTheme =
    theme.palette.mode === 'light' ? 'tomorrow' : 'tomorrow_night';

  return (
    <Stack sx={formulaEditorStyles}>
      <GlobalStyles styles={getGlobalStylesOverrides} />
      {editorRef && (
        <FormulaEditorToolbar
          editor={editorRef}
          renderButtons={renderButtons}
        />
      )}
      <Stack
        pt={2}
        pl={config?.showGutters ? 0 : 2}
        pr={0}
        sx={{
          backgroundColor: t => t.palette.background.default,
          [`& .ace-${aceTheme.replace('_', '-')}`]: {
            color: t => t.palette.text.secondary,
            backgroundColor: 'transparent',
          },
        }}
        position="relative"
      >
        {/* Rendering this instead of the placeholder due to the limited styling
        possibilities */}
        {!value && placeholder && (
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{
              position: 'absolute',
              top: ({ typography }) => typography.body2.fontSize,
              zIndex: 1,
              left: 32,
              pointerEvents: 'none',
            }}
          >
            {placeholder}
          </Typography>
        )}
        <AceEditor
          commands={commands}
          placeholder=""
          showGutter={config?.showGutters ?? SHOW_GUTTERS}
          showPrintMargin={false}
          ref={ref => setEditorRef(ref)}
          maxLines={config?.maxVisibleLines ?? MAX_VISIBLE_LINES}
          minLines={config?.minVisibleLines ?? MIN_VISIBLE_LINES}
          width="100%"
          height={`${(editorRef?.editor.session.getLength() ?? 1) * LINE_HEIGHT}`}
          theme={aceTheme}
          mode="python"
          value={value ?? ''}
          onChange={newValue => onChange(newValue)}
          onFocus={onFocus}
          onBlur={onBlur}
          highlightActiveLine={false}
          editorProps={{
            $blockScrolling: Infinity,
          }}
          setOptions={{
            useWorker: true,
            hScrollBarAlwaysVisible: false,
            enableLiveAutocompletion: false,
            enableBasicAutocompletion: true,
          }}
        />
      </Stack>
    </Stack>
  );
};

export { FormulaEditor };
