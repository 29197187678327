import { FunctionRuntime } from '@rossum/api-client/hooks';
import { Alert, AlertTitle, Box, Stack } from '@rossum/ui/material';
import { format } from 'date-fns';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { docsLinks } from '../../../../../constants/values';
import { dateFnsLocales } from '../../../../../i18n/dateLocales';
import { boldText, link } from '../../../../../lib/formaterValues';
import {
  runtimesConfig,
  runtimeTypesConfig,
} from '../../../../Extension/config';

type Props = {
  runtimeDeprecationDate: Date;
  selectedRuntime: FunctionRuntime;
};

const DeprecatedRuntimeAlert = ({
  runtimeDeprecationDate,
  selectedRuntime,
}: Props) => {
  const intl = useIntl();
  const locale = get(dateFnsLocales, intl.locale);

  return (
    <Alert severity="warning" variant="filled" sx={{ fontWeight: 'normal' }}>
      <AlertTitle>
        {intl.formatMessage({
          id: 'containers.settings.extensions.function.runtime.deprecatedWarning.title',
        })}
      </AlertTitle>

      <Stack spacing={1}>
        <Box>
          {intl.formatMessage(
            {
              id: 'containers.settings.extensions.function.runtime.deprecatedWarning.message',
            },
            {
              deprecatedVersion: selectedRuntime,
              deprecatedDate: format(runtimeDeprecationDate, 'PP', {
                locale,
              }),
              bold: boldText,
            }
          )}
        </Box>
        <Box>
          {intl.formatMessage(
            {
              id: 'containers.settings.extensions.function.runtime.deprecatedWarning.recommended',
            },
            {
              recommendedVersion:
                runtimeTypesConfig[runtimesConfig[selectedRuntime].type]
                  .default,
              link: link(docsLinks.runtimeDeprecations),
            }
          )}
        </Box>
      </Stack>
    </Alert>
  );
};

export default DeprecatedRuntimeAlert;
