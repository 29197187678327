import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatapoint } from '../../../redux/modules/datapoints/actions';
import {
  currentDatapointSelector,
  datapointPathSelector,
} from '../../../redux/modules/datapoints/selector';
import { State } from '../../../types/state';
import {
  useCanvasSelectionActions,
  useDocumentStore,
} from '../document-store/DocumentStore';
import { useDocumentContext } from '../DocumentContext';

const useDeselectDatapoint = () => {
  const dispatch = useDispatch();
  const viewportRef = useDocumentStore(state => state.viewportRef);

  const currentDatapoint = useSelector(currentDatapointSelector);
  const datapointPath = useSelector(datapointPathSelector);
  const searchActive = useSelector((state: State) => state.search.shouldShow);

  // TODO: SVG-mission: is this flag even still valid?
  // not sure but since we have all selects from MUI, it should not be needed
  // leaving it here right now just in case
  const selectMenuIsOpen = useSelector(
    (state: State) => state.ui.selectMenuIsOpen
  );
  const { canvasActionInProgress } = useDocumentContext();
  const { clearSelectedBboxes } = useCanvasSelectionActions();

  const handleDeselection = useCallback(() => {
    if (!canvasActionInProgress) {
      if (currentDatapoint) {
        const datapointPathToSelect = currentDatapoint.meta.sidebarDatapoint
          ? []
          : datapointPath.slice(0, 2);

        dispatch(selectDatapoint(datapointPathToSelect, { noTail: true }));
      }
      clearSelectedBboxes();
    }
  }, [
    canvasActionInProgress,
    clearSelectedBboxes,
    currentDatapoint,
    datapointPath,
    dispatch,
  ]);

  const handleDeselectEscPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        if (!selectMenuIsOpen && !searchActive) {
          handleDeselection();
        }
      }
    },
    [handleDeselection, searchActive, selectMenuIsOpen]
  );

  const handleDeselectClick = useCallback(
    (e: MouseEvent) => {
      const shouldDeselect =
        e.target instanceof Element && e.target === viewportRef.current;

      if (!selectMenuIsOpen && shouldDeselect) {
        handleDeselection();
      }
    },
    [handleDeselection, selectMenuIsOpen, viewportRef]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleDeselectEscPress);
    document.addEventListener('click', handleDeselectClick);

    return () => {
      document.removeEventListener('keydown', handleDeselectEscPress);
      document.removeEventListener('click', handleDeselectClick);
    };
  }, [handleDeselectClick, handleDeselectEscPress]);
};

export { useDeselectDatapoint };
