import { IconCheck, IconCopy } from '@rossum/ui/icons/tabler';
import {
  Button,
  ButtonProps,
  Fade,
  Stack,
  SvgIcon,
  SvgIconProps,
  Tooltip,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useCopyToClipboard } from './useCopyToClipboard';

type CopyToClipboardButtonProps = {
  content: string;
  iconColor?: SvgIconProps['color'];
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
};

const CopyToClipboardButton = ({
  content,
  iconColor,
  children,
  buttonProps,
}: CopyToClipboardButtonProps) => {
  const intl = useIntl();
  const { copied, copyToClipboard } = useCopyToClipboard(content);

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'components.clipboardButton.copy',
      })}
      placement="top"
    >
      <Button
        sx={{
          minWidth: 0,
          p: children ? 0.5 : 1,
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          copyToClipboard();
        }}
        {...(buttonProps ?? {})}
      >
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
          {children}
          <Fade key={copied ? 'copied' : 'copy'} in>
            <SvgIcon
              component={copied ? IconCheck : IconCopy}
              color={iconColor}
              fontSize="small"
              sx={{ fill: 'none' }}
            />
          </Fade>
        </Stack>
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
