import { Box, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DatapointAutomationBlocker } from '../../../../../redux/modules/annotation/types';
import { schemaMapSelector } from '../../../../../redux/modules/schema/schemaMapSelector';
import { BlockersIcon } from './BlockersIcon';
import { createContentForDatapointBlocker } from './helpers';

type DatapointBlockerProps = {
  blocker: DatapointAutomationBlocker;
  handleSelectDatapoint: (id: number) => void;
};

export const DatapointBlocker = ({
  handleSelectDatapoint,
  blocker,
}: DatapointBlockerProps) => {
  const intl = useIntl();
  const schemaMap = useSelector(schemaMapSelector);
  // header field should always have one sample, for multivalues we select the first one
  const firstSample = blocker.samples[0];

  return (
    <Stack direction="row" spacing={1}>
      <BlockersIcon />

      <Typography variant="body2">
        <Typography fontSize="inherit" color="text.secondary" component="span">
          <Box
            component="span"
            sx={{
              '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
            }}
            onClick={() =>
              firstSample?.datapointId
                ? handleSelectDatapoint(firstSample.datapointId)
                : {}
            }
          >
            {schemaMap.get(blocker.schemaId)?.label ?? blocker.schemaId}
          </Box>
          :{' '}
        </Typography>
        <Typography fontSize="inherit" component="span">
          {createContentForDatapointBlocker(blocker, firstSample, intl)}
        </Typography>
      </Typography>
    </Stack>
  );
};
