import { Logo } from '@rossum/ui';
import { Stack, styled, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';

const Emphasized = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const shouldRenderResolver = (state: State) =>
  (organizationSelector(state).uiSettings?.branding?.poweredBy ?? true) &&
  !!organizationSelector(state).id;

const PoweredBy = () => {
  const shouldRender = useSelector(shouldRenderResolver);
  const intl = useIntl();

  return shouldRender ? (
    <Stack direction="row" alignItems="center" sx={{ mr: 1 }} gap={1}>
      <Logo size={22} />
      <Typography fontSize={13} color="text.secondary">
        {intl.formatMessage(
          {
            id: 'components.poweredBy.poweredByRossumHighlighted',
          },
          {
            white: (msg: ReactNode) => <Emphasized>{msg}</Emphasized>,
          }
        )}
      </Typography>
    </Stack>
  ) : null;
};

export default PoweredBy;
