import { Queue } from '@rossum/api-client/queues';
import { snakeToCamel } from '@rossum/api-client/utils';
import { Chip, Stack, Tab, Tabs } from '@rossum/ui/material';
import { GridFilterItem, GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getUpdatedExistingFilter } from '../../document-list-base/mql/transformers';
import { HiddenTabs } from './HiddenTabs';
import { useStatusTabCounts } from './hooks/useStatusTabCounts';
import { useStatusTabs } from './hooks/useStatusTabs';
import { useTabVisibility } from './hooks/useTabVisibility';
import { defaultStatusTabs, StatusTabKeys, statusTabsColumnMap } from './tabs';
import {
  getMatchingStatusFilter,
  getTabAnimation,
  isStatusTabColumn,
} from './utils';

type Props = {
  existingFilter: GridFilterModel | null;
  handleFilterModelChange: (fm: GridFilterItem[]) => void;
  activeQueue: Queue;
};
export const STATUS_TABS_HEIGHT = 45;

export const StatusTabs = ({
  existingFilter,
  handleFilterModelChange,
  activeQueue,
}: Props) => {
  const intl = useIntl();
  const [activeTabKey, setActiveTabKey] = useState<StatusTabKeys>(
    defaultStatusTabs.all_statuses.key
  );

  const statusTabs = useStatusTabs(activeQueue);

  const { data: statusCounts } = useStatusTabCounts({
    queueId: activeQueue.id,
    statusTabs,
  });

  const matchingStatusFilter = getMatchingStatusFilter({
    existingFilter,
    statusTabs,
  });

  const isStatusTabKey = (key: string): key is StatusTabKeys =>
    statusTabs.some(tab => tab.key === key);

  // move active tab if status tab filter settings match the url
  if (matchingStatusFilter && matchingStatusFilter.key !== activeTabKey)
    setActiveTabKey(matchingStatusFilter.key);

  const excludeNonTabColumns = (
    filterItems: GridFilterItem[],
    targetTabKey: StatusTabKeys
  ) => {
    return filterItems.flatMap(item => {
      const tabKey = item.field;
      if (!isStatusTabColumn(tabKey)) return [item];

      const matchingStatusTabs = statusTabsColumnMap[tabKey];

      if (matchingStatusTabs) {
        return matchingStatusTabs.some(statusTab => statusTab === targetTabKey)
          ? [item]
          : [];
      }
      return [item];
    });
  };

  const handleChange = (newValue: unknown) => {
    if (typeof newValue !== 'string' || !isStatusTabKey(newValue)) return;

    const targetTab = statusTabs.find(tab => tab.key === newValue);

    if (!targetTab) return;

    setActiveTabKey(newValue);
    const existingFilterItems = existingFilter?.items ?? [];

    const newFilter =
      newValue !== 'all_statuses'
        ? getUpdatedExistingFilter({
            existingFilter,
            incomingFilters: [targetTab.filter],
            isReplaceAction: existingFilterItems.some(
              item => item.field === 'status'
            ),
          })
        : existingFilterItems.filter(item => item.field !== 'status');

    const updatedFilter = excludeNonTabColumns(newFilter, newValue);

    const sortedFilter = updatedFilter
      .map(f => f)
      .sort(a => (a.field === 'status' ? -1 : 1));

    handleFilterModelChange(sortedFilter);
  };

  const {
    setContainerSizeRef,
    setTabSizes,
    visibleTabs,
    hiddenTabs,
    setMoreButtonSize,
    readyToDisplay,
  } = useTabVisibility({
    activeTabKey,
    allStatusTabs: statusTabs,
  });
  // e.g. if status is filtered by confirmed, and confirmed tab doesn't exist, then this useEffect will remove status filter
  useEffect(() => {
    if (existingFilter) {
      const validStatusFilterValues = statusTabs.flatMap(
        tab => tab.filter.value
      );

      const isStatusFilterInvalid = existingFilter.items.some(item => {
        if (item.field !== 'status') return false;

        if (!Array.isArray(item.value)) return true;

        return item.value.some(
          statusValue => !validStatusFilterValues.includes(statusValue)
        );
      });

      if (activeTabKey !== 'all_statuses' && isStatusFilterInvalid) {
        const filteredModel = existingFilter.items.filter(
          item => item.field !== 'status'
        );

        handleFilterModelChange(filteredModel);
      }
    }
  }, [
    existingFilter,
    statusTabs,
    handleFilterModelChange,
    activeQueue,
    activeTabKey,
  ]);

  return (
    <Stack
      direction="row"
      ref={setContainerSizeRef}
      flexGrow={1}
      sx={{
        visibility: readyToDisplay ? 'visible' : 'hidden',
      }}
    >
      <Tabs
        value={activeTabKey}
        onChange={(_, newValue) => handleChange(newValue)}
        sx={{
          height: STATUS_TABS_HEIGHT,
          minHeight: STATUS_TABS_HEIGHT,
          overflow: 'hidden',
        }}
      >
        {visibleTabs.map(({ key }, index) => {
          const { validCount, invalidCount } = statusCounts?.[key] ?? {};
          const isTabActive = key === activeTabKey;
          return (
            <Tab
              ref={el => setTabSizes(el, key)}
              label={intl.formatMessage({
                id: `containers.allDocuments.statusTabs.${snakeToCamel(key)}`,
              })}
              data-id={snakeToCamel(key)}
              key={key}
              value={key}
              sx={{
                '&.Mui-selected': { color: 'text.primary' },
                flexShrink: 0,
                height: STATUS_TABS_HEIGHT,
                minHeight: STATUS_TABS_HEIGHT,
                ...getTabAnimation({
                  startAnimation: readyToDisplay,
                  delayIndex: index,
                }),
              }}
              iconPosition="end"
              icon={
                key !== 'all_statuses' && statusCounts ? (
                  <Stack direction="row" spacing={1}>
                    {validCount ? (
                      <Chip
                        label={validCount}
                        color={isTabActive ? 'info' : 'default'}
                      />
                    ) : null}
                    {invalidCount ? (
                      <Chip label={invalidCount} color="error" />
                    ) : null}
                  </Stack>
                ) : undefined
              }
            />
          );
        })}
      </Tabs>
      {hiddenTabs.length ? (
        <HiddenTabs
          setMoreButtonSize={setMoreButtonSize}
          hiddenTabs={hiddenTabs}
          onClick={tab => handleChange(tab.key)}
          sx={getTabAnimation({
            startAnimation: readyToDisplay,
            delayIndex: visibleTabs.length,
          })}
        />
      ) : null}
    </Stack>
  );
};
