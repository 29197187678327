import { Queue as QueueType } from '@rossum/api-client/queues';
import { Check, DoDisturb } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { unique } from 'remeda';
import Collapse from '../../../components/UI/Collapse';
import { boldText, white } from '../../../lib/formaterValues';
import { OpenModal } from '../../../redux/modules/modal/actions';
import { Url } from '../../../types/basic';
import { User } from '../../../types/user';
import styles from '../styles.module.sass';
import Queue from './Queue';

type Props = {
  activeQueuesCount: number;
  isFirst: boolean;
  name: string;
  openModal: OpenModal;
  queues: Array<QueueType>;
  updateUserDetail: (_user: Partial<User>) => void;
  userQueues: Array<Url>;
  forceExpand: boolean;
};

const Workspace = ({
  activeQueuesCount,
  isFirst,
  name,
  openModal,
  queues,
  updateUserDetail,
  userQueues,
  forceExpand,
}: Props) => {
  const isQueueActive = (queueUrl: string) => userQueues.includes(queueUrl);

  const toggleQueueActive = (queueUrl: string) =>
    isQueueActive(queueUrl)
      ? updateUserDetail({ queues: userQueues.filter(url => url !== queueUrl) })
      : updateUserDetail({ queues: [...userQueues, queueUrl] });

  const onAddAll = () =>
    openModal({
      textId: 'addAllQueues',
      onConfirm: () =>
        updateUserDetail({
          queues: unique([...userQueues, ...queues.map(({ url }) => url)]),
        }),
      values: {
        count: forceExpand ? queues.length : 0,
        workspaceName: name,
        white,
        bold: boldText,
      },
    });

  const onDisableAll = () =>
    openModal({
      textId: 'disableAllQueues',
      onConfirm: () =>
        updateUserDetail({
          queues: userQueues.filter(
            _url => !queues.find(({ url }) => url === _url)
          ),
        }),
      values: {
        count: forceExpand ? queues.length : 0,
        workspaceName: name,
        white,
        bold: boldText,
      },
    });

  return (
    <Collapse
      activeChildrenLength={activeQueuesCount}
      childrenLength={queues.length}
      disabled={!activeQueuesCount}
      name={name}
      defaultExpand={isFirst}
      forceExpand={forceExpand}
      controlElements={
        <>
          <Button
            size="small"
            color="secondary"
            startIcon={<Check />}
            onClick={onAddAll}
          >
            <FormattedMessage
              id="containers.users.components.workspace.enableAll"
              values={{
                count: forceExpand ? queues.length : 0,
              }}
            />
          </Button>
          <Button
            size="small"
            color="secondary"
            startIcon={<DoDisturb />}
            onClick={onDisableAll}
          >
            <FormattedMessage
              id="containers.users.components.workspace.disableAll"
              values={{
                count: forceExpand ? queues.length : 0,
              }}
            />
          </Button>
        </>
      }
    >
      <div className={styles.Queues}>
        {queues.map(queue => (
          <Queue
            key={queue.id}
            queue={queue}
            active={isQueueActive(queue.url)}
            onToggle={() => toggleQueueActive(queue.url)}
          />
        ))}
      </div>
    </Collapse>
  );
};

export default Workspace;
