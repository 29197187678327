import { Tooltip } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  children: ReactNode;
  tooMany: boolean;
  tooFew: boolean;
  isSimpleMultivalue: boolean;
};

const getText = (tooFew: boolean) =>
  tooFew
    ? 'components.documentValidation.multivalue.occurences.min'
    : 'components.documentValidation.multivalue.occurences.max';

const OccurenceTooltip = ({
  children,
  tooFew,
  tooMany,
  isSimpleMultivalue,
}: Props) => {
  const intl = useIntl();
  return (
    <Tooltip
      placement="right"
      title={
        (!tooFew && !tooMany) || !isSimpleMultivalue
          ? ''
          : intl.formatMessage({ id: getText(tooFew) })
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default OccurenceTooltip;
