import { Menu } from '@rossum/ui/material';
import { ReactNode, useState } from 'react';
import { AnnotatorLimitedRestrictor } from '../../../components/Restrictors';
import { useMenuPosition } from '../hooks/useMenuPosition';
import { DownloadFormat, DownloadFormatsSelect } from './DownloadFormatsSelect';

type Props = {
  downloadAll: (format: { format: DownloadFormat }) => void;
  asyncDownload?: () => void;
  selectedItemsCount: number;
  children: (
    setAnchor: (e: React.MouseEvent) => void,
    isVisible: boolean
  ) => ReactNode;
  // TODO: nestedMenu is temporary solution whole dropdown will be replaced
  // with dialog here: https://rossumai.atlassian.net/browse/AC-3436
  nestedMenu?: boolean;
};

const DownloadAllOption = ({
  downloadAll,
  asyncDownload,
  selectedItemsCount,
  children,
  nestedMenu,
}: Props) => {
  const [anchor, setAnchor] = useState<Element | null>(null);
  const { anchorOrigin, transformOrigin } = useMenuPosition(!!nestedMenu);

  return (
    <AnnotatorLimitedRestrictor>
      {children(e => setAnchor(e.currentTarget), !!anchor)}
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <DownloadFormatsSelect
          downloadAll={downloadAll}
          asyncDownload={asyncDownload}
          selectedItemsCount={selectedItemsCount}
        />
      </Menu>
    </AnnotatorLimitedRestrictor>
  );
};

export { DownloadAllOption };
