import { Alert, Button, Stack } from '@rossum/ui/material';
import {
  DateRange,
  DateRangeCalendar,
  dateRangeCalendarClasses,
  dateRangePickerDayClasses,
  LocalizationProvider,
} from '@rossum/ui/x-date-pickers-pro';
import { AdapterDateFns } from '@rossum/ui/x-date-pickers-pro/AdapterDateFns';
import { isValid } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { dateFnsLocales } from '../../../../../i18n/dateLocales';
import {
  getValidatedDateContext,
  isRangedDateOperator,
  processDatesBeforeSubmit,
  SelectedDates,
  shouldBeEndOfDay,
} from '../../../../document-list-base/mql/dateUtils';
import { FilterOperator } from '../../../../document-list-base/mql/operators';
import { FilterComponentProps } from '../../../../document-list-base/mql/types';
import { FilterActionsWrapper } from '../FilterActionsWrapper';
import { useOperators } from '../Operators/useOperators';
import { resolveValueBasedOnBooleanOperator } from '../Operators/utils';
import { DATE_RANGE_CALENDAR_WIDTH, DateTimeInput } from './DateTimeInput';
import {
  getCustomDateRangeTime,
  getCustomFixedDateTime,
  getFixedDateShortcuts,
  getIsActive,
  rangedDateShortcuts,
} from './shortcuts';
import {
  applyTextButtonStyle,
  SHORTCUTS_MIN_WIDTH,
  shortcutStyles,
} from './styles';

const { formatTokenMap } = new AdapterDateFns();

export const DateTimePicker = ({
  applyFilter,
  filterItem,
  onClose,
  onBackClick,
}: FilterComponentProps) => {
  const intl = useIntl();
  const [hasWarning, setHasWarning] = useState<boolean>(false);

  const locale = dateFnsLocales[intl.locale] ?? dateFnsLocales.en;

  const { operatorValue, existingDates } = getValidatedDateContext(filterItem);

  const [selectedDates, setSelectedDates] =
    useState<SelectedDates>(existingDates);

  const firstDateIsNull = selectedDates[0] === null;
  const secondDateIsNull = selectedDates[1] === null;
  const areDatesNull = firstDateIsNull && secondDateIsNull;

  if (!operatorValue && !hasWarning && areDatesNull) {
    setHasWarning(true);
  }

  useEffect(() => {
    if (!areDatesNull) setHasWarning(false);
  }, [areDatesNull]);

  const handleOperatorChange = useCallback(
    (operator: FilterOperator) => {
      const firstDate = selectedDates[0];

      if (!firstDate) return;

      if (!isRangedDateOperator(operator.value)) {
        const fixedDate = getCustomFixedDateTime(
          firstDate,
          shouldBeEndOfDay(operator.value)
        );
        // update selectedDate to endOf or startOf the day based on the operator
        if (fixedDate !== firstDate) setSelectedDates([fixedDate, fixedDate]);
        return;
      }

      setSelectedDates(getCustomDateRangeTime([firstDate, firstDate], true));
    },
    [selectedDates]
  );

  const {
    node: Operators,
    operator,
    emptyOperatorIsUsed,
  } = useOperators({
    filterItem,
    initialState: {
      value: operatorValue ?? 'is',
      type: 'date',
      component: 'dateTimePicker',
    },
    onChange: handleOperatorChange,
  });

  const isRangedOperator = isRangedDateOperator(operator.value);

  const areRangedValuesInvalid =
    (isRangedOperator && secondDateIsNull) ||
    (!isRangedOperator && firstDateIsNull);

  const handleDatePickerChange = (
    dateRange: DateRange<Date>,
    selectionState?: 'partial' | 'finish' | 'shallow'
  ) => {
    const [from, to] = dateRange;

    if (!isRangedOperator) {
      // selectionState is 'finish' when we have both dates selected in dateRange
      // to define targetDate we determine which date equals to our selectedDate
      // when !isRangedOperator selectedDates[0] === selectedDates[1]
      const targetDate =
        selectionState === 'finish'
          ? selectedDates[0] === from
            ? to
            : from
          : from ?? to;

      // targetDate should never be null here
      const normalisedDate = targetDate
        ? getCustomFixedDateTime(targetDate, shouldBeEndOfDay(operator.value))
        : null;

      return setSelectedDates([normalisedDate, normalisedDate]);
    }

    if (!from || !to) return setSelectedDates(dateRange);

    return setSelectedDates(
      getCustomDateRangeTime([from, to], isRangedOperator)
    );
  };

  return (
    <FilterActionsWrapper
      onSubmit={() => {
        if (emptyOperatorIsUsed) {
          applyFilter({
            operator,
            value: resolveValueBasedOnBooleanOperator(operator),
          });
        }

        const processedFilterContext = processDatesBeforeSubmit({
          selectedDates,
          operator,
          column: filterItem.column,
        });

        if (processedFilterContext) applyFilter(processedFilterContext);
      }}
      isSubmitDisabled={!emptyOperatorIsUsed && areRangedValuesInvalid}
      onCancel={onClose}
      onBackClick={onBackClick}
      columnField={filterItem.column.field}
      hasFixedWidth={emptyOperatorIsUsed}
    >
      <Stack>
        <Operators />
        {hasWarning && (
          <Alert
            color="warning"
            closeText="Close"
            sx={{ background: 'transparent' }}
          >
            {intl.formatMessage({
              id: 'containers.filtering.invalidDateFilter',
            })}
          </Alert>
        )}
        {!emptyOperatorIsUsed ? (
          <Stack direction="row">
            <Stack
              my={1}
              pr={4}
              minWidth={SHORTCUTS_MIN_WIDTH}
              sx={{
                borderRight: theme => `1px solid ${theme.palette.other.muted}`,
              }}
            >
              {getFixedDateShortcuts(operator.value).map(shortcut => (
                <Button
                  key={shortcut.labelKey}
                  onClick={() => {
                    setSelectedDates(shortcut.getValue());
                  }}
                  variant="text"
                  sx={{
                    ...shortcutStyles,
                    ...applyTextButtonStyle(
                      getIsActive(selectedDates, shortcut.getValue())
                    ),
                  }}
                >
                  {intl.formatMessage({
                    id: `containers.filtering.shortcuts.${shortcut.labelKey}`,
                  })}
                </Button>
              ))}
              {isRangedOperator
                ? rangedDateShortcuts.map(shortcut => (
                    <Button
                      key={shortcut.labelKey}
                      onClick={() => {
                        setSelectedDates(shortcut.getValue());
                      }}
                      variant="text"
                      sx={{
                        ...shortcutStyles,
                        ...applyTextButtonStyle(
                          getIsActive(selectedDates, shortcut.getValue())
                        ),
                      }}
                    >
                      {intl.formatMessage({
                        id: `containers.filtering.shortcuts.${shortcut.labelKey}`,
                      })}
                    </Button>
                  ))
                : null}
            </Stack>
            <Stack>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale}
              >
                <DateRangeCalendar
                  value={selectedDates}
                  onChange={handleDatePickerChange}
                  sx={{
                    [`& .${dateRangePickerDayClasses.rangeIntervalPreview}`]:
                      isRangedOperator
                        ? {}
                        : {
                            border: 'none',
                            // to account for border-width
                            margin: '2px',
                          },
                    [`& .${dateRangeCalendarClasses.monthContainer}:not(:last-of-type)`]:
                      {
                        borderRight: 'none',
                      },
                  }}
                />

                <Stack direction="row" maxWidth={DATE_RANGE_CALENDAR_WIDTH}>
                  <DateTimeInput
                    value={selectedDates[0]}
                    isActive={!selectedDates[0]}
                    onChange={value => {
                      if (value && isValid(value)) {
                        setSelectedDates(prev => [value, prev[1]]);
                      }
                    }}
                    formatTokenMap={formatTokenMap}
                  />
                  {isRangedOperator ? (
                    <DateTimeInput
                      value={selectedDates[1]}
                      isActive={
                        !!(
                          isRangedOperator &&
                          selectedDates[0] &&
                          !selectedDates[1]
                        )
                      }
                      onChange={value => {
                        if (value && isValid(value)) {
                          setSelectedDates(prev => [
                            prev[0],
                            isRangedOperator ? value : prev[0],
                          ]);
                        }
                      }}
                      formatTokenMap={formatTokenMap}
                    />
                  ) : null}
                </Stack>
              </LocalizationProvider>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </FilterActionsWrapper>
  );
};
