import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggableDatapointValue } from '../../../../components/Datapoint/helpers';
import { DEFAULT_ROW_TYPE } from '../../../../constants/values';
import { pagesSelector } from '../../../../redux/modules/annotation/selectors';
import {
  bboxCreatedAction,
  createDatapointWithPosition,
  recountDatapointPosition,
  updatePosition,
} from '../../../../redux/modules/datapoints/actions';
import {
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
  isParentTableDatapointSelectedSelector,
} from '../../../../redux/modules/datapoints/selector';
import { createGrid } from '../../../../redux/modules/grid/actions';
import { getGridSchema } from '../../../../redux/modules/magicGrid/selector';
import {
  Rectangle2D,
  rectangleToCoordinates,
} from '../../document-canvas/utils/geometry';

export const useCreateBoundingBox = () => {
  const dispatch = useDispatch();

  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );
  const gridSchema = useSelector(getGridSchema);

  const magicGridDefaultRowType = currentMultivalueDatapoint
    ? gridSchema?.defaultRowType ?? DEFAULT_ROW_TYPE
    : null;

  const isParentTableDatapointSelected = useSelector(
    isParentTableDatapointSelectedSelector
  );

  const currentDatapoint = useSelector(currentDatapointSelector);
  const pages = useSelector(pagesSelector);

  const createBoundingBox = useCallback(
    (pageNumber: number, rect: Rectangle2D) => {
      const page = pages.find(p => p.number === pageNumber);

      if (!page) {
        throw new Error(`Page ${page} not found.`);
      }

      if (isParentTableDatapointSelected && currentMultivalueDatapoint) {
        return dispatch(
          createGrid({
            datapointIndex: currentMultivalueDatapoint.meta.index,
            page: pageNumber,
            rectangle: [rect.x, rect.y, rect.width, rect.height],
            rowType: magicGridDefaultRowType ?? null,
          })
        );
      }

      if (currentDatapoint) {
        // if on simple multivalue parent, create a child DP
        if (
          currentMultivalueDatapoint &&
          currentDatapoint.meta.index === currentMultivalueDatapoint.meta.index
        ) {
          dispatch(bboxCreatedAction(currentDatapoint.schemaId));
          dispatch(
            createDatapointWithPosition(currentMultivalueDatapoint.meta.index, {
              page,
              position: rectangleToCoordinates(rect),
            })
          );
        } else {
          dispatch(bboxCreatedAction(currentDatapoint.schemaId));
          dispatch(
            updatePosition(
              {
                index: currentDatapoint.meta.index,
                page,
              },
              {
                content: {
                  position: rectangleToCoordinates(rect),
                },
              }
            )
          );
          return dispatch(
            recountDatapointPosition(currentDatapoint.meta.index, {
              oldValue: getLoggableDatapointValue(currentDatapoint),
              reason: 'create-bbox',
            })
          );
        }
      }

      return undefined;
    },
    [
      currentDatapoint,
      currentMultivalueDatapoint,
      dispatch,
      isParentTableDatapointSelected,
      magicGridDefaultRowType,
      pages,
    ]
  );

  return { createBoundingBox };
};
