import { isSchemaItem, Schema } from '@rossum/api-client/schemas';
import { Workspace } from '@rossum/api-client/workspaces';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import { addItemToSchema } from '../../../../queue-settings/fields/form-model/transformations/toSchema';
import {
  getChildListPath,
  getSchemaObjectPaths,
} from '../../../../queue-settings/fields/helpers';
import { flattenSchemaAndAddQueues } from '../../../hooks/useMemoFlatSchemasWithQueues';
import { Queue } from '../../../types/queue';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { aggregationRow } from '../../constants';
import { getPartId } from '../../create-field/helpers';
import { Dialog } from '../../ui/Dialog';
import { SelectSchemaLocation } from '../../ui/SelectSchemaLocation';
import { isDatapointInTable } from '../columns/editabilityConditions';
import { UpdatedSchemasMap } from '../getProcessRowUpdate';
import { transformSchemasToRows } from '../rows/rows';
import { GridRowModel } from '../rows/rowTypes';

const getSchemaOptions = (
  schema: FlatSchemaWithQueues,
  workspace: Workspace,
  selectedRow: GridRowModel
) =>
  schema.content
    ? schema.content.flatMap(content => {
        // To achieve the desired behaviour, on a schema option level the structure is flat
        // Only "sections" and "multivalues" can be selected. Only simple field can be duplicated
        const multivalues = content.children
          .filter(
            child =>
              child.category === 'multivalue' &&
              child.children.category === 'tuple'
          )
          .map(multivalue => ({
            id: `workspaceId:${workspace.id}.schemaId:${schema.id}.sectionId:${content.id}.multivalueId:${multivalue.id}`,
            label: `${multivalue.label} (line item)`,
          }));

        // If the field to be duplicated is child of a tuple, BE will not allow us to duplicate such field as section children
        const excludeSection = isDatapointInTable(selectedRow.meta);

        const section = excludeSection
          ? []
          : [
              {
                id: `workspaceId:${workspace.id}.schemaId:${schema.id}.sectionId:${content.id}`,
                label: `${content.label} (${content.category})`,
              },
            ];

        return [...section, ...multivalues];
      })
    : [];

const getWorkspaceOptions = (
  workspace: Workspace,
  queues: Queue[],
  flatSchemasWithQueues: FlatSchemaWithQueues[],
  schemasAlreadyContainingField: FlatSchemaWithQueues[],
  selectedRow: GridRowModel
) =>
  workspace.queues
    .flatMap(queueUrl => {
      const queue = queues.find(q => q.url === queueUrl);

      if (!queue) return [];

      const schema = flatSchemasWithQueues.find(s => s.url === queue.schema);

      // Duplicate works only when field with the given id is not already in the schema,
      // thus we need to filter out those schemas already containing field id which would be duplicated
      const schemaAlreadyContainsField = schemasAlreadyContainingField.some(
        schemaWithItem => schemaWithItem.url === schema?.url
      );

      if (
        !schema ||
        !schema.content ||
        schema.content.length === 0 ||
        schemaAlreadyContainsField
      )
        return [];

      return [
        {
          id: `workspaceId:${workspace.id}.queueId:${queue.id}.schemaId:${schema.id}`,
          label: queue.name,
          options: getSchemaOptions(schema, workspace, selectedRow),
        },
      ];
    })
    // Filter out schemas with no options (e.g. there is only one section/line item in a schema and it already contains the field with the given id)
    .filter(s => s.options.length !== 0);

const createWorkspaceOptions = (
  selectedRow: GridRowModel,
  workspaces: Workspace[],
  queues: Queue[],
  flatSchemasWithQueues: FlatSchemaWithQueues[]
) => {
  const nonEmptyWorkspaces = workspaces.filter(
    workspace => workspace.queues.length > 0
  );

  const schemasAlreadyContainingField = flatSchemasWithQueues.filter(schema =>
    schema.flattenedContent.find(item => item.id === selectedRow.id)
  );

  return (
    nonEmptyWorkspaces
      .map(workspace => ({
        id: `workspaceId:${workspace.id}`,
        label: workspace.name,
        options: getWorkspaceOptions(
          workspace,
          queues,
          flatSchemasWithQueues,
          schemasAlreadyContainingField,
          selectedRow
        ),
      }))
      // Filter out workspaces with no options (e.g. every child of a workspace already contains the field with the given id)
      .filter(ws => ws.options.length !== 0)
  );
};

type UseCopySettingsToQueues = {
  workspaces: Workspace[];
  queues: Queue[];
  schemas: Schema[];
  flatSchemasWithQueues: FlatSchemaWithQueues[];
  setUpdatedSchemas: React.Dispatch<React.SetStateAction<UpdatedSchemasMap>>;
  setRows: React.Dispatch<React.SetStateAction<GridRowModel[]>>;
  updatedSchemas: UpdatedSchemasMap;
};

type DuplicateDialogParams = {
  selectedRows: GridRowModel[];
};

export type DuplicateDialogParamsSetter = (
  params: DuplicateDialogParams
) => void;

export const useDuplicateFieldToQueues = ({
  setUpdatedSchemas,
  schemas,
  workspaces,
  queues,
  flatSchemasWithQueues,
  setRows,
  updatedSchemas,
}: UseCopySettingsToQueues) => {
  const [duplicateDialogParams, setDuplicateDialogParams] =
    useState<DuplicateDialogParams | null>(null);
  const open = duplicateDialogParams !== null;
  const intl = useIntl();

  // Location list
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const resetDialog = useCallback(() => {
    setSelectedLocations([]);
    setExpandedItems([]);
    setDuplicateDialogParams(null);
  }, []);

  const selectedRow = useMemo(
    () => duplicateDialogParams?.selectedRows[0],
    [duplicateDialogParams?.selectedRows]
  );

  const workspacesWithQueuesAndSchemas = useMemo(
    () =>
      selectedRow
        ? createWorkspaceOptions(
            selectedRow,
            workspaces,
            queues,
            flatSchemasWithQueues
          )
        : [],
    [selectedRow, workspaces, queues, flatSchemasWithQueues]
  );

  const duplicateFieldToSchemas = useCallback(
    (row: GridRowModel) => {
      if (row.meta.schema_id === aggregationRow) {
        return;
      }

      const alreadyInUpdated = updatedSchemas[row.meta.schema_id];

      const originalSchema = flatSchemasWithQueues.find(
        schema => schema.id === row.meta.schema_id
      );

      const schemaToUpdate = alreadyInUpdated ?? originalSchema;

      if (!schemaToUpdate || !schemaToUpdate.content) {
        return;
      }

      // TODO: Would be nice to make it typesafe but I am ok with returning undefined
      // and checking it with the typeguard afterwards
      // Get schema item which will be duplicated
      const fieldToBeDuplicated = R.pathOr(
        schemaToUpdate.content,
        // @ts-expect-error
        row.meta.path,
        undefined
      );

      if (!fieldToBeDuplicated || !isSchemaItem(fieldToBeDuplicated)) {
        return;
      }

      selectedLocations.forEach(item => {
        const targetSchema = schemas.find(
          schema => String(schema.id) === getPartId(item, 'schemaId')
        );

        if (!targetSchema) {
          return;
        }

        const targetSchemaObjectPaths = getSchemaObjectPaths(targetSchema);

        const targetParentId =
          getPartId(item, 'multivalueId') ?? getPartId(item, 'sectionId');

        const targetParentPath = targetParentId
          ? targetSchemaObjectPaths[targetParentId]
          : null;

        const targetListPath = targetParentPath
          ? getChildListPath(targetParentPath)
          : null;

        const updatedSchema = addItemToSchema(
          targetSchema,
          targetListPath ?? [],
          fieldToBeDuplicated
        );

        const updatedFlattenSchemaWithQueues = flattenSchemaAndAddQueues(
          updatedSchema,
          queues
        );

        const updatedRow = transformSchemasToRows(fieldToBeDuplicated.id, [
          updatedFlattenSchemaWithQueues,
        ])()?.[0];

        if (updatedRow) {
          setRows(prevRows => [...prevRows, updatedRow]);
        }

        setUpdatedSchemas(prevState => ({
          ...prevState,
          [updatedSchema.id]: updatedFlattenSchemaWithQueues,
        }));
      });
    },

    [
      flatSchemasWithQueues,
      queues,
      schemas,
      selectedLocations,
      setRows,
      setUpdatedSchemas,
      updatedSchemas,
    ]
  );

  const duplicateDialog = (
    <Dialog
      onClose={resetDialog}
      open={open}
      title={intl.formatMessage({
        id: 'features.fieldManager.fieldDetail.hooks.useDuplicateFieldToQueues.title',
      })}
      actions={
        open && (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={resetDialog}
              data-cy="fm-duplicate-dialog-cancel-button"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useDuplicateFieldToQueues.button.cancel',
              })}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (selectedRow) {
                  duplicateFieldToSchemas(selectedRow);
                  resetDialog();
                }
                setDuplicateDialogParams(null);
              }}
              data-cy="fm-duplicate-dialog-confirm-button"
              disabled={workspacesWithQueuesAndSchemas?.length === 0}
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useDuplicateFieldToQueues.button.duplicate',
              })}
            </Button>
          </Stack>
        )
      }
    >
      {open && (
        <Stack spacing={2}>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'features.fieldManager.fieldDetail.hooks.useDuplicateFieldToQueues.decription',
            })}
          </Typography>
          <Stack sx={{ maxHeight: 'min(600px, 40vh)', overflow: 'auto' }}>
            {workspacesWithQueuesAndSchemas?.length > 0 ? (
              <SelectSchemaLocation
                options={workspacesWithQueuesAndSchemas}
                mode="multi-single-select"
                onSelect={ids => setSelectedLocations(ids)}
                selectedOptions={selectedLocations}
                expandedOptions={expandedItems}
                setExpandedOptions={setExpandedItems}
              />
            ) : (
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.hooks.useDuplicateFieldToQueues.info',
                })}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </Dialog>
  );

  return {
    duplicateDialog,
    setDuplicateDialogParams,
  };
};
