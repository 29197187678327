import { IconCustomBoltPause } from '@rossum/ui/icons/tabler';
import { SvgIcon } from '@rossum/ui/material';
import { BLOCKERS_COLOR } from './helpers';

// TODO: Better name, placing into UI?
export const BlockersIcon = () => (
  <SvgIcon color={BLOCKERS_COLOR} fontSize="small">
    <IconCustomBoltPause />
  </SvgIcon>
);
