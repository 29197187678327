import { getIDFromUrl } from '@rossum/api-client';
import {
  IconCopy,
  IconDotsVertical,
  IconLayoutGrid,
  IconLayoutList,
  IconMailCog,
  IconSTurnRight,
  IconUserSquareRounded,
} from '@rossum/ui/icons/tabler';
import {
  IconButton,
  IconButtonProps,
  Menu,
  SvgIcon,
} from '@rossum/ui/material';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NonAdminRestrictor } from '../../../components/Restrictors';
import { isEmbedded } from '../../../constants/config';
import { myExtensionsPath } from '../../../containers/Extensions/helpers';
import { rulesEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { Annotation } from '../../../types/annotation';
import { Document } from '../../../types/document';
import { useCopyToClipboard } from '../../../ui/copy-to-clipboard/useCopyToClipboard';
import { ContextActionItem } from '../document-sidebar/sidebar-items/shared/ContextActionItem';

// TODO: These could be useful globally + unify with `getFieldsSettingsPath`
const fieldSettingsUrl = (queueId: number | undefined) =>
  typeof queueId === 'number' ? `/queues/${queueId}/settings/fields` : null;

const ruleSettingsUrl = (queueId: number | undefined) =>
  typeof queueId === 'number' ? `/queues/${queueId}/settings/rules` : null;

const emailSettingsUrl = (queueId: number | undefined) =>
  typeof queueId === 'number' ? `/queues/${queueId}/settings/emails` : null;

const accessSettingsUrl = (queueId: number | undefined) =>
  typeof queueId === 'number' ? `/queues/${queueId}/settings/access` : null;

const extensionSettingsUrl = (queueId: number | undefined) =>
  typeof queueId === 'number'
    ? `${myExtensionsPath()}?events=all&page=1&pageSize=15&view=list&queues=${queueId}`
    : null;

type DocumentContextActionsProps = IconButtonProps & {
  document: Document | undefined;
  annotation: Annotation | undefined;
};

export const DocumentContextActions = ({
  document,
  annotation,
  ...iconButtonProps
}: DocumentContextActionsProps) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(e => {
      setAnchorEl(e.currentTarget);
    }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { copyToClipboard: copyDocumentName } = useCopyToClipboard(
    document?.originalFileName ?? ''
  );

  const documentUrl = window.location.href.split('?')[0] ?? '';

  const { copyToClipboard: copyDocumentUrl } = useCopyToClipboard(documentUrl);

  const handleCopyDocumentName = useCallback(() => {
    copyDocumentName();
    handleCloseMenu();
  }, [copyDocumentName, handleCloseMenu]);

  const handleCopyDocumentUrl = useCallback(() => {
    copyDocumentUrl();
    handleCloseMenu();
  }, [copyDocumentUrl, handleCloseMenu]);

  const rulesEnabled = useSelector(rulesEnabledSelector);

  const queueId = annotation ? getIDFromUrl(annotation.queue) : undefined;

  return (
    <>
      <IconButton
        {...iconButtonProps}
        size="medium"
        color="secondary"
        onClick={handleOpenMenu}
      >
        <SvgIcon fontSize="small">
          <IconDotsVertical />
        </SvgIcon>
      </IconButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <ContextActionItem icon={<IconCopy />} onClick={handleCopyDocumentName}>
          {intl.formatMessage({
            id: 'components.documentContextActions.copyName',
          })}
        </ContextActionItem>
        {!isEmbedded() ? (
          <ContextActionItem
            icon={<IconCopy />}
            onClick={handleCopyDocumentUrl}
          >
            {intl.formatMessage({
              id: 'components.documentContextActions.copyUrl',
            })}
          </ContextActionItem>
        ) : null}
        {!isEmbedded() ? (
          <NonAdminRestrictor>
            <ContextActionItem
              icon={<IconLayoutList />}
              component={Link}
              to={fieldSettingsUrl(queueId)}
            >
              {intl.formatMessage({
                id: 'components.documentContextActions.fieldsSettings',
              })}
            </ContextActionItem>
            {rulesEnabled ? (
              <ContextActionItem
                icon={<IconSTurnRight />}
                component={Link}
                to={ruleSettingsUrl(queueId)}
              >
                {intl.formatMessage({
                  id: 'components.documentContextActions.rulesSettings',
                })}
              </ContextActionItem>
            ) : null}
            <ContextActionItem
              icon={<IconMailCog />}
              component={Link}
              to={emailSettingsUrl(queueId)}
            >
              {intl.formatMessage({
                id: 'components.documentContextActions.emailSettings',
              })}
            </ContextActionItem>
            <ContextActionItem
              icon={<IconUserSquareRounded />}
              component={Link}
              to={accessSettingsUrl(queueId)}
            >
              {intl.formatMessage({
                id: 'components.documentContextActions.accessSettings',
              })}
            </ContextActionItem>
            <ContextActionItem
              icon={<IconLayoutGrid />}
              component={Link}
              to={extensionSettingsUrl(queueId)}
            >
              {intl.formatMessage({
                id: 'components.documentContextActions.extensionSettings',
              })}
            </ContextActionItem>
          </NonAdminRestrictor>
        ) : null}
      </Menu>
    </>
  );
};
