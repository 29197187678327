import { Url } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { IconCode, IconCodeOff } from '@rossum/ui/icons/tabler';
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { FieldValues, Path, UseControllerReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ContentWrapper } from '../../../ui/content-group/ContentWrapper';
import { SplitContent } from '../../../ui/content-group/SplitContent';
import { AuroraSuggest } from '../../../ui/icons/AuroraSuggest';
import { FormulaEditor } from '../../formulas/FormulaEditor/FormulaEditor';
import { getValidSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { SummaryBox } from '../../formulas/FormulaFieldSection/components/SummaryBox';
import { FormulaPreviewGrid } from '../../formulas/FormulaPreview/FormulaPreviewGrid';
import { visibleActions } from '../actions';
import { TriggerCopilot } from '../copilots/TriggerCopilot';
import { useSuggestTriggerConditionSummary } from '../hooks/useSuggestTriggerConditionSummary';
import { useValidateTrigger } from '../hooks/useValidateTrigger';
import { RuleFormType } from '../types';

const MIN_VISIBLE_EDITOR_LINES = 3;

type TriggerProps<T extends FieldValues & RuleFormType> = {
  schemaContent: SchemaSection[];
  queueUrls: Url[];
  schemaUrl: Url;
  triggerConditionField: UseControllerReturn<T, Path<T>>;
  currentRuleConcept: T;
  validationError: string | undefined;
};

const createRuleSection = (formula: string): SchemaSection => ({
  id: 'rule_section',
  category: 'section',
  label: 'rule section',
  children: [
    {
      id: `condition`,
      category: 'datapoint',
      label: 'Formula condition',
      type: 'string',
      formula,
      uiConfiguration: { type: 'formula', edit: 'disabled' },
    },
  ],
});

export const TriggerSection = <T extends FieldValues & RuleFormType>({
  schemaContent,
  queueUrls,
  schemaUrl,
  triggerConditionField,
  currentRuleConcept,
  validationError,
}: TriggerProps<T>) => {
  const intl = useIntl();

  const [triggerCopilotOpen, setTriggerCopilotOpen] = useState(false);
  const [codeEditorVisible, setCodeEditorVisible] = useState(false);

  const [ruleForPreview, setRuleForPreview] = useState<T>(currentRuleConcept);

  const [previewVisible, setPreviewVisible] = useState(false);

  const {
    data: triggerConditionError,
    isLoading: isValidatingTriggerCondition,
  } = useValidateTrigger(triggerConditionField.field.value);

  const { data: summary, isFetching: isSuggestingSummary } =
    useSuggestTriggerConditionSummary(
      {
        schemaContent,
        schemaRule: { ...ruleForPreview, schema: schemaUrl },
      },
      {
        enabled:
          !!ruleForPreview.name.length &&
          !!triggerConditionField.field.value.length &&
          !triggerConditionError &&
          !isValidatingTriggerCondition &&
          !validationError,
      }
    );

  useEffect(() => {
    if (triggerConditionError || validationError) {
      setCodeEditorVisible(true);
    }
  }, [triggerConditionError, validationError]);

  return (
    <>
      <ContentWrapper>
        <SplitContent
          title={intl.formatMessage({
            id: 'features.queueSettings.rules.detail.when.title',
          })}
          description={intl.formatMessage({
            id: 'features.queueSettings.rules.detail.when.description',
          })}
        >
          {isSuggestingSummary ? (
            <SummaryBox variant="suggestingSummary" />
          ) : summary ? (
            <SummaryBox value={summary} />
          ) : triggerConditionField.field.value ? (
            <SummaryBox variant="editingCode" />
          ) : (
            <SummaryBox
              variant="emptyRule"
              callback={() => setTriggerCopilotOpen(true)}
            />
          )}
        </SplitContent>

        <Stack>
          <Collapse in={codeEditorVisible}>
            <Stack spacing={2} pt={3}>
              <Divider />
              <FormulaEditor
                fields={getValidSchemaFields(schemaContent)}
                value={triggerConditionField.field.value}
                onChange={newValue => {
                  return triggerConditionField.field.onChange(newValue);
                }}
                config={{
                  minVisibleLines: MIN_VISIBLE_EDITOR_LINES,
                  showGutters: true,
                }}
                onBlur={triggerConditionField.field.onBlur}
                renderButtons={() => (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={
                      isSuggestingSummary ||
                      !!triggerConditionError ||
                      isValidatingTriggerCondition
                    }
                    startIcon={
                      isSuggestingSummary ? (
                        <CircularProgress size={18} />
                      ) : null
                    }
                    onClick={() => {
                      setRuleForPreview(currentRuleConcept);
                    }}
                  >
                    {intl.formatMessage({ id: 'features.formulas.applyCode' })}
                  </Button>
                )}
              />
              {triggerConditionError || validationError ? (
                <Typography color="error" variant="body2">
                  {triggerConditionError || validationError}
                </Typography>
              ) : null}
            </Stack>
          </Collapse>
          <Collapse in={previewVisible}>
            <Stack spacing={2} pt={3}>
              <Divider />

              {ruleForPreview && ruleForPreview.triggerCondition ? (
                <FormulaPreviewGrid
                  queueUrls={queueUrls}
                  type="rule"
                  currentFormulaId="condition"
                  schemaRules={[{ ...ruleForPreview, schema: schemaUrl }]}
                  schemaForPreview={[
                    createRuleSection(ruleForPreview.triggerCondition),
                    ...schemaContent,
                  ]}
                  disabled={
                    !!triggerConditionError || isValidatingTriggerCondition
                  }
                  renderAction={action => {
                    const config = visibleActions.find(knownAction =>
                      knownAction.resolveAction(action)
                    );
                    const Icon = config?.icon;

                    return Icon ? (
                      <Tooltip
                        title={intl.formatMessage({
                          id: `features.queueSettings.rules.actions.${config.intlKey}.name`,
                        })}
                      >
                        <Icon />
                      </Tooltip>
                    ) : null;
                  }}
                />
              ) : null}
            </Stack>
          </Collapse>
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <Tooltip
            title={
              codeEditorVisible
                ? intl.formatMessage({ id: 'features.formulas.hideSourceCode' })
                : intl.formatMessage({ id: 'features.formulas.showSourceCode' })
            }
          >
            <IconButton
              color="inherit"
              onClick={() => setCodeEditorVisible(!codeEditorVisible)}
            >
              <SvgIcon>
                {codeEditorVisible ? <IconCodeOff /> : <IconCode />}
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              if (previewVisible) {
                setPreviewVisible(false);
              } else {
                setRuleForPreview(currentRuleConcept);
                setPreviewVisible(true);
              }
            }}
          >
            {previewVisible
              ? intl.formatMessage({
                  id: 'features.queueSettings.rules.detail.when.hideTest',
                })
              : intl.formatMessage({
                  id: 'features.queueSettings.rules.detail.when.testRule',
                })}
          </Button>

          <Button
            variant="contained"
            color="aurora"
            onClick={() => setTriggerCopilotOpen(true)}
            startIcon={<AuroraSuggest />}
            data-cy="copilot-edit"
          >
            {intl.formatMessage({ id: 'features.rules.copilotEdit' })}
          </Button>
        </Stack>
      </ContentWrapper>
      <TriggerCopilot
        open={triggerCopilotOpen}
        onClose={() => setTriggerCopilotOpen(false)}
        onSuccess={response => {
          if (response?.triggerCondition) {
            triggerConditionField.field.onChange(response.triggerCondition);

            setRuleForPreview({
              ...currentRuleConcept,
              triggerCondition: response.triggerCondition,
            });
          }

          setTriggerCopilotOpen(false);
        }}
        schemaContent={schemaContent}
        schemaRule={{ ...currentRuleConcept, schema: schemaUrl }}
      />
    </>
  );
};
