import { Logo } from '@rossum/ui';
import { keyframes, Stack, styled, Typography } from '@rossum/ui/material';
import { PADDING_TOP } from '../../../components/UI/Container';

export const HEADER_HEIGHT = 50;

const LOGO_SIZE = 30;

const ANIMATION_DURATION = 2000;
const ANIMATION_DELAY = 1000;

const logoAnimation = keyframes({
  '0%': { left: '-100%' },
  '100%': { left: '-50%' },
});

const textAnimation = keyframes({
  '0%': { opacity: 1 },
  '8%': { opacity: 0 },
  '100%': { opacity: 0 },
});

const TextWrapper = styled('div')<{ isLoading: boolean }>(
  ({ theme, isLoading }) => ({
    marginLeft: `calc(${theme.spacing(1.5)} + ${LOGO_SIZE}px)`,
    animationName: isLoading ? `${textAnimation}` : '',
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationDelay: `${ANIMATION_DELAY}ms`,
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  })
);

const Overlay = styled('div')<{ isLoading: boolean }>(
  ({ theme, isLoading }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    opacity: isLoading ? 1 : 0,
  })
);

export type AnimationState = 'ready' | 'loading' | 'hidden';

type AnimatedHeaderProps = {
  title: string;
  animationState: AnimationState;
  setAnimationDone: (value: boolean) => void;
};

const AnimatedHeader = ({
  title,
  animationState,
  setAnimationDone,
}: AnimatedHeaderProps) => {
  const isLoading = animationState === 'loading';

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        pointerEvents: 'none',
      }}
    >
      <Overlay isLoading={isLoading} />
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          position: 'absolute',
          top: PADDING_TOP,
          color: theme => theme.palette.common.white,
        }}
      >
        <Stack
          onAnimationEnd={() => setAnimationDone(true)}
          sx={{
            position: 'absolute',
            left: isLoading ? '-50%' : '-100%',
            padding: `5px 0 5px 100%`,
            backgroundColor: theme => theme.palette.background.default,
            borderRadius: '0px 20px 20px 0px',
            zIndex: 1,
            animationName: isLoading ? `${logoAnimation}` : '',
            animationDuration: `${ANIMATION_DURATION}ms`,
            animationIterationCount: 1,
            animationTimingFunction: 'cubic-bezier(.3,-1,.4,4)',
          }}
        >
          <Logo size={LOGO_SIZE} />
        </Stack>
        <TextWrapper isLoading={isLoading}>
          <Typography variant="h3">{title}</Typography>
        </TextWrapper>
      </Stack>
    </Stack>
  );
};

export default AnimatedHeader;
