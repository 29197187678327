import { getIDFromUrl, Url } from '@rossum/api-client';
import { FieldLabel } from '@rossum/ui';
import { Box, Grid, Skeleton, Stack, Typography } from '@rossum/ui/material';
import { FieldValues, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import { useQueueToSchemaDatapointMap } from '../../../../../../../../../business/queues/useQueueToSchemaDatapointMap';
import RadioGroupControl from '../../../../../../../../../components/ReactHookForm/controls/RadioGroupControl';
import SingleCheckboxControl from '../../../../../../../../../components/ReactHookForm/controls/SingleCheckboxControl';
import TextFieldControl from '../../../../../../../../../components/ReactHookForm/controls/TextFieldControl';
import { CompositeControlProps } from '../../../../../../../../../components/ReactHookForm/utils';
import {
  FieldMappingControl,
  FieldMappingControlShape,
} from './FieldMappingControl';

type DatapointFieldControlShape = {
  label: string;
  engineOutputId: string;
  type: string;
  description: string;
  sources: FieldMappingControlShape[];
  isMultivalue?: boolean;
};

type DatapointFieldControlProps<
  TFieldValues extends FieldValues = FieldValues,
> = CompositeControlProps<TFieldValues, DatapointFieldControlShape> & {
  trainingQueues: Url[];
  fieldType: 'headerField' | 'lineItemColumn';
};

const DatapointFieldControl = <TFieldValues extends FieldValues = FieldValues>({
  ControllerProps: { control, nameMap },
  disabled,
  trainingQueues,
  fieldType,
}: DatapointFieldControlProps<TFieldValues>) => {
  // TODO: Doesn't this break the convention of not letting form controls handle
  // localisation?
  // to get rid of it we'd have to pass more than just nameMap as a prop
  // it's debatable whether composite controls should be as flexible as simple controls though,
  // perhaps labels should be fixed on per control basis, e.g. label for 'firstName' is always 'First name' etc.
  // yet it would be a natural extension of the API to pass e.g. a Partial of props for a given field's Control
  const intl = useIntl();

  const { fields } = useFieldArray({
    control,
    name: nameMap.sources.__rootField,
  });

  // TODO: This would be much better lazy loaded as they come into view, rather than fetch
  // a potentially long list at once
  const { data, status } = useQueueToSchemaDatapointMap(
    R.pipe(trainingQueues.map(getIDFromUrl), R.filter(R.isTruthy)),
    fieldType,
    dp =>
      dp.type !== 'button' &&
      (!dp.uiConfiguration || dp.uiConfiguration.type === 'captured') &&
      !dp.disablePrediction
  );

  // TODO: This is a codesmell, can we find a better way of accessing values within
  // composite controls later?
  // would also break if `queue` became a composite component
  const queueKey = nameMap.sources.nameMap(0).queue.split('.').pop();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldControl
            ControllerProps={{ control, name: nameMap.label }}
            placeholder={intl.formatMessage({
              id: 'components.datapointFieldControl.placeholders.label',
            })}
            label={intl.formatMessage({
              id: 'components.datapointFieldControl.labels.label',
            })}
            required
            disabled={disabled}
            // to not lose tabIndex when mounting form
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldControl
            ControllerProps={{
              control,
              name: nameMap.engineOutputId,
            }}
            placeholder={intl.formatMessage({
              id: 'components.datapointFieldControl.placeholders.engineOutputId',
            })}
            label={intl.formatMessage({
              id: 'components.datapointFieldControl.labels.engineOutputId',
            })}
            required
            autoComplete="off"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupControl
            ControllerProps={{ control, name: nameMap.type }}
            // TODO: Are we translating this?
            options={[
              {
                id: 'string',
                label: 'String',
              },
              {
                id: 'number',
                label: 'Number',
              },
              {
                id: 'date',
                label: 'Date',
              },
              {
                id: 'enum',
                label: 'Enum',
              },
            ]}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            label={intl.formatMessage({
              id: 'components.datapointFieldControl.labels.type',
            })}
            aria-label="type"
            row
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldControl
            ControllerProps={{
              control,
              name: nameMap.description,
            }}
            placeholder={intl.formatMessage({
              id: 'components.datapointFieldControl.placeholders.description',
            })}
            label={intl.formatMessage({
              id: 'components.datapointFieldControl.labels.description',
            })}
            multiline
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={3}>
          {typeof nameMap.isMultivalue === 'string' && (
            <SingleCheckboxControl
              ControllerProps={{
                control,
                name: nameMap.isMultivalue,
              }}
              FieldLabelProps={{
                layout: 'none',
              }}
              label={intl.formatMessage({
                id: 'components.datapointFieldControl.labels.isMultivalue',
              })}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FieldLabel
            label={intl.formatMessage({
              id: 'components.datapointFieldControl.labels.sources',
            })}
            required
          >
            <Stack spacing={2}>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({
                  id: 'components.datapointFieldControl.sourcesDescription',
                })}
              </Typography>
              <Stack alignSelf="center" spacing={2} sx={{ width: 600 }}>
                {status === 'success' &&
                  fields.map((field, i) => {
                    // related to codesmell with queueKey
                    // the value is on the `field` object but in general we don't know how to access it
                    // and TS generics can't give us the type
                    // enforcing shape of Control is not possible if it is to be used inside multiple different forms?
                    // console.log((field as any)[queueKey]);
                    const queueId = getIDFromUrl(
                      field[queueKey as keyof typeof field] as unknown as Url
                    );
                    const queue = data[queueId];
                    return queue ? (
                      <FieldMappingControl
                        key={field.id}
                        ControllerProps={{
                          control,
                          nameMap: nameMap.sources.nameMap(i),
                        }}
                        queueName={queue.queueName}
                        schemaFieldOptions={queue.datapoints}
                        disabled={disabled}
                      />
                    ) : null;
                  })}
                {status === 'loading' &&
                  fields.map(field => (
                    <Skeleton
                      key={field.id}
                      variant="rectangular"
                      width="100%"
                      height={35}
                    />
                  ))}
              </Stack>
            </Stack>
          </FieldLabel>
        </Grid>
      </Grid>
    </Box>
  );
};

export { DatapointFieldControl };
