import { Skeleton, Stack } from '@rossum/ui/material';
import { useRef } from 'react';
import * as R from 'remeda';

const RandomSkeleton = () => {
  const width = useRef(R.randomInteger(10, 50)).current;

  return <Skeleton sx={{ width: `${width}%`, height: 40 }} />;
};

export const DocumentSidebarSkeleton = () => {
  return (
    <Stack spacing={0} sx={{ pt: 2 }}>
      {R.range(0, 25).map(item => (
        <Stack
          direction="row"
          justifyContent="space-between"
          key={item}
          spacing={0}
          sx={{ pl: '20px', pr: '16px' }}
        >
          <RandomSkeleton />
          <RandomSkeleton />
        </Stack>
      ))}
    </Stack>
  );
};
