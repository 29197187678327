import { Theme } from '../material';
import { paperBackgroundMap } from './component-overrides/paper';

export const getScrollbarColors = (theme: Theme) => {
  const trackColor = theme.palette.background.paper;

  // TODO: @ui Change to theme.paper when available
  const thumbColor = paperBackgroundMap[theme.palette.mode]['12'];

  return { trackColor, thumbColor };
};
