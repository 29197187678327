import { InfoOutlined as InfoOutlinedIcon } from '@rossum/ui/icons';
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { docsLinks } from '../../../constants/values';
import { link } from '../../../lib/formaterValues';
import { GroupRole } from '../../../types/group';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 750,
    textAlign: 'left',
  },
});

type RoleTooltipProps = {
  roles: Array<GroupRole>;
};

const RoleTooltip = ({ roles }: RoleTooltipProps) => {
  const intl = useIntl();

  return (
    <CustomWidthTooltip
      placement="top"
      title={
        <>
          {roles.map(role => (
            <div key={role}>
              <Typography variant="body2" component="span">
                <Typography variant="body2" fontWeight={600} component="span">
                  {intl.formatMessage({
                    id: `containers.settings.users.roles.${role}`,
                  })}
                </Typography>
                {` - `}
                {intl.formatMessage(
                  {
                    id: `containers.settings.users.roles.${role}.description`,
                  },
                  {
                    link:
                      role === 'organization_group_admin' &&
                      link(docsLinks.userRole),
                  }
                )}
              </Typography>
            </div>
          ))}
        </>
      }
    >
      <InfoOutlinedIcon fontSize="small" />
    </CustomWidthTooltip>
  );
};

export default RoleTooltip;
