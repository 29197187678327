import { inputBaseClasses, useTheme } from '@rossum/ui/material';
import { TextField, TextFieldProps } from '@rossum/ui/material';
import { useMemo } from 'react';
import HTMLMessage from '../../../../components/DatapointMessage/HTMLMessage';
import { getFontSize } from '../../document-canvas/utils/fontScaling';

const DEFAULT_FONT = 'Inter, Arial, sans-serif';

type ValidationTextFieldProps = Omit<TextFieldProps, 'value'> & {
  value: string | undefined;
  parentWidth: number | undefined;
  message: { type: 'error' | 'info' | 'warning'; content: string } | undefined;
  schemaLabel?: string;
};

const ValidationTextField = ({
  parentWidth,
  value,
  message,
  schemaLabel,
  ...textFieldProps
}: ValidationTextFieldProps) => {
  const { disabled, onChange } = textFieldProps;

  const { fontFamily } = useTheme().typography;

  const size = useMemo(() => {
    return parentWidth
      ? getFontSize(
          !value ? 'M' : value,
          parentWidth,
          fontFamily ?? DEFAULT_FONT
        )
      : undefined;
  }, [fontFamily, parentWidth, value]);

  return (
    <TextField
      id="editBoxInput"
      multiline
      value={value}
      FormHelperTextProps={{
        sx: {
          mx: '10px',
          color: theme =>
            message?.type === 'error'
              ? theme.palette.error.main
              : message?.type === 'warning'
                ? theme.palette.warning.dark
                : undefined,
        },
        component: 'div',
      }}
      error={message?.type === 'error'}
      helperText={
        message ? <HTMLMessage content={message.content} /> : schemaLabel
      }
      disabled={disabled}
      onChange={onChange}
      // To match alignment and number of lines with the extracted text
      sx={{
        [`.${inputBaseClasses.root}`]: {
          px: '10px',
        },
      }}
      inputProps={{
        style: {
          lineHeight: 'normal',
          fontSize: size?.fontSize ? `${size.fontSize}px` : undefined,
          overflow: 'hidden',
        },
      }}
      {...textFieldProps}
    />
  );
};

export { ValidationTextField };
