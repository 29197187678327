import { Label } from '@rossum/api-client/labels';
import { Queue } from '@rossum/api-client/queues';
import { SchemaField } from '@rossum/api-client/schemaFields';
import {
  AnnotationListTable,
  MetaColumn,
  MetaField,
  SchemaColumn,
} from '@rossum/api-client/shared';
import {
  GridColumnVisibilityModel,
  GridRowParams,
} from '@rossum/ui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { fromEntries, isArray, uniqueBy } from 'remeda';
import { allTabStatuses } from '../../../containers/AnnotationList/helpers';
import { isStatusVisible } from '../../../containers/AnnotationList/types';
import { camelToSnake } from '../../../lib/keyConvertor';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { organizationGroupSelector } from '../../../redux/modules/organizationGroup/selectors';
import { LabelsColumn } from '../../document-list-base/components/LabelsColumn';
import { RenderDate } from '../../document-list-base/components/RenderDate';
import {
  RenderDetails,
  WorkflowRuns,
} from '../../document-list-base/components/RenderDetails';
import { RenderStatus } from '../../document-list-base/components/RenderStatus';
import { dateValueGetter } from '../../document-list-base/helpers/dateValueGetter';
import { useUnpaginatedWorklowRuns } from '../../document-list-base/hooks/useUnpaginatedWorkflowRuns';
import { detailsColumnOptionKeys } from '../../document-list-base/mql/constants';
import {
  dateOperators,
  detailsOperators,
  multiSelectOperators,
  numericOperators,
  queueSelectOperators,
  statusOperators,
  stringFieldOperators,
} from '../../document-list-base/mql/operators';
import {
  MetaColDef,
  SchemaColDef,
  TypedGridColDef,
} from '../../document-list-base/mql/types';
import { SupportedAnnotationView } from '../../document-list-base/supportedAnnotationViews';
import { useRequestUnpaginatedLabels } from '../../labels/hooks/useRequestLabels';
import { RestrictedAccessIcon } from '../../pricing/components/RestrictedAccessIcon';
import { labelsFeatureSelector } from '../../pricing/selectors';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { ColumnHeader } from '../components/ColumnHeader';
import { RowActions } from '../components/RowActions';
import { toDict, TransformedData } from '../helpers';
import {
  SCHEMA_FIELDS_QUERY_KEY,
  useFetchSchemaFields,
} from '../hooks/useFetchSchemaFields';
import { userDashboardCustomizationSelector } from '../selectors';
import { AllDocsAnnotation, CellProps } from '../types';
import {
  filterMetaColumns,
  filterSchemaColumns,
  getColumnField,
  getColumnName,
  sortValueOptions,
} from './helpers';
import { RenderDatapointField } from './RenderDatapointField';
import { RenderFilenameCell } from './RenderFilenameCell';
import { WorkspacesProps, WorkspacesQueuesCell } from './WorkspacesQueuesCell';

type LabelsProps = {
  labels: Record<string, Label>;
  labelsEnabled: boolean;
  labelsAreLoading: boolean;
};

// TODO: fix typings of allTabStatuses
const supportedStatuses = allTabStatuses.filter(isStatusVisible);

export const ACTIONS_COLUMN = 'actions';

const commonMetaColumnProps: Omit<MetaColumn, 'metaName' | 'width'> = {
  columnType: 'meta',
  visible: true,
};
const getMetaColumns = ({
  intl,
  commonFieldProps,
  labelsProps: { labelsEnabled, labels, labelsAreLoading },
  workspacesProps,
  handleSelectAnnotation,
  workflowRuns,
  setRowForPreview,
  level,
}: {
  intl: IntlShape;
  commonFieldProps: Partial<MetaColDef>;
  labelsProps: LabelsProps;
  workspacesProps: WorkspacesProps;
  handleSelectAnnotation: (params: {
    annotationUrl: string;
    view: SupportedAnnotationView;
  }) => void;
  workflowRuns: WorkflowRuns;
  setRowForPreview: (row: TransformedData | undefined) => void;
  level: string | undefined;
}): Array<MetaColDef> => {
  const { workspacesWithQueuesMap, workspacesAreLoading } = workspacesProps;

  return R.pipe<Array<MetaColDef | undefined>, Array<MetaColDef>>(
    [
      {
        ...commonMetaColumnProps,
        metaName: 'status',
        width: 170,
        field: 'status' satisfies MetaField,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.status',
        }),
        renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
        renderCell: RenderStatus,
        ...commonFieldProps,
        operators: statusOperators,
        valueOptions: supportedStatuses.map(status => ({
          value: camelToSnake(status),
          label: intl.formatMessage({
            id: `containers.annotationList.statuses.${status}`,
          }),
        })),
        filterable: true,
      },
      {
        ...commonMetaColumnProps,
        metaName: 'original_file_name',
        field: 'original_file_name' satisfies MetaField,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.document__original_file_name',
        }),
        width: 270,
        renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
        renderCell: params => (
          <RenderFilenameCell
            {...params}
            onMouseOver={() => setRowForPreview(params.row)}
            onMouseOut={() => setRowForPreview(undefined)}
          />
        ),
        ...commonFieldProps,
        operators: stringFieldOperators,
        filterable: true,
      },
      {
        ...commonMetaColumnProps,
        metaName: 'details',
        field: 'details' satisfies MetaField,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.details',
        }),
        width: 150,
        filterable: true,
        valueOptions: [
          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.automated',
            }),
            value: detailsColumnOptionKeys.automated,
          },
          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.automaticallyRejected',
            }),
            value: detailsColumnOptionKeys.automatically_rejected,
          },
          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.emails',
            }),
            value: detailsColumnOptionKeys.emails,
          },
          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.attachments',
            }),
            value: detailsColumnOptionKeys.attachments,
          },
          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.duplicates',
            }),
            value: detailsColumnOptionKeys.duplicates,
          },

          {
            label: intl.formatMessage({
              id: 'components.documentOverview.details.valueOptions.edited',
            }),
            value: detailsColumnOptionKeys.edit,
          },
        ],
        resizable: false,
        hideable: false,
        operators: detailsOperators,
        renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
        renderCell: props => {
          const hasWorkflowRun = workflowRuns.some(
            ({ annotationUrl }) => annotationUrl === props.row.url
          );
          return (
            <RenderDetails
              {...props}
              hasWorkflowRun={hasWorkflowRun}
              handleSelectAnnotation={handleSelectAnnotation}
            />
          );
        },
        ...commonFieldProps,
        sortable: false,
      },
      {
        ...commonMetaColumnProps,
        visible: level !== 'queue',
        metaName: 'queue',
        field: 'queue' satisfies MetaField,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.queue',
        }),
        width: 300,
        renderCell: cellProps => (
          <WorkspacesQueuesCell
            {...cellProps}
            workspacesProps={workspacesProps}
          />
        ),
        ...commonFieldProps,
        operators: queueSelectOperators,
        valueOptions: sortValueOptions(
          Object.values(workspacesWithQueuesMap).flatMap(item => ({
            label: item.queueName,
            value: `${item.queueId}`,
          }))
        ),
        filterable: true,
        areValuesLoading: workspacesAreLoading,
        disableWithNoValues: true,
      },
      labelsEnabled
        ? {
            ...commonMetaColumnProps,
            metaName: 'labels',
            field: 'labels' satisfies MetaField,
            headerName: intl.formatMessage({
              id: 'components.documentOverview.labels',
            }),
            width: 250,
            ...commonFieldProps,
            renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
            renderCell: (cellProps: CellProps<string[]>) => (
              <LabelsColumn
                labels={R.pipe(
                  cellProps.row.labels.map(labelUrl => labels[labelUrl]),
                  R.filter(R.isTruthy)
                )}
              />
            ),
            operators: multiSelectOperators,
            valueOptions: sortValueOptions(
              Object.values(labels).map(label => ({
                value: `${label.id}`,
                label: label.name,
              }))
            ),
            valueGetter: ({ value }: { value: Array<string> }) =>
              value.join(', '),
            filterable: true,
            areValuesLoading: labelsAreLoading,
            disableWithNoValues: true,
          }
        : undefined,
      {
        ...commonMetaColumnProps,
        metaName: 'created_at',
        field: 'created_at' satisfies MetaField,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.createdAt',
        }),
        width: 150,
        renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
        renderCell: (cellProps: CellProps<Date>) => (
          <RenderDate {...cellProps} />
        ),
        ...commonFieldProps,
        operators: dateOperators,
        valueGetter: dateValueGetter,
        filterable: true,
      },
    ],
    R.filter(R.isTruthy)
  );
};

// action column is not defined in the API because it is purely for FE and does not relate to any data in the API
// which is why have to separate it from meta columns that are defined in the API.
const actionsColumn: TypedGridColDef = {
  field: ACTIONS_COLUMN,
  type: 'actions',
  width: 50,
  resizable: false,
  operators: [],
  filterable: false,
  getActions: ({ row, id }: GridRowParams<AllDocsAnnotation>) => [
    row.restricted_access ? (
      <RestrictedAccessIcon />
    ) : (
      <RowActions key={id} annotation={row} />
    ),
  ],
};

const getSchemaColumns = ({
  schemaColumnsWithLabels,
  commonFieldProps,
}: {
  schemaColumnsWithLabels: Array<SchemaColumn & { label: string }>;
  commonFieldProps: Partial<SchemaColDef>;
}): SchemaColDef[] => {
  return schemaColumnsWithLabels.map(c => {
    const schemaOperators =
      c.dataType === 'date'
        ? dateOperators
        : c.dataType === 'number'
          ? numericOperators
          : stringFieldOperators;

    return {
      align: 'left',
      columnType: 'schema',
      schemaId: c.schemaId,
      dataType: c.dataType,
      headerAlign: 'left',
      visible: c.visible,
      scoreThreshold: c.scoreThreshold,
      field: getColumnField({
        columnType: c.columnType,
        field: c.schemaId,
        dataType: c.dataType,
      }),
      headerName: c.label,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      renderCell: params => <RenderDatapointField {...params} />,
      width: c.width ?? 170,
      // Using `date` type here will cause MUI Data Grid to throw an error: `date` column type only accepts `Date` objects as values.
      // do not set `date` type as column value doesn't need to be in parseable format and easily convertable to Date object
      // but use date filter operators that render Datepicker
      type: c.dataType === 'date' ? 'string' : c.dataType,
      operators: schemaOperators,
      filterable: true,
      ...commonFieldProps,
    };
  });
};

const setLabelForSchemaField =
  (schemaFields: SchemaField[]) => (schemaColumn: SchemaColumn) => {
    const columnLabelInSchema = schemaFields.find(
      field =>
        field.schemaId === schemaColumn.schemaId &&
        field.type === schemaColumn.dataType
    )?.label;

    return {
      ...schemaColumn,
      label: columnLabelInSchema ?? schemaColumn.schemaId,
    };
  };

export const useColumns = ({
  commonFieldProps,
  sideloadedLabels,
  dataIsLoading,
  activeQueue,
  handleSelectAnnotation,
  annotations,
  setRowForPreview,
  level,
  tableConfig,
  isTableConfigLoading,
  statusColumns,
}: {
  statusColumns: MetaColDef[];
  isTableConfigLoading: boolean;
  tableConfig: AnnotationListTable;
  commonFieldProps: Pick<TypedGridColDef, 'sortable'>;
  sideloadedLabels: Record<string, Label> | undefined;
  dataIsLoading: boolean;
  activeQueue: Queue | null;
  annotations: AllDocsAnnotation[] | undefined;
  handleSelectAnnotation: (params: {
    annotationUrl: string;
    view: SupportedAnnotationView;
  }) => void;
  setRowForPreview: (row: TransformedData | undefined) => void;
  level: string | undefined;
}): {
  columnsAreReady: boolean;
  columns: Array<SchemaColDef | MetaColDef | TypedGridColDef>;
  columnVisibilityModel: GridColumnVisibilityModel;
} => {
  const intl = useIntl();
  const labelsEnabled = useSelector(labelsFeatureSelector);
  const { data: allLabels, isInitialLoading: allLabelsAreLoading } =
    useRequestUnpaginatedLabels({
      select: toDict,
    });

  const { data: workflowRuns } = useUnpaginatedWorklowRuns({
    annotationIds: annotations?.map(a => a.id) ?? [],
  });

  const labels = { ...sideloadedLabels, ...allLabels };

  const organizationId = useSelector(safeOrganizationSelector)?.id;
  const organizationLoaded = !!organizationId;
  const organizationGroupLoaded = !!useSelector(organizationGroupSelector);

  const existingSchemaColumns = filterSchemaColumns(tableConfig.columns);

  const userDefinedWidth = useSelector(userDashboardCustomizationSelector);

  const { data: schemaFields } = useFetchSchemaFields({
    schemaIds:
      existingSchemaColumns?.map(schemaCol => schemaCol.schemaId) ?? [],
    queueIds: activeQueue ? [activeQueue.id] : [],
  });

  // we don't store schema column labels (displayed in table header)
  // So here; we fetch the schema fields separately, find the corresponding label there, and append it to the column here
  const schemaColumnsWithLabels = existingSchemaColumns.map(
    setLabelForSchemaField(schemaFields?.results ?? [])
  );

  const { workspacesWithQueues: workspaces, isLoading: workspacesAreLoading } =
    useWorkspacesWithQueues({
      enableQueries: true,
    });

  const isTableConfigInitiallyLoading =
    isTableConfigLoading || workspacesAreLoading;

  const queueValuesArray = workspaces?.flatMap(workspace => {
    const queueValues = workspace.queues.map(queue => ({
      url: queue.url,
      queueId: queue.id,
      queueName: queue.name,
      workspaceName: workspace.name,
    }));

    return queueValues;
  });

  const workspacesWithQueuesMap = toDict(queueValuesArray);

  const metaColumns = getMetaColumns({
    commonFieldProps,
    intl,
    labelsProps: {
      labels,
      labelsEnabled,
      labelsAreLoading: dataIsLoading || allLabelsAreLoading,
    },
    workspacesProps: {
      workspacesWithQueuesMap,
      workspacesAreLoading,
    },
    handleSelectAnnotation,
    workflowRuns:
      workflowRuns?.map(({ annotation }) => ({
        annotationUrl: annotation,
      })) ?? [],
    setRowForPreview,
    level,
  });

  const schemaColumns = getSchemaColumns({
    schemaColumnsWithLabels,
    commonFieldProps,
  });

  const savedMetaColumns = filterMetaColumns(tableConfig.columns);

  const mergedMetaColumns = [...metaColumns, ...statusColumns].map(col => {
    const savedColumn = savedMetaColumns.find(c => c.metaName === col.metaName);
    return savedColumn ? { ...col, ...savedColumn } : col;
  });

  const mergedColumns = uniqueBy(
    [...mergedMetaColumns, ...schemaColumns],
    col => col.field
  );

  const columnsOrder = tableConfig.columns.map(getColumnName);

  const columns = mergedColumns.map(c => {
    const key = c.field;

    const userWidth = activeQueue
      ? userDefinedWidth.queues?.width[activeQueue.id]?.[key]
      : userDefinedWidth.organization?.width[key];

    return {
      ...c,
      width: userWidth ?? c.width,
    };
  });

  const sortedColumns = sortBy(columns, col => {
    const index = columnsOrder.findIndex(
      colName => colName === getColumnName(col)
    );
    return index > -1 ? index : columns.length;
  });

  const queryClient = useQueryClient();

  // getQueriesData returns an array of tuples with [queryKey, queryData],
  // use [] as default value as getQueriesData can return an empty array in case of no queries with given key
  // get first query, destructure key and data from it
  const [[firstSchemaFieldsQueryKey, firstSchemaFieldsQueryData] = []] =
    queryClient.getQueriesData({
      queryKey: [SCHEMA_FIELDS_QUERY_KEY],
    });

  // when schemaIds key is empty we do not need to check loading
  const schemaIdsKeyHasLength =
    firstSchemaFieldsQueryKey?.[1] &&
    isArray(firstSchemaFieldsQueryKey[1]) &&
    firstSchemaFieldsQueryKey[1].length;

  // wait until firstSchemaFieldsQueryData is not undefined in case of having any schemaIds
  const isInitialLoading =
    schemaIdsKeyHasLength && firstSchemaFieldsQueryData === undefined;

  const columnsAreReady =
    !isInitialLoading &&
    !isTableConfigInitiallyLoading &&
    organizationLoaded &&
    organizationGroupLoaded;

  return {
    columnsAreReady,
    columns: [...sortedColumns, actionsColumn],
    columnVisibilityModel: fromEntries(
      columns.map(col => [col.field, col.visible])
    ),
  };
};
