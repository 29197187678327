import { Typography } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'redux-first-history';
import {
  contactSupportLink,
  statusPageURL,
  termsAndConditionsURL,
} from '../../constants/values';
import { link } from '../../lib/formaterValues';
import ElisHead from './components/ElisHead';
import styles from './styles.module.sass';

type Props = { refresh: () => void };
type MessageKey = 'maintenance' | 'throttleError';
export type MaintenaceRouterState = {
  initialCountdown?: number;
  messageKey: MessageKey;
};

const Maintenance = ({ refresh }: Props) => {
  const location = useLocation<MaintenaceRouterState>();

  const { initialCountdown = 10, messageKey = 'maintenance' } =
    location.state ?? {};

  const [countdown, setCountdown] = useState(initialCountdown);

  const intl = useIntl();

  useEffect(() => {
    const interval = setInterval(
      () => setCountdown(n => Math.max(n - 1, 0)),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown <= 0) refresh();
  }, [countdown, refresh]);

  const progressPercentage =
    (100 / initialCountdown) * (initialCountdown - countdown);

  return (
    <div className={styles.Container} data-page-title="maintenance">
      <ElisHead />
      <div className={styles.Title}>
        <FormattedMessage id="containers.maintenance.title" />
      </div>
      <div className={styles.Text}>
        {intl.formatMessage(
          { id: `containers.${messageKey}.text` },
          {
            policyLink: link(termsAndConditionsURL, {
              color: 'inherit',
            }),
          }
        )}
      </div>
      <div className={styles.LoaderBar}>
        <div
          style={{ width: `${progressPercentage}%` }}
          className={styles.Loader}
        />
      </div>
      <div className={styles.Countdown}>
        {countdown}
        <FormattedMessage id="containers.maintenance.smallText" />
      </div>
      <Typography color="text.secondary" fontSize="small" sx={{ mt: '105px' }}>
        {intl.formatMessage(
          { id: 'containers.maintenance.badge.text1' },
          {
            contactLink: link(contactSupportLink, {
              color: 'inherit',
            }),
            statusLink: link(statusPageURL, { color: 'inherit' }),
          }
        )}
      </Typography>
    </div>
  );
};

export default connect<unknown, { refresh: () => void }>(null, {
  refresh: () => replace('/'),
})(Maintenance);
