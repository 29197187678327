import { Hook } from '@rossum/api-client/hooks';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  paperClasses,
  Skeleton,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import * as R from 'remeda';

const isKeydownEvent = (
  e: React.SyntheticEvent<Element, Event>
): e is React.SyntheticEvent<Element, KeyboardEvent> => {
  return e.type === 'keydown';
};

type HooksAutocompleteProps = {
  setHookFilter: (hooks: Array<string>) => void;
  value: string[];
  extensions: Array<Hook> | undefined;
  isFetchingExtensions: boolean;
  isLoadingExtensions: boolean;
  width: number;
};

const MENU_LIST_MIN_HEIGHT = 450;
const MENU_CONTENT_MAX_WIDTH = 600;

export const HooksAutocomplete = ({
  extensions = [],
  isFetchingExtensions,
  isLoadingExtensions,
  value = [],
  setHookFilter,
  width,
}: HooksAutocompleteProps) => {
  const intl = useIntl();
  const hasValue = !!value;

  const currentValue = R.pipe(
    extensions,
    R.filter(e => value.includes(`${e.id}`)),
    R.map(e => ({ value: `${e.id}`, label: e.name }))
  );

  const options = R.pipe(
    extensions,
    R.partition(e => value.includes(`${e.id}`)),
    R.flat(),
    R.map(e => ({ value: `${e.id}`, label: e.name }))
  );

  const existingNotFound = Boolean(
    extensions.length > 0 &&
      value &&
      value.some(v => !extensions.find(e => `${e.id}` === v))
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormControl variant="outlined" sx={{ width }} error={existingNotFound}>
        <Autocomplete
          size="small"
          multiple
          fullWidth
          value={currentValue}
          onChange={(e, newValue, _reason, details) => {
            if (_reason === 'removeOption') {
              if (isKeydownEvent(e)) {
                if (
                  (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) &&
                  e.nativeEvent.key === 'Backspace'
                ) {
                  setHookFilter([]);

                  return;
                }
              }
            }

            const alreadySelected =
              currentValue.find(v => v.value === details?.option.value) !==
              undefined;

            const hooksToFilter = alreadySelected
              ? R.pipe(
                  currentValue,
                  R.filter(v => v.value !== details?.option.value),
                  R.map(v => v.value),
                  R.unique()
                )
              : R.pipe(
                  newValue,
                  R.map(v => v.value),
                  R.unique()
                );
            setHookFilter(hooksToFilter);
          }}
          disableClearable
          disableCloseOnSelect
          renderInput={params => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: 'containers.settings.extensions.logs.filters.extension.label',
              })}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  isLoadingExtensions && hasValue ? (
                    <Skeleton variant="text" width="60%" />
                  ) : (
                    params.InputProps.startAdornment
                  ),
                endAdornment: isFetchingExtensions ? (
                  <Stack sx={{ mr: 0 }}>
                    <CircularProgress size={20} />
                    {params.InputProps.endAdornment}
                  </Stack>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
          renderTags={(selectedOptions, getTagProps) => {
            const firstOption = selectedOptions[0];
            const { className, ...tagProps } = getTagProps({ index: 0 });

            if (firstOption) {
              return (
                <>
                  <Chip
                    {...tagProps}
                    label={firstOption.label}
                    sx={{ maxWidth: 60, overflow: 'hidden', margin: '3px' }}
                  />
                  {selectedOptions.length > 1 ? (
                    <Chip label={`+${selectedOptions.length - 1}`} />
                  ) : null}
                </>
              );
            }

            return null;
          }}
          ListboxProps={{
            style: {
              maxHeight: MENU_LIST_MIN_HEIGHT,
            },
          }}
          PaperComponent={({ children }) => (
            <Paper
              elevation={6}
              sx={{
                maxHeight: MENU_LIST_MIN_HEIGHT,
                maxWidth: MENU_CONTENT_MAX_WIDTH,
                width: 'fit-content',
                [`& .${paperClasses.root}`]: {},
              }}
            >
              {children}
            </Paper>
          )}
          options={options}
          getOptionLabel={option =>
            option.value.length ? `${option.value}: ${option.label}` : ''
          }
          isOptionEqualToValue={(option, newValue) =>
            option.value === newValue.value
          }
          renderOption={(props, option, _state, ownerState) => {
            // eslint-disable-next-line react/prop-types
            const { key, ...optionProps } = props;

            return (
              <MenuItem
                key={key}
                value={`${option.value}`}
                data-cy={`logs-hook-menu-item-${option.value}`}
                {...optionProps}
              >
                {ownerState.getOptionLabel(option)}
              </MenuItem>
            );
          }}
          disabled={isLoadingExtensions}
          data-cy="extensions-logs-select"
        />
        {existingNotFound ? (
          <FormHelperText>
            {intl.formatMessage({
              id: 'containers.settings.extensions.logs.filters.extension.notFoundError',
            })}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Stack>
  );
};
