import { FieldLabel, FieldLabelProps } from '@rossum/ui';
import { FormHelperText, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useWysiwygHtmlDecorator } from '../../../../containers/Queue/components/TemplateMessageEditor/hooks';
import { ControlledFieldProps, LabeledFieldProps } from '../../utils';
import styles from './styles.module.sass';
import editorToolbarConfig from './wysiwygControlToolbarConfig';

type WysiwygControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControlledFieldProps<TFieldValues, TName> &
  LabeledFieldProps & {
    // this has to be passed explicitly now
    initialValue: string;
    disabled?: boolean;
    error?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    required?: boolean;
    customButtons?: EditorProps['toolbarCustomButtons'];
  };

const WysiwygControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ControllerProps,
  FieldLabelProps,
  initialValue,
  disabled,
  error,
  required,
  onFocus,
  onBlur,
  customButtons,
}: WysiwygControlProps<TFieldValues, TName>) => {
  const { control, name } = ControllerProps;

  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: name,
    required,
    ...FieldLabelProps,
    label: FieldLabelProps?.label ?? name,
  };

  const [, editorState, setEditorState] = useWysiwygHtmlDecorator({
    msgString: initialValue,
  });

  // What is this
  // eslint-disable-next-line react/hook-use-state
  const [_focused, setFocused] = useState(false);

  const wysiwygFocused = () => {
    if (!disabled) {
      setFocused(true);
      onFocus?.();
    }
  };

  const wysiwygBlurred = () => {
    if (!disabled) {
      setFocused(false);
      onBlur?.();
    }
  };

  return (
    <FieldLabel {...resolvedFieldLabelProps}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState, formState }) => (
          <Stack spacing={1}>
            <Editor
              editorRef={field.ref}
              editorState={editorState}
              onFocus={() => wysiwygFocused()}
              onBlur={() => wysiwygBlurred()}
              onEditorStateChange={editorState => {
                field.onChange(
                  draftToHtml(convertToRaw(editorState.getCurrentContent()))
                );
                setEditorState(editorState);
              }}
              toolbarClassName={clsx(
                styles.EditorToolbar,
                disabled && styles.EditorToolbarDisabled
              )}
              editorClassName={clsx(
                styles.EditorContent,
                error && styles.EditorContentError,
                (formState.isSubmitting || disabled) &&
                  styles.EditorContentDisabled
              )}
              wrapperClassName={styles.EditorWrapper}
              toolbar={editorToolbarConfig}
              readOnly={formState.isSubmitting || disabled}
              toolbarCustomButtons={customButtons ?? []}
            />
            {fieldState?.error && (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            )}
          </Stack>
        )}
      />
    </FieldLabel>
  );
};

export const WysiwygControl2 = ({
  value,
  onChange,
  disabled,
}: {
  value: EditorState;
  onChange: (editorState: EditorState) => void;
  disabled?: boolean;
}) => {
  return (
    <Editor
      editorState={value}
      onEditorStateChange={editorState => {
        onChange(editorState);
      }}
      toolbarClassName={clsx(
        styles.EditorToolbar,
        disabled && styles.EditorToolbarDisabled
      )}
      editorClassName={clsx(styles.EditorContent)}
      wrapperClassName={styles.EditorWrapper}
      toolbar={editorToolbarConfig}
    />
  );
};

export default WysiwygControl;
