import { Organization } from '@rossum/api-client/organization';
import { IconBuilding, IconChevronRight } from '@rossum/ui/icons/tabler';
import {
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import CopyToClipboardButton from '../../../ui/copy-to-clipboard/CopyToClipboardButton';
import { OrganizationsMenu } from './OrganizationsMenu';

const COPY_TO_CLIPBOARD_BUTTON_CLASS = 'copy-to-clipboard-button';

type OrganizationSwitcherProps = {
  organization: Organization;
  organizations: Organization[] | undefined;
  organizationsAreLoading: boolean;
};

export const OrganizationSwitcher = ({
  organization,
  organizations,
  organizationsAreLoading,
}: OrganizationSwitcherProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isSwitcherEnabled =
    !organizationsAreLoading && organizations && organizations.length > 1;

  const intl = useIntl();

  return (
    <Stack
      position="relative"
      data-cy="personal-organization-info"
      onMouseLeave={() => setIsMenuOpen(false)}
      onMouseEnter={() => {
        if (isSwitcherEnabled) setIsMenuOpen(true);
      }}
    >
      <MenuItem
        disableRipple
        sx={{
          backgroundColor: t =>
            isMenuOpen ? t.palette.action.hover : 'transparent',
          '&:hover': {
            backgroundColor: t =>
              isSwitcherEnabled ? t.palette.action.hover : 'transparent',
          },
        }}
      >
        <Stack spacing={1} py={1} width={1}>
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.personalInfo.organizations.title',
            })}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <SvgIcon
              sx={{ fill: 'none' }}
              component={IconBuilding}
              color="action"
              fontSize="small"
            />
            <Stack
              width={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: 220,
                    textWrap: 'wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {organization.name}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  sx={{
                    [`&:hover .${COPY_TO_CLIPBOARD_BUTTON_CLASS}`]: {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {`ID ${organization.id}`}
                  </Typography>
                  <Box
                    className={COPY_TO_CLIPBOARD_BUTTON_CLASS}
                    sx={{
                      opacity: 0,
                      transition: t => t.transitions.create(['opacity']),
                    }}
                  >
                    <CopyToClipboardButton
                      content={`${organization.id}`}
                      iconColor="secondary"
                    />
                  </Box>
                </Stack>
              </Stack>
              {isSwitcherEnabled ? (
                <SvgIcon
                  sx={{ fill: 'none' }}
                  component={IconChevronRight}
                  color="secondary"
                  fontSize="small"
                />
              ) : organizationsAreLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </MenuItem>
      {isSwitcherEnabled ? (
        <OrganizationsMenu
          currentOrganization={organization}
          organizations={organizations}
          isMenuOpen={isMenuOpen}
        />
      ) : null}
    </Stack>
  );
};
