import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import { ElisClientError, HttpError } from '../errors';
import { RequestError } from '../errors/requestError';
import { ElisClientConfig } from './client';
import { buildAxiosConfig, ElisRequestConfig } from './requestConfig';
import { asDecoded } from './requestResponse';

const axiosRequest = (config: AxiosRequestConfig) =>
  TE.tryCatch<ElisClientError, AxiosResponse<unknown>>(
    () => axios.request(config),
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        // We know that axios config always has URL.
        const url = config.url!;
        return error.response
          ? new HttpError({
              endpoint: url,
              method: config.method,
              statusCode: error.response.status,
              data: error.response.data,
              headers: error.response.headers,
            })
          : new RequestError({
              endpoint: url,
              message: `${error.message}\ncode:${error.code}`,
            });
      }
      // This is absolutely not supposed to happen.
      return new RequestError({
        endpoint: '',
        message: 'Invalid request error',
      });
    }
  );

export const request = <R, Q, P>(
  clientConfig: ElisClientConfig,
  requestConfig: ElisRequestConfig<R, Q, P>
) =>
  new Promise<R>((resolve, reject) =>
    pipe(
      buildAxiosConfig(clientConfig, requestConfig),
      TE.fromEither,
      TE.chain(axiosRequest),
      TE.map(x => x.data),
      TE.chainEitherK(asDecoded(clientConfig, requestConfig))
    )().then(
      E.fold(
        e => reject(e),
        d => resolve(d)
      )
    )
  );

export const fullRequest = <R, Q, P>(
  clientConfig: ElisClientConfig,
  requestConfig: ElisRequestConfig<R, Q, P>
) =>
  new Promise<AxiosResponse<R>>((resolve, reject) =>
    pipe(
      buildAxiosConfig(clientConfig, requestConfig),
      TE.fromEither,
      TE.chain(axiosRequest),
      TE.chainEitherK(response =>
        pipe(
          asDecoded(clientConfig, requestConfig)(response.data),
          E.map(data => ({ ...response, data }))
        )
      )
    )().then(
      E.fold(
        e => reject(e),
        d => resolve(d)
      )
    )
  );
