import { Divider, Stack } from '@rossum/ui/material';
import MailIcon from 'mdi-react/EmailOutlineIcon';
import WebhookIcon from 'mdi-react/WebhookIcon';
import { ComponentType } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as R from 'remeda';
import { docsLinks } from '../../../../../constants/values';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import styles from './styles.module.sass';

type Setting = {
  Icon: ComponentType;
  translationId: 'inbox' | 'webhooks';
  link?: string;
  Button?: () => JSX.Element;
};

type Props = { inboxExists: boolean };

const settings = ({ inboxExists }: Props): Array<Setting> =>
  R.pipe(
    [
      !inboxExists && {
        Icon: MailIcon,
        translationId: 'inbox' as const,
        link: docsLinks.inbox,
      },
      {
        Icon: WebhookIcon,
        translationId: 'webhooks' as const,
        link: docsLinks.webhooks,
      },
    ],
    R.filter(R.isTruthy)
  );

const Advanced = ({ inboxExists }: Props) => {
  const intl = useIntl();

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.advanced',
      })}
    >
      <Stack spacing={2} divider={<Divider />}>
        {settings({ inboxExists }).map(
          ({ Icon, Button, translationId, link }) => (
            <div
              className={styles.Row}
              key={`advanced-section-${translationId}`}
            >
              <div>
                <div className={styles.Icon}>
                  <Icon />
                </div>
              </div>
              <div>
                <div className={styles.Title}>
                  <FormattedMessage
                    id={`containers.settings.queues.advanced.${translationId}.title`}
                  />
                </div>
                <div className={styles.Text}>
                  <FormattedMessage
                    id={`containers.settings.queues.advanced.${translationId}.text`}
                  />
                </div>
              </div>

              <div>
                {link && (
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.DocLink}
                    data-cy="basic-settings-read-more"
                  >
                    <FormattedMessage id="containers.settings.queues.advanced.link" />
                  </a>
                )}
                {Button && <Button />}
              </div>
            </div>
          )
        )}
      </Stack>
    </PaperSection>
  );
};

export default Advanced;
