import { getIDFromUrl } from '@rossum/api-client';
import {
  Collapse,
  Dialog,
  DialogContent,
  List,
  Stack,
} from '@rossum/ui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import { AnnotationProgress } from './AnnotationProgress';
import { AsyncTaskProgress } from './AsyncTaskProgress';
import { classifyTasks } from './helpers/classifyTasks';
import {
  getTaskDialogCounter,
  getTaskDialogTitle,
} from './helpers/getTaskDialogTitle';
import { useTaskContext } from './TaskContext';
import { UploadsTaskProgress } from './UploadsTaskProgress';
import { useImportingAnnotations } from './useImportingAnnotations';

type TasksDialogProps = {
  // TODO: This could use a nicer, more generic API, currently implementing just for the importing tasks
  onTaskFinished?: (taskType: 'importTask', payload: number[]) => void;
};

export const TasksDialog = ({ onTaskFinished }: TasksDialogProps) => {
  const intl = useIntl();
  const {
    tasks: { asyncTasks, uploadsTasks, importTasks },
    clearAllTasks,
  } = useTaskContext();

  const {
    childAnnotationsQuery: { data: childAnnotations },
    uploadedAnnotationsQuery: { data: uploadedAnnotations },
  } = useImportingAnnotations({
    annotationUrls: importTasks.map(importTask => importTask.annotationUrl),
  });

  // save annotations that were already imported last render
  const previouslyProcessedAnnotations = useRef<number[]>([]);

  // This effect notifies the parent that some annotation imports have finished
  // parent refetches the dashboard data if they intersect with data currently visible
  useEffect(() => {
    const allProcessedAnnotations = R.pipe(
      uploadedAnnotations ?? [],
      R.concat(childAnnotations ?? []),
      R.filter(annotation => annotation.annotationStatus !== 'importing'),
      R.map(annotation => getIDFromUrl(annotation.annotationUrl))
    );

    const newlyProcessedAnnotations = R.difference(
      allProcessedAnnotations,
      previouslyProcessedAnnotations.current
    );

    if (newlyProcessedAnnotations.length > 0) {
      onTaskFinished?.('importTask', newlyProcessedAnnotations);
    }

    previouslyProcessedAnnotations.current = R.pipe(
      [...previouslyProcessedAnnotations.current, ...newlyProcessedAnnotations],
      // just in case
      R.unique()
    );
  }, [childAnnotations, onTaskFinished, uploadedAnnotations]);

  const [dialogCollapsed, setDialogCollapsed] = useState(false);

  const allTasks = useMemo(() => {
    return R.sortBy(
      [
        ...(childAnnotations ?? []).map(
          annotationInfo =>
            ({
              type: 'childAnnotation',
              key: `annotation-${annotationInfo.annotationUrl}`,
              taskData: annotationInfo,
              name: annotationInfo.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
        ...importTasks.map(importTask => {
          const freshInfo = uploadedAnnotations?.find(
            annotation => annotation.annotationUrl === importTask.annotationUrl
          );
          return {
            type: 'importTask',
            key: `annotation-${importTask.annotationUrl}`,
            taskData: { ...importTask, ...freshInfo },
            name: importTask.fileName?.toLowerCase() ?? '-',
          } as const;
        }),
        ...asyncTasks.map(
          (task, index) =>
            ({
              type: 'asyncTask',
              key: task.data ? `async-task-${task.data.id}` : `index-${index}`,
              taskData: task,
              name: task.data?.content?.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
        ...uploadsTasks.map(
          task =>
            ({
              type: 'uploadTask',
              key: `uploads-task-${task.taskId}`,
              taskData: task,
              name: task.data.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
      ],
      [R.prop('name'), 'asc']
    );
  }, [
    asyncTasks,
    childAnnotations,
    importTasks,
    uploadedAnnotations,
    uploadsTasks,
  ]);

  const tasks = classifyTasks({
    importTasks,
    asyncTasks,
    uploadsTasks,
    childAnnotations,
    uploadedAnnotations,
  });

  const intlId = getTaskDialogTitle(tasks);
  const counter = getTaskDialogCounter(tasks);

  return (
    <Dialog
      open={tasks.length > 0}
      hideBackdrop
      disableAutoFocus
      disableEscapeKeyDown
      disableRestoreFocus
      disableScrollLock
      disableEnforceFocus
      sx={{
        position: 'initial',
      }}
      PaperProps={{
        'data-cy': 'async-tasks-dialog',
        sx: {
          position: 'fixed !important',
          bottom: -30,
          right: 0,
          zIndex: theme => theme.zIndex.drawer,
          width: 430,
        },
        elevation: 2,
      }}
    >
      <DialogTitle
        title={`${intl.formatMessage({ id: intlId })} (${counter}/${
          tasks.length
        })`}
        onClose={() => clearAllTasks()}
        onToggleMinimize={() => setDialogCollapsed(collapsed => !collapsed)}
      />
      <Collapse in={!dialogCollapsed} unmountOnExit>
        <DialogContent
          sx={{ p: '0px !important', overflow: 'auto', maxHeight: '300px' }}
        >
          <Stack spacing={3}>
            <List sx={{ p: 1 }}>
              <Stack spacing={0}>
                {allTasks.map(task => {
                  switch (task.type) {
                    case 'childAnnotation':
                    case 'importTask':
                      return (
                        <AnnotationProgress
                          key={task.key}
                          importTask={task.taskData}
                        />
                      );
                    case 'asyncTask':
                      return (
                        <AsyncTaskProgress
                          key={task.key}
                          task={task.taskData}
                        />
                      );

                    case 'uploadTask':
                      return (
                        <UploadsTaskProgress
                          key={task.key}
                          task={task.taskData}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </Stack>
            </List>
          </Stack>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
};
