import { Hook } from '@rossum/api-client/hooks';
import { Code } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { isPublicFunction } from '../../helpers';
import styles from '../../style.module.sass';

type Props = {
  selectedExtension: Hook;
  isFromStore: boolean;
  routeUrl: string;
};

const ViewCodePart = ({ routeUrl, selectedExtension, isFromStore }: Props) => {
  const location = useLocation();

  if (!isPublicFunction(selectedExtension)) {
    return null;
  }

  return (
    <div className={styles.UpperPart}>
      {isFromStore && (
        <>
          <div className={styles.UpperPartTitle}>
            <FormattedMessage id="containers.settings.extensions.fromStoreTitle" />
          </div>
          <div
            className={clsx(styles.FromStoreDescription, styles.FromStoreNote)}
          >
            <FormattedMessage id="containers.settings.extensions.fromStoreNote" />
          </div>
        </>
      )}
      <div className={styles.UpperPartEdit}>
        <div className={styles.FunctionControls}>
          <Button
            component={Link}
            to={{ pathname: `${routeUrl}/code`, state: location.state }}
            variant="outlined"
            color="secondary"
            startIcon={<Code />}
            data-cy="extensions-edit-code-button"
            sx={{
              '&:hover': {
                color: theme => theme.palette.secondary.main,
              },
            }}
          >
            <FormattedMessage
              id={
                isFromStore
                  ? 'containers.settings.extensions.function.viewTestCode'
                  : 'containers.settings.extensions.function.editCode'
              }
            />
          </Button>
          {get(selectedExtension, 'config.code') === '' && (
            <span
              className={styles.FunctionEmpty}
              data-cy="extensions-function-empty-message"
            >
              <FormattedMessage id="containers.settings.extensions.function.editCode.empty" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCodePart;
