import { IconLayoutRows } from '@rossum/ui/icons/tabler';
import { Chip } from '@rossum/ui/material';

type MultivalueChipProps = {
  childrenCount: number;
};

export const MultivalueChip = ({ childrenCount }: MultivalueChipProps) => {
  return (
    <Chip
      icon={<IconLayoutRows size={16} />}
      label={childrenCount}
      sx={{ float: 'right' }}
    />
  );
};
