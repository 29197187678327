import { Close } from '@rossum/ui/icons';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  paperClasses,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
} from '@rossum/ui/material';
import { kebabCase } from 'lodash';
import { useIntl } from 'react-intl';
import {
  activeLabelStyle,
  activeOutlineStyle,
} from '../../../../components/UI/SingleQueueSelect/styles';

type FilterId = 'pageSize' | 'logLevel' | 'statusCode';

type Props = {
  isLoadingExtensions: boolean;
  filterId: FilterId;
  width?: number;
  selectProps: {
    value: SelectProps<string | string[]>['value'];
    options: string[];
    multiple: boolean;
  };
  onChange: (
    event: SelectChangeEvent<string | string[]>,
    filterType: FilterId
  ) => void;
  onClear: (filterType: FilterId, isMultiple: boolean) => void;
};

const FilterSelect = ({
  isLoadingExtensions,
  onChange,
  filterId,
  onClear,
  width,
  selectProps,
}: Props) => {
  const intl = useIntl();
  const FILTER_SELECT_WIDTH = width || 180;
  const { value, options, multiple } = selectProps;
  const hasValue = Array.isArray(value) ? !!value?.length : !!value;
  const fallBackValue = filterId === 'pageSize' ? '' : [];
  const label = kebabCase(filterId);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormControl variant="outlined" sx={{ width: FILTER_SELECT_WIDTH }}>
        <InputLabel
          sx={hasValue && !isLoadingExtensions ? activeLabelStyle : {}}
          id={`${label}-logs-label`}
          size="small"
        >
          {intl.formatMessage({
            id: `containers.settings.extensions.logs.filters.${filterId}.label`,
          })}
        </InputLabel>

        <Select
          labelId={`${label}-logs-label`}
          label={intl.formatMessage({
            id: `containers.settings.extensions.logs.filters.${filterId}.label`,
          })}
          size="small"
          fullWidth
          multiple={multiple}
          value={hasValue ? value : fallBackValue}
          sx={hasValue && !isLoadingExtensions ? activeOutlineStyle : {}}
          data-cy={`${label}-logs-select`}
          onChange={e => onChange(e, filterId)}
          disabled={isLoadingExtensions}
          endAdornment={
            hasValue ? (
              <Stack
                sx={{ position: 'absolute', right: 30 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <IconButton
                  size="small"
                  onClick={_ => onClear(filterId, multiple)}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Stack>
            ) : null
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              [`& .${paperClasses.root}`]: {
                width: 'fit-content',
              },
            },
          }}
        >
          {options.map(optionValue => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export { FilterSelect };
