import {
  AddRounded,
  CreateNewFolderOutlined,
  PostAddOutlined,
} from '@rossum/ui/icons';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NonAdminRestrictor } from '../../../../components/Restrictors';
import { getSidebarListItemStyles } from '../styles';

type Props = {
  handleOnClick: () => void;
  isActive: boolean;
  onAddQueueClick: () => void;
  onAddWorkspaceClick: () => void;
};

const AllDocumentItem = memo(
  ({
    handleOnClick,
    isActive,
    onAddQueueClick,
    onAddWorkspaceClick,
  }: Props) => {
    const intl = useIntl();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const addNewMenuItems = [
      {
        key: 'add-queue',
        label: intl.formatMessage({
          id: 'components.queueWorkspaceControl.add.newQueue',
        }),
        icon: (
          <PostAddOutlined
            fontSize="small"
            sx={{ transform: 'rotateX(180deg)' }}
          />
        ),
        onClick: () => {
          onAddQueueClick();
          setAnchorEl(null);
        },
      },
      {
        key: 'add-workspace',
        label: intl.formatMessage({
          id: 'components.queueWorkspaceControl.add.newWorkspace',
        }),
        icon: <CreateNewFolderOutlined fontSize="small" />,
        onClick: () => {
          onAddWorkspaceClick();
          setAnchorEl(null);
        },
      },
    ];

    return (
      <Stack
        data-cy="all-documents-sidebar"
        sx={{
          cursor: 'pointer',
          ...getSidebarListItemStyles(isActive),
        }}
        onClick={handleOnClick}
      >
        <Stack
          px={2}
          py={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">
            {intl.formatMessage({
              id: 'containers.settings.sidebar.allDocuments',
            })}
          </Typography>
          <NonAdminRestrictor>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {addNewMenuItems.map(({ key, onClick, label, icon }) => (
                <MenuItem key={key} data-cy={key} onClick={onClick}>
                  <Stack flexDirection="row" gap={1}>
                    {icon}
                    <Typography variant="body2">{label}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              data-cy="add-workspace-queue-control"
              onClick={e => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
              size="small"
              color="secondary"
            >
              <AddRounded />
            </IconButton>
          </NonAdminRestrictor>
        </Stack>
      </Stack>
    );
  }
);

export { AllDocumentItem };
