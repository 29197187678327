import { ExternalToast, toast, Toaster } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { ToasterMessage, ToasterMessageProps } from './ToasterMessage';
import { Severity } from './types';

const DEFAULT_TIMEOUT = 6000;

const renderComponent =
  (severity: Severity) =>
  (
    {
      title,
      description,
      isDismissible = true,
      onClose,
      toastAction,
      id = uuidv4(),
    }: Omit<ToasterMessageProps, 'severity'>,
    options?: ExternalToast
  ) => {
    toast.custom(
      toastId => (
        <ToasterMessage
          title={title}
          description={description}
          isDismissible={isDismissible}
          severity={severity}
          onClose={() => {
            onClose?.();
            toast.dismiss(toastId);
          }}
          toastAction={toastAction}
        />
      ),
      {
        id,
        duration: options?.duration ?? DEFAULT_TIMEOUT,
        ...options,
      }
    );
  };

export const notify = {
  info: renderComponent('info'),
  success: renderComponent('success'),
  error: renderComponent('error'),
  warning: renderComponent('warning'),
};

export const ToasterContainer = () => (
  <Toaster
    position="bottom-center"
    style={{ width: 600, display: 'flex', justifyContent: 'center' }}
  />
);
