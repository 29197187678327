import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_ORGANIZATIONS_WITH_MEMBERSHIP = 'organizations-with-membership';

export const useUnpaginatedOrganizationsWithMembership = () => {
  return useQuery({
    queryKey: [QUERY_KEY_ORGANIZATIONS_WITH_MEMBERSHIP] as const,
    queryFn: () =>
      api.unpaginatedRequest(endpoints.organization.list)({
        includeMembershipOrganizations: true,
      }),
  });
};
