import { Stack } from '@rossum/ui/material';
import DocumentFileName from './DocumentFileName';

const minimizeFilenameString = (
  string: string,
  max: number,
  separator: Array<string> = ['.', '.', '.']
) => {
  const array = string.split('');
  const halfMax = Math.floor(max / 2);
  return string.length > max
    ? [
        ...array.slice(0, halfMax),
        ...separator,
        ...array.slice(array.length - halfMax, array.length),
      ].join('')
    : string;
};

type Props = {
  disabled?: boolean;
  originalFilename: string | null | undefined;
  status?: string;
};

const Filename = ({ originalFilename, disabled = false, status }: Props) => {
  const name = minimizeFilenameString(originalFilename || '', 50);
  const [prefix, postfix] = name.split(/([^/]+)$/g);

  const fileName = (
    <>
      {prefix}
      <span>{postfix}</span>
    </>
  );

  return (
    <Stack
      data-cy="file-name"
      position="relative"
      whiteSpace="nowrap"
      sx={
        disabled && (status === 'failedImport' || status === 'importing')
          ? {
              color: 'text.disabled',
              cursor: status === 'importing' ? 'wait' : 'not-allowed',
              textDecoration: 'none',
            }
          : {}
      }
    >
      {originalFilename ? (
        fileName
      ) : (
        <DocumentFileName originalFileName={originalFilename} />
      )}
    </Stack>
  );
};

export default Filename;
