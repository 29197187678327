import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import {
  IconCheck,
  IconCircles,
  IconFileInvoice,
  IconFocusCentered,
  IconUsers,
} from '@rossum/ui/icons/tabler';
import { SvgIcon } from '@rossum/ui/material';
import { get, inRange, map, sum, values } from 'lodash';
import { connect } from 'react-redux';
import { omit } from 'remeda';
import { useUsers } from '../../../business/users/useUsers';
import { useExtensionsFeatureSubscription } from '../../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';
import { getMyExtensionsPathWithQuery } from '../../Extensions/lib/routes';
import Stats, { StatsType } from '../components/Stats';

type OwnProps = {
  selectedQueue: Queue;
};

type StateProps = {
  admins: Array<string>;
  enabledFieldsCount: number;
  fieldsCount: number;
};

type Props = StateProps & OwnProps;

// algoritmus
const getNumber = (n: number) =>
  ((
    [
      [0, n.toString()],
      [10000, '>10k'],
      [100000, '>100k'],
      [1000000, '>1M'],
      [10000000, '>10M'],
      [100000000, '>100M'],
    ] as const
  ).find(
    ([lowerLimit]) => n < 10000 || inRange(n, lowerLimit, lowerLimit * 10)
  ) ?? [null, '>1B'])[1];

const getDocumentsCount = (n: Record<string, number>) =>
  getNumber(sum(values(omit(n, ['purged', 'split']))));

const QueueStats = ({
  enabledFieldsCount,
  fieldsCount,
  selectedQueue,
  admins,
}: Props) => {
  const extensionsSubscription = useExtensionsFeatureSubscription();
  const { data } = useUsers({
    id: [...admins.map(getIDFromUrl), ...selectedQueue.users.map(getIDFromUrl)],
    deleted: false,
  });

  const accessRoute = `/queues/${selectedQueue.id}/settings/access`;
  const fieldsRoute = `/queues/${selectedQueue.id}/settings/fields`;

  const iconProps = {
    color: 'primary',
    sx: { fill: 'none' },
  } as const;

  const stats: StatsType[] = [
    {
      value: data?.pagination.total ?? 0,
      icon: <SvgIcon {...iconProps} component={IconUsers} />,
      title: 'containers.settings.stats.queue.assignedUsers' as const,
      type: 'assignedUsers',
      link: accessRoute,
    },
    {
      value: getDocumentsCount(selectedQueue.counts),
      icon: <SvgIcon {...iconProps} component={IconFileInvoice} />,
      title: 'containers.settings.stats.queue.documents' as const,
      type: 'numberOfDocuments',
      link: `/annotations/${selectedQueue.id}`,
    },
    {
      value: enabledFieldsCount,
      icon: <SvgIcon {...iconProps} component={IconCheck} />,
      title: 'containers.settings.stats.queue.fieldsEnabled' as const,
      type: 'fieldsEnabled',
      link: fieldsRoute,
    },
    {
      value: fieldsCount,
      icon: <SvgIcon {...iconProps} component={IconFocusCentered} />,
      title: 'containers.settings.stats.queue.fieldsTotal' as const,
      type: 'fieldsTotal',
      link: fieldsRoute,
    },
    ...(extensionsSubscription.canAccess
      ? [
          {
            value: selectedQueue.hooks.length,
            icon: <SvgIcon {...iconProps} component={IconCircles} />,
            title: 'containers.settings.stats.queue.extensionsTotal' as const,
            type: 'extensionsTotal',
            link: selectedQueue.hooks.length
              ? getMyExtensionsPathWithQuery({
                  queues: selectedQueue.id.toString(),
                })
              : undefined,
          },
        ]
      : []),
  ];

  return <Stats stats={stats} />;
};

const mapStateToProps = (state: State) => {
  const fields = get(state, 'schema.content', []).filter(
    (item: AnyDatapointSchema) => item.category === 'datapoint'
  );
  const enabledFieldsCount = fields.filter(
    (item: AnyDatapointSchema) => !item.hidden
  ).length;

  return {
    admins: map(organizationSelector(state).admins, 'url'),
    fieldsCount: fields.length,
    enabledFieldsCount,
  };
};

const ConnectedStats = connect<StateProps, null, OwnProps, State>(
  mapStateToProps
)(QueueStats);

export default (props: OwnProps) =>
  props.selectedQueue ? <ConnectedStats {...props} /> : null;
