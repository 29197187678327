import { useEffect, useMemo, useState } from 'react';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import { DevToolsSync } from '../DevToolsSync';
import { useSurveyIfEnabled } from '../hooks/useSurveyIfEnabled';
import { SurveyName } from '../surveysUuidMap';
import { researchCallSurveyBlockersSelector } from './blockersSelectors';
import { getResearchCallSurveyBlockersFromSurvey } from './dialogBlockers';
import { ResearchCallSurveyDialog } from './dialogs/ResearchCallSurveyDialog';

const SURVEY_NAME = 'researchCallSurvey' satisfies SurveyName;

const ResearchCallSurvey = ({
  surveyPlacement,
  blockersSelector = researchCallSurveyBlockersSelector,
}: {
  surveyPlacement: 'statistics' | 'documents' | 'queueSettings' | 'extensions';
  blockersSelector?: Parameters<
    typeof useSurveyIfEnabled
  >[0]['blockersSelector'];
}) => {
  const [userEngaged, setUserEngaged] = useState(false);

  const surveyInfo = useSurveyIfEnabled({
    surveyName: SURVEY_NAME,
    userEngaged,
    blockersSelector,
    getBlockersFromSurvey: getResearchCallSurveyBlockersFromSurvey,
  });

  // we have sufficient engagement for survey feedback after 10s spent w/ this component rendered
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUserEngaged(true);
    }, 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // For debugging.
  const surveyDevToolsAction = useMemo(
    () => ({
      type: `@@ELIS_DEVTOOLS/SET_SURVEY_STATE` as const,
      payload: [
        {
          label: 'Research Call Survey',
          ...surveyInfo,
        },
      ],
    }),
    [surveyInfo]
  );

  return (
    <>
      {DEV_FEATURES_ENABLED && <DevToolsSync action={surveyDevToolsAction} />}
      {surveyInfo.survey && (
        <ResearchCallSurveyDialog
          open={surveyInfo.shouldViewSurvey}
          survey={surveyInfo.survey}
          surveyPlacement={surveyPlacement}
        />
      )}
    </>
  );
};

export { ResearchCallSurvey };
