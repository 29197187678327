import {
  GridApiPro,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridSortItem,
  GridSortModel,
} from '@rossum/ui/x-data-grid-pro';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { isDeepEqual, pick } from 'remeda';
import { removeIdColumnKeys } from '../utils';
import { EditedRow, Row, RowWithIndex } from './types';

type Props = {
  apiRef: MutableRefObject<GridApiPro>;
  setEditedRows: Dispatch<SetStateAction<EditedRow[]>>;
  setSortItem: Dispatch<SetStateAction<GridSortItem | null>>;
  data: DSResultsResponseResultsItem[] | undefined;
  rowsWithId: Row[];
  columns: GridColDef[];
};

export const useTableHandlers = ({
  apiRef,
  setEditedRows,
  data,
  rowsWithId,
  setSortItem,
  columns,
}: Props) => {
  const areRowsEqual = useCallback(
    (newRow: Row, oldRow: Row) => {
      if (!data || !data[0]) return false;
      const columnKeys = removeIdColumnKeys(Object.keys(data[0]));

      return isDeepEqual(pick(newRow, columnKeys), pick(oldRow, columnKeys));
    },
    [data]
  );

  const handleProcessRowUpdate = useCallback(
    (newRow: RowWithIndex, oldRow: RowWithIndex) => {
      if (areRowsEqual(newRow, oldRow)) return newRow;

      const rowsToCompare = new Map(rowsWithId.map(r => [r.id, r]));
      const rowToCompare = rowsToCompare.get(newRow.id);

      if (rowToCompare && areRowsEqual(rowToCompare, newRow)) {
        setEditedRows(prev => prev.filter(r => r.id !== newRow.id));
        return newRow;
      }

      setEditedRows(prev => {
        const existing = prev.find(r => r.id === newRow.id);

        return existing
          ? prev.map(r =>
              r.id === existing.id ? { ...newRow, status: existing.status } : r
            )
          : [...prev, { ...newRow, status: 'edited' }];
      });

      return newRow;
    },
    [areRowsEqual, rowsWithId, setEditedRows]
  );

  const handleCellKeydown: GridEventListener<'cellKeyDown'> = useCallback(
    (params: GridCellParams<RowWithIndex>, event) => {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent default tabbing behavior

        const { field, id } = params;
        const columnFields = columns.map(col => col.field);
        const currentColumnIndex = columnFields.indexOf(field);

        const lastColumn = columnFields[columnFields.length - 1];
        const firstColumn = columnFields[0];
        const nextColumn = columnFields[currentColumnIndex + 1];
        const previousColumn = columnFields[currentColumnIndex - 1];

        if (!lastColumn || !firstColumn) return;

        const nextCellParams = event.shiftKey
          ? apiRef.current.getCellParams(id, previousColumn ?? lastColumn)
          : apiRef.current.getCellParams(id, nextColumn ?? firstColumn);

        if (nextCellParams) {
          apiRef.current.setCellFocus(nextCellParams.id, nextCellParams.field);

          // Navigate to the next row if at the edge
          if (
            (!event.shiftKey &&
              currentColumnIndex === columnFields.length - 1) ||
            (event.shiftKey && currentColumnIndex === 0)
          ) {
            const nextRowId = event.shiftKey
              ? apiRef.current.getRowIdFromRowIndex(params.row.index - 1)
              : apiRef.current.getRowIdFromRowIndex(params.row.index + 1);

            if (nextRowId) {
              apiRef.current.startRowEditMode({
                id: nextRowId,
                fieldToFocus:
                  columnFields[event.shiftKey ? columnFields.length - 1 : 0],
              });
            }
          }
        }
      }
    },
    [apiRef, columns]
  );

  const handleSortModelChange = useCallback(
    (sortModelList: GridSortModel) => {
      const model = sortModelList[0];
      if (model) setSortItem(model);
    },
    [setSortItem]
  );

  return { handleCellKeydown, handleProcessRowUpdate, handleSortModelChange };
};
