import {
  Button,
  CircularProgress,
  Fade,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useUnpaginatedHooks } from '../../../../business/hooks/useUnpaginatedHooks';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { ExtensionsHeader } from '../../../../containers/Extensions/ExtensionsHeader';
import { UnpaidFeatureOverlay } from '../../../pricing/components/UnpaidFeatureOverlay';
import { useExtensionLogsFeatureSubscription } from '../../../pricing/hooks/useExtensionsFeatureSubscription';
import { useLogsQuery } from '../hooks/useLogsQuery';
import { useRequestLogs } from '../hooks/useRequestLogs';
import { EmptyList } from './EmptyList';
import { Filters } from './Filters';
import { ListHead } from './ListHead';
import { LogsList } from './LogsList';
import { Skeletons } from './Skeletons';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const Logs = () => {
  const intl = useIntl();

  const { filters, setFilters } = useLogsQuery();

  const {
    data: logs,
    isFetching,
    isError,
    refetch: refetchLogs,
    isRefetching,
    dataUpdatedAt,
  } = useRequestLogs(filters);

  const {
    data: extensions,
    isLoading: isLoadingExtensions,
    isFetching: isFetchingExtensions,
    refetch: refetchExtensions,
  } = useUnpaginatedHooks({ pageSize: 100 });

  const { canAccess: isFeatureEnabled } = useExtensionLogsFeatureSubscription();

  const getContent = () => {
    if (isFetching) {
      return (
        <Stack spacing={0.75}>
          <ListHead />
          <Skeletons />
        </Stack>
      );
    }

    if (isError) {
      return <EmptyList translationKey="error" />;
    }

    if (logs) {
      const { results } = logs;
      return (
        <Stack sx={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Stack
            spacing={0.75}
            sx={{ minWidth: results.length ? 1200 : 'auto' }}
          >
            <LogsList
              logs={results}
              extensions={extensions || []}
              isLoadingExtensions={isLoadingExtensions}
            />
          </Stack>
        </Stack>
      );
    }

    return null;
  };

  return (
    <PageLayoutV2
      fullWidth={isFeatureEnabled}
      renderHeader={params => (
        <ExtensionsHeader
          {...params}
          value="logs"
          title={intl.formatMessage({
            id: 'containers.settings.extensions.logs',
          })}
          buttons={
            isFeatureEnabled
              ? [
                  <Stack
                    key="logs-header-buttons"
                    sx={{ ml: 'auto' }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    {!!dataUpdatedAt && (
                      <Fade in>
                        <Typography variant="body2" color="text.secondary">
                          {intl.formatMessage(
                            {
                              id: 'containers.settings.extensions.logs.dataUpdatedAt',
                            },
                            {
                              date: format(dataUpdatedAt, DATE_FORMAT),
                            }
                          )}
                        </Typography>
                      </Fade>
                    )}
                    <CircularProgress
                      size={14}
                      sx={{ visibility: isRefetching ? 'visible' : 'hidden' }}
                      data-cy="refresh-logs-progress"
                    />
                    <Button
                      onClick={() => {
                        refetchExtensions();
                        refetchLogs();
                      }}
                      data-cy="refresh-logs-button"
                      variant="outlined"
                    >
                      {intl.formatMessage({
                        id: 'containers.settings.extensions.logs.refetchLogs',
                      })}
                    </Button>
                  </Stack>,
                ]
              : []
          }
        />
      )}
    >
      {isFeatureEnabled ? (
        <Stack spacing={3} data-page-title="extension-logs" p={4}>
          {isFeatureEnabled ? (
            <Filters
              isLoadingExtensions={isLoadingExtensions}
              extensions={extensions}
              isFetchingExtensions={isFetchingExtensions}
              filters={filters}
              setFilters={setFilters}
            />
          ) : null}
          {getContent()}
        </Stack>
      ) : (
        <Stack component={Paper} m={4} p={4} justifyContent="center">
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensionLogs',
            })}
            dataCy="unpaid-overlay-extension-logs"
          />
        </Stack>
      )}
    </PageLayoutV2>
  );
};
