import { useRouteMatch } from 'react-router';
import { DATASET_ID_PARAM_KEY } from '../routes';
import { transformIdIntoName } from '../utils';

export const useGetDatasetParams = ({ route }: { route: string }) => {
  const match =
    useRouteMatch<Record<typeof DATASET_ID_PARAM_KEY, string>>(route);

  const currentDatasetId = match?.params.id;
  const currentDatasetName = currentDatasetId
    ? transformIdIntoName(currentDatasetId)
    : undefined;

  return { currentDatasetId, currentDatasetName };
};
