import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ExtensionEditor } from '../../features/extension-editor';
import { selectedExtensionSelector } from '../../redux/modules/extensions/selectors';
import {
  enterExtensionEditor,
  leaveExtensionEditor,
} from '../../redux/modules/ui/actions';
import { isPublicFunction } from '../Extension/helpers';
import { extensionDetailPath } from '../Extensions/helpers';

export const ExtensionEditorRoute = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const selectedExtension = useSelector(selectedExtensionSelector);
  const extensionHasEditor =
    selectedExtension && isPublicFunction(selectedExtension);

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    dispatch(enterExtensionEditor());

    return () => {
      dispatch(leaveExtensionEditor());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedExtension && !extensionHasEditor)
      replace({
        pathname: extensionDetailPath(selectedExtension.id),
      });
  }, [replace, extensionHasEditor, selectedExtension]);

  return extensionHasEditor ? (
    <ExtensionEditor selectedExtension={selectedExtension} />
  ) : null;
};
