import { Button, MenuItem, Stack, TextField } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useIntlDate } from '../../../i18n/useIntlDate';

type Period = {
  beginDate: string;
  endDate: string;
  values: {
    isCurrent: boolean;
  };
};

type BillingPeriodProps<T> = {
  periods: T[];
  currentPeriod: T;
  setCurrentPeriod: (period: T) => void;
};

const BillingPeriodPicker = <T extends Period>({
  periods,
  currentPeriod,
  setCurrentPeriod,
}: BillingPeriodProps<T>) => {
  const intl = useIntl();
  const { formatDate } = useIntlDate();

  const boundaries = (period: T) => [period.beginDate, period.endDate];
  const wrapPeriod = (period: T) => ({
    period,
    label: boundaries(period).map(formatDate).join(' - '),
    id: boundaries(period).join('-'),
  });

  const defaultPeriod = periods.find(period => period.values.isCurrent);
  const allowSetCurrent = defaultPeriod && !currentPeriod.values.isCurrent;

  const wrappedPeriods = periods.map(wrapPeriod);

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <TextField
        select
        value={wrapPeriod(currentPeriod).id}
        size="small"
        label={intl.formatMessage({
          id: 'containers.billing.overview.billingPeriod',
        })}
      >
        {wrappedPeriods.map(({ id, label, period }) => (
          <MenuItem
            key={id}
            value={id}
            onClick={() => setCurrentPeriod(period)}
          >
            {label}
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="text"
        color="secondary"
        onClick={() => defaultPeriod && setCurrentPeriod(defaultPeriod)}
        sx={{
          opacity: allowSetCurrent ? 1 : 0,
          visibility: allowSetCurrent ? 'visible' : 'hidden',
          transition: 'all 0.15s ease-in-out',
        }}
      >
        {intl.formatMessage({
          id: 'containers.billing.overview.selectCurrentPeriod',
        })}
      </Button>
    </Stack>
  );
};

export { BillingPeriodPicker };
