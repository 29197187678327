import { MenuItem, Select } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const granularities = ['day', 'week', 'month'] as const;
export type GranularityT = (typeof granularities)[number];

const isGranularity = (value: string): value is GranularityT =>
  granularities.includes(value as GranularityT);

type Props = {
  activeGranularity: GranularityT;
  setActiveGranularity: (granularity: GranularityT) => void;
};

const Granularity = ({ setActiveGranularity, activeGranularity }: Props) => {
  const intl = useIntl();

  return (
    <Select
      labelId={`${activeGranularity}-granularity`}
      size="small"
      fullWidth
      value={activeGranularity}
      data-cy="stats-granularity-dropdown"
      onChange={e =>
        isGranularity(e.target.value) && setActiveGranularity(e.target.value)
      }
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {granularities.map(value => (
        <MenuItem key={value} value={value}>
          {intl.formatMessage({
            id: `containers.statistics.granularities.${value}`,
          })}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Granularity;
