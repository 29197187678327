import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { DatasetsGuard, LabelsGuard } from '../../components/Restrictors';
import { DatasetsRoutes } from '../../features/datasets';
import { datasetsPath } from '../../features/datasets/routes';
import { fieldManagerPath } from '../../features/field-manager/constants';
import { FieldManagerRoutes } from '../../features/field-manager/field-manager';
import { labelsPath } from '../../features/labels/components/constants';
import { LabelList } from '../../features/labels/components/LabelList';
import { ruleTemplatesPath } from '../../features/rule-templates/paths';
import { RuleTemplatesRoute } from '../../features/rule-templates/RuleTemplatesRoutes';
import { automationPath } from '../Automation/helpers';
import { extensionsPath, extensionsStorePath } from '../Extensions/helpers';
import { usersPath } from '../Users/helpers';
import { UsersRoutes } from '../Users/UsersRoutes';
import { settingsPath } from './helpers';
import SettingsPage from './SettingsPage';

export const SettingsRoutes = (props: RouteChildrenProps) => {
  const { match: settingsRouteMatch } = props;

  if (!settingsRouteMatch) {
    return null;
  }

  return (
    <Switch>
      <Route path={settingsPath()} exact component={SettingsPage} />
      <Route path={usersPath()} component={UsersRoutes} />
      <Route path={fieldManagerPath()} component={FieldManagerRoutes} />
      <Route path={ruleTemplatesPath()} component={RuleTemplatesRoute} />
      <Route
        path={labelsPath()}
        render={() => (
          <LabelsGuard>
            <LabelList />
          </LabelsGuard>
        )}
      />
      <Route
        path={datasetsPath()}
        render={() => (
          <DatasetsGuard>
            <DatasetsRoutes />
          </DatasetsGuard>
        )}
      />
      {/* Remove next Switch with redirects when its year 2026+ */}
      <Switch>
        <Route
          path={`${settingsRouteMatch.path}/automation`}
          render={({ location }) => (
            <Redirect
              to={location.pathname.replace(
                `${settingsRouteMatch.path}/automation`,
                automationPath()
              )}
            />
          )}
        />
        <Route
          path={`${settingsRouteMatch.path}${extensionsPath()}`}
          render={({ location }) => (
            <Redirect
              to={location.pathname.replace(
                `${settingsRouteMatch.path}/extensions`,
                extensionsPath()
              )}
            />
          )}
        />
        <Route
          path={`${settingsRouteMatch.path}/store`}
          render={() => <Redirect to={extensionsStorePath()} />}
        />
      </Switch>
      <Route render={() => <Redirect to={settingsPath()} />} />
    </Switch>
  );
};
