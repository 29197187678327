import { IntlShape } from 'react-intl';
import { pipe } from 'remeda';
import { RefinementCtx, z } from 'zod';
import {
  triggerConditionOperators,
  triggerEvents,
} from '../mappers/templateMapper';
import { makeAnnotationVariable } from './EmailTemplateVariablesButton';
import {
  VARIABLE_CURRENT_USER_EMAIL,
  VARIABLE_SENDER,
} from './TemplateValueCheatsheetItem/cheatSheetConfig';

export const validateEmailWithVariables = (
  email: string,
  headerFields: { id: string }[]
) => {
  const parsed = z.string().email().safeParse(email);
  if (parsed.success) return true;
  return (
    email === VARIABLE_SENDER ||
    email === VARIABLE_CURRENT_USER_EMAIL ||
    headerFields.some(
      headerField => makeAnnotationVariable(headerField.id) === email
    )
  );
};

const triggerSchema = (intl: IntlShape) =>
  z
    .object({
      event: z.enum(triggerEvents),
      fields: z.array(z.string()),
      condition: z.object({
        field: z.string().nullable(),
        operator: z.enum(triggerConditionOperators),
        value: z.string(),
      }),
    })
    .nullable()
    .superRefine((data, ctx) => {
      if (data?.event === 'at_least_one_missing' && data?.fields.length < 1) {
        ctx.addIssue({
          code: 'custom',
          message: intl.formatMessage({
            id: 'components.emailTemplateForm.triggerFields.error',
          }),
        });
      }
    });

const emailSchema = (
  key: 'to' | 'cc' | 'bcc',
  intl: IntlShape,
  headerFields: { id: string }[],
  isOptional = false
) =>
  z.array(z.string()).superRefine((array, ctx) => {
    const shouldSkip = isOptional && array.length === 0;
    const isInvalid = array.some(
      email => !validateEmailWithVariables(email, headerFields)
    );

    if (isInvalid && !shouldSkip) {
      ctx.addIssue({
        code: 'custom',
        message: intl.formatMessage({
          id: `components.emailResponseForm.fields.edit.${key}.invalidEmail`,
        }),
      });
    }
  });

export const emailTemplateFormSchema = (
  intl: IntlShape,
  headerFields: { id: string }[]
) =>
  z
    .object({
      id: z.number().optional(),
      to: emailSchema('to', intl, headerFields),
      cc: emailSchema('cc', intl, headerFields, true),
      bcc: emailSchema('bcc', intl, headerFields, true),
      name: z.string({
        required_error: intl.formatMessage({
          id: 'components.emailTemplateForm.name.required',
        }),
      }),
      message: z.string().min(
        1,
        intl.formatMessage({
          id: 'components.emailTemplateForm.message.required',
        })
      ),
      subject: z.string({
        required_error: intl.formatMessage({
          id: 'components.emailTemplateForm.subject.required',
        }),
      }),
      trigger: triggerSchema(intl),
      automate: z.boolean(),
    })
    .superRefine((data, ctx) => {
      pipe({ data, ctx, intl }, validateAutomate, validateTrigger);
    });

export type EmailTemplateForm = z.TypeOf<
  ReturnType<typeof emailTemplateFormSchema>
>;

const validateAutomate = ({
  data,
  ctx,
  intl,
}: {
  intl: IntlShape;
  data: EmailTemplateForm;
  ctx: RefinementCtx;
}) => {
  if (
    data.to.length === 0 &&
    data.cc.length === 0 &&
    data.bcc.length === 0 &&
    data.automate
  ) {
    ctx.addIssue({
      path: ['automate'],
      message: intl.formatMessage({
        id: 'components.emailTemplateForm.automationError',
      }),
      code: 'custom',
    });
  }
  return {
    data,
    ctx,
    intl,
  };
};

const validateTrigger = ({
  data,
  ctx,
  intl,
}: {
  intl: IntlShape;
  data: EmailTemplateForm;
  ctx: RefinementCtx;
}) => {
  if (data.automate && data.trigger && !data.trigger?.event) {
    ctx.addIssue({
      path: ['trigger', 'event'],
      message: intl.formatMessage({
        id: 'components.emailTemplateForm.triggerEvent.error',
      }),
      code: 'custom',
    });
  }

  if (
    data.trigger?.event === 'at_least_one_missing' &&
    data.trigger.fields.length < 1
  ) {
    ctx.addIssue({
      path: ['trigger', 'fields'],
      message: intl.formatMessage({
        id: 'components.emailTemplateForm.triggerFields.error',
      }),
      code: 'custom',
    });
  }

  if (
    Boolean(data.trigger?.condition.field) &&
    !data.trigger?.condition.operator
  ) {
    ctx.addIssue({
      path: ['trigger', 'condition', 'operator'],
      message: intl.formatMessage({
        id: 'components.emailTemplateForm.triggerCondition.operator.error',
      }),
      code: 'custom',
    });
  }

  if (
    Boolean(data.trigger?.condition.field) &&
    !data.trigger?.condition.value
  ) {
    ctx.addIssue({
      path: ['trigger', 'condition', 'operator'],
      message: intl.formatMessage({
        id: 'components.emailTemplateForm.triggerCondition.value.error',
      }),
      code: 'custom',
    });
  }
  return {
    data,
    ctx,
    intl,
  };
};
