import { Stack, Typography } from '@rossum/ui/material';
import { QueryStatus } from '@tanstack/react-query';
import { TileLinkSkeleton } from './TileLinkSkeleton';

type TilesListProps<T> = {
  title: string;
  renderTile: (props: T) => React.ReactNode;
  items: T[] | undefined;
  status: QueryStatus;
  emptyState: React.ReactNode;
  errorState?: React.ReactNode;
  buttons?: React.ReactNode[];
};

const TilesList = <T,>({
  title,
  buttons,
  items,
  status,
  renderTile,
  emptyState,
  errorState,
}: TilesListProps<T>) => {
  const getContent = () => {
    if (status === 'loading') {
      return <TileLinkSkeleton />;
    }

    if (status === 'success' && items && items.length > 0) {
      return (
        <Stack spacing={2} alignItems="flex-end">
          <Stack spacing={0.5} width={1}>
            {items.map(item => renderTile(item))}
          </Stack>
          <Stack direction="row" gap={1}>
            {buttons ?? null}
          </Stack>
        </Stack>
      );
    }

    if (status === 'error' && errorState) {
      return errorState;
    }

    return emptyState;
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{title}</Typography>
      {getContent()}
    </Stack>
  );
};

export { TilesList };
