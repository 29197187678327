import { createReactComponent } from '@tabler/icons-react';

export const IconCustomBorderCornersCheck = createReactComponent(
  'outline',
  'customBorderCornersCheck',
  'CustomBorderCornersCheck',
  [
    [
      'path',
      {
        key: 'customBorderCornersCheck-path',
        d: 'M16 4H18C18.5306 4 19.0392 4.2112 19.5342 4.607C20.0291 5.0028 20.3333 5.552 20.3333 6V8M20.3333 16V18C20.3333 18.5306 20.1211 19.0392 19.7253 19.5342C19.3295 20.0291 18.7804 20.3333 18.3333 20.3333H16M8 20.3333H6C5.46933 20.3333 4.96073 20.1211 4.4658 19.7253C3.97086 19.3295 3.66667 18.7804 3.66667 18V16M3.66667 8V6C3.66667 5.552 3.97086 5.0028 4.4658 4.607C4.96073 4.2112 5.46933 4 6 4H8M9 12L11 14L15 10',
      },
    ],
  ]
);
