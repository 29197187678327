import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { DrawerConfig } from '../../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { isUserViewer } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { SidebarFooterAction } from './utils';

type UseEmailActionResult = {
  action: SidebarFooterAction | null;
};

export const useEmailAction = (
  annotation: Annotation,
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void
): UseEmailActionResult => {
  const intl = useIntl();

  const userIsViewer = useSelector(isUserViewer);

  const { restrictedAccess } = annotation;

  const handleEmailDocument = useCallback(() => {
    onEmailThreadOpen({
      defaultFormMode: 'forwardDocument',
      highlightedEmailUrl: annotation.email ?? undefined,
    });
  }, [annotation.email, onEmailThreadOpen]);

  const emailVisible = !isEmbedded() && !userIsViewer;

  const result = useMemo(() => {
    return {
      action: emailVisible
        ? {
            id: 'email' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.email',
            }),
            disabled: restrictedAccess,
            onClick: handleEmailDocument,
            dataCy: 'annotation-sidebar-send',
          }
        : null,
    };
  }, [emailVisible, handleEmailDocument, intl, restrictedAccess]);

  return result;
};
