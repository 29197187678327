import { CircularProgress, Stack, Tooltip } from '@rossum/ui/material';
import clsx from 'clsx';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import RefreshIcon from 'mdi-react/BackupRestoreIcon';
import ContentSave from 'mdi-react/ContentSaveOutlineIcon';
import PrettifyIcon from 'mdi-react/FormatAlignLeftIcon';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from '../styles.module.sass';
import DocumentationLinks from './DocumentationLinks';
import { TemporaryMessageT } from './hooks';
import InfoMessage, { EditorType } from './InfoMessage';

type Props = {
  title?: string | ReactNode;
  right: ReactNode;
  onPrettify: () => void;
  canPrettify: boolean;
  onSave: () => void;
  canSave: boolean;
  onReset: () => void;
  canReset: boolean;
  isValid: boolean;
  isChanged: boolean;
  onNavigateBack?: () => void;
  canNavigateBack?: boolean;
  dark?: boolean;
  editorType: EditorType;
  isPending?: boolean;
  temporaryMessage?: TemporaryMessageT;
  savingFailed?: boolean;
  isSaving?: boolean;
};

const TopMenu = ({
  canNavigateBack,
  canPrettify,
  canReset,
  canSave,
  dark,
  isChanged,
  isValid,
  onNavigateBack,
  onPrettify,
  onReset,
  onSave,
  right,
  title,
  editorType,
  isPending,
  temporaryMessage,
  savingFailed,
  isSaving,
}: Props) => {
  const intl = useIntl();
  return (
    <div className={clsx(styles.EditorHeader, dark && styles.EditorHeaderDark)}>
      <Stack
        direction="row"
        spacing={2.5}
        alignItems="center"
        className={styles.EditorControls}
      >
        {onNavigateBack && (
          <Tooltip
            title={intl.formatMessage({
              id: 'components.editor.tooltips.navigateBack',
            })}
          >
            <button
              onClick={onNavigateBack}
              className={clsx(
                styles.EditorIcon,
                canNavigateBack && styles.ActiveIcon
              )}
              disabled={!canNavigateBack}
              data-cy="exit-route-button"
            >
              <ArrowLeftIcon />
            </button>
          </Tooltip>
        )}
        <Tooltip
          title={
            canPrettify
              ? intl.formatMessage({
                  id: 'components.editor.tooltips.prettify',
                })
              : ''
          }
        >
          <button
            onClick={onPrettify}
            className={clsx(
              styles.EditorIcon,
              canPrettify && styles.ActiveIcon
            )}
            disabled={!canPrettify}
            data-cy="prettify-button"
          >
            <PrettifyIcon />
          </button>
        </Tooltip>
        <Tooltip
          title={intl.formatMessage({
            id: isPending
              ? 'components.editor.tooltips.save.pending'
              : 'components.editor.tooltips.save',
          })}
        >
          <button
            onClick={onSave}
            className={clsx(styles.EditorIcon, canSave && styles.ActiveIcon)}
            disabled={!canSave}
            data-cy="save-button"
          >
            {isSaving ? (
              <CircularProgress size={20} color="inherit" sx={{ mx: 0.25 }} />
            ) : (
              <ContentSave />
            )}
          </button>
        </Tooltip>
        <Tooltip
          title={intl.formatMessage({
            id: isPending
              ? 'components.editor.tooltips.reset.pending'
              : 'components.editor.tooltips.reset',
          })}
        >
          <button
            onClick={onReset}
            className={clsx(styles.EditorIcon, canReset && styles.ActiveIcon)}
            disabled={!canReset}
            data-cy="reset-button"
          >
            <RefreshIcon />
          </button>
        </Tooltip>
        <DocumentationLinks editorType={editorType} />
        <div>
          <InfoMessage
            isPending={isPending ?? false}
            editorType={editorType}
            isValid={isValid}
            isChanged={isChanged}
            temporaryMessage={temporaryMessage}
            savingFailed={savingFailed ?? false}
          />
        </div>
      </Stack>
      {title && <div className={styles.Title}>{title}</div>}
      <div className={styles.OnRight}>{right}</div>
    </div>
  );
};

export default TopMenu;
