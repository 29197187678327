import { floor } from 'lodash';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import { detectUserActivity } from './detectUserActivity';
import { inactiveIntervals } from './inactiveIntervals';
import { TimeInterval, timers } from './timers';

export type ProcessingDuration = {
  timeSpentActive: number;
  timeSpentOverall: number;
  timeSpentEdit: number;
  timeSpentEmails: number;
  timeSpentBlockers: number;
  timeSpentOpening: number;
};

type DatapointTimerMetadata = {
  timeSpent: number;
  timeSpentOverall: number;
  timeSpentGrid: number;
  timeSpentGridOverall: number;
  schemaName?: string;
};

const millisecondsToTimeSpentSeconds = (milliseconds: number): number => {
  const seconds = milliseconds / 1000;
  return floor(seconds, 2);
};

const log = (message: string, ...params: unknown[]) => {
  // eslint-disable-next-line no-console
  if (DEV_FEATURES_ENABLED) console.log(`⏱️ [TS] ${message}`, ...params);
};

const logAnnotationStart = (annotationId: number) => {
  log(`Start annotation %c${annotationId}`, 'font-weight:bold');
};

const logAnnotationOpened = (result: { interval: TimeInterval }) => {
  log(
    `Annotation opened in %c${millisecondsToTimeSpentSeconds(
      result.interval[1] - result.interval[0]
    )}s`,
    'font-weight:bold'
  );
};

const logAnnotationStop = (
  annotationId: number,
  result: { active: number; overall: number }
) => {
  log(
    `Stop annotation %c${annotationId} %cActive: %c+${millisecondsToTimeSpentSeconds(
      result.active
    )}s %cOverall: %c+${millisecondsToTimeSpentSeconds(result.overall)}s`,
    'font-weight:bold',
    'font-weight:normal',
    'font-weight:bold',
    'font-weight:normal',
    'font-weight:bold'
  );
};

const logDatapointStart = (name: string, timerName: string) => {
  log(
    `Start %c"${name}" %c(${timerName})`,
    'font-weight:bold',
    'font-weight:normal;color:gray'
  );
};

const logDatapointStop = (result: {
  active: number;
  overall: number;
  metadata?: DatapointTimerMetadata;
}) => {
  log(
    `Stop  %c"${
      result.metadata?.schemaName
    }" %cActive: %c+${millisecondsToTimeSpentSeconds(result.active)}s ${
      result.metadata?.timeSpentGrid
        ? `(Grid +${result.metadata.timeSpentGrid}s)`
        : ''
    } %cOverall: %c+${millisecondsToTimeSpentSeconds(result.overall)}s ${
      result.metadata?.timeSpentGridOverall
        ? `(Grid +${result.metadata.timeSpentGridOverall}s)`
        : ''
    }`,
    'font-weight:bold',
    'font-weight:normal',
    'font-weight:bold',
    'font-weight:normal',
    'font-weight:bold'
  );
};

const logMagicGridStart = (timerName: string) => {
  log(`Start magic grid %c(${timerName})`, 'font-weight:normal;color:gray');
};

const logMagicGridStop = (result: { active: number; overall: number }) => {
  log(
    `Stop magic grid Active: %c+${millisecondsToTimeSpentSeconds(
      result.active
    )}s %cOverall: %c+${millisecondsToTimeSpentSeconds(result.overall)}s`,
    'font-weight:bold',
    'font-weight:normal',
    'font-weight:bold'
  );
};

const logPageVisible = (result: TimeInterval) => {
  log(
    `Page became visible after %c${millisecondsToTimeSpentSeconds(
      result[1] - result[0]
    )}s`,
    'font-weight:bold'
  );
};

const logUserInactivity = (result: TimeInterval) => {
  log(
    `User was inactive for %c${millisecondsToTimeSpentSeconds(
      result[1] - result[0]
    )}s`,
    'font-weight:bold'
  );
};

const timerName = (datapointId: number) => `datapoint/${datapointId}`;

const calculateIntervals = <TMetadata>({
  interval,
  metadata,
}: {
  interval: TimeInterval;
  metadata: TMetadata;
}) => {
  const overall = interval[1] - interval[0];
  const active = overall - inactiveIntervals.getTotalInactiveTimeIn(interval);

  return { overall, active, metadata: metadata as TMetadata | undefined };
};

const stopAndCalculate = <TMetadata>(name: string) => {
  const result = timers.stop<TMetadata>(name);

  if (!result) return undefined;

  return calculateIntervals<TMetadata>(result);
};

const closeEmails = (annotationId: number) => {
  const result = stopAndCalculate('emails');

  if (!result) return;

  timers.updateMetadata(
    `annotation/${annotationId}`,
    (metadata: ProcessingDuration) => ({
      ...metadata,
      timeSpentEmails: millisecondsToTimeSpentSeconds(
        (metadata.timeSpentEmails ?? 0) * 1000 + result.active
      ),
    })
  );
};

const closeEditMode = (annotationId: number) => {
  const result = stopAndCalculate('edit-mode');

  if (!result) return;

  timers.updateMetadata(
    `annotation/${annotationId}`,
    (metadata: ProcessingDuration) => ({
      ...metadata,
      timeSpentEdit: millisecondsToTimeSpentSeconds(
        (metadata.timeSpentEdit ?? 0) * 1000 + result.active
      ),
    })
  );
};

const closeBlockers = (annotationId: number) => {
  const result = stopAndCalculate('blockers');

  if (!result) return;

  timers.updateMetadata(
    `annotation/${annotationId}`,
    (metadata: ProcessingDuration) => ({
      ...metadata,
      timeSpentBlockers: millisecondsToTimeSpentSeconds(
        (metadata.timeSpentBlockers ?? 0) * 1000 + result.active
      ),
    })
  );
};

// TODO support taking periodic measurments (not that later measurement can get lower then previous one)
const timeSpent = {
  startAnnotationOpening: () => {
    // User can start loading an annotation, but then go back, and open another one
    // In that case, we want to stop any previous timer, and start a new one.
    // That's why we don't store annotation ID in the timer's name
    timers.stop(`annotation-opening`);

    timers.start(`annotation-opening`);
  },

  stopAnnotationOpening: () => {
    const result = timers.stop(`annotation-opening`);

    if (!result) return undefined;

    logAnnotationOpened(result);

    return {
      timeSpentOpening: millisecondsToTimeSpentSeconds(
        result.interval[1] - result.interval[0]
      ),
    };
  },

  startAnnotation: (annotationId: number) => {
    logAnnotationStart(annotationId);

    timers.start(`annotation/${annotationId}`);

    const minStartTimestamp = timers.minStartTimestamp();

    if (minStartTimestamp) {
      inactiveIntervals.clearBefore(minStartTimestamp, 'user-inactivity');
      inactiveIntervals.clearBefore(minStartTimestamp, 'page-hidden');
    }
  },

  setAnnotationInitialProcessingDuration: (
    annotationId: number,
    processingDuration: ProcessingDuration
  ) => {
    timers.setMetadata(`annotation/${annotationId}`, processingDuration);
  },

  stopAnnotation: (
    annotationId: number
  ): { processingDuration: ProcessingDuration } | undefined => {
    closeEmails(annotationId);
    closeEditMode(annotationId);
    closeBlockers(annotationId);

    const result = stopAndCalculate(`annotation/${annotationId}`);

    // Due to complicated ways how the user can leave an annotation,
    // stopAnnotation can be triggered by multiple actions.
    // The first one to call stopAnnotation will do the update with real data,
    // all the other ones won't update anything.
    if (!result) {
      return undefined;
    }

    logAnnotationStop(annotationId, result);

    // If we don't have initial data, we don't update anything
    // to avoid overwriting existing data.
    if (!result.metadata) {
      // annotation timer should have metadata if it started properly
      // log whenever we are trying to stop a timer without metadata that did not bail earlier
      // TODO: Commented this out since it kept spamming Sentry, we can enable when needed
      // report(
      //   {
      //     status:
      //       '[TimeSpent]: Attempting to stop annotation timer without initial processing duration',
      //   },
      //   { timers: JSON.stringify(timers.snapshot()) }
      // );
      return undefined;
    }

    const initialProcessingDuration = result.metadata as ProcessingDuration;

    return {
      processingDuration: {
        timeSpentActive: millisecondsToTimeSpentSeconds(
          initialProcessingDuration.timeSpentActive * 1000 + result.active
        ),
        timeSpentOverall: millisecondsToTimeSpentSeconds(
          initialProcessingDuration.timeSpentOverall * 1000 + result.overall
        ),
        timeSpentEdit: initialProcessingDuration.timeSpentEdit,
        timeSpentEmails: initialProcessingDuration.timeSpentEmails,
        timeSpentBlockers: initialProcessingDuration.timeSpentBlockers,
        timeSpentOpening: initialProcessingDuration.timeSpentOpening,
      },
    };
  },

  userActivityWasDetected: () => {
    const result = inactiveIntervals.stop('user-inactivity');

    if (result) {
      logUserInactivity(result);
    }

    inactiveIntervals.start('user-inactivity');
  },

  pageVisibilityHasChanged: (isVisible: boolean) => {
    if (isVisible) {
      const result = inactiveIntervals.stop('page-hidden');

      if (result) {
        logPageVisible(result);
      }
    } else {
      log('Page become hidden');
      inactiveIntervals.start('page-hidden');
    }
  },

  openEditMode: () => timers.start('edit-mode'),

  closeEditMode,

  openEmails: () => timers.start('emails'),

  closeEmails,

  openBlockers: () => timers.start('blockers'),

  closeBlockers,

  openMagicGrid: (multivalueDatapointId: number) => {
    const timerName = `magic-grid/${multivalueDatapointId}`;

    timers.start(timerName);

    logMagicGridStart(timerName);
  },

  closeMagicGrid: (multivalueDatapointId: number): void => {
    const result = stopAndCalculate(`magic-grid/${multivalueDatapointId}`);

    if (!result) {
      return;
    }

    logMagicGridStop(result);

    timers.updateMetadata<DatapointTimerMetadata>(
      timerName(multivalueDatapointId),
      metadata => ({
        ...metadata,
        timeSpentGrid: millisecondsToTimeSpentSeconds(
          (metadata.timeSpentGrid ?? 0) * 1000 + result.active
        ),
        timeSpentGridOverall: millisecondsToTimeSpentSeconds(
          (metadata.timeSpentGridOverall ?? 0) * 1000 + result.overall
        ),
      })
    );
  },

  startAnyDatapoint: (
    datapoint: { id: number } & Partial<DatapointTimerMetadata>,
    schemaName: string
  ) => {
    const name = timerName(datapoint.id);

    logDatapointStart(schemaName, name);

    const metadata: DatapointTimerMetadata = {
      timeSpent: datapoint.timeSpent ?? 0,
      timeSpentOverall: datapoint.timeSpentOverall ?? 0,
      timeSpentGrid: datapoint.timeSpentGrid ?? 0,
      timeSpentGridOverall: datapoint.timeSpentGridOverall ?? 0,
      schemaName,
    };

    timers.start(name, metadata);
  },

  // When stopping a timer for a datapoint, we often
  // don't have the datapoint object available any more.
  // That's why we take only ID here (not the whole object as in start())
  stopAnyDatapoint: (id: number) => {
    // If there is a magicGrid timer running, we want to stop it now, so that
    // its values are included here.
    timeSpent.closeMagicGrid(id);

    const name = timerName(id);

    const result = stopAndCalculate<DatapointTimerMetadata>(name);

    if (!result) return undefined;

    logDatapointStop(result);

    return {
      timeSpentOverall: millisecondsToTimeSpentSeconds(
        (result.metadata?.timeSpentOverall ?? 0) * 1000 + result.overall
      ),
      timeSpent: millisecondsToTimeSpentSeconds(
        (result.metadata?.timeSpent ?? 0) * 1000 + result.active
      ),
      timeSpentGrid: result.metadata?.timeSpentGrid,
      timeSpentGridOverall: result.metadata?.timeSpentGridOverall,
    };
  },

  // Get time elapsed on a datapoint so far, without stopping the timer
  // used when updating DP TS on tab hide
  getElapsedDatapoint: (id: number) => {
    const name = timerName(id);

    const result = timers.elapsedInterval<DatapointTimerMetadata>(name);

    if (!result) return undefined;

    const { overall, active, metadata } =
      calculateIntervals<DatapointTimerMetadata>(result);

    return {
      timeSpentOverall: millisecondsToTimeSpentSeconds(
        (metadata?.timeSpentOverall ?? 0) * 1000 + overall
      ),
      timeSpent: millisecondsToTimeSpentSeconds(
        (metadata?.timeSpent ?? 0) * 1000 + active
      ),
      timeSpentGrid: metadata?.timeSpentGrid,
      timeSpentGridOverall: metadata?.timeSpentGridOverall,
    };
  },

  initialize: () => {
    detectUserActivity(() => {
      timeSpent.userActivityWasDetected();
    });

    inactiveIntervals.init('user-inactivity', { minLength: 10_000 });

    inactiveIntervals.start('user-inactivity');

    if (document.visibilityState === 'hidden') {
      inactiveIntervals.start('page-hidden');
    }
  },

  snapshot: () => {
    return {
      timers: timers.snapshot(),
      inactiveIntervals: inactiveIntervals.snapshot(),
    };
  },

  clear: () => {
    timers.clear();
  },
};

// @ts-expect-error
window.timeSpent = timeSpent;

timeSpent.initialize();

export { timeSpent };
