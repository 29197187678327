import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import { FETCH_ATTACHMENT_INTERVAL } from '../helpers';

export const ATTACHMENTS_RELATIONS_QUERY_KEY = 'attachments-relations';

// An annotation can be included in multiple attachment relations as both parent and/or a child.
// The current decision is to prioritise one of these relations and display only that one to the user. Also we'll be preventing creation of multiple attachment relations in other places
// current prioritisation in this hook is if the annotation is parent in the relation we chose that over child.
export const useFetchAttachmentRelation = (annotationId: number | null) => {
  const { data: attachmentRelation, isInitialLoading } = useQuery({
    queryKey: [ATTACHMENTS_RELATIONS_QUERY_KEY, annotationId] as const,
    queryFn: async () => {
      if (!annotationId)
        throw new Error(
          'Annotation id is required to fetch attachment relation'
        );

      const parentRelations = await api.unpaginatedRequest(
        endpoints.relations.list
      )({ type: 'attachment', parent: [annotationId] });

      if (parentRelations.length !== 0) return parentRelations[0] ?? null;

      const childRelations = await api.unpaginatedRequest(
        endpoints.relations.list
      )({
        type: 'attachment',
        annotation: [annotationId],
      });

      return childRelations[0] ?? null;
    },
    enabled: !!annotationId,
    refetchInterval: FETCH_ATTACHMENT_INTERVAL,
  });
  return {
    attachmentRelation,
    isInitialLoading,
  };
};
