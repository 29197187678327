import { Check, Close } from '@rossum/ui/icons';
import {
  ClickAwayListener,
  IconButton,
  Stack,
  StackProps,
  TextField,
} from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isEnter, isEsc } from '../../../../lib/keyboard';

type EditNameProps = {
  ContainerProps: StackProps;
  originalName: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

const EditName = ({
  ContainerProps,
  originalName,
  onClose,
  onSubmit,
}: EditNameProps) => {
  const [newName, setNewName] = useState(originalName);
  useEffect(() => {
    const escapeKeySubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(filter(isEsc))
      .subscribe(() => {
        onClose();
      });

    const enterKeySubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(filter(isEnter))
      .subscribe(() => {
        if (
          newName &&
          newName.trim() !== originalName &&
          newName.trim() !== ''
        ) {
          onSubmit(newName);
          onClose();
        } else {
          onClose();
        }
      });
    return () => {
      if (escapeKeySubscription) escapeKeySubscription.unsubscribe();
      if (enterKeySubscription) enterKeySubscription.unsubscribe();
    };
  }, [newName, onClose, originalName, onSubmit]);

  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <Stack
        direction="row"
        alignItems="center"
        spacing={ContainerProps.spacing}
        sx={ContainerProps.sx}
        onClick={e => e.stopPropagation()}
      >
        <TextField
          value={newName}
          placeholder={originalName}
          autoFocus
          InputProps={{
            inputProps: {
              maxLength: 255,
              sx: {
                pl: 1,
                pr: 1,
                pt: 0.5,
                pb: 0.5,
                fontSize: 14,
              },
            },
          }}
          onChange={({ target: { value: inputValue } }) =>
            setNewName(inputValue)
          }
        />
        <IconButton
          color="primary"
          size="small"
          disabled={newName?.trim() === ''}
          onClick={e => {
            e.stopPropagation();
            if (newName && newName.trim() !== originalName) onSubmit(newName);
            onClose();
          }}
        >
          <Check fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Stack>
    </ClickAwayListener>
  );
};

export { EditName };
