import { get, isEqual } from 'lodash';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import { sidebarNavigationOptions } from '../../../components/LineItemsControls/components/formValues';
import { boolify, isEmbedded } from '../../../constants/config';
import {
  assertMutable,
  sidebarFooterActions,
  SidebarFooterActionsOrder,
} from '../../../features/annotation-view/document-sidebar/sidebar-footer/sidebar-footer-actions/utils';
import createDeepEqualSelector from '../../../lib/createDeepEqualSelector';
import { GroupRole } from '../../../types/group';
import { ProductTourName } from '../../../types/productTour';
import { State } from '../../../types/state';
import { FooterLayout, SortFooterColumns } from '../../../types/user';
import { isFeatureFlagEnabled } from '../../../unleash/useFeatureFlag';
import { getRoleName, isApproverRole, roleNames } from '../groups/helpers';
import { NAVIGATE_TO_NEXT } from '../localStorage/actions';
import { organizationSelector } from '../organization/selectors';

export const userSelector = (state: State) => state.user;
export const groupSelector = (state: State) => state.groups;
export const firstTimeUserSelector = (state: State) =>
  isEqual(state.user.uiSettings.hints, {}) &&
  !state.user.uiSettings.onboardingAcknowledged;

export const displayExtensionsIntroBannerSelector = (state: State) =>
  !state.user.uiSettings.hideExtensionsIntroBanner;

export const userHookStateSelector = (state: State) =>
  state.user.uiSettings.hookState ?? {};

export const displayDAGIntroBannerSelector = (state: State) =>
  !state.user.uiSettings.hideDagIntroBanner;

export const allDocumentsDashboardCustomizationSelector = (state: State) =>
  state.user.uiSettings.documentsDashboard;

export const confidenceIndicatorEnabledSelector = (state: State) =>
  state.user.uiSettings.showConfidenceScore;

export const userRoleNameSelector = createDeepEqualSelector(
  userSelector,
  groupSelector,
  (user, groups) => getRoleName(get(user, 'groups'), groups)
);

export const isUserViewer = (state: State) =>
  isUserOfRoleSelector(state)('viewer') || isUserRoleUnknownSelector(state);

export const isUserAdmin = (state: State) =>
  isUserOfRoleSelector(state)('admin') ||
  isUserOfRoleSelector(state)('organization_group_admin');

export const isUserManager = (state: State) =>
  isUserOfRoleSelector(state)('manager');

export const isUserAnnotatorBetaSelector = (state: State) =>
  isUserOfRoleSelector(state)('annotator_limited');

export const isUserOfRoleSelector = createSelector(
  userSelector,
  groupSelector,
  (user, groups) => (desiredGroup: GroupRole) =>
    groups
      .filter(group => user.groups.includes(group.url))
      .some(userGroup => userGroup.name === desiredGroup)
);

const isUserRoleUnknownSelector = createSelector(
  userRoleNameSelector,
  (roleName: GroupRole) => !roleNames.includes(roleName)
);

export const tourResultSelector =
  (tourName: ProductTourName) => (state: State) =>
    state.user.uiSettings?.productTours?.[tourName]?.result ?? null;

// support access or membership access - user from different organization
export const userIsSupportSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, organization) => {
    return user.organization !== organization.url;
  }
);

export const canUserPurgeSelector = createSelector(
  isUserAdmin,
  isUserAdmin => isUserAdmin
);

export const canUserApproveSelector = createSelector(
  isUserAdmin,
  userSelector,
  groupSelector,
  (isUserAdmin, user, group) =>
    isApproverRole(user.groups, group) || isUserAdmin
);

export const footerLayoutSelector = (state: State): FooterLayout => {
  const value = state.user?.uiSettings.footerLayout;
  return value ?? 'table-per-page';
};

export const sortFooterColumnsSelector = createSelector(
  footerLayoutSelector,
  (state: State) => state.user?.uiSettings.sortFooterColumns,
  (layout, sorting): SortFooterColumns =>
    // handle previous state without sorting based on layout preference
    sorting === undefined
      ? layout === 'table-per-page'
        ? 'automatically'
        : 'queue-settings'
      : sorting
);

export const sidebarAdditionalInfoSelector = (state: State) =>
  isEmbedded() ? undefined : state.user.uiSettings.sidebarAdditionalInfo;

// Takes into account pre-new-sidebar setting as well so can be used in code without feature flag
const resolveOnConfirmNavigate = (
  onConfirm: (typeof sidebarNavigationOptions)[number] | undefined
): (typeof sidebarNavigationOptions)[number] => {
  // TODO: Remove this in ~a year
  const oldLocalValue = localStorage.getItem(NAVIGATE_TO_NEXT);
  const oldResolvedNavigateToNextValue = oldLocalValue
    ? boolify(oldLocalValue)
    : true;

  const fallbackValue = oldResolvedNavigateToNextValue
    ? 'sidebarNavigation.next'
    : 'sidebarNavigation.stay';

  const newSidebarEnabled = isFeatureFlagEnabled('ac-5499-new-sidebar');

  if (!newSidebarEnabled || isEmbedded()) {
    return fallbackValue;
  }

  return onConfirm ?? fallbackValue;
};

export const onConfirmNavigateSelector = (state: State) =>
  resolveOnConfirmNavigate(
    isEmbedded()
      ? undefined
      : state.user.uiSettings.sidebarNavigation?.onConfirm
  );

export const sidebarFieldIdsEnabledSelector = createSelector(
  sidebarAdditionalInfoSelector,
  info => info?.includes('sidebarAdditionalInfo.fieldIds')
);

export const sidebarConfidenceScoresEnabledSelector = createSelector(
  sidebarAdditionalInfoSelector,
  info => info?.includes('sidebarAdditionalInfo.confidenceScores')
);

export const sidebarHiddenFieldsVisibleSelector = createSelector(
  sidebarAdditionalInfoSelector,
  info => {
    const newSidebarEnabled = isFeatureFlagEnabled('ac-5499-new-sidebar');

    return (
      newSidebarEnabled &&
      info?.includes('sidebarAdditionalInfo.hiddenFieldsVisible')
    );
  }
);

export const sidebarAutomationBlockersByDefaultVisibleSelector = createSelector(
  sidebarAdditionalInfoSelector,
  info => info?.includes('sidebarAdditionalInfo.automationBlockers')
);

const assertAllActionsIncluded = (
  actionsOrder: SidebarFooterActionsOrder | undefined
): SidebarFooterActionsOrder => {
  if (!actionsOrder) {
    return assertMutable(sidebarFooterActions);
  }

  const notIncluded = R.difference(sidebarFooterActions, actionsOrder);

  return [...actionsOrder, ...notIncluded] as SidebarFooterActionsOrder;
};

export const sidebarActionsOrderSelector = (state: State) =>
  isEmbedded()
    ? undefined
    : assertAllActionsIncluded(state.user.uiSettings.sidebarActionsOrder);
