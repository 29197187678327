import { IconDatabaseOff } from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon, Typography, useTheme } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { linebreak, routerLink } from '../../../lib/formaterValues';
import { datasetCreateRoute } from '../routes';

export const EmptyList = () => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Stack
      p={3}
      alignItems="center"
      justifyContent="center"
      gap={3}
      m="auto"
      textAlign="center"
    >
      <SvgIcon
        sx={{
          color: 'action.disabled',
          width: 48,
          height: 48,
        }}
      >
        <IconDatabaseOff strokeWidth={1} />
      </SvgIcon>
      <Stack alignItems="center" justifyContent="center" gap={2}>
        <Typography variant="h5">
          {intl.formatMessage({ id: 'features.datasets.emptyList.title' })}
        </Typography>
        <Typography>
          {intl.formatMessage(
            { id: 'features.datasets.emptyList.description' },
            {
              link: routerLink({
                route: datasetCreateRoute(),
                attributes: {
                  style: {
                    color: theme.palette.text.secondary,
                    textDecoration: 'underline',
                  },
                },
              }),
              linebreak,
            }
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
