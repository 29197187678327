import { extensionFunctionType, Hook } from '@rossum/api-client/hooks';
import { get, isEmpty, negate } from 'lodash';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { timer } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  pairwise,
  pluck,
  skip,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { myExtensionsPath } from '../../../containers/Extensions/helpers';
import { webhookNormalizedEvents } from '../../../containers/Extensions/lib/helpers';
import { errorHandler } from '../../../lib/api';
import {
  isNonEmptyObject,
  isNotNullOrUndefined,
} from '../../../lib/typeGuards';
import { asScalar, getIDFromString, parse } from '../../../lib/url';
import {
  enterExtension,
  enterExtensionEditor,
  enterExtensionsList,
  leaveExtension,
  leaveExtensionEditor,
} from '../ui/actions';
import { isActionOf, locationChange, makeEpic } from '../utils';
import {
  clearExtensions,
  deleteExtensionFulfilled,
  fetchExtensionDetail,
  fetchExtensionDetailFulfilled,
  fetchExtensions,
  fetchExtensionsFulfilled,
  FetchExtensionsFulfilledPayload,
  FetchExtensionsQuery,
  updateExtensionDetailFulfilled,
} from './actions';
import { selectedExtensionSelector } from './selectors';

export const excludeKeysFromConversion = [
  'settings',
  'settings_schema',
  'secrets',
  'secrets_schema',
];

export const fetchExtensionsEpic = makeEpic(
  (action$, state$, { authGetJSON$ }) =>
    action$.pipe(
      filter(isActionOf(fetchExtensions)),
      pluck('meta', 'query'),
      map(query =>
        isEmpty(query)
          ? parse(get(state$.value, ['router', 'location', 'search']))
          : query
      ),
      filter(isNonEmptyObject),
      filter(({ events }) => {
        if (events) {
          const eventsAsScalar = asScalar(events);

          return ['all', ...webhookNormalizedEvents].includes(eventsAsScalar);
        }

        return true;
      }),
      switchMap(({ events, view, ...queryWithoutEvents }) => {
        const query =
          events === 'all'
            ? queryWithoutEvents
            : { ...queryWithoutEvents, events };

        return authGetJSON$<FetchExtensionsFulfilledPayload>(
          `${apiUrl}/hooks`,
          {
            query,
            excludeKeysFromConversion,
          }
        ).pipe(map(fetchExtensionsFulfilled), catchError(errorHandler));
      })
    )
);

const INITIAL_VIEW = 'initial';

export const fetchExtensionsOnQueryChangeEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(locationChange)),
    pluck('payload', 'location'),
    filter(({ pathname }) => pathname.includes('/extensions')),
    filter(({ pathname }) => !pathname.includes('/logs')),
    skip(1), // first emission is skipped because of useEffect changing query in component
    map(({ search }) => parse(search)),
    filter(isNonEmptyObject),
    // startWith mimics initial emit of locationChange action
    // so that the pairwise is not waiting for the second emit of it
    // using static `view: INITIAL_VIEW` value because state$.router.location.search is empty at the app load
    startWith({
      view: INITIAL_VIEW,
    }),
    pairwise(),
    filter(([{ view: prevView }, { view: currentView }]) => {
      return prevView === INITIAL_VIEW || prevView === currentView;
    }),
    map(([, query]: [FetchExtensionsQuery, FetchExtensionsQuery]) =>
      fetchExtensions(query)
    )
  )
);

const clearExtensionsOnExitEpic = makeEpic(action$ =>
  action$.pipe(
    filter(
      isActionOf([fetchExtensionsFulfilled, fetchExtensionDetailFulfilled])
    ),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(locationChange)),
        pluck('payload', 'location'),
        filter(negate(({ pathname }) => pathname.includes('/extensions'))),
        filter(negate(({ pathname }) => pathname.includes('/queues'))),
        first(),
        mapTo(clearExtensions())
      )
    )
  )
);

const fetchDataOnEnterListEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([enterExtensionsList, deleteExtensionFulfilled])),
    map(() => fetchExtensions({}))
  )
);

const redirectAfterDeleteExtensionEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteExtensionFulfilled)),
    filter(() => state$.value.router.location.pathname !== myExtensionsPath()),
    map(() => replace(myExtensionsPath()))
  )
);

const assureExtensionDetailEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([enterExtension, enterExtensionEditor])),
    map(() => state$.value),
    map(
      ({
        router: {
          location: { pathname },
        },
        extensions,
      }) => [getIDFromString(pathname), extensions.list] as const
    ),
    filter(
      ([id, extensions]) => !extensions.find(extension => extension.id === id)
    ),
    map(([id]) => id),
    filter((id): id is number => !!id),
    map(extensionId => fetchExtensionDetail(extensionId))
  )
);

const assureFunctionIsReadyEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([enterExtension, enterExtensionEditor])),
    map(() => selectedExtensionSelector(state$.value)),
    filter(isNotNullOrUndefined),
    filter(
      hook => hook.type === extensionFunctionType && hook.status === 'pending'
    ),
    map(({ id }) => fetchExtensionDetail(id))
  )
);

const fetchExtensionDetailEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchExtensionDetail)),
    pluck('payload'),
    mergeMap(id =>
      authGetJSON$<Hook>(`${apiUrl}/hooks/${id}`, {
        excludeKeysFromConversion,
      }).pipe(map(fetchExtensionDetailFulfilled), catchError(errorHandler))
    )
  )
);

const checkFunctionStatus = makeEpic(action$ =>
  action$.pipe(
    filter(
      isActionOf([
        fetchExtensionDetailFulfilled,
        updateExtensionDetailFulfilled,
      ])
    ),
    pluck('payload'),
    filter(
      hook => hook.type === extensionFunctionType && hook.status === 'pending'
    ),
    switchMap(({ id }) =>
      timer(10000).pipe(
        takeUntil(
          action$.pipe(
            filter(isActionOf([leaveExtension, leaveExtensionEditor]))
          )
        ),
        map(() => fetchExtensionDetail(id))
      )
    )
  )
);

export default combineEpics(
  checkFunctionStatus,
  assureFunctionIsReadyEpic,
  clearExtensionsOnExitEpic,
  fetchDataOnEnterListEpic,
  fetchExtensionsEpic,
  fetchExtensionsOnQueryChangeEpic,
  fetchExtensionDetailEpic,
  assureExtensionDetailEpic,
  redirectAfterDeleteExtensionEpic
);
