import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { repeateRequest, repeateRequestSucceeded } from './actions';

const initialState: number[] = [];

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(repeateRequest):
      return [...state, action.meta.timestamp];

    case getType(repeateRequestSucceeded):
      return state.filter(request => request !== action.meta.timestamp);

    default:
      return state;
  }
};

export default reducer;
