import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { approvalWorkflowsFeatureSelector } from '../../../features/pricing/selectors';
import { api } from '../../../lib/apiClient';

export const useShowWorkflowActivity = (annotationId: number | undefined) => {
  const isWorkflowsEnabled = useSelector(approvalWorkflowsFeatureSelector);

  const query = useQuery({
    queryKey: ['showWorkflowActivity', annotationId],

    queryFn: () =>
      api.request(
        endpoints.workflowRuns.list({
          annotation: annotationId,
        })
      ),

    enabled: !!annotationId && isWorkflowsEnabled,
  });

  return query.data && query.data.pagination.total > 0;
};
