import { Log } from '../models/log';
import { logsQuerySchema, logsSchema } from './logs.schema';

export type LogsQuery = {
  timestampAfter?: string;
  timestampBefore?: string;
  search?: string;
  hook?: Array<string>;
  requestId?: string;
  logLevel?: string;
  statusCode?: Array<string>;
  queue?: Array<string>;
  annotation?: Array<string>;
  email?: Array<string>;
  pageSize?: string;
};

// TODO: can be removed once we use listResponse and response has pagination, use `ListResponse<Log>`
export type Logs = { results: Array<Log> };

export const logs = (query: LogsQuery = {}) => {
  return {
    endpoint: `/hooks/logs`,
    method: 'GET',
    // TODO: use regular listResponse once pagination is introduced on BE
    responseSchema: logsSchema,
    query,
    querySchema: logsQuerySchema,
  } as const;
};
