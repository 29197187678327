import { Box, Paper, Skeleton, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

type AggregateStatProps = {
  icon: ReactNode;
  value: ReactNode | undefined;
  description: ReactNode;
};

const LABEL_SIZE = 20;

export const AggregateStat = ({
  icon,
  value,
  description,
}: AggregateStatProps) => (
  <Stack mr={4} gap={1} flexDirection="row" alignItems="center">
    <Stack
      component={Paper}
      justifyContent="center"
      alignItems="center"
      elevation={6}
      p={1.5}
      sx={{
        boxShadow: 'none',
        borderRadius: '50%',
        '& svg': {
          width: 20,
          height: 20,
        },
      }}
    >
      {icon}
    </Stack>
    <Box>
      {
        // TODO rewrite condition after clean types in Aggtegates component
      }
      {!value && value !== 0 ? (
        <Skeleton variant="text" style={{ fontSize: LABEL_SIZE }} />
      ) : (
        <Typography
          fontSize={LABEL_SIZE}
          color="text.primary"
          fontWeight={600}
          lineHeight={1.3}
        >
          {value}
        </Typography>
      )}
      <Typography fontSize={14} color="text.disabled">
        {description}
      </Typography>
    </Box>
  </Stack>
);

export default AggregateStat;
