import {
  Box,
  Link as MUILink,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { Link } from 'react-router-dom';

type MenuItemProps = {
  href: string;
  label: string;
  Icon: React.ComponentType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  rel?: string;
  dataCy?: string;
  external?: boolean;
};

export const MenuLinkItem = ({
  href,
  onClick,
  label,
  Icon,
  rel,
  external = false,
  dataCy,
}: MenuItemProps) => {
  const linkStyles = {
    px: 2,
    py: 1,
    width: '100%',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  };

  const getLinkContent = () => (
    <Stack
      direction="row"
      gap={1.5}
      component={Typography}
      variant="body2"
      color="text.primary"
    >
      <SvgIcon
        sx={{
          fill: 'none',
          color: t => t.palette.action.active,
        }}
        fontSize="small"
        component={Icon}
      />
      {label}
    </Stack>
  );

  return (
    <MenuItem sx={{ p: 0 }}>
      {external ? (
        <MUILink
          rel={rel}
          href={href}
          onClick={onClick}
          data-cy={dataCy}
          sx={linkStyles}
          target="_blank"
        >
          {getLinkContent()}
        </MUILink>
      ) : (
        <Box
          component={Link}
          rel={rel}
          to={href}
          onClick={onClick}
          data-cy={dataCy}
          sx={linkStyles}
        >
          {getLinkContent()}
        </Box>
      )}
    </MenuItem>
  );
};
