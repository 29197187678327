import dl from 'downloadjs';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 } from 'uuid';
import * as z from 'zod';
import {
  MessageId,
  throwError,
  throwInfo,
} from '../../../redux/modules/messages/actions';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import { userHookStateSelector } from '../../../redux/modules/user/selectors';
import { useExtensionInteropStore } from '../store/ExtensionInteropStore';
import { intentSchema } from './intent.schema';

export const useProcessIntent = () => {
  const openForm = useExtensionInteropStore(state => state.openForm);
  const closeForm = useExtensionInteropStore(state => state.closeForm);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const hookState = useSelector(userHookStateSelector);

  const processIntent = useCallback(
    (response: unknown) => {
      const responseParsed = z
        .object({
          intent: intentSchema.optional(),
        })
        .safeParse(response);

      if (responseParsed.success && responseParsed.data.intent) {
        const { download, redirect, error, info, form, user_state, open_tab } =
          responseParsed.data.intent;

        if (form === null) {
          closeForm();
        } else if (form) {
          openForm({ form, uuid: v4() });
        }

        if (error) {
          dispatch(throwError(error.message as MessageId));
        }

        if (user_state) {
          dispatch(
            updateUiSettings({
              hookState: {
                ...hookState,
                [user_state.scope]: user_state.payload,
              },
            })
          );
        }

        if (info) {
          dispatch(throwInfo(info.message as MessageId));
        }

        if (download) {
          if (typeof download.content === 'string') {
            dl(download.content, download.filename);
          } else {
            dl(JSON.stringify(download.content), download.filename);
          }
        }

        if (redirect) {
          push(redirect.url);
        }

        if (open_tab) {
          window.open(open_tab.url, '_blank');
        }
      } else if (!responseParsed.success) {
        // @ts-expect-error This will be fixed by toastr update
        dispatch(throwError('Extension response has invalid format.'));
      }
    },
    [closeForm, openForm, dispatch, hookState, push]
  );

  return useMemo(
    () => ({
      processIntent,
    }),
    [processIntent]
  );
};
