import { createReactComponent } from '@tabler/icons-react';

export const IconCustomAlertOctagonOff = createReactComponent(
  'outline',
  'customAlertOctagonOff',
  'CustomAlertOctagonOff',
  [
    [
      'path',
      {
        key: 'customAlertOctagonOff-path',
        d: 'M18.376 19.4472L12.802 21.8352C12.29 22.0552 11.71 22.0552 11.198 21.8352L5.624 19.446C5.14212 19.2402 4.759 18.8568 4.55312 18.3748L2.16512 12.8008C2.0564 12.5488 2 12.2758 2 12.0001C2 11.7244 2.0564 11.4514 2.16512 11.1994L4.554 5.62596M8.4108 3.35914L11.198 2.16514C11.451 2.0564 11.724 2 12 2C12.276 2 12.549 2.0564 12.801 2.16514L18.375 4.55412C18.8568 4.76004 19.2408 5.14314 19.4467 5.62596L21.8347 11.1994C22.0552 11.7114 22.0552 12.2914 21.8347 12.8034L20.6404 15.5896M12 7.0002V8.0099M12 16H12.0099M3 3L21 21',
      },
    ],
  ]
);
