import { Route, Switch } from 'react-router';
import { DatasetContext } from './context';
import { DatasetList } from './dataset-list';
import { DatasetSettings } from './dataset-settings';
import { DatasetTable } from './dataset-table';
import {
  datasetCreateRoute,
  datasetSettingsRoute,
  datasetsPath,
  datasetTableRoute,
} from './routes';

export const DatasetsRoutes = () => {
  return (
    <DatasetContext>
      <Switch>
        <Route path={datasetsPath()} exact component={DatasetList} />
        <Route path={datasetCreateRoute()} exact component={DatasetSettings} />
        <Route path={datasetTableRoute()} exact component={DatasetTable} />
        <Route
          path={datasetSettingsRoute()}
          exact
          component={DatasetSettings}
        />
      </Switch>
    </DatasetContext>
  );
};
