import { ExtensionEvent, ExtensionEventGroup } from '@rossum/api-client/hooks';
import { ExpandLess, ExpandMore } from '@rossum/ui/icons';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Box,
  Chip,
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isArray, unique } from 'remeda';
import {
  getDestructedEventAction,
  isExtensionEventGroup,
  visibleWebhookEvents,
  webhookEvents,
} from '../helpers';

const RenderGroup = ({
  params,
  eventGroup,
  selectedEventsLength,
  selectAll,
  deselectAll,
  inputValue,
}: {
  params: AutocompleteRenderGroupParams;
  eventGroup: ExtensionEventGroup | null;
  selectedEventsLength: number;
  selectAll: () => void;
  deselectAll: () => void;
  inputValue: string;
}) => {
  const [open, setOpen] = useState(true);
  const intl = useIntl();

  const { group, children, key } = params;
  const childrenLength = isArray(children) ? children.length : 0;

  return (
    <List key={`${group}-${key}`} dense sx={{ py: 0 }}>
      <ListItemButton
        onClick={() => setOpen(prevOpen => !prevOpen)}
        data-cy="extension-event"
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText
          primary={
            eventGroup
              ? intl.formatMessage({
                  id: `containers.settings.extensions.events.${eventGroup}`,
                })
              : group
          }
        />
        {inputValue ? null : (
          <Stack spacing={1} direction="row" alignItems="center">
            {selectedEventsLength < childrenLength ? (
              <Link
                variant="body2"
                color="text.primary"
                sx={{
                  '&:hover': { color: theme => theme.palette.text.primary },
                }}
                onClick={e => {
                  e.stopPropagation();
                  selectAll();
                }}
              >
                {intl.formatMessage({ id: 'features.queues.selectAll' })}
              </Link>
            ) : (
              <Link
                variant="body2"
                color="text.primary"
                sx={{
                  '&:hover': { color: theme => theme.palette.text.primary },
                }}
                onClick={e => {
                  e.stopPropagation();
                  deselectAll();
                }}
              >
                {intl.formatMessage({ id: 'features.queues.deselectAll' })}
              </Link>
            )}
            <Chip label={`${selectedEventsLength} / ${childrenLength}`} />
          </Stack>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {params.children}
        </List>
      </Collapse>
    </List>
  );
};

type EventMultiSelectProps = {
  value: ExtensionEvent[];
  onChange: (values: ExtensionEvent[]) => void;
};

export const EventMultiSelect = forwardRef(
  ({ value: rawValue, onChange }: EventMultiSelectProps, ref) => {
    // Raw value can contain event groups (like `annotation_content`), these get expanded
    const value = rawValue.flatMap(event =>
      isExtensionEventGroup(event)
        ? webhookEvents
            .find(e => e.name === event)
            ?.actions.map(action => `${event}.${action}` as ExtensionEvent) ??
          []
        : [event]
    );

    const intl = useIntl();
    const [inputValue, setInputValue] = useState<string>('');

    const eventActions = visibleWebhookEvents.flatMap(event => {
      return event.actions.map(
        action => `${event.name}.${action}` as ExtensionEvent
      );
    });

    return (
      <Autocomplete
        data-cy="extensions-events-select"
        PaperComponent={Paper}
        slotProps={{ paper: { elevation: 6 } }}
        multiple
        ref={ref}
        size="small"
        options={eventActions}
        groupBy={option => getDestructedEventAction(option).name}
        value={value}
        disableCloseOnSelect
        onChange={(_e, newValue) => onChange(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={intl.formatMessage({
              id: 'containers.settings.extensions.events.placeholder',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderGroup={params => {
          const eventGroup = isExtensionEventGroup(params.group)
            ? params.group
            : null;

          const selectedEvents = value.filter(event => {
            const { name } = getDestructedEventAction(event);
            return name === params.group;
          });

          const eventActionsOfGroup = eventActions.filter(event => {
            const { name } = getDestructedEventAction(event);
            return name === params.group;
          });

          return (
            <RenderGroup
              key={params.key}
              eventGroup={eventGroup}
              params={params}
              selectedEventsLength={selectedEvents.length}
              selectAll={() =>
                onChange(unique([...value, ...eventActionsOfGroup]))
              }
              deselectAll={() =>
                onChange(value.filter(event => !event.includes(params.group)))
              }
              inputValue={inputValue}
            />
          );
        }}
        renderOption={(params, event) => {
          const { action } = getDestructedEventAction(event);

          return (
            <ListItemButton
              component="li"
              {...params}
              key={event}
              data-cy="extension-event-action"
            >
              <ListItemText
                primary={intl.formatMessage({
                  id: `containers.settings.extensions.events.${action}`,
                })}
                secondary={intl.formatMessage({
                  id: `containers.settings.extensions.events.description.${action}`,
                })}
              />
            </ListItemButton>
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            const { action, name } = getDestructedEventAction(option);

            return (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={
                  <Stack
                    component={Typography}
                    variant="caption"
                    color="text.secondary"
                    direction="row"
                    divider={
                      <Box component="span" px={0.5}>
                        -
                      </Box>
                    }
                  >
                    <Typography
                      component="span"
                      variant="caption"
                      color="text.primary"
                    >
                      {intl.formatMessage({
                        id: `containers.settings.extensions.events.${name}`,
                      })}
                    </Typography>
                    {intl.formatMessage({
                      id: `containers.settings.extensions.events.${action}`,
                    })}
                  </Stack>
                }
              />
            );
          });
        }}
      />
    );
  }
);
