import { getIDFromUrl } from '@rossum/api-client';
import { ListItemProps } from '@rossum/ui/material';
import deepEqual from 'fast-deep-equal/es6/react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  sidebarFieldIdsEnabledSelector,
  sidebarHiddenFieldsVisibleSelector,
} from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { SchemaFieldIconType } from '../../../../ui/schema-field-icon/SchemaFieldIcon';
import { SidebarItemLabel } from '../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../shared/SidebarItemLayout';
import { SidebarMultivalueChildFieldModel } from '../useSidebarData';
import { InactiveValue } from './shared/InactiveValue';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { SidebarItemActiveValue } from './shared/SidebarItemActiveValue';
import { formatConfidenceScore, getFieldsSettingsPath } from './utils';

type MultivalueChildItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarMultivalueChildFieldModel;
  index: number;
  active: boolean;
  selected: boolean;
  focused?: boolean;
  disabled: boolean;
  onChange: (item: SidebarMultivalueChildFieldModel, newValue: string) => void;
  onClick: (params: {
    event: React.MouseEvent<HTMLElement>;
    datapointId: number;
    datapointIndex: number;
    selected: boolean;
    active: boolean;
  }) => void;
};

const MultivalueChildItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    index,
    active,
    focused,
    selected,
    disabled,
    onChange,
    onClick,
    ...listItemProps
  }: MultivalueChildItemProps) => {
    const handleChange = useCallback(
      (newValue: string) => {
        onChange(item, newValue);
      },
      [item, onChange]
    );

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        onClick({
          event: e,
          datapointId: item.id,
          datapointIndex: index,
          selected,
          active,
        });
      },
      [active, index, item.id, onClick, selected]
    );

    const hiddenFieldsVisible = useSelector(sidebarHiddenFieldsVisibleSelector);

    const isHidden = item.hidden && hiddenFieldsVisible;

    const isConfirmed = useMemo(() => {
      return item.validationSources.includes('human');
    }, [item.validationSources]);

    const iconType: SchemaFieldIconType = item.valueSource;

    const iconVariant = useMemo(() => {
      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed]);

    const iconColor = useMemo(() => {
      if (isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed]);

    const isDisabled = disabled || item.editing === 'disabled';

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <MagicContainer
        active={active}
        focused={focused}
        selected={selected}
        disabled={isDisabled}
        sidebarScrollableRef={sidebarScrollableRef}
        onClick={handleClick}
        {...listItemProps}
      >
        <SidebarItemLayout
          iconSlot={
            <ItemContextActions
              item={item}
              active={active}
              disabled={isDisabled}
              iconProps={{
                type: iconType,
                variant: iconVariant,
                color: iconColor,
              }}
              fieldSettingsPath={getFieldsSettingsPath(queueId, item.schemaId)}
              variant={isHidden ? 'secondary' : 'primary'}
            />
          }
          labelSlot={
            <SidebarItemLabel
              label={`${index + 1}`}
              sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
              variant={isHidden ? 'secondary' : 'primary'}
              isRequired={item.required}
            />
          }
          valueSlot={
            active ? (
              <SidebarItemActiveValue
                value={item.value}
                onChange={handleChange}
                disabled={isDisabled}
              />
            ) : (
              <InactiveValue
                value={item.value}
                confidence={formatConfidenceScore(item.confidenceScore)}
              />
            )
          }
          decorationSlot={
            active ? null : (
              <MessageIndicator messagesStats={item.messagesStats} />
            )
          }
        />
        {active && item.messages.length > 0 && (
          <Messages messages={item.messages} />
        )}
      </MagicContainer>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

MultivalueChildItem.displayName = 'MultivalueChildItem';

export { MultivalueChildItem };
