import { ReactNode } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  children: ReactNode;
  text?: string;
  id: LocalizationKeys;
  values?: Parameters<IntlShape['formatMessage']>[1];
  intl: IntlShape;
  onClick?: () => void;
};

const Tooltip = ({
  children,
  text,
  id,
  intl,
  values = {},
  onClick,
  ...props
}: Props & { intl: IntlShape }) => (
  <div
    style={{ margin: 0 }}
    title={intl.formatMessage({ id }, values) as string}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

export default injectIntl<'intl', Props>(Tooltip);
