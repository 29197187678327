import { EditorState, Modifier } from 'draft-js';
import {
  EmailTemplateVariablesButton,
  EmailTemplateVariablesButtonProps,
} from './EmailTemplateVariablesButton';

type EmailTemplateWysiwygVariablesButtonProps = {
  // Passed from WYSIWYG component automatically.
  onChange?: (state: EditorState) => void;
  editorState?: EditorState;
} & Pick<
  EmailTemplateVariablesButtonProps,
  // Passed manually.
  'annotationFields' | 'cheatSheetConfig'
>;

export const EmailTemplateWysiwygVariablesButton = ({
  onChange,
  editorState,
  ...VariableButtonProps
}: EmailTemplateWysiwygVariablesButtonProps) => {
  const insertText = (text: string, currentEditorState: EditorState) => {
    const currentContent = currentEditorState.getCurrentContent();
    const currentSelection = currentEditorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      currentEditorState,
      newContent,
      'insert-characters'
    );

    return newEditorState;
  };

  const handleAppendWysiwygVariable = (option: string): void => {
    if (onChange && editorState) {
      onChange(insertText(option, editorState));
    }
  };

  return (
    <EmailTemplateVariablesButton
      {...VariableButtonProps}
      onValueSelect={(variableName: string) =>
        handleAppendWysiwygVariable(variableName)
      }
      sx={{
        borderRadius: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      }}
    />
  );
};
