import { useState } from 'react';
import SearchInput from '../../../../ui/search-input/SearchInput';
import { filterWorkspaces } from '../../helpers/filterWorkspaces';
import { WorkspaceWithQueues } from '../types';

type Props = {
  className?: string;
  setFilteredWorkspaces: (workspaces: WorkspaceWithQueues[]) => void;
  workspaces: WorkspaceWithQueues[] | undefined;
  placeholder?: string;
  onChange?: (value: string) => void;
};

export const SearchFilter = ({
  workspaces,
  setFilteredWorkspaces,
  className,
  placeholder,
  onChange,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');

  const onValueChange = (value: string) => {
    setFilteredWorkspaces(filterWorkspaces(value, workspaces || []));
    onChange?.(value);
    return setSearchValue(value);
  };

  return (
    <SearchInput
      className={className}
      onChange={onValueChange}
      placeholder={placeholder}
      value={searchValue}
      fullWidth
      size="small"
      autoFocus
    />
  );
};
