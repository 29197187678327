import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { ruleTemplateSchema } from '../models/ruleTemplate.schema';
import { ruleTemplateListQuerySchema } from './list.schema';

export type RuleTemplateListQuery = PaginationQuery;

export const list = (query: RuleTemplateListQuery) => {
  return {
    endpoint: `/rule_templates`,
    method: 'GET',
    responseSchema: listResponse(ruleTemplateSchema),
    query,
    querySchema: ruleTemplateListQuerySchema,
  } as const;
};
