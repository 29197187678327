import { PaginationQuery } from '@rossum/api-client/utils';
import {
  Paper,
  Skeleton,
  Stack,
  TableCell,
  Typography,
} from '@rossum/ui/material';
import { random } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { formatNumberToLocale } from '../../../lib/helpers';
import { BillingUnit, getValuesByUnit } from '../helpers';
import { BillingEntity } from '../hooks/useBillingEntity';
import { usePageStatisticsPerQueue } from '../hooks/usePageStatisticsPerQueue';
import { useUnpaginatedQueuesPerOrg } from '../hooks/useUnpaginatedQueuesPerOrg';
import { BillingTable, TableRow } from './BillingTable/BillingTable';
import { EmptyTable } from './BillingTable/EmptyTable';
import { LoadingTable } from './BillingTable/LoadingTable';
import { StatisticsFilterFormState } from './StatisticsFilter';

const getColumns = (unit: BillingUnit) =>
  unit === 'pages'
    ? (['queue', 'billablePages', 'nonBillablePages'] as const)
    : (['queue', 'billableDocuments', 'nonBillableDocuments'] as const);

type StatisticsQueueTableProps = {
  billingEntity: BillingEntity;
  filters: StatisticsFilterFormState;
};

const StatisticsQueueTable = ({
  billingEntity,
  filters,
}: StatisticsQueueTableProps) => {
  const intl = useIntl();

  const { data: queues, isLoading: queuesLoading } = useUnpaginatedQueuesPerOrg(
    filters.organizationUrl,
    {
      pageSize: 100,
    }
  );

  const [paginationQuery, setPaginationQuery] = useState<
    Required<PaginationQuery>
  >({ page: 0, pageSize: 10 });
  const { data, isLoading } = usePageStatisticsPerQueue(
    billingEntity,
    filters,
    paginationQuery
  );

  const columns = getColumns(filters.unit);

  return (
    <Stack spacing={3}>
      <Typography variant="h6">
        {intl.formatMessage({
          id: 'containers.billing.usage.topQueues',
        })}
      </Typography>

      <Paper sx={{ overflow: 'hidden' }} elevation={2}>
        <BillingTable
          paginationResponse={data?.pagination}
          paginationState={paginationQuery}
          setPaginationState={setPaginationQuery}
          headColumns={columns.map(column => ({
            id: `containers.billing.usage.topQueues.${column}` as const,
          }))}
        >
          {isLoading ? (
            <LoadingTable
              columnsCount={columns.length}
              rowsCount={paginationQuery.pageSize + 1} // + 1 is for visual purposes - replace for pagination
            />
          ) : data?.results.length ? (
            data.results.map(row => {
              const queue = queues?.find(queue => queue.url === row.queue);
              const { billable, nonBillable } = getValuesByUnit(
                row.values,
                filters.unit
              );
              return (
                <TableRow key={row.queue} className="TableRowOverride">
                  <TableCell component="th" scope="row">
                    {queuesLoading ? (
                      <Skeleton width={random(200, 250)} height={15} />
                    ) : (
                      queue?.name ?? ''
                    )}
                  </TableCell>
                  <TableCell>
                    {formatNumberToLocale(billable, intl.locale)}
                  </TableCell>
                  <TableCell>
                    {formatNumberToLocale(nonBillable, intl.locale)}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <EmptyTable
              title="containers.billing.emptyTable"
              text="containers.billing.emptyDescription"
              columnsCount={columns.length}
            />
          )}
        </BillingTable>
      </Paper>
    </Stack>
  );
};

export { StatisticsQueueTable };
