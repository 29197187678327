import { EmailThreadCounts } from '@rossum/api-client/emailThreads';
import {
  alpha,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  Stack,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { ViewOptions } from '../hooks/useDashboardQuery';

const MAX_ERRORS = 99;

type Props = {
  view: ViewOptions;
  isVisible: boolean;
  setView: (view: ViewOptions) => void;
  counts: EmailThreadCounts | undefined;
};

const ViewSwitch = ({ view, isVisible, setView, counts }: Props) => {
  const intl = useIntl();

  const newEmailsExist = !!counts?.withNewReplies;
  const newEmailsWithUnprocessableAttachment =
    counts?.recentWithNoDocumentsNotReplied ?? 0;

  if (!isVisible) {
    return null;
  }

  return (
    <ButtonGroup color="secondary" sx={{ ml: 'auto', height: 1 }}>
      <Button
        variant="outlined"
        onClick={() => setView('documents')}
        aria-selected={view === 'documents' ? 'true' : undefined}
        data-cy="switch-documents"
        sx={{
          '&, &:hover': {
            backgroundColor: t =>
              view === 'documents'
                ? alpha(t.palette.text.primary, 0.16)
                : 'transparent',
          },
        }}
      >
        {intl.formatMessage({
          id: 'containers.settings.queues.viewSwitch.documents',
        })}
      </Button>
      <Button
        variant="outlined"
        onClick={() => setView('emails')}
        aria-selected={view === 'emails' ? 'true' : undefined}
        data-cy="switch-emails"
        sx={{
          '&, &:hover': {
            backgroundColor: t =>
              view === 'emails'
                ? alpha(t.palette.text.primary, 0.16)
                : 'transparent',
          },
        }}
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={!newEmailsExist}
          slotProps={{
            badge: {
              // @ts-expect-error
              sx: {
                top: 2,
                right: -5,
              },
            },
          }}
        >
          <Stack gap={0.5} direction="row" alignItems="center">
            {intl.formatMessage({
              id: 'containers.settings.queues.viewSwitch.emails',
            })}
            {newEmailsWithUnprocessableAttachment ? (
              <Chip
                color="warning"
                data-cy="annotation-list-tab-label"
                label={
                  newEmailsWithUnprocessableAttachment > MAX_ERRORS
                    ? '99+'
                    : newEmailsWithUnprocessableAttachment
                }
              />
            ) : null}
          </Stack>
        </Badge>
      </Button>
    </ButtonGroup>
  );
};

export { ViewSwitch };
