import { DedicatedEngineStatus } from '@rossum/api-client/dedicatedEngines';
import { Chip, ChipProps } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type EngineStatusChipProps = ChipProps & {
  // 'active' is a UI only status
  status: DedicatedEngineStatus | 'active';
};

const statusColorMap: Record<
  DedicatedEngineStatus | 'active',
  ChipProps['color']
> = {
  sample_review: 'warning',
  draft: 'info',
  schema_review: 'warning',
  annotating_initial: 'info',
  annotating_review: 'warning',
  annotating_training: 'info',
  training_started: 'info',
  training_finished: 'success',
  retraining: 'info',
  active: 'success',
};

const EngineStatusChip = ({ status, ...rest }: EngineStatusChipProps) => {
  const intl = useIntl();

  return (
    <Chip
      label={intl.formatMessage({
        id: `components.engineStatusChip.${status}`,
      })}
      color={statusColorMap[status]}
      {...rest}
    />
  );
};

export { EngineStatusChip };
