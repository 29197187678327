import { createReactComponent } from '@tabler/icons-react';

export const IconCustomMathFunctionCheck = createReactComponent(
  'outline',
  'customMathFunctionCheck',
  'CustomMathFunctionCheck',
  [
    [
      'path',
      {
        key: 'customMathFunctionCheck-path',
        d: 'M3 19C3 19.5308 3.21071 20.0392 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21C7 21 7 17 8 12C9 7 9 3 11 3C11.5304 3 12.0391 3.21071 12.4142 3.58579C12.7893 3.96086 13 4.46957 13 5M5 12H11M15 19L16.5 20.5L21 17',
      },
    ],
  ]
);
