import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { DrawerConfig } from '../../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { sidebarActionsOrderSelector } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { useAnnotationNavigationActions } from './useAnnotationNavigationActions';
import { useBypassWorkflowAction } from './useBypassWorkflowAction';
import { useDeleteAction } from './useDeleteAction';
import { useDownloadAction } from './useDownloadAction';
import { useEmailAction } from './useEmailAction';
import { useLinkAction } from './useLinkAction';
import { useMoveAction } from './useMoveAction';
import { usePostponeAndRejectAction } from './usePostponeAndRejectAction';
import { SidebarFooterAction } from './utils';

type UseSidebarFooterActionsResult = {
  actions: [SidebarFooterAction[], SidebarFooterAction[]];
  postponeAndReject: {
    modal: JSX.Element | null;
  };
  move: {
    modal: JSX.Element | null;
  };
  link: {
    modal: JSX.Element | null;
  };
};

type UseSidebarFooterActions = (
  visibleCount: number,
  annotation: Annotation,
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void
) => UseSidebarFooterActionsResult;

export const useSidebarFooterActions: UseSidebarFooterActions = (
  visibleCount,
  annotation,
  onEmailThreadOpen
) => {
  const {
    modal: postponeAndRejectModals,
    postpone: postponeAction,
    reject: rejectAction,
  } = usePostponeAndRejectAction(onEmailThreadOpen);

  const { previous: previousAction, next: nextAction } =
    useAnnotationNavigationActions(annotation);

  const { modal: moveModal, action: moveAction } = useMoveAction(annotation);

  const { action: emailAction } = useEmailAction(annotation, onEmailThreadOpen);

  const { modal: linkModal, action: linkAction } = useLinkAction(annotation);

  const { action: downloadAction } = useDownloadAction(annotation);

  const { action: deleteAction } = useDeleteAction(annotation);

  const { action: bypassAction } = useBypassWorkflowAction(annotation);

  const actionsOrder = useSelector(sidebarActionsOrderSelector);

  const orderMap = (actionsOrder ?? []).reduce<Record<string, number>>(
    (acc, action, index) => ({ ...acc, [action]: index }),
    {}
  );

  const allActions: SidebarFooterAction[] = useMemo(
    () =>
      R.pipe(
        [
          postponeAction,
          rejectAction,
          bypassAction,
          nextAction,
          previousAction,
          moveAction,
          emailAction,
          linkAction,
          downloadAction,
          deleteAction,
        ],
        R.filter(R.isTruthy),
        R.sortBy(action => orderMap[action.id] ?? 0)
      ),
    [
      bypassAction,
      deleteAction,
      downloadAction,
      emailAction,
      linkAction,
      moveAction,
      nextAction,
      orderMap,
      postponeAction,
      previousAction,
      rejectAction,
    ]
  );

  // -1 is for the "More" button
  const splitActions = R.partition(
    allActions,
    (_, index) => index < visibleCount - 1
  );

  return {
    actions: splitActions,
    postponeAndReject: {
      modal: postponeAndRejectModals,
    },
    move: {
      modal: moveModal,
    },
    link: {
      modal: linkModal,
    },
  };
};
