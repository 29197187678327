import { Queue } from '@rossum/api-client/queues';
import { SchemaSection } from '@rossum/api-client/schemas';
import {
  Autocomplete,
  Button,
  Collapse,
  Divider,
  Paper,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { Control, Controller, useController, useWatch } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { partition } from 'remeda';
import { ContentWrapper } from '../../../../ui/content-group/ContentWrapper';
import { SplitContent } from '../../../../ui/content-group/SplitContent';
import useDebounce from '../../../../utils/hooks/useDebounce';
import { getSchemaFields } from '../../../formulas/FormulaEditor/helpers';
import { FormulaPreviewGrid } from '../../../formulas/FormulaPreview/FormulaPreviewGrid';
import { FieldsFormValues } from '../form-model/formModels';

const getContextOptions = (intl: IntlShape) => [
  {
    id: 'queue.name',
    label: intl.formatMessage({
      id: 'features.queueSettings.fields.reasoning.meta.queueName',
    }),
  },
  {
    id: 'workspace.name',
    label: intl.formatMessage({
      id: 'features.queueSettings.fields.reasoning.meta.workspaceName',
    }),
  },
  {
    id: 'self.attr.label',
    label: intl.formatMessage({
      id: 'features.queueSettings.fields.reasoning.meta.label',
    }),
  },
  {
    id: 'self.attr.description',
    label: intl.formatMessage({
      id: 'features.queueSettings.fields.reasoning.meta.description',
    }),
  },
];

export const ReasoningTiles = ({
  control,
  schemaContent,
  queues,
}: {
  control: Control<FieldsFormValues, undefined>;
  schemaContent: SchemaSection[];
  queues: Queue[];
}) => {
  const [advancedVisible, setAdvancedVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);

  const schemaId = useWatch({ control, name: 'field.id' });

  // Make `draftSchemaContent` null when schema id is not filled in
  // This prevents zod encoder error because of missing ID
  const conditionalSchemaContent = schemaId ? schemaContent : null;
  const draftSchemaContent = useDebounce(conditionalSchemaContent, 1000);

  const { field } = useController({ control, name: 'field.context' });
  const [fields, attributes] = partition(field.value, item =>
    item.startsWith('field.')
  );

  const [setFields, setAttributes] = [
    // Purposely shadowing the one I am changing.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (fields: string[]) => field.onChange([...fields, ...attributes]),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (attributes: string[]) => field.onChange([...fields, ...attributes]),
  ];

  // TODO: Proper escaping with "\" so that python code does not crash.
  const makeField = (id: string) => `field.${id}`;

  const intl = useIntl();

  const schemaFieldOptions = useMemo(
    () => getSchemaFields(schemaContent),
    [schemaContent]
  );

  const schemaFields = useMemo(
    () =>
      schemaFieldOptions.filter(f => fields.includes(makeField(f.id))) ??
      schemaFieldOptions[0],
    [fields, schemaFieldOptions]
  );

  const contextOptions = getContextOptions(intl);

  return (
    <ContentWrapper>
      <SplitContent
        title={intl.formatMessage({
          id: 'features.queueSettings.fields.form.context.title',
        })}
        description={intl.formatMessage({
          id: 'features.queueSettings.fields.form.context.description',
        })}
        disabled={!schemaId}
      >
        <Stack gap={3}>
          <Autocomplete
            multiple
            size="small"
            options={schemaFieldOptions}
            getOptionLabel={option => `${option.label} (${option.id})`}
            value={schemaFields}
            onChange={(_e, vs) => {
              setFields(vs.map(v => makeField(v.id)));
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: 'features.queueSettings.fields.form.relevantFields.placeholder',
                })}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            )}
          />
          <Collapse in={advancedVisible}>
            <Stack gap={3}>
              <Divider />
              <Autocomplete
                multiple
                size="small"
                options={contextOptions}
                getOptionLabel={option => `${option.label}`}
                value={contextOptions.filter(op => attributes.includes(op.id))}
                onChange={(_e, vs) => {
                  setAttributes(vs.map(({ id }) => id));
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'features.queueSettings.fields.reasoning.meta.title',
                    })}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                )}
              />
              <Controller
                control={control}
                name="field.prompt"
                render={({ field: promptField }) => (
                  <TextField
                    multiline
                    label={intl.formatMessage({
                      id: 'features.queueSettings.fields.form.additionalInstructions.placeholder',
                    })}
                    helperText={intl.formatMessage({
                      id: 'features.queueSettings.fields.form.additionalInstructions.helperText',
                    })}
                    size="small"
                    {...promptField}
                  />
                )}
              />
            </Stack>
          </Collapse>
        </Stack>
      </SplitContent>
      <Stack spacing={2} pt={3}>
        {draftSchemaContent ? (
          <Collapse in={previewVisible}>
            <Divider />
            <Paper>
              <FormulaPreviewGrid
                queueUrls={queues.map(({ url }) => url)}
                currentFormulaId={schemaId}
                schemaForPreview={draftSchemaContent}
                type="formula"
              />
            </Paper>
          </Collapse>
        ) : null}

        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setAdvancedVisible(v => !v)}
            disabled={!schemaId}
          >
            {advancedVisible
              ? intl.formatMessage({
                  id: 'features.formulas.reasoningTiles.hideOptions',
                })
              : intl.formatMessage({
                  id: 'features.formulas.reasoningTiles.showOptions',
                })}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setPreviewVisible(v => !v)}
            disabled={!schemaId}
          >
            {previewVisible
              ? intl.formatMessage({
                  id: 'features.formulas.reasoningTiles.hideTest',
                })
              : intl.formatMessage({
                  id: 'features.formulas.reasoningTiles.testReasoning',
                })}
          </Button>
        </Stack>
      </Stack>
    </ContentWrapper>
  );
};
