import {
  GridCell,
  GridCellProps,
  GridValidRowModel,
  GridValueSetterParams,
  useGridApiContext,
} from '@rossum/ui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { fromEntries } from 'remeda';
import { aggregationRow } from '../constants';
import { formatAggregate } from './aggregations';
import { getColumns } from './columns/columns';
import { DetailGridField } from './rows/rowTypes';

const BatchEditCellStateHandler = ({
  setBatchEditError,
  ...props
}: Omit<GridCellProps, 'editCellState'> & {
  editCellState: NonNullable<GridCellProps['editCellState']>;
  setBatchEditError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const apiRef = useGridApiContext();
  const { editCellState, field } = props;
  const { value, changeReason, error } = editCellState;
  const hasChanged = !!changeReason;
  const intl = useIntl();

  const columsMap = fromEntries(getColumns({ intl }).map(c => [c.field, c]));

  useEffect(() => {
    if (hasChanged) {
      const selectedRows = Array.from(
        apiRef.current.getSelectedRows().values()
      );

      const columnValueSetter = columsMap[field]?.valueSetter;
      const defaultValueSetter = (
        params: GridValueSetterParams<GridValidRowModel>
      ) => ({
        ...params.row,
        [field]: params.value,
      });
      const fieldValueSetter = columnValueSetter ?? defaultValueSetter;

      const updatedRows = selectedRows.map(row =>
        // @ts-expect-error GridValidRowModel returned from getSelectedRows is less strict version of GridRowModel
        fieldValueSetter({ row, value })
      );

      apiRef.current.updateRows(updatedRows);
      setBatchEditError(!!error);
    }
  }, [value, hasChanged, apiRef, field, error, setBatchEditError, columsMap]);

  return <GridCell {...props} />;
};

export const CustomGridCell = ({
  selectionActive,
  setBatchEditError,
  editCellState,
  onVariantsClick,
  field, // Need to move field out of props to type it
  ...props
}: GridCellProps & {
  field: DetailGridField;
  selectionActive: boolean;
  setBatchEditError: React.Dispatch<React.SetStateAction<boolean>>;
  onVariantsClick: (
    fieldType: DetailGridField,
    aggregations: Set<string>
  ) => void;
}) => {
  const intl = useIntl();
  return editCellState && selectionActive ? (
    <BatchEditCellStateHandler
      {...props}
      field={field}
      editCellState={editCellState}
      setBatchEditError={setBatchEditError}
    />
  ) : (
    <GridCell {...props} field={field} editCellState={editCellState}>
      {props.rowId === aggregationRow
        ? formatAggregate(field, intl, props.value, onVariantsClick)
        : props.children}
    </GridCell>
  );
};
