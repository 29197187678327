import { ArrowBack } from '@rossum/ui/icons';
import {
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PoweredBy from '../../components/UI/PoweredBy';
import CallToActionButton from '../../containers/CallToAction';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { UserPanel } from '../user-panel';

export const EditDocumentTopBar = ({
  documentName,
  onLeaveEditMode,
}: {
  documentName: string | null | undefined;
  onLeaveEditMode: () => void;
}) => {
  const intl = useIntl();
  const isTrial = useSelector(isTrialSelector);

  return (
    <Stack
      direction="row"
      spacing={1}
      component={Paper}
      elevation={0}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', minWidth: 0 }}
      >
        <IconButton onClick={onLeaveEditMode} data-cy="exit-route-button">
          <ArrowBack />
        </IconButton>
        <Stack sx={{ minWidth: 0 }}>
          {documentName ? (
            <Tooltip title={documentName}>
              <Typography variant="h6" component={Stack} direction="row">
                {/* TODO reuse TruncatedName component */}
                {intl.formatMessage(
                  { id: 'containers.editDocument.editingDocumentName' },
                  { documentName }
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Skeleton width={200} />
          )}
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ pl: 2 }} />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ flexShrink: 0 }}>
        {isTrial && <CallToActionButton ctaLocation="navigation-panel" />}
        <PoweredBy />
        <UserPanel />
      </Stack>
    </Stack>
  );
};
