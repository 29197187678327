import { ValidationInput } from '@rossum/rossum-ui/ValidationInput';
import React, { ReactNode } from 'react';
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';

const ControlledValidationInput = <T extends FieldValues>({
  className,
  clearValidationsOnChange,
  control,
  dataCy,
  disabled,
  getErrorMessage,
  isMultiInput,
  maxLength,
  name,
  rightIcon,
  rightIconProps,
  type,
  placeholder,
  autoComplete,
}: {
  control: Control<T>;
  getErrorMessage: (
    _id: string,
    _errorMessage: FieldError
  ) => string | undefined;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  name: Path<T>;
  maxLength?: number;
  isMultiInput?: boolean;
  dataCy?: string;
  type?: 'text' | 'password';
  rightIcon?: ReactNode;
  rightIconProps?: unknown;
  clearValidationsOnChange?: (
    _onChange: (_event: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
  autoComplete?: string;
}) => {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ValidationInput
      className={className}
      dataCy={dataCy}
      disabled={disabled}
      autoComplete={autoComplete}
      placeholder={placeholder}
      errorMessage={error ? getErrorMessage(name, error) ?? '' : ''}
      onBlur={onBlur}
      maxLength={maxLength}
      pureOnChange={
        clearValidationsOnChange ? clearValidationsOnChange(onChange) : onChange
      }
      isMultiInput={isMultiInput}
      setRef={ref}
      value={value as string}
      type={type}
      rightIconProps={rightIconProps}
      rightIcon={rightIcon}
    />
  );
};

export default ControlledValidationInput;
