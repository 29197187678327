import { DescriptionOutlined } from '@rossum/ui/icons';
import { Chip } from '@rossum/ui/material';
import { ReactNode } from 'react';

type EmailCardChipProps = {
  showAttachmentError: boolean;
  label: ReactNode;
};

export const EmailCardChip = ({
  showAttachmentError,
  label,
}: EmailCardChipProps) => (
  <>
    <DescriptionOutlined
      fontSize="small"
      sx={{
        color: theme => theme.palette.text.secondary,
      }}
    />

    <Chip
      label={label}
      color={showAttachmentError ? 'error' : 'default'}
      sx={{
        maxWidth: 350,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'inherit',
      }}
    />
  </>
);
