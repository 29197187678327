import { produce } from 'immer';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { getCurrentRelationShape } from '../../../features/duplicates/helpers';
import { RootActionType } from '../../rootActions';
import { clearAnnotationData, exitAnnotation } from '../annotation/actions';
import { deleteAnnotations } from '../annotations/actions';
import { deleteQueueFulfilled } from '../queues/actions';
import { fetchRelationsFulfilled } from './actions';
import { RelationState } from './types';

const initialState: RelationState = {
  list: [],
  currentRelation: undefined,
};

const relationsReducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchRelationsFulfilled): {
      const currentRelation = getCurrentRelationShape(
        action.payload,
        action.meta.urls
      );

      return { ...state, currentRelation };
    }

    case getType(deleteAnnotations): {
      return produce(state, draft => {
        if (!draft.currentRelation) return;
        draft.currentRelation.annotations =
          draft.currentRelation?.annotations.map(annotation =>
            action.meta.urls.find(url => url === annotation.url)
              ? { ...annotation, status: 'deleted' }
              : annotation
          ) ?? [];
      });
    }

    case getType(deleteQueueFulfilled):
    case getType(exitAnnotation):
    case getType(clearAnnotationData):
      return initialState;

    default:
      return state;
  }
};

export default relationsReducer;
