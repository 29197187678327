import { Queue } from '@rossum/api-client/queues';
import { AjaxError } from 'rxjs/ajax';
import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../types/basic';
import {
  CreateQueueFulfilledMeta,
  FetchQueuesFulfilledPayload,
  FetchQueuesMeta,
  UpdateUsersOnQueueMeta,
  UpdateUsersOnQueuePayloadMeta,
} from './types';

export const clearQueues = createAction('CLEAR_QUEUES')<void>();

export const fetchQueues = createAction(
  'FETCH_QUEUES',
  undefined,
  ({ url }: Partial<FetchQueuesMeta> | undefined = {}) => ({
    url,
  })
)<undefined, FetchQueuesMeta>();

export const fetchQueue = createAction('FETCH_QUEUE', undefined, (id: ID) => ({
  id,
}))<undefined, { id: ID }>();

export const fetchQueuesFulfilled = createAction(
  'FETCH_QUEUES_FULFILLED',
  (payload: FetchQueuesFulfilledPayload) => payload
)<FetchQueuesFulfilledPayload>();

export const fetchQueueFulfilled = createAction(
  'FETCH_QUEUE_FULFILLED',
  (payload: Queue) => payload
)<Queue>();

export const deleteQueueFulfilled = createAction(
  'DELETE_QUEUE_FULFILLED',
  (id: ID) => ({
    id,
  })
)<{ id: ID }>();

export const allQueuesWereFetched = createAction(
  'ALL_QUEUES_WERE_FETCHED'
)<void>();

export const updateQueueDetailFailed = createAction(
  'UPDATE_QUEUE_DETAIL_FAILED',
  (_id: ID, payload: AjaxError) => payload,
  (id, _payload) => ({ id })
)();

export const updateQueueDetail = createAction(
  'UPDATE_QUEUE_DETAIL',
  (_id: ID, payload: Partial<Queue>) => payload,
  (id, _payload) => ({ id })
)<Partial<Queue>, { id: ID }>();

export const updateQueueDetailFulfilled = createAction(
  'UPDATE_QUEUE_DETAIL_FULFILLED',
  (_id: ID, payload: Partial<Queue>) => payload,
  (id, _payload) => ({ id })
)<Partial<Queue>, { id: ID }>();

export const updateUsersOnQueue = createAction(
  'UPDATE_USERS_ON_QUEUE',
  ({ results }: UpdateUsersOnQueuePayloadMeta) => results,
  ({ adding, queueId, url }) => ({ adding, url, queueId })
)<string[], UpdateUsersOnQueueMeta>();

export const createQueueFulfilled = createAction(
  'CREATE_QUEUE_FULFILLED',
  (payload: Queue, _?: CreateQueueFulfilledMeta) => payload,
  (_: Queue, meta: CreateQueueFulfilledMeta = { withMessage: true }) => meta
)<Queue, CreateQueueFulfilledMeta>();

export const queueNotFound = createAction('QUEUE_NOT_FOUND')<void>();

export const removeQueueFromState = createAction(
  'REDIRECT_TO_NEXT_QUEUE',
  (id: ID) => ({ queueId: id })
)<{ queueId: ID }>();

export type UpdateQueueDetail = typeof updateQueueDetail;

export type QueuesActions = ActionType<
  | typeof allQueuesWereFetched
  | typeof createQueueFulfilled
  | typeof fetchQueue
  | typeof fetchQueueFulfilled
  | typeof fetchQueues
  | typeof fetchQueuesFulfilled
  | typeof queueNotFound
  | typeof updateQueueDetailFulfilled
  | typeof updateUsersOnQueue
  | typeof updateQueueDetailFailed
  | UpdateQueueDetail
  | typeof clearQueues
  | typeof deleteQueueFulfilled
  | typeof removeQueueFromState
>;
