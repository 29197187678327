import { History, Location } from 'history';
import {
  compact,
  flow,
  get,
  includes,
  isNaN,
  last,
  map,
  negate,
  pickBy,
} from 'lodash';
import {
  parse as queryStringParse,
  ParseOptions,
  stringify as queryStringStringify,
} from 'query-string';
import { isArray } from 'remeda';
import { dataMatchingUrl, isPathBasedEmbeddedMode } from '../constants/config';
import { Url } from '../types/basic';
import {
  camelToSnake,
  convertKeys,
  convertStringValuesToSnake,
} from './keyConvertor';
import { getAuthToken } from './token';

export const asArray = <T>(value: T | T[] | null): T[] => {
  return value ? (isArray(value) ? value : [value]) : [];
};

export const asScalar = <T>(value: T | T[]): T => {
  return isArray(value) ? value[0] : value;
};

export const parse = (string: string, parseOptions?: ParseOptions) =>
  queryStringParse(string, { arrayFormat: 'comma', ...parseOptions });
export const stringify = (query: Parameters<typeof queryStringStringify>[0]) =>
  queryStringStringify(query, { arrayFormat: 'comma' });

const isQuestionMark = (char: string): boolean => char !== '?';

const trimQuestionMark = (string: string): string =>
  string.split('').filter(isQuestionMark).join('');

export const getDatapointPathFromSearch = (search = ''): number[] =>
  flow(
    trimQuestionMark,
    parse,
    query => query.datapointPath,
    path => (path ? ([] as string[]).concat(path) : []),
    (path: string[]) => map(path, Number)
  )(search);

export const getCurrentQueueId = (
  path: string | null | undefined
): number | null | undefined => {
  if (!path) {
    return null;
  }

  const match = path.match(/^.*\/(annotations|emails|queues)\/(?<queueId>\d+)/);

  if (match?.groups?.queueId) {
    const parsed = Number(match.groups.queueId);

    return Number.isNaN(parsed) ? null : parsed;
  }

  return null;
};

const ANNOTATION_ID_REGEX = /^\/document\/((\d+))/;
const ANNOTATION_ID_EMBEDDED_REGEX = /^\/embedded\/document\/((\d+))/;

export const getCurrentAnnotationId = (path: string): number =>
  Number(
    (path.match(
      isPathBasedEmbeddedMode()
        ? ANNOTATION_ID_EMBEDDED_REGEX
        : ANNOTATION_ID_REGEX
    ) || [])[1]
  );
export const getAnnotationId = (path: string): number =>
  Number((path.match(/^.*\/annotations\/((\d+))/) || [])[1]);

const constructQuery = flow(
  convertKeys(camelToSnake),
  convertStringValuesToSnake([
    'search',
    'name',
    'phrase',
    'content.schema_id',
    'events',
  ]),
  stringify
);

export const constructUrl = (
  url: string,
  query: unknown | null | undefined,
  queryConstructor = constructQuery,
  hash?: string
) => {
  const strQuery = queryConstructor(query);
  const joint = strQuery ? (includes(url, '?') ? '&' : '?') : '';

  return `${url}${joint}${strQuery}${hash ? `#${hash}` : ''}`;
};

const supportedExtensionsUrls = [dataMatchingUrl];
const supportedExtensionsNames = ['data-matching', 'data-matching-legacy'];

const shouldAppendToken = (link: string) =>
  // URL check - useful also for local testing and static URLs
  supportedExtensionsUrls.includes(link) ||
  // more benevolent way for checking URL in hook templates
  (supportedExtensionsNames.some(extension => link.includes(extension)) &&
    (link.includes('.elis.') ||
      link.includes('.rossum.ai') ||
      link.includes('.rossum.app') ||
      link.includes('.r8.lol')));

export const buildAuthLink = (link: Url) =>
  shouldAppendToken(link) ? `${link}#token=${getAuthToken()}` : link;

export const getIDFromString = (
  URL: string | Url | undefined
): number | undefined =>
  URL ? last(URL.split('/').map(Number).filter(negate(isNaN))) : undefined;

export const getIDsFromURLs = (urls: Url[]) =>
  compact(urls.map(getIDFromString));

// TODO: MEGAFUJ
export const setQueryCreator =
  (history: History) =>
  (queries: Record<string, unknown> & { pathname?: string }, hash?: string) => {
    const changingPage = !!queries.page;
    const { pathname, ..._queries } = queries;
    const query = pickBy({
      ...parse(history.location.search),
      ...(changingPage ? _queries : { ..._queries, page: 1 }),
    });
    const search = stringify(query);

    history.push({
      pathname: pathname || history.location.pathname,
      search,
      hash,
      state: get(history, 'location.state'),
    });
  };

type ConstructDocumentUrlParams = {
  id?: number;
  pathname?: string;
  query?: object;
  hash?: string;
  nestedPath?: string;
};

export const constructDocumentUrl = ({
  id,
  pathname,
  query = {},
  hash = '',
  nestedPath = '',
}: ConstructDocumentUrlParams) =>
  constructUrl(
    pathname ||
      `${
        isPathBasedEmbeddedMode() ? '/embedded/document' : '/document'
      }/${id}${nestedPath}`,
    query,
    stringify,
    hash
  );

export const AUTH_CODE = 'authCode';

export const removeTokenFromLocationHash = (location: Location) => {
  const { hash, ...originalLocation } = location;
  const {
    authToken,
    // use const with hardcoded string and rename extracted value
    [AUTH_CODE]: _authCode,
    ...restHash
  } = parse(hash);

  return {
    ...originalLocation,
    hash: stringify(restHash),
  };
};
