import { ChevronRight } from '@rossum/ui/icons';
import { Skeleton, Stack, Typography } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';

type Props = {
  activeQueueId: number | undefined;
  allDocumentsLevelIsActive?: boolean;
};

const BreadCrumbs = ({ activeQueueId, allDocumentsLevelIsActive }: Props) => {
  const intl = useIntl();

  const { allWorkspacesWithQueues, isLoading } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const currentWorkspace = allWorkspacesWithQueues?.find(ws =>
    ws.queues.some(q => q.id === activeQueueId)
  );
  const currentWorkspaceName = currentWorkspace?.name;
  const currentQueueName = currentWorkspace?.queues.find(
    q => q.id === activeQueueId
  )?.name;

  const skeletonQueueWidth = useRef(random(50, 100));
  const skeletonWorkspaceWidth = useRef(random(50, 100));

  if (!allDocumentsLevelIsActive && isLoading) {
    return (
      <Stack flexDirection="row" alignItems="center">
        <Skeleton variant="text" width={skeletonWorkspaceWidth.current} />
        <ChevronRight fontSize="small" color="disabled" sx={{ mx: 1 }} />
        <Skeleton variant="text" width={skeletonQueueWidth.current} />
      </Stack>
    );
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      {allDocumentsLevelIsActive ? (
        <Typography variant="h5">
          {intl.formatMessage({
            id: 'containers.settings.sidebar.allDocuments',
          })}
        </Typography>
      ) : (
        <>
          <Typography variant="body2" color="text.secondary">
            {currentWorkspaceName}
          </Typography>
          <ChevronRight fontSize="small" color="disabled" sx={{ mx: 1 }} />
          <Typography variant="body2">{currentQueueName}</Typography>
        </>
      )}
    </Stack>
  );
};

export { BreadCrumbs };
