import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  batchDatapointConfirm,
  batchDatapointDelete,
  materializeVirtualDatapoints,
  nextDatapoint,
  updateDatapointValue,
} from '../../../../../redux/modules/datapoints/actions';
import {
  currentMultivalueDatapointSelector,
  datapointsSelector,
} from '../../../../../redux/modules/datapoints/selector';
import { suggestedOperationsSelector } from '../../../../../redux/modules/datapoints/suggestedOperations/selector';
import { isSimpleDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import { SimpleDatapointDataST } from '../../../../../types/datapoints';
import { State } from '../../../../../types/state';
import { useCanvasSelectionActions } from '../../../document-store/DocumentStore';
import { createMaterializePayload } from './createMaterializePayload';
import { getTuplesFromSelectedDatapoints } from './getTuplesFromSelectedDatapoints';

export const useValidationDialogCallbacks = () => {
  // Suggested operations
  const suggestedOperations = useSelector(suggestedOperationsSelector);
  const { clearSelectedBboxes } = useCanvasSelectionActions();
  const pendingMaterialization = useSelector(
    (state: State) => state.datapoints.pendingMaterialization
  );

  const allDatapoints = useSelector(datapointsSelector);
  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );

  const dispatch = useDispatch();
  const handleDatapointValueChange = useCallback(
    (
      datapointId: number,
      datapointIndex: number,
      value: string,
      oldValue: string
    ) => {
      dispatch(
        updateDatapointValue(
          {
            id: datapointId,
            index: datapointIndex,
            oldValue,
            reason: 'input-edit-box',
          },
          value
        )
      );
    },
    [dispatch]
  );

  const handleTupleDelete = useCallback(
    (selectedDatapoints: SimpleDatapointDataST[]) => {
      const tuplesToDelete = getTuplesFromSelectedDatapoints(
        allDatapoints,
        selectedDatapoints
      );
      const payload = {
        datapointsToReset: [],
        tuplesToDelete,
      };

      dispatch(batchDatapointDelete(payload));
      clearSelectedBboxes();
    },
    [allDatapoints, clearSelectedBboxes, dispatch]
  );

  const handleDatapointsDelete = useCallback(
    (selectedDatapoints: SimpleDatapointDataST[]) => {
      const payload = {
        datapointsToReset: selectedDatapoints.map(dp => ({
          id: dp.id,
          index: dp.meta.index,
        })),
        tuplesToDelete: [],
      };

      dispatch(batchDatapointDelete(payload));
      clearSelectedBboxes();
    },
    [clearSelectedBboxes, dispatch]
  );

  const handleDatapointConfirm = useCallback(
    (selectedDatapoints: SimpleDatapointDataST[], next?: boolean) => {
      const replaceOperations = Object.values(suggestedOperations).filter(
        op => op.source === 'table' && op.op === 'replace'
      );

      const materializePayload = currentMultivalueDatapoint
        ? createMaterializePayload(
            allDatapoints,
            replaceOperations,
            selectedDatapoints,
            currentMultivalueDatapoint
          )
        : undefined;

      // On confirm, materialize selected suggestion boxes if you are in a multivalue.
      // Do not materialize if another materialization is in progress - risk of race condition.
      if (
        materializePayload &&
        (materializePayload.tuplesToCreate.length ||
          materializePayload.datapointsToReplace.length) &&
        !pendingMaterialization
      ) {
        dispatch(materializeVirtualDatapoints(materializePayload));
      }

      if (!next) {
        const datapointsToReplace =
          materializePayload?.datapointsToReplace ?? [];
        const tuplesToCreate = materializePayload?.tuplesToCreate ?? [];
        const affectedIds: number[] = [
          ...datapointsToReplace.map(dp => dp.datapointId),
          ...tuplesToCreate.flatMap(tuple => tuple.validatedDatapointIds),
        ];

        // Subtract datapoints created during materialization step.
        const regularDatapoints = selectedDatapoints.filter(
          dp => !affectedIds.includes(dp.id)
        );

        const datapoints = regularDatapoints
          .filter(isSimpleDatapoint)
          .filter(dp => !dp.schema?.hidden);

        dispatch(batchDatapointConfirm({ datapoints }));
      } else {
        dispatch(nextDatapoint());
      }
      clearSelectedBboxes();
    },
    [
      allDatapoints,
      clearSelectedBboxes,
      currentMultivalueDatapoint,
      dispatch,
      pendingMaterialization,
      suggestedOperations,
    ]
  );

  return {
    handleDatapointValueChange,
    handleDatapointConfirm,
    handleTupleDelete,
    handleDatapointsDelete,
  };
};
