import { endpoints } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { camelToSnake } from '@rossum/api-client/utils';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { api } from '../../../../lib/apiClient';
import { convertKeys } from '../../../../lib/keyConvertor';
const VALIDATE_SCHEMA_KEY = 'validate-schema';

export const useValidateSchema = () => {
  return useMutation([VALIDATE_SCHEMA_KEY], (payload: Schema) =>
    api.request({
      // tl;dr overriding payload schema with unknown so incorrect form values does not prevent the request from being made
      ...endpoints.schemas.validate(convertKeys(camelToSnake)(payload)),
      payloadSchema: z.unknown(),
    })
  );
};

// what happens if we don't  override the payload schema:
// zod validation on the api throws the error and stops the request
// all existing BE errors disappear from the form because request is not made and response is null.
