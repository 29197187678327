import { Alert, AlertProps, AlertTitle, Snackbar } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { actionMap } from './actions';
import { ToastAction } from './types';

export type ToasterMessageProps = {
  id?: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  isDismissible?: boolean;
  onClose?: () => void;
  toastAction?: ToastAction;
  severity: NonNullable<AlertProps['severity']>;
};

export const ToasterMessage = ({
  title,
  description,
  severity,
  onClose,
  toastAction,
}: ToasterMessageProps) => {
  const intl = useIntl();

  const Action = toastAction
    ? actionMap[toastAction.key]({ ...toastAction.props, intl })
    : null;

  return (
    <Snackbar data-cy={`${severity}-toaster`} sx={{ position: 'static' }} open>
      <Alert
        variant="outlined"
        severity={severity}
        onClose={onClose}
        action={Action}
      >
        <AlertTitle sx={{ marginBottom: description ? undefined : 0 }}>
          {title}
        </AlertTitle>
        {description || null}
      </Alert>
    </Snackbar>
  );
};
