import { createReactComponent } from '@tabler/icons-react';

export const IconCustomCrystalBallEdited = createReactComponent(
  'outline',
  'customCrystalBallEdited',
  'CustomCrystalBallEdited',
  [
    [
      'path',
      {
        key: 'customCrystalBallEdited-path',
        d: 'M5.60825 14.1817C4.58792 13.2883 3.86431 12.105 3.53401 10.7897C3.2037 9.47432 3.28241 8.08953 3.75962 6.82009C4.23683 5.55065 5.08985 4.45693 6.20483 3.68491C7.31982 2.91289 8.64374 2.49928 9.99992 2.49928C11.3561 2.49928 12.68 2.91289 13.795 3.68491C14.91 4.45693 15.763 5.55065 16.2402 6.82009C16.4736 7.44097 16.6117 8.08945 16.6532 8.7439M9.16659 17.5H5.83325C5.39122 17.5 4.9673 17.3244 4.65474 17.0118C4.34218 16.6993 4.16659 16.2754 4.16659 15.8333C4.16659 15.3913 4.34218 14.9674 4.65474 14.6548C4.9673 14.3423 5.39122 14.1667 5.83325 14.1667H9.16659M9.16659 5.83333C8.50354 5.83333 7.86766 6.09672 7.39882 6.56556C6.92998 7.0344 6.66659 7.67029 6.66659 8.33333M15.35 13.0083C15.5125 12.8458 15.7055 12.7169 15.9178 12.6289C16.1301 12.541 16.3577 12.4957 16.5875 12.4957C16.8173 12.4957 17.0449 12.541 17.2572 12.6289C17.4696 12.7169 17.6625 12.8458 17.825 13.0083C17.9875 13.1708 18.1164 13.3638 18.2044 13.5761C18.2923 13.7884 18.3376 14.016 18.3376 14.2458C18.3376 14.4756 18.2923 14.7032 18.2044 14.9155C18.1164 15.1279 17.9875 15.3208 17.825 15.4833L15 18.3333H12.5V15.8333L15.35 13.0083Z',
      },
    ],
  ]
);
