import { OrganizationGroup } from '../../types/organizationGroup';
import {
  ActiveSubscriptionUsage,
  getSubscriptionUsageSchema,
  SoftLimit,
  SoftLimitKeys,
} from './schemas';

export const ACCESSIBLE_ANNOTATIONS_FILTER_ITEM = {
  field: 'restricted_access',
  value: false,
  operator: 'equals',
};

export const getRateLimitFeatureMap = (
  subscriptionUsage: ActiveSubscriptionUsage
) => {
  const { queues, documentArchive, sandboxes: sandbox } = subscriptionUsage;

  return {
    queues: {
      ...queues,
      hasExceededLimit: !queues.ignore && queues.limit < queues.used,
    },
    documentArchive: {
      ...documentArchive,
      hasExceededLimit:
        !documentArchive.ignore && documentArchive.limit < documentArchive.used,
    },
    sandboxes: {
      ...sandbox,
      hasExceededLimit: !sandbox.ignore && sandbox.limit < sandbox.used,
    },
  } as const satisfies Record<
    SoftLimitKeys,
    SoftLimit & { hasExceededLimit: boolean }
  >;
};

const trialSubscriptionFallback: ActiveSubscriptionUsage = {
  queues: { ignore: true, limit: 0, used: 0 },
  documentArchive: { ignore: true, limit: 0, used: 0 },
  attachments: { purchased: true },
  sandboxes: { ignore: true, limit: 0, used: 0 },
  extensionLogs: { purchased: true },
  extensions: { purchased: true },
  embeddedMode: { purchased: true },
  duplicateHandling: { purchased: true },
  intelligentMailbox: { purchased: true },
  usageReports: { purchased: true },
  // features that are not enabled to trials by default
  approvalWorkflows: { purchased: false },
  labels: { purchased: false },
  // hasSubscriptionPlan flag is an easy check to see if the pricing limits take effect or not.
  // It can be false if Sales Force toggle is turned off or the usage object coming from Django is wrong
  formulaFields: { purchased: false },
  hasSubscriptionPlan: false,
};

type OrgGroupWithUnknownUsageType = Omit<OrganizationGroup, 'usage'> & {
  usage: unknown;
};

export const getLimitsWithoutSubscription = ({
  features,
}: OrgGroupWithUnknownUsageType): ActiveSubscriptionUsage => {
  return {
    ...trialSubscriptionFallback,
    usageReports: {
      purchased:
        features?.usageReports?.enabled ??
        trialSubscriptionFallback.usageReports.purchased,
    },

    attachments: {
      purchased:
        features?.attachments?.enabled ??
        trialSubscriptionFallback.attachments.purchased,
    },
    // features that are not enabled to trials by default
    labels: { purchased: Boolean(features?.labels?.enabled) },
    approvalWorkflows: {
      purchased: Boolean(features?.approvalWorkflows?.enabled),
    },
    formulaFields: {
      purchased: Boolean(features?.formulaFields?.enabled),
    },
  };
};

// We should default to fallback if the incoming usage object is an empty object, or somehow in wrong format
// If the pricing toggle in SF is turned off, or the client is in trial mode the object will be empty.
export const safeCheckSubscriptionUsage = (
  organizationGroup: OrgGroupWithUnknownUsageType
): ActiveSubscriptionUsage => {
  const fallbackUsage = getLimitsWithoutSubscription(organizationGroup);

  const orgGroupUsage = organizationGroup.usage;

  const subscriptionUsageSchema = getSubscriptionUsageSchema(
    organizationGroup.features
  );

  const parsedUsage =
    typeof orgGroupUsage === 'object' &&
    orgGroupUsage !== null &&
    Object.keys(orgGroupUsage).length === 0
      ? ({ success: false } as const)
      : subscriptionUsageSchema.safeParse(orgGroupUsage);

  if (!parsedUsage.success) return fallbackUsage;

  return {
    ...parsedUsage.data,
    hasSubscriptionPlan: true,
  };
};
