import { createReactComponent } from '@tabler/icons-react';

export const IconCustomFolderMove = createReactComponent(
  'outline',
  'customFolderMove',
  'CustomFolderMove',
  [
    [
      'path',
      {
        key: 'customFolderMove-path',
        d: 'M12 19H5C4.36957 19 3.78086 18.7893 3.5858 18.4142C3.39074 18.0391 3 17.449 3 16.8V6C3 5.33761 3.39074 4.7475 3.5858 4.37241C3.78086 3.99732 4.36957 3.8 5 3.8H9L12 7H19C19.6304 7 20.2191 7.21074 20.4142 7.5858C20.6093 7.96086 21 8.551 21 9.2V12.5M16 19H22M22 19L19 22M22 19L19 16',
      },
    ],
  ]
);
