import { Stack, Typography } from '@rossum/ui/material';
import { forwardRef } from 'react';

const divideString = (name: string) => {
  const midpoint = Math.floor(name.length / 2);
  const firstPart = name.slice(0, midpoint);
  const secondPart = name.slice(midpoint, name.length);
  return { firstPart, secondPart };
};

export const TruncatedName = forwardRef<HTMLDivElement, { name: string }>(
  ({ name, ...rest }, ref) => {
    const { firstPart, secondPart } = divideString(name);
    return (
      <Stack direction="row" component="span" ref={ref} {...rest}>
        <Typography
          variant="inherit"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'inherit',
            minWidth: 0,
            position: 'relative',
          }}
        >
          {firstPart}
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'inherit',
            minWidth: 0,
            position: 'relative',
            direction: 'rtl',
          }}
        >
          &lrm;{secondPart}&lrm;
        </Typography>
      </Stack>
    );
  }
);
