import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { Stack } from '../../../types/stack';
import { RootActionType } from '../../rootActions';
import { ANNOTATION_STACK } from '../localStorage/actions';
import { startValidation } from '../ui/actions';
import { fetchAnnotationStackFulfilled, resetAnnotationStack } from './actions';

export const getCachedStack = () =>
  R.pipe(
    (localStorage.getItem(ANNOTATION_STACK) || '').split(','),
    R.filter(R.isTruthy)
  ).map(Number) || [];

const initialState: Stack = getCachedStack();

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAnnotationStackFulfilled):
      return action.payload;

    case getType(resetAnnotationStack):
    case getType(startValidation):
      return [];
    default:
      return state;
  }
};

export default reducer;
