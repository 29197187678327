import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';

export const useDeleteDataset = () => {
  const intl = useIntl();
  return useMutation({
    mutationKey: [
      '@MDH',
      'deleteDatasetApiV1DatasetDatasetNameDelete',
    ] as const,
    mutationFn: async ({ datasetName }: { datasetName: string }) => {
      return datasetApi
        .deleteDatasetApiV1DatasetDatasetNameDelete(
          datasetName,
          getDatasetRequestConfig()
        )
        .then(response => {
          return { taskId: getTaskIdFromResponse(response), datasetName };
        })
        .catch(err => {
          notify.error({
            title: intl.formatMessage(
              {
                id: 'features.datasets.context.taskNotification.delete.error.title',
              },
              { datasetName }
            ),
          });
          throw err;
        });
    },
  });
};
