import { snakeCaseStatuses } from '../../../types/annotationStatus';
import { MQLOperators } from './types';

export const FILTER_ITEMS_MENU_WIDTH = 252;
export const SIMPLE_COMPONENT_WIDTH = 352;
export const ADVANCED_COMPONENT_WIDTH = 562;
export const VALUE_INPUT_WIDTH = 250;

export const disallowedStatuses = ['purged', 'split', 'created'] as const;

export const allowedStatuses = snakeCaseStatuses.filter(
  status =>
    !disallowedStatuses.some(excludedStatus => excludedStatus === status)
);

export const dataGridOperatorsMap = {
  isEmpty: '$emptyOrMissing',
  isNotEmpty: '$emptyOrMissing',
  contains: '$containsPrefixes',
  equals: '$eq',
  startsWith: '$startsWith',
  isAnyOf: '$in',
  notIn: '$nin',
  is: '$eq',
  not: '$ne',
  after: '$gt',
  onOrAfter: '$gte',
  before: '$lt',
  onOrBefore: '$lte',
  '=': '$eq',
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
} as const satisfies Record<string, MQLOperators>;

export const detailsColumnOptionKeys = {
  automated: 'automated',
  automatically_rejected: 'automatically_rejected',
  emails: 'emails',
  attachments: 'attachments',
  duplicates: 'duplicates',
  edit: 'edit',
};
