import { ContentCut } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EditDocumentsRestrictor } from '../../../components/Restrictors';
import { constructDocumentUrl } from '../../../lib/url';

type SuggestedEditProps = {
  annotationId: number;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url: string
  ) => void;
  dataCySuffix: string;
};

const SuggestedEdit = ({
  annotationId,
  onClick,
  dataCySuffix,
}: SuggestedEditProps) => {
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const intl = useIntl();

  const linkUrl = constructDocumentUrl({ id: annotationId });

  const to = `${linkUrl}/edit`;

  return (
    <EditDocumentsRestrictor>
      <Tooltip
        placement="top"
        onOpen={() => !tooltipOpened && setTooltipOpened(true)}
        title={intl.formatMessage({
          id: 'containers.editDocument.suggestedSplit.tooltip',
        })}
        sx={{ maxWidth: '100px' }}
      >
        <div onClick={e => onClick && onClick(e, to)}>
          <Link
            onClick={e => !e.metaKey && !e.ctrlKey && e.preventDefault()}
            to={to}
            style={{ display: 'flex', alignItems: 'center' }}
            data-cy={`suggested-edit-indicator-${dataCySuffix}`}
          >
            <ContentCut
              fontSize="small"
              sx={{
                color: theme => theme.palette.warning.main,
                zIndex: 2,
              }}
            />
          </Link>
        </div>
      </Tooltip>
    </EditDocumentsRestrictor>
  );
};

export { SuggestedEdit };
