import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { z } from 'zod';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ThemeType } from '../../../constants/values';
import { localeOptions } from '../../../i18n/config';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { splitFullName } from '../../../redux/modules/user/helpers';
import { User } from '../../../types/user';
import FormRow, { FORM_FIELD_WIDTH } from '../components/FormRow';

type ThemeChoices = ThemeType | 'organization' | 'default';

type ThemeFieldOption = { value: ThemeChoices; label: string };

type UpdateUserPayload = {
  firstName: string;
  lastName: string;
  uiSettings: {
    locale: string | undefined;
    theme: ThemeType | undefined;
    showConfidenceScore: boolean | undefined;
  };
};

type UpdateUserMeta = { withMessage: boolean };

type Props = {
  intl: IntlShape;
  updateUser: (_payload: UpdateUserPayload, _meta: UpdateUserMeta) => void;
  user: User;
};

const themeSchema = z.enum(['dark', 'white']);

// ui settings can be set to any value. Fall back to default if invalid
// this function resolves what user sees in the dropdown menu
const resolveIncomingTheme = ({
  userTheme,
  orgTheme,
}: {
  userTheme?: ThemeType;
  orgTheme?: ThemeType;
}) => {
  const defaultTheme = orgTheme ? 'organization' : 'default';
  const parsed = themeSchema.safeParse(userTheme);

  return parsed.success ? parsed.data : defaultTheme;
};

const PersonalInformationForm = ({ intl, user, updateUser }: Props) => {
  const organization = useSelector(safeOrganizationSelector);

  const hasOrgTheme = !!organization?.uiSettings.theme;

  const initialThemeChoice = resolveIncomingTheme({
    userTheme: user.uiSettings.theme,
    orgTheme: organization?.uiSettings.theme,
  });

  const themeFieldOptions = useMemo<Array<ThemeFieldOption>>(() => {
    const defaultOption: ThemeFieldOption = hasOrgTheme
      ? {
          value: 'organization',
          label: intl.formatMessage({
            id: 'containers.personalInfo.theme.organization',
          }),
        }
      : {
          value: 'default',
          label: intl.formatMessage({
            id: 'containers.personalInfo.theme.default',
          }),
        };

    return [
      defaultOption,
      {
        value: 'dark',
        label: intl.formatMessage({ id: 'containers.personalInfo.theme.dark' }),
      },
      {
        value: 'white',
        label: intl.formatMessage({
          id: 'containers.personalInfo.theme.white',
        }),
      },
    ];
  }, [hasOrgTheme, intl]);

  // reset ui settings with default option
  const resolveOutgoingTheme = (selectedThemeChoice: ThemeChoices) => {
    return selectedThemeChoice === 'dark' || selectedThemeChoice === 'white'
      ? selectedThemeChoice
      : undefined;
  };

  const personalInformationValidationSchema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: intl.formatMessage({
          id: 'containers.settings.users.addUser.name.error',
        }),
      }),
    locale: z.string().optional(),
    theme: z.enum(['white', 'dark', 'organization', 'default']).optional(),
    showConfidenceScore: z.boolean().optional(),
  });

  const defaultValues = {
    name: R.pipe(
      [user.firstName, user.lastName],
      R.filter(R.isTruthy),
      R.join(' ')
    ),
    locale: user.uiSettings.locale,
    theme: initialThemeChoice,
    showConfidenceScore: user.uiSettings.showConfidenceScore,
  } as const;

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: zodResolver(personalInformationValidationSchema),
  });

  return (
    <form
      onSubmit={handleSubmit(({ name, locale, theme, showConfidenceScore }) =>
        updateUser(
          {
            firstName: splitFullName(name).firstName,
            lastName: splitFullName(name).lastName,
            uiSettings: {
              locale,
              theme: resolveOutgoingTheme(theme),
              showConfidenceScore,
            },
          },
          { withMessage: true }
        )
      )}
    >
      <Stack spacing={2} justifyContent="center" width={455}>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.settings.users.addUser.name.label',
            })}
          </FormLabel>
          <TextFieldControl
            FieldLabelProps={{ layout: 'none' }}
            id="input"
            type="string"
            ControllerProps={{ control, name: 'name' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.users.addUser.name.placeholder',
            })}
            inputProps={{
              'data-cy': 'my-profile-name-input',
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.settings.users.addUser.locale.label',
            })}
          </FormLabel>
          <TextFieldControl
            FieldLabelProps={{ layout: 'none' }}
            ControllerProps={{ control, name: 'locale' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.users.addUser.locale.placeholder',
            })}
            select
            autoComplete="off"
          >
            {localeOptions.map(locale => (
              <MenuItem
                key={locale.label}
                value={locale.value}
                data-cy={`queue-form-workspace-item-${locale.label}`}
              >
                {locale.label}
              </MenuItem>
            ))}
          </TextFieldControl>
        </FormRow>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.personalInfo.theme',
            })}
          </FormLabel>
          <TextFieldControl
            FieldLabelProps={{ layout: 'none' }}
            ControllerProps={{ control, name: 'theme' }}
            placeholder={intl.formatMessage({
              id: 'containers.personalInfo.theme.placeholder',
            })}
            select
            autoComplete="off"
          >
            {themeFieldOptions.map(option => (
              <MenuItem
                key={option.label}
                value={option.value}
                data-cy={`queue-form-workspace-item-${option.label}`}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextFieldControl>
        </FormRow>
        <Controller
          control={control}
          name="showConfidenceScore"
          render={({ field }) => (
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              label={
                <Stack direction="column" width={400}>
                  <Typography fontWeight="bold">
                    {intl.formatMessage({
                      id: 'containers.personalInfo.showConfidenceScore.title',
                    })}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      flex: 1,
                    }}
                  >
                    {intl.formatMessage({
                      id: 'containers.personalInfo.showConfidenceScore.description',
                    })}
                  </Typography>
                </Stack>
              }
              control={
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    paddingLeft: 0,
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  {...field}
                  checked={field.value}
                />
              }
            />
          )}
        />

        <Stack
          width={FORM_FIELD_WIDTH}
          alignSelf="flex-end"
          alignItems="center"
        >
          <Button
            type="submit"
            variant="contained"
            data-cy="my-profile-update-settings"
          >
            {intl.formatMessage({
              id: 'containers.settings.users.update',
            })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default PersonalInformationForm;
