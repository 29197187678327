import { TextField } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  doUnsavedValuesExist,
  getStringValueWithDefault,
  STARTS_WITH_OPERATOR_MIN_LENGTH,
} from '../../../document-list-base/mql/helpers';
import { SimpleFilterComponentProps } from '../../../document-list-base/mql/types';
import { FilterActionsWrapper } from './FilterActionsWrapper';
import { useOperators } from './Operators/useOperators';
import { resolveValueBasedOnBooleanOperator } from './Operators/utils';

type Props = SimpleFilterComponentProps & {
  minLength?: number;
};
export const FilterTextField = ({
  filterItem,
  minLength = 1,
  onBackClick,
  onClose,
  applyFilter,
  convertToAdvancedFilter,
  onOperatorChange,
}: Props) => {
  const intl = useIntl();

  const { filterContext } = filterItem;
  const [value, setValue] = useState<string>(
    getStringValueWithDefault(filterContext.value)
  );

  const handleOnClickOnMore = convertToAdvancedFilter
    ? () =>
        convertToAdvancedFilter(
          operator,
          doUnsavedValuesExist(value, filterContext.value) ? value : undefined
        )
    : undefined;

  const shouldBeDisabled = Boolean(value?.length < minLength);

  const helperText =
    value?.length && shouldBeDisabled
      ? intl.formatMessage(
          { id: 'containers.allDocuments.operators.minLength' },
          { minLength }
        )
      : '';

  const {
    node: Operators,
    operator,
    emptyOperatorIsUsed,
  } = useOperators({
    filterItem,
    onChange: onOperatorChange,
    value,
  });

  return (
    <FilterActionsWrapper
      onSubmit={() => {
        applyFilter({
          operator,
          value: emptyOperatorIsUsed
            ? resolveValueBasedOnBooleanOperator(operator, value)
            : value,
        });
      }}
      onBackClick={onBackClick}
      isSubmitDisabled={!emptyOperatorIsUsed && shouldBeDisabled}
      onCancel={onClose}
      onClickOnMore={handleOnClickOnMore}
      columnField={filterItem.column.field}
    >
      <Operators />
      {!emptyOperatorIsUsed ? (
        <TextField
          autoFocus
          value={value}
          onChange={e => setValue(e.target.value)}
          helperText={helperText}
          sx={{ width: '100%' }}
          size="small"
        />
      ) : null}
    </FilterActionsWrapper>
  );
};

export const FilterStartsWith = (props: SimpleFilterComponentProps) => (
  <FilterTextField {...props} minLength={STARTS_WITH_OPERATOR_MIN_LENGTH} />
);

export const FilterContains = (props: SimpleFilterComponentProps) => (
  <FilterTextField {...props} minLength={2} />
);
