import { Stack, Typography } from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type ItemTileDescriptionProps = {
  isFaded: boolean;
  to: LocationDescriptor<unknown>;
  label: ReactNode;
  description: ReactNode;
  Icon?: JSX.Element;
};

export const ItemTileDescription = ({
  isFaded,
  label,
  description,
  to,
}: ItemTileDescriptionProps) => {
  return (
    <Stack
      pl={3}
      direction="row"
      spacing={2}
      component={Link}
      to={to}
      tabIndex={-1}
      sx={{
        minWidth: 0,
        color: t => t.palette.text[isFaded ? 'secondary' : 'primary'],
        height: '100%',
        flexGrow: 1,
        cursor: 'pointer',
        alignItems: 'center',
        '&:hover': {
          color: theme => theme.palette.text.primary,
          textDecoration: 'none',
        },
      }}
    >
      <Stack data-cy="field-tile-description" sx={{ minWidth: 0 }}>
        <Stack direction="row" gap={1}>
          {typeof label === 'string' ? (
            <Typography
              variant="h6"
              sx={{ minWidth: 0 }}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {label}
            </Typography>
          ) : (
            label
          )}
        </Stack>

        {typeof description === 'string' ? (
          <Typography
            sx={{ minWidth: 0 }}
            variant="caption"
            color="text.secondary"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {description}
          </Typography>
        ) : (
          description
        )}
      </Stack>
    </Stack>
  );
};
