import { DatasetDTO } from '@rossum/mdh-api-client';
import { useQuery } from '@tanstack/react-query';
import { datasetApi, getDatasetRequestConfig } from '../api';

export const GET_DATASETS_QUERY_KEYS = [
  '@MDH',
  'getDatasetsApiV2DatasetGet',
] as const;

export const useGetDatasets = () => {
  return useQuery<Array<DatasetDTO>>({
    queryKey: GET_DATASETS_QUERY_KEYS,
    queryFn: () =>
      datasetApi
        .getDatasetsApiV2DatasetGet(getDatasetRequestConfig())
        .then(res => res.data.datasets),
  });
};
