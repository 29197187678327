import { Queue } from '@rossum/api-client/queues';
import { produce } from 'immer';
import { differenceBy, partition, uniqBy } from 'lodash';
import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { QueueState } from '../../../types/queue';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, logoutUser } from '../auth/actions';
import { createInboxFulfilled, updateInboxFulfilled } from '../inbox/actions';
import { initialPagination } from '../utils';
import {
  clearQueues,
  deleteQueueFulfilled,
  fetchQueueFulfilled,
  fetchQueuesFulfilled,
  updateQueueDetailFailed,
  updateQueueDetailFulfilled,
} from './actions';

const initialState: QueueState = {
  list: [],
  pagination: initialPagination,
  loaded: false,
  reloading: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchQueuesFulfilled): {
      const [queues, deletedQueues] = partition(
        action.payload.results,
        queue => !queue.deleteAfter
      );

      const prevQueues = differenceBy(state.list, deletedQueues, 'id');

      const allQueues = [...prevQueues, ...queues];
      const uniqQueues = allQueues.filter(
        (queue, i) =>
          !(
            // fresh queue from payload.results should take precedence over queue already in state
            allQueues
              .slice(i + 1, allQueues.length)
              .map(({ id }) => id)
              .includes(queue.id)
          )
      );
      // also attach generic and dedicated engines
      const builtQueues = uniqQueues.map(queue => ({
        ...queue,
        ...(queue.settings?.columns && {
          settings: {
            ...queue.settings,
            columns: uniqBy(queue.settings.columns ?? [], 'schemaId'),
          },
        }),
      }));

      return {
        ...state,
        list: builtQueues,
        pagination: action.payload.pagination,
        loaded: state.loaded || !action.payload.pagination.next,
        reloading: false,
      };
    }

    case getType(fetchQueueFulfilled): {
      const queueIndex = state.list.findIndex(
        queue => queue.id === action.payload.id
      );

      return produce(state, draft => {
        const newQueue = {
          ...action.payload,
          settings: {
            ...action.payload.settings,
            columns: uniqBy(action.payload.settings.columns ?? [], 'schemaId'),
          },
        };

        if (queueIndex > -1) {
          draft.list[queueIndex] = newQueue;
        } else {
          draft.list.push(newQueue);
        }
      });
    }

    case getType(deleteQueueFulfilled):
      return {
        ...state,
        list: state.list.filter(queue => queue.id !== action.payload.id),
      };

    case getType(updateQueueDetailFulfilled): {
      const { id } = action.meta;
      const index = state.list.findIndex(queue => queue.id === id);

      return produce(state, draft => {
        draft.list[index] = R.mergeDeep(
          draft.list[index],
          action.payload
        ) as Queue;
        draft.errors = draft.errors ? R.omit(draft.errors, [id]) : {};
        draft.reloading = true;
      });
    }

    case getType(updateQueueDetailFailed): {
      const { id } = action.meta;

      return { ...state, errors: { [id]: action.payload } };
    }

    case getType(fetchMembershipTokenFulfilled):
    case getType(logoutUser):
    case getType(clearQueues): {
      return initialState;
    }

    case getType(createInboxFulfilled): {
      const { queueUrl } = action.meta;
      const index = state.list.findIndex(queue => queue.url === queueUrl);

      return produce(state, draft => {
        draft.list[index].inbox = action.payload.url;
      });
    }

    case getType(updateInboxFulfilled): {
      const { inbox } = action.payload;
      const index = state.list.findIndex(
        queue => queue.url === inbox.queues[0]
      );

      return produce(state, draft => {
        draft.list[index].inbox = inbox.url;
      });
    }
    default:
      return state;
  }
};

export default reducer;
