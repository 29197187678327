import createSelector from '../../../lib/createDeepEqualSelector';
import { State } from '../../../types/state';

const extensionsSelector = (state: State) => state.extensions.list;
const pathnameSelector = (state: State) => state.router.location.pathname;

export const selectedExtensionSelector = createSelector(
  extensionsSelector,
  pathnameSelector,
  (extensions, pathname) => {
    const extensionId = Number(
      (pathname.match(/^\/(extensions\/my-extensions)\/((\d+))/) || [])[2]
    );
    return extensions.find(({ id }) => id === extensionId);
  }
);
