import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { Modal } from '../../../types/modal';
import { RootActionType } from '../../rootActions';
import { logoutUser } from '../auth/actions';
import { closeModal, openModal } from './actions';

const initialState: Modal = {
  confirmAction: undefined,
  confirmType: 'Primary',
  isOpen: false,
  onConfirm: undefined,
  textId: undefined,
  values: undefined,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(openModal): {
      const { textId, confirmAction, confirmType, values, onConfirm } =
        action.payload;

      return {
        ...state,
        confirmAction,
        confirmType,
        isOpen: true,
        onConfirm,
        textId,
        values,
      };
    }

    case getType(closeModal):
      // Translations is not cleared because of closing animation
      return {
        ...initialState,
        textId: state.textId,
        values: state.values,
        confirmType: state.confirmType,
      };

    case getType(logoutUser):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
