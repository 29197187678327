import { FieldsFormModel } from './formModels';

// top-level type guard
const isFieldsFormModel = (model: unknown): model is FieldsFormModel =>
  typeof model === 'object' &&
  model !== null &&
  'fieldType' in model &&
  typeof model.fieldType === 'string' &&
  [
    'simpleValue',
    'lineItemSimpleValue',
    'multivalue',
    'lineItems',
    'button',
    'lineItemButton',
    'section',
  ].includes(model.fieldType) &&
  'field' in model;

// `fieldType` related type guards
export type SectionFormModel = Extract<
  FieldsFormModel,
  { fieldType: 'section' }
>;

export const isSectionFormModel = (model: unknown): model is SectionFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'section';

export type LineItemsFormModel = Extract<
  FieldsFormModel,
  {
    fieldType: 'lineItems';
  }
>;

export const isLineItemsFormModel = (
  model: unknown
): model is LineItemsFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'lineItems';

type ButtonFormModel = Extract<FieldsFormModel, { fieldType: 'button' }>;

export const isButtonFormModel = (model: unknown): model is ButtonFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'button';

type LineItemButtonFormModel = Extract<
  FieldsFormModel,
  { fieldType: 'lineItemButton' }
>;

export const isLineItemButtonFormModel = (
  model: unknown
): model is LineItemButtonFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'lineItemButton';

export const isAnyButtonFormModel = (
  model: unknown
): model is ButtonFormModel | LineItemButtonFormModel =>
  isButtonFormModel(model) || isLineItemButtonFormModel(model);

type SimpleValueFormModel = Extract<
  FieldsFormModel,
  {
    fieldType: 'simpleValue';
  }
>;

export const isSimpleValueFormModel = (
  model: unknown
): model is SimpleValueFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'simpleValue';

type LineItemSimpleValueFormModel = Extract<
  FieldsFormModel,
  { fieldType: 'lineItemSimpleValue' }
>;

export const isLineItemSimpleValueFormModel = (
  model: unknown
): model is LineItemSimpleValueFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'lineItemSimpleValue';

export const isAnySimpleValueFormModel = (
  model: unknown
): model is SimpleValueFormModel | LineItemSimpleValueFormModel =>
  isSimpleValueFormModel(model) || isLineItemSimpleValueFormModel(model);

type MultivalueFormModel = Extract<
  FieldsFormModel,
  { fieldType: 'multivalue' }
>;

export const isMultivalueFormModel = (
  model: unknown
): model is MultivalueFormModel =>
  isFieldsFormModel(model) && model.fieldType === 'multivalue';

export const isSimpleOrMultivalueFormModel = (
  model: unknown
): model is SimpleValueFormModel | MultivalueFormModel =>
  isAnySimpleValueFormModel(model) || isMultivalueFormModel(model);

// `valueSource` related type guards
type CapturedFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { valueSource: 'captured' };
};

export const isCapturedFieldFormModel = (
  model: unknown
): model is CapturedFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) &&
  model.field.valueSource === 'captured';

type UnsetFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { valueSource: 'unset' };
};

export const isUnsetFieldFormModel = (
  model: unknown
): model is UnsetFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.valueSource === 'unset';

type DataFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { valueSource: 'data' };
};

export const isDataFieldFormModel = (
  model: unknown
): model is DataFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.valueSource === 'data';

type ManualFieldFormModel = (SimpleValueFormModel & MultivalueFormModel) & {
  field: { valueSource: 'manual' };
};

export const isManualFieldFormModel = (
  model: unknown
): model is ManualFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.valueSource === 'manual';

type FormulaFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { valueSource: 'formula' };
};

export const isFormulaFieldFormModel = (
  model: unknown
): model is FormulaFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.valueSource === 'formula';

export type ReasoningFieldFormModel = (
  | SimpleValueFormModel
  | MultivalueFormModel
) & {
  field: {
    valueSource: 'reasoning';
    prompt: string;
    context: string[];
  };
};

export const isReasoningFieldFormModel = (
  model: unknown
): model is ReasoningFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) &&
  model.field.valueSource === 'reasoning';

// data type related guards
type StringFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { dataType: 'string' };
};

export const isStringFieldFormModel = (
  model: unknown
): model is StringFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.dataType === 'string';

type NumberFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { dataType: 'number' };
};

export const isNumberFieldFormModel = (
  model: unknown
): model is NumberFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.dataType === 'number';

type DateFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { dataType: 'date' };
};

export const isDateFieldFormModel = (
  model: unknown
): model is DateFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.dataType === 'date';

type EnumFieldFormModel = (SimpleValueFormModel | MultivalueFormModel) & {
  field: { dataType: 'enum' };
};

export const isEnumFieldFormModel = (
  model: unknown
): model is EnumFieldFormModel =>
  isSimpleOrMultivalueFormModel(model) && model.field.dataType === 'enum';
