import { getIDFromUrl, Url } from '@rossum/api-client';
import {
  DatapointData,
  MultivalueData,
  SectionData,
  TupleData,
} from '@rossum/api-client/annotations';
import { AutomationBlocker } from '@rossum/api-client/internal';
import { SchemaAction } from '@rossum/api-client/schemas';
import { Message } from '@rossum/api-client/shared';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import * as R from 'remeda';
import { pick } from 'remeda';

export const columnsPrefix = 'columns';

export const globals = 'globals';

export const documentsColumn = 'document';

export const conditionsColumn = 'conditions';

export const actionsColumn = 'actions';

export type DatapointColumn = `${typeof columnsPrefix}.${string}`;

export type RowModel = {
  id: number | string;
  annotationUrl: Url;
  [documentsColumn]: string | undefined;
  [globals]: {
    messages: Message[] | undefined;
    automationBlockers: AutomationBlocker[] | undefined;
    actions: SchemaAction[] | undefined;
    conditions: Array<boolean> | undefined;
  };
  isFetching: boolean;
} & Record<
  DatapointColumn,
  | {
      datapoints: Array<DatapointValue>;
      messages: Record<string, Message[]>;
      automationBlockers: Record<string, AutomationBlocker[]>;
    }
  | undefined
>;

type DatapointValue = SectionData | DatapointData | TupleData | MultivalueData;

const getMaxLength = (arr: Array<Array<unknown>>) =>
  // at least one line per annotation should be displayed
  Math.max(1, ...arr.map(a => a.length));

// n + 1 for "...X more"
const MAX_ROWS_COUNT = 4;

const findBySchemaId = <
  Base extends { schemaId: string | null },
  C extends (Base & { children: C[] }) | Base,
>(
  content: Array<C>,
  schemaId: string
): Array<C> =>
  content.reduce<Array<C>>(
    (results, dp) =>
      dp.schemaId === schemaId
        ? [...results, dp]
        : 'children' in dp
          ? [...results, ...findBySchemaId(dp.children, schemaId)]
          : results,
    []
  );

export const getDatapointsData = (
  annotationContent: Array<SectionData> | undefined,
  datapointColumns: Array<GridColDef<RowModel>>,
  prefixForColumns: string
): SectionData[][] =>
  datapointColumns.flatMap(({ field }) =>
    annotationContent
      ? [
          findBySchemaId(
            annotationContent,
            field.replace(`${prefixForColumns}.`, '')
          ),
        ]
      : []
  );

export const constructRows = <
  T extends {
    actions?: SchemaAction[];
    conditionValues?: Array<boolean>;
  },
>({
  annotationUrl,
  document,
  documentsMap,
  datapointColumns,
  datapointsData,
  evaluationData,
  isFetching,
  messagesMap = {},
  automationBlockersMap = {},
}: {
  annotationUrl: string;
  document: string;
  documentsMap: Record<string, string>;
  datapointColumns: Array<GridColDef<RowModel>>;
  datapointsData: DatapointValue[][];
  evaluationData: T;
  isFetching: boolean;
  messagesMap?: Record<string, Message[]>;
  automationBlockersMap?: Record<string, AutomationBlocker[]>;
}): Array<RowModel> =>
  Array.from(
    { length: Math.min(getMaxLength(datapointsData), MAX_ROWS_COUNT) },
    (_, index) => {
      const isFirstRow = index === 0;
      const indexBasedAttributes = isFirstRow
        ? {
            [documentsColumn]: documentsMap[document],
            [globals]: {
              messages: messagesMap?.all || [],
              automationBlockers: automationBlockersMap?.all || [],
              actions: evaluationData.actions || [],
              conditions: evaluationData.conditionValues,
            },
          }
        : {
            [documentsColumn]: '',
            [globals]: {
              messages: [],
              automationBlockers: [],
              actions: [],
              conditions: undefined,
            },
          };

      return {
        id: `${getIDFromUrl(annotationUrl)}-${index}`,
        annotationUrl,
        ...indexBasedAttributes,
        isFetching,
        ...datapointColumns.reduce((acc, column, columnIndex) => {
          const datapoints = datapointsData.length
            ? R.pipe(
                (index === MAX_ROWS_COUNT - 1
                  ? datapointsData[columnIndex]?.slice(MAX_ROWS_COUNT - 1)
                  : [datapointsData[columnIndex]?.[index]]) ?? [],
                R.filter(R.isTruthy)
              )
            : undefined;

          const datapointsIds = datapoints?.map(({ id }) => String(id)) ?? [];

          return {
            ...acc,
            [column.field]: datapoints
              ? {
                  datapoints,
                  messages: pick(messagesMap, datapointsIds),
                  automationBlockers: pick(
                    automationBlockersMap,
                    datapointsIds
                  ),
                }
              : undefined,
          };
        }, {}),
      };
    }
  );
