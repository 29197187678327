// Generated by ts-to-zod
import { z } from 'zod';
import { annotationStatusSchema } from '../../shared/models/annotationStatus.schema';
import { messageSchema } from '../../shared/models/message.schema';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const annotationSchema = z.object({
  document: urlSchema,
  id: idSchema,
  queue: urlSchema,
  schema: urlSchema,
  relations: z.array(urlSchema),
  pages: z.array(urlSchema),
  creator: urlSchema.nullable(),
  modifier: urlSchema.nullable(),
  createdAt: z.string(),
  modifiedAt: z.string().nullable(),
  confirmedAt: z.string().nullable(),
  exportFailedAt: z.string().nullable(),
  exportedAt: z.string().nullable(),
  deletedAt: z.string().nullable(),
  rejectedAt: z.string().nullable(),
  confirmedBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  exportedBy: z.string().nullable(),
  rejectedBy: z.string().nullable(),
  assignedAt: z.string().nullable(),
  status: annotationStatusSchema,
  rirPollId: z.string().nullable(),
  messages: z.array(messageSchema).nullable(),
  url: urlSchema,
  content: urlSchema,
  timeSpent: z.number().nullable(),
  metadata: z.record(z.unknown()),
  automated: z.boolean(),
  suggestedEdit: z.string().nullable(),
  relatedEmails: z.array(z.string()),
  email: z.string().nullable(),
  automationBlocker: urlSchema.nullable(),
  emailThread: z.string().nullable(),
  organization: urlSchema,
  automaticallyRejected: z.boolean(),
  prediction: z
    .object({
      source: z.string().nullable(),
      version: z.string().nullable(),
    })
    .nullable(),
  hasEmailThreadWithReplies: z.boolean().optional(),
  hasEmailThreadWithNewReplies: z.boolean().optional(),
  assignees: z.array(urlSchema),
  labels: z.array(urlSchema),
  restrictedAccess: z.boolean(),
});
