/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Master Data Hub
 * OpenAPI spec version: 0.1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export type GetOperationsApiV2OperationGetParams = {
dataset_name?: string | null;
limit?: number;
};

export type GetOperationsApiV1OperationGetParams = {
dataset_name?: string | null;
limit?: number;
};

export type SexMasterDataHubWebApiModelsV2ResponseOperationStatus = typeof SexMasterDataHubWebApiModelsV2ResponseOperationStatus[keyof typeof SexMasterDataHubWebApiModelsV2ResponseOperationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SexMasterDataHubWebApiModelsV2ResponseOperationStatus = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  UNKNOWN: 'UNKNOWN',
} as const;

export type SexMasterDataHubWebApiModelsV1ResponseOperationType = typeof SexMasterDataHubWebApiModelsV1ResponseOperationType[keyof typeof SexMasterDataHubWebApiModelsV1ResponseOperationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SexMasterDataHubWebApiModelsV1ResponseOperationType = {
  upload: 'upload',
  replace: 'replace',
  delete: 'delete',
  update: 'update',
  upsert: 'upsert',
} as const;

export type SexMasterDataHubWebApiModelsV1ResponseOperationStatus = typeof SexMasterDataHubWebApiModelsV1ResponseOperationStatus[keyof typeof SexMasterDataHubWebApiModelsV1ResponseOperationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SexMasterDataHubWebApiModelsV1ResponseOperationStatus = {
  processing: 'processing',
  finished: 'finished',
  failed: 'failed',
  unknown: 'unknown',
  new: 'new',
  queued: 'queued',
} as const;

export type SexMasterDataHubRepositoriesQueryRepositoryFindSortAnyOf = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryFindSort = SexMasterDataHubRepositoriesQueryRepositoryFindSortAnyOf | null;

export type SexMasterDataHubRepositoriesQueryRepositoryFindQuery = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryFindProjectionAnyOf = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryFindProjection = SexMasterDataHubRepositoriesQueryRepositoryFindProjectionAnyOf | null;

export interface SexMasterDataHubRepositoriesQueryRepositoryFind {
  limit?: number;
  projection?: SexMasterDataHubRepositoriesQueryRepositoryFindProjection;
  query: SexMasterDataHubRepositoriesQueryRepositoryFindQuery;
  skip?: number;
  sort?: SexMasterDataHubRepositoriesQueryRepositoryFindSort;
}

export type SexMasterDataHubRepositoriesQueryRepositoryAggregatePipelineItem = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateOptionsAnyOf = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateOptions = SexMasterDataHubRepositoriesQueryRepositoryAggregateOptionsAnyOf | null;

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateLetAnyOf = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateLet = SexMasterDataHubRepositoriesQueryRepositoryAggregateLetAnyOf | null;

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateCollationAnyOf = { [key: string]: unknown };

export type SexMasterDataHubRepositoriesQueryRepositoryAggregateCollation = SexMasterDataHubRepositoriesQueryRepositoryAggregateCollationAnyOf | null;

export interface SexMasterDataHubRepositoriesQueryRepositoryAggregate {
  collation?: SexMasterDataHubRepositoriesQueryRepositoryAggregateCollation;
  let?: SexMasterDataHubRepositoriesQueryRepositoryAggregateLet;
  options?: SexMasterDataHubRepositoriesQueryRepositoryAggregateOptions;
  /** @minItems 1 */
  pipeline: SexMasterDataHubRepositoriesQueryRepositoryAggregatePipelineItem[];
}

export type SexMasterDataHubMatchingModelsFindSortAnyOf = { [key: string]: unknown };

export type SexMasterDataHubMatchingModelsFindSort = SexMasterDataHubMatchingModelsFindSortAnyOf | null;

export type SexMasterDataHubMatchingModelsFindProjectionAnyOf = { [key: string]: unknown };

export type SexMasterDataHubMatchingModelsFindProjection = SexMasterDataHubMatchingModelsFindProjectionAnyOf | null;

/**
 * MongoDB find command is used in the background.
Runtime limited to 120 seconds

[MongoDB find documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.find/)
 */
export type SexMasterDataHubMatchingModelsFindFind = { [key: string]: unknown };

export interface SexMasterDataHubMatchingModelsFind {
  /** MongoDB find command is used in the background.
Runtime limited to 120 seconds

[MongoDB find documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.find/) */
  find: SexMasterDataHubMatchingModelsFindFind;
  limit?: number;
  projection?: SexMasterDataHubMatchingModelsFindProjection;
  skip?: number;
  sort?: SexMasterDataHubMatchingModelsFindSort;
}

export type SexMasterDataHubMatchingModelsAggregateOptionsAnyOf = { [key: string]: unknown };

export type SexMasterDataHubMatchingModelsAggregateOptions = SexMasterDataHubMatchingModelsAggregateOptionsAnyOf | null;

export type SexMasterDataHubMatchingModelsAggregateLetAnyOf = { [key: string]: unknown };

export type SexMasterDataHubMatchingModelsAggregateLet = SexMasterDataHubMatchingModelsAggregateLetAnyOf | null;

export type SexMasterDataHubMatchingModelsAggregateCollationAnyOf = { [key: string]: unknown };

export type SexMasterDataHubMatchingModelsAggregateCollation = SexMasterDataHubMatchingModelsAggregateCollationAnyOf | null;

export type SexMasterDataHubMatchingModelsAggregateAggregateItem = { [key: string]: unknown };

export interface SexMasterDataHubMatchingModelsAggregate {
  /**
   * MongoDB aggregate command is used in the background.
Runtime limited to 120 seconds

[MongoDB aggregate documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.aggregate/)

If there is no $limit stage specified after a $search stage, the default limit = 50
will be used.
   * @minItems 1
   */
  aggregate: SexMasterDataHubMatchingModelsAggregateAggregateItem[];
  collation?: SexMasterDataHubMatchingModelsAggregateCollation;
  let?: SexMasterDataHubMatchingModelsAggregateLet;
  options?: SexMasterDataHubMatchingModelsAggregateOptions;
}

export type SexDatasetManagerOperationLoggerOperationType = typeof SexDatasetManagerOperationLoggerOperationType[keyof typeof SexDatasetManagerOperationLoggerOperationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SexDatasetManagerOperationLoggerOperationType = {
  create: 'create',
  update: 'update',
  replace: 'replace',
  delete: 'delete',
} as const;

/**
 * Status of the operation persisted in the operations log (collection).

WAITING status is optional and might be skipped. Could be used by extensions
that create the operation to be processed later. See DatasetManager.prepare().
 */
export type SexDatasetManagerOperationLoggerOperationStatus = typeof SexDatasetManagerOperationLoggerOperationStatus[keyof typeof SexDatasetManagerOperationLoggerOperationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SexDatasetManagerOperationLoggerOperationStatus = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
} as const;

export type XLSXParserConfigFieldNames = string[] | null;

export interface XLSXParserConfig {
  field_names?: XLSXParserConfigFieldNames;
  ignore_first_line?: boolean;
}

export type ValueConstraintsRegexp = RegexpConstraint | null;

export type ValueConstraintsLength = LengthConstraint | null;

export interface ValueConstraints {
  length?: ValueConstraintsLength;
  regexp?: ValueConstraintsRegexp;
  required?: boolean;
}

export type UpdateQueryDTOQueryAnyOf = DatasetFindInput | DatasetAggregateInput;

export type UpdateQueryDTOQuery = UpdateQueryDTOQueryAnyOf | null;

export type UpdateQueryDTOName = string | null;

export interface UpdateQueryDTO {
  name?: UpdateQueryDTOName;
  query?: UpdateQueryDTOQuery;
}

export type StringSchemaDatapointElementWidth = number | null;

export type StringSchemaDatapointElementStretch = boolean | null;

export type StringSchemaDatapointElementScoreThreshold = number | null;

export type StringSchemaDatapointElementRirFieldNames = string[] | null;

export type StringSchemaDatapointElementFormula = string | null;

export type StringSchemaDatapointElementDefaultValue = string | null;

export type StringSchemaDatapointElementConstraints = ValueConstraints | null;

export interface StringSchemaDatapointElement {
  can_collapse?: boolean;
  can_export?: boolean;
  category: 'datapoint';
  constraints?: StringSchemaDatapointElementConstraints;
  default_value?: StringSchemaDatapointElementDefaultValue;
  disable_prediction?: boolean;
  formula?: StringSchemaDatapointElementFormula;
  hidden?: boolean;
  id: string;
  label: string;
  rir_field_names?: StringSchemaDatapointElementRirFieldNames;
  score_threshold?: StringSchemaDatapointElementScoreThreshold;
  stretch?: StringSchemaDatapointElementStretch;
  type: 'string';
  width?: StringSchemaDatapointElementWidth;
  [key: string]: unknown;
 }

export type SelectOption = typeof SelectOption[keyof typeof SelectOption];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectOption = {
  default: 'default',
  best_match: 'best_match',
  best_match_include_default: 'best_match_include_default',
} as const;

export type SchemaTupleElementRirFieldNames = string[] | null;

export type SchemaTupleElementChildrenItem = StringSchemaDatapointElement | DateSchemaDatapointElement | NumberSchemaDatapointElement | EnumSchemaDatapointElement | ButtonSchemaDatapointElement;

export interface SchemaTupleElement {
  category: 'tuple';
  children: SchemaTupleElementChildrenItem[];
  disable_prediction?: boolean;
  hidden?: boolean;
  id: string;
  label: string;
  rir_field_names?: SchemaTupleElementRirFieldNames;
  [key: string]: unknown;
 }

export type SchemaSectionElementIcon = string | null;

export interface SchemaSectionElement {
  category: 'section';
  children: SchemaSectionElementChildrenItem[];
  disable_prediction?: boolean;
  hidden?: boolean;
  icon?: SchemaSectionElementIcon;
  id: string;
  label: string;
  [key: string]: unknown;
 }

export type SchemaSectionElementChildrenItemAnyOf = StringSchemaDatapointElement | DateSchemaDatapointElement | NumberSchemaDatapointElement | EnumSchemaDatapointElement | ButtonSchemaDatapointElement;

export type SchemaSectionElementChildrenItem = SchemaMultivalueElement | SchemaSectionElementChildrenItemAnyOf;

export type SchemaMultivalueElementRirFieldNames = string[] | null;

export type SchemaMultivalueElementMinOccurrences = number | null;

export type SchemaMultivalueElementMaxOccurrences = number | null;

export type SchemaMultivalueElementGrid = Grid | null;

export type SchemaMultivalueElementChildrenAnyOf = StringSchemaDatapointElement | DateSchemaDatapointElement | NumberSchemaDatapointElement | EnumSchemaDatapointElement | ButtonSchemaDatapointElement;

export type SchemaMultivalueElementChildren = SchemaTupleElement | SchemaMultivalueElementChildrenAnyOf;

export interface SchemaMultivalueElement {
  category: 'multivalue';
  children: SchemaMultivalueElementChildren;
  disable_prediction?: boolean;
  grid?: SchemaMultivalueElementGrid;
  hidden?: boolean;
  id: string;
  label: string;
  max_occurrences?: SchemaMultivalueElementMaxOccurrences;
  min_occurrences?: SchemaMultivalueElementMinOccurrences;
  rir_field_names?: SchemaMultivalueElementRirFieldNames;
  show_grid_by_default?: boolean;
  [key: string]: unknown;
 }

export type RowType = typeof RowType[keyof typeof RowType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RowType = {
  header: 'header',
  subsection_header: 'subsection_header',
  data: 'data',
  footer: 'footer',
} as const;

export interface ResultMessage {
  content: string;
  type: MessageType;
}

export type ResultActionMessage = ResultMessage | null;

export interface ResultAction {
  message?: ResultActionMessage;
  /** What to preselect in the target ENUM annotation field.
The default can be specified in the `default` part of the configuration.

If the `best_match` option is configured, the default option will not be present
in the list of options at all. To include the default option, while also preselecting
the best match, set the option to `best_match_include_default`. */
  select: SelectOption;
}

export interface ResultActions {
  multiple_matches_found: ResultAction;
  no_match_found: EmptyResultAction;
  one_match_found: ResultAction;
}

/**
 * The URL query parameters to be sent in the form of a dictionary. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
will be sent as a URL query like this: `?poNumber=PO1234&lineItemCode=A002`
 */
export type RestAPIRequestQueryParams = {[key: string]: string | number | number | boolean | null};

/**
 * The headers to be sent with the request. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "Authorization": "Basic {secrets.credentials}"
}
```
 */
export type RestAPIRequestHeaders = {[key: string]: string | number | null};

export interface RestAPIRequest {
  /** The body of the request to be sent. It can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
``` */
  body?: RestAPIRequestBody;
  /** The headers to be sent with the request. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "Authorization": "Basic {secrets.credentials}"
}
``` */
  headers?: RestAPIRequestHeaders;
  /** HTTP method to use. Can be either GET or POST. */
  method: Method;
  /** The URL query parameters to be sent in the form of a dictionary. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
will be sent as a URL query like this: `?poNumber=PO1234&lineItemCode=A002` */
  query_params?: RestAPIRequestQueryParams;
  /** The URL of the resource to be requested. It can contain
[placeholders](#section/Configuration-placeholders).

Example: `https://app.com/api/purchase-order/{po_number}` */
  url: string;
}

/**
 * An optional authentication request. The response can be referenced in the
following queries using auth [placeholders](#section/Configuration-placeholders).
 */
export type RestAPISourceAuth = RestAPIRequest | null;

export interface RestAPISource {
  /** An optional authentication request. The response can be referenced in the
following queries using auth [placeholders](#section/Configuration-placeholders). */
  auth?: RestAPISourceAuth;
  /**
   * List of API requests to perform in the given order, until one of them finds a result.
Each query must return an object or a list of objects. The server must respond within
5 seconds. If the server fails to respond (connection cannot be established, times out
after 5 seconds, or responds with a 500 status code), the query is retried once after
3 seconds.
   * @minItems 1
   */
  queries: RestAPIQuery[];
}

export type RestAPIRequestBodyAnyOf = { [key: string]: unknown };

/**
 * The body of the request to be sent. It can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
 */
export type RestAPIRequestBody = RestAPIRequestBodyAnyOf | null;

/**
 * The URL query parameters to be sent in the form of a dictionary. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
will be sent as a URL query like this: `?poNumber=PO1234&lineItemCode=A002`
 */
export type RestAPIQueryQueryParams = {[key: string]: string | number | number | boolean | null};

/**
 * The headers to be sent with the request. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "Authorization": "Basic {secrets.credentials}"
}
```
 */
export type RestAPIQueryHeaders = {[key: string]: string | number | null};

export interface RestAPIQuery {
  /** The body of the request to be sent. It can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
``` */
  body?: RestAPIQueryBody;
  /** The headers to be sent with the request. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "Authorization": "Basic {secrets.credentials}"
}
``` */
  headers?: RestAPIQueryHeaders;
  /** HTTP method to use. Can be either GET or POST. */
  method: Method;
  /** The URL query parameters to be sent in the form of a dictionary. They can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
will be sent as a URL query like this: `?poNumber=PO1234&lineItemCode=A002` */
  query_params?: RestAPIQueryQueryParams;
  /** A [JMESPath](https://jmespath.org/specification.html) expression
specifying how to access the results in the response body.

For example:
The result items returned in the response body like this
```json
{
  "result": {
    "items": [...]
  },
  "metadata": {...}
}
```
can be accessed using the path `result.items`.

An empty path can be used to select the whole response body. */
  result_path: string;
  /** The URL of the resource to be requested. It can contain
[placeholders](#section/Configuration-placeholders).

Example: `https://app.com/api/purchase-order/{po_number}` */
  url: string;
}

export type RestAPIQueryBodyAnyOf = { [key: string]: unknown };

/**
 * The body of the request to be sent. It can contain
[placeholders](#section/Configuration-placeholders).

For example:
```json
{
  "poNumber": "{po_number}",
  "lineItemCode": "{item_code}"
}
```
 */
export type RestAPIQueryBody = RestAPIQueryBodyAnyOf | null;

export type ResponseMessageId = number | null;

/**
 * Message to be displayed in the Rossum UI.
 */
export interface ResponseMessage {
  content: string;
  id?: ResponseMessageId;
  type: MessageType;
}

export type ResponseAutomationBlockerId = number | null;

export interface ResponseAutomationBlocker {
  content: string;
  id?: ResponseAutomationBlockerId;
}

export interface ReplaceOperation {
  id: number;
  op?: 'replace';
  value: OperationValue;
  [key: string]: unknown;
 }

export interface RemoveOperation {
  id: number;
  op?: 'remove';
  [key: string]: unknown;
 }

export interface RegexpConstraint {
  pattern: string;
}

export type QueueMetadata = { [key: string]: unknown };

export interface Queue {
  hooks?: string[];
  id: number;
  metadata: QueueMetadata;
  name: string;
  schema: string;
  url: string;
  webhooks?: string[];
  workspace: string;
  [key: string]: unknown;
 }

export type QueryDTOQuery = DatasetFindOutput | DatasetAggregateOutput;

export interface QueryDTO {
  id: string;
  name: string;
  query: QueryDTOQuery;
}

export interface QueriesDTO {
  queries: QueryDTO[];
}

export interface PongResponse {
  code_project_path: string;
  code_version_hash: string;
}

export interface OperationsResponse {
  operations: DatasetOperationOut[];
}

export interface OperationsDTO {
  operations: OperationLog[];
}

export interface OperationValueAdd { [key: string]: unknown }

export interface OperationValue { [key: string]: unknown }

export interface OperationSummary {
  record_count?: number;
}

export type OperationLogStarted = string | null;

export type OperationLogMessage = string | null;

/**
 * Persisted state of an operation.

All fields are managed by DatasetManager, except for the metadata, that can
be used to store any user data. They can be provided during preparation stage,
see DatasetManager.prepare().
 */
export interface OperationLog {
  _id?: string;
  created?: string;
  dataset_name: string;
  message?: OperationLogMessage;
  metadata: Metadata;
  started?: OperationLogStarted;
  status?: SexDatasetManagerOperationLoggerOperationStatus;
  type: SexDatasetManagerOperationLoggerOperationType;
  updated?: string;
}

export interface OperationContent { [key: string]: unknown }

export type NumberSchemaDatapointElementWidth = number | null;

export type NumberSchemaDatapointElementStretch = boolean | null;

export type NumberSchemaDatapointElementScoreThreshold = number | null;

export type NumberSchemaDatapointElementRirFieldNames = string[] | null;

export type NumberSchemaDatapointElementFormula = string | null;

export type NumberSchemaDatapointElementFormat = string | null;

export type NumberSchemaDatapointElementDefaultValue = string | null;

export type NumberSchemaDatapointElementConstraints = ValueConstraints | null;

export type NumberSchemaDatapointElementAggregationsAnyOf = { [key: string]: unknown };

export type NumberSchemaDatapointElementAggregations = NumberSchemaDatapointElementAggregationsAnyOf | null;

export interface NumberSchemaDatapointElement {
  aggregations?: NumberSchemaDatapointElementAggregations;
  can_collapse?: boolean;
  can_export?: boolean;
  category: 'datapoint';
  constraints?: NumberSchemaDatapointElementConstraints;
  default_value?: NumberSchemaDatapointElementDefaultValue;
  disable_prediction?: boolean;
  format?: NumberSchemaDatapointElementFormat;
  formula?: NumberSchemaDatapointElementFormula;
  hidden?: boolean;
  id: string;
  label: string;
  rir_field_names?: NumberSchemaDatapointElementRirFieldNames;
  score_threshold?: NumberSchemaDatapointElementScoreThreshold;
  stretch?: NumberSchemaDatapointElementStretch;
  type: 'number';
  width?: NumberSchemaDatapointElementWidth;
  [key: string]: unknown;
 }

export type Method = typeof Method[keyof typeof Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Method = {
  GET: 'GET',
  POST: 'POST',
} as const;

export interface Metadata {
  operation_summary?: OperationSummary;
  [key: string]: unknown;
 }

export type MessageType = typeof MessageType[keyof typeof MessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
} as const;

export interface MessageResponse {
  message: string;
  type: MessageType;
}

export type MatchResponseResultsItem = { [key: string]: unknown };

export interface MatchResponse {
  results: MatchResponseResultsItem[];
}

export type MatchRequestArguments = { [key: string]: unknown };

export interface MatchRequest {
  arguments?: MatchRequestArguments;
  query_name: string;
}

export type MatchEventSecrets = { [key: string]: unknown };

export type MatchEventSchemas = DocumentSchema[] | null;

export type MatchEventQueues = Queue[] | null;

export interface MatchConfig {
  /** Definition of a condition for the particular matching. When defined,
the matching will be performed only if the condition is evaluated to True,
otherwise the matching targets will be reset.

**Warning:** The condition is evaluated when selecting a value in the main
mapping as well. This means that if the condition turns out to be False,
the additional mappings will not be updated!

If the matching query is defined on header level and the condition on
a table level, all rows must pass the condition in order for the matching
to be performed. If the matching query as well as the condition are defined
on table level, the matching will be performed only for the rows which pass
the condition.

It must be a Pythonic expression where annotation fields are referenced
by their schema ID enclosed in curly brackets.
Example: `'{item_code}' != '' and '{document_type}' == 'invoice'` */
  action_condition?: string;
  /** Map additional fields of the found result to different annotation fields
to match from the query result. Changing the selected value of the main
mapping will also change the value of the additional mappings. */
  additional_mappings?: Mapping[];
  /** The default value and label to put in the target annotation field
if no matches are found or if configured in the `result_actions`. */
  default: EnumOption;
  /** IDs of queues where the matching won't be performed.
This parameter cannot be set along with 'queue_ids'. */
  excluded_queue_ids?: number[];
  /** Describes how to map the found result to the annotation fields. */
  mapping: Mapping;
  /** Definition of one of the results, that should always be put to the top of the matching
results. Can be either a constant value or a dynamically evaluated value from the
document's annotation data. */
  preferred_result?: MatchConfigPreferredResult;
  /** IDs of queues where the matching should be performed.
You can assign the extension to multiple queues and
specify numerous actions for different queues in one instance.

If not present, matching will be performed on all the queues
to which the extension is assigned unless `excluded_queue_ids` is set. */
  queue_ids?: number[];
  /** The actions describe what to do when either no, one or multiple entities
were found in the dataset by the specified queries. */
  result_actions: ResultActions;
  /** Source of the data in which the matching should be performed. */
  source: MatchConfigSource;
}

export interface MatchConfigs {
  /** List of matching configurations, performed in the specified order. */
  configurations: MatchConfig[];
}

export interface MatchEvent {
  action: AnnotationContentAction;
  annotation: AnnotationWithContent;
  base_url: string;
  document: Document;
  event: 'annotation_content';
  hook: string;
  queues?: MatchEventQueues;
  request_id: string;
  rossum_authorization_token: string;
  schemas?: MatchEventSchemas;
  secrets?: MatchEventSecrets;
  settings: MatchConfigs;
  timestamp: string;
  updated_datapoints: number[];
  [key: string]: unknown;
 }

/**
 * Source of the data in which the matching should be performed.
 */
export type MatchConfigSource = DMDatasetSource | RestAPISource;

/**
 * Definition of one of the results, that should always be put to the top of the matching
results. Can be either a constant value or a dynamically evaluated value from the
document's annotation data.
 */
export type MatchConfigPreferredResult = DynamicPreferredResult | ConstantPreferredResult | null;

/**
 * Template to use for the resulting enum option label.

Template can contain references to the keys of the matched dataset entity.
It is possible to access nested data by using
[JMESPath](https://jmespath.org/specification.html) syntax.

For example:

`"label_template": "Supplier: {Supplier.\"Full Name\"} (VAT: {\"VAT ID\"})"`
with a matched dataset entity like this:
```
{
  "Supplier": {
    "Full Name": "John Doe"
  },
  "VAT ID": "123456789"
}
```
Will result in the following label: "Supplier: John Doe (VAT: 123456789)"

If the parameter is not specified, the value from `dataset_key`
is displayed in the field label.
 */
export type MappingLabelTemplate = string | null;

export interface Mapping {
  /** Attribute name of the matched dataset entity. Its value will be put in the target field.

It is possible to access the matched entity attributes using the
[JMESPath](https://jmespath.org/specification.html) syntax. You can utilize the full
syntax to control what ends up in the target field.

For example:

`"dataset_key": ""First Name" | '---'"`
with a matched dataset entity with either a missing or an empty `First Name` attribute:
```
{
  "Company Name": "My Vendor 123",
  "First Name": ""
}
```
Will result in the value `---` being saved in the target field, instead of an empty string. */
  dataset_key: string;
  /** [DEPRECATED] - Use `label_template` instead.

Keys of the matched dataset entity to use as the resulting enum option label.
The values will be joined by a ', ' delimiter. */
  label_keys?: string[];
  /** Template to use for the resulting enum option label.

Template can contain references to the keys of the matched dataset entity.
It is possible to access nested data by using
[JMESPath](https://jmespath.org/specification.html) syntax.

For example:

`"label_template": "Supplier: {Supplier.\"Full Name\"} (VAT: {\"VAT ID\"})"`
with a matched dataset entity like this:
```
{
  "Supplier": {
    "Full Name": "John Doe"
  },
  "VAT ID": "123456789"
}
```
Will result in the following label: "Supplier: John Doe (VAT: 123456789)"

If the parameter is not specified, the value from `dataset_key`
is displayed in the field label. */
  label_template?: MappingLabelTemplate;
  /** Schema ID of the annotation field to put the match result in.
The field must be of type ENUM. */
  target_schema_id: string;
}

export type LengthConstraintMin = number | null;

export type LengthConstraintMax = number | null;

export type LengthConstraintExact = number | null;

export interface LengthConstraint {
  exact?: LengthConstraintExact;
  max?: LengthConstraintMax;
  min?: LengthConstraintMin;
}

export interface Grid {
  default_row_type?: RowType;
  row_types?: RowType[];
  row_types_to_extract?: RowType[];
}

export type FindBodySortAnyOf = { [key: string]: unknown };

export type FindBodySort = FindBodySortAnyOf | null;

export type FindBodyProjectionAnyOf = { [key: string]: unknown };

export type FindBodyProjection = FindBodyProjectionAnyOf | null;

/**
 * MongoDB find command is used in the background.
Runtime limited to 120 seconds

[MongoDB find documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.find/)
 */
export type FindBodyFind = { [key: string]: unknown };

export interface FindBody {
  dataset: string;
  /** MongoDB find command is used in the background.
Runtime limited to 120 seconds

[MongoDB find documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.find/) */
  find: FindBodyFind;
  limit?: number;
  projection?: FindBodyProjection;
  skip?: number;
  sort?: FindBodySort;
}

export type FindOutputSortAnyOf = { [key: string]: unknown };

export type FindOutputSort = FindOutputSortAnyOf | null;

export type FindOutputQuery = { [key: string]: unknown };

export type FindOutputProjectionAnyOf = { [key: string]: unknown };

export type FindOutputProjection = FindOutputProjectionAnyOf | null;

export interface FindOutput {
  limit?: number;
  projection?: FindOutputProjection;
  query: FindOutputQuery;
  skip?: number;
  sort?: FindOutputSort;
}

export type FileMetadataOutParserConfig = CSVParserConfig | XLSXParserConfig | null;

export type FileFormat = typeof FileFormat[keyof typeof FileFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileFormat = {
  json: 'json',
  xml: 'xml',
  csv: 'csv',
  xlsx: 'xlsx',
} as const;

export interface FileMetadataOut {
  dynamic_parsing?: boolean;
  file_encoding: string;
  file_format: FileFormat;
  file_size: number;
  filename: string;
  parser_config?: FileMetadataOutParserConfig;
}

export interface ErrorInfo {
  text: string;
  type: string;
}

export type EnumValueType = typeof EnumValueType[keyof typeof EnumValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumValueType = {
  string: 'string',
  number: 'number',
  date: 'date',
} as const;

export type EnumSchemaDatapointElementWidth = number | null;

export type EnumSchemaDatapointElementStretch = boolean | null;

export type EnumSchemaDatapointElementScoreThreshold = number | null;

export type EnumSchemaDatapointElementRirFieldNames = string[] | null;

export type EnumSchemaDatapointElementFormula = string | null;

export type EnumSchemaDatapointElementDefaultValue = string | null;

export type EnumSchemaDatapointElementConstraints = ValueConstraints | null;

export interface EnumOption {
  label: string;
  value: string;
  [key: string]: unknown;
 }

export interface EnumSchemaDatapointElement {
  can_collapse?: boolean;
  can_export?: boolean;
  category: 'datapoint';
  constraints?: EnumSchemaDatapointElementConstraints;
  default_value?: EnumSchemaDatapointElementDefaultValue;
  disable_prediction?: boolean;
  enum_value_type?: EnumValueType;
  formula?: EnumSchemaDatapointElementFormula;
  hidden?: boolean;
  id: string;
  label: string;
  options: EnumOption[];
  rir_field_names?: EnumSchemaDatapointElementRirFieldNames;
  score_threshold?: EnumSchemaDatapointElementScoreThreshold;
  stretch?: EnumSchemaDatapointElementStretch;
  type: 'enum';
  width?: EnumSchemaDatapointElementWidth;
  [key: string]: unknown;
 }

export type EmptyResultActionMessage = ResultMessage | null;

export interface EmptyResultAction {
  message?: EmptyResultActionMessage;
  /** The only possible value for `no_match_found` is the default.
Therefore it can be omitted. */
  select?: 'default';
}

export interface DynamicPreferredResult {
  /** Attribute name of the matched dataset entity. Can be specified using the
[JMESPath](https://jmespath.org/specification.html) syntax. */
  dataset_key: string;
  schema_id: string;
}

export type DocumentSchemaMetadataAnyOf = { [key: string]: unknown };

export type DocumentSchemaMetadata = DocumentSchemaMetadataAnyOf | null;

export interface DocumentSchema {
  content: SchemaSectionElement[];
  id: number;
  metadata?: DocumentSchemaMetadata;
  name: string;
  queues: string[];
  url: string;
}

export interface Document {
  content: string;
  id: number;
  mime_type: string;
  original_file_name: string;
  s3_name: string;
  url: string;
  [key: string]: unknown;
 }

export type DateSchemaDatapointElementWidth = number | null;

export type DateSchemaDatapointElementStretch = boolean | null;

export type DateSchemaDatapointElementScoreThreshold = number | null;

export type DateSchemaDatapointElementRirFieldNames = string[] | null;

export type DateSchemaDatapointElementFormula = string | null;

export type DateSchemaDatapointElementFormat = string | null;

export type DateSchemaDatapointElementDefaultValue = string | null;

export type DateSchemaDatapointElementConstraints = ValueConstraints | null;

export interface DateSchemaDatapointElement {
  can_collapse?: boolean;
  can_export?: boolean;
  category: 'datapoint';
  constraints?: DateSchemaDatapointElementConstraints;
  default_value?: DateSchemaDatapointElementDefaultValue;
  disable_prediction?: boolean;
  format?: DateSchemaDatapointElementFormat;
  formula?: DateSchemaDatapointElementFormula;
  hidden?: boolean;
  id: string;
  label: string;
  rir_field_names?: DateSchemaDatapointElementRirFieldNames;
  score_threshold?: DateSchemaDatapointElementScoreThreshold;
  stretch?: DateSchemaDatapointElementStretch;
  type: 'date';
  width?: DateSchemaDatapointElementWidth;
  [key: string]: unknown;
 }

export interface DatasetsResponse {
  datasets: Dataset[];
}

export interface DatasetsDTO {
  datasets: DatasetDTO[];
}

export type DatasetOperationOutFileMetadata = FileMetadataOut | null;

export type DatasetOperationOutError = ErrorInfo | null;

export interface DatasetOperationOut {
  create_ts: string;
  dataset_name: string;
  error: DatasetOperationOutError;
  file_metadata: DatasetOperationOutFileMetadata;
  operation_id: string;
  operation_type: SexMasterDataHubWebApiModelsV1ResponseOperationType;
  status: SexMasterDataHubWebApiModelsV1ResponseOperationStatus;
  status_ts: string;
}

export interface DatasetFindOutput {
  dataset_name: string;
  find: FindOutput;
  type?: 'dataset_find';
}

export interface DatasetFindInput {
  dataset_name: string;
  find: SexMasterDataHubRepositoriesQueryRepositoryFind;
  type?: 'dataset_find';
}

export type DatasetDTOUpdated = string | null;

export interface DatasetDTO {
  dataset_name: string;
  status: SexMasterDataHubWebApiModelsV2ResponseOperationStatus;
  updated?: DatasetDTOUpdated;
}

export interface DatasetAggregateOutput {
  aggregate: AggregateOutput;
  dataset_name: string;
  type?: 'dataset_aggregate';
}

export interface DatasetAggregateInput {
  aggregate: SexMasterDataHubRepositoriesQueryRepositoryAggregate;
  dataset_name: string;
  type?: 'dataset_aggregate';
}

export type DatasetStatusTs = string | null;

export interface Dataset {
  dataset_name: string;
  status: SexMasterDataHubWebApiModelsV1ResponseOperationStatus;
  status_ts?: DatasetStatusTs;
}

export type DatapointContentNormalizedValue = string | null;

export interface DatapointContent {
  normalized_value?: DatapointContentNormalizedValue;
  value: string;
  [key: string]: unknown;
 }

export type DSResultsResponseResultsItem = { [key: string]: unknown };

export interface DSResultsResponse {
  results: DSResultsResponseResultsItem[];
}

export type DMDatasetSourceQueriesItem = SexMasterDataHubMatchingModelsFind | SexMasterDataHubMatchingModelsAggregate;

export interface DMDatasetSource {
  /** The name of a dataset in which to perform the matching. The dataset
name can contain [placeholders](#section/Configuration-placeholders). */
  dataset: string;
  /**
   * List of queries to perform in the given order, until one of them finds a result.
The queries can contain [placeholders](#section/Configuration-placeholders).

The syntax of the queries matches closely the one of
[MongoDB queries](https://www.mongodb.com/docs/manual/tutorial/query-documents/).
The query must be translated to a valid JSON: `{$or: [...]}` => `{"$or": [...]}`

For example:
```json
{
  "Name": {
    "$eq": "{sender_name}"
  }
}
```
   * @minItems 1
   */
  queries: DMDatasetSourceQueriesItem[];
}

export type CreateQueryDTOQuery = DatasetFindInput | DatasetAggregateInput;

export interface CreateQueryDTO {
  name: string;
  query: CreateQueryDTOQuery;
}

export interface ContentTupleElement {
  category: 'tuple';
  children: ContentDatapointElement[];
  id: number;
  schema_id: string;
  url: string;
  [key: string]: unknown;
 }

export interface ContentSectionElement {
  category: 'section';
  children: ContentSectionElementChildrenItem[];
  id: number;
  schema_id: string;
  url: string;
  [key: string]: unknown;
 }

export type ContentMultivalueElementChildren = ContentTupleElement[] | ContentDatapointElement[];

export interface ContentMultivalueElement {
  category: 'multivalue';
  children: ContentMultivalueElementChildren;
  id: number;
  schema_id: string;
  url: string;
  [key: string]: unknown;
 }

export type ContentSectionElementChildrenItem = ContentMultivalueElement | ContentDatapointElement;

export type ContentDatapointElementOptions = EnumOption[] | null;

export type ContentDatapointElementContent = DatapointContent | null;

export interface ContentDatapointElement {
  category: 'datapoint';
  content?: ContentDatapointElementContent;
  id: number;
  options?: ContentDatapointElementOptions;
  schema_id: string;
  url: string;
  validation_sources: string[];
  [key: string]: unknown;
 }

export interface ConstantPreferredResult {
  /** Attribute name of the matched dataset entity. Can be specified using the
[JMESPath](https://jmespath.org/specification.html) syntax. */
  dataset_key: string;
  value?: unknown;
}

export type CSVParserConfigFieldNames = string[] | null;

export type CSVParserConfigEscapeCharacter = string | null;

export interface CSVParserConfig {
  double_quote?: boolean;
  escape_character?: CSVParserConfigEscapeCharacter;
  field_delimiter?: string;
  field_names?: CSVParserConfigFieldNames;
  ignore_first_line?: boolean;
  quote_character?: string;
}

export type ButtonSchemaDatapointElementWidth = number | null;

export type ButtonSchemaDatapointElementStretch = boolean | null;

export type ButtonSchemaDatapointElementScoreThreshold = number | null;

export type ButtonSchemaDatapointElementRirFieldNames = string[] | null;

export type ButtonSchemaDatapointElementPopupUrl = string | null;

export type ButtonSchemaDatapointElementFormula = string | null;

export type ButtonSchemaDatapointElementDefaultValue = string | null;

export type ButtonSchemaDatapointElementConstraints = ValueConstraints | null;

export interface ButtonSchemaDatapointElement {
  can_collapse?: boolean;
  can_export?: boolean;
  category: 'datapoint';
  constraints?: ButtonSchemaDatapointElementConstraints;
  default_value?: ButtonSchemaDatapointElementDefaultValue;
  disable_prediction?: boolean;
  formula?: ButtonSchemaDatapointElementFormula;
  hidden?: boolean;
  id: string;
  label: string;
  popup_url?: ButtonSchemaDatapointElementPopupUrl;
  rir_field_names?: ButtonSchemaDatapointElementRirFieldNames;
  score_threshold?: ButtonSchemaDatapointElementScoreThreshold;
  stretch?: ButtonSchemaDatapointElementStretch;
  type: 'button';
  width?: ButtonSchemaDatapointElementWidth;
  [key: string]: unknown;
 }

/**
 * (CSV only) The character used to quote fields containing special characters, default `"`.
 */
export type BodyUpdateDatasetApiV1DatasetDatasetNamePatchQuoteCharacter = string | null;

/**
 * (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row!
 */
export type BodyUpdateDatasetApiV1DatasetDatasetNamePatchIgnoreFirstLine = boolean | null;

/**
 * (CSV only) The character used to separate fields (columns) in the CSV, default `;`.
 */
export type BodyUpdateDatasetApiV1DatasetDatasetNamePatchFieldDelimiter = string | null;

/**
 * (CSV only) The character used to escape special characters, none is set by default.
 */
export type BodyUpdateDatasetApiV1DatasetDatasetNamePatchEscapeCharacter = string | null;

/**
 * (CSV only) If true, we are expecting the quote character to be escaped by doubling it.
 */
export type BodyUpdateDatasetApiV1DatasetDatasetNamePatchDoubleQuote = boolean | null;

export interface BodyUpdateDatasetApiV1DatasetDatasetNamePatch {
  /** (CSV only) If true, we are expecting the quote character to be escaped by doubling it. */
  double_quote?: BodyUpdateDatasetApiV1DatasetDatasetNamePatchDoubleQuote;
  /** With dynamic mode off, the following file formats are expected:
- CSV - The first row is always used as headers.
The column delimiter must be a semicolon `;`.
- XML - Elements with the same name on the same level anywhere in the
XML tree are treated as the entities.
    - Example of valid XML structure:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<root>
    <invoices>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
    </invoices>
</root>
```
- XLSX - The first row is always used as headers. */
  dynamic?: boolean;
  /** All [major encodings](https://docs.python.org/3.12/library/codecs.html#standard-encodings)
are supported. If you are not sure, your file probably uses a UTF-8 encoding. */
  encoding: string;
  /** (CSV only) The character used to escape special characters, none is set by default. */
  escape_character?: BodyUpdateDatasetApiV1DatasetDatasetNamePatchEscapeCharacter;
  /** (CSV only) The character used to separate fields (columns) in the CSV, default `;`. */
  field_delimiter?: BodyUpdateDatasetApiV1DatasetDatasetNamePatchFieldDelimiter;
  /** (CSV, XLSX only) Array of field names. Can be used to set field names
if they are missing in the file, but also to rewrite existing ones.
To rewrite existing ones, ignore_first_line must be set to true.

Must be a `multipart/form-data` valid array - either multiple fields of
the given name, or a single field with comma (`,`) separated values. */
  field_names?: string[];
  /** The following file formats are supported:
- JSON - Must be a list of objects like `[{...}, {...}, ...]`.
- CSV - The dialect of the csv file is automatically determined. If the header is
missing, an alphabetical keys are generated for the columns. If you run into problems,
consider turning off the dynamic mode.
- XML - XML attributes are ignored, namespaces are removed from the key names.
The dataset entities must be nested one level at maximum.
    - Examples of valid XML structures:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoice>
    <id>...</id>
    <name>...</name>
</invoice>
```
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoices>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
</invoices>
```
- XLSX - Only the active spreadsheet is used.
Headers are treated the same way as in a CSV file. */
  file: Blob;
  /** The names of the columns which are used for updating existing entities.
The keys can refer to a nested value using a dot notation. E.g. `header.id`.

Must be a `multipart/form-data` valid array - either multiple fields of
the given name, or a single field with comma (`,`) separated values. */
  id_keys: string[];
  /** (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row! */
  ignore_first_line?: BodyUpdateDatasetApiV1DatasetDatasetNamePatchIgnoreFirstLine;
  /** (CSV only) The character used to quote fields containing special characters, default `"`. */
  quote_character?: BodyUpdateDatasetApiV1DatasetDatasetNamePatchQuoteCharacter;
  /** The switch enables calling this endpoint with a non-existing
dataset name, resulting in dataset creation as if the POST method was used. */
  update_or_new?: boolean;
}

/**
 * (CSV only) The character used to quote fields containing special characters, default `"`.
 */
export type BodyReplaceDatasetApiV1DatasetDatasetNamePutQuoteCharacter = string | null;

/**
 * (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row!
 */
export type BodyReplaceDatasetApiV1DatasetDatasetNamePutIgnoreFirstLine = boolean | null;

/**
 * (CSV only) The character used to separate fields (columns) in the CSV, default `;`.
 */
export type BodyReplaceDatasetApiV1DatasetDatasetNamePutFieldDelimiter = string | null;

/**
 * (CSV only) The character used to escape special characters, none is set by default.
 */
export type BodyReplaceDatasetApiV1DatasetDatasetNamePutEscapeCharacter = string | null;

/**
 * (CSV only) If true, we are expecting the quote character to be escaped by doubling it.
 */
export type BodyReplaceDatasetApiV1DatasetDatasetNamePutDoubleQuote = boolean | null;

export interface BodyReplaceDatasetApiV1DatasetDatasetNamePut {
  /** (CSV only) If true, we are expecting the quote character to be escaped by doubling it. */
  double_quote?: BodyReplaceDatasetApiV1DatasetDatasetNamePutDoubleQuote;
  /** With dynamic mode off, the following file formats are expected:
- CSV - The first row is always used as headers.
The column delimiter must be a semicolon `;`.
- XML - Elements with the same name on the same level anywhere in the
XML tree are treated as the entities.
    - Example of valid XML structure:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<root>
    <invoices>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
    </invoices>
</root>
```
- XLSX - The first row is always used as headers. */
  dynamic?: boolean;
  /** All [major encodings](https://docs.python.org/3.12/library/codecs.html#standard-encodings)
are supported. If you are not sure, your file probably uses a UTF-8 encoding. */
  encoding: string;
  /** (CSV only) The character used to escape special characters, none is set by default. */
  escape_character?: BodyReplaceDatasetApiV1DatasetDatasetNamePutEscapeCharacter;
  /** (CSV only) The character used to separate fields (columns) in the CSV, default `;`. */
  field_delimiter?: BodyReplaceDatasetApiV1DatasetDatasetNamePutFieldDelimiter;
  /** (CSV, XLSX only) Array of field names. Can be used to set field names
if they are missing in the file, but also to rewrite existing ones.
To rewrite existing ones, ignore_first_line must be set to true.

Must be a `multipart/form-data` valid array - either multiple fields of
the given name, or a single field with comma (`,`) separated values. */
  field_names?: string[];
  /** The following file formats are supported:
- JSON - Must be a list of objects like `[{...}, {...}, ...]`.
- CSV - The dialect of the csv file is automatically determined. If the header is
missing, an alphabetical keys are generated for the columns. If you run into problems,
consider turning off the dynamic mode.
- XML - XML attributes are ignored, namespaces are removed from the key names.
The dataset entities must be nested one level at maximum.
    - Examples of valid XML structures:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoice>
    <id>...</id>
    <name>...</name>
</invoice>
```
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoices>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
</invoices>
```
- XLSX - Only the active spreadsheet is used.
Headers are treated the same way as in a CSV file. */
  file: Blob;
  /** (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row! */
  ignore_first_line?: BodyReplaceDatasetApiV1DatasetDatasetNamePutIgnoreFirstLine;
  /** (CSV only) The character used to quote fields containing special characters, default `"`. */
  quote_character?: BodyReplaceDatasetApiV1DatasetDatasetNamePutQuoteCharacter;
  /** The switch enables calling this endpoint with a non-existing
dataset name, resulting in dataset creation as if the POST method was used. */
  replace_or_new?: boolean;
}

/**
 * (CSV only) The character used to quote fields containing special characters, default `"`.
 */
export type BodyCreateDatasetApiV1DatasetDatasetNamePostQuoteCharacter = string | null;

/**
 * (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row!
 */
export type BodyCreateDatasetApiV1DatasetDatasetNamePostIgnoreFirstLine = boolean | null;

/**
 * (CSV only) The character used to separate fields (columns) in the CSV, default `;`.
 */
export type BodyCreateDatasetApiV1DatasetDatasetNamePostFieldDelimiter = string | null;

/**
 * (CSV only) The character used to escape special characters, none is set by default.
 */
export type BodyCreateDatasetApiV1DatasetDatasetNamePostEscapeCharacter = string | null;

/**
 * (CSV only) If true, we are expecting the quote character to be escaped by doubling it.
 */
export type BodyCreateDatasetApiV1DatasetDatasetNamePostDoubleQuote = boolean | null;

export interface BodyCreateDatasetApiV1DatasetDatasetNamePost {
  /** (CSV only) If true, we are expecting the quote character to be escaped by doubling it. */
  double_quote?: BodyCreateDatasetApiV1DatasetDatasetNamePostDoubleQuote;
  /** With dynamic mode off, the following file formats are expected:
- CSV - The first row is always used as headers.
The column delimiter must be a semicolon `;`.
- XML - Elements with the same name on the same level anywhere in the
XML tree are treated as the entities.
    - Example of valid XML structure:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<root>
    <invoices>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
          <invoice>
            <id>...</id>
            <name>...</name>
          </invoice>
    </invoices>
</root>
```
- XLSX - The first row is always used as headers. */
  dynamic?: boolean;
  /** All [major encodings](https://docs.python.org/3.12/library/codecs.html#standard-encodings)
are supported. If you are not sure, your file probably uses a UTF-8 encoding. */
  encoding: string;
  /** (CSV only) The character used to escape special characters, none is set by default. */
  escape_character?: BodyCreateDatasetApiV1DatasetDatasetNamePostEscapeCharacter;
  /** (CSV only) The character used to separate fields (columns) in the CSV, default `;`. */
  field_delimiter?: BodyCreateDatasetApiV1DatasetDatasetNamePostFieldDelimiter;
  /** (CSV, XLSX only) Array of field names. Can be used to set field names
if they are missing in the file, but also to rewrite existing ones.
To rewrite existing ones, ignore_first_line must be set to true.

Must be a `multipart/form-data` valid array - either multiple fields of
the given name, or a single field with comma (`,`) separated values. */
  field_names?: string[];
  /** The following file formats are supported:
- JSON - Must be a list of objects like `[{...}, {...}, ...]`.
- CSV - The dialect of the csv file is automatically determined. If the header is
missing, an alphabetical keys are generated for the columns. If you run into problems,
consider turning off the dynamic mode.
- XML - XML attributes are ignored, namespaces are removed from the key names.
The dataset entities must be nested one level at maximum.
    - Examples of valid XML structures:
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoice>
    <id>...</id>
    <name>...</name>
</invoice>
```
```xml
<?xml version="1.0" encoding="UTF-8"?>
<invoices>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
    <invoice>
          <id>...</id>
          <name>...</name>
    </invoice>
</invoices>
```
- XLSX - Only the active spreadsheet is used.
Headers are treated the same way as in a CSV file. */
  file: Blob;
  /** (CSV, XLSX only) Ignore first line in the file, false by default.
Can be used to replace header by a custom one (see field_names).
If header is not present, it will remove first data row! */
  ignore_first_line?: BodyCreateDatasetApiV1DatasetDatasetNamePostIgnoreFirstLine;
  /** (CSV only) The character used to quote fields containing special characters, default `"`. */
  quote_character?: BodyCreateDatasetApiV1DatasetDatasetNamePostQuoteCharacter;
}

export type AnnotationWithContentMessages = ResponseMessage[] | null;

export type AnnotationWithContentEmail = string | null;

export type AnnotationLifecycleStatus = typeof AnnotationLifecycleStatus[keyof typeof AnnotationLifecycleStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationLifecycleStatus = {
  created: 'created',
  importing: 'importing',
  failed_import: 'failed_import',
  split: 'split',
  to_review: 'to_review',
  reviewing: 'reviewing',
  in_workflow: 'in_workflow',
  confirmed: 'confirmed',
  rejected: 'rejected',
  exporting: 'exporting',
  exported: 'exported',
  failed_export: 'failed_export',
  postponed: 'postponed',
  deleted: 'deleted',
  purged: 'purged',
} as const;

export type AnnotationWithContentPreviousStatus = AnnotationLifecycleStatus | null;

export interface AnnotationWithContent {
  content: ContentSectionElement[];
  document: string;
  email?: AnnotationWithContentEmail;
  id: number;
  messages?: AnnotationWithContentMessages;
  organization: string;
  pages?: string[];
  previous_status?: AnnotationWithContentPreviousStatus;
  queue: string;
  relations?: string[];
  schema: string;
  status: AnnotationLifecycleStatus;
  url: string;
  [key: string]: unknown;
 }

export type AnnotationContentHookResponseOperationsItem = AddOperation | ReplaceOperation | RemoveOperation;

/**
 * Payload Rossum is expecting as a response to 'annotation_content' event trigger.
 */
export interface AnnotationContentHookResponse {
  automation_blockers?: ResponseAutomationBlocker[];
  messages?: ResponseMessage[];
  operations?: AnnotationContentHookResponseOperationsItem[];
}

export type AnnotationContentAction = typeof AnnotationContentAction[keyof typeof AnnotationContentAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationContentAction = {
  initialize: 'initialize',
  user_update: 'user_update',
  export: 'export',
  started: 'started',
  confirm: 'confirm',
  updated: 'updated',
} as const;

export type AggregateBodyOptionsAnyOf = { [key: string]: unknown };

export type AggregateBodyOptions = AggregateBodyOptionsAnyOf | null;

export interface AggregateBody {
  /**
   * MongoDB aggregate command is used in the background.
Runtime limited to 120 seconds

[MongoDB aggregate documentation]
(https://www.mongodb.com/docs/manual/reference/method/db.collection.aggregate/)

If there is no $limit stage specified after a $search stage, the default limit = 50
will be used.
   * @minItems 1
   */
  aggregate: AggregateBodyAggregateItem[];
  collation?: AggregateBodyCollation;
  dataset: string;
  let?: AggregateBodyLet;
  options?: AggregateBodyOptions;
}

export type AggregateBodyLetAnyOf = { [key: string]: unknown };

export type AggregateBodyLet = AggregateBodyLetAnyOf | null;

export type AggregateBodyCollationAnyOf = { [key: string]: unknown };

export type AggregateBodyCollation = AggregateBodyCollationAnyOf | null;

export type AggregateBodyAggregateItem = { [key: string]: unknown };

export type AggregateOutputPipelineItem = { [key: string]: unknown };

export type AggregateOutputOptionsAnyOf = { [key: string]: unknown };

export type AggregateOutputOptions = AggregateOutputOptionsAnyOf | null;

export type AggregateOutputLetAnyOf = { [key: string]: unknown };

export type AggregateOutputLet = AggregateOutputLetAnyOf | null;

export type AggregateOutputCollationAnyOf = { [key: string]: unknown };

export type AggregateOutputCollation = AggregateOutputCollationAnyOf | null;

export interface AggregateOutput {
  collation?: AggregateOutputCollation;
  let?: AggregateOutputLet;
  options?: AggregateOutputOptions;
  /** @minItems 1 */
  pipeline: AggregateOutputPipelineItem[];
}

/**
 * An AddOperation represents adding a row to a multivalue.

The list of values can contain multiple schema_id, value pairs,
for adding multiple columns of the multivalue row at once.
 */
export interface AddOperation {
  id: number;
  op?: 'add';
  value: OperationValueAdd[];
  [key: string]: unknown;
 }





  export const getMasterDataHub = () => {
/**
 * See if API is alive.
 * @summary Healthz
 */
const healthzApiHealthzGet = <TData = AxiosResponse<PongResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/healthz`,options
    );
  }

/**
 * Upload a new dataset.

Dataset name must be unique in your organization.

Maximum supported file size is 50MB.
 * @summary Create Dataset
 */
const createDatasetApiV1DatasetDatasetNamePost = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string,
    bodyCreateDatasetApiV1DatasetDatasetNamePost: BodyCreateDatasetApiV1DatasetDatasetNamePost, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = new FormData();
formData.append('file', bodyCreateDatasetApiV1DatasetDatasetNamePost.file)
formData.append('encoding', bodyCreateDatasetApiV1DatasetDatasetNamePost.encoding)
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.dynamic !== undefined) {
 formData.append('dynamic', bodyCreateDatasetApiV1DatasetDatasetNamePost.dynamic.toString())
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.field_delimiter !== undefined && bodyCreateDatasetApiV1DatasetDatasetNamePost.field_delimiter !== null) {
 formData.append('field_delimiter', bodyCreateDatasetApiV1DatasetDatasetNamePost.field_delimiter)
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.escape_character !== undefined && bodyCreateDatasetApiV1DatasetDatasetNamePost.escape_character !== null) {
 formData.append('escape_character', bodyCreateDatasetApiV1DatasetDatasetNamePost.escape_character)
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.quote_character !== undefined && bodyCreateDatasetApiV1DatasetDatasetNamePost.quote_character !== null) {
 formData.append('quote_character', bodyCreateDatasetApiV1DatasetDatasetNamePost.quote_character)
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.double_quote !== undefined && bodyCreateDatasetApiV1DatasetDatasetNamePost.double_quote !== null) {
 formData.append('double_quote', bodyCreateDatasetApiV1DatasetDatasetNamePost.double_quote.toString())
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.field_names !== undefined) {
 bodyCreateDatasetApiV1DatasetDatasetNamePost.field_names.forEach(value => formData.append('field_names', value));
 }
if(bodyCreateDatasetApiV1DatasetDatasetNamePost.ignore_first_line !== undefined && bodyCreateDatasetApiV1DatasetDatasetNamePost.ignore_first_line !== null) {
 formData.append('ignore_first_line', bodyCreateDatasetApiV1DatasetDatasetNamePost.ignore_first_line.toString())
 }

    return axios.post(
      `/svc/master-data-hub/api/v1/dataset/${datasetName}`,
      formData,options
    );
  }

/**
 * Fully replace an existing dataset.
 * @summary Replace Dataset
 */
const replaceDatasetApiV1DatasetDatasetNamePut = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string,
    bodyReplaceDatasetApiV1DatasetDatasetNamePut: BodyReplaceDatasetApiV1DatasetDatasetNamePut, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = new FormData();
formData.append('file', bodyReplaceDatasetApiV1DatasetDatasetNamePut.file)
formData.append('encoding', bodyReplaceDatasetApiV1DatasetDatasetNamePut.encoding)
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.dynamic !== undefined) {
 formData.append('dynamic', bodyReplaceDatasetApiV1DatasetDatasetNamePut.dynamic.toString())
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.field_delimiter !== undefined && bodyReplaceDatasetApiV1DatasetDatasetNamePut.field_delimiter !== null) {
 formData.append('field_delimiter', bodyReplaceDatasetApiV1DatasetDatasetNamePut.field_delimiter)
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.escape_character !== undefined && bodyReplaceDatasetApiV1DatasetDatasetNamePut.escape_character !== null) {
 formData.append('escape_character', bodyReplaceDatasetApiV1DatasetDatasetNamePut.escape_character)
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.quote_character !== undefined && bodyReplaceDatasetApiV1DatasetDatasetNamePut.quote_character !== null) {
 formData.append('quote_character', bodyReplaceDatasetApiV1DatasetDatasetNamePut.quote_character)
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.double_quote !== undefined && bodyReplaceDatasetApiV1DatasetDatasetNamePut.double_quote !== null) {
 formData.append('double_quote', bodyReplaceDatasetApiV1DatasetDatasetNamePut.double_quote.toString())
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.field_names !== undefined) {
 bodyReplaceDatasetApiV1DatasetDatasetNamePut.field_names.forEach(value => formData.append('field_names', value));
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.ignore_first_line !== undefined && bodyReplaceDatasetApiV1DatasetDatasetNamePut.ignore_first_line !== null) {
 formData.append('ignore_first_line', bodyReplaceDatasetApiV1DatasetDatasetNamePut.ignore_first_line.toString())
 }
if(bodyReplaceDatasetApiV1DatasetDatasetNamePut.replace_or_new !== undefined) {
 formData.append('replace_or_new', bodyReplaceDatasetApiV1DatasetDatasetNamePut.replace_or_new.toString())
 }

    return axios.put(
      `/svc/master-data-hub/api/v1/dataset/${datasetName}`,
      formData,options
    );
  }

/**
 * Add new and update existing entities in a dataset,
without removing any existing entities.
 * @summary Update Dataset
 */
const updateDatasetApiV1DatasetDatasetNamePatch = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string,
    bodyUpdateDatasetApiV1DatasetDatasetNamePatch: BodyUpdateDatasetApiV1DatasetDatasetNamePatch, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = new FormData();
formData.append('file', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.file)
formData.append('encoding', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.encoding)
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.dynamic !== undefined) {
 formData.append('dynamic', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.dynamic.toString())
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.field_delimiter !== undefined && bodyUpdateDatasetApiV1DatasetDatasetNamePatch.field_delimiter !== null) {
 formData.append('field_delimiter', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.field_delimiter)
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.escape_character !== undefined && bodyUpdateDatasetApiV1DatasetDatasetNamePatch.escape_character !== null) {
 formData.append('escape_character', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.escape_character)
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.quote_character !== undefined && bodyUpdateDatasetApiV1DatasetDatasetNamePatch.quote_character !== null) {
 formData.append('quote_character', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.quote_character)
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.double_quote !== undefined && bodyUpdateDatasetApiV1DatasetDatasetNamePatch.double_quote !== null) {
 formData.append('double_quote', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.double_quote.toString())
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.field_names !== undefined) {
 bodyUpdateDatasetApiV1DatasetDatasetNamePatch.field_names.forEach(value => formData.append('field_names', value));
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.ignore_first_line !== undefined && bodyUpdateDatasetApiV1DatasetDatasetNamePatch.ignore_first_line !== null) {
 formData.append('ignore_first_line', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.ignore_first_line.toString())
 }
if(bodyUpdateDatasetApiV1DatasetDatasetNamePatch.update_or_new !== undefined) {
 formData.append('update_or_new', bodyUpdateDatasetApiV1DatasetDatasetNamePatch.update_or_new.toString())
 }
bodyUpdateDatasetApiV1DatasetDatasetNamePatch.id_keys.forEach(value => formData.append('id_keys', value));

    return axios.patch(
      `/svc/master-data-hub/api/v1/dataset/${datasetName}`,
      formData,options
    );
  }

/**
 * Delete an existing dataset.

**Warning**: all indexes and aliases will be lost!
 * @summary Delete Dataset
 */
const deleteDatasetApiV1DatasetDatasetNameDelete = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/svc/master-data-hub/api/v1/dataset/${datasetName}`,options
    );
  }

/**
 * List all datasets and their status.
 * @summary Get Datasets
 */
const getDatasetsApiV1DatasetGet = <TData = AxiosResponse<DatasetsResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v1/dataset/`,options
    );
  }

/**
 * Perform matching using the supplied configuration.

This endpoint is supposed to be triggered by Rossum.
 * @summary Match
 */
const matchApiV1MatchPost = <TData = AxiosResponse<AnnotationContentHookResponse>>(
    matchEvent: MatchEvent, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v1/match`,
      matchEvent,options
    );
  }

/**
 * Retrieve a previously created dataset operation by its ID.
 * @summary Get Operation
 */
const getOperationApiV1OperationOperationIdGet = <TData = AxiosResponse<DatasetOperationOut>>(
    operationId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v1/operation/${operationId}`,options
    );
  }

/**
 * Retrieve all dataset operations.
 * @summary Get Operations
 */
const getOperationsApiV1OperationGet = <TData = AxiosResponse<OperationsResponse>>(
    params?: GetOperationsApiV1OperationGetParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v1/operation/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Ds Proxy Find
 */
const dsProxyFindApiV1DataFindPost = <TData = AxiosResponse<DSResultsResponse>>(
    findBody: FindBody, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v1/data/find`,
      findBody,options
    );
  }

/**
 * @summary Ds Proxy Aggregate
 */
const dsProxyAggregateApiV1DataAggregatePost = <TData = AxiosResponse<DSResultsResponse>>(
    aggregateBody: AggregateBody, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v1/data/aggregate`,
      aggregateBody,options
    );
  }

/**
 * Enable fuzzy searching for the specified dataset.
 * @summary Enable
 */
const enableApiV1FuzzySearchDatasetNamePost = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v1/fuzzy_search/${datasetName}`,undefined,options
    );
  }

/**
 * Disable fuzzy searching for the specified dataset.
 * @summary Disable
 */
const disableApiV1FuzzySearchDatasetNameDelete = <TData = AxiosResponse<MessageResponse>>(
    datasetName: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/svc/master-data-hub/api/v1/fuzzy_search/${datasetName}`,options
    );
  }

/**
 * List all datasets and their status.
 * @summary Get Datasets
 */
const getDatasetsApiV2DatasetGet = <TData = AxiosResponse<DatasetsDTO>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v2/dataset/`,options
    );
  }

/**
 * @summary Match
 */
const matchApiV2MatchPost = <TData = AxiosResponse<MatchResponse>>(
    matchRequest: MatchRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v2/match/`,
      matchRequest,options
    );
  }

/**
 * Retrieve a previously created dataset operation by its ID.
 * @summary Get Operation
 */
const getOperationApiV2OperationOperationIdGet = <TData = AxiosResponse<OperationLog>>(
    operationId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v2/operation/${operationId}`,options
    );
  }

/**
 * Retrieve all dataset operations.
 * @summary Get Operations
 */
const getOperationsApiV2OperationGet = <TData = AxiosResponse<OperationsDTO>>(
    params?: GetOperationsApiV2OperationGetParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v2/operation/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Get Queries
 */
const getQueriesApiV2QueryGet = <TData = AxiosResponse<QueriesDTO>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/svc/master-data-hub/api/v2/query/`,options
    );
  }

/**
 * @summary Create Query
 */
const createQueryApiV2QueryPost = <TData = AxiosResponse<QueryDTO>>(
    createQueryDTO: CreateQueryDTO, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/svc/master-data-hub/api/v2/query/`,
      createQueryDTO,options
    );
  }

/**
 * @summary Update Query
 */
const updateQueryApiV2QueryQueryIdPatch = <TData = AxiosResponse<QueryDTO>>(
    queryId: string,
    updateQueryDTO: UpdateQueryDTO, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/svc/master-data-hub/api/v2/query/${queryId}`,
      updateQueryDTO,options
    );
  }

/**
 * @summary Delete Query
 */
const deleteQueryApiV2QueryQueryIdDelete = <TData = AxiosResponse<void>>(
    queryId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/svc/master-data-hub/api/v2/query/${queryId}`,options
    );
  }

return {healthzApiHealthzGet,createDatasetApiV1DatasetDatasetNamePost,replaceDatasetApiV1DatasetDatasetNamePut,updateDatasetApiV1DatasetDatasetNamePatch,deleteDatasetApiV1DatasetDatasetNameDelete,getDatasetsApiV1DatasetGet,matchApiV1MatchPost,getOperationApiV1OperationOperationIdGet,getOperationsApiV1OperationGet,dsProxyFindApiV1DataFindPost,dsProxyAggregateApiV1DataAggregatePost,enableApiV1FuzzySearchDatasetNamePost,disableApiV1FuzzySearchDatasetNameDelete,getDatasetsApiV2DatasetGet,matchApiV2MatchPost,getOperationApiV2OperationOperationIdGet,getOperationsApiV2OperationGet,getQueriesApiV2QueryGet,createQueryApiV2QueryPost,updateQueryApiV2QueryQueryIdPatch,deleteQueryApiV2QueryQueryIdDelete}};
export type HealthzApiHealthzGetResult = AxiosResponse<PongResponse>
export type CreateDatasetApiV1DatasetDatasetNamePostResult = AxiosResponse<MessageResponse>
export type ReplaceDatasetApiV1DatasetDatasetNamePutResult = AxiosResponse<MessageResponse>
export type UpdateDatasetApiV1DatasetDatasetNamePatchResult = AxiosResponse<MessageResponse>
export type DeleteDatasetApiV1DatasetDatasetNameDeleteResult = AxiosResponse<MessageResponse>
export type GetDatasetsApiV1DatasetGetResult = AxiosResponse<DatasetsResponse>
export type MatchApiV1MatchPostResult = AxiosResponse<AnnotationContentHookResponse>
export type GetOperationApiV1OperationOperationIdGetResult = AxiosResponse<DatasetOperationOut>
export type GetOperationsApiV1OperationGetResult = AxiosResponse<OperationsResponse>
export type DsProxyFindApiV1DataFindPostResult = AxiosResponse<DSResultsResponse>
export type DsProxyAggregateApiV1DataAggregatePostResult = AxiosResponse<DSResultsResponse>
export type EnableApiV1FuzzySearchDatasetNamePostResult = AxiosResponse<MessageResponse>
export type DisableApiV1FuzzySearchDatasetNameDeleteResult = AxiosResponse<MessageResponse>
export type GetDatasetsApiV2DatasetGetResult = AxiosResponse<DatasetsDTO>
export type MatchApiV2MatchPostResult = AxiosResponse<MatchResponse>
export type GetOperationApiV2OperationOperationIdGetResult = AxiosResponse<OperationLog>
export type GetOperationsApiV2OperationGetResult = AxiosResponse<OperationsDTO>
export type GetQueriesApiV2QueryGetResult = AxiosResponse<QueriesDTO>
export type CreateQueryApiV2QueryPostResult = AxiosResponse<QueryDTO>
export type UpdateQueryApiV2QueryQueryIdPatchResult = AxiosResponse<QueryDTO>
export type DeleteQueryApiV2QueryQueryIdDeleteResult = AxiosResponse<void>
