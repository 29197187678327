import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { last } from 'lodash';
import { matchSorter } from 'match-sorter';

export const fuzzySearchMultipleWords = <T extends object>(
  rows: Array<T>,
  keys: Array<keyof T>,
  filterValue: string
) => {
  if (!filterValue || !filterValue.length) {
    return rows;
  }

  const terms = filterValue.split(' ');
  if (!terms) {
    return rows;
  }

  return terms.reduceRight(
    (results, term) =>
      matchSorter(results, term, {
        keys: keys.map(key => {
          return { threshold: matchSorter.rankings.CONTAINS, key: String(key) };
        }),
      }),
    rows
  );
};

const getMatches = (text: string, search: string) =>
  search
    .split(' ')
    .map(searchPart =>
      match(text, searchPart, {
        insideWords: true,
        findAllOccurrences: true,
      })
    )
    // autosuggest-highlight/match returns all occurrences of the split search term
    // but we want to use only the one that is after the last match
    .reduce((acc, matches) => {
      const nextMatch = matches.find(
        ([firstMatch]) => firstMatch >= (last(acc)?.[1] ?? -1)
      );
      return nextMatch ? [...acc, nextMatch] : acc;
    }, []);

export const HighlightMatches = ({
  search,
  text,
}: {
  search: string;
  text: string;
}) => {
  const matches = getMatches(text, search);
  const parsed = parse(text, matches);

  return (
    <>
      {parsed.map(({ highlight, text: textToHighlight }, index) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={textToHighlight + index}
          style={{
            fontWeight: highlight ? 'bold' : 'normal',
            textDecoration: highlight ? 'underline' : 'none',
          }}
        >
          {textToHighlight}
        </span>
      ))}
    </>
  );
};
