import * as R from 'remeda';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
} from '../../../../types/datapoints';
import { NavigationContext, NavigationStepFunc } from './datapointNavigation';
import { findDatapointIndex } from './findDatapointIndex';
import {
  getSelectedDatapoint,
  getSidebarDatapoint,
  isMultivalue,
  isSimpleMultivalue,
  toNavigationStop,
} from './navigationStop';
import { resolveAnyDatapointPath } from './resolvedDatapointPath';
import { getTotalChildrenCount } from './utils';

const isSelectable = (
  datapoint: AnyDatapointDataST
): datapoint is SimpleDatapointDataST | MultivalueDatapointDataST =>
  datapoint.category !== 'section' && datapoint.category !== 'tuple';

const findNextSelectable = (startIndex: number, ctx: NavigationContext) =>
  ctx.datapoints.find((dp, index) => isSelectable(dp) && index >= startIndex);

const findPrevSelectable = (startIndex: number, ctx: NavigationContext) =>
  R.findLast(
    ctx.datapoints,
    (dp, index) => isSelectable(dp) && index <= startIndex
  );

export const defaultOrderForward: NavigationStepFunc = (current, ctx) => {
  const currentSidebarDatapoint = getSidebarDatapoint(current);

  if (current.kind === 'none' || !currentSidebarDatapoint) {
    // Pick first
    const next = findNextSelectable(0, ctx);

    if (!next) {
      return { kind: 'none' };
    }

    return toNavigationStop(resolveAnyDatapointPath(ctx.datapoints, next));
  }

  // If on "add value", skip to next sidebar datapoint
  if (
    current.kind === 'simple-multivalue-add-value' ||
    current.kind === 'table-multivalue-add-value'
  ) {
    const firstIndexAfterMultivalue =
      findDatapointIndex(ctx.datapoints, currentSidebarDatapoint.id) +
      getTotalChildrenCount(ctx.datapoints, currentSidebarDatapoint) +
      1;

    const nextSidebarDatapoint = findNextSelectable(
      firstIndexAfterMultivalue,
      ctx
    );

    if (!nextSidebarDatapoint) {
      return { kind: 'none' };
    }

    return toNavigationStop(
      resolveAnyDatapointPath(ctx.datapoints, nextSidebarDatapoint)
    );
  }

  const next = findNextSelectable(
    findDatapointIndex(ctx.datapoints, getSelectedDatapoint(current).id) + 1,
    ctx
  );

  const nextStop = toNavigationStop(
    resolveAnyDatapointPath(ctx.datapoints, next)
  );

  // If we would leave a multivalue, stop at add value first
  if (
    isMultivalue(current) &&
    getSidebarDatapoint(current).id !== getSidebarDatapoint(nextStop)?.id
  ) {
    if (isSimpleMultivalue(current)) {
      return {
        kind: 'simple-multivalue-add-value',
        path: [current.path[0], current.path[1]],
      };
    }
    return {
      kind: 'table-multivalue-add-value',
      path: [current.path[0], current.path[1]],
    };
  }

  return nextStop;
};

export const defaultOrderBackward: NavigationStepFunc = (current, ctx) => {
  if (current.kind === 'none') {
    const prevDatapoint = findPrevSelectable(ctx.datapoints.length - 1, ctx);

    if (!prevDatapoint) {
      return { kind: 'none' };
    }

    const first = toNavigationStop(
      resolveAnyDatapointPath(ctx.datapoints, prevDatapoint)
    );

    if (isMultivalue(first)) {
      if (isSimpleMultivalue(first)) {
        return {
          kind: 'simple-multivalue-add-value',
          path: [first.path[0], first.path[1]],
        };
      }
      return {
        kind: 'table-multivalue-add-value',
        path: [first.path[0], first.path[1]],
      };
    }

    return first;
  }

  const currentSidebarDatapoint = getSidebarDatapoint(current);

  // If on "add value", go to last child
  if (
    current.kind === 'simple-multivalue-add-value' ||
    current.kind === 'table-multivalue-add-value'
  ) {
    const lastChildrenIndex =
      findDatapointIndex(ctx.datapoints, currentSidebarDatapoint.id) +
      getTotalChildrenCount(ctx.datapoints, currentSidebarDatapoint);

    return toNavigationStop(
      resolveAnyDatapointPath(ctx.datapoints, ctx.datapoints[lastChildrenIndex])
    );
  }

  const prev = findPrevSelectable(
    findDatapointIndex(ctx.datapoints, getSelectedDatapoint(current).id) - 1,
    ctx
  );

  if (!prev) {
    return { kind: 'none' };
  }

  const prevStop = toNavigationStop(
    resolveAnyDatapointPath(ctx.datapoints, prev)
  );

  // If we are entering a multivalue, stop at add value first
  if (
    isMultivalue(prevStop) &&
    getSidebarDatapoint(current)?.id !== getSidebarDatapoint(prevStop)?.id
  ) {
    if (isSimpleMultivalue(prevStop)) {
      return {
        kind: 'simple-multivalue-add-value',
        path: [prevStop.path[0], prevStop.path[1]],
      };
    }
    return {
      kind: 'table-multivalue-add-value',
      path: [prevStop.path[0], prevStop.path[1]],
    };
  }

  return prevStop;
};
