import { FontDownloadOutlined } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  isAutomaticallyRejected?: boolean;
};

export const AutomationIconWithTooltip = ({
  isAutomaticallyRejected,
}: Props) => {
  const intl = useIntl();

  return (
    <Stack sx={{ zIndex: 1 }}>
      <Tooltip
        title={intl.formatMessage({
          id: isAutomaticallyRejected
            ? 'components.automaticallyRejectedIcon.tooltip'
            : 'components.automationIcon.tooltip',
        })}
        placement="top"
      >
        <FontDownloadOutlined fontSize="small" color="inherit" />
      </Tooltip>
    </Stack>
  );
};
