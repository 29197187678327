import { Popover, Stack } from '@rossum/ui/material';
import { useRef, useState } from 'react';
import { RoleRestrictor } from '../../../components/Restrictors';
import {
  AnnotationsLabelsList,
  OnLabelAction,
} from '../../labels/AnnotationsLabelsList';
import {
  MENU_MAX_HEIGHT,
  MENU_MIN_HEIGHT,
  MENU_WIDTH,
} from '../../labels/components/constants';
import { useMenuPosition } from '../hooks/useMenuPosition';

type Props<T extends { labels: Array<string>; url: string }> = {
  onLabel: OnLabelAction;
  selectedAnnotations: Array<T>;
  children: ({ isHovered }: { isHovered: boolean }) => React.ReactNode;
  // TODO: nestedMenu is temporary solution whole dropdown will be replaced
  // with dialog here: https://rossumai.atlassian.net/browse/AC-3435
  nestedMenu?: boolean;
};

const BatchLabels = <T extends { labels: Array<string>; url: string }>({
  children,
  selectedAnnotations,
  onLabel,
  nestedMenu,
}: Props<T>) => {
  const ref = useRef(null);

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const [isExiting, setIsExiting] = useState<boolean>(false);

  const open = !!anchor;

  const { anchorOrigin, transformOrigin } = useMenuPosition(!!nestedMenu);

  return (
    <RoleRestrictor
      requiredRoles={[
        'admin',
        'organization_group_admin',
        'manager',
        'annotator',
      ]}
    >
      <div>
        <Stack
          onClick={e => {
            setAnchor(e.currentTarget);
          }}
        >
          {children({ isHovered: open })}
        </Stack>

        <Popover
          container={ref.current}
          open={open}
          onClose={() => setAnchor(null)}
          anchorEl={anchor}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={{
            sx: {
              maxHeight: MENU_MAX_HEIGHT,
              minHeight: MENU_MIN_HEIGHT,
              width: MENU_WIDTH,
            },
          }}
          TransitionProps={{
            onExited: () => {
              if (isExiting) setIsExiting(false);
            },
          }}
        >
          <AnnotationsLabelsList
            annotations={selectedAnnotations}
            onLabelsChanged={onLabel}
            onClose={() => {
              setAnchor(null);
              setIsExiting(true);
            }}
            isClosing={isExiting}
          />
        </Popover>
      </div>
    </RoleRestrictor>
  );
};

export { BatchLabels };
