import { last, sortBy } from 'lodash';
import { range } from 'remeda';
import { getOrderedIndexesForGrid } from '../../../../containers/Footer/getOrderedIndexesForGrid';
import { assertNever } from '../../../../lib/typeUtils';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
  TupleDatapointDataST,
} from '../../../../types/datapoints';
import { AnyDatapointSchema } from '../../../../types/schema';
import { NavigationContext } from './datapointNavigation';
import { findDatapointIndex } from './findDatapointIndex';

const getChildIndexInSchemaOrder = (
  datapoints: AnyDatapointDataST[],
  tuple: TupleDatapointDataST,
  datapoint: SimpleDatapointDataST
) => {
  return (
    findDatapointIndex(datapoints, datapoint.id) -
    findDatapointIndex(datapoints, tuple.id) -
    1
  );
};

// Different ways how we can specify a tuple
type TupleSpec =
  | TupleDatapointDataST
  | 'first-in-multivalue'
  | 'last-in-multivalue'
  | { type: 'next'; tuple: TupleDatapointDataST }
  | { type: 'prev'; tuple: TupleDatapointDataST };

// Different ways how we can specify a datapoint inside a tuple
type DatapointInTupleSpec =
  | 'first-in-tuple'
  | 'last-in-tuple'
  | {
      type: 'next';
      datapoint: SimpleDatapointDataST;
      canMoveToDifferentTuple: boolean;
    }
  | {
      type: 'prev';
      datapoint: SimpleDatapointDataST;
      canMoveToDifferentTuple: boolean;
    };

const queryTupleId = (
  ctx: NavigationContext,
  multivalue: MultivalueDatapointDataST,
  tupleSpec: TupleSpec
) => {
  if (tupleSpec === 'first-in-multivalue') return multivalue.children[0].id;
  if (tupleSpec === 'last-in-multivalue') return last(multivalue.children)?.id;

  if (!('type' in tupleSpec)) return tupleSpec.id;

  const newTupleIndex =
    findDatapointIndex(ctx.datapoints, tupleSpec.tuple.id) +
    (tupleSpec.tuple.children.length + 1) *
      (tupleSpec.type === 'prev' ? -1 : 1);

  const anyDatapoint = ctx.datapoints[newTupleIndex];

  if (
    anyDatapoint &&
    anyDatapoint.category === 'tuple' &&
    anyDatapoint.meta.parentId === tupleSpec.tuple.meta.parentId
  ) {
    return anyDatapoint.id;
  }

  return null;
};

const queryTupleInMultivalue = (
  ctx: NavigationContext,
  multivalue: MultivalueDatapointDataST,
  tupleSpec: TupleSpec,
  schemaColumns: AnyDatapointSchema[]
) => {
  const tupleId = queryTupleId(ctx, multivalue, tupleSpec);

  if (!tupleId) return null;

  const tupleIndex = findDatapointIndex(ctx.datapoints, tupleId);
  const tuple = ctx.datapoints[tupleIndex] as TupleDatapointDataST;

  const grids = ctx.getGrids(multivalue);

  const gridsSortedByPage = sortBy(grids, g => g.page);

  const tupleGridIndex = gridsSortedByPage.findIndex(g =>
    g.rows.find(r => r.tupleId === tupleId)
  );

  const orderedIndexes =
    tupleGridIndex === -1
      ? range(0, schemaColumns.length)
      : getOrderedIndexesForGrid(
          gridsSortedByPage[tupleGridIndex],
          schemaColumns
        );

  return {
    tuple,
    tupleIndex,
    orderedIndexes,
  };
};

export const queryDatapointInMultivalue = (
  ctx: NavigationContext,
  multivalue: MultivalueDatapointDataST,
  tupleSpec: TupleSpec,
  datapointSpec: DatapointInTupleSpec,
  schemaColumns: AnyDatapointSchema[]
): [TupleDatapointDataST, SimpleDatapointDataST] | null => {
  if (multivalue.children.length === 0) return null;

  const tupleResult = queryTupleInMultivalue(
    ctx,
    multivalue,
    tupleSpec,
    schemaColumns
  );

  if (!tupleResult) return null;

  const { tuple, tupleIndex, orderedIndexes } = tupleResult;

  if (datapointSpec === 'first-in-tuple') {
    const datapointIndex = tupleIndex + 1 + orderedIndexes[0];

    return [tuple, ctx.datapoints[datapointIndex] as SimpleDatapointDataST];
  }

  if (datapointSpec === 'last-in-tuple') {
    const datapointIndex = tupleIndex + 1 + (last(orderedIndexes) ?? 0);

    return [tuple, ctx.datapoints[datapointIndex] as SimpleDatapointDataST];
  }

  const childIndexInSchemaOrder = getChildIndexInSchemaOrder(
    ctx.datapoints,
    tuple,
    datapointSpec.datapoint
  );
  // TODO return both indexes + is first is last?
  const childIndexInGridOrder = orderedIndexes.indexOf(childIndexInSchemaOrder);

  if (datapointSpec.type === 'next') {
    if (childIndexInGridOrder === orderedIndexes.length - 1) {
      return datapointSpec.canMoveToDifferentTuple
        ? queryDatapointInMultivalue(
            ctx,
            multivalue,
            { type: 'next', tuple },
            'first-in-tuple',
            schemaColumns
          )
        : null;
    }

    const datapointIndex =
      tupleIndex + 1 + orderedIndexes[childIndexInGridOrder + 1];

    return [tuple, ctx.datapoints[datapointIndex] as SimpleDatapointDataST];
  }

  if (datapointSpec.type === 'prev') {
    if (childIndexInGridOrder === 0) {
      return datapointSpec.canMoveToDifferentTuple
        ? queryDatapointInMultivalue(
            ctx,
            multivalue,
            { type: 'prev', tuple },
            'last-in-tuple',
            schemaColumns
          )
        : null;
    }

    const datapointIndex =
      tupleIndex + 1 + orderedIndexes[childIndexInGridOrder - 1];

    return [tuple, ctx.datapoints[datapointIndex] as SimpleDatapointDataST];
  }

  /* istanbul ignore next */
  return assertNever(datapointSpec);
};
