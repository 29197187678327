import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { toHumanString } from '../../lib/helpers';
import GraphsContainer from './components/GraphContainer';
import Line from './components/Line';
import { gray, grayDark } from './constants';
import CategoriesTooltip from './Tooltips/CategoriesTooltip';

type Data = {
  date: string;
  deleted: number;
  exported: number;
  confirmed: number;
  imported: number;
  rejected: number;
};

type Keys = Exclude<keyof Data, 'date'>;

type Props = {
  intl: IntlShape;
  data: Array<Data>;
  lines: Array<{ key: string; color: string; dotClassName: string }>;
  name: 'usage';
  children: ReactNode;
  showTooltip: boolean;
  fullWidth?: boolean;
};

const Graph = ({
  data,
  lines,
  name,
  children,
  showTooltip,
  fullWidth,
  intl,
}: Props) => {
  return (
    <GraphsContainer fullWidth={fullWidth} graphName={name}>
      <span>{children}</span>
      <ResponsiveContainer height="70%">
        <LineChart data={data}>
          <CartesianGrid vertical={false} strokeWidth={0.3} />
          <XAxis tickSize={0} tickMargin={20} dataKey="date" />
          <YAxis
            tickFormatter={toHumanString}
            axisLine={false}
            tickSize={0}
            tickMargin={12}
          />
          {showTooltip && (
            <Tooltip<number, Keys>
              labelStyle={{
                color: gray,
              }}
              content={({ payload }) => (
                <CategoriesTooltip
                  payload={payload?.map(({ name, value, color }) => ({
                    color,
                    value,
                    name: name
                      ? intl.formatMessage({
                          id: `components.graphs.usage.series.${name}`,
                        })
                      : '-',
                  }))}
                />
              )}
              contentStyle={{
                border: 'none',
                backgroundColor: grayDark,
                borderRadius: 10,
              }}
            />
          )}
          {lines.map(Line)}
        </LineChart>
      </ResponsiveContainer>
    </GraphsContainer>
  );
};

export default Graph;
