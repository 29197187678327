import { BillingStatsListResponse } from '@rossum/api-client/billing';
import { PaginationQuery } from '@rossum/api-client/utils';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { StatisticsFilterFormState } from '../components/StatisticsFilter';
import { BillingEntity, getBillingEndpoint } from './useBillingEntity';

const QUERY_KEY_BILLING_STATS_PER_QUEUE = 'billing-stats-per-queue';

export const usePageStatisticsPerQueue = (
  statsBillingEntity: BillingEntity,
  statsFilters: StatisticsFilterFormState,
  statsPagination: Required<PaginationQuery>
): UseQueryResult<BillingStatsListResponse> => {
  const api = useApiClient();
  return useQuery({
    queryKey: [
      QUERY_KEY_BILLING_STATS_PER_QUEUE,
      statsBillingEntity,
      statsFilters,
      statsPagination,
    ] as const,

    queryFn: ({ queryKey: [, billingEntity, filters, pagination] }) => {
      const organizations = filters.organizationUrl
        ? [filters.organizationUrl]
        : undefined;

      const { id, endpoint } = getBillingEndpoint(billingEntity);

      return api.request(
        endpoint.billingStats.get(
          id,
          {
            filters: {
              beginDate: filters.dateRange.from,
              endDate: filters.dateRange.to,
              queues: filters.queues
                ? [
                    ...filters.queues.urls,
                    ...(filters.queues.deleted ? [null] : []),
                  ]
                : undefined,
              organizations,
            },
            groupBy: ['queue'],
            orderBy:
              filters.unit === 'pages'
                ? ['-billable_pages']
                : ['-billable_documents'],
          },
          {
            pageSize: pagination.pageSize,
            page: pagination.page + 1,
          }
        )
      );
    },
  });
};
