import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { nextAnnotation } from '../../../../../redux/modules/annotation/actions';
import { Annotation } from '../../../../../types/annotation';
import { State } from '../../../../../types/state';
import { SidebarFooterAction } from './utils';

type UseAnnotationNavigationActionsResult = {
  previous: SidebarFooterAction | null;
  next: SidebarFooterAction | null;
};

export const useAnnotationNavigationActions = (
  annotation: Annotation
): UseAnnotationNavigationActionsResult => {
  // TODO: We now show a loader on the entire screen, preventing possible multiple clicks at once
  // This makes it harder to quickly jump over N annotations, though it's questionable if we need that
  // But it definitely makes everything blink a bit too much
  const dispatch = useDispatch();

  const intl = useIntl();

  const annotationStack = useSelector((state: State) => state.stack);
  const totalAnnotations = annotationStack.length;
  const currentAnnotationIndex = annotationStack.indexOf(annotation.id);
  const shouldDisplayAnnotationNavigation =
    totalAnnotations > 1 && currentAnnotationIndex !== -1;

  const canGoNext = currentAnnotationIndex < totalAnnotations - 1;

  const canGoPrevious = currentAnnotationIndex > 0;

  const handleNextClick = useCallback(() => {
    dispatch(nextAnnotation(1));
  }, [dispatch]);

  const handlePreviousClick = useCallback(() => {
    dispatch(nextAnnotation(-1));
  }, [dispatch]);

  const result = useMemo(() => {
    return {
      next: shouldDisplayAnnotationNavigation
        ? {
            id: 'next' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.next',
            }),
            disabled: !canGoNext,
            onClick: handleNextClick,
            dataCy: 'next-annotation-arrow',
          }
        : null,
      previous: shouldDisplayAnnotationNavigation
        ? {
            id: 'previous' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.previous',
            }),
            disabled: !canGoPrevious,
            onClick: handlePreviousClick,
            dataCy: 'previous-annotation-arrow',
          }
        : null,
    };
  }, [
    canGoNext,
    canGoPrevious,
    handleNextClick,
    handlePreviousClick,
    intl,
    shouldDisplayAnnotationNavigation,
  ]);

  return result;
};
