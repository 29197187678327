import { CircularProgress, ListItem } from '@rossum/ui/material';
import { forwardRef } from 'react';

/** For when we don't know the remaining number of items
 *  In this case best we can do is display a loader and then jump
 */
// MAYBE: Move this into @rossum-ui when working on lists?
type ListLoaderProps = {
  size?: number;
};

const ListLoader = forwardRef<HTMLLIElement, ListLoaderProps>(
  ({ size = 24 }: ListLoaderProps, ref) => (
    <ListItem
      divider
      ref={ref}
      sx={{ pt: '10px', pb: '10px', justifyContent: 'center' }}
    >
      <CircularProgress size={size} />
    </ListItem>
  )
);

export { ListLoader };
