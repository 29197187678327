import { useMutation, useQuery } from '@tanstack/react-query';
import { datasetApi, getDatasetRequestConfig } from '../api';

export const GET_DATASET_DETAILS_QUERY_KEYS = [
  '@MDH',
  'dsProxyFindApiV1DataFindPost',
] as const;

export const useGetDatasetDetails = ({
  name,
}: {
  name: string | undefined;
}) => {
  return useQuery({
    queryKey: [...GET_DATASET_DETAILS_QUERY_KEYS, name],
    queryFn: async () => {
      if (!name)
        throw new Error(
          'Error: Dataset name is required to fetch dataset details!'
        );
      return datasetApi
        .dsProxyFindApiV1DataFindPost(
          { find: {}, dataset: name },
          getDatasetRequestConfig()
        )
        .then(res => res.data.results);
    },
  });
};

export const useGetDatasetDetailsMutation = () => {
  return useMutation({
    mutationKey: ['@MDH', 'dsProxyFindApiV1DataFindPost'],
    mutationFn: async ({ name }: { name: string }) => {
      return datasetApi
        .dsProxyFindApiV1DataFindPost(
          { find: {}, dataset: name },
          getDatasetRequestConfig()
        )
        .then(res => res.data.results);
    },
  });
};
