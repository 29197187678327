import { ActionType, createAction } from 'typesafe-actions';
import { Organization } from '../../../types/organization';
import {
  FetchOrganizationAdminsPayload,
  SetOrganizationColor,
  SetOrganizationFont,
} from './types';

export const fetchOrganizationFulfilled = createAction(
  'FETCH_ORGANIZATION_FULFILLED',
  (payload: Organization) => payload
)<Organization>();

export const fetchOrganizationAdminsFulfilled = createAction(
  'FETCH_ORGANIZATION_ADMINS_FULFILLED',
  (payload: FetchOrganizationAdminsPayload) => payload
)<FetchOrganizationAdminsPayload>();

export const updateOrganization = createAction(
  'UPDATE_ORGANIZATION',
  (payload: Partial<Organization>) => payload
)<Organization>();

export const updateOrganizationFulfilled = createAction(
  'UPDATE_ORGANIZATION_FULFILLED',
  (payload: Organization) => payload
)<Organization>();

export const setOrganizationFont = createAction(
  'SET_ORGANIZATION_FONT',
  (font: string) => ({
    font,
  })
)<SetOrganizationFont>();

export const setOrganizationColor = createAction(
  'SET_ORGANIZATION_COLOR',
  (color: string) => ({
    color,
  })
)<SetOrganizationColor>();

export const setOrganizationRadiuses = createAction(
  'SET_ORGANIZATION_RADIUSES'
)<void>();

export type OrganizationActions = ActionType<
  | typeof fetchOrganizationFulfilled
  | typeof fetchOrganizationAdminsFulfilled
  | typeof updateOrganization
  | typeof updateOrganizationFulfilled
  | typeof setOrganizationFont
>;
