import { SchemaItem } from '@rossum/api-client/schemas';
import { alpha, Stack, Typography } from '@rossum/ui/material';
import { SchemaFieldIcon } from '../../../ui/schema-field-icon/SchemaFieldIcon';
import { schemaFieldRefPrefix } from '../FormulaEditor/constants';
import { resolveIconType } from '../FormulaPreview/hooks/utils';
import { HighlightMatches } from './searchMatching';

type MenuItemContentFieldProps = {
  schemaField: SchemaItem;
  search: string;
};

const MenuItemContentField = ({
  search,
  schemaField,
}: MenuItemContentFieldProps) => {
  const type = resolveIconType(schemaField);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      data-cy="template-suggester-field"
    >
      {type ? (
        <Stack
          sx={{
            border: '1px solid',
            borderColor: t => alpha(t.palette.text.disabled, 0.15),
            width: 36,
            height: 36,
            borderRadius: '100%',
            color: 'text.secondary',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <SchemaFieldIcon type={type} fontSize="small" />
        </Stack>
      ) : null}
      <Stack>
        <Typography variant="body2">
          <HighlightMatches search={search} text={schemaField.label} />
        </Typography>
        <Typography
          variant="caption"
          color="info.main"
          sx={{
            // TODO: add "code" variant to MUI typography
            fontFamily:
              "Monaco, Menlo, 'Ubuntu Mono', Consolas, 'Source Code Pro', source-code-pro, monospace",
          }}
        >
          <HighlightMatches
            search={search}
            text={`${schemaFieldRefPrefix}.${schemaField.id}`}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};

export { MenuItemContentField };
