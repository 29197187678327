import { Collapse, Stack } from '@rossum/ui/material';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { allMessagesSelector } from '../../../../redux/modules/datapoints/selector';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { SidebarContainer } from '../shared/SidebarContainer';
import { Messages } from '../sidebar-items/shared/Messages';

const GlobalMessages = React.memo(() => {
  const intl = useIntl();

  const messagesVisible = useDocumentStore(
    state => state.sidebarState.messagesVisible
  );

  const globalMessages = useSelector(allMessagesSelector).all;

  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (containerRef.current && messagesVisible) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [messagesVisible]);

  return (
    <Collapse
      in={messagesVisible}
      ref={containerRef}
      sx={{ scrollMarginTop: '150px' }}
    >
      {!!globalMessages && globalMessages.length > 0 ? (
        <SidebarContainer
          title={intl.formatMessage({
            id: 'components.sidebarV2.messages.title',
          })}
        >
          <Stack py={1}>
            <Messages messages={globalMessages} />
          </Stack>
        </SidebarContainer>
      ) : (
        <span />
      )}
    </Collapse>
  );
});

GlobalMessages.displayName = 'GlobalMessages';

export { GlobalMessages };
