import { FunctionRuntime } from '@rossum/api-client/hooks';
import { startOfDay } from 'date-fns';
import LanguagePythonIcon from 'mdi-react/LanguagePythonIcon';
import NodejsIcon from 'mdi-react/NodejsIcon';
import { ComponentType } from 'react';
import { LocalizationKeys } from '../../i18n';
import {
  defaultCodeJS,
  defaultCodePython,
} from '../Extensions/lib/defaultCode';
import { formatCode } from './helpers';

export const runtimesConfig = {
  'nodejs22.x': {
    runtime: 'nodejs22.x',
    canPrettify: true,
    editorMode: 'javascript',
    status: 'default',
    type: 'nodejs',
    translation:
      'containers.settings.extensions.createExtension.function.programmingLanguage.nodejs',
  },
  'nodejs18.x': {
    runtime: 'nodejs18.x',
    canPrettify: true,
    editorMode: 'javascript',
    deprecationDate: startOfDay(new Date('2025-03-1')),
    status: 'default',
    type: 'nodejs',
    translation:
      'containers.settings.extensions.createExtension.function.programmingLanguage.nodejs',
  },
  'python3.12': {
    runtime: 'python3.12',
    canPrettify: false,
    editorMode: 'python',
    status: 'default',
    type: 'python',
    translation:
      'containers.settings.extensions.createExtension.function.programmingLanguage.python',
  },
  'python3.8': {
    runtime: 'python3.8',
    canPrettify: false,
    deprecationDate: startOfDay(new Date('2025-02-28')),
    editorMode: 'python',
    status: 'default',
    type: 'python',
    translation:
      'containers.settings.extensions.createExtension.function.programmingLanguage.python',
  },
} as const satisfies {
  [P in FunctionRuntime]: {
    runtime: P;
    canPrettify: boolean;
    editorMode: 'javascript' | 'python';
    status: 'default' | 'deprecated';
    type: 'nodejs' | 'python';
    translation: LocalizationKeys;
    deprecationDate?: Date;
  };
};

type RuntimesConfig = typeof runtimesConfig;
type RuntimeConfig = RuntimesConfig[keyof RuntimesConfig];
export type RuntimeType = RuntimeConfig['type'];

type RuntimeTypeConfig = {
  [P in RuntimeType]: {
    default: Extract<RuntimeConfig, { type: P; status: 'default' }>['runtime'];
    options: Readonly<Array<Extract<RuntimeConfig, { type: P }>['runtime']>>;
    icon: ComponentType;
  };
};

export const runtimeTypesConfig: RuntimeTypeConfig = {
  nodejs: {
    default: 'nodejs22.x',
    options: ['nodejs22.x', 'nodejs18.x'],
    icon: NodejsIcon,
  },
  python: {
    default: 'python3.12',
    options: ['python3.12', 'python3.8'],
    icon: LanguagePythonIcon,
  },
} as const;

export type DefaultRuntime =
  (typeof runtimeTypesConfig)[keyof typeof runtimeTypesConfig]['default'];

export const defaultNodejsRuntime = runtimeTypesConfig.nodejs.default;
export const defaultPythonRuntime = runtimeTypesConfig.python.default;

export const defaultCode = {
  [defaultNodejsRuntime]: formatCode(defaultCodeJS),
  [defaultPythonRuntime]: defaultCodePython,
} as const;
