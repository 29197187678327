import { IconRefresh } from '@rossum/ui/icons/tabler';
import { Box, SvgIcon, Tooltip, useTheme } from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPendingValidationForFormulaDatapoint,
  updateDatapointValue,
} from '../../../redux/modules/datapoints/actions';
import { validationInProgressSelector } from '../../../redux/modules/datapoints/selector';
import { AnyDatapointDataST } from '../../../types/datapoints';
import { Formula } from '../../../ui/schema-field-icon/Formula';
import { getLoggableDatapointValue } from '../../Datapoint/helpers';
import { FormulaPaused } from '../../icons/FormulaPaused';

type FormulaTooltipProps = {
  data: AnyDatapointDataST;
  isEditable: boolean;
  inFooter?: boolean;
};

type DisconnectedFormulaIconProps = {
  isEditable: boolean;
  onReconnect: () => void;
  inFooter?: boolean;
  validationInProgress?: boolean;
};

const DisconnectedFormulaIcon = ({
  isEditable,
  onReconnect,
  inFooter,
  validationInProgress,
}: DisconnectedFormulaIconProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    if (isEditable && !validationInProgress) {
      setHovered(false);
      onReconnect();
    }
  };

  const iconStyle = {
    fontSize: inFooter ? 10 : undefined,
    lineHeight: 0,
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 0.8,
        cursor: isEditable ? 'pointer' : undefined,
        position: inFooter ? 'absolute' : 'relative',
        right: inFooter ? 0 : undefined,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {hovered && isEditable && !validationInProgress ? (
        <Tooltip
          title={intl.formatMessage({
            id: 'components.documentValidation.formulaField.reconnect.tooltip',
          })}
          placement={inFooter ? 'top' : 'bottom'}
          enterDelay={inFooter ? 300 : 1000}
        >
          <span style={iconStyle}>
            <SvgIcon
              color="secondary"
              fontSize={inFooter ? 'inherit' : 'small'}
              viewBox="0 0 24 24"
            >
              <IconRefresh />
            </SvgIcon>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            validationInProgress
              ? intl.formatMessage({
                  id: 'components.documentValidation.formulaField.reconnect.tooltip.disabled',
                })
              : ''
          }
          placement={inFooter ? 'top' : 'bottom'}
          enterDelay={inFooter ? 300 : 1000}
        >
          <span style={iconStyle}>
            <FormulaPaused
              fontSize={inFooter ? 'inherit' : 'small'}
              stroke={
                validationInProgress
                  ? theme.palette.text.disabled
                  : theme.palette.warning.main
              }
              strokeWidth={inFooter ? 1.5 : 2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </span>
        </Tooltip>
      )}
    </Box>
  );
};

export const FormulaTooltip = ({
  data,
  isEditable,
  inFooter,
}: FormulaTooltipProps) => {
  const dispatch = useDispatch();

  const validationInProgress = useSelector(validationInProgressSelector);

  const onReconnectFormulaField = useCallback(() => {
    dispatch(setPendingValidationForFormulaDatapoint(data.id));
    dispatch(
      updateDatapointValue(
        {
          id: data.id,
          index: data.meta.index,
          validationSource: '',
          oldValue: getLoggableDatapointValue(data),
          reason: 'reconnect-formula-field',
          noRecalculation: false,
        },
        ''
      )
    );
  }, [data, dispatch]);

  const isDisconnectedFormulaField =
    'noRecalculation' in data && data.noRecalculation;

  const hasValidationSourceHuman =
    'validationSources' in data && data.validationSources?.includes('human');

  if (isDisconnectedFormulaField) {
    return (
      <DisconnectedFormulaIcon
        isEditable={isEditable}
        inFooter={inFooter}
        onReconnect={onReconnectFormulaField}
        validationInProgress={validationInProgress}
      />
    );
  }

  if (inFooter) {
    return null;
  }

  return (
    <Formula
      fontSize="small"
      sx={{ mr: 0.8 }}
      color={hasValidationSourceHuman ? 'success' : 'inherit'}
    />
  );
};
