import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import * as animationData from './animations/error.json';

const NoPermission = () => {
  const intl = useIntl();

  return (
    <>
      <Lottie
        loop
        play
        animationData={animationData}
        style={{
          height: '250px',
          width: '250px',
        }}
      />
      <Stack width={380} spacing={2}>
        <Typography variant="h5" color="text.secondary" fontWeight="bold">
          {intl.formatMessage({
            id: `containers.errorPage.no-permission.title`,
          })}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: `containers.errorPage.no-permission.text`,
          })}
        </Typography>
      </Stack>
    </>
  );
};

export { NoPermission };
