import { Chip, Stack, Tooltip } from '@rossum/ui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as R from 'remeda';
import { Url } from '../../../types/basic';
import { WorkspaceWithQueues } from '../../../types/workspace';
import workspaceBadgesStyles from '../style.module.sass';
import { ExtensionText } from './EventsDropdown/components/ExtensionText';
import ExtensionTextWithTooltip from './EventsDropdown/components/ExtensionTextWithTooltip';

type Props = {
  queueUrls: Array<Url>;
  workspaces: Array<WorkspaceWithQueues>;
  id: string | number;
  displayPlaceholder?: boolean;
};

const ExpandableWorkspaceBadges = ({
  queueUrls = [],
  workspaces,
  id,
  displayPlaceholder,
}: Props) => {
  const queues = useMemo(() => {
    const flattenQueues = workspaces.flatMap(({ queues }) => queues);

    return flattenQueues.filter(({ url }) => queueUrls.includes(url));
  }, [workspaces, queueUrls]);

  if (!queueUrls.length || !queues.length) {
    return displayPlaceholder ? (
      <div className={workspaceBadgesStyles.WorkspacesPlaceholder}>
        <FormattedMessage id="containers.settings.extensions.create.queues.placeholder" />
      </div>
    ) : null;
  }

  const workspacesWithQueues = R.groupBy(
    queues,
    queue => queue.workspace ?? undefined
  );
  const [firstItem, ...restOfItems] = Object.keys(workspacesWithQueues);

  const findWorkspace = (url: string) =>
    workspaces.find(_workspace => _workspace.url === url)?.name || 'N/A';

  return (
    <Stack
      direction="row"
      alignItems="center"
      px={1}
      spacing={1}
      sx={{ maxWidth: 'calc(100% - 24px)' }}
    >
      <Chip
        label={
          <ExtensionTextWithTooltip
            secondaryText={findWorkspace(firstItem)}
            primaryText={workspacesWithQueues[firstItem]
              .map(item => item.name)
              .join(',')}
          />
        }
        sx={{ minWidth: 0 }}
      />
      {!!restOfItems.length && (
        <Tooltip
          placement="top"
          disableInteractive
          title={restOfItems.map(workspace => {
            const queueNames = workspacesWithQueues[workspace]
              .map(item => item.name)
              .join(',');

            return (
              <ExtensionText
                key={`${workspace}-${id}`}
                primaryText={queueNames}
                secondaryText={findWorkspace(workspace)}
              />
            );
          })}
        >
          <Chip label={`+${restOfItems.length}`} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default ExpandableWorkspaceBadges;
