import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { apiUrl, isEmbedded } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import {
  isNonEmptyString,
  isNotNullOrUndefined,
} from '../../../lib/typeGuards';
import {
  getCurrentAnnotationId,
  getCurrentQueueId,
  getIDFromString,
  parse,
} from '../../../lib/url';
import { removeNonActiveRanges } from '../annotations/helpers';
import { FetchAnnotationsFulfilledPayload } from '../annotations/types';
import { confirmEditModeFulfilled } from '../editMode/actions';
import { ANNOTATIONS_QUERY } from '../localStorage/actions';
import { startValidation } from '../ui/actions';
import { insertInArray, isActionOf, makeEpic } from '../utils';
import { fetchAnnotationStack, fetchAnnotationStackFulfilled } from './actions';
import { getCachedStack } from './reducer';

const startValidationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startValidation)),
    filter(({ meta: { annotationsIds } }) => !annotationsIds),
    map(action => {
      const annotationsQuery = localStorage.getItem(ANNOTATIONS_QUERY);
      if (!isNonEmptyString(annotationsQuery)) return null;

      const [queueUrl, search] = annotationsQuery.split('?');
      const queueId = getCurrentQueueId(queueUrl);

      return queueId
        ? fetchAnnotationStack(
            queueId,
            search,
            getCurrentAnnotationId(action.meta.url),
            action.meta.previousId,
            action.meta.nextId
          )
        : null;
    }),
    filter(isNotNullOrUndefined)
  )
);

const batchStartValidationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startValidation)),
    pluck('meta', 'annotationsIds'),
    filter(isNotNullOrUndefined),
    map(fetchAnnotationStackFulfilled)
  )
);

export const fetchAnnotationStackEpic = makeEpic(
  (action$, _, { authGetJSON$ }) =>
    action$.pipe(
      filter(isActionOf(fetchAnnotationStack)),
      mergeMap(
        ({ payload: { queue, search, currentId, previousId, nextId } }) => {
          const query = removeNonActiveRanges({
            queue,
            ...parse(search),
            page: 1,
            // fixme
            // @ts-expect-error
            fields: 'id',
            pageSize: 1000,
          });

          return authGetJSON$<FetchAnnotationsFulfilledPayload>(
            `${apiUrl}/annotations`,
            {
              query,
            }
          ).pipe(
            map(({ results }) => {
              const currentStack = results.map(({ id }) => id);
              // current stack already includes current document ID, no actions is needed
              if (currentStack.includes(currentId)) {
                return currentStack;
              }

              // current document ID is missing, but we have document which should be in the stack after/before, let's insert
              // before/after it current document ID
              const nextIdIndex = currentStack.findIndex(
                item => item === nextId
              );

              if (nextIdIndex >= 0) {
                return insertInArray(
                  currentStack,
                  nextIdIndex === 0 ? nextIdIndex : nextIdIndex - 1,
                  currentId
                );
              }

              const previousIdIndex = currentStack.findIndex(
                item => item === previousId
              );

              if (previousIdIndex >= 0) {
                return insertInArray(
                  currentStack,
                  previousIdIndex + 1,
                  currentId
                );
              }

              // there probably can't be such situation(?)
              return currentStack;
            }),
            map(fetchAnnotationStackFulfilled),
            catchError(errorHandler)
          );
        }
      )
    )
);

const updateStackOnSplitEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(confirmEditModeFulfilled)),
    filter(() => !isEmbedded()),
    pluck('payload', 'results'),
    map(annotations =>
      R.pipe(
        annotations.map(({ annotation }) => getIDFromString(annotation)),
        R.filter(R.isTruthy)
      )
    ),
    withLatestFrom(
      state$.pipe(
        pluck('router', 'location', 'pathname'),
        map(getCurrentAnnotationId)
      )
    ),
    filter(([[id], currentAnnotationId]) => id !== currentAnnotationId),
    map(([ids, currentAnnotationId]) => {
      const cachedStack = getCachedStack();
      const index = cachedStack.findIndex(id => id === currentAnnotationId);

      if (index === -1) {
        return undefined;
      }

      const head = cachedStack.slice(0, index);
      const tail = cachedStack.slice(index + 1);

      return fetchAnnotationStackFulfilled([...head, ...ids, ...tail]);
    }),
    filter(isNotNullOrUndefined)
  )
);

export default combineEpics(
  fetchAnnotationStackEpic,
  startValidationEpic,
  batchStartValidationEpic,
  updateStackOnSplitEpic
);
