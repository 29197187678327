import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { OrganizationGroupQueueListQuery } from '@rossum/api-client/organizationGroups';
import { OrganizationGroupQueue } from '@rossum/api-client/organizationGroups';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { useOrganizationGroupUrl } from './useOrganizationGroupUrl';

const QUERY_KEY_QUEUES_PER_ORG_UNPAGINATED =
  'queues-per-organization-unpaginated';

export const useUnpaginatedQueuesPerOrg = (
  orgUrl: Url | undefined,
  orgGroupListQuery: OrganizationGroupQueueListQuery = {}
): UseQueryResult<OrganizationGroupQueue[]> => {
  const orgGroupUrl = useOrganizationGroupUrl();

  return useQuery({
    queryKey: [
      QUERY_KEY_QUEUES_PER_ORG_UNPAGINATED,
      orgGroupUrl,
      orgUrl,
      orgGroupListQuery,
    ] as const,

    queryFn: ({
      queryKey: [, organizationGroupUrl, organizationUrl, query],
    }) =>
      organizationGroupUrl
        ? api.unpaginatedRequest(endpoints.organizationGroups.queues)({
            ...query,
            organization: organizationUrl
              ? getIDFromUrl(organizationUrl)
              : undefined,
            organizationGroupId: getIDFromUrl(organizationGroupUrl),
          })
        : api
            .unpaginatedRequest(endpoints.queues.list)(query)
            .then(result =>
              result.map(row => ({
                id: row.id,
                name: row.name,
                url: row.url,
                workspace: row.workspace ?? '',
                organization: null,
              }))
            ),
  });
};
