import { zodResolver } from '@hookform/resolvers/zod';
import { Engine } from '@rossum/api-client/engines';
import { HttpError } from '@rossum/api-client/errors';
import { InfoOutlined } from '@rossum/ui/icons';
import { Alert, Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { mapValues } from 'remeda';
import { z } from 'zod';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { useDuplicateEngine } from '../../hooks/useDuplicateEngine';
import { engineDetailPath } from '../../paths';

export const DUPLICATE_ENGINE_FORM_ID = 'duplicate_engine_form';

const resolveErrorPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const errorSchema = z
      .object({
        name: z.array(z.string()),
      })
      .partial();

    return errorSchema.catch({}).parse(error.data);
  }

  return {};
};

const engineFormSchema = () =>
  z.object({
    name: z.string().min(1),
  });

type DuplicateEngineFormProps = {
  engine: Engine;
  closeDialog: () => void;
};

const DuplicateEngineForm = ({
  engine,
  closeDialog,
}: DuplicateEngineFormProps) => {
  const intl = useIntl();
  const history = useHistory();

  const { mutate: duplicateEngine, error } = useDuplicateEngine(engine.id);

  const defaultName = `${engine.name} (${intl.formatMessage({
    id: 'features.engines.dialog.duplicateEngine.duplicate',
  })})`;

  const { control, handleSubmit } = useForm({
    errors: mapValues(resolveErrorPayload(error), apiError =>
      apiError ? { type: 'api_error', message: apiError[0] } : undefined
    ),

    defaultValues: { name: defaultName },
    resolver: zodResolver(engineFormSchema()),
  });

  return (
    <Stack
      spacing={4}
      component="form"
      id={DUPLICATE_ENGINE_FORM_ID}
      onSubmit={handleSubmit(({ name }) => {
        duplicateEngine(
          { name },
          {
            onSuccess: engineData => {
              closeDialog();
              history.push(engineDetailPath(engineData.id));
            },
          }
        );
      })}
    >
      <Alert
        variant="filled"
        severity="info"
        icon={<InfoOutlined />}
        sx={{ mx: 2, mt: 1 }}
      >
        <Typography variant="body2" fontWeight="normal">
          {intl.formatMessage({
            id: 'features.engines.dialog.duplicateEngine.alert',
          })}
        </Typography>
      </Alert>
      <TextFieldControl
        ControllerProps={{ control, name: 'name' }}
        label={intl.formatMessage({
          id: 'features.engines.dialog.duplicateEngine.label.name',
        })}
        FieldLabelProps={{
          layout: 'floating',
        }}
        size="medium"
      />
    </Stack>
  );
};

export { DuplicateEngineForm };
