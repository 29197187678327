import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RULE_TEMPLATES_QUERY_KEY } from './useListRuleTemplates';

export const useDeleteRuleTemplate = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([RULE_TEMPLATES_QUERY_KEY]);
    },
    mutationFn: (id: ID) => api.request(endpoints.ruleTemplates.delete(id)),
  });
};
