import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  QUERY_KEY_LAZY_WORKSPACES,
  QUERY_KEY_WORKSPACES_UNPAGINATED,
} from '../../../../business/workspaces';
import { useCreateWorkspace } from '../../../../business/workspaces/useCreateWorkspace';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { safeOrganizationSelector } from '../../../../redux/modules/organization/selectors';
import { createWorkspaceFulfilled } from '../../../../redux/modules/workspaces/actions';
import { State } from '../../../../types/state';

type AddWorkspaceModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddWorkspaceModal = ({ open, onClose }: AddWorkspaceModalProps) => {
  const intl = useIntl();

  const validationSchema = yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.queues.workspaces.name.error',
      })
    ),
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: { name: '' },
    resolver: yupResolver(validationSchema),
  });

  const organization = useSelector(
    (state: State) => safeOrganizationSelector(state)?.url
  );

  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateWorkspace();

  const onSubmit = ({ name }: { name: string }) => {
    if (!organization) {
      throw new Error(
        'Add workspace: Cannot create workspace with no organization'
      );
    }
    mutate(
      { name, organization },
      {
        onSuccess: response => {
          onClose();
          dispatch(createWorkspaceFulfilled(response));
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_WORKSPACES_UNPAGINATED],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_LAZY_WORKSPACES],
          });
        },
      }
    );
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      PaperProps={{
        elevation: 2,
        sx: { width: 448 },
      }}
    >
      <Paper
        elevation={8}
        sx={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 3, py: 2 }}
        >
          <Typography variant="h5">
            {intl.formatMessage({
              id: 'containers.settings.queues.addWorkspace.title',
            })}
          </Typography>
          <IconButton size="small" onClick={() => onClose()}>
            <Close fontSize="large" />
          </IconButton>
        </Stack>
      </Paper>
      <DialogContent sx={{ p: 3, pt: '24px !important' }}>
        <Typography variant="body1" sx={{ pb: 3 }}>
          {intl.formatMessage({ id: 'components.queueDetailForm.description' })}
        </Typography>
        <form
          id="add-workspace-form"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            alignSelf: 'center',
          }}
        >
          <Stack spacing={2}>
            <TextFieldControl
              autoFocus
              ControllerProps={{ control, name: 'name' }}
              label={intl.formatMessage({
                id: 'containers.settings.queues.workspaces.nameNew.placeholder',
              })}
              FieldLabelProps={{
                layout: 'floating',
              }}
              inputProps={{
                'data-cy': 'add-workspace-modal-name-input',
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          type="submit"
          variant="contained"
          form="add-workspace-form"
          startIcon={
            isSubmitting || isLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : null
          }
          data-cy="add-workspace-modal-submit-button"
          disabled={!isValid || isSubmitting || isLoading}
        >
          {intl.formatMessage({
            id: 'containers.settings.queues.addWorkspace',
          })}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {intl.formatMessage({
            id: 'containers.settings.queues.addWorkspace.cancel',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddWorkspaceModal };
