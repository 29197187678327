import { useShallow } from 'zustand/react/shallow';
import { CONTROL_BAR_HEIGHT } from '../../../../constants/values';
import { useFeatureFlag } from '../../../../unleash/useFeatureFlag';
import { Dimensions } from '../../document-store/documentGeometry';
import {
  useCanvasGeometryActions,
  useCanvasGeometryState,
  useDocumentStore,
} from '../../document-store/DocumentStore';
import { calculateScrollbarProps } from './calculateScrollbarProps';
import { Scrollbar } from './Scrollbar';

type DocumentScrollbarsProps = {
  canvasDimensions: Dimensions;
};

export const DocumentScrollbars = ({
  canvasDimensions,
}: DocumentScrollbarsProps) => {
  const { viewportRef, viewportState } = useDocumentStore(
    useShallow(state => ({
      viewportRef: state.viewportRef,
      viewportState: state.viewportState,
    }))
  );

  const matrixState = useCanvasGeometryState();
  const matrixActions = useCanvasGeometryActions();

  const viewport = viewportRef.current?.getBoundingClientRect();
  const totalZoomLevel = viewportState.zoomLevel * matrixState.zoomLevel;

  const scaledViewport = {
    height: (viewport?.height ?? 0) / totalZoomLevel,
    width: (viewport?.width ?? 0) / totalZoomLevel,
  };

  const verticalScrollbarProps = calculateScrollbarProps({
    viewportSize: scaledViewport.height,
    canvasSize: canvasDimensions.height,
    translate: matrixState.translateY,
    setTranslate: (translateY: number) =>
      matrixActions.translateTo({ y: translateY }),
    zoomLevel: matrixState.zoomLevel,
  });

  const horizontalScrollbarProps = calculateScrollbarProps({
    viewportSize: scaledViewport.width,
    canvasSize: canvasDimensions.width,
    translate: matrixState.translateX,
    setTranslate: (translateX: number) =>
      matrixActions.translateTo({ x: translateX }),
    zoomLevel: matrixState.zoomLevel,
  });

  const isNewSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');

  return (
    <>
      {verticalScrollbarProps && (
        <Scrollbar
          orientation="vertical"
          {...verticalScrollbarProps}
          wrapperStyleOverrides={{
            top: isNewSidebarEnabled ? 0 : CONTROL_BAR_HEIGHT,
          }}
        />
      )}
      {horizontalScrollbarProps && (
        <Scrollbar orientation="horizontal" {...horizontalScrollbarProps} />
      )}
    </>
  );
};
