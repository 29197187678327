import React, { useCallback, useMemo, useState } from 'react';
import { useFeatureFlag } from '../unleash/useFeatureFlag';

type EasterEggContextType = {
  easterEggActive: boolean;
  toggleMerry: () => void;
};

const EasterEggContext = React.createContext<EasterEggContextType | null>(null);

export const EasterEggProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [easterEggActive, setEasterEggActive] = useState(false);

  const easterEggFeatureFlagEnabled = useFeatureFlag('xmas');

  const toggleEasterEgg = useCallback(() => {
    if (window.Sentry) {
      if (!easterEggActive) {
        window.Sentry.captureMessage('Easter egg activated!');
      } else {
        window.Sentry.captureMessage('Easter egg deactivated!');
      }
    }
    setEasterEggActive(egg => !egg);
  }, [easterEggActive]);

  const ctxValue = useMemo(
    () => ({
      easterEggActive: easterEggActive && easterEggFeatureFlagEnabled,
      toggleMerry: toggleEasterEgg,
    }),
    [easterEggActive, easterEggFeatureFlagEnabled, toggleEasterEgg]
  );

  return (
    <EasterEggContext.Provider value={ctxValue}>
      {children}
    </EasterEggContext.Provider>
  );
};

export const useEasterEggProvider = () => {
  const ctx = React.useContext(EasterEggContext);

  if (!ctx) {
    throw new Error(
      'useEasterEggProvider must be used within a EasterEggProvider'
    );
  }

  return ctx;
};
