import { isEmpty } from 'lodash';
import createSelector from '../../../lib/createDeepEqualSelector';
import { Organization } from '../../../types/organization';
import { State } from '../../../types/state';
import { usageReportsEnabledSelector as organizationGroupUsageReportsEnabledSelector } from '../organizationGroup/selectors';

/**
 * @deprecated
 */
export const organizationSelector = (state: State): Partial<Organization> =>
  state.organization;

export const safeOrganizationSelector = (
  state: State
): Organization | undefined =>
  state.organization.id ? (state.organization as Organization) : undefined;

const usageReportsEnabledSelector = createSelector(
  organizationSelector,
  organization => organization?.uiSettings?.features?.usageReports
);

export const isTrialSelector = (state: State): boolean | undefined =>
  organizationSelector(state).isTrial;

export const canUseUsageReportsSelector = createSelector(
  isTrialSelector,
  usageReportsEnabledSelector,
  organizationGroupUsageReportsEnabledSelector,
  (isTrial, usageReportsEnabled, organizationGroupUsageReportsEnabled) =>
    (usageReportsEnabled || organizationGroupUsageReportsEnabled) ?? isTrial
);

export const hasBrandingSelector = createSelector(
  organizationSelector,
  organization => !isEmpty(organization.uiSettings?.branding ?? {})
);

// This option is for OEM customers, who want to hide the Aurora branding across app
export const poweredByAuroraSelector = createSelector(
  organizationSelector,
  organization => organization.uiSettings?.branding?.poweredByAurora ?? true
);

export const hideArticleLinksSelector = createSelector(
  organizationSelector,
  organization => organization?.uiSettings?.branding?.hideArticleLinks ?? false
);

export const statisticsUserReportsDisabled = createSelector(
  organizationSelector,
  organization =>
    organization?.uiSettings?.statistics?.disableUserReports ?? false
);

export const isSSOEnable = createSelector(
  safeOrganizationSelector,
  organization =>
    !!organization?.internalInfo?.ssoActive ||
    !!organization?.oidcProvider ||
    false
);

export const supportMenuItemsSelector = createSelector(
  organizationSelector,
  organization => organization?.uiSettings?.branding?.supportMenu
);

export const overduePaymentDateSelector = createSelector(
  organizationSelector,
  organization => organization?.internalInfo?.overduePaymentDate
);

export const customerTypeSelector = createSelector(
  organizationSelector,
  organization => organization?.internalInfo?.customerType
);
