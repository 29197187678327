import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { confirmAnnotation } from '../../../../../redux/modules/annotation/actions';
import {
  queueHasBypassWorkflowsEnabledSelector,
  queueHasWorkflowsSelector,
} from '../../../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import { isUserViewer } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { SidebarFooterAction } from './utils';

type UseBypassWorkflowActionResult = {
  action: SidebarFooterAction | null;
};

export const useBypassWorkflowAction = (
  annotation: Annotation
): UseBypassWorkflowActionResult => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { restrictedAccess } = annotation;

  const queueHasWorkflows = useSelector(queueHasWorkflowsSelector);

  const bypassWorkflows = useSelector(queueHasBypassWorkflowsEnabledSelector);

  const isViewer = useSelector(isUserViewer);

  const handleBypassWorkflow = useCallback(() => {
    dispatch(
      pauseValidation({
        nextAction: confirmAnnotation(annotation?.url, true),
        trigger: 'confirmAnnotation',
        reason: 'surveys',
      })
    );
  }, [annotation?.url, dispatch]);

  const bypassWorkflowVisible =
    !isEmbedded() &&
    annotation &&
    bypassWorkflows &&
    queueHasWorkflows &&
    !isViewer;

  const result = useMemo(() => {
    return {
      action: bypassWorkflowVisible
        ? {
            id: 'bypass' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.bypass',
            }),
            disabled: restrictedAccess,
            onClick: handleBypassWorkflow,
            dataCy: 'annotation-sidebar-confirm',
          }
        : null,
    };
  }, [bypassWorkflowVisible, handleBypassWorkflow, intl, restrictedAccess]);

  return result;
};
