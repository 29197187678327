import {
  IconArrowAutofitWidth,
  IconArrowLeft,
  IconFileSearch,
  IconLanguage,
  IconZoomIn,
  IconZoomOut,
} from '@rossum/ui/icons/tabler';
import {
  AppBar,
  Badge,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Toolbar,
  toolbarClasses,
  Tooltip,
} from '@rossum/ui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useShallow } from 'zustand/react/shallow';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { SearchButton } from '../../../components/NavBar/SearchButton';
import PoweredBy from '../../../components/UI/PoweredBy';
import { isEmbedded } from '../../../constants/config';
import {
  annotationSelector,
  annotationSideloadsSelector,
  pagesSelector,
} from '../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../redux/modules/beforeLeave/actions';
import { displaySearchPanel } from '../../../redux/modules/search/actions';
import { leaveValidation } from '../../../redux/modules/ui/actions';
import { userSelector } from '../../../redux/modules/user/selectors';
import { State } from '../../../types/state';
import { useQuickSearchContext } from '../../quick-search/QuickSearchContext';
import { UserPanel } from '../../user-panel';
import { getBoxCoordinates } from '../document-store/documentGeometry';
import {
  useCanvasGeometryActions,
  useDocumentStore,
  useDocumentStoreContext,
} from '../document-store/DocumentStore';
import { visiblePageZustandSelector } from '../document-store/documentStoreSelectors';
import { useTranslationEnabled } from '../document-translation/hooks/useTranslationEnabled';
import { DocumentContextActions } from './DocumentContextActions';
import { DocumentInformation } from './DocumentInformation';
import { PageNavigation } from './PageNavigation';

const TOOLBAR_ZOOM_FACTOR = 0.2;

type ToolbarV2Props = {
  loading?: boolean;
};

export const TopBarV2 = ({ loading = false }: ToolbarV2Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const matrixActions = useCanvasGeometryActions();

  const isTranslationEnabled = useTranslationEnabled();

  // <translation stuff>
  const user = useSelector(userSelector);

  const setTranslationState = useDocumentStore(
    state => state.translationActions.setTranslationState
  );
  const { targetLanguage } = useDocumentStore(state => state.translationState);

  const handleTranslation = useCallback(() => {
    // navigator.languages contains an array of locales in order of user preference
    const fallbackLanguage = navigator.languages?.[0] ?? navigator.language;

    const newTarget = user.uiSettings.locale ?? fallbackLanguage;

    // TODO move this to a util fn after we align on date locales
    setTranslationState({
      targetLanguage: targetLanguage
        ? undefined
        : newTarget.replace(/[-_].*/, ''),
    });
  }, [targetLanguage, user.uiSettings.locale, setTranslationState]);

  // </translation stuff>

  // <Ex-DocumentToolbar actions>
  const handleZoomIn = useCallback(() => {
    matrixActions.zoomBy(+TOOLBAR_ZOOM_FACTOR, undefined);
  }, [matrixActions]);

  const handleZoomOut = useCallback(() => {
    matrixActions.zoomBy(-TOOLBAR_ZOOM_FACTOR, undefined);
  }, [matrixActions]);

  const handleResetZoom = useCallback(() => {
    matrixActions.resetZoom();
  }, [matrixActions]);

  const showingDocumentSearch = useSelector(
    (state: State) => state.search.shouldShow
  );
  const toggleDocumentSearch = useCallback(() => {
    dispatch(displaySearchPanel(!showingDocumentSearch));
  }, [dispatch, showingDocumentSearch]);
  // </Ex-DocumentToolbar actions>

  // <QuickSearch>
  const { setOpen } = useQuickSearchContext();

  const openQuickSearch = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  // </QuickSearch>

  // <Annotation document + queue + workspace data>
  // TODO: Get rid of Redux here
  const currentAnnotation = useSelector(annotationSelector);
  const annotationSideloads = useSelector(annotationSideloadsSelector);
  const annotationDocument = annotationSideloads.document;
  // </Annotation document + queue + workspace data>

  // <Annotation stack data>
  const annotationStack = useSelector((state: State) => state.stack);
  const totalAnnotations = annotationStack.length;
  const currentAnnotationIndex = currentAnnotation
    ? annotationStack.indexOf(currentAnnotation?.id)
    : -1;
  // </Annotation stack data>

  const leaveAnnotation = useCallback(() => {
    if (isEmbedded()) {
      dispatch(leaveValidation());
    } else {
      const backArrowLink = getAnnotationBacklink();
      dispatch(
        pauseValidation({
          nextAction: push(backArrowLink),
          trigger: 'cancelAnnotation',
          reason: 'surveys',
        })
      );
    }
  }, [dispatch]);

  // <Page scrolling>
  const pages = useSelector(pagesSelector);

  const { dimensions } = useDocumentStoreContext();

  const { pageNumber: visiblePageNumber } = useDocumentStore(
    useShallow(visiblePageZustandSelector(dimensions))
  );

  const handleScrollToPage = useCallback(
    (page: number) => {
      const pageDimension = dimensions.pages.find(p => p.pageNumber === page);

      if (pageDimension) {
        matrixActions.translateIntoViewport(
          getBoxCoordinates(
            page,
            {
              // a third chosen pseudorandomly so that top of the page is on top of the viewport in most cases
              // could probably be done better
              x: pageDimension.dimensions.width / 3,
              y: pageDimension.dimensions.height / 3,
              width: 42,
              height: 69,
            },
            dimensions.pages
          )
        );
      }
    },
    [dimensions.pages, matrixActions]
  );
  // </Page scrolling>

  return (
    <AppBar
      position="static"
      sx={{
        zIndex: 100,
        boxShadow: 'none',
        border: 'none',
      }}
    >
      <Toolbar
        variant="dense"
        component={Paper}
        elevation={0}
        square
        sx={{
          borderBottom: theme => `1px ${theme.palette.divider} solid`,
          boxShadow: 'none',
          [`&.${toolbarClasses.root}`]: {
            px: 1,
            py: 0,
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            sx={{ flex: '1 0 35%', overflow: 'hidden' }}
          >
            <IconButton
              size="medium"
              color="secondary"
              onClick={leaveAnnotation}
            >
              <SvgIcon fontSize="small">
                <IconArrowLeft />
              </SvgIcon>
            </IconButton>
            {loading ? (
              <Skeleton sx={{ width: '100%', height: '100%' }} />
            ) : (
              <>
                <DocumentInformation
                  document={annotationDocument}
                  annotationsInStack={totalAnnotations}
                  currentAnnotationIndex={currentAnnotationIndex}
                />
                <DocumentContextActions
                  id="document-context-actions-button"
                  document={annotationDocument}
                  annotation={currentAnnotation}
                />
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              px: 2,
              flex: '1 0 20%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <Skeleton sx={{ width: '100%', height: '100%' }} />
            ) : (
              <>
                <PageNavigation
                  currentPage={visiblePageNumber}
                  totalPages={pages.length}
                  onNavigate={handleScrollToPage}
                />
                <Tooltip
                  title={intl.formatMessage({
                    id: 'components.topbarV2.actions.zoomIn',
                  })}
                >
                  <IconButton
                    size="medium"
                    color="secondary"
                    onClick={handleZoomIn}
                  >
                    <SvgIcon fontSize="small">
                      <IconZoomIn />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'components.topbarV2.actions.zoomOut',
                  })}
                >
                  <IconButton
                    size="medium"
                    color="secondary"
                    onClick={handleZoomOut}
                  >
                    <SvgIcon fontSize="small">
                      <IconZoomOut />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'components.topbarV2.actions.resetZoom',
                  })}
                >
                  <IconButton
                    size="medium"
                    color="secondary"
                    onClick={handleResetZoom}
                  >
                    <SvgIcon fontSize="small">
                      <IconArrowAutofitWidth />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                {isTranslationEnabled ? (
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'components.documentValidation.translation.toggleButton.tooltip',
                    })}
                  >
                    <Badge
                      invisible={!targetLanguage}
                      badgeContent={targetLanguage?.toUpperCase()}
                      color="success"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      <IconButton
                        size="medium"
                        color="secondary"
                        onClick={handleTranslation}
                      >
                        <SvgIcon
                          fontSize="small"
                          color={targetLanguage ? 'success' : 'inherit'}
                        >
                          <IconLanguage />
                        </SvgIcon>
                      </IconButton>
                    </Badge>
                  </Tooltip>
                ) : null}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'components.topbarV2.actions.find',
                  })}
                >
                  <IconButton
                    size="medium"
                    color="secondary"
                    onClick={toggleDocumentSearch}
                  >
                    <SvgIcon fontSize="small">
                      <IconFileSearch />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              flex: '1 0 35%',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <PoweredBy />
            <SearchButton onClick={openQuickSearch} />
            <UserPanel />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
