import { endpoints, withSideload } from '@rossum/api-client';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { partition, reverse, uniqueBy } from 'remeda';
import { api } from '../../../lib/apiClient';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { filterSchemaColumns } from '../columns/helpers';
import { useTableConfig } from '../columns/hooks/useTableConfig';

export const ALL_DOCUMENTS_DATAPOINTS_QUERY_KEY = 'all-documents-datapoints';

// If there is an annotation that is older than {MAX_STALE_TIME}, force refetch.
const MAX_STALE_TIME = 12000;

// In the forced refetch, fetch also annotations that are about to expire (between {MAX_STALE_TIME} and {MIN_STALE_TIME})
const MIN_STALE_TIME = 8000;

// Number of rows pre-loaded when you open dashboard.
const ROWS_PRELOADED_COUNT = 20;

// "Margin" for virtualization.
const ROW_OFFSET = 4;

// Aggregate data from multiple pages to one page.
const deduplicatePages = <
  R extends { url: string },
  C extends { url: string | null },
>(
  r: ({ results: R[]; content: C[]; responseReceivedAt: number } | undefined)[]
): { results: R[]; content: C[]; responseReceivedAt: number } => {
  const pages = r.filter(isNotNullOrUndefined);
  const flatDatapointData = pages.flatMap(page => page?.content ?? []) ?? [];
  const flatAnnotationData = pages.flatMap(page => page?.results ?? []) ?? [];

  // Using reverse > unique > reverse in order to throw away the first items and keep the last ones.
  const content = reverse(uniqueBy(reverse(flatDatapointData), d => d.url));
  const results = reverse(uniqueBy(reverse(flatAnnotationData), a => a.url));

  // The validity of a set of pages = the validity of the oldest page
  // We are using it to "squash" old pages
  // So both max and min would work, as both would be more than 10s ago.
  const responseReceivedAt = Math.min(
    ...pages.map(({ responseReceivedAt }) => responseReceivedAt)
  );

  return { results, content, responseReceivedAt };
};

const filterRecentlyLoadedAnnotations = (
  renderedAnnotationIds: number[],
  pages: { results: { id: number }[]; responseReceivedAt: number }[],
  staleTime: number
) => {
  const recentlyLoadedAnnotations = new Set(
    pages
      .filter(isStillValid(staleTime))
      .flatMap(page => page.results.map(r => r.id))
  );

  // Filter out those which we have fresh data for.
  return renderedAnnotationIds.filter(id => !recentlyLoadedAnnotations.has(id));
};

// Determine if a page is valid
// = pages whose `responseReceivedAt + {staleTime} seconds` is in the future.
const isStillValid =
  (staleTime: number) => (page: { responseReceivedAt: number }) => {
    return page.responseReceivedAt + staleTime > Date.now();
  };

export const useFetchDashboardDatapoints = ({
  annotations,
  activeQueueId,
}: {
  annotations: { id: number; restricted_access: boolean }[];
  activeQueueId: number | undefined;
}) => {
  const orgId = useSelector(safeOrganizationSelector)?.id;

  const annotationIds = annotations.flatMap(({ restricted_access, id }) =>
    restricted_access ? [] : id
  );

  const { tableConfig } = useTableConfig({
    orgId: orgId ?? 0,
    queueId: activeQueueId ?? 0,
  });

  const schemaColumns = filterSchemaColumns(tableConfig.columns);

  const visibleSchemaIds = schemaColumns
    .filter(c => c.visible)
    .map(c => c.schemaId);

  const queryKey = useMemo(
    () =>
      [
        ALL_DOCUMENTS_DATAPOINTS_QUERY_KEY,
        visibleSchemaIds,
        // When list of annotations changes, invalidate all datapoints.
        // In theory, we could re-use some of the datapoints, but it would be more complicated.
        annotationIds,
      ] as const,
    [annotationIds, visibleSchemaIds]
  );

  const { data, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => {
      if (visibleSchemaIds.length > 0 && annotationIds.length > 0) {
        // Fetch first annotations instantly, otherwise you would need to wait for the interval refetch.
        const idsToFetch =
          pageParam ?? annotationIds.slice(0, ROWS_PRELOADED_COUNT);
        return (
          api
            .request(
              withSideload(
                endpoints.annotations.list({
                  'content.schema_id': visibleSchemaIds,
                  id: idsToFetch,
                }),
                { content: true }
              )
            )
            // We append `responseReceivedAt` to know which data is stale and need to be re-fetched.
            // On the other hand, pagination will have no meaning, so we remove that so that we don't have to take it into account during deduplication.
            .then(({ pagination, ...response }) => ({
              ...response,
              responseReceivedAt: Date.now(),
            }))
        );
      }
      return undefined;
    },
    {
      enabled: visibleSchemaIds.length > 0 && annotationIds.length > 0,
    }
  );

  const queryClient = useQueryClient();
  const fetchMoreDatapoints = useCallback(
    (indexes: [number, number]) => {
      // Find a set of annotation ids in the viewport.
      const renderedAnnotationIds = annotationIds.slice(
        Math.max(indexes[0] - ROW_OFFSET, 0),
        Math.min(indexes[1] + ROW_OFFSET, annotationIds.length)
      );

      const pages = data?.pages.filter(isNotNullOrUndefined) ?? [];
      const veryStaleAnnotations = filterRecentlyLoadedAnnotations(
        renderedAnnotationIds,
        pages,
        MAX_STALE_TIME
      );

      if (
        veryStaleAnnotations.length > 0 &&
        visibleSchemaIds.length > 0 &&
        !isFetchingNextPage
      ) {
        // We filter again for MIN_STALE_TIME. This is to limit the number of requests.
        // If we didn't do this, there might be an annotation that expires in 0.1 seconds so we would need to send another request right after.
        const annotationsToFetch = filterRecentlyLoadedAnnotations(
          renderedAnnotationIds,
          pages,
          MIN_STALE_TIME
        );

        // Fetch them from the API.
        fetchNextPage({
          pageParam: annotationsToFetch,
        })
          // Clean up/deduplicate the state = squash the "very" stale pages together.
          .then(({ data: nextPageData }) => {
            if (nextPageData) {
              const [valid, invalid] = partition(
                nextPageData.pages.filter(isNotNullOrUndefined),
                isStillValid(MAX_STALE_TIME)
              );

              queryClient.setQueryData<typeof nextPageData>(queryKey, {
                pages: [deduplicatePages(invalid), ...valid],
                pageParams: [],
              });
            }
          });
      }
    },
    [
      annotationIds,
      data?.pages,
      fetchNextPage,
      isFetchingNextPage,
      queryClient,
      queryKey,
      visibleSchemaIds.length,
    ]
  );

  const datapointData = useMemo(() => {
    return deduplicatePages(data?.pages ?? []);
  }, [data?.pages]);

  return { schemaColumns, fetchMoreDatapoints, datapointData };
};
