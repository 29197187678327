import { endpoints, ID } from '@rossum/api-client';
import { PatchRelationPayload } from '@rossum/api-client/relations';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../../lib/apiClient';
import { throwError } from '../../../../redux/modules/messages/actions';
import { ATTACHMENTS_RELATIONS_QUERY_KEY } from './useFetchAttachmentRelation';

const PATCH_ATTACHMENT_MUTATION_KEY = 'patch-attachment';

export const usePatchAttachment = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [PATCH_ATTACHMENT_MUTATION_KEY],

    mutationFn: ({
      relationId,
      payload,
    }: {
      relationId: ID;
      payload: PatchRelationPayload;
    }) =>
      api
        .request(endpoints.relations.patch(relationId, payload))
        .then(response => {
          queryClient.invalidateQueries({
            queryKey: [ATTACHMENTS_RELATIONS_QUERY_KEY],
          });
          return response;
        }),

    onError: () => {
      dispatch(throwError('patchAttachmentError'));
    },
  });
};
