import { Components, Theme } from '../../material';

// source of the colors and elevation:
// https://www.figma.com/design/AaBwZVqcfaoe476bCiLqmJ/branch/76sCtm4DNTQWRUW3as9EpJ/Helene-Design-System?m=auto&node-id=11692-9109

// TODO: Perhaps put this onto the theme itself?
export const paperBackgroundMap = {
  dark: {
    '0': 'none',
    '2': 'rgba(26, 31, 39, 1)',
    '4': 'rgba(31, 37, 46, 1)',
    '6': 'rgba(39, 47, 58, 1)',
    '8': 'rgba(40, 50, 62, 1)',
    '12': 'rgba(56, 67, 82, 1)',
    '24': 'rgba(66, 79, 97, 1)',
  },
  light: {
    '0': 'none',
    '2': 'rgba(249, 250, 251, 1)',
    '4': 'rgba(243, 245, 246, 1)',
    '6': 'rgba(238, 239, 242, 1)',
    '8': 'rgba(231, 234, 238, 1)',
    '12': 'rgba(219, 223, 230, 1)',
    '24': 'rgba(207, 213, 221, 1)',
  },
};

const elevations = {
  dark: {
    0: ['0px 0px 0px 1px rgba(166, 177, 193, 0.06) inset'],
    2: ['0px 0px 0px 1px rgba(166, 177, 193, 0.06) inset'],
    4: [
      '0px 1px 4px 1px rgba(13, 17, 23, 0.06)',
      '0px 2px 8px 0px rgba(13, 17, 23, 0.06)',
    ],
    6: [
      '0px 2px 4px 1px rgba(13, 17, 23, 0.1)',
      '0px 2px 8px 0px rgba(13, 17, 23, 0.06)',
    ],
    8: [
      '0px 3px 4px 1px rgba(13, 17, 23, 0.12)',
      '0px 4px 8px 0px rgba(13, 17, 23, 0.08)',
    ],
    12: [
      '0px 2px 8px 1px rgba(13, 17, 23, 0.14)',
      '0px 4px 8px 0px rgba(13, 17, 23, 0.08)',
    ],
    24: [
      '0px 4px 6px 1px rgba(13, 17, 23, 0.18)',
      '0px 4px 10px 0px rgba(13, 17, 23, 0.06)',
    ],
  },
  light: {
    0: ['0px 0px 0px 1px rgba(26, 31, 39, 0.08) inset'],
    2: ['0px 0px 0px 1px rgba(26, 31, 39, 0.08) inset'],
    4: [
      '0px 1px 4px 1px rgba(13, 17, 23, 0.06)',
      '0px 2px 8px 0px rgba(13, 17, 23, 0.06)',
    ],
    6: [
      '0px 2px 4px 1px rgba(13, 17, 23, 0.1)',
      '0px 2px 8px 0px rgba(13, 17, 23, 0.06)',
    ],
    8: [
      '0px 3px 4px 1px rgba(13, 17, 23, 0.12)',
      '0px 4px 8px 0px rgba(13, 17, 23, 0.08)',
    ],
    12: [
      '0px 2px 8px 1px rgba(13, 17, 23, 0.14)',
      '0px 4px 8px 0px rgba(13, 17, 23, 0.08)',
    ],
    24: [
      '0px 4px 6px 1px rgba(13, 17, 23, 0.18)',
      '0px 4px 10px 0px rgba(13, 17, 23, 0.06)',
    ],
  },
};

const getElevationStyles =
  (colors: { dark: Record<string, string>; light: Record<string, string> }) =>
  (elevation: 0 | 2 | 4 | 6 | 8 | 12 | 24, mode: Theme['palette']['mode']) => {
    const color = colors[mode][elevation];

    return {
      boxShadow: elevations[mode][elevation].join(', '),
      backgroundImage:
        color === 'none' ? color : `linear-gradient(${color}, ${color})`,
    };
  };

export const muiPaper =
  (colors: { dark: Record<string, string>; light: Record<string, string> }) =>
  (theme: Theme): Components['MuiPaper'] => {
    return {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(!ownerState?.square && {
            borderRadius: 2 * (theme.shape.borderRadius as number),
          }),
        }),
        elevation0: getElevationStyles(colors)(0, theme.palette.mode),
        elevation1: getElevationStyles(colors)(0, theme.palette.mode),
        elevation2: getElevationStyles(colors)(2, theme.palette.mode),
        elevation3: getElevationStyles(colors)(2, theme.palette.mode),
        elevation4: getElevationStyles(colors)(4, theme.palette.mode),
        elevation5: getElevationStyles(colors)(4, theme.palette.mode),
        elevation6: getElevationStyles(colors)(6, theme.palette.mode),
        elevation7: getElevationStyles(colors)(6, theme.palette.mode),
        elevation8: getElevationStyles(colors)(8, theme.palette.mode),
        elevation9: getElevationStyles(colors)(8, theme.palette.mode),
        elevation10: getElevationStyles(colors)(8, theme.palette.mode),
        elevation11: getElevationStyles(colors)(8, theme.palette.mode),
        elevation12: getElevationStyles(colors)(12, theme.palette.mode),
        elevation13: getElevationStyles(colors)(12, theme.palette.mode),
        elevation14: getElevationStyles(colors)(12, theme.palette.mode),
        elevation15: getElevationStyles(colors)(12, theme.palette.mode),
        elevation16: getElevationStyles(colors)(12, theme.palette.mode),
        elevation17: getElevationStyles(colors)(12, theme.palette.mode),
        elevation18: getElevationStyles(colors)(12, theme.palette.mode),
        elevation19: getElevationStyles(colors)(12, theme.palette.mode),
        elevation20: getElevationStyles(colors)(12, theme.palette.mode),
        elevation21: getElevationStyles(colors)(12, theme.palette.mode),
        elevation22: getElevationStyles(colors)(12, theme.palette.mode),
        elevation23: getElevationStyles(colors)(12, theme.palette.mode),
        elevation24: getElevationStyles(colors)(24, theme.palette.mode),
      },
    };
  };
