import { endpoints } from '@rossum/api-client';
import equal from 'fast-deep-equal/es6/react';
import { every, includes } from 'lodash';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { isTruthy } from 'remeda';
import { from, zip } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  getThisMonthStart,
  getToday,
} from '../../../components/Datepicker/helpers';
import { apiUrl } from '../../../constants/config';
import { granularities } from '../../../containers/Statistics/containers/Granularity';
import { getDummyData } from '../../../containers/Statistics/tempSeries';
import { errorHandler } from '../../../lib/api';
import { api } from '../../../lib/apiClient';
import { asArray, asScalar, parse, stringify } from '../../../lib/url';
import { setItem, STATISTICS_QUERY } from '../localStorage/actions';
import { canUseUsageReportsSelector } from '../organization/selectors';
import { enterStatistics, leaveStatistics } from '../ui/actions';
import { fetchDeletedUsersFulfilled } from '../users/actions';
import { isActionOf, locationChange, makeEpic } from '../utils';
import {
  fetchStatistics,
  fetchStatisticsFulfilled,
  showTmpStatistics,
} from './actions';
import { validateDateQuery } from './helpers';

const onStatisticsQueryChangeEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(enterStatistics)),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(locationChange)),
        filter(
          ({
            payload: {
              location: { pathname },
            },
          }) => pathname.includes('/statistics')
        ),
        distinctUntilChanged(equal),
        mapTo(fetchStatistics())
      )
    )
  )
);

const fetchStatisticsEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchStatistics)),
    map(() => parse(state$.value.router.location.search)),
    filter(() => canUseUsageReportsSelector(state$.value) !== false),
    filter(({ users, queues, beginDate, endDate, groupBy }) =>
      every([users, queues, beginDate, endDate, groupBy])
    ),
    map(({ users, queues, beginDate, endDate, groupBy }) => ({
      filter: {
        users: asArray(users).map(id => `${apiUrl}/users/${id}`),
        queues: asArray(queues).map(id => `${apiUrl}/queues/${id}`),
        // Neither is null because of the previous filter.
        beginDate: asScalar(beginDate!),
        endDate: asScalar(endDate!),
      },
      groupBy: asArray(groupBy),
    })),
    mergeMap(body =>
      from(api.request(endpoints.annotations.usageReport(body))).pipe(
        map(fetchStatisticsFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const showTmpStatisticsEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchStatistics)),
    map(() => parse(state$.value.router.location.search)),
    filter(({ beginDate, endDate, groupBy }) =>
      every([beginDate, endDate, groupBy])
    ),
    map(({ beginDate, endDate, groupBy }) =>
      showTmpStatistics({
        series: getDummyData({
          beginDate: asScalar(beginDate!),
          endDate: asScalar(endDate!),
          groupBy: asScalar(groupBy!),
        }),
        totals: {
          importedCount: 0,
          deletedCount: 0,
          exportedCount: 0,
          turnaroundAvgS: 0,
          correctionsPerDocumentAvg: 0,
          exportedOnTimeCount: 0,
          exportedLateCount: 0,
          exportedAutomatedCount: 0,
          timePerDocumentAvgS: 0,
          rejectedAutomaticallyCount: 0,
          rejectedManuallyCount: 0,
        },
      })
    )
  )
);

const storeStatisticsQueryEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchStatisticsFulfilled)),
    map(() => setItem(STATISTICS_QUERY, state$.value.router.location.search))
  )
);

const defaultStatisticsLocationEpic = makeEpic((action$, state$) =>
  action$.pipe(filter(isActionOf(enterStatistics))).pipe(
    switchMap(() =>
      zip(
        state$.pipe(
          map(state => state.queues.loaded),
          filter(isTruthy)
        ),
        state$.pipe(
          map(state => state.users.loaded),
          filter(isTruthy)
        ),
        action$.pipe(filter(isActionOf(fetchDeletedUsersFulfilled)))
      ).pipe(
        first(),
        takeUntil(action$.pipe(filter(isActionOf(leaveStatistics))))
      )
    ),
    map(([, , { payload: deletedUsersIds }]) => {
      const { beginDate, endDate, queues, users, groupBy } = parse(
        state$.value.router.location.search
      );

      const {
        beginDate: cachedBeginDate,
        endDate: cachedEndDate,
        queues: cachedQueues,
        users: cachedUsers,
        groupBy: cachedGroupBy,
      } = parse(localStorage.getItem(STATISTICS_QUERY) || '');

      const _groupBy = groupBy || cachedGroupBy;
      const validGroupBy = includes(granularities, _groupBy) && _groupBy;

      const usersStateIds = state$.value.users.list.map(user => user.id);

      const usersValue: (string | number)[] = users
        ? asArray(users)
        : cachedUsers
          ? asArray(cachedUsers)
          : usersStateIds;

      const allUsers = [...usersStateIds, ...deletedUsersIds];
      const validUsers = usersValue.filter(userId =>
        allUsers.includes(Number(userId))
      );

      const queuesStateIds = state$.value.queues.list.map(queue => queue.id);

      const queuesValue: (string | number)[] = queues
        ? asArray(queues)
        : cachedQueues
          ? asArray(cachedQueues)
          : [queuesStateIds[0]];

      const validQueues = queuesValue.filter(queueId =>
        queuesStateIds.includes(Number(queueId))
      );

      const query = {
        ...validateDateQuery(
          beginDate || cachedBeginDate,
          endDate || cachedEndDate,
          {
            defaultBeginDate: getThisMonthStart(),
            defaultEndDate: getToday(),
          }
        ),
        groupBy: validGroupBy || 'week',
        users: validUsers,
        queues: validQueues,
      };

      return stringify(query);
    }),
    map(search => replace({ search }))
  )
);

export default combineEpics(
  onStatisticsQueryChangeEpic,
  fetchStatisticsEpic,
  defaultStatisticsLocationEpic,
  storeStatisticsQueryEpic,
  showTmpStatisticsEpic
);
