import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationInput as UncontrolledValidationInput } from '@rossum/rossum-ui/ValidationInput';
import { ValidationTextarea } from '@rossum/rossum-ui/ValidationTextarea';
import { DetailDrawer } from '@rossum/ui';
import { Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import GearIcon from 'mdi-react/CogIcon';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import ValidationInput from '../../../../components/ReactHookForm/ValidationInput';
import HiddingButton from '../../../../components/UI/HiddingButton';
import {
  GITHUB_REGEX2_DOCS,
  WIKIPEDIA_MIME_TYPE_DOCS,
} from '../../../../constants/values';
import { linebreak, link } from '../../../../lib/formaterValues';
import { getUnsafe } from '../../../../lib/helpers';
import { clearValidationsOnChangeCreator } from '../../../../lib/messages';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import { DocumentRejectionConditions } from '../../../../types/inbox';
import {
  formatFileNameList,
  formatMimeTypeList,
  joinElementsByNewline,
  joinElementsWithComma,
  parseElements,
  parseElementsSeparatedByNewline,
} from './helpers';
import styles from './styles.module.sass';

type Props = {
  attachmentFilteringRules: DocumentRejectionConditions;
  clearValidationMessages: typeof clearValidationMessagesAction;
  closeDrawer: () => void;
  updateInbox: (
    _filterParams: Omit<DocumentRejectionConditions, 'enabled'>
  ) => void;
  validationMessages: InputMessages;
  open: boolean;
};

type Form = {
  resolutionHeight: string;
  resolutionWidth: string;
  fileSizeLessThanKB: string;
  mimeTypes: string;
  fileNameRegexes: string;
};

const numberOrNullRegex = /(^[+-]?([0-9]*[.])?[0-9]+$|^$)/;
const mimeTypesRegex = /^[\w*?]+\/[\w\-_.+*?]+$/;

const filteringRulesValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    resolutionHeight: yup
      .string()
      .matches(
        numberOrNullRegex,
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.resolutionHeight.mustBeNumber',
        })
      )
      .required(
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.resolutionHeight.required',
        })
      ),
    resolutionWidth: yup
      .string()
      .matches(
        numberOrNullRegex,
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.resolutionWidth.mustBeNumber',
        })
      )
      .required(
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.resolutionWidth.required',
        })
      ),
    fileSizeLessThanKB: yup
      .string()
      .matches(
        numberOrNullRegex,
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.fileSizeLessThanKB.mustBeNumber',
        })
      )
      .notRequired(),
    mimeTypes: yup
      .string()
      .test(
        'testMimeTypes',
        intl.formatMessage({
          id: 'containers.settings.queues.email.filteringRules.mimeTypes.invalidMimeTypes',
        }),
        value => {
          const mimeTypes = parseElements(value ?? '');
          return mimeTypes.every(type => type.match(mimeTypesRegex));
        }
      )
      .notRequired(),
    fileNameRegexes: yup.string().notRequired(),
  });

const FilteringRulesEditor = ({
  attachmentFilteringRules,
  clearValidationMessages,
  closeDrawer,
  updateInbox,
  validationMessages,
  open,
}: Props) => {
  const intl = useIntl();

  const {
    resolutionLowerThanPx,
    fileSizeLessThanB,
    mimeTypes,
    fileNameRegexes,
  } = attachmentFilteringRules;
  const [resWidth, resHeight] = resolutionLowerThanPx;

  const defaultValues = {
    resolutionHeight: resHeight?.toString(),
    resolutionWidth: resWidth?.toString(),
    fileSizeLessThanKB: fileSizeLessThanB
      ? (fileSizeLessThanB / 1000).toString()
      : '',
    mimeTypes: joinElementsWithComma(mimeTypes),
    fileNameRegexes: joinElementsByNewline(fileNameRegexes),
  };

  const {
    watch,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    control,
  } = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(filteringRulesValidationSchema(intl)),
  });

  const watchFields = watch();

  const {
    field: {
      value: mimeTypesValue,
      onChange: mimeTypesOnChange,
      onBlur: mimeTypesOnBlur,
      ref: mimeTypesRef,
    },
    fieldState: { error: mimeTypesError },
  } = useController({
    name: 'mimeTypes',
    control,
  });

  const {
    field: {
      value: fileNameRegexesValue,
      onChange: fileNameRegexesOnChange,
      onBlur: fileNameRegexesOnBlur,
    },
    fieldState: { error: fileNameRegexesError },
  } = useController({
    name: 'fileNameRegexes',
    control,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      mimeTypesOnChange(formatMimeTypeList(watchFields.mimeTypes));
      fileNameRegexesOnChange(formatFileNameList(watchFields.fileNameRegexes));
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isSubmitSuccessful]);

  const canSubmit = !isEqual(watchFields, defaultValues) && isValid;

  const onSubmit = ({
    resolutionHeight,
    resolutionWidth,
    fileSizeLessThanKB: formSize,
    mimeTypes: formMimeTypes,
    fileNameRegexes: formFileNames,
  }: Form) => {
    const resolution: [number, number] = [
      parseFloat(resolutionWidth),
      parseFloat(resolutionHeight),
    ];
    const size = parseFloat(formSize) * 1000;
    const mime = parseElements(formMimeTypes);
    const fileNames = parseElementsSeparatedByNewline(formFileNames);

    updateInbox({
      resolutionLowerThanPx: resolution,
      fileSizeLessThanB: size,
      mimeTypes: mime,
      fileNameRegexes: fileNames,
    });
  };

  const regexMessages = getUnsafe(validationMessages, [
    'filters',
    'documentRejectionConditions',
    'fileNameRegexes',
  ]);
  const regexMessage = regexMessages
    ? JSON.stringify(regexMessages)
    : undefined;

  const clearValidationsOnChange = clearValidationsOnChangeCreator(
    clearValidationMessages,
    'inbox',
    validationMessages
  );

  return (
    // TODO: This will get fixed when elevations are refactored and then drawer is adjusted in RUI
    <DetailDrawer
      PaperProps={{
        elevation: 2,
        sx: {
          '.DetailDrawer-bodyBox': {
            height: '100%',
          },
        },
      }}
      title={
        <Stack direction="row" spacing={2} alignItems="center">
          <GearIcon size={20} />
          <Typography variant="h5">
            <FormattedMessage id="containers.settings.queues.email.filteringRules.title" />
          </Typography>
        </Stack>
      }
      open={open}
      keepMounted={false}
      onClose={closeDrawer}
    >
      <Stack p={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.FilterRulesDescription}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.description" />
          </div>
          <div className={styles.NameLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.resolution.title" />
          </div>
          <div className={styles.NameSubLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.resolution.subtitle" />
          </div>
          <div className={styles.ResolutionWrapper}>
            <ValidationInput<Form>
              name="resolutionWidth"
              getErrorMessage={(_, error) => error.message ?? ''}
              className={clsx(
                styles.DrawerInput,
                styles.WidthRestrictorResolution
              )}
              control={control}
              isMultiInput
              placeholder={intl.formatMessage({
                id: 'containers.settings.queues.email.filteringRules.resolutionWidth.placeholder',
              })}
            />
            <span className={styles.ResolutionSeparator}>x</span>
            <ValidationInput<Form>
              name="resolutionHeight"
              getErrorMessage={(_, error) => error.message ?? ''}
              className={clsx(
                styles.DrawerInput,
                styles.WidthRestrictorResolution
              )}
              control={control}
              isMultiInput
              placeholder={intl.formatMessage({
                id: 'containers.settings.queues.email.filteringRules.resolutionHeight.placeholder',
              })}
            />
          </div>
          <div className={styles.NameLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.fileSizeLessThanKB.title" />
          </div>
          <div className={styles.NameSubLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.fileSizeLessThanKB.subtitle" />
          </div>
          <ValidationInput<Form>
            name="fileSizeLessThanKB"
            getErrorMessage={(_, error) => error.message ?? ''}
            className={clsx(styles.DrawerInput, styles.WidthRestrictorFileSize)}
            control={control}
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.email.filteringRules.fileSizeLessThanKB.placeholder',
            })}
          />
          <div className={styles.NameLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.mimeTypes.title" />
          </div>
          <div className={styles.NameSubLabel}>
            <FormattedMessage
              id="containers.settings.queues.email.filteringRules.mimeTypes.subtitle"
              values={{ linebreak, link: link(WIKIPEDIA_MIME_TYPE_DOCS) }}
            />
          </div>
          <UncontrolledValidationInput
            className={styles.DrawerInput}
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.email.filteringRules.mimeTypes.placeholder',
            })}
            errorMessage={mimeTypesError?.message ?? ''}
            onBlur={mimeTypesOnBlur}
            pureOnChange={clearValidationsOnChange('filters')(
              mimeTypesOnChange
            )}
            setRef={mimeTypesRef}
            value={mimeTypesValue as string}
          />
          <div className={styles.NameLabel}>
            <FormattedMessage id="containers.settings.queues.email.filteringRules.fileNameRegexes.title" />
          </div>
          <div className={styles.NameSubLabel}>
            <FormattedMessage
              id="containers.settings.queues.email.filteringRules.fileNameRegexes.subtitle"
              values={{
                linebreak,
                link: link(GITHUB_REGEX2_DOCS),
              }}
            />
          </div>
          <ValidationTextarea
            className={styles.FileNameRegexesList}
            id="fileNameRegexes"
            onBlur={fileNameRegexesOnBlur}
            pureOnChange={clearValidationsOnChange('filters')(
              fileNameRegexesOnChange
            )}
            value={(fileNameRegexesValue || '') as string}
            errorMessage={
              typeof regexMessage === 'string'
                ? intl.formatMessage(
                    {
                      id: `containers.settings.queues.email.filteringRules.filters.apiError`,
                    },
                    { message: regexMessage }
                  )
                : fileNameRegexesError?.message ?? ''
            }
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.email.filteringRules.fileNameRegexes.placeholder',
            })}
          />
          <div className={styles.Center}>
            <HiddingButton
              hideCondition={!canSubmit}
              messageId="containers.settings.fields.save"
              type="submit"
            />
          </div>
        </form>
      </Stack>
    </DetailDrawer>
  );
};

export default FilteringRulesEditor;
