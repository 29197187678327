import * as R from 'remeda';
import {
  SidebarEnumFieldModel,
  SidebarLineItemFieldModel,
  SidebarMultivalueChildFieldModel,
  SidebarMultivalueFieldModel,
  SidebarSimplevalueFieldModel,
} from '../useSidebarData';

export type Item =
  | SidebarEnumFieldModel
  | SidebarLineItemFieldModel
  | SidebarMultivalueChildFieldModel
  | SidebarSimplevalueFieldModel
  | SidebarMultivalueFieldModel;

export type ItemWithValue =
  | SidebarEnumFieldModel
  | SidebarMultivalueChildFieldModel
  | SidebarSimplevalueFieldModel;

export const formatConfidenceScore = (score: number | null) =>
  typeof score === 'number' ? `${(score * 100).toFixed(2)}%` : undefined;

export const getFieldsSettingsPath = (
  queueId: number | undefined,
  schemaId: string | undefined
) =>
  typeof queueId === 'number' && !!schemaId
    ? `/queues/${queueId}/settings/fields/${schemaId}`
    : null;

// multivalue range selection utils
export const removeAdjacentRanges =
  (targetIndex: number) =>
  (set: Set<number>): Set<number> => {
    let i = 1;
    let j = 1;

    set.delete(targetIndex);

    while (set.has(targetIndex - i) && targetIndex - i >= 0) {
      set.delete(targetIndex - i);
      i += 1;
    }

    while (set.has(targetIndex + j) && targetIndex + j >= 0) {
      set.delete(targetIndex + j);
      j += 1;
    }

    return set;
  };

export const addRange = (from: number, to: number) => (set: Set<number>) => {
  const newRange = R.range(Math.min(from, to), Math.max(from, to) + 1);
  newRange.forEach(i => set.add(i));
  return set;
};

export const findClosestInSet = (
  batchSelection: Set<number>,
  referenceValue: number
): number | null => {
  let greater: number | null = null;
  let lesser: number | null = null;

  batchSelection.forEach(num => {
    if (num > referenceValue) {
      if (greater === null || num < greater) {
        greater = num;
      }
    } else if (lesser === null || num > lesser) {
      lesser = num;
    }
  });

  return greater ?? lesser ?? null;
};
