import { IconInfoCircle } from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { SidebarContainer } from '../shared/SidebarContainer';

export const ReviewNeeded = () => {
  const intl = useIntl();

  return (
    <SidebarContainer
      title={intl.formatMessage({
        id: 'components.sidebar.reviewIsNeeded.title',
      })}
    >
      <Stack direction="row" spacing={1}>
        <SvgIcon fontSize="small" color="info">
          <IconInfoCircle />
        </SvgIcon>

        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: 'components.sidebar.reviewIsNeeded.description',
          })}
        </Typography>
      </Stack>
    </SidebarContainer>
  );
};
