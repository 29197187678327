import { alpha, SxProps, Theme } from '@rossum/ui/material';
import { CSSProperties } from 'react';
import { calculateWidth } from '../../components/footerCell';
import { getUITypeFromSchema } from '../../redux/modules/schema/helpers';
import { AnyDatapointSchema } from '../../types/schema';

export const DEFAULT_STICKY_HEADER_Z_INDEX = 2;

export const LINE_NUMBER_HEADER_TEXT = '#';

export const highlightOverlay = (theme: Theme) =>
  alpha(
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black,
    0.08
  );

export const commonCheckboxCellStyle: SxProps<Theme> = {
  left: 24,
  backgroundColor: theme => theme.palette.background.paper,
  padding: '6px 10px',
  position: 'sticky',
  // Without fixed width, the checkbox column will get stretched,
  // if there are any columns with stretch: true in the table.
  width: '61px',
};

export const commonCellStyle: SxProps<Theme> = {
  boxShadow: theme =>
    `inset 0px -1px 0px ${
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.common.white, 0.12)
        : alpha(theme.palette.common.black, 0.12)
    }`,
  ':nth-of-type(2)': {
    pl: 1,
  },
  verticalAlign: 'middle',
  backgroundColor: theme => theme.palette.background.paper,
};

export const calculateWidthStyle = (
  column: AnyDatapointSchema
): CSSProperties => {
  const { stretch, width, type, label } = column;
  const uiFieldType = getUITypeFromSchema(column) ?? null;

  const calculated = calculateWidth({ width, type, label, uiFieldType });

  return stretch
    ? {
        minWidth: `${calculated}px`,
      }
    : { width: `${calculated}px` };
};

export const hasVisibleStretchedColumn = (allColumns: AnyDatapointSchema[]) =>
  allColumns.some(c => c.hidden !== false && c.stretch);

export const hasVisibleCollapsibleColumn = (allColumns: AnyDatapointSchema[]) =>
  allColumns.some(c => c.hidden !== false && c.canCollapse);

export const isColumnHidden = (
  column: AnyDatapointSchema,
  footerExpanded: boolean
) => {
  return column.hidden || (!footerExpanded && column.canCollapse);
};

export const canSearchAndReplaceIn = (column: AnyDatapointSchema) => {
  return (
    column.category === 'datapoint' &&
    column.type !== 'button' &&
    column.type !== 'enum' &&
    column.uiConfiguration?.edit !== 'disabled'
  );
};

export const includedInBatchUpdateStyle: SxProps<Theme> = {
  border: '1px dashed #686868',
  borderRadius: '5px',
  position: 'absolute',
  left: 0,
  right: 0,
  height: '100%',
  zIndex: 1,
  pointerEvents: 'none',
};

// For some reason, TS wasn't comfy with just spreading objects, this ensures types
// maybe a more global util?
export const composeSx = (...args: SxProps<Theme>[]): SxProps<Theme> =>
  args.reduce((acc, cur) => ({ ...acc, ...cur }), {});

export const getTableRowIdentifier = (el: HTMLElement) =>
  el.dataset.tupleid ?? '';
