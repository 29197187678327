import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const INBOX_QUERY_KEY = 'inbox';

export const useGetInbox = (inboxId: number) => {
  return useQuery({
    queryKey: [INBOX_QUERY_KEY, inboxId],
    queryFn: () => api.request(endpoints.inboxes.get(inboxId)),
    enabled: !!inboxId,
  });
};
