import { SchemaSection } from '@rossum/api-client/schemas';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CopilotDialog } from '../../../../ui/copilot-dialog/CopilotDialog';
import { useSuggestFormula } from '../hooks/useSuggestFormula';
import { SummaryBox } from './SummaryBox';

type FormulaCopilotProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (response: {
    type: string;
    name: string;
    summary?: string;
    formula: string;
  }) => void;
  schemaContent: SchemaSection[];
  fieldSchemaId: string;
  isIterativeApproach?: boolean;
  summary?: string | undefined;
};

export const FormulaCopilot = ({
  open,
  onClose,
  onSuccess,
  schemaContent,
  fieldSchemaId,
  isIterativeApproach,
  summary,
}: FormulaCopilotProps) => {
  const { mutate, isLoading, isError, data } = useSuggestFormula();

  const onSubmit = useCallback(
    (search: string) => {
      if (search) {
        mutate(
          {
            hint: search,
            fieldSchemaId,
            schemaContent,
          },
          {
            onSuccess: response => {
              const firstResult = response.results[0];

              if (firstResult) {
                onSuccess(firstResult);
              }
            },
          }
        );
      }
    },
    [fieldSchemaId, mutate, onSuccess, schemaContent]
  );

  const intl = useIntl();

  return (
    <CopilotDialog
      title={intl.formatMessage({
        id: isIterativeApproach
          ? 'features.formulas.formulaCopilot.iterative.title'
          : 'features.formulas.formulaCopilot.title',
      })}
      subtitle={
        isIterativeApproach
          ? undefined
          : intl.formatMessage({
              id: 'features.formulas.formulaCopilot.subtitle',
            })
      }
      errorText={intl.formatMessage({
        id: 'features.formulas.formulaCopilot.error',
      })}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      isError={isError || data?.results.length === 0}
      placeholders={([1, 2, 3, 4, 5] as const).map(i =>
        intl.formatMessage({
          id: isIterativeApproach
            ? `features.formulas.formulaCopilot.placeholders.iterative.${i}`
            : `features.formulas.formulaCopilot.placeholders.${i}`,
        })
      )}
      closeText={intl.formatMessage({
        id: 'features.formulas.formulaCopilot.close',
      })}
      confirmText={intl.formatMessage({
        id: 'features.formulas.formulaCopilot.confirm',
      })}
      summary={
        isIterativeApproach && summary ? (
          <SummaryBox value={summary} />
        ) : undefined
      }
    />
  );
};
