import { createReactComponent } from '@tabler/icons-react';

export const IconCustomTableCheck = createReactComponent(
  'outline',
  'customTableCheck',
  'CustomTableCheck',
  [
    [
      'path',
      {
        key: 'iconCustomTableCheck-path',
        d: 'M12 21H5C4.46957 21 3.96086 20.7993 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7993 3.96086 21 4.46957 21 5V13M3 10H21M10 3V21M15 19L17 21L21 17',
      },
    ],
  ]
);
