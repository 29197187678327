import { Url } from '@rossum/api-client';
import { RuleTemplate } from '@rossum/api-client/rule-templates';
import { SchemaRule } from '@rossum/api-client/schemas';
import { IconTransfer } from '@rossum/ui/icons/tabler';
import { Button, Stack, SvgIcon } from '@rossum/ui/material';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { boldText } from '../../../lib/formaterValues';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { QueueDialog } from '../../queues/select-queue/QueueDialog';
import { useDeleteRule } from '../../rules/hooks/useDeleteRule';
import { usePatchRule } from '../../rules/hooks/usePatchRule';
import { notify } from '../../toaster';
import {
  TemplateDistributionGrid,
  TemplateDistributionRuleRowModel,
} from './TemplateDistributionGrid';

type TemplateDistributionProps = {
  value: Url[];
  onChange: React.Dispatch<React.SetStateAction<Url[]>>;
  ruleTemplate: RuleTemplate | undefined;
  ruleTemplateDraft: Partial<RuleTemplate>;
  rules: SchemaRule[] | undefined;
  onDistribute: () => void;
};

export const TemplateDistribution = ({
  value,
  onChange,
  ruleTemplate,
  ruleTemplateDraft,
  rules,
  onDistribute,
}: TemplateDistributionProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteRowValue, setDeleteRowValue] =
    useState<TemplateDistributionRuleRowModel | null>(null);

  const { queues, workspaces } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const { mutate: patchRule, isLoading: isPatching } = usePatchRule();

  const { mutate: deleteRule } = useDeleteRule();

  const onDeleteRowSuccess = useCallback(
    () =>
      notify.success({
        title: intl.formatMessage({
          id: 'containers.message.ruleDeleteSuccess.title',
        }),
      }),
    [intl]
  );

  const onDeleteRowError = useCallback(
    () =>
      notify.error({
        title: intl.formatMessage({
          id: 'containers.message.ruleDeleteFailed.text',
        }),
        description: intl.formatMessage({
          id: 'containers.message.ruleDeleteFailed.title',
        }),
      }),
    [intl]
  );

  const onChangeRowSynchronizationError = useCallback(
    () =>
      notify.error({
        title: intl.formatMessage({
          id: 'containers.message.changeRuleSynchronizationFailed.title',
        }),
        description: intl.formatMessage({
          id: 'containers.message.changeRuleSynchronizationFailed.text',
        }),
      }),
    [intl]
  );

  const onChangeRowSynchronizationSuccess = useCallback(() => {
    notify.success({
      title: intl.formatMessage({
        id: 'containers.message.changeRuleSynchronizationSuccess.title',
      }),
    });
  }, [intl]);

  const ruleRows = useMemo(() => {
    return ruleTemplate && rules && queues
      ? rules.flatMap(rule => {
          const queuesForRule = queues.filter(
            queue => rule.schema === queue.schema
          );
          return queuesForRule.map(queue => ({
            queue,
            workspaceName: workspaces?.find(ws => ws.url === queue.workspace)
              ?.name,
            rule,
          }));
        })
      : [];
  }, [queues, ruleTemplate, rules, workspaces]);

  const queueRows = useMemo(() => {
    return (
      queues
        ?.filter(queue => value.includes(queue.schema))
        .map(queue => ({
          queue,
          workspaceName: workspaces?.find(ws => ws.url === queue.workspace)
            ?.name,
        })) ?? []
    );
  }, [queues, value, workspaces]);

  const rows = useMemo(
    () => [...ruleRows, ...queueRows],
    [queueRows, ruleRows]
  );

  return (
    <>
      <ContentGroup
        isFullWidthLayout
        description={intl.formatMessage({
          id: 'features.ruleTemplates.distribution.title',
        })}
        title={intl.formatMessage({
          id: 'features.ruleTemplates.distribution.description',
        })}
      >
        <TemplateDistributionGrid
          ruleTemplateDraft={ruleTemplateDraft}
          rows={rows}
          onRemoveRow={row => {
            if (value.includes(row.queue.schema)) {
              return onChange(prev => prev.filter(s => s !== row.queue.schema));
            }

            if (row.rule) {
              return setDeleteRowValue(row);
            }

            return null;
          }}
          onChangeSynchronizationOfRow={row => {
            if (row.rule) {
              patchRule(
                {
                  patchedRuleId: row.rule.id,
                  patchedRule: {
                    ...row.rule,
                    synchronizedFromTemplate:
                      !row.rule.synchronizedFromTemplate,
                  },
                },
                {
                  onSuccess: onChangeRowSynchronizationSuccess,
                  onError: onChangeRowSynchronizationError,
                }
              );
            }
          }}
          isChangingSynchronizationSettings={isPatching}
        />
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            {intl.formatMessage({
              id: 'features.ruleTemplates.distribution.addQueue.button',
            })}
          </Button>
          <Button
            variant="contained"
            disabled={!rows.length}
            startIcon={
              // TODO: add loading state when distributing rows
              <SvgIcon>
                <IconTransfer />
              </SvgIcon>
            }
            onClick={() => {
              onDistribute();
            }}
          >
            {intl.formatMessage({
              id: 'features.ruleTemplates.distribution.distribute.button',
            })}
          </Button>
        </Stack>
      </ContentGroup>
      <QueueDialog
        title={intl.formatMessage({
          id: 'features.ruleTemplates.distribution.addQueue.dialog.title',
        })}
        onClose={() => setOpen(false)}
        onConfirm={queue => {
          onChange(prev => [...prev, queue.schema]);
          setOpen(false);
        }}
        open={open}
      />
      {deleteRowValue && deleteRowValue.rule ? (
        <DeleteConfirmationDialog
          title={intl.formatMessage(
            {
              id: 'features.ruleTemplates.deleteRuleRowConfirmation.title',
            },
            {
              queueName: deleteRowValue.queue.name,
              bold: boldText,
            }
          )}
          description={intl.formatMessage(
            {
              id: 'features.ruleTemplates.deleteRuleRowConfirmation.text',
            },
            {
              name: deleteRowValue.rule.name,
              queueName: deleteRowValue.queue.name,
              workspaceName: deleteRowValue.workspaceName,
              bold: boldText,
            }
          )}
          open={deleteRowValue !== null}
          onCancel={() => setDeleteRowValue(null)}
          onConfirm={() => {
            setDeleteRowValue(null);
            return (
              deleteRowValue.rule &&
              deleteRule(
                {
                  deletedRuleId: deleteRowValue.rule.id,
                },
                {
                  onSuccess: onDeleteRowSuccess,
                  onError: onDeleteRowError,
                }
              )
            );
          }}
        />
      ) : null}
    </>
  );
};
