import { Stack, useTheme } from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { useEffect } from 'react';
import { sanitizeLinks } from '../../../lib/htmlSanitization';
import { Email } from '../api-client';

const DOMPurify = createDOMPurify();

const ORIGIN_WRAPPER_STYLE =
  'padding: 25px; background-color: #f6f5f9; color: black; font-family: Inter, sans-serif; line-height: 1.54;';

const ORIGIN_MESSAGE_STYLE =
  'background-color: white; padding: 40px; border-radius: 10px; border: solid 1px #ebeaeb;';

export const EmailBody = ({
  email,
  showHtml,
}: {
  email: Email;
  showHtml: boolean;
}) => {
  const {
    palette: { background, divider, text, mode },
  } = useTheme();

  const wrapperStyle = `padding: 25px; background-color: transparent; color: ${text.secondary}; font-family: Inter, sans-serif; line-height: 1.54;`;
  const messageStyle = `background-color: ${background.paper}; color: ${text.primary}; padding: 40px; border-radius: 10px; border: solid 1px ${divider};`;

  useEffect(() => {
    // Remove existing hook to avoid duplicates
    DOMPurify.removeHook('afterSanitizeAttributes');

    DOMPurify.addHook('afterSanitizeAttributes', (node, _) => {
      sanitizeLinks(node);

      if (
        node.getAttribute('style')?.match(ORIGIN_WRAPPER_STYLE) ||
        node.id === 'email-body'
      ) {
        node.setAttribute('style', wrapperStyle);
      }

      if (node.getAttribute('style')?.match(ORIGIN_MESSAGE_STYLE)) {
        node.setAttribute('style', messageStyle);
      }

      if (
        mode === 'dark' &&
        node.getAttribute('alt')?.match('Powered by Rossum') &&
        node.getAttribute('src')?.includes('powered_by_rossum.png')
      ) {
        const srcUrl = node.getAttribute('src');
        const srcUrlDarkVariant = srcUrl?.replace(
          'powered_by_rossum',
          'powered_by_rossum_dark_variant'
        );

        if (srcUrlDarkVariant) {
          node.setAttribute('src', srcUrlDarkVariant);
        }
      }
    });

    return () => {
      // Remove hook to avoid duplicates when component is unmounted
      DOMPurify.removeHook('afterSanitizeAttributes');
    };
  });
  return showHtml ? (
    <Stack
      sx={theme => ({
        color: theme.palette.text.secondary,
        '& p': {
          margin: 0,
        },
      })}
      {...(email.bodyTextHtml
        ? {
            dangerouslySetInnerHTML: {
              __html: DOMPurify.sanitize(email.bodyTextHtml),
            },
          }
        : {})}
    />
  ) : (
    <Stack component="span" sx={{ color: 'text.secondary' }}>
      {email.bodyTextPlain}
    </Stack>
  );
};
