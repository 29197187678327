import { Chip, Paper, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

export const TrialLimitedBox = ({
  elevation,
  direction,
  children,
}: {
  direction?: 'row' | 'column';
  elevation?: number;
  children: ReactNode;
}) => {
  const intl = useIntl();

  return (
    <Paper
      elevation={elevation}
      sx={{
        borderRadius: 1,
        p: 2,
      }}
    >
      <Stack
        direction={direction}
        spacing={2}
        alignItems={direction === 'row' ? 'center' : undefined}
      >
        <Chip
          color="warning"
          sx={{
            width: 'fit-content',
          }}
          label={intl.formatMessage({
            id: 'containers.settings.queues.email.trialLimited.upgradeChip.label',
          })}
        />
        <Typography variant="caption" color="text.secondary">
          {children}
        </Typography>
      </Stack>
    </Paper>
  );
};
