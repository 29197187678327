import { Tooltip } from '@rossum/ui/material';
import clsx from 'clsx';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { useIntl } from 'react-intl';
import datapointStyles from '../../../../../components/Datapoint/style.module.sass';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import { isFieldRequired } from '../helpers';
import scrollingSubject from '../lib/scrollingSubject';
import styles from '../styles.module.sass';

const typeSwitch = ({
  type,
  format = '',
}: Pick<OriginalAnyDatapointSchema, 'format' | 'type'>) => {
  switch (type) {
    case 'string':
      return 'String';
    case 'date':
      return `Date (${format})`;
    case 'number':
      return 'Number';
    default:
      return null;
  }
};

const Datapoint = ({
  label,
  constraints,
  hidden,
  id,
  format,
  type,
  inFooter,
  toggleCurrent,
}: Pick<
  OriginalAnyDatapointSchema,
  'label' | 'constraints' | 'hidden' | 'type' | 'format' | 'id'
> & { inFooter?: boolean; toggleCurrent: () => void }) => {
  const intl = useIntl();
  return (
    <div
      className={clsx(!inFooter && datapointStyles.Datapoint, styles.Datapoint)}
      onClick={() => {
        scrollingSubject.next(id);
        toggleCurrent();
      }}
    >
      {!inFooter && (
        <div className={clsx(datapointStyles.Title, styles.TitleEncapsulor)}>
          {label}
          {isFieldRequired(constraints, type) && (
            <Tooltip
              title={intl.formatMessage({
                id: 'containers.settings.queues.schema.sidebar.required',
              })}
            >
              <span className={styles.Requirer}>&nbsp;*</span>
            </Tooltip>
          )}
          {hidden && <EyeOffIcon className={styles.HiddenIcon} />}
        </div>
      )}
      <div className={clsx(!inFooter && styles.DatapointValue)}>
        {typeSwitch({ format, type })}
      </div>
    </div>
  );
};

export default Datapoint;
