import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import { isSimpleMultivalueField } from '@rossum/api-client/dedicatedEngineSchema';
import { Warning } from '@rossum/ui/icons';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grow,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SwitchTransition, TransitionGroup } from 'react-transition-group';
import { useMountTransition } from '../../../../../../../../components/UI/MountTransition/useMountTransition';
import { DEDICATED_ENGINES_TRAINING_LINK } from '../../../../../../../../constants/values';
import { link } from '../../../../../../../../lib/formaterValues';
import { EngineStatusChip } from '../../../../../../components/EngineStatusChip';
import { useDedicatedEngineSchemaValidateResult } from '../../../../../../hooks/useDedicatedEngineSchemaValidateResult';
import { EngineSchemaObjectCard } from '../FieldsMappingStep/components/EngineSchemaObjectCard';
import { anyErrors, isErrorAtIndex } from './utils';

type IntegrityValidationStepProps = {
  engine: DedicatedEngine;
  engineSchema: DedicatedEngineSchema;
  onPrevious: () => void;
  onComplete: () => void;
  onError: (e?: unknown) => void;
};

const IntegrityValidationStep = ({
  engine,
  engineSchema,
  onComplete,
  onError,
  onPrevious,
}: IntegrityValidationStepProps) => {
  const intl = useIntl();

  const [transitionProps, transitionOut] = useMountTransition();

  const handlePreviousClick = useCallback(() => {
    transitionOut(onPrevious);
  }, [onPrevious, transitionOut]);

  const { data: integrityValidationData, status: integrityValidationStatus } =
    useDedicatedEngineSchemaValidateResult({
      content: engineSchema.content,
    });

  const showBanner =
    integrityValidationStatus === 'success' && integrityValidationData
      ? anyErrors(integrityValidationData)
        ? 'error'
        : 'success'
      : 'none';

  useEffect(() => {
    if (integrityValidationStatus === 'success' && integrityValidationData) {
      if (anyErrors(integrityValidationData)) {
        onError();
      } else {
        onComplete();
      }
    }
  }, [integrityValidationData, integrityValidationStatus, onComplete, onError]);

  return (
    <Stack spacing={3} divider={<Divider />}>
      <Fade {...transitionProps}>
        <Stack spacing={8} sx={{ pb: 8 }}>
          <Stack
            spacing={3}
            alignItems="center"
            sx={{ width: '100%', pl: 8, pr: 8 }}
          >
            <Stack spacing={2} alignItems="center">
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'components.integrityValidationStep.title',
                })}
              </Typography>
              <EngineStatusChip status={engine.status} />
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                {intl.formatMessage({
                  id: 'components.integrityValidationStep.description',
                })}
              </Typography>
              {engine.status === 'draft' && (
                <SwitchTransition>
                  <Grow key={showBanner} appear unmountOnExit>
                    {showBanner === 'error' ? (
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{ width: '100%' }}
                        icon={<Warning />}
                      >
                        <Typography variant="body2">
                          {intl.formatMessage({
                            id: 'components.integrityValidationStep.errorMessage',
                          })}
                        </Typography>
                      </Alert>
                    ) : showBanner === 'success' ? (
                      <Alert
                        variant="filled"
                        severity="success"
                        sx={{ width: '100%' }}
                      >
                        <Typography variant="body2">
                          {intl.formatMessage({
                            id: 'components.integrityValidationStep.successMessage',
                          })}
                        </Typography>
                      </Alert>
                    ) : (
                      <span />
                    )}
                  </Grow>
                </SwitchTransition>
              )}
            </Stack>
            {integrityValidationStatus === 'loading' ? (
              <CircularProgress />
            ) : integrityValidationStatus === 'error' ? (
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Grow in key="validate-error" appear unmountOnExit>
                  <Alert
                    severity="error"
                    variant="filled"
                    icon={<Warning />}
                    sx={{ width: '100%' }}
                  >
                    {intl.formatMessage({
                      id: 'components.integrityValidationStep.fetchingError',
                    })}
                  </Alert>
                </Grow>
              </Stack>
            ) : (
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TransitionGroup style={{ width: '100%' }} component={null}>
                  {engineSchema.content.fields.map((field, i) =>
                    integrityValidationData &&
                    isErrorAtIndex(integrityValidationData, i) ? (
                      <Grow
                        key={
                          isSimpleMultivalueField(field)
                            ? `${i}-${field.children.engineOutputId}`
                            : `${i}-${field.engineOutputId}`
                        }
                      >
                        <Box sx={{ width: '100%' }}>
                          <EngineSchemaObjectCard
                            field={field}
                            fieldErrors={
                              integrityValidationData.content?.fields?.[i]
                            }
                            fieldType="headerField"
                            actions={[]}
                          />
                        </Box>
                      </Grow>
                    ) : null
                  )}
                </TransitionGroup>
                {showBanner === 'error' && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="start"
                  >
                    {intl.formatMessage({
                      id: 'components.integrityValidationStep.errorsDescription',
                    })}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          {engine.status === 'draft' && (
            <Stack spacing={2} alignItems="center">
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'components.integrityValidationStep.readyTitle',
                })}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({
                  id: 'components.integrityValidationStep.readyDescription',
                })}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Fade>
      <Stack
        direction="row"
        spacing={2}
        sx={{ p: 4, pt: 1 }}
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handlePreviousClick}
        >
          {intl.formatMessage({ id: 'components.stepWorkflowButtons.back' })}
        </Button>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mr: 1, ml: 1, flexShrink: 0 }}
        >
          {intl.formatMessage(
            {
              id: 'containers.settings.engineDetail.helpText',
            },
            {
              link: link(DEDICATED_ENGINES_TRAINING_LINK),
            }
          )}
        </Typography>
        <span />
      </Stack>
    </Stack>
  );
};

export { IntegrityValidationStep };
