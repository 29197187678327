import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@rossum/ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import * as yup from 'yup';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { auroraEngineConfigEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { useSortWorkspacesWithQueues } from '../sidebar/hooks/useSortWorkspacesWithQueues';
import { useWorkspaceSorting } from '../sidebar/hooks/useWorkspaceSorting';
import { DocumentType } from './data';
import { QueueDetailFormModel, regions } from './utils';

const QueueDetailFormSchema = (intl: IntlShape) =>
  yup.object({
    queueName: yup.string().required(
      intl.formatMessage({
        id: 'components.queueDetailForm.queueName.required',
      })
    ),
    workspaceUrl: yup.string().when('addingNewWorkspace', {
      is: false,
      then: schema =>
        schema.required(
          intl.formatMessage({
            id: 'components.queueDetailForm.workspace.required',
          })
        ),
    }),
    newWorkspaceName: yup.string().when('addingNewWorkspace', {
      is: true,
      then: schema =>
        schema.required(
          intl.formatMessage({
            id: 'components.queueDetailForm.workspace.required',
          })
        ),
    }),
    region: yup.string().when('documentType', {
      is: 'tax_invoice',
      then: schema =>
        schema.required(
          intl.formatMessage({
            id: 'components.queueDetailForm.region.required',
          })
        ),
    }),
    includeDocuments: yup.boolean(),
  });

type QueueDetailFormProps = {
  onClose: () => void;
  onChange?: (formModel: QueueDetailFormModel) => void;
  defaultValues: QueueDetailFormModel;
  selectedTypeName: DocumentType['id'];
  onSubmit: (formModel: QueueDetailFormModel) => void;
  mutationLoading: boolean;
};

const QueueDetailForm = ({
  onClose,
  onChange,
  defaultValues,
  selectedTypeName,
  onSubmit,
  mutationLoading,
}: QueueDetailFormProps) => {
  const intl = useIntl();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(QueueDetailFormSchema(intl)),
  });

  // This is in form model for validation and processing purposes
  const addingNewWorkspace = watch('addingNewWorkspace');

  const { allWorkspacesWithQueues: unsortedWorkspaces, isLoading } =
    useWorkspacesWithQueues({
      enableQueries: true,
      onWorkspacesSuccessfulFetched: workspaces => {
        if (!workspaces || workspaces.length === 0) {
          setValue('addingNewWorkspace', true);
        }
      },
    });

  const { sorting } = useWorkspaceSorting();
  const { sortedWorkspaces } = useSortWorkspacesWithQueues({
    workspaces: unsortedWorkspaces ?? [],
    sorting,
  });

  const workspaces = sortedWorkspaces?.map(w => ({
    ...w,
    label: w.name,
  }));

  useEffect(() => {
    const subscription = watch(value =>
      onChange?.({ ...defaultValues, ...value })
    );
    return () => subscription.unsubscribe();
  }, [defaultValues, onChange, watch]);

  const auroraConfigEnabled = useSelector(auroraEngineConfigEnabledSelector);

  return (
    <form id="queue-detail-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body1" sx={{ pt: 1 }}>
            {intl.formatMessage({
              id: auroraConfigEnabled
                ? 'components.addQueueDialog.step.basicInformation'
                : `components.selectDocumentType.documentTypeId.${snakeToCamel(
                    selectedTypeName
                  )}`,
            })}
          </Typography>
          {auroraConfigEnabled ? (
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'components.addQueueDialog.step.basicInformation.description',
              })}
            </Typography>
          ) : null}
        </Stack>

        <Stack>
          <TextFieldControl
            ControllerProps={{ control, name: 'queueName' }}
            FieldLabelProps={{
              layout: 'floating',
            }}
            autoComplete="off"
            size="small"
            label={intl.formatMessage({
              id: 'components.queueDetailForm.queueName.label',
            })}
            helperText={' '}
            data-cy="queue-form-name"
            required
            autoFocus
          />
          <SwitchTransition>
            <Grow key={addingNewWorkspace ? 'new' : 'existing'} unmountOnExit>
              {addingNewWorkspace ? (
                <Box>
                  <TextFieldControl
                    ControllerProps={{ control, name: 'newWorkspaceName' }}
                    FieldLabelProps={{ layout: 'floating' }}
                    label={intl.formatMessage({
                      id: 'components.queueDetailForm.workspace.label',
                    })}
                    helperText={' '}
                    size="small"
                    fullWidth
                    autoComplete="off"
                    autoFocus={workspaces?.length !== 0}
                    InputProps={{
                      endAdornment: workspaces?.length !== 0 && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() =>
                              setValue('addingNewWorkspace', false)
                            }
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required={addingNewWorkspace}
                    data-cy="queue-form-new-workspace-name"
                  />
                </Box>
              ) : (
                <Box>
                  <TextFieldControl
                    ControllerProps={{ control, name: 'workspaceUrl' }}
                    FieldLabelProps={{ layout: 'floating' }}
                    label={intl.formatMessage({
                      id: 'components.queueDetailForm.workspace.label',
                    })}
                    select
                    autoComplete="off"
                    fullWidth
                    required={!addingNewWorkspace}
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        sx: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    helperText={' '}
                    data-cy="queue-form-workspace-select"
                  >
                    <MenuItem
                      onClick={() => {
                        setValue('addingNewWorkspace', true);
                      }}
                    >
                      {intl.formatMessage({
                        id: 'components.queueDetailForm.addNewWorkspace',
                      })}
                    </MenuItem>
                    {(workspaces ?? []).map(ws => (
                      <MenuItem
                        key={ws.url}
                        value={ws.url}
                        data-cy={`queue-form-workspace-item-${ws.id}`}
                      >
                        {ws.label}
                      </MenuItem>
                    ))}
                  </TextFieldControl>
                </Box>
              )}
            </Grow>
          </SwitchTransition>
          {defaultValues.documentType === 'tax_invoice' && (
            <TextFieldControl
              ControllerProps={{ control, name: 'region' }}
              FieldLabelProps={{ layout: 'floating' }}
              label={intl.formatMessage({
                id: 'components.queueDetailForm.region.label',
              })}
              select
              required={defaultValues.documentType === 'tax_invoice'}
              autoComplete="off"
              size="small"
              SelectProps={{
                MenuProps: {
                  sx: {
                    maxHeight: 300,
                  },
                },
              }}
              helperText={' '}
              data-cy="queue-form-region"
            >
              {regions.map(region => (
                <MenuItem
                  key={region}
                  value={region}
                  data-cy={`queue-form-region-${region}`}
                >
                  {intl.formatMessage({
                    id: `components.queueDetailForm.region.${region}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              data-cy="queue-form-button-cancel"
              disabled={mutationLoading || isSubmitting}
            >
              {intl.formatMessage({
                id: 'components.queueDetailForm.cancelButton',
              })}
            </Button>
            <Button
              variant="contained"
              startIcon={
                mutationLoading || isSubmitting ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null
              }
              type="submit"
              disabled={mutationLoading || isSubmitting || isLoading}
              data-cy={
                auroraConfigEnabled
                  ? 'queue-form-next-to-engines-button'
                  : 'queue-form-button-submit'
              }
            >
              {intl.formatMessage({
                id: auroraConfigEnabled
                  ? 'components.queueDetailForm.nextButton'
                  : 'components.queueDetailForm.sendButton',
              })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export { QueueDetailForm };
