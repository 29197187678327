import { DetailDrawer, detailDrawerClasses } from '@rossum/ui';
import { ReactNode } from 'react';
import Title from './Title';

type Props = {
  children: ReactNode;
  name: string;
  id: number;
  open: boolean;
  handleClose: () => void;
};

const ConfigAppDrawer = ({ children, name, id, open, handleClose }: Props) => {
  return (
    <DetailDrawer
      open={open}
      keepMounted={false}
      onClose={handleClose}
      anchor="right"
      PaperProps={{
        sx: {
          [`> .${detailDrawerClasses.title}`]: { zIndex: 2 },
          [`> .${detailDrawerClasses.body}`]: {
            height: '100%',
            position: 'relative',
          },
        },
      }}
      title={<Title name={name} id={id} />}
    >
      {children}
    </DetailDrawer>
  );
};

export default ConfigAppDrawer;
