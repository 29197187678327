import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useApiClient } from '../../../lib/apiClient';
import { useInvokeHook } from './useInvokeHook';

export const useStartupHook = ({ enabled }: { enabled: boolean }) => {
  const api = useApiClient();
  const { mutate: invoke } = useInvokeHook();

  const query = useQuery({
    queryKey: ['startup_hook'],
    queryFn: () =>
      api.request(endpoints.hooks.list({ name: 'Start: ', active: true })),
    enabled,
  });

  useEffect(() => {
    if (query.isSuccess) {
      const first = query.data.results[0];
      if (first) {
        invoke({
          hook: first.url,
          payload: {},
        });
      }
    }
  }, [invoke, query?.data?.results, query.isSuccess]);
  return query;
};
