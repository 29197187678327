import { useDispatch } from 'react-redux';
import {
  openModal,
  OpenModalParameters,
} from '../../../../redux/modules/modal/actions';

export const useConfirmModal = () => {
  const dispatch = useDispatch();
  const openConfirmModal = (props: OpenModalParameters) =>
    dispatch(openModal(props));

  return { openConfirmModal };
};
