import { Queue } from '@rossum/api-client/queues';
import { Stack } from '@rossum/ui/material';
import { Control, useController } from 'react-hook-form';
import Threshold from '../../../../components/UI/Threshold';
import { limitPercentageDecimalPlaces } from '../../../../components/UI/Threshold/useThresholdInput';

const DEFAULT_VALUE = 0.8;

const predefinedValues = [
  { value: 0, label: '0 %' },
  { value: 0.65, label: '65 %' },
  { value: DEFAULT_VALUE, label: '80 %' },
  { value: 0.975, label: '97.5 %' },
  { value: 1, label: '100 %' },
];

// While the user didn't leave the custom input, the value could be incorrect
// so we need to do handle it here
export const clampThresholdValue = (value: number) =>
  Math.max(0, Math.min(value, 1));

export const getRatio = (value: number) =>
  limitPercentageDecimalPlaces(100 - value * 100);

const DefaultScoreThreshold = ({
  control,
  defaultValue,
}: {
  control: Control<Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>>;
  defaultValue: number;
}) => {
  const {
    field: { value: thresholdValue, onChange: thresholdOnChange },
  } = useController({ name: 'defaultScoreThreshold', control });

  return (
    <Stack gap={2}>
      <Threshold
        value={thresholdValue}
        defaultValue={defaultValue}
        predefinedValues={predefinedValues}
        onChange={value => {
          if (value !== null) {
            thresholdOnChange(value);
          }
        }}
      />
    </Stack>
  );
};

export default DefaultScoreThreshold;
