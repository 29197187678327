import { HttpError } from '@rossum/api-client/errors';
import { ErrorEvent, EventHint } from '@sentry/react';
import { AxiosError } from 'axios';
import { AjaxError } from 'rxjs/ajax';
import {
  getApiErrorRequestMethod,
  getApiErrorStatusCode,
  getApiErrorUrl,
  inferEndpointGroup,
} from '../utils';

const getTransactionName = (error: HttpError | AxiosError | AjaxError) => {
  const code = getApiErrorStatusCode(error);
  const method = getApiErrorRequestMethod(error);
  const endpointGroup = inferEndpointGroup(getApiErrorUrl(error));

  return `[${code}] ${method} ${endpointGroup}`;
};

export const withTransactionName = ([event, hint]: [ErrorEvent, EventHint]): [
  ErrorEvent,
  EventHint,
] => {
  const { originalException } = hint;

  if (
    originalException instanceof HttpError ||
    originalException instanceof AxiosError ||
    originalException instanceof AjaxError
  ) {
    return [
      { ...event, transaction: getTransactionName(originalException) },
      hint,
    ];
  }

  return [event, hint];
};
