import { Avatar, Box, Stack, Typography } from '@rossum/ui/material';

const AVATAR_SIZE = 32;

type UserAvatarProps = {
  userInitials: string;
  avatarUrl?: string;
};

export const UserAvatar = ({ avatarUrl, userInitials }: UserAvatarProps) => {
  if (avatarUrl) {
    return (
      <Box
        sx={{
          border: t => `2px solid ${t.palette.divider}`,
          borderRadius: '50%',
        }}
      >
        <Avatar
          sx={{
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
          }}
          src={avatarUrl}
        />
      </Box>
    );
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width={AVATAR_SIZE}
      height={AVATAR_SIZE}
      sx={{
        borderRadius: '50%',
        border: t => `1px solid ${t.palette.divider}`,
      }}
    >
      <Typography color="text.primary" variant="body2">
        {userInitials}
      </Typography>
    </Stack>
  );
};
