import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormValues, sidebarAdditionalInfoTypes } from './formValues';

type AdditionalInfoControlProps = {
  control: Control<FormValues>;
  options: {
    title: string;
    description: string;
    value: (typeof sidebarAdditionalInfoTypes)[number];
  }[];
};

export const AdditionalInfoControl = ({
  control,
  options,
}: AdditionalInfoControlProps) => {
  const intl = useIntl();

  const {
    field: { disabled, value, onChange },
  } = useController({ name: 'additionalInfo', control });

  const handleChange = useCallback(
    (option: (typeof sidebarAdditionalInfoTypes)[number]) => {
      const resultingValue = value?.includes(option)
        ? value.filter(v => v !== option)
        : [...(value ?? []), option];

      onChange(resultingValue);
    },
    [onChange, value]
  );

  return (
    <FormControl>
      <FormLabel>
        <Typography
          variant="h6"
          color={disabled ? 'text.disabled' : 'text.primary'}
        >
          {intl.formatMessage({
            id: 'components.lineItemsSettings.sidebarAdditionalInfo.title',
          })}
        </Typography>
      </FormLabel>
      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={value?.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
            }
            label={
              <Stack pr={2}>
                <Typography variant="body2" pt={1}>
                  {option.title}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.description}
                </Typography>
              </Stack>
            }
            sx={{ userSelect: 'none' }}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
