import { Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  children?: ReactNode;
};

const EmptyListLabels = ({ children }: Props) => {
  const intl = useIntl();
  return (
    <Stack
      sx={{ pt: 2, minHeight: 'inherit', textAlign: 'center' }}
      alignItems="center"
    >
      <Typography sx={{ fontWeight: 700 }} px={2}>
        {intl.formatMessage({
          id: 'components.labels.labelsFiltering.emptyList',
        })}
      </Typography>
      <Typography variant="body2" color="text.disabled" px={2}>
        {intl.formatMessage({
          id: 'components.labels.labelsFiltering.emptyListDescription',
        })}
      </Typography>
      {children}
    </Stack>
  );
};

export { EmptyListLabels };
