import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { useApiClient } from '../../../lib/apiClient';
import { BillingEntity, getBillingEndpoint } from './useBillingEntity';

const QUERY_KEY_BILLING_HISTORY_EXPORT = 'billing-history-export';

export const useBillingHistoryExport = (
  historyBillingEntity: BillingEntity
) => {
  const api = useApiClient();
  const { refetch, isFetching } = useQuery({
    queryKey: [QUERY_KEY_BILLING_HISTORY_EXPORT, historyBillingEntity] as const,

    queryFn: ({ queryKey: [, billingEntity] }) => {
      const { id, endpoint } = getBillingEndpoint(billingEntity);
      return api.request(endpoint.billingHistory.export(id));
    },
    enabled: false,
    onSuccess: data => {
      download(
        data,
        `${QUERY_KEY_BILLING_HISTORY_EXPORT}-${Date.now()}.csv`,
        'text/csv'
      );
    },
  });

  return { triggerExport: refetch, isExporting: isFetching };
};
