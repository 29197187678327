import { DragIndicator } from '@rossum/ui/icons';
import clsx from 'clsx';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { Dispatch, SetStateAction } from 'react';
import SortableWrapper from '../../../../../../components/Dnd/SortableWrapper';
import Input from '../../../../../../components/UI/Input';
import {
  insertInArray,
  removeIndexInArray,
  updateInArray,
} from '../../../../../../redux/modules/utils';
import { EnumOption } from '../../../../../../types/schema';
import sharedStyles from '../EnumEditor/styles.module.sass';
import styles from './styles.module.sass';

const defaultOption = { value: '', label: '' };

type EnumEditorItemProps = {
  enumId: string;
  enumIndex: number;
  enumItem: EnumOption;
  optionsAreEmpty: boolean;
  options: Array<EnumOption>;
  currentLine?: number;
  setCurrentLine: Dispatch<SetStateAction<number | undefined>>;
  onCurrentSchemaPartChange: (_options: Array<EnumOption>) => void;
};

const EnumEditorItem = ({
  enumId,
  enumIndex,
  enumItem,
  currentLine,
  setCurrentLine,
  optionsAreEmpty,
  options,
  onCurrentSchemaPartChange,
}: EnumEditorItemProps) => {
  const onChange =
    (index: number, type: 'value' | 'label') => (value: string) =>
      onCurrentSchemaPartChange(
        updateInArray(options, index, _option => ({
          ..._option,
          [type]: value,
        }))
      );

  const onTmpChange = (type: 'value' | 'label') => (value: string) => {
    const newOption = { ...defaultOption, [type]: value };
    onCurrentSchemaPartChange(insertInArray(options, 0, newOption));
  };

  const onRemove = (index: number) => () =>
    onCurrentSchemaPartChange(removeIndexInArray(options, index));

  return (
    <SortableWrapper
      id={enumId}
      render={sortableProps => (
        <div className={clsx(styles.OptionInputs, sharedStyles.RemoveEnable)}>
          <div className={styles.DragHandle}>
            <DragIndicator
              fontSize="inherit"
              {...sortableProps?.listeners}
              {...sortableProps?.attributes}
              sx={{
                cursor: 'grab',
                ':focus': {
                  outline: 'none',
                  cursor: 'grabbing',
                },
              }}
            />
          </div>
          <Input
            autoFocus={enumIndex === currentLine}
            className={clsx(styles.Input, styles.IdInput)}
            onChange={
              optionsAreEmpty
                ? onTmpChange('value')
                : onChange(enumIndex, 'value')
            }
            onFocus={() => setCurrentLine(enumIndex)}
            placeholder="components.enumEditor.id.placeholder"
            value={enumItem.value}
          />
          <Input
            className={clsx(styles.Input, styles.LabelInput)}
            onChange={
              optionsAreEmpty
                ? onTmpChange('label')
                : onChange(enumIndex, 'label')
            }
            onFocus={() => setCurrentLine(enumIndex)}
            placeholder="components.enumEditor.label.placeholder"
            value={enumItem.label}
          />
          <div
            className={sharedStyles.OptionDeleteIcon}
            onClick={onRemove(enumIndex)}
          >
            <DeleteIcon size={14} />
          </div>
        </div>
      )}
    />
  );
};

export default EnumEditorItem;
