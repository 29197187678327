import { Button, CircularProgress, Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AuroraPromo } from '../../features/annotation-view/complex-line-items-tour/AuroraPromo';
import { AuroraTour } from '../../features/annotation-view/complex-line-items-tour/AuroraTour';
import { isVirtualDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { MultivalueDatapointDataST } from '../../types/datapoints';
import { AuroraAcceptIcon } from '../../ui/aurora/Icons';
import { useAcceptSuggestions } from '../DocumentPage/useAcceptSuggestions';
import { ContextMenu } from './components/ContextMenu';
import { LineItemSettingsDialog } from './components/LineItemSettingsDialog';
import SuggestButton from './components/SuggestButton/SuggestButton';

const LineItemsControls = ({
  tupleIds,
  currentDatapoint,
}: {
  tupleIds: Array<number>;
  currentDatapoint: MultivalueDatapointDataST;
}) => {
  const intl = useIntl();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const amount = tupleIds.filter(id => !isVirtualDatapoint(id))?.length;
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const { acceptSuggestions, canAcceptSuggestions, loading } =
    useAcceptSuggestions({ multivalueId: currentDatapoint.id });

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        position="relative"
        justifyContent="flex-end"
      >
        {/* TODO: Delete from here when deleting old sidebar */}
        <LineItemSettingsDialog
          key={`${complexLineItemsEnabled}`}
          open={settingsOpen}
          onClose={() => setSettingsOpen(false)}
        />
        <ContextMenu
          amount={amount}
          currentMultivalue={currentDatapoint}
          openSettings={() => setSettingsOpen(true)}
        />
        {complexLineItemsEnabled && (
          <SuggestButton
            disabled={amount < 1}
            currentDatapoint={currentDatapoint}
          />
        )}
        {complexLineItemsEnabled && (
          <Button
            startIcon={
              loading ? (
                <CircularProgress
                  sx={{ width: '20px' }}
                  size="20"
                  color="inherit"
                />
              ) : (
                <AuroraAcceptIcon />
              )
            }
            variant="contained"
            color="primary"
            disabled={!canAcceptSuggestions}
            onClick={acceptSuggestions}
            data-cy="line-items-accept-suggestions"
          >
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.lineItems.acceptSuggestions',
            })}
          </Button>
        )}
      </Stack>
      {complexLineItemsEnabled ? <AuroraTour /> : <AuroraPromo />}
    </>
  );
};

export default LineItemsControls;
