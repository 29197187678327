// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { functionConfigSchema, webhookConfigSchema } from './hookConfig.schema';
import {
  extensionEventSchema,
  extensionSourceSchema,
  extensionStatusSchema,
} from './hookUtils.schema';

export const hookBaseSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: urlSchema,
  queues: z.array(urlSchema),
  runAfter: z.array(urlSchema),
  active: z.boolean(),
  events: z.array(extensionEventSchema),
  sideload: z.array(z.string()),
  metadata: z.unknown().optional(),
  tokenOwner: urlSchema.nullable(),
  test: z.unknown().optional(),
  description: z.string().nullable(),
  extensionSource: extensionSourceSchema,
  settings: z.unknown().optional(),
  guide: z.string().nullable(),
  readMoreUrl: urlSchema.nullable(),
  extensionImageUrl: urlSchema.nullable(),
  settingsSchema: z.record(z.unknown()).nullable(),
  secretsSchema: z.record(z.unknown()).nullable(),
  secrets: z.record(z.unknown()).optional().nullable(),
});

export const hookTypeSchema = z.union([
  z.literal('webhook'),
  z.literal('function'),
]);

export const webhookHookSchema = hookBaseSchema.and(
  z.object({
    type: z.literal('webhook'),
    config: webhookConfigSchema,
  })
);

export const functionHookSchema = hookBaseSchema.and(
  z.object({
    type: z.literal('function'),
    config: functionConfigSchema,
    status: extensionStatusSchema,
  })
);

export const hookSchema = z.union([webhookHookSchema, functionHookSchema]);
