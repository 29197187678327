import { WithEtag } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { Stack } from '@rossum/ui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import type { match } from 'react-router';
import { MUTATION_KEY_PATCH_SCHEMA } from '../../../../business/schema/usePatchSchema';
import { Header, HeaderProps } from '../../../../ui/header/Header';
import { QueueSettingsBreadcrumbs } from '../../components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../../components/QueueSettingsTabs';
import { useFieldsHeaderMeta } from '../../hooks/useFieldsHeaderMeta';
import { usePageKey } from '../../hooks/usePageKey';
import { QueueSettingsRouteParams } from '../types';
import { UpdateIndicator } from './UpdateIndicator';

type FieldsHeaderProps = {
  schema: WithEtag<Schema> | undefined;
  schemaIsLoading: boolean;
  queueName: string;
  match: match<QueueSettingsRouteParams>;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const FieldsHeader = ({
  match,
  schema,
  schemaIsLoading,
  queueName,
  ...params
}: FieldsHeaderProps) => {
  const intl = useIntl();

  const { subPageKeys } = usePageKey();

  const { breadcrumbs, title, description, buttons, onBackButtonClicked } =
    useFieldsHeaderMeta(
      `${match?.url ?? ''}/fields`,
      subPageKeys,
      schema,
      schemaIsLoading
    );

  const schemaIsPatching = !!useIsMutating({
    mutationKey: MUTATION_KEY_PATCH_SCHEMA,
  });

  return (
    <Stack position="relative">
      <Header
        {...params}
        tabs={
          subPageKeys.length === 0 ? (
            <QueueSettingsTabs value="fields" parentPath={match.url} />
          ) : null
        }
        onBackButtonClicked={onBackButtonClicked}
        breadcrumbs={
          <QueueSettingsBreadcrumbs
            breadcrumbs={[
              {
                label: intl.formatMessage({
                  id: 'features.queueSettings.fields.title',
                }),
                to: `${match.url}/fields`,
              },
              ...breadcrumbs,
            ]}
            queueName={queueName}
            settingsPath={match.url}
          />
        }
        buttons={buttons}
        title={title}
        description={description}
      />
      {schemaIsPatching ? <UpdateIndicator sx={{ bottom: 0 }} /> : null}
    </Stack>
  );
};
