import {
  alpha,
  Button,
  ButtonProps,
  SxProps,
  Theme,
  useTheme,
} from '@rossum/ui/material';
import { ReactNode } from 'react';

type CliButtonProps = {
  children: ReactNode;
  isVirtual?: boolean;
  isVertical?: boolean;
  isActive?: boolean;
  height?: number;
} & ButtonProps;

const activeLabelSxProps = (theme: Theme) =>
  ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText, // contrast of primary color is different from calculated contrast color in Button component, it should be same,TODO: unify that!
    borderColor: 'transparent',
  }) as const;

const virtualLabelSxProps = (theme: Theme) =>
  ({
    backgroundColor: 'white',
    borderColor: theme.palette.aurora.main,
    color: theme.palette.aurora.main,
    zIndex: 100000,
    borderWidth: 1,
    borderStyle: 'dashed',
    strokeDasharray: '4 4',
  }) as const;

const verticalLabelSxProps: SxProps<Theme> = {
  transform: 'rotate(-90deg)',
  transformOrigin: 'top left',
};

const horizontalLabelSxProps: SxProps<Theme> = {
  transform: 'translate(-100%, 0)',
};

export const CliButton = ({
  children,
  isVirtual,
  isVertical,
  isActive,
  height,
  ...buttonProps
}: CliButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      sx={{
        height,
        position: 'absolute',
        minWidth: 0,
        boxShadow: 'none',
        pointerEvents: 'all',
        whiteSpace: 'nowrap',
        // backgroundColor: theme => alpha(theme.palette.background.paper, 0.7),
        backgroundColor: t => alpha(t.palette.common.black, 0.7),
        color: t => t.palette.common.white,
        ...(isVirtual ? virtualLabelSxProps(theme) : {}),
        ...(isVertical ? verticalLabelSxProps : horizontalLabelSxProps),
        ...(isActive ? activeLabelSxProps(theme) : {}),
        '&:hover': activeLabelSxProps(theme),
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
