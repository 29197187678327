import { IconRefresh } from '@rossum/ui/icons/tabler';
import { IconButton, SvgIcon } from '@rossum/ui/material';
import { ActionKeys, ActionProps } from './types';

// as per definition in Helene, actions should be icon buttons whenever possible
// https://www.figma.com/design/AaBwZVqcfaoe476bCiLqmJ?node-id=10522-671#1048969375
export const actionMap: Record<
  ActionKeys,
  (props: ActionProps) => JSX.Element
> = {
  // `intl` might be useful later if we add `aria` or something like that
  refresh: ({ onClick }) => (
    <IconButton size="small" onClick={onClick}>
      <SvgIcon fontSize="inherit">
        <IconRefresh />
      </SvgIcon>
    </IconButton>
  ),
};
