import { IconAlertOctagon } from '@rossum/ui/icons/tabler';
import {
  Button,
  Grow,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
} from '@rossum/ui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Annotation } from '../../../../../types/annotation';
import { useConfirmButton } from './useConfirmButton';

type ConfirmButtonProps = {
  annotation: Annotation;
  onNavigate: (id: number | 'all') => void;
};

export const ConfirmButton = ({
  annotation,
  onNavigate,
}: ConfirmButtonProps) => {
  const {
    disabled: confirmButtonDisabled,
    focused: confirmButtonFocused,
    startIcon: confirmButtonStartIcon,
    label: confirmButtonLabel,
    onClick: handleConfirmClick,
    onKeyDown: handleConfirmKeyDown,
    confirmBlockingMessage,
  } = useConfirmButton(annotation);

  const intl = useIntl();

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const confirmButton = confirmButtonRef.current;
    if (confirmButton) {
      if (confirmButtonFocused) {
        confirmButton.focus();
      } else {
        confirmButton.blur();
      }
    }
  }, [confirmButtonFocused]);

  const handleNavigate = useCallback(() => {
    if (confirmBlockingMessage && confirmBlockingMessage.id) {
      if (typeof confirmBlockingMessage.id === 'number') {
        onNavigate(confirmBlockingMessage.id);
      } else if (confirmBlockingMessage.id === 'all') {
        onNavigate('all');
      } else {
        onNavigate(parseInt(confirmBlockingMessage.id, 10));
      }
    }
  }, [confirmBlockingMessage, onNavigate]);

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Button
        ref={confirmButtonRef}
        variant="contained"
        color="primary"
        sx={{ flexGrow: 1 }}
        disabled={confirmButtonDisabled}
        startIcon={confirmButtonStartIcon}
        onClick={handleConfirmClick}
        onKeyDownCapture={handleConfirmKeyDown}
        data-cy="confirm-annotation-btn"
      >
        {confirmButtonLabel}
      </Button>
      <Grow in={!!confirmBlockingMessage} timeout={300} unmountOnExit>
        <Tooltip
          title={intl.formatMessage({
            id: 'components.sidebarV2.footer.navigateToNextError',
          })}
        >
          <IconButton size="small" color="error" onClick={handleNavigate}>
            <SvgIcon fontSize="small">
              <IconAlertOctagon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Grow>
    </Stack>
  );
};
