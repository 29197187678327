import { StoreMallDirectoryRounded } from '@rossum/ui/icons';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import AdBanner from '../../../components/UI/AdBanner';
import { extensionsStorePath } from '../helpers';

type Props = {
  withBackground?: boolean;
};

const StoreAdBanner = ({ withBackground }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <AdBanner
      withBackground={withBackground}
      buttonText={intl.formatMessage({
        id: 'containers.settings.extensions.adBanner.button',
      })}
      icon={StoreMallDirectoryRounded}
      title={intl.formatMessage({
        id: 'containers.settings.extensions.adBanner.title',
      })}
      subtitle={intl.formatMessage({
        id: 'containers.settings.extensions.adBanner.subtitle',
      })}
      onButtonClick={() => history.push(extensionsStorePath())}
      dataCy="extensions-ad-banner"
    />
  );
};

export default StoreAdBanner;
