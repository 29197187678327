import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import { EngineField } from '@rossum/api-client/engineFields';
import { Engine } from '@rossum/api-client/engines';
import { HttpError } from '@rossum/api-client/errors';
import { Stack } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { mapValues } from 'remeda';
import { z } from 'zod';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import {
  restrictedEngineCreationEnabledSelector,
  restrictedEngineEditEnabledSelector,
} from '../../../redux/modules/organizationGroup/selectors';
import { EngineErrorAlert } from '../components/EngineErrorAlert';
import { FieldForm } from '../components/FieldForm';
import { FormSkeleton } from '../components/skeletons/FormSkeleton';
import { FIELD_FORM_ID } from '../constants';
import { FieldAddHeader } from '../headers/FieldAddHeader';
import { FieldDetailHeader } from '../headers/FieldDetailHeader';
import { useCreateEngineField } from '../hooks/useCreateEngineField';
import { useEngine } from '../hooks/useEngine';
import { useEngineField } from '../hooks/useEngineField';
import { usePatchEngineField } from '../hooks/usePatchEngineField';
import { subtypes, types } from '../hooks/useTypesAndSubtypes';
import { engineFieldDetailPath } from '../paths';

type FieldFormType = z.TypeOf<ReturnType<typeof fieldFormSchema>>;

export const useFieldForm = ({
  error,
  defaultValues,
}: {
  error: unknown;
  defaultValues: FieldFormType['field'];
}) => {
  const backendErrors = mapValues(resolveErrorPayload(error), apiError =>
    apiError ? { type: 'api_error', message: apiError[0] } : undefined
  );

  const intl = useIntl();
  return useForm<FieldFormType>({
    errors: {
      // TODO: Translate some common errors / add some F/E validation.
      field: backendErrors,
    },
    defaultValues: {
      field: defaultValues,
    },
    resolver: zodResolver(fieldFormSchema(intl)),
  });
};

const resolveErrorPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const errorSchema = z
      .object({
        label: z.array(z.string()),
        name: z.array(z.string()),
        preTrainedFieldId: z.array(z.string()),
        type: z.array(z.string()),
        subtype: z.array(z.string()),
        tabular: z.array(z.string()),
      })
      .partial();

    return errorSchema.catch({}).parse(error.data);
  }

  return {};
};

export const fieldFormSchema = (intl: IntlShape) =>
  z.object({
    field: z.object({
      label: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      name: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      preTrainedFieldId: z.string().nullable(),
      type: z.enum(types),
      subtype: z.enum(subtypes).nullable(),
      tabular: z.boolean(),
      multiline: z.string(),
    }),
  });

type FieldAddContentProps = {
  engine: Engine;
};

const FieldAddContent = ({ engine }: FieldAddContentProps) => {
  const {
    mutate,
    error,
    isLoading: fieldIsCreating,
  } = useCreateEngineField(engine.url);

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineCreationEnabledSelector
  );

  const history = useHistory();
  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      label: '',
      name: '',
      preTrainedFieldId: null,
      type: 'string',
      subtype: null,
      tabular: false,
      multiline: 'false',
    },
  });

  return (
    <PageLayoutV2
      renderHeader={params => (
        <FieldAddHeader
          {...params}
          engine={engine}
          isSaving={fieldIsCreating}
        />
      )}
    >
      <Stack
        px={4}
        py={4}
        component="form"
        id={FIELD_FORM_ID}
        onSubmit={handleSubmit(({ field }) => {
          mutate(field, {
            onSuccess: updatedField =>
              history.push(
                engineFieldDetailPath(
                  getIDFromUrl(updatedField.engine),
                  updatedField.id
                )
              ),
          });
        })}
      >
        <FieldForm control={control} disabled={isRestrictedEngineEditEnabled} />
      </Stack>
    </PageLayoutV2>
  );
};
export const FieldAddPage = () => {
  const { engineId } = useParams<{ engineId: string }>();
  const engineIdNumber = parseInt(engineId, 10);
  const { data: engine } = useEngine(engineIdNumber);

  if (!engine) {
    return null;
  }

  return <FieldAddContent engine={engine} />;
};

type FieldDetailContentProps = {
  field: EngineField;
};

const FieldDetailContent = ({ field }: FieldDetailContentProps) => {
  const {
    mutate,
    error,
    isLoading: fieldIsPatching,
  } = usePatchEngineField(field.id);

  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      label: field.label,
      type: field.type,
      name: field.name,
      preTrainedFieldId: field.preTrainedFieldId,
      tabular: field.tabular,
      multiline: field.multiline,
      subtype: field.subtype,
    },
  });

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  return (
    <PageLayoutV2
      renderHeader={params => (
        <FieldDetailHeader
          {...params}
          field={field}
          isSaving={fieldIsPatching}
        />
      )}
    >
      <Stack
        px={4}
        py={4}
        component="form"
        id={FIELD_FORM_ID}
        onSubmit={handleSubmit(formValues => {
          mutate(formValues.field);
        })}
      >
        <FieldForm
          control={control}
          disableName
          disabled={isRestrictedEngineEditEnabled}
        />
      </Stack>
    </PageLayoutV2>
  );
};

export const FieldDetailPage = () => {
  const { engineId, fieldId } = useParams<{
    engineId: string;
    fieldId: string;
  }>();
  const engineIdNumber = parseInt(engineId, 10);
  const fieldIdNumber = parseInt(fieldId, 10);

  const {
    data: field,
    isLoading: fieldIsLoading,
    isError,
  } = useEngineField(fieldIdNumber);

  if (isError) {
    return (
      <PageLayoutV2>
        <EngineErrorAlert engineId={engineIdNumber} />
      </PageLayoutV2>
    );
  }

  if (fieldIsLoading) {
    return (
      <PageLayoutV2>
        <FormSkeleton />
      </PageLayoutV2>
    );
  }

  if (!field) {
    return null;
  }

  return <FieldDetailContent field={field} />;
};
