import { Button, Chip, Dialog, Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { settingsPath } from '../../../containers/Settings/helpers';
import { SettingsBreadcrumbs } from '../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps, HeaderTitle } from '../../../ui/header/Header';
import { CreateLabelDialog } from './LabelDialogs';

type LabelsHeaderProps = { labelsCount: number } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const LabelsHeader = ({ labelsCount, ...rest }: LabelsHeaderProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const intl = useIntl();
  const history = useHistory();

  return (
    <>
      <Header
        {...rest}
        breadcrumbs={
          <SettingsBreadcrumbs
            breadcrumbs={[
              {
                label: intl.formatMessage({
                  id: 'components.appBar.menu.settings.labels',
                }),
              },
            ]}
          />
        }
        title={hasScrolled => (
          <Stack direction="row" alignItems="center" gap={1}>
            <HeaderTitle
              hasScrolled={hasScrolled}
              title={intl.formatMessage({
                id: 'containers.settings.labels.header.title',
              })}
            />
            <Chip label={labelsCount} />
          </Stack>
        )}
        description={intl.formatMessage({
          id: 'containers.settings.labels.description',
        })}
        buttons={[
          <Button
            key="add"
            variant="contained"
            color="primary"
            sx={{ ml: 'auto' }}
            onClick={() => setShowDialog(true)}
          >
            {intl.formatMessage({
              id: 'containers.settings.labels.actions.createNew',
            })}
          </Button>,
        ]}
        onBackButtonClicked={() => history.push(settingsPath())}
      />
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <CreateLabelDialog onCancel={() => setShowDialog(false)} />
      </Dialog>
    </>
  );
};
