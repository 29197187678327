import { Delete } from '@rossum/ui/icons';
import { Box, Button, Stack, TableCell, useTheme } from '@rossum/ui/material';
import { isEqual } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { range } from 'remeda';
import DatapointMessage from '../../components/Datapoint/components/DatapointMessage';
import { FakeCheckbox } from '../../components/UI/FakeCheckbox';
import { findDatapointById } from '../../redux/modules/datapoints/navigation/findDatapointIndex';
import { isVirtualDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { footerLayoutSelector } from '../../redux/modules/user/selectors';
import { AnyDatapointSchema } from '../../types/schema';
import { State } from '../../types/state';
import { FooterDatapointTableCell } from './FooterDatapointTableCell';
import { BatchUpdateEvent } from './useBatchUpdate';
import { SelectionAction } from './useTupleActions';
import {
  commonCellStyle,
  commonCheckboxCellStyle,
  composeSx,
  DEFAULT_STICKY_HEADER_Z_INDEX,
  hasVisibleStretchedColumn,
  isColumnHidden,
} from './utils';

export type FooterTupleRowProps = {
  tupleId: number;
  rowIndicator: string;
  onSelect: (selection: SelectionAction) => void;
  onDelete: (tupleId: number) => void;
  allColumns: AnyDatapointSchema[];
  orderedIndexes: number[];
  checked: boolean;
  onBatchUpdateEvent: (event: BatchUpdateEvent) => void;
  batchUpdatedColumnSchemaId: string | null;
  footerExpanded: boolean;
  readOnly: boolean;
  isGhostRow: boolean;
};

const FooterTupleRow = React.memo(
  ({
    tupleId,
    onSelect,
    onDelete,
    allColumns,
    orderedIndexes,
    checked,
    onBatchUpdateEvent,
    batchUpdatedColumnSchemaId,
    footerExpanded,
    readOnly,
    isGhostRow,
    rowIndicator,
  }: FooterTupleRowProps) => {
    const theme = useTheme();

    const layout = useSelector(footerLayoutSelector);

    const message = useSelector(
      (state: State) => state.datapoints.messages[tupleId]
    );

    const complexLineItemsEnabled = useSelector(
      complexLineItemsEnabledSelector
    );

    const isOneTable = layout === 'one-table';

    const childrenIds = useSelector((state: State) => {
      const tuple = findDatapointById(state.datapoints.content, tupleId);

      if (!tuple || tuple.category !== 'tuple') {
        return [];
      }

      return tuple.children.map(c => c.id);
    }, isEqual);

    const tableCellSx = composeSx(commonCellStyle, commonCheckboxCellStyle, {
      zIndex: DEFAULT_STICKY_HEADER_Z_INDEX + 1,
    });

    return (
      <>
        <TableCell sx={tableCellSx}>
          <Stack direction="row">
            <DatapointMessage message={message} renderEmptyPlaceholder>
              {({ messageIcon }) => <>{messageIcon}</>}
            </DatapointMessage>
            <FakeCheckbox
              size="small"
              checked={checked}
              disabled={isGhostRow}
              onChange={e =>
                e.shiftKey
                  ? onSelect({ type: 'select-range', tupleId })
                  : onSelect({ type: 'toggle', tupleId })
              }
              style={{
                visibility: readOnly ? 'hidden' : 'visible',
                cursor: isGhostRow ? 'not-allowed' : undefined,
              }}
            />
          </Stack>
        </TableCell>
        {complexLineItemsEnabled && (
          <TableCell
            sx={{
              ...commonCellStyle,
              py: 0,
              pl: 0,
              pr: 0,
            }}
          >
            <Box
              sx={
                isVirtualDatapoint(tupleId) && !isGhostRow
                  ? {
                      borderRadius: '2px',
                      borderColor: theme.palette.aurora.main,
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      lineHeight: '27px',
                      color: theme => theme.palette.text.secondary,
                    }
                  : {
                      textAlign: 'left',
                      lineHeight: '27px',
                      width: isOneTable ? '60px' : '30px',
                    }
              }
            >
              {rowIndicator}
            </Box>
          </TableCell>
        )}
        {range(0, allColumns.length).map(i => {
          const id: number | undefined = childrenIds[orderedIndexes[i]];
          const column: AnyDatapointSchema | undefined =
            allColumns[orderedIndexes[i]];

          // we cannot _really_ guarantee that schema columns and childrenIds fit
          // that would need a lot more refactoring
          // these checks fix the problem tho
          return !id ||
            !column ||
            isColumnHidden(column, footerExpanded) ? null : (
            <FooterDatapointTableCell
              id={id}
              tupleId={tupleId}
              key={id}
              column={column}
              onBatchUpdateEvent={onBatchUpdateEvent}
              isIncludedInBatchUpdate={batchUpdatedColumnSchemaId === column.id}
              readOnly={readOnly}
              isGhostRow={isGhostRow}
            />
          );
        })}
        {/* Fill remaining width, so that row separators are drawn across the whole footer, if the table is too narrow */}
        {hasVisibleStretchedColumn(allColumns) ? null : (
          <TableCell sx={{ width: '100%', ...commonCellStyle }} />
        )}
        <TableCell
          sx={{
            padding: '0 0 0 6px',
            ...commonCellStyle,
          }}
          data-last-cell
        >
          {!readOnly && !isGhostRow && (
            <Button
              variant="contained"
              color="error"
              sx={{
                minWidth: 'unset',
                mb: '1px',
                p: '0 4px',
                height: '22px',
                fontSize: '1.6rem',
                opacity: 0,
                visibility: 'none',
                transition: theme =>
                  theme.transitions.create('opacity', {
                    duration: 150,
                  }),
                color: theme => theme.palette.common.white,
              }}
              onClick={() => onDelete(tupleId)}
              data-cy="remove-line"
            >
              <Delete fontSize="inherit" />
            </Button>
          )}
        </TableCell>
      </>
    );
  },
  isEqual
);

FooterTupleRow.displayName = 'FooterTupleRow';

export { FooterTupleRow };
