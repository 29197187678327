import { Button, Divider, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  isSubmitDisabled: boolean;
  onCancel: () => void;
  secondaryButton?: ReactNode;
  dataCyKey: string;
};

const ActionButtons = ({
  isSubmitDisabled,
  onCancel,
  secondaryButton,
  dataCyKey,
}: Props) => {
  const intl = useIntl();

  return (
    <Stack width="100%" gap={2}>
      <Divider />
      <Stack flexDirection="row" justifyContent="space-between" width="100%">
        {secondaryButton && secondaryButton}
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
          gap={2}
        >
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCancel}
            data-cy={`all-documents-filter-menu-${dataCyKey}-cancel-button`}
          >
            {intl.formatMessage({ id: 'containers.filtering.buttons.cancel' })}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitDisabled}
            data-cy={`all-documents-filter-menu-${dataCyKey}-apply-button`}
          >
            {intl.formatMessage({ id: 'containers.filtering.buttons.apply' })}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { ActionButtons };
