import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { partition } from 'remeda';
import { StatusTabArray, StatusTabKeys } from '../tabs';
import { makeSureActiveTabIsVisible, sortStatusTabs } from '../utils';

const getVisibleTabsCount = (
  widths: number[],
  wrapperWidth: number,
  minCount: number
) => {
  const tabsCount = widths.reduce<[number, number]>(
    (prev, current, index) => {
      if (prev[0] + current > wrapperWidth) {
        return [prev[0] + current, prev[1]];
      }

      return [prev[0] + current, index + 1];
    },
    [0, 0]
  )[1];

  return Math.max(tabsCount, minCount);
};

const LOOK_AHEAD = 40;
const MINIMUM_VISIBLE_TABS = 2;

export const useTabVisibility = ({
  activeTabKey,
  allStatusTabs,
}: {
  allStatusTabs: StatusTabArray;
  activeTabKey: StatusTabKeys;
}) => {
  const [readyToDisplay, setReadyToDisplay] = useState<boolean>(false);

  // moreButtonWidth is a fixed value that is measured at the initial render of moreButton and it does not change
  // we use it to compute the time set readyToDisplay true. We are not able to use moreButtonRef for this because more button unmounts when fully expanded and its width becomes 0,
  const moreButtonWidth = useRef<number>(0);
  const moreButtonRef = useRef<HTMLButtonElement | null>(null);

  const { width: containerRefWidth, ref: tabsContainerRef } =
    useResizeDetector<HTMLDivElement>();

  const [tabItemWidths, setTabItemWidths] = useState(
    Object.fromEntries(allStatusTabs.map(tab => [tab.key, 0]))
  );

  const visibleTabsCount = useMemo(() => {
    const moreTabWidth = moreButtonRef.current?.clientWidth ?? 0;
    const containerWidth = containerRefWidth ?? 0;

    const tabsCount = getVisibleTabsCount(
      allStatusTabs.map(({ key }) => tabItemWidths[key] ?? 0),
      containerWidth - (moreTabWidth + LOOK_AHEAD),
      MINIMUM_VISIBLE_TABS
    );

    return tabsCount;
  }, [tabItemWidths, containerRefWidth, allStatusTabs]);

  const { visibleTabs, hiddenTabs } = useMemo(() => {
    const [visibleTabs, hiddenTabs] = partition(
      sortStatusTabs(allStatusTabs).map((tab, i) => ({
        ...tab,
        visible: i < visibleTabsCount,
      })),
      tab => tab.visible
    );
    if (hiddenTabs.some(tab => tab.key === activeTabKey)) {
      return makeSureActiveTabIsVisible({
        visibleTabs,
        hiddenTabs,
        activeTabKey,
      });
    }

    return { hiddenTabs, visibleTabs };
  }, [allStatusTabs, visibleTabsCount, activeTabKey]);

  // // display status tabs only when;
  // // 1. we have a defined value of containerRefWidth
  // // 2. we have all the widths of all status tabs (requires status tab to be fully expanded at the initial render)
  // // 3. we know visibleTabs + moreButtonWidth is smaller the container width
  // if (!readyToDisplay && containerRefWidth) {
  //   const allWidthsAreMeasured = Object.values(tabItemWidths).every(
  //     width => !!width
  //   );
  //   const visibleWidth = visibleTabs.reduce((acc, tab) => {
  //     const tabWidth = tabItemWidths[tab.key] ?? 0;
  //     return acc + tabWidth;
  //   }, 0);
  //   if (
  //     allWidthsAreMeasured &&
  //     visibleWidth + moreButtonWidth.current < containerRefWidth
  //   )
  //     setReadyToDisplay(true);
  // }

  const setContainerSizeRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        // eslint-disable-next-line react-compiler/react-compiler
        tabsContainerRef.current = node;
      } else if (tabsContainerRef.current) {
        tabsContainerRef.current = null;
      }
    },
    [tabsContainerRef]
  );

  const setTabSizes = useCallback(
    (node: HTMLDivElement | null, tabKey: StatusTabKeys) => {
      const existingWidth = tabItemWidths[tabKey];
      if (node !== null && existingWidth !== node.clientWidth) {
        setTabItemWidths(prev => ({ ...prev, [tabKey]: node.clientWidth }));
      }
    },
    [tabItemWidths]
  );

  const setMoreButtonSize = useCallback((node: HTMLButtonElement | null) => {
    moreButtonRef.current = node;
    if (moreButtonWidth.current === 0 && node)
      moreButtonWidth.current = node.clientWidth;
  }, []);

  useEffect(() => {
    if (readyToDisplay) return () => {};

    const timeout = setTimeout(() => {
      setReadyToDisplay(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, [readyToDisplay]);

  return {
    setContainerSizeRef,
    setTabSizes,
    visibleTabs,
    hiddenTabs,
    setMoreButtonSize,
    readyToDisplay,
  };
};
