import { Message } from '@rossum/api-client/shared';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
  TupleDatapointDataST,
} from '../../../../types/datapoints';
import { findDatapointIndex } from './findDatapointIndex';
import { getSelectedDatapoint, NavigationStopNotNone } from './navigationStop';

export const getTotalChildrenCount = (
  allDatapoints: Array<AnyDatapointDataST>,
  datapoint:
    | MultivalueDatapointDataST
    | SimpleDatapointDataST
    | TupleDatapointDataST
) => {
  if (!('children' in datapoint)) return 0;

  if (datapoint.children.length === 0) return 0;
  if (datapoint.meta.isSimpleMultivalue || datapoint.category === 'tuple')
    return datapoint.children.length;

  // WORKAROUND: Some unit tests are failing here, because they use children array as array of numbers, which is not correct
  const tupleId =
    typeof datapoint.children[0] === 'number'
      ? datapoint.children[0]
      : datapoint.children[0].id;

  const tupleColumns = (
    allDatapoints[
      findDatapointIndex(allDatapoints, tupleId)
    ] as TupleDatapointDataST
  ).children.length;

  return datapoint.children.length * (1 + tupleColumns);
};

export const isUnvalidated = (
  stop: NavigationStopNotNone,
  messages: Record<number, Message>
) => {
  // AFI: This is following the existing behavior, when the focus stops on Add value buttons
  // when using Enter. But I'm not sure if it makes sense to keep it that way
  if (
    stop.kind === 'simple-multivalue-add-value' ||
    stop.kind === 'table-multivalue-add-value'
  ) {
    return true;
  }

  const datapoint = getSelectedDatapoint(stop);

  return (
    (datapoint &&
      'validationSources' in datapoint &&
      datapoint.validationSources?.length === 0) ||
    messages[datapoint.id]?.type === 'error'
  );
};
