import { Stack, StackProps } from '@rossum/ui/material';

type VerticalContainerProps = StackProps & {
  dense?: boolean;
};

export const VerticalContainer = ({
  dense = false,
  ...restStackProps
}: VerticalContainerProps) => {
  const { sx, ...rest } = restStackProps;
  return (
    <Stack
      sx={{
        py: dense ? 0 : 1,
        ...(sx ?? {}),
      }}
      {...rest}
    />
  );
};
