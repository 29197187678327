import { Stack, Typography } from '@rossum/ui/material';

type TilesListEmptyStateProps = {
  title: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};

const TilesListEmptyState = ({
  title,
  subtitle,
  children,
}: TilesListEmptyStateProps) => {
  return (
    <Stack alignItems="center" spacing={2} py={4}>
      <Typography variant="h5" color="text.secondary">
        {title}
      </Typography>
      {subtitle ? (
        <Typography component="div" color="text.secondary" textAlign="center">
          {subtitle}
        </Typography>
      ) : null}
      {children}
    </Stack>
  );
};

export { TilesListEmptyState };
