import { Tooltip, Typography, TypographyProps } from '@rossum/ui/material';
import { useFormattedDate } from '../../../../../utils/hooks/useFormattedDate';

type DateDetailProps = Omit<TypographyProps, 'children'> & {
  dateString?: string | null | undefined;
};

export const DateDetail = ({
  dateString,
  ...typographyProps
}: DateDetailProps) => {
  const { formattedDate, formattedDateTime } = useFormattedDate(
    typeof dateString === 'string' ? new Date(dateString) : undefined
  );

  const { sx, ...restTypographyProps } = typographyProps;
  return (
    <Typography
      variant="body2"
      color="text.primary"
      sx={{ cursor: 'default', ...sx }}
      {...restTypographyProps}
    >
      <Tooltip title={formattedDateTime}>
        <span>{formattedDate}</span>
      </Tooltip>
    </Typography>
  );
};
