import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { isEmbedded } from '../../../../constants/config';
import { api } from '../../../../lib/apiClient';
import { linebreak, routerLink } from '../../../../lib/formaterValues';
import { notify } from '../../../toaster';
import { getTranslationError } from '../errorHandlers';

type UseTranslateDocumentProps = {
  annotationId?: number;
  targetLanguage?: string;
  pageNumber: number;
};

const TEN_MINUTES = 1000 * 60 * 10;
const GET_TRANSLATIONS_QUERY_KEY = 'get-translations';

export const useTranslateDocument = ({
  annotationId,
  targetLanguage,
  pageNumber,
}: UseTranslateDocumentProps) => {
  const intl = useIntl();
  return useQuery({
    queryKey: [
      GET_TRANSLATIONS_QUERY_KEY,
      targetLanguage,
      annotationId,
      pageNumber,
    ] as const,
    queryFn: async () => {
      if (!annotationId)
        throw new Error('Annotation id is needed to translate.');
      if (!targetLanguage)
        throw new Error(
          'Target language must be defined to translate the document'
        );

      return api
        .request(
          endpoints.annotations.translate(annotationId, {
            granularity: 'lines',
            pageNumbers: [pageNumber],
            targetLanguage,
          })
        )
        .catch(error => {
          if (error instanceof HttpError) {
            const { errorKey } = getTranslationError(error.data);
            const description =
              targetLanguage && errorKey === 'invalidTargetLanguage'
                ? isEmbedded()
                  ? intl.formatMessage(
                      {
                        id: 'components.documentValidation.translation.errors.invalidTargetLanguage.embeddedDescription',
                      },
                      { targetLanguage }
                    )
                  : intl.formatMessage(
                      {
                        id: 'components.documentValidation.translation.errors.invalidTargetLanguage.description',
                      },
                      {
                        targetLanguage,
                        link: routerLink({
                          route: '/account/personalInfo',
                          attributes: {
                            style: {
                              color: 'inherit',
                              textDecoration: 'underline',
                            },
                          },
                        }),
                        linebreak,
                      }
                    )
                : undefined;

            const title = intl.formatMessage({
              id: `components.documentValidation.translation.errors.${errorKey}.title`,
            });

            notify.error({
              title,
              description,
              id: 'invalid_translation_target_language',
            });
          }

          throw error;
        });
    },
    staleTime: TEN_MINUTES,
    enabled: Boolean(annotationId && targetLanguage),
  });
};
