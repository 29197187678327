import { DialogContent } from '@rossum/ui/material';
import { createContext, FC, ReactNode } from 'react';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { DialogIconColor } from '../../../ui/dialog-title/DialogTitleIcon';

type Props = {
  dialogTitle: string;
  onCancel: () => void;
  children?: ReactNode;
  dialogColor: DialogIconColor;
  DialogTitleIcon?: FC<{ fontSize: 'small' }>;
  dialogActions: ReactNode;
  dataCy: string;
};

export const ActionDialogContext = createContext<
  Partial<Pick<Props, 'dialogColor' | 'onCancel'>>
>({});

const ActionDialog = ({
  dialogTitle,
  onCancel,
  children,
  dialogColor,
  DialogTitleIcon,
  dialogActions,
  dataCy,
}: Props) => {
  return (
    <ActionDialogContext.Provider value={{ onCancel, dialogColor }}>
      <DialogTitle
        title={dialogTitle}
        Icon={DialogTitleIcon}
        iconBackgroundColor={dialogColor}
        onClose={onCancel}
        closeDataCy={dataCy}
      />
      <DialogContent sx={{ mx: 4, mt: 4, p: 0 }}>{children}</DialogContent>
      {dialogActions}
    </ActionDialogContext.Provider>
  );
};

export default ActionDialog;
