import { Settings } from '@rossum/ui/icons';
import { Skeleton, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SelectItems from '../../../../../components/ReactHookForm/SelectItems';
import { link } from '../../../../../lib/formaterValues';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import DefaultAlignedToggle from '../../../components/DefaultAlignedToggle';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';
import QueueSettingsLabel from './QueueSettingsLabel';

type Props = {
  suggestedEditEnabled: boolean;
  control: Control<QueueSettingsData>;
  isTriageReady: boolean;
  openTriageSettings: () => void;
};
const Document = ({
  suggestedEditEnabled,
  control,
  isTriageReady,
  openTriageSettings,
}: Props) => {
  const intl = useIntl();

  const {
    field: { value: triageValue },
  } = useController({
    name: 'triageStateEnabled',
    control,
  });

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.document',
      })}
    >
      <Stack>
        <div className={styles.Row}>
          <div>
            <QueueSettingsLabel
              titleId="containers.settings.queues.splitBatchFiles.label"
              tooltipId="containers.settings.queues.splitBatchFiles.tooltip"
            />
          </div>
          <div data-cy="basic-setting-split-batch-select">
            <Tooltip
              title={
                suggestedEditEnabled
                  ? ''
                  : intl.formatMessage(
                      {
                        id: 'containers.settings.queues.splitBatchFiles.unavailable',
                      },
                      { link: link('mailto:support@rossum.ai') }
                    )
              }
            >
              <div>
                <SelectItems<
                  QueueSettingsData,
                  NonNullable<QueueSettingsData['suggestedEdit']>
                >
                  selectDisabled={!suggestedEditEnabled}
                  control={control}
                  name="suggestedEdit"
                  placeholderKey="containers.settings.queues.splitBatchFiles.placeholder"
                  options={[{ value: 'disable' }, { value: 'suggest' }]}
                  getLabel={value =>
                    intl.formatMessage({
                      id: `containers.settings.queues.splitBatchFiles.${value}.title`,
                    })
                  }
                  withDescription
                  getDescription={value =>
                    intl.formatMessage({
                      id: `containers.settings.queues.splitBatchFiles.${value}.description`,
                    })
                  }
                />
              </div>
            </Tooltip>
          </div>
        </div>
        {/* Hide if triage value did not load successfully */}
        {isTriageReady && triageValue === undefined ? null : (
          <Stack maxWidth={600} spacing={2}>
            {isTriageReady ? (
              <DefaultAlignedToggle
                name="triageStateEnabled"
                control={control}
                onClick={openTriageSettings}
                buttonIcon={<Settings />}
                buttonTitle={intl.formatMessage({
                  id: 'containers.settings.queues.triageState.button',
                })}
                dataCy="queue-settings-triage-state"
              >
                {intl.formatMessage({
                  id: 'containers.settings.queues.triageState.sublabel',
                })}
              </DefaultAlignedToggle>
            ) : (
              <Skeleton height={36} width="100%" />
            )}
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'containers.settings.queues.triageState.description',
              })}
            </Typography>
          </Stack>
        )}
      </Stack>
    </PaperSection>
  );
};

export default Document;
