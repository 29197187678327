import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { SchemaField } from '@rossum/api-client/schemaFields';
import { Schema } from '@rossum/api-client/schemas';
import { SchemaColumn } from '@rossum/api-client/shared';
import {
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { boldText } from '../../../../lib/formaterValues';
import { getColumnName } from '../helpers';
import { ColumnItem } from './ColumnItem';
import { SEARCH_VALUE_MIN_LENGTH, searchValueIsTooShort } from './helpers';
import { useGetSchema } from './hooks/useGetSchema';
import { useSearchSchemaFields } from './hooks/useSearchSchemaFields';
type Props = {
  activeQueue: Queue | null;
  handleAddColumn: (field: SchemaField) => void;
  handleRemoveColumn: (column: SchemaColumn) => void;
  schemaColumns: Array<SchemaColumn>;
};

export const filterHeaderFields = (
  schemaContent: NonNullable<Schema['content']>
): SchemaField[] => {
  return schemaContent
    .flatMap(content => content.children)
    .flatMap(dp =>
      dp.category === 'datapoint' && dp.type !== 'button'
        ? [
            {
              schemaId: dp.id,
              label: dp.label,
              // enum fields are currently handled as string type. link to allowed types: https://elis.rossum.ai/api/docs/internal/#queue (check data_type)
              type: dp.type === 'enum' ? 'string' : dp.type,
            },
          ]
        : []
    )
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const AddColumns = ({
  activeQueue,
  handleAddColumn,
  schemaColumns,
  handleRemoveColumn,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const intl = useIntl();

  const { data: schema, isInitialLoading: isSchemaLoading } = useGetSchema({
    schemaId: activeQueue ? getIDFromUrl(activeQueue.schema) : undefined,
  });

  const isOnAllDocsLevel = !activeQueue;

  const searchInPlace = !searchValueIsTooShort(searchValue.trim());

  const { data: searchedFields, isInitialLoading: isSearchingSchemaFields } =
    useSearchSchemaFields({
      search: searchValue.trim(),
      enabled: isOnAllDocsLevel && searchInPlace,
    });

  const filteredFields = useMemo<SchemaField[]>(() => {
    if (isOnAllDocsLevel) {
      // sort by label, if label is missing fallback to schema id, if labels are the same fallback to schema id
      return searchedFields
        ? sortBy(searchedFields.results, [
            obj =>
              obj.label ? obj.label.toLowerCase() : obj.schemaId?.toLowerCase(),
            obj => obj.schemaId?.toLowerCase(),
          ])
        : [];
    }

    if (!schema?.content) return [];
    const headerFields = filterHeaderFields(schema.content);

    if (!searchValue) return headerFields;

    return headerFields.filter(
      field =>
        field.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
        field.schemaId?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [isOnAllDocsLevel, schema?.content, searchValue, searchedFields]);

  return (
    <Stack overflow="hidden" flex={1}>
      <TextField
        autoFocus
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        sx={{ mt: 3, mb: 2 }}
        placeholder={intl.formatMessage({
          id: 'containers.allDocuments.columnsPanel.search.placeholder',
        })}
        helperText={
          isOnAllDocsLevel && Boolean(searchValue) && !searchInPlace
            ? intl.formatMessage(
                { id: 'containers.allDocuments.search.minLength' },
                { minLength: SEARCH_VALUE_MIN_LENGTH }
              )
            : null
        }
      />

      {isSchemaLoading || isSearchingSchemaFields ? (
        <LinearProgress variant="indeterminate" />
      ) : filteredFields.length > 0 ? (
        <Stack spacing={1} pb={4} overflow="auto" flex={1}>
          {filteredFields.map(field => {
            const existingColumn = schemaColumns.find(
              col =>
                getColumnName(col) === field.schemaId &&
                col.dataType === field.type
            );
            return (
              <ColumnItem
                key={`${field.schemaId}.${field.type}`}
                column={{
                  headerName: field.label,
                  visible: true,
                  columnType: 'schema',
                  label: `${field.schemaId} (${field.type})`,
                }}
                onAddColumn={() => handleAddColumn(field)}
                onRemoveColumn={
                  existingColumn
                    ? () => handleRemoveColumn(existingColumn)
                    : undefined
                }
              />
            );
          })}
        </Stack>
      ) : (
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="center"
          sx={{ color: 'text.secondary', textAlign: 'center' }}
        >
          <Typography variant="h5" mb={2}>
            {intl.formatMessage({
              id: 'containers.allDocuments.columnsPanel.search.empty.title',
            })}
          </Typography>
          <Typography>
            {searchInPlace
              ? intl.formatMessage(
                  {
                    id: 'containers.allDocuments.columnsPanel.search.noResults.subTitle',
                  },
                  { search: searchValue }
                )
              : intl.formatMessage({
                  id: 'containers.allDocuments.columnsPanel.search.empty.subTitle',
                })}
          </Typography>
          {!searchInPlace ? (
            <Typography variant="body2">
              {intl.formatMessage(
                {
                  id: 'containers.allDocuments.columnsPanel.search.empty.description',
                },
                { bold: boldText }
              )}
            </Typography>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
};
