import { alpha, List, ListItem, Skeleton } from '@rossum/ui/material';
import { forwardRef } from 'react';

/** For when we know the remaining number of items
 *  In this case it is better to show placeholders and avoid layout jumps
 */
// MAYBE: Move this into @rossum-ui when working on lists?
type ListSkeletonPlaceholderProps = {
  length: number;
  itemHeight?: number;
  divider?: boolean;
};

const ListSkeletonPlaceholder = forwardRef<
  HTMLUListElement,
  ListSkeletonPlaceholderProps
>(
  (
    { length, itemHeight = 45, divider = true }: ListSkeletonPlaceholderProps,
    ref
  ) => (
    <List ref={ref}>
      {Array.from({ length }, (_, i) => i).map(idx => (
        <ListItem
          key={idx}
          sx={{ height: itemHeight }}
          disablePadding
          divider={divider}
        >
          <Skeleton
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: theme => alpha(theme.palette.text.primary, 0.03),
            }}
          />
        </ListItem>
      ))}
    </List>
  )
);

export { ListSkeletonPlaceholder };
