import { createReactComponent } from '@tabler/icons-react';

export const IconCustomMathFunctionEdit = createReactComponent(
  'outline',
  'customMathFunctionEdit',
  'CustomMathFunctionEdit',
  [
    [
      'path',
      {
        key: 'customMathFunctionEdit-path',
        d: 'M3 19C3 19.5308 3.21067 20.0392 3.58579 20.4142C3.96086 20.7893 4.46925 21 5 21C7 21 7 17 8 12C9 7 9 3 11 3C11.531 3 12.0393 3.21067 12.4142 3.58579C12.7893 3.96086 13 4.46925 13 5M5 12H11M18.4201 15.6099C18.615 15.415 18.8467 15.2603 19.1015 15.1547C19.3564 15.049 19.6301 14.9948 19.9051 14.9948C20.1801 14.9948 20.4537 15.049 20.7086 15.1547C20.9635 15.2603 21.1951 15.415 21.39 15.6099C21.5849 15.8048 21.7396 16.0365 21.8453 16.2914C21.951 16.5463 22.0053 16.8199 22.0053 17.0949C22.0053 17.3699 21.951 17.6435 21.8453 17.8984C21.7396 18.1533 21.5849 18.3849 21.39 18.5798L18 22H15V19L18.4201 15.6099Z',
      },
    ],
  ]
);
