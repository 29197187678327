import {
  extensionFunctionType,
  extensionWebhookType,
  Hook,
} from '@rossum/api-client/hooks';
import { Pagination } from './pagination';

// This also defines their order in UI.
export const sideloadOptions = [
  'schemas',
  'modifiers',
  'queues',
  'emails',
  'related_emails',
  'relations',
  'child_relations',
  'suggested_edits',
  'assignees',
  'pages',
  'notes',
  'labels',
  'automation_blockers',
] as const;

export type ExtensionType =
  | typeof extensionWebhookType
  | typeof extensionFunctionType;

export type SideloadValues = (typeof sideloadOptions)[number];

export type State = {
  list: Array<Hook>;
  loaded: boolean;
  pagination: Pagination | Record<string, never>;
};

export type ExtensionsView = 'list' | 'graph';
