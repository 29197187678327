import { ChangeEvent, ReactNode } from 'react';
import JsonEditor, {
  Props as EditorProps,
} from '../../../components/UI/Editor/components/JsonEditor';
import styles from '../style.module.sass';
import { InputContentLoader } from './InputContentLoader';

type Props = {
  children: ReactNode;
  forwardRef: EditorProps['forwardRef'];
  height: number;
  onChange: (_value: string, _event?: ChangeEvent) => void;
  onValidate: EditorProps['onValidate'];
  showInputLoading: boolean;
  value: string;
};

export const ExtensionsInput = ({
  children,
  forwardRef,
  height,
  onChange,
  onValidate,
  showInputLoading,
  value,
}: Props) => {
  const name = 'extensions-editor-input';

  return (
    <div className={styles.Box} data-cy={name}>
      {children}
      {showInputLoading ? (
        <InputContentLoader />
      ) : (
        <JsonEditor
          height={height}
          // It seems that React ACE sometimes splits one user action into
          // several operations, and if the change event is debounced,
          // the partial results can be passed to the parent components
          // and then problems happen (such as marking invalid JSON when it's valid)
          // So it should be small non-zero value
          debounceChangePeriod={5}
          name={name}
          forwardRef={forwardRef}
          onChange={onChange}
          value={value}
          onValidate={onValidate}
        />
      )}
    </div>
  );
};
