import { endpoints } from '@rossum/api-client';
import { Hook as HookType } from '@rossum/api-client/hooks';
import { Store } from 'redux';
import { api } from '../../../../../../lib/apiClient';
import convertKeys, { camelToSnake } from '../../../../../../lib/keyConvertor';
import { fetchExtensionDetailFulfilled } from '../../../../../../redux/modules/extensions/actions';
import { excludeKeysFromConversion as excludeHookKeysFromConversion } from '../../../../../../redux/modules/extensions/epics';
import { RootActionType } from '../../../../../../redux/rootActions';
import { State } from '../../../../../../types/state';

const convertToSnakeCase = (hook: HookType) =>
  convertKeys<HookType>(camelToSnake, excludeHookKeysFromConversion)(hook);

type HookProps = {
  selectedExtension: HookType;
  store: Store<State, RootActionType>;
};

const setExtensionToState = (extension: HookType) =>
  fetchExtensionDetailFulfilled(extension);

export class Hook {
  #selectedExtension: HookType;

  #store: Store<State, RootActionType>;

  constructor({ selectedExtension, store }: HookProps) {
    this.#selectedExtension = selectedExtension;
    this.#store = store;
  }

  get = () => {
    const hook = convertToSnakeCase(this.#selectedExtension);

    return hook;
  };

  getSecretsKeys = () =>
    api.request(endpoints.hooks.getSecretsKeys(this.#selectedExtension.id));

  patchSettingsAndSecrets = (body: {
    settings: Record<string, unknown>;
    secrets: Record<string, unknown>;
  }) =>
    api
      .request(
        endpoints.hooks.patch(this.#selectedExtension.id, {
          settings: body.settings,
          secrets: body.secrets,
          type: this.#selectedExtension.type,
        })
      )
      .then(extension => {
        this.#store.dispatch(setExtensionToState(extension));
        const snakeCasedExtension = convertToSnakeCase(this.#selectedExtension);
        return snakeCasedExtension;
      });
}
