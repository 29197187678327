import { ErrorEvent, EventHint } from '@sentry/react';

export const withSmartlook = ([event, hint]: [ErrorEvent, EventHint]): [
  ErrorEvent,
  EventHint,
] => {
  // Add smartlook playUrl, can this be done more elegantly?
  if (window.smartlook) {
    return [
      {
        ...event,
        contexts: {
          ...event.contexts,
          smartlook: { playUrl: window.smartlook?.playUrl },
        },
      },
      hint,
    ];
  }

  return [event, hint];
};
