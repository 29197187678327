import {
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import { InfoOutlined } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useController,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import TextFieldControl from '../../../../../components/ReactHookForm/controls/TextFieldControl';
import ValidationInput from '../../../../../components/ReactHookForm/ValidationInput';
import {
  docsLinks,
  EXTERNAL_CRONTAB_VALIDATOR,
} from '../../../../../constants/values';
import { QueueMultiSelect } from '../../../../../features/queues/select-queue/QueueMultiSelect';
import { italicText, linebreak, link } from '../../../../../lib/formaterValues';
import { Url } from '../../../../../types/basic';
import { ExtensionType, SideloadValues } from '../../../../../types/extensions';
import {
  defaultNodejsRuntime,
  defaultPythonRuntime,
  DefaultRuntime,
  runtimesConfig,
} from '../../../../Extension/config';
import { EventMultiSelect } from '../../../../Extension/events/components/EventMultiSelect';
import FormLabel from '../../../../User/components/FormLabel';
import styles from '../style.module.sass';
import ExtensionTypeSelect from './ExtensionTypeSelect';
import RuntimeButton from './RuntimeButton';

export type NewExtensionData = {
  description: string;
  events: Array<ExtensionEvent>;
  name: string;
  queues: Array<Url>;
  runtime: DefaultRuntime;
  secret: string;
  settings: Record<string, unknown>;
  secrets: Record<string, unknown>;
  sideload: Array<SideloadValues>;
  tokenOwner: Url | null;
  type: ExtensionType;
  schedule: string;
  url: string;
  payloadLoggingEnabled: boolean;
};

type Props = {
  control: Control<NewExtensionData, unknown>;
  errors: FieldErrors<NewExtensionData>;
};

export const GeneralCreateSettings = ({ control, errors }: Props) => {
  const intl = useIntl();

  const [showSecret, setShowSecret] = useState(false);

  const {
    field: { value: watchType, onChange: onTypeChange },
  } = useController({ name: 'type', control });

  const {
    field: { value: watchRuntime, onChange: onWatchRuntimeChange },
  } = useController({ name: 'runtime', control });

  const {
    field: { value: watchEvents },
  } = useController({ name: 'events', control });

  return (
    <>
      <div className={styles.FormLabel}>
        <FormLabel>
          <FormattedMessage id="containers.settings.extensions.createExtension.name.label" />
        </FormLabel>
      </div>
      <div className={styles.NameInputWidthLimiter}>
        <ValidationInput<NewExtensionData>
          control={control}
          name="name"
          getErrorMessage={(_, { message }) => message ?? ''}
          dataCy="extensions-name-input"
          placeholder={intl.formatMessage({
            id: `containers.settings.extensions.createExtension.name.placeholder`,
          })}
        />
      </div>
      <div className={styles.LabelWithOptional}>
        <div className={styles.FormLabel}>
          <FormLabel>
            <FormattedMessage id="containers.settings.extensions.createExtension.description.label" />
          </FormLabel>
        </div>
        <span className={styles.Optional}>
          <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
        </span>
      </div>
      <TextFieldControl
        size="small"
        minRows={4}
        multiline
        ControllerProps={{ control, name: 'description' }}
        placeholder={intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.description.placeholder',
        })}
        data-cy="extensions-description-input"
      />
      <div className={styles.FormLabel}>
        <FormLabel>
          <FormattedMessage id="containers.settings.extensions.createExtension.events.label" />
          <Tooltip
            title={
              <FormattedMessage
                id="containers.settings.extensions.function.events.tooltip"
                values={{
                  link: link(docsLinks.webhookEvents, { color: 'white' }),
                  linebreak,
                }}
              />
            }
            placement="top"
          >
            <InfoOutlined />
          </Tooltip>
        </FormLabel>
      </div>
      <div className={styles.SubLabel}>
        <FormattedMessage id="containers.settings.extensions.createExtension.events.subLabel" />
      </div>
      <Controller
        control={control}
        name="events"
        render={({ field }) => <EventMultiSelect {...field} />}
      />
      <div className={styles.ErrorMessagePlaceholder}>
        {errors.events && (
          <span
            data-cy="error-message-eventsDropdown"
            className={styles.ErrorMessage}
          >
            <FormattedMessage id="containers.settings.extensions.createExtension.events.required" />
          </span>
        )}
      </div>
      {watchEvents.includes('invocation.scheduled') && (
        <>
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.schedule.label" />
              <Tooltip
                title={
                  <FormattedMessage
                    id="containers.settings.extensions.createExtension.schedule.tooltip"
                    values={{
                      link: link(EXTERNAL_CRONTAB_VALIDATOR, {
                        color: 'white',
                      }),
                      linebreak,
                    }}
                  />
                }
                placement="top"
              >
                <InfoOutlined />
              </Tooltip>
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage id="containers.settings.extensions.createExtension.schedule.subLabel" />
          </div>
          <ValidationInput
            control={control}
            placeholder="*/10 * * * *"
            name="schedule"
            dataCy="extensions-webhook-schedule-input"
            getErrorMessage={(_, { message }) => message ?? ''}
          />
        </>
      )}
      <div className={styles.FormLabel}>
        <FormLabel>
          <FormattedMessage id="containers.settings.extensions.createExtension.queues.label" />
        </FormLabel>
      </div>
      <div className={styles.SubLabel}>
        <FormattedMessage
          id="containers.settings.extensions.createExtension.queues.subLabel"
          values={{
            linebreak,
            italicText,
          }}
        />
      </div>
      <Controller
        control={control}
        name="queues"
        render={({ field }) => (
          <QueueMultiSelect {...field} withLabel={false} />
        )}
      />
      <div className={styles.FormLabel}>
        <FormLabel>
          <FormattedMessage id="containers.settings.extensions.createExtension.extensionType.label" />
        </FormLabel>
      </div>
      <div className={styles.ExtensionTypeWrapper}>
        <ExtensionTypeSelect
          extensionType={extensionFunctionType}
          selected={watchType === extensionFunctionType}
          onClick={() => onTypeChange(extensionFunctionType)}
          dataCy="extension-type-function"
        />
        <ExtensionTypeSelect
          extensionType={extensionWebhookType}
          selected={watchType === extensionWebhookType}
          onClick={() => onTypeChange(extensionWebhookType)}
          dataCy="extension-type-webhook"
        />
      </div>
      {watchType === extensionFunctionType && (
        <>
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.function.programmingLanguage.label" />
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage id="containers.settings.extensions.createExtension.function.programmingLanguage.subLabel" />
          </div>
          <div className={styles.ExtensionTypeWrapper}>
            <RuntimeButton
              selected={watchRuntime === defaultPythonRuntime}
              runtime={defaultPythonRuntime}
              onClick={() => onWatchRuntimeChange(defaultPythonRuntime)}
              dataCy={`extensions-function-runtime-${runtimesConfig[defaultPythonRuntime].type}`}
            />
            <RuntimeButton
              selected={watchRuntime === defaultNodejsRuntime}
              runtime={defaultNodejsRuntime}
              onClick={() => onWatchRuntimeChange(defaultNodejsRuntime)}
              dataCy={`extensions-function-runtime-${runtimesConfig[defaultNodejsRuntime].type}`}
            />
          </div>
        </>
      )}
      {watchType === extensionWebhookType && (
        <>
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.label" />
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.subLabel" />
          </div>
          <ValidationInput<NewExtensionData>
            control={control}
            name="url"
            getErrorMessage={(_, { message }) => message ?? ''}
            dataCy="extensions-webhook-url-input"
            placeholder={intl.formatMessage({
              id: `containers.settings.extensions.createExtension.webhook.url.placeholder`,
            })}
          />
          <div className={styles.LabelWithOptional}>
            <div>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.webhook.sharedSecret.label" />
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage
                  id="containers.settings.extensions.createExtension.webhook.sharedSecret.subLabel"
                  values={{ link: link(docsLinks.validatingPayloads) }}
                />
              </div>
            </div>
            <span className={styles.Optional}>
              <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
            </span>
          </div>
          <ValidationInput
            autoComplete="new-password"
            control={control}
            name="secret"
            type={showSecret ? 'text' : 'password'}
            rightIconProps={{
              onClick: () => setShowSecret(!showSecret),
              'data-cy': 'eye-icon',
            }}
            rightIcon={showSecret ? <EyeOffIcon /> : <EyeIcon />}
            dataCy="extensions-webhook-secret-input"
            getErrorMessage={(_, { message }) => message ?? ''}
            placeholder={intl.formatMessage({
              id: 'containers.settings.extensions.createExtension.webhook.secret.placeholder',
            })}
          />
        </>
      )}
    </>
  );
};
