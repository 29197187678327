import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { Queue } from '@rossum/api-client/queues';
import { useMutation } from '@tanstack/react-query';
import { camelCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../../../lib/apiClient';
import convertKeys from '../../../../lib/keyConvertor';
import { createInboxFulfilled } from '../../../../redux/modules/inbox/actions';
import { throwInfo } from '../../../../redux/modules/messages/actions';
import { setValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';

export const useCreateInbox = (queue: Queue) => {
  const api = useApiClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (emailPrefix: string) =>
      api.request(
        endpoints.inboxes.create({
          queues: [queue.url],
          emailPrefix,
          name: `${queue.name} inbox`,
        })
      ),

    onSuccess: inbox => {
      dispatch(createInboxFulfilled(inbox, false, queue.url));
      dispatch(throwInfo('inbox.created'));
    },

    onError: (response: HttpError) => {
      const convertedResponse = convertKeys(camelCase)(response.data);
      dispatch(setValidationMessagesAction(convertedResponse, 'inbox'));
    },
  });
};
