import { Message } from '@rossum/api-client/shared';
import { IntlShape } from 'react-intl';
import * as R from 'remeda';
import {
  AUTOMATION_BUILT_IN_CHECKS,
  CONFIDENCE_THRESHOLDS_LINK,
} from '../../../../../constants/values';
import { DatapointAutomationBlocker } from '../../../../../redux/modules/annotation/types';
import { schemaMapSelector } from '../../../../../redux/modules/schema/schemaMapSelector';
import { MessagesStats } from '../../../../../types/datapoints';

export const BLOCKERS_COLOR = 'warning';

export type MessageOrBlocker = Omit<Message, 'type'> & {
  type: Message['type'] | 'blocker';
  docsLink?: string;
};

const docLinksMap: Partial<Record<DatapointAutomationBlocker['type'], string>> =
  {
    low_score: CONFIDENCE_THRESHOLDS_LINK,
    failed_checks: AUTOMATION_BUILT_IN_CHECKS,
  };

export const createContentForDatapointBlocker = (
  blocker: DatapointAutomationBlocker,
  sample: DatapointAutomationBlocker['samples'][0] | undefined,
  intl: IntlShape
) => {
  switch (blocker.type) {
    case 'error_message': {
      const message = sample?.details?.messageContent?.join(', ');
      return `${intl.formatMessage({
        id: `components.documentValidation.automationBlockers.tooltip.error_message`,
      })}${message ? ` ${message}` : ''}`;
    }

    case 'failed_checks': {
      return intl.formatMessage({
        id: `components.documentValidation.automationBlockers.tooltip.failed_checks`,
      });
    }

    case 'low_score': {
      const score = sample?.details?.score;
      const threshold = sample?.details?.threshold;

      return R.isDefined(score) && R.isDefined(threshold)
        ? intl.formatMessage(
            {
              id: 'components.documentValidation.automationBlockers.tooltip.low_score_dynamic_threshold',
            },
            {
              confidencePct: `${parseFloat((score * 100).toFixed(2))}%`,
              thresholdPct: `${parseFloat((threshold * 100).toFixed(2))}%`,
            }
          )
        : intl.formatMessage({
            id: 'components.documentValidation.automationBlockers.tooltip.low_score_threshold',
          });
    }

    case 'extension': {
      const content = sample?.details?.content?.join(', ');

      return `${intl.formatMessage({
        id: 'components.documentValidation.automationBlockers.tooltip.extension',
      })}${content ? `: ${content}` : ''}`;
    }

    case 'no_validation_sources': {
      return intl.formatMessage({
        id: `components.documentValidation.automationBlockers.tooltip.no_validation_sources`,
      });
    }

    default: {
      return `${intl.formatMessage({
        id: 'components.documentValidation.automationBlockers.tooltip.other',
      })}: ${blocker.type}`;
    }
  }
};

// prepend the label of the column for given blocker type,
// ignore the content of it because there can be multiple samples
// in footer we display the content in the context of given datapoint, we do not need that in sidebar
const createContentForLineItemBlocker = (
  blocker: DatapointAutomationBlocker,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  intl: IntlShape
) => {
  const fieldLabel = schemaMap.get(blocker.schemaId)?.label ?? '';

  switch (blocker.type) {
    case 'error_message':
    case 'extension':
    case 'failed_checks':
    case 'no_validation_sources': {
      return `${fieldLabel}: ${intl.formatMessage({
        id: `components.documentValidation.automationBlockers.tooltip.${blocker.type}`,
      })}`;
    }

    case 'low_score': {
      return `${fieldLabel}: ${intl.formatMessage({
        id: 'components.documentValidation.automationBlockers.tooltip.low_score_threshold',
      })}`;
    }

    default: {
      return `${fieldLabel}: ${intl.formatMessage({
        id: 'components.documentValidation.automationBlockers.tooltip.other',
      })}: ${blocker.type}`;
    }
  }
};

export const groupMessagesByType = (
  messages: Array<Message> | undefined | null
) => {
  const [errorMessages, warningAndInfoMessages] = R.partition(
    messages ?? [],
    message => message.type === 'error'
  );

  const [warningMessages, infoMessages] = R.partition(
    warningAndInfoMessages,
    message => message.type === 'warning'
  );
  return { errorMessages, warningMessages, infoMessages };
};

export const combineMessagesAndBlockers = (
  currentSchemaId: string,
  messages: Array<Message> | undefined = [],
  blockers: Array<DatapointAutomationBlocker> | undefined = [],
  schemaMap: ReturnType<typeof schemaMapSelector>,
  intl: IntlShape
): Array<MessageOrBlocker> => {
  const { errorMessages, warningMessages, infoMessages } =
    groupMessagesByType(messages);

  const samplesAsMessages = blockers.map(
    blocker =>
      ({
        type: 'blocker',
        content:
          // multivalues can have different schemaId than the current one
          currentSchemaId !== blocker.schemaId
            ? createContentForLineItemBlocker(blocker, schemaMap, intl)
            : createContentForDatapointBlocker(
                blocker,
                blocker.samples[0],
                intl
              ),
        docsLink: docLinksMap[blocker.type],
      }) satisfies MessageOrBlocker
  );

  // order of types should be: errors, blockers, warnings, infos
  // reverse the order of messages because we want to display the latest messages first
  return [
    ...R.reverse(errorMessages),
    ...samplesAsMessages,
    ...R.reverse(warningMessages),
    ...R.reverse(infoMessages),
  ];
};

export type MessagesStatsWithBlockers = Omit<
  MessagesStats,
  'highestSeverity'
> & {
  highestSeverity: MessagesStats['highestSeverity'] | 'blocker';
};

export const combineMessagesStatsAndBlockers = (
  messagesStats: MessagesStats | undefined,
  blockers: Array<DatapointAutomationBlocker> | undefined
): MessagesStatsWithBlockers | undefined => {
  const currentSeverity = messagesStats?.highestSeverity;
  const newCount = (messagesStats?.count ?? 0) + (blockers?.length ?? 0);
  const newSeverity =
    currentSeverity === 'error'
      ? 'error'
      : blockers?.length
        ? 'blocker'
        : currentSeverity;

  return newSeverity === undefined || newCount === 0
    ? undefined
    : { count: newCount, highestSeverity: newSeverity };
};
