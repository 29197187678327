import {
  translateAnnotationResponseSchema,
  translateAnnotationPayloadSchema,
} from './translate.schema';

export type TranslateAnnotationPayload = {
  granularity: 'lines';
  pageNumbers: number[];
  targetLanguage: string;
};

export type TranslateAnnotationResponse = {
  results: Array<{
    pageNumber: number;
    granularity: 'lines';
    items: Array<{ position: [number, number, number, number]; text: string }>;
  }>;
};

export const translate = (
  annotationId: number,
  payload: TranslateAnnotationPayload
) => {
  return {
    endpoint: `/annotations/${annotationId}/page_data/translate`,
    method: 'POST',
    payloadSchema: translateAnnotationPayloadSchema,
    payload,
    responseSchema: translateAnnotationResponseSchema,
  } as const;
};
