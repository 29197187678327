import { createReactComponent } from '@tabler/icons-react';

export const IconCustomSuggestX = createReactComponent(
  'outline',
  'customSuggestX',
  'CustomSuggestX',
  [
    [
      'path',
      {
        key: 'customSuggestX-path',
        d: 'M10 5.16L14 9.16M14 5.16L10 9.16M11 14.32H13M18 14.32H19C19.2648 14.32 19.5192 14.4254 19.707 14.6132C19.8948 14.801 20 15.0554 20 15.32V15.72M20 18.6V19C20 19.2648 19.8948 19.5192 19.707 19.707C19.5192 19.8948 19.2648 20 19 20H18M13 20H11M6 20H5C4.73524 20 4.4808 19.8948 4.293 19.707C4.1052 19.5192 4 19.2648 4 19V18.6M4 15.72V15.316C4 15.0512 4.1052 14.7968 4.293 14.609C4.4808 14.4212 4.73524 14.316 5 14.316H6',
      },
    ],
  ]
);
