import { User } from '@rossum/api-client/users';
import { Box, Checkbox, Stack, Typography } from '@rossum/ui/material';
import UserIcon from 'mdi-react/UserIcon';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { unique } from 'remeda';
import { useUnpaginatedUsers } from '../../../business/users/useUnpaginatedUsers';
import Picker, { OptionT } from '../../../components/Picker';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import { fetchDeletedUsersFulfilled } from '../../../redux/modules/users/actions';
import Loader from '../../Loader';

type Props = {
  isSearched: boolean;
  onPick: (ids: Array<number>) => void;
  onSearch: (search: string) => void;
  options: Array<OptionT>;
  picked: Array<number>;
  usersLoaded: boolean;
};

const getIdsFromResult = (result: Array<User>) => result.map(({ id }) => id);

const UserPicker = ({
  isSearched,
  options,
  picked,
  onPick,
  onSearch,
  usersLoaded,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data } = useUnpaginatedUsers(
    {
      deleted: true,
    },
    {
      onSuccess: deletedUsers => {
        dispatch(fetchDeletedUsersFulfilled(deletedUsers));
      },
      select: getIdsFromResult,
    }
  );

  return (
    <>
      <Picker
        titleId="containers.statistics.sidebar.title.users"
        options={options}
        isSearched={isSearched}
        onSearch={onSearch}
        onPick={onPick}
        dataCy="stats-select-all-users"
        picked={picked}
        noResultsPlaceholder={
          usersLoaded ? (
            <InfoPlaceholder
              icon={<UserIcon />}
              title="containers.statistics.noUsers"
            />
          ) : (
            <Loader />
          )
        }
      />
      <Box
        sx={{ height: 100, p: 1 }}
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <Stack
          component="label"
          direction="row"
          spacing={3}
          alignItems="center"
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'containers.statistics.sidebar.checkbox.deletedUsers',
            })}
          </Typography>
          <Checkbox
            size="small"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (data) {
                const newPicked = e.target.checked
                  ? unique([...picked, ...data])
                  : picked.filter(id => !data.includes(id));
                onPick(newPicked);
              }
            }}
            id="deleted-users"
            checked={data?.every(id => picked.includes(id)) ?? false}
          />
        </Stack>
      </Box>
    </>
  );
};

export default UserPicker;
