import {
  AnnotationContentAction,
  ExtensionEvent,
  ExtensionEventGroup,
} from '@rossum/api-client/hooks';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';

export const webhookEvents = [
  {
    name: 'email',
    actions: ['received'],
  },
  {
    name: 'upload',
    actions: ['created'],
  },
  {
    name: 'annotation_status',
    actions: ['changed'],
  },
  {
    name: 'annotation_content',
    actions: [
      'initialize',
      'started',
      'updated',
      'confirm',
      'export',
      'user_update', // deprecated, only to be shown when the GET /hooks/{id} API response has it set in `hook.events` (set up before the change)
    ],
  },
  {
    name: 'invocation',
    actions: ['scheduled', 'manual'],
  },
] as const;

export type EventShape = (typeof webhookEvents)[number];

// Keep this so we can quickly hide events in the future?
const hiddenEvents: Readonly<Array<EventShape['name']>> = [] as const;
export const visibleWebhookEvents = webhookEvents.filter(e =>
  DEV_FEATURES_ENABLED ? true : !hiddenEvents.includes(e.name)
);

export const isExtensionEventGroup = (
  name: string
): name is ExtensionEventGroup => {
  return visibleWebhookEvents.some(event => event.name === name);
};

export const getDestructedEventAction = (eventAction: ExtensionEvent) => {
  const [name, action] = eventAction.split('.');

  return {
    name: name as ExtensionEventGroup,
    action: action as AnnotationContentAction,
  };
};
