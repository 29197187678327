import { HttpError } from '@rossum/api-client/errors';
import PagePreviewDrawer from '../../../ui/page-preview/PagePreviewDrawer';
import { usePagePreview } from '../hooks/usePagePreview';

type PagePreviewProps = {
  originalFileName: string | undefined;
  pages: Array<string>;
};

const PagePreview = ({ pages, originalFileName }: PagePreviewProps) => {
  const { data: pagePreviewData, error } = usePagePreview(pages[0]);

  const errorStatusCode = (error as HttpError)?.code;

  return (
    <PagePreviewDrawer
      previewImage={pagePreviewData}
      originalFileName={originalFileName}
      pageCount={pages.length}
      errorStatusCode={errorStatusCode}
    />
  );
};

export { PagePreview };
