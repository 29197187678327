import { getIDFromUrl, Url } from '@rossum/api-client';
import { useIntl } from 'react-intl';
import CountBadge from '../../ui/count-badge/CountBadge';
import { useFetchAttachmentRelation } from '../document/annotation-attachment/hooks/useFetchAttachmentRelation';
import { useDocumentStore } from './document-store/DocumentStore';

type AttachmentsIndicatorForValidationScreenProps = {
  annotationUrl: Url;
};

const AttachmentsIndicatorForValidationScreen = ({
  annotationUrl,
}: AttachmentsIndicatorForValidationScreenProps) => {
  const intl = useIntl();
  const openDrawer = useDocumentStore(state => state.openDrawer);

  const annotationId = getIDFromUrl(annotationUrl);

  const { attachmentRelation } = useFetchAttachmentRelation(annotationId);

  const attachmentsCount = attachmentRelation?.annotations.length;

  return attachmentsCount ? (
    <CountBadge
      count={attachmentsCount}
      label={intl.formatMessage({
        id: 'components.annotationAttachment.indicator.label',
      })}
      onClick={() => openDrawer({ drawer: 'attachments', annotationId })}
      dataCy="validation-screen-addAttachment-label"
    />
  ) : null;
};

export { AttachmentsIndicatorForValidationScreen };
