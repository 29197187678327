import { InfoOutlined } from '@rossum/ui/icons';
import {
  Alert,
  Button,
  Collapse,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ComponentType } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

const TIMEOUT = 200;

type SoftWarningProps = {
  active: boolean;
  textKey: LocalizationKeys;
  textKeySecondary?: LocalizationKeys;
  buttonKey: LocalizationKeys;
  handleOnClick: () => void;
  buttonKeySecondary?: LocalizationKeys;
  handleOnClickSecondary?: () => void;
  Icon?: ComponentType<{ fontSize: 'small' }>;
  title?: LocalizationKeys;
  dataCy?: string;
  dataCySecondary?: string;
};

const SoftWarning = ({
  active,
  textKey,
  buttonKey,
  textKeySecondary,
  handleOnClick,
  buttonKeySecondary,
  handleOnClickSecondary,
  Icon,
  title,
  dataCy,
  dataCySecondary,
}: SoftWarningProps) => {
  const intl = useIntl();

  const alertIcont = Icon ? (
    <Icon fontSize="small" />
  ) : (
    <InfoOutlined fontSize="small" />
  );

  return (
    <Collapse in={active} timeout={TIMEOUT} mountOnEnter unmountOnExit>
      <Alert
        variant="filled"
        severity="info"
        icon={alertIcont}
        sx={{ mx: 2, mt: 1 }}
      >
        <Stack direction="column" alignItems="flex-start" gap={0.75}>
          {title && (
            <Typography variant="h6">
              {intl.formatMessage({ id: title })}
            </Typography>
          )}
          <Typography variant="caption">
            {intl.formatMessage({ id: textKey })}
          </Typography>

          {textKeySecondary && (
            <Typography variant="caption">
              {intl.formatMessage({ id: textKeySecondary })}
            </Typography>
          )}

          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
            gap={0.5}
          >
            {handleOnClickSecondary && buttonKeySecondary && (
              <Button
                onClick={handleOnClickSecondary}
                variant="text"
                color="inherit"
                size="small"
                data-cy={dataCySecondary}
              >
                {intl.formatMessage({ id: buttonKeySecondary })}
              </Button>
            )}
            <Button
              onClick={handleOnClick}
              variant="text"
              color="inherit"
              size="small"
              data-cy={dataCy}
            >
              {intl.formatMessage({ id: buttonKey })}
            </Button>
          </Stack>
        </Stack>
      </Alert>
    </Collapse>
  );
};

export default SoftWarning;
