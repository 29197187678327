import { CloudDownload } from '@rossum/ui/icons';
import { CircularProgress } from '@rossum/ui/material';
import { AnnotatorLimitedRestrictor } from '../../../components/Restrictors';
import { DownloadAllButton } from '../download-buttons/DownloadAllButton';
import { useExportAnnotationsCrossQueue } from '../hooks/useExportAnnotationsCrossQueue';

type Props = {
  annotationIds: number[];
  isPrimary?: boolean;
  queueName: string;
};

export const DownloadCTA = ({
  queueName,
  annotationIds,
  isPrimary = true,
}: Props) => {
  const { mutate: exportAnnotations, isLoading } =
    useExportAnnotationsCrossQueue();

  return (
    <AnnotatorLimitedRestrictor>
      <DownloadAllButton
        ButtonProps={{
          disabled: isLoading || annotationIds.length === 0,
          variant: isPrimary ? 'contained' : 'outlined',
          color: isPrimary ? 'primary' : 'secondary',
          startIcon: isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <CloudDownload />
          ),
          sx: { height: 1 },
        }}
        downloadAll={payload => {
          exportAnnotations({
            format: payload.format,
            selectedAnnotationsIds: annotationIds,
            toExport: false,
            queueName,
          });
        }}
        label="downloadAll"
      />
    </AnnotatorLimitedRestrictor>
  );
};
