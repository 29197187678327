import {
  Autocomplete,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from '@rossum/ui/material';
import { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Url } from '../../../types/basic';
import { User, UserList } from '../../../types/user';

type TokenOwnerSelectProps = {
  allOrgUsers: UserList;
  users: UserList;
  value: Url | null;
  onChange: (values: Url | null) => void;
};

export const getUserName = (user: User): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.email) {
    return user.email;
  }

  return user.username;
};

const TokenOwnerSelect = forwardRef<unknown, TokenOwnerSelectProps>(
  ({ allOrgUsers, value, onChange, users }, ref) => {
    const [inputValue, setInputValue] = useState<string>('');

    const intl = useIntl();
    const selectedUserObject = allOrgUsers.find(({ url }) => url === value);

    return (
      <Autocomplete
        ref={ref}
        data-cy="extensions-users-select"
        PaperComponent={Paper}
        slotProps={{ paper: { elevation: 6 } }}
        size="small"
        options={users}
        getOptionLabel={user => getUserName(user)}
        value={selectedUserObject ?? null}
        onChange={(_e, value) => onChange(value?.url ?? null)}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={intl.formatMessage({
              id: 'containers.settings.extensions.createExtension.tokenOwner.placeholder',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(params, user) => (
          <ListItemButton component="li" {...params} key={user.url}>
            <ListItemText secondary={getUserName(user)} />
          </ListItemButton>
        )}
      />
    );
  }
);

export default TokenOwnerSelect;
