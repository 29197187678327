import { TooltipProps } from 'recharts';
import styles from '../styles.module.sass';

const CategoriesTooltip = ({
  label,
  payload,
}: TooltipProps<number, string>) => (
  <div className={styles.TooltipContent}>
    <div className={styles.TooltipLabel}>{label}</div>
    <div>
      {payload?.map(({ value, name, color }) => (
        <div key={name} className={styles.TooltipValues}>
          <div
            style={{ backgroundColor: color }}
            className={styles.TooltipDot}
          />
          <span>{`${value}`}</span>
          &nbsp;
          {name}
        </div>
      ))}
    </div>
  </div>
);

export default CategoriesTooltip;
