import { getIDFromUrl } from '@rossum/api-client';
import { Message } from '@rossum/api-client/shared';
import { Dialog, Stack, Typography } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import * as R from 'remeda';
import HTMLMessage from '../../../components/DatapointMessage/HTMLMessage';
import {
  addSecondsToDate,
  convertToUTC,
  formatDateHoursSecondsToQuery,
} from '../../../components/Datepicker/helpers';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import { getIDFromString } from '../../../lib/url';
import { findDatapointById } from '../../../redux/modules/datapoints/navigation/findDatapointIndex';
import { isUserAdmin } from '../../../redux/modules/user/selectors';
import { State } from '../../../types/state';
import { useCopyToClipboard } from '../../../ui/copy-to-clipboard/useCopyToClipboard';
import {
  extensionDetailPath,
  extensionsLogsPath,
} from '../../Extensions/helpers';
import { useFieldsRoute } from './useFieldsRoute';

type RowProps = {
  value: string;
  label: string;
};

const Row = ({ value, label }: RowProps) => (
  <Stack direction="row" gap={2}>
    <Typography variant="subtitle2" align="right" minWidth="25%">
      {label}
    </Typography>
    <Typography
      variant="body2"
      sx={{ wordBreak: 'break-word' }}
      component="div"
    >
      <HTMLMessage content={value} />
    </Typography>
  </Stack>
);

const TIME_WINDOW = 60; // time window in seconds for increasing chance to find desired results

type MessageDetailDialogProps = {
  messageDetail: Exclude<Message['detail'], null | undefined>;
  content: string;
  open: boolean;
  onClose: () => void;
  datapointId: Message['id'];
};

const MessageDetailDialogContent = ({
  messageDetail,
  content,
  onClose,
  datapointId,
}: MessageDetailDialogProps) => {
  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();

  const isAdmin = useSelector(isUserAdmin);

  const queueUrl = useSelector((state: State) => state.annotation.queue);
  const queueId = queueUrl ? getIDFromUrl(queueUrl) : null;
  const datapoints = useSelector((state: State) => state.datapoints.content);
  const datapoint = useMemo(() => {
    return findDatapointById(datapoints, Number(datapointId));
  }, [datapoints, datapointId]);

  const rows = [
    { key: 'annotationId', value: getIDFromString(pathname) },
    { key: 'extensionId', value: messageDetail.hookId },
    { key: 'extensionName', value: messageDetail.hookName },
    { key: 'requestId', value: messageDetail.requestId },
    { key: 'message', value: content },
  ] as const;

  const getTimestampQuery = (timestampString: string) => {
    const timestamp = convertToUTC(new Date(timestampString));
    const before = formatDateHoursSecondsToQuery(
      addSecondsToDate(timestamp, TIME_WINDOW)
    );
    const after = formatDateHoursSecondsToQuery(
      addSecondsToDate(timestamp, -TIME_WINDOW)
    );
    return `&timestampBefore=${before}&timestampAfter=${after}`;
  };

  const contentForCopy = rows
    .map(({ key, value }) => `${key}: ${value}`)
    .join('\n');

  const { copyToClipboard } = useCopyToClipboard(contentForCopy);

  const fieldsRoute = useFieldsRoute(datapoint, queueId);

  const { hookId } = messageDetail;
  const isFormulaRelated =
    hookId === null &&
    messageDetail.hookName === 'formula_fields' &&
    !!fieldsRoute;
  const showConfirmButton = isAdmin && (hookId !== null || isFormulaRelated);
  const moreActions = showConfirmButton
    ? R.pipe(
        [
          hookId !== null && {
            localizationKey:
              'components.documentValidation.sidebar.exceptionalError.modal.button.log' as const,
            onClick: () => {
              history.push(
                `${extensionsLogsPath()}?hook=${hookId}${
                  messageDetail.timestamp
                    ? getTimestampQuery(messageDetail.timestamp)
                    : ''
                }`
              );
            },
          },
          {
            localizationKey:
              'components.documentValidation.sidebar.exceptionalError.modal.copyToClipboard' as const,
            onClick: copyToClipboard,
          },
        ],
        R.filter(R.isTruthy)
      )
    : undefined;

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'components.documentValidation.sidebar.exceptionalError.modal.title',
      })}
      onCancel={onClose}
      dialogColor="primary"
      dialogActions={
        <ActionDialogActions
          hideConfirmButton={!showConfirmButton}
          confirmButtonProps={{
            onClick: () => {
              if (isFormulaRelated) {
                history.push(fieldsRoute, {
                  backLink: history.location.pathname,
                });
              } else if (hookId !== null) {
                history.push(extensionDetailPath(hookId), {
                  backLink: history.location.pathname,
                });
              }
            },
            color: 'primary',
          }}
          secondaryButtonProps={
            moreActions ? undefined : { onClick: copyToClipboard }
          }
          confirmButtonText={intl.formatMessage({
            id: isFormulaRelated
              ? `components.documentValidation.sidebar.exceptionalError.modal.button.schemaField`
              : `components.documentValidation.sidebar.exceptionalError.modal.button.extension`,
          })}
          secondaryButtonText={intl.formatMessage({
            id: `components.documentValidation.sidebar.exceptionalError.modal.copyToClipboard`,
          })}
          cancelButtonText={intl.formatMessage({
            id: `components.documentValidation.sidebar.exceptionalError.modal.button.close`,
          })}
          moreActions={moreActions}
        />
      }
      dataCy="exceptional-error-modal-cancel-button"
    >
      <Stack>
        {rows.map(({ key, value }) => {
          if (!value) return null;

          return (
            <Row
              key={key}
              value={`${value}`}
              label={intl.formatMessage({
                id: `components.documentValidation.sidebar.exceptionalError.modal.label.${key}`,
              })}
            />
          );
        })}
        {!isAdmin && (
          <Typography
            variant="body2"
            align="center"
            color="text.secondary"
            sx={{ mt: 2 }}
          >
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.exceptionalError.modal.helpMessage',
            })}
          </Typography>
        )}
      </Stack>
    </ActionDialog>
  );
};

const MessageDetailDialog = (props: MessageDetailDialogProps) => {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: { width: 480, minHeight: 220, position: 'fixed' },
        elevation: 2,
      }}
      onClick={e => e.stopPropagation()}
      onClose={props.onClose}
      sx={{ transition: 'smooth' }}
    >
      <MessageDetailDialogContent {...props} />
    </Dialog>
  );
};

export default MessageDetailDialog;
