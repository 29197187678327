import { Dialog } from '@rossum/ui/material';
import { useState } from 'react';
import {
  AnnotationPurge,
  AnnotationPurgeParams,
} from '../components/AnnotationPurge';

type PurgeCallbacks = {
  onSuccess?: (params: AnnotationPurgeParams) => void;
  onSubmit: (params: AnnotationPurgeParams) => void;
};

export const usePurgeModal = () => {
  const [params, setParams] = useState<AnnotationPurgeParams | null>(null);
  const [callbacks, setCallbacks] = useState<PurgeCallbacks | null>(null);

  const openPurgeModal = (
    newParams: AnnotationPurgeParams,
    purgeCallbacks: PurgeCallbacks
  ) => {
    setParams(newParams);
    setCallbacks(purgeCallbacks);
  };

  const handleClose = () => {
    setParams(null);
    setCallbacks(null);
  };

  const node = (
    <Dialog
      open={params !== null}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 480, minHeight: 213, position: 'fixed' },
        elevation: 2,
      }}
    >
      {params !== null ? (
        <AnnotationPurge
          onPurge={() => {
            callbacks?.onSubmit(params);
            callbacks?.onSuccess?.(params);
            handleClose();
          }}
          onCancel={handleClose}
          {...params}
        />
      ) : null}
    </Dialog>
  );
  return { node, openPurgeModal };
};
