import { getIDFromUrl, snakeToCamel } from '@rossum/api-client/utils';
import { IconTrash } from '@rossum/ui/icons/tabler';
import {
  Button,
  ButtonBaseActions,
  List,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { canManageAnnotation } from '../../../../components/AnnotationInformation/helpers';
import { RoleProps, RoleProvider } from '../../../../components/Restrictors';
import { annotationSelector } from '../../../../redux/modules/annotation/selectors';
import { deleteAnnotations } from '../../../../redux/modules/annotations/actions';
import { isDeleteRecommendationInPathSelector } from '../../../../redux/modules/datapoints/selector';
import { MatchedTriggerRulesPerLevel } from '../../../../types/datapoints';
import { SidebarContainer } from '../shared/SidebarContainer';
import { MatchedCriteria } from './MatchedCriteria';
import { useMatchedRules } from './useMatchedRules';

type DeleteRecommendationProps = {
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
};

export const DeleteRecommendation = ({
  matchedTriggerRules,
}: DeleteRecommendationProps) => {
  const intl = useIntl();

  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const actionRef = useRef<ButtonBaseActions>(null);

  const dispatch = useDispatch();
  const shouldFocusDeleteButton = useSelector(
    isDeleteRecommendationInPathSelector
  );

  const annotation = useSelector(annotationSelector);

  const queueId = getIDFromUrl(annotation?.queue || '');

  const matchedRules = useMatchedRules(matchedTriggerRules);

  // Focus delete button when all other focuses in dp navigation are disabled
  useEffect(() => {
    if (
      shouldFocusDeleteButton &&
      deleteButtonRef.current &&
      actionRef.current
    ) {
      deleteButtonRef.current.focus();
      actionRef.current.focusVisible();
    }
  }, [shouldFocusDeleteButton]);

  return (
    <SidebarContainer
      title={intl.formatMessage({
        id: 'components.sidebarV2.deleteRecommendations.title',
      })}
    >
      <Stack direction="row" spacing={1}>
        <SvgIcon fontSize="small" color="warning">
          <IconTrash />
        </SvgIcon>

        <Stack>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.sidebarV2.deleteRecommendations.text',
            })}
          </Typography>
          <List
            disablePadding
            dense
            sx={{
              listStyleType: 'disc',
              color: 'text.secondary',
              pl: 3,
            }}
          >
            {matchedRules.map(rule => (
              <MatchedCriteria
                key={`${'id' in rule ? rule.id : 'matched-criteria'}-${rule.type}`}
                conditionType={rule.type}
                value={rule.ruleValue}
                enumLabel={'label' in rule ? rule.label : undefined}
              />
            ))}
          </List>
        </Stack>
      </Stack>
      {annotation && (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <RoleProvider>
            {({ role }: RoleProps) => {
              const displaySettingsLink = canManageAnnotation(
                role,
                snakeToCamel(annotation.status)
              );
              return displaySettingsLink ? (
                <Button
                  component={Link}
                  to={{
                    pathname: `/queues/${queueId}/settings/basic`,
                    state: {
                      backLink: `/document/${annotation.id}/`,
                      openTriageRules: true,
                    },
                  }}
                  data-cy="queue-settings-triage"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    '&:hover': {
                      color: theme => theme.palette.secondary.main,
                    },
                  }}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.deleteRecommendations.button.settings',
                  })}
                </Button>
              ) : null;
            }}
          </RoleProvider>
          <Button
            ref={deleteButtonRef}
            action={actionRef}
            variant="contained"
            color="primary"
            data-cy="triage-rules-button-delete"
            startIcon={<SvgIcon component={IconTrash} sx={{ fill: 'none' }} />}
            onClick={() => dispatch(deleteAnnotations([annotation.url]))}
          >
            {intl.formatMessage({
              id: 'components.sidebarV2.deleteRecommendations.button.delete',
            })}
          </Button>
        </Stack>
      )}
    </SidebarContainer>
  );
};
