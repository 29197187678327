import { endpoints, withSideload } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { PlayCircleFilled, Search } from '@rossum/ui/icons';
import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@rossum/ui/material';
import { useQuery } from '@tanstack/react-query';
import { throttle } from 'lodash';
import { matchSorter } from 'match-sorter';
import { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { partition } from 'remeda';
import * as z from 'zod';
import { useHooks } from '../../business/hooks/useHooks';
import { useSchema } from '../../business/schema/useSchema';
import { useUsers } from '../../business/users/useUsers';
import { extensionDetailPath } from '../../containers/Extensions/helpers';
import { useApiClient } from '../../lib/apiClient';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { encodeMqlQuery } from '../document-list-base/mql/mql';
import { useInvokeHook } from '../extension-interop/hooks/useInvokeHook';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../pricing/utils';
import { useWorkspacesWithQueues } from '../queues/hooks/useWorkspacesWithQueues';
import { getFlattenSchemaFields } from '../schemas/getFlattenSchemaFields';

export const QuickSearchAutocomplete = ({
  onClose,
  queue: currentQueue,
}: {
  onClose: () => void;
  queue: Queue | undefined;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const intl = useIntl();

  const debouncedSetSearchValue = useMemo(
    () => throttle(value => setSearchValue(value), 1000),
    []
  );

  const { push } = useHistory();

  const { allWorkspacesWithQueues, isLoading: queuesLoading } =
    useWorkspacesWithQueues({
      enableQueries: true,
    });

  const { data: users, isFetching: usersLoading } = useUsers({
    search: searchValue,
  });
  const { data: hooks, isFetching: hooksLoading } = useHooks({
    name: searchValue,
  });

  const { data: schema, isFetching: schemaLoading } = useSchema(
    currentQueue?.schema
  );

  const schemaFields = useMemo(
    () =>
      Object.values(getFlattenSchemaFields(schema?.content ?? [])).flatMap(
        ({ path, field }) =>
          path !== null && field !== null ? [{ ...field, path }] : []
      ),
    [schema?.content]
  );

  const mqlQuery = encodeMqlQuery({
    items: [ACCESSIBLE_ANNOTATIONS_FILTER_ITEM],
  });

  const { mutate: invoke, isLoading: isInvoking } = useInvokeHook();
  const commandsEnabled = useFeatureFlag('ac-5730-fe-exe-interop');

  const api = useApiClient();
  const { data: annotations, isFetching: annotationsLoading } = useQuery(
    ['quick-search-annotations', searchValue] as const,
    () =>
      api.request(
        withSideload(
          endpoints.annotations.search(
            {},
            { query: mqlQuery, queryString: { string: searchValue } }
          ),
          { documents: true, queues: true, workspaces: true }
        )
      ),
    { enabled: searchValue.length > 0, keepPreviousData: true }
  );

  const isLoading =
    inputValue &&
    (annotationsLoading ||
      queuesLoading ||
      hooksLoading ||
      usersLoading ||
      schemaLoading ||
      inputValue !== searchValue);

  const options = useMemo(() => {
    const queueOptions =
      allWorkspacesWithQueues?.flatMap(workspace =>
        workspace.queues.map(queue => ({
          name: `${workspace.name} > ${queue.name}`,
          type: 'queue' as const,
          secondaryInfo: undefined,
          id: queue.id,
          url: queue.url,
          frontendUrl: `/queues/${queue.id}`,
        }))
      ) ?? [];

    const userOptions =
      users?.results.map(user => ({
        name: `${user.firstName} ${user.lastName}`,
        type: 'user' as const,
        secondaryInfo: user.email,
        id: user.id,
        url: user.url,
        frontendUrl: `/settings/users/${user.id}`,
      })) ?? [];

    const hookOptions =
      hooks?.results.map(hook => ({
        name: `${hook.name}`,
        type: 'extension' as const,
        secondaryInfo: undefined,
        id: hook.id,
        url: hook.url,
        frontendUrl: extensionDetailPath(hook.id),
      })) ?? [];

    const fieldOptions =
      currentQueue && schemaFields
        ? schemaFields.map(field => ({
            name: `${field.label} (${field.id})`,
            type: 'field' as const,
            secondaryInfo: field.path.map(elem => elem.label).join(' > '),
            id: field.id,
            url: undefined,
            frontendUrl: `/queues/${currentQueue.id}/settings/fields/${field.path.map(elem => elem.id).join('/')}`,
          }))
        : [];

    const annotationOptions =
      annotations?.results?.map(annotation => {
        const queue = annotations?.queues.find(
          queue => queue.url === annotation.queue
        );
        const workspace = annotations?.workspaces.find(
          workspace => workspace.url === queue?.workspace
        );
        const document = annotations?.documents.find(
          document => document.url === annotation.document
        );

        return {
          name: `${document?.originalFileName}`,
          type: 'document' as const,
          secondaryInfo: `${workspace?.name} > ${queue?.name}`,
          id: annotation.id,
          url: annotation.url,
          frontendUrl: `/document/${annotation.id}`,
        };
      }) ?? [];

    const commands =
      commandsEnabled && hooks
        ? hooks.results.flatMap(hook => {
            const settings = z
              .object({ is_command: z.boolean() })
              .safeParse(hook.settings);

            return settings.success &&
              settings.data.is_command === true &&
              hook.active
              ? [
                  {
                    type: 'command' as const,
                    name: hook.name,
                    secondaryInfo: undefined,
                    id: hook.id,
                    url: hook.url,
                    frontendUrl: undefined,
                  } as const,
                ]
              : [];
          }) ?? []
        : [];

    return inputValue
      ? [
          ...commands,
          ...fieldOptions,
          ...userOptions,
          ...queueOptions,
          ...hookOptions,
          ...annotationOptions,
        ]
      : [...commands];
  }, [
    allWorkspacesWithQueues,
    annotations?.documents,
    annotations?.queues,
    annotations?.results,
    annotations?.workspaces,
    commandsEnabled,
    hooks,
    inputValue,
    currentQueue,
    schemaFields,
    users?.results,
  ]);

  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={(_, v) => {
        setSearchValue('');
        debouncedSetSearchValue(v);
        setInputValue(v);
      }}
      open
      autoHighlight
      onClose={(_, reason) => {
        if (reason === 'escape') {
          onClose();
        }
      }}
      fullWidth
      sx={{ width: 500 }}
      options={options}
      renderInput={field => (
        <TextField
          {...field}
          InputProps={{
            ...field.InputProps,
            startAdornment: <Search />,
            endAdornment: isLoading ? <CircularProgress size={25} /> : null,
          }}
          autoFocus
        />
      )}
      groupBy={option => option.type}
      renderGroup={({ group, key, children }) =>
        group === 'queue' ||
        group === 'extension' ||
        group === 'document' ||
        group === 'user' ||
        group === 'command' ||
        group === 'field' ? (
          <Fragment key={key}>
            <ListItemText
              primary={intl.formatMessage({
                id: `features.quickSearch.type.${group}`,
              })}
              primaryTypographyProps={{ fontWeight: 'bold' }}
              sx={{ px: 2 }}
            />
            {children}
          </Fragment>
        ) : null
      }
      renderOption={(props, option) => (
        <ListItem {...props} key={`${option.type}-${option.id}`}>
          {option.type === 'command' && !isInvoking ? (
            <ListItemIcon>
              <PlayCircleFilled />
            </ListItemIcon>
          ) : null}
          {option.type === 'command' && isInvoking ? (
            <ListItemIcon>
              <CircularProgress size={18} />
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={option.name}
            secondary={option.secondaryInfo}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
      getOptionLabel={option => option.name}
      noOptionsText={
        inputValue
          ? intl.formatMessage({ id: 'features.quickSearch.noResultsText' })
          : intl.formatMessage({ id: 'features.quickSearch.initialText' })
      }
      filterOptions={(filterOptions, state) => {
        const [frontendFiltered, backendFiltered] = partition(
          filterOptions,
          option => option.type === 'queue' || option.type === 'field'
        );

        const normalizedInputValue = state.inputValue.trim();

        const filtered = matchSorter(frontendFiltered, normalizedInputValue, {
          keys: [
            {
              threshold: matchSorter.rankings.EQUAL,
              key: option => option.id.toString(),
            },
            {
              threshold: matchSorter.rankings.CONTAINS,
              key: option => option.name,
            },
          ],
        });

        return [...filtered, ...backendFiltered];
      }}
      onChange={(_, v) => {
        if (v && v.frontendUrl) {
          push(v.frontendUrl);
          onClose();
        }
        if (v && v.type === 'command') {
          invoke(
            { hook: v.url, payload: {} },
            {
              onSuccess: () => {
                onClose();
              },
            }
          );
        }
      }}
      slotProps={{
        paper: {
          sx: {
            mt: 1,
          },
        },
      }}
    />
  );
};
