import { Box, LinearProgress, Stack, Typography } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { termsAndConditionsURL } from '../../constants/values';
import { link } from '../../lib/formaterValues';

export type ErrorPageRouterState = {
  initialCountdown?: number;
  from: string;
};

const TooManyRequests = () => {
  const intl = useIntl();
  const location = useLocation<ErrorPageRouterState>();
  const history = useHistory();

  const { initialCountdown = 10 } = location.state ?? {};

  const [secondsRemaining, setSecondsRemaining] = useState(initialCountdown);

  const [progress, setProgress] = useState(initialCountdown);

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timerId = setInterval(() => {
        setSecondsRemaining(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
    const originalPath = location.state?.from || '/';

    return history.push(originalPath);
  }, [history, location.state?.from, secondsRemaining]);

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timerId = setInterval(() => {
        setProgress(prev => prev - 0.1);
      }, 100);

      return () => clearInterval(timerId);
    }
    return undefined;
  }, [history, secondsRemaining]);

  const progressPercentage =
    (100 / initialCountdown) * (initialCountdown - progress);

  return (
    <Stack alignItems="center">
      <Typography variant="body1" color="text.disabled" mt={2}>
        {intl.formatMessage(
          { id: 'containers.errorPage.progressSection.text' },
          {
            fairUsePolicyLink: link(termsAndConditionsURL, {}),
          }
        )}
      </Typography>

      <Box sx={{ width: '80%', maxWidth: 400, mt: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progressPercentage}
          sx={{ transition: 2 }}
        />
      </Box>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {intl.formatMessage(
          { id: 'containers.errorPage.too-many-requests.timer' },
          {
            count: secondsRemaining,
          }
        )}
      </Typography>
    </Stack>
  );
};

export { TooManyRequests };
