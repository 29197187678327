import {
  DeleteForever,
  DescriptionOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@rossum/ui/icons';
import {
  alpha,
  Button,
  Chip,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { take } from 'lodash';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { dataDisabled } from '../../../redux/modules/annotation/helpers';
import { State } from '../../../types/state';
import PagePreviewDrawer from '../../../ui/page-preview/PagePreviewDrawer';
import { usePagePreview } from '../hooks/usePagePreview';
import { EmailAttachment } from './EmailAttachment';
import { AttachmentT } from './EmailAttachment/helpers';
import { getAttachmentRows, WithDepth } from './getAttachmentRows';

type Props = {
  attachments: 'loading' | AttachmentT[];
  shouldDisplayAttachmentError?: boolean;
  annotationCount: number;
  getSecondaryAction: (attachment: AttachmentT) => ReactNode;
};

const DISPLAYED_ATTACHMENTS_COUNT = 3;

const shouldDisplayShowMoreLessButton = (
  attachments: 'loading' | WithDepth<AttachmentT>[]
) =>
  Array.isArray(attachments) &&
  attachments.length > DISPLAYED_ATTACHMENTS_COUNT;

const EmailAnnotationsPreview = ({
  attachments: rawAttachments,
  annotationCount,
  getSecondaryAction,
  shouldDisplayAttachmentError,
}: Props) => {
  const intl = useIntl();
  const userId = useSelector((state: State) => state.user.id);
  const [numberOfDisplayedAttachments, setNumberOfDisplayedAttachments] =
    useState(DISPLAYED_ATTACHMENTS_COUNT);

  const attachmentsLoading = rawAttachments === 'loading';

  const emailAttachments = !attachmentsLoading
    ? rawAttachments.filter(
        attachment =>
          !attachment.annotation || attachment.annotation.status !== 'purged'
      )
    : [];

  const hasPurgedAnnotations = !attachmentsLoading
    ? emailAttachments.length < rawAttachments.length
    : false;

  const numberOfPurgedAnnotations =
    rawAttachments.length - emailAttachments.length;

  // Filter detected duplicates
  const emailAttachmentsWithoutDuplicates = !attachmentsLoading
    ? emailAttachments.filter(
        attachment =>
          attachment.document.attachmentStatus !==
          'filtered_by_thread_duplicate'
      )
    : [];

  const attachments = attachmentsLoading
    ? 'loading'
    : getAttachmentRows(emailAttachmentsWithoutDuplicates);

  const [hoveredAttachment, setHoveredAttachment] = useState<
    AttachmentT | undefined
  >(undefined);

  const { data: pagePreviewData } = usePagePreview(
    hoveredAttachment?.annotation?.pages[0]
  );

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          variant="subtitle2"
          color="text.secondary"
          data-cy="attachements-list-title"
        >
          <Stack direction="row" component="span" spacing={2}>
            <DescriptionOutlined fontSize="small" color="inherit" />
            <span>
              <FormattedMessage id="components.emailDocumentsPreview.title" />
            </span>
          </Stack>
        </Typography>
        <Chip
          color={shouldDisplayAttachmentError ? 'error' : 'default'}
          label={
            hasPurgedAnnotations
              ? emailAttachmentsWithoutDuplicates.length
              : annotationCount
          }
        />
      </Stack>
      {attachments === 'loading' ? (
        <Stack spacing={0}>
          {[1, 2, 3].map(n => (
            <Skeleton key={n} height={40} width="100%" sx={{ mt: 0 }} />
          ))}
        </Stack>
      ) : Array.isArray(attachments) && attachments.length ? (
        <List dense data-cy="attachements-list">
          {take(attachments, numberOfDisplayedAttachments).map(attachment => (
            <EmailAttachment
              attachment={attachment}
              key={attachment.item.document.url}
              userId={userId}
              secondaryAction={getSecondaryAction(attachment.item)}
              onMouseEnter={attachmentToHover =>
                setHoveredAttachment(attachmentToHover)
              }
              onMouseLeave={() => setHoveredAttachment(undefined)}
            />
          ))}
          {hasPurgedAnnotations ? (
            <ListItem
              sx={{
                borderRadius: 0.5,
                backgroundColor: theme =>
                  alpha(
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                    0.04
                  ),
                pl: 1.7,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <DeleteForever color="disabled" />
                <Typography
                  variant="body2"
                  color="text.disabled"
                  sx={{ py: 0.5 }}
                >
                  {intl.formatMessage({
                    id: 'components.emailDocumentsPreview.hasPurgedAnnotations',
                  })}
                </Typography>
              </Stack>
            </ListItem>
          ) : null}
          {hoveredAttachment &&
          hoveredAttachment.annotation &&
          !dataDisabled.includes(hoveredAttachment.annotation.status) ? (
            <PagePreviewDrawer
              isNested
              originalFileName={
                hoveredAttachment.document.originalFileName ?? ''
              }
              pageCount={hoveredAttachment.annotation.pages.length}
              previewImage={pagePreviewData}
            />
          ) : null}
        </List>
      ) : hasPurgedAnnotations ? (
        numberOfPurgedAnnotations > 1 ? (
          <Typography variant="body2" color="text.disabled">
            <FormattedMessage id="components.emailDocumentsPreview.allAnnotationsWerePurged" />
          </Typography>
        ) : (
          <Typography variant="body2" color="text.disabled">
            <FormattedMessage id="components.emailDocumentsPreview.annotationWasPurged" />
          </Typography>
        )
      ) : (
        <Typography variant="body2" color="text.disabled">
          <FormattedMessage id="components.emailDocumentsPreview.noDocuments" />
        </Typography>
      )}
      {shouldDisplayShowMoreLessButton(attachments) && (
        <Button
          color="secondary"
          onClick={() =>
            setNumberOfDisplayedAttachments(
              attachments.length !== numberOfDisplayedAttachments
                ? attachments.length
                : DISPLAYED_ATTACHMENTS_COUNT
            )
          }
          startIcon={
            attachments.length !== numberOfDisplayedAttachments ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            )
          }
          size="small"
          sx={{ alignSelf: 'flex-start', ml: 4 }}
          data-cy={
            attachments.length !== numberOfDisplayedAttachments
              ? 'show-more-attachments'
              : 'show-less-attachments'
          }
        >
          <FormattedMessage
            id={
              attachments.length !== numberOfDisplayedAttachments
                ? 'components.emailDocumentsPreview.button.showAll'
                : 'components.emailDocumentsPreview.button.showLess'
            }
          />
        </Button>
      )}
    </Stack>
  );
};

export { EmailAnnotationsPreview };
