import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { State } from '../../../types/extensions';
import { RootActionType } from '../../rootActions';
import { initialPagination } from '../utils';
import {
  clearExtensions,
  fetchExtensionDetailFulfilled,
  fetchExtensions,
  fetchExtensionsFulfilled,
  updateExtensionDetailFulfilled,
} from './actions';

const initialState: State = {
  list: [],
  loaded: false,
  pagination: initialPagination,
};

const extensionsReducer: Reducer<State, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchExtensions):
      return { ...state, loaded: false };

    case getType(fetchExtensionsFulfilled):
      return {
        ...state,
        pagination: action.payload.pagination,
        list: action.payload.results,
        loaded: true,
      };

    case getType(fetchExtensionDetailFulfilled): {
      const { id } = action.payload;
      const index = state.list.findIndex(dp => dp.id === id);

      if (index === -1) {
        return { ...initialState, list: [...state.list, action.payload] };
      }

      const updatedList = [...state.list];
      updatedList[index] = action.payload;

      return { ...state, list: updatedList };
    }

    case getType(updateExtensionDetailFulfilled): {
      const { id } = action.payload;
      const index = state.list.findIndex(dp => dp.id === id);

      if (index === -1) {
        return state;
      }

      const updatedList = [...state.list];
      updatedList[index] = action.payload;

      return { ...state, list: updatedList };
    }

    case getType(clearExtensions):
      return initialState;

    default:
      return state;
  }
};

export default extensionsReducer;
