import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { AutomationBreadcrumbs } from '../../../containers/Automation/AutomationBreadcrumbs';
import { AutomationTabs } from '../../../containers/Automation/AutomationTabs';
import { auroraEngineConfigEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { Header } from '../../../ui/header/Header';
import { DedicatedEnginesList } from '../components/DedicatedEnginesList';
import { ExtractorsList } from '../components/ExtractorsList';
import { GenericEnginesList } from '../components/GenericEnginesList';
import { SplittersList } from '../components/SplittersList';
export const EngineListPage = () => {
  const intl = useIntl();
  const auroraConfigEnabled = useSelector(auroraEngineConfigEnabledSelector);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          {...params}
          title={intl.formatMessage({
            id: 'features.engines.header.title.main',
          })}
          description={intl.formatMessage({
            id: 'features.engines.header.description.main',
          })}
          tabs={<AutomationTabs value="aiEngines" />}
          breadcrumbs={
            <AutomationBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'components.appBar.menu.automation.aiEngines',
                  }),
                },
              ]}
            />
          }
        />
      )}
    >
      <Stack spacing={8} p={4}>
        {auroraConfigEnabled && <ExtractorsList />}
        {auroraConfigEnabled && <SplittersList />}
        <DedicatedEnginesList />
        <GenericEnginesList />
      </Stack>
    </PageLayoutV2>
  );
};
