import { Button, CircularProgress } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { matchPath, useHistory } from 'react-router';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { notify } from '../../toaster';
import { useDatasetContext } from '../context';
import { useDeleteDataset } from '../hooks/useDeleteDataset';
import { GET_DATASETS_QUERY_KEYS } from '../hooks/useGetDatasets';
import { datasetSettingsPath, datasetsPath } from '../routes';
import { transformNameIntoId } from '../utils';

export const DeleteButton = ({
  datasetName,
  shouldRedirect,
}: {
  datasetName: string;
  shouldRedirect: boolean;
}) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { mutate: deleteDataset, isLoading } = useDeleteDataset();

  const { registerTask, datasetTasks } = useDatasetContext();

  const isPollingTask = datasetTasks.some(
    task => task.datasetName === datasetName
  );

  const handleSubmit = () => {
    if (!shouldRedirect) {
      handleClose();

      deleteDataset(
        { datasetName },
        {
          onSuccess: ({ taskId }) => {
            if (taskId) {
              registerTask({
                type: 'delete',
                taskId,
                datasetName,
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: GET_DATASETS_QUERY_KEYS,
                  });

                  notify.success({
                    title: intl.formatMessage(
                      {
                        id: 'features.datasets.context.taskNotification.delete.success.title',
                      },
                      { datasetName }
                    ),
                  });
                  const datasetId = transformNameIntoId(datasetName);
                  const match = datasetId
                    ? matchPath(history.location.pathname, {
                        path: datasetSettingsPath(datasetId),
                        exact: true,
                      })
                    : null;

                  const isUrlExpired = match?.isExact;
                  if (isUrlExpired) history.push(datasetsPath());
                },
                onFailed: () =>
                  notify.error({
                    title: intl.formatMessage(
                      {
                        id: 'features.datasets.context.taskNotification.delete.error.title',
                      },
                      { datasetName }
                    ),
                  }),
              });
            }
          },
        }
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isUpdating = isPollingTask || isLoading;
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={isLoading ? <CircularProgress size={14} /> : null}
        disabled={isUpdating}
        onClick={() => {
          if (shouldRedirect) {
            history.push(datasetsPath());
          } else setOpen(true);
        }}
      >
        {intl.formatMessage({
          id: 'features.datasets.settings.header.button.delete',
        })}
      </Button>
      <DeleteConfirmationDialog
        open={open}
        title={intl.formatMessage(
          {
            id: 'features.datasets.settings.dialog.title',
          },
          { datasetName }
        )}
        onConfirm={handleSubmit}
        onCancel={handleClose}
        description={intl.formatMessage({
          id: 'features.datasets.settings.dialog.text',
        })}
      />
    </>
  );
};
