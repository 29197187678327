// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { extensionEventSchema } from '../models/hookUtils.schema';

export const createHookFromTemplatePayloadSchema = z.object({
  name: z.string(),
  hookTemplate: urlSchema,
  events: z.array(extensionEventSchema),
  queues: z.array(urlSchema),
  tokenOwner: urlSchema.optional().nullable(),
});
