import { Typography } from '@rossum/ui/material';

type SidebarSectionTitleProps = {
  title: string;
};

export const SidebarSectionTitle = ({ title }: SidebarSectionTitleProps) => (
  <Typography
    variant="h6"
    sx={{
      py: 1,
      px: 2,
    }}
  >
    {title}
  </Typography>
);
