import { getIDFromUrl, Url } from '@rossum/api-client';
import { Relation } from '@rossum/api-client/relations';
import { DetailDrawer, detailDrawerClasses } from '@rossum/ui';
import { RemoveCircleOutline } from '@rossum/ui/icons';
import {
  alpha,
  Button,
  Fade,
  LinearProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { DataGridPro, GridRow, GridRowProps } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { filter, isTruthy } from 'remeda';
import { drawerDataGridStyles } from '../../document-list-base/drawerDataGridStyles';
import {
  SimpleColumn,
  SimpleColumnFieldNames,
  useSimpleColumns,
} from '../../document-list-base/hooks/useSimpleColumns';
import { TransformedAnnotation } from '../../document-list-base/hooks/useTransformAnnotation';
import { SupportedAnnotationView } from '../../document-list-base/supportedAnnotationViews';
import { DisabledRow } from '../../pricing/components/DisabledRow';
import { UnpaidFeatureOverlay } from '../../pricing/components/UnpaidFeatureOverlay';
import { attachmentsFeatureSelector } from '../../pricing/selectors';
import { NoAttachmentsFound } from './components/NoAttachmentsFound';
import { useAddAttachmentsModal } from './hooks/useAddAttachmentsModal';
import { useCanPerformAttachmentActions } from './hooks/useCanAddAttachments';
import { useConfirmModal } from './hooks/useConfirmModal';
import { useDeleteAttachmentRelation } from './hooks/useDeleteAttachmentRelation';
import { useFetchAttachmentRelation } from './hooks/useFetchAttachmentRelation';
import { useFetchAttachments } from './hooks/useFetchAttachments';
import { usePatchAttachment } from './hooks/usePatchAttachment';

type HandleSelectAnnotation = (params: {
  annotationUrl: string;
  view: SupportedAnnotationView;
}) => void;

export type UseAttachmentsDrawerState = {
  annotationUrl: Url | null;
};

type AttachmentDrawerProps = UseAttachmentsDrawerState & {
  onClose: () => void;
  handleSelectAnnotation?: HandleSelectAnnotation;
};

// attachments drawer has different dimensions and column width than the others that are using useSimpleColumns
const widthMap: Partial<Record<SimpleColumnFieldNames, number>> = {
  status: 150,
  original_file_name: 250,
  queueName: 180,
  labels: 180,
  createdAt: 150,
};

const replaceWidth = ({ width, ...col }: SimpleColumn) => ({
  ...col,
  width: widthMap[col.field] ?? width,
  ...(col.field === 'createdAt' ? { flex: 1 } : {}),
});

export const AttachmentsDrawer = ({
  onClose,
  annotationUrl,
  handleSelectAnnotation,
}: AttachmentDrawerProps) => {
  const intl = useIntl();

  const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const { attachmentRelation, isInitialLoading: isFetchingAttachmentRelation } =
    useFetchAttachmentRelation(
      annotationUrl ? getIDFromUrl(annotationUrl) : null
    );

  const attachmentIds = attachmentRelation?.annotations.map(getIDFromUrl) || [];
  const parentIds = attachmentRelation?.parent
    ? [getIDFromUrl(attachmentRelation.parent)]
    : [];

  const {
    attachments,
    isLoading: isFetchingAttachments,
    isError,
  } = useFetchAttachments([...attachmentIds, ...parentIds]);

  const attachmentRelationParent = attachmentRelation?.parent;

  const parentAttachment =
    attachmentRelationParent === null
      ? null
      : attachments.find(({ url }) => url === attachmentRelationParent);
  const childAttachments = attachments.flatMap(attachment =>
    attachmentRelation?.annotations.includes(attachment.url) ? [attachment] : []
  );

  const selectedAnnotation = attachments.find(
    ({ url }) => url === annotationUrl
  );

  const isInitialLoading =
    isFetchingAttachments || isFetchingAttachmentRelation;

  return (
    <DetailDrawer
      PaperProps={{ elevation: 2, sx: { width: 1200 } }}
      open={!!annotationUrl}
      title={intl.formatMessage({
        id: 'components.docAttachmentsDrawer.title',
      })}
      onClose={onClose}
      sx={{
        [`& .${detailDrawerClasses.body}`]: {
          height: '100%',
        },
      }}
    >
      {!isAttachmentsEnabled ? (
        <UnpaidFeatureOverlay
          title={intl.formatMessage({
            id: 'features.pricing.unpaidFeatureOverlay.title.attachments',
          })}
          dataCy="unpaid-overlay-attachments-drawer"
        />
      ) : (
        <>
          {isInitialLoading ? <LinearProgress variant="indeterminate" /> : null}
          <Fade in={Boolean(attachmentRelation)}>
            <Stack height={1}>
              {parentAttachment !== undefined &&
              attachmentRelation &&
              annotationUrl ? (
                <AttachmentDrawerContent
                  parentAttachment={parentAttachment}
                  childAttachments={childAttachments}
                  selectedAnnotationId={selectedAnnotation?.id}
                  attachmentRelation={attachmentRelation}
                  rootAnnotationUrl={annotationUrl}
                  onClose={onClose}
                  isError={isError}
                  handleSelectAnnotation={handleSelectAnnotation}
                />
              ) : (
                <>
                  {isInitialLoading ? null : (
                    <Stack alignItems="center" height={1}>
                      <NoAttachmentsFound translationKey="noAttachments" />
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Fade>
        </>
      )}
    </DetailDrawer>
  );
};

const AttachmentDrawerContent = ({
  attachmentRelation,
  rootAnnotationUrl,
  onClose,
  parentAttachment,
  childAttachments,
  selectedAnnotationId,
  isError,
  handleSelectAnnotation,
}: {
  onClose: () => void;
  attachmentRelation: Relation;
  parentAttachment: TransformedAnnotation | null;
  rootAnnotationUrl: Url;
  childAttachments: TransformedAnnotation[];
  selectedAnnotationId: number | undefined;
  isError: boolean;
  handleSelectAnnotation?: HandleSelectAnnotation;
}) => {
  const intl = useIntl();

  const { mutate: patchAttachment, isLoading: isPatchingAttachment } =
    usePatchAttachment();

  const {
    mutate: deleteAttachmentRelation,
    isLoading: isDeletingAttachmentRelation,
  } = useDeleteAttachmentRelation();

  const onRemove = (attachmentUrl: Url) => {
    if (childAttachments.length === 1) {
      deleteAttachmentRelation(attachmentRelation.id, { onSuccess: onClose });
    } else {
      const remainingAttachments = childAttachments.flatMap(attachment =>
        attachment.url === attachmentUrl ? [] : [attachment.url]
      );

      patchAttachment(
        {
          relationId: attachmentRelation.id,
          payload: { annotations: remainingAttachments },
        },
        {
          onSuccess: () => {
            // close drawer if selected annotation is removed from relation, otherwise the drawer content becomes empty.
            if (getIDFromUrl(attachmentUrl) === selectedAnnotationId) {
              onClose();
            }
          },
        }
      );
    }
  };

  const { openConfirmModal } = useConfirmModal();

  const canPerformAttachmentActions = useCanPerformAttachmentActions();

  const columns = useSimpleColumns({
    removeProps: canPerformAttachmentActions
      ? {
          dataCyPrefix: 'attachment',
          onRemove: attachmentUrl =>
            openConfirmModal({
              onConfirm: () => onRemove(attachmentUrl),
              textId: 'removeDocAttachment',
              confirmType: 'Danger',
            }),
          Icon: RemoveCircleOutline,
        }
      : undefined,
    columnsLocation: 'attachmentsDrawer',
    ...(handleSelectAnnotation
      ? {
          detailsColumnProps: {
            handleSelectAnnotation,
            annotations: filter(
              [parentAttachment, ...childAttachments],
              isTruthy
            ),
          },
        }
      : {}),
  });

  const { openModal, node: addAttachmentsModal } = useAddAttachmentsModal();

  const actionInProgress = isPatchingAttachment || isDeletingAttachmentRelation;

  const tables = filter(
    [
      parentAttachment && {
        rows: [parentAttachment],
        tableColumns: columns.map(c =>
          c.type === 'actions'
            ? // display an empty placeholder for parent action cell to have both tables columns aligned
              { ...c, renderCell: () => <Stack width={c.width} height={1} /> }
            : replaceWidth(c)
        ),
        title: intl.formatMessage({
          id: 'components.docAttachmentsDrawer.parent.title',
        }),
      },
      {
        rows: childAttachments,
        tableColumns: columns.map(replaceWidth),
        title: intl.formatMessage({
          id: 'components.docAttachmentsDrawer.child.title',
        }),
        isChildAttachmentTable: true,
      },
    ],
    isTruthy
  );

  return (
    <Stack p={3} height={1} spacing={3}>
      {tables.map(({ rows, tableColumns, title, isChildAttachmentTable }) => (
        <Stack key={rows.map(r => r.id).join(',')} spacing={2}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
          <DataGridPro
            // parent table should never be in loading state
            loading={isChildAttachmentTable ? actionInProgress : false}
            columns={tableColumns}
            rows={rows}
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            autoHeight={rows.length === 0}
            sx={{
              ...drawerDataGridStyles,
              '--DataGrid-overlayHeight': '250px',
            }}
            slots={{
              row: props => (
                <CustomRow
                  {...props}
                  selectedAnnotationId={selectedAnnotationId}
                />
              ),
              loadingOverlay: LinearProgress,
              footer: () => {
                return isChildAttachmentTable ? (
                  <Stack mt={2}>
                    {canPerformAttachmentActions && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ alignSelf: 'flex-end' }}
                        disabled={actionInProgress}
                        onClick={() => openModal(rootAnnotationUrl)}
                        data-cy="attachment-drawer-new-attachment-button"
                      >
                        {intl.formatMessage({
                          id: 'components.docAttachmentsDrawer.addButton',
                        })}
                      </Button>
                    )}
                  </Stack>
                ) : null;
              },
            }}
            localeText={{
              noRowsLabel: intl.formatMessage({
                id: isError
                  ? ('components.modal.docAttachments.noAttachmentsFound.genericError' as const)
                  : ('components.modal.docAttachments.noAttachmentsFound.noAttachments' as const),
              }),
            }}
          />
        </Stack>
      ))}

      {addAttachmentsModal}
    </Stack>
  );
};

const CustomRow = ({
  selectedAnnotationId,
  ...props
}: Omit<GridRowProps, 'row'> & {
  row?: TransformedAnnotation;
  selectedAnnotationId: number | undefined;
}) =>
  props.row ? (
    props.row.restricted_access ? (
      <DisabledRow {...props} />
    ) : props.row.id === selectedAnnotationId ? (
      <Stack sx={{ backgroundColor: t => alpha(t.palette.common.white, 0.2) }}>
        <GridRow {...props} />
      </Stack>
    ) : (
      <GridRow {...props} />
    )
  ) : null;
