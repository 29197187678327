import { ChevronRight } from '@rossum/ui/icons';
import { Breadcrumbs, Stack, Tab, Tabs } from '@rossum/ui/material';
import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import PageLayout from '../../components/PageLayout/index';
import Scrollable from '../../components/Scrollable';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { Breadcrumb } from '../../ui/breadcrumb/Breadcrumb';
import { Header } from '../../ui/header/Header';
import {
  hasSubscriptionPlanExistSelector,
  resolvedFeaturesSelector,
} from '../pricing/selectors';
import { ExportHistoryButton } from './ExportHistoryButton';
import { HistoryPage as History } from './History';
import { useBillingEnabled } from './hooks/useBillingEnabled';
import { useBillingEntity } from './hooks/useBillingEntity';
import { MyPlan } from './MyPlan';
import { Overview } from './Overview';
import { Usage } from './Usage';

type OwnProps = RouteComponentProps<{ section: string }>;

type Props = OwnProps;

const billingSections = [
  { route: 'my-plan', label: 'myPlan' },
  { route: 'overview', label: 'overview' },
  { route: 'statistics', label: 'usage' },
  { route: 'history', label: 'history' },
] as const;

const Billing = ({
  match: { path },
  location: { pathname },
  history,
}: Props) => {
  const intl = useIntl();
  const scrollabeDivRef = useRef<HTMLDivElement | null>(null);

  const { isEnabled, isInitialLoading } = useBillingEnabled();
  const isTrial = useSelector(isTrialSelector);
  const billingEntity = useBillingEntity();
  const resolvedFeatures = useSelector(resolvedFeaturesSelector);

  const isPricingActive = useSelector(hasSubscriptionPlanExistSelector);

  const currentSection = useMemo(() => {
    const sectionInUrl = pathname.split('/').pop();
    return (
      billingSections.find(el => el.route === sectionInUrl) ??
      billingSections[0]
    );
  }, [pathname]);

  const tabs = useMemo(() => {
    return (
      <Tabs
        value={currentSection.route}
        onChange={(_, value) => history.push(`/billing/${value}`)}
      >
        {billingSections
          .filter(section => (section.route === 'history' ? !isTrial : true))
          .filter(section =>
            section.route === 'my-plan' ? isPricingActive : true
          )
          .map(section => (
            <Tab
              key={section.route}
              value={section.route}
              label={intl.formatMessage({
                id: `containers.billing.${section.label}`,
              })}
              data-cy={`billing-${section.route}`}
            />
          ))}
      </Tabs>
    );
  }, [currentSection.route, history, intl, isPricingActive, isTrial]);

  if (isInitialLoading) {
    return null;
  }

  const sectionTitle = intl.formatMessage({
    id: `containers.billing.${currentSection.label}`,
  });

  return isEnabled && billingEntity ? (
    <PageLayout data-page-title="billing">
      <Scrollable ref={scrollabeDivRef}>
        <Header
          scrollableDivRef={scrollabeDivRef}
          tabs={tabs}
          title={sectionTitle}
          description={intl.formatMessage({
            id: 'containers.billing.description',
          })}
          breadcrumbs={
            <Breadcrumbs separator={<ChevronRight fontSize="small" />}>
              <Breadcrumb>
                {intl.formatMessage({ id: 'containers.billing.title' })}
              </Breadcrumb>
              <Breadcrumb>{sectionTitle}</Breadcrumb>
            </Breadcrumbs>
          }
          buttons={
            currentSection.label === 'history'
              ? [
                  <ExportHistoryButton
                    billingEntity={billingEntity}
                    key={billingEntity.name}
                  />,
                ]
              : undefined
          }
        />
        <Stack width={1024} px={4} mx="auto">
          <Route exact path={`${path}/my-plan`}>
            {resolvedFeatures && isPricingActive ? (
              <MyPlan subscriptionUsage={resolvedFeatures} />
            ) : null}
          </Route>
          <Route exact path={`${path}/overview`}>
            <Overview billingEntity={billingEntity} />
          </Route>
          <Route exact path={`${path}/statistics`}>
            <Usage billingEntity={billingEntity} />
          </Route>
          <Route exact path={`${path}/history`}>
            {!isTrial && <History billingEntity={billingEntity} />}
          </Route>
        </Stack>
      </Scrollable>
    </PageLayout>
  ) : (
    <Redirect to="/annotations" />
  );
};

export { Billing };
