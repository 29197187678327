import { NavBar } from '@rossum/rossum-ui/NavBar';
import { Close } from '@rossum/ui/icons';
import {
  Box,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { fullscreenConfigAppPath } from '../helpers';

type Props = {
  children: ReactNode;
  name: string;
  handleClose: () => void;
};

const StyledNavBar = styled(NavBar)({
  zIndex: 2,
  // TODO: expose NavBar classes from elis-ui
  '.NavBar-toolbar': { paddingLeft: 8 },
});

const ConfigAppRoute = ({ handleClose, name, children }: Props) => {
  const match = useRouteMatch();

  return (
    <Route path={`${match.path}${fullscreenConfigAppPath}`}>
      <Stack
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: theme => theme.zIndex.drawer,
          background: theme => theme.palette.background.paper,
        }}
      >
        <StyledNavBar
          position="relative"
          additionalInfo={null}
          user={
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          }
          logo={null}
          navigation={
            <Stack direction="row" alignItems="center" sx={{ pl: 1 }}>
              <Typography variant="h6">{name}</Typography>
            </Stack>
          }
        />
        <Box sx={{ height: '100%', position: 'relative' }}>{children}</Box>
      </Stack>
    </Route>
  );
};

export default ConfigAppRoute;
