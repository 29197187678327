import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Label } from '@rossum/api-client/labels';
import { Page } from '@rossum/api-client/pages';
import { Queue } from '@rossum/api-client/queues';
import { Relation } from '@rossum/api-client/relations';
import { User } from '@rossum/api-client/users';
import { Workspace } from '@rossum/api-client/workspaces';
import equal from 'fast-deep-equal/es6/react';
import { includes, isEmpty, negate } from 'lodash';
import { push, replace } from 'redux-first-history';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import * as R from 'remeda';
import { isTruthy } from 'remeda';
import {
  combineLatest,
  concat,
  EMPTY,
  forkJoin,
  from,
  fromEvent,
  merge,
  Observable,
  of,
  throwError,
  timer,
  zip,
} from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  pairwise,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { absoluteApiUrl, apiUrl, isEmbedded } from '../../../constants/config';
import { FETCH_INTERVAL } from '../../../constants/values';
import { embeddedFeatureSelector } from '../../../features/pricing/selectors';
import {
  authPatch$,
  confirmErrorHandler,
  errorHandler,
  notFoundErrorHandler,
} from '../../../lib/api';
import { api } from '../../../lib/apiClient';
import { createAuthJSONHeaders, report } from '../../../lib/apiHelpers';
import convertKeys, { camelToSnake } from '../../../lib/keyConvertor';
import { assertNever } from '../../../lib/typeUtils';
import {
  constructDocumentUrl,
  getCurrentAnnotationId,
  getIDFromString,
  parse,
} from '../../../lib/url';
import { ProcessingDuration, timeSpent } from '../../../timeSpent/timeSpent';
import { Annotation } from '../../../types/annotation';
import { Url } from '../../../types/basic';
import { Document } from '../../../types/document';
import { State } from '../../../types/state';
import { RootActionType } from '../../rootActions';
import { popAnnotationFromStack } from '../annotations/actions';
import { fetchBboxes } from '../bboxes/actions';
import {
  addSchemasToDatapoints,
  fetchDatapoints,
  fetchDatapointsFulfilled,
  lastDatapointsForTimeSpentUpdate,
} from '../datapoints/actions';
import { confirmEditModeFulfilled } from '../editMode/actions';
import { DOCUMENTS_QUERY } from '../localStorage/actions';
import { fetchSchema } from '../schema/actions';
import { fetchAnnotationStackFulfilled } from '../stack/actions';
import { leaveValidation, startValidation } from '../ui/actions';
import {
  isUserViewer,
  onConfirmNavigateSelector,
  userRoleNameSelector,
} from '../user/selectors';
import { locationChange, makeEpic } from '../utils';
import {
  annotationExpired,
  cancelAnnotation,
  cancelAnnotationFulfilled,
  confirmAnnotation,
  confirmAnnotationFulfilled,
  deleteAnnotation,
  deleteAnnotationFulfilled,
  displayAnnotation,
  fetchAnnotationFulfilled,
  fetchProcessingDuration,
  fetchProcessingDurationFulfilled,
  fetchSuggestedEdit,
  fetchSuggestedEditFulfilled,
  nextAnnotableAnnotation,
  nextAnnotation,
  postponeAnnotation,
  postponeAnnotationFulfilled,
  refetchAnnotation,
  refetchAnnotationEmails,
  refetchAnnotationEmailsFulfilled,
  rejectAnnotation,
  rejectAnnotationFulfilled,
  startAnnotation,
  startAnnotationFulfilled,
} from './actions';
import {
  getMissingAnnotationIndexes,
  getReviewableStatusesFromAnnotationsQuery,
  handleAnnotationStatus,
  readOnlyModeEndingActionCreators,
  validationEndingActionCreators,
} from './helpers';
import { pagesSelector } from './selectors';
import {
  AnnotationLoadMode,
  AutomationBlocker,
  DeleteAnnotationMeta,
  PostponeAnnotationMeta,
  SuggestedEdit,
} from './types';
import { withProcessingDuration } from './withProcessingDuration';

const statusesWithoutContent = ['importing', 'failedImport'].map(camelToSnake);

const annotationDataToFetch = (
  { schema, url, status, restrictedAccess }: Annotation,
  mode: AnnotationLoadMode
) => {
  const fetchSchemaOnFullLoad = [mode === 'full-load' && fetchSchema(schema)];
  const extendedData = [
    fetchDatapoints(url),
    // Bboxes, pages, and schema are not going to change while somebody
    // else is reviewing an annotation. No need to refetch them again
    mode === 'full-load' && fetchBboxes(url),
    ...fetchSchemaOnFullLoad,
    // TODO this is an interesting case read-only annotation switched into reviewing
    mode === 'full-load' && fetchProcessingDuration(url),
  ];

  const showContent =
    restrictedAccess !== true &&
    negate(includes)(statusesWithoutContent, status);

  return R.pipe(
    showContent ? extendedData : fetchSchemaOnFullLoad,
    R.filter(R.isTruthy)
  );
};

const fetchAnnotationDataEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([startAnnotationFulfilled, displayAnnotation])),
    filter(action => !!action.meta.url),
    switchMap(action => {
      return action$.pipe(
        filter(isActionOf(fetchAnnotationFulfilled)),
        mergeMap(({ payload: { annotation } }) =>
          from(annotationDataToFetch(annotation, action.meta.mode))
        )
      );
    })
  )
);

const startAnnotationEpic = makeEpic((action$, _, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(startAnnotation)),
    pluck('payload'),
    switchMap(({ id, mode }) => {
      const statuses = getReviewableStatusesFromAnnotationsQuery();

      return authPost$<{ annotation: string }>(
        `${apiUrl}/annotations/${id}/start`,
        statuses.length ? { statuses } : {}
      ).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        map(({ annotation }) => startAnnotationFulfilled(annotation, mode)),
        catchError(handleAnnotationStatus(id, mode)),
        catchError(errorHandler)
      );
    })
  )
);

const fetchAnnotationEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(
      isActionOf([
        startAnnotationFulfilled,
        displayAnnotation,
        refetchAnnotation,
      ])
    ),
    switchMap(({ meta: { url } }) => {
      const annotationId = getIDFromString(url);

      const query = {
        id: [annotationId],
        sideload: [
          'automation_blockers',
          'documents',
          'modifiers',
          'pages',
          'relations',
          'queues',
          'workspaces',
          'exported_bys',
          'confirmed_bys',
          'labels',
        ],
      };

      return authGetJSON$<{
        automationBlockers: { content: AutomationBlocker[] }[];
        documents: Document[];
        modifiers: User[];
        pages: Page[];
        results: Annotation[];
        relations: Relation[];
        queues: Queue[];
        workspaces: Workspace[];
        confirmedBys: User[];
        exportedBys: User[];
        labels: Label[];
      }>(`${apiUrl}/annotations`, {
        query,
      }).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf([...validationEndingActionCreators])))
        ),
        mergeMap(
          ({
            results,
            automationBlockers,
            documents,
            modifiers,
            pages,
            relations,
            queues,
            workspaces,
            confirmedBys,
            exportedBys,
            labels,
          }) => {
            if (!results.length) return notFoundErrorHandler();

            const annotation = results[0];

            return of(
              fetchAnnotationFulfilled(
                annotation,
                annotation.restrictedAccess
                  ? {
                      automationBlockers: [],
                      document: documents[0],
                      modifier: { user: undefined },
                      pages: [],
                      relations: [],
                      workspace: workspaces[0],
                      queue: queues[0],
                      confirmedBy: undefined,
                      exportedBy: undefined,
                      labels: [],
                    }
                  : {
                      automationBlockers: automationBlockers[0]
                        ? automationBlockers[0].content
                        : [],
                      document: documents[0],
                      workspace: workspaces[0],
                      queue: queues[0],
                      modifier: results[0].modifier
                        ? {
                            user: modifiers.find(
                              ({ url }) => url === results[0].modifier
                            ),
                          }
                        : undefined,
                      pages: pages.sort((a, b) => a.number - b.number),
                      relations,
                      confirmedBy: results[0].confirmedBy
                        ? confirmedBys.find(
                            ({ url }) => url === results[0].confirmedBy
                          )
                        : undefined,
                      exportedBy: results[0].exportedBy
                        ? exportedBys.find(
                            ({ url }) => url === results[0].exportedBy
                          )
                        : undefined,
                      labels,
                    }
              )
            );
          }
        ),
        catchError(errorHandler)
      );
    })
  )
);

const redirectAfterFetchAnnotation = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchAnnotationFulfilled)),
    filter(
      ({
        payload: {
          annotation: { id },
        },
      }) => {
        const {
          router: {
            location: { pathname },
          },
        } = state$.value;
        return getCurrentAnnotationId(pathname) !== id;
      }
    ),
    map(
      ({
        payload: {
          annotation: { id },
        },
      }) =>
        replace(
          constructDocumentUrl({
            id,
            query: parse(state$.value.router.location.search),
          })
        )
    )
  )
);

const nextAnnotationEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(nextAnnotation)),
    map(action => action.meta.nSteps),
    // Ignore the other variant
    filter((nSteps): nSteps is number => typeof nSteps === 'number'),
    map((nSteps = 1) => {
      const {
        router: {
          location: { pathname },
        },
        stack,
      } = state$.value;
      const annotationId = getCurrentAnnotationId(pathname);

      return { nSteps, stack, annotationId };
    }),
    map(({ stack, annotationId, nSteps }) => {
      const currentAnnotationIndex = stack.indexOf(annotationId);
      const nextAnnotationId = stack[currentAnnotationIndex + nSteps];
      const defaultListLocation = getAnnotationBacklink();

      return nextAnnotationId
        ? push(
            constructDocumentUrl({
              id: nextAnnotationId,
              query: {
                ...parse(state$.value.router.location.search),
                datapointPath: undefined,
              },
            })
          )
        : replace(defaultListLocation);
    })
  )
);

const nextAnnotableAnnotationEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(fetchAnnotationFulfilled)),
    switchMap(
      ({
        payload: {
          annotation: { id: annotationId },
        },
      }) =>
        action$.pipe(
          filter(isActionOf(nextAnnotableAnnotation)),
          mergeMap(() => {
            const { stack } = state$.value;

            const defaultListLocation = getAnnotationBacklink();

            const currentAnnotationIndex = stack.indexOf(annotationId);
            const slicedStack = stack.slice(currentAnnotationIndex + 1);

            if (isEmpty(slicedStack)) return of(replace(defaultListLocation));

            const statuses = getReviewableStatusesFromAnnotationsQuery();

            return combineLatest([
              authPost$<{ annotation: Url }>(`${apiUrl}/annotations/next`, {
                annotations: slicedStack.map(
                  id => `${absoluteApiUrl}/annotations/${id}`
                ),
                ...(statuses.length && { statuses }),
              }),
              action$.pipe(
                filter(isActionOf(leaveValidation)),
                map(() => true),
                startWith(false)
              ),
            ]).pipe(
              map(([{ annotation: annotationUrl }, shouldCancel]) => {
                const documentListQuery = localStorage.getItem(DOCUMENTS_QUERY);
                const documentListUrl = documentListQuery
                  ? `/documents?${documentListQuery}`
                  : '/documents';

                const redirectUrl = documentListUrl;

                return annotationUrl
                  ? shouldCancel
                    ? cancelAnnotation(annotationUrl)
                    : startAnnotationFulfilled(annotationUrl)
                  : replace(redirectUrl);
              }),
              catchError((error: AjaxError) => {
                const { stack } = state$.value;
                const currentAnnotationIndex = stack.indexOf(annotationId);

                // if one of the next annotations have been moved to a queue the user does not have access to
                // we filter them out and continue to the next aannotable annotaiton instead of throwing an error
                const missingAnnotationIndexes =
                  getMissingAnnotationIndexes(error);

                if (
                  missingAnnotationIndexes &&
                  missingAnnotationIndexes?.length > 0 &&
                  currentAnnotationIndex !== -1
                ) {
                  const firstHalf = stack.slice(0, currentAnnotationIndex + 1);
                  const secondHalf = stack.slice(currentAnnotationIndex + 1);

                  const filteredSecondHalf = secondHalf.filter(
                    (_, index) => !missingAnnotationIndexes.includes(index)
                  );
                  const filteredStack = [...firstHalf, ...filteredSecondHalf];

                  return of(
                    fetchAnnotationStackFulfilled(filteredStack),
                    nextAnnotableAnnotation()
                  );
                }
                return errorHandler(error);
              })
            );
          })
        )
    )
  )
);

const resetDatapointPath = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        ...validationEndingActionCreators,
        cancelAnnotation,
        popAnnotationFromStack,
        confirmAnnotation,
      ])
    ),
    map(() => state$.value),
    pluck('router', 'location'),
    filter(({ pathname }) => pathname.includes('/document/')),
    map(({ pathname, search }) =>
      constructDocumentUrl({
        pathname,
        query: { ...parse(search), datapointPath: undefined },
      })
    ),
    map(path => replace(path))
  )
);

const refetchAnnotationEmailsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([refetchAnnotationEmails])),
    switchMap(({ payload: { annotationId } }) =>
      from(api.request(endpoints.annotations.get(annotationId))).pipe(
        map(annotation => refetchAnnotationEmailsFulfilled(annotation))
      )
    )
  )
);

const fetchProcessingDurationEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchProcessingDuration)),
    switchMap(({ payload: annotationUrl }) =>
      authGetJSON$<
        ProcessingDuration & {
          annotation: Url;
        }
      >(`${annotationUrl}/processing_duration`).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf([...validationEndingActionCreators])))
        ),
        map(fetchProcessingDurationFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const setupTimeSpentOnWindowCloseEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(({ meta: { url } }) =>
      fromEvent<PageTransitionEvent>(window, 'pagehide', {
        capture: true,
      }).pipe(
        filter(e => !e.persisted),
        // unsubscribe from this listener when annotating is finished
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        tap(() => {
          const processingDuration = timeSpent.stopAnnotation(
            getIDFromUrl(url)
          );

          // send a `keepalive` request and Jesus take the wheel (we don't know how it ends up)
          // sendBeacon will not let us set custom headers incl. Authorization :shrug:
          fetch(`${url}/cancel`, {
            method: 'POST',
            headers: createAuthJSONHeaders(),
            keepalive: true,
            body: JSON.stringify(convertKeys(camelToSnake)(processingDuration)),
          });
        }),
        mapTo({ type: 'CANCEL_AFTER_PAGEHIDE' })
      )
    )
  )
);

// When a tab becomes hidden, update last DP time spent
const setupTimeSpentOnTabHideEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(() =>
      fromEvent<Event>(document, 'visibilitychange').pipe(
        filter(() => document.visibilityState === 'hidden'),
        withLatestFrom(
          action$.pipe(filter(isActionOf(lastDatapointsForTimeSpentUpdate)))
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        filter(
          ([
            _e,
            {
              payload: { datapoints },
            },
          ]) => datapoints.length > 0
        ),
        mergeMap(
          ([
            _e,
            {
              payload: { annotationUrl, datapoints },
            },
          ]) =>
            forkJoin(
              datapoints.map(d => {
                const timeSpentUpdate = timeSpent.getElapsedDatapoint(d.id);

                // We need to return some value, so that
                // forkJoin will emit a value at the end.
                if (!timeSpentUpdate) return of(undefined);

                return authPatch$(
                  `${annotationUrl}/content/${d.id}?fields!=children`,
                  timeSpentUpdate
                ).pipe(
                  catchError((err: AjaxError) => {
                    // Ignore errors so that we can call validation ending action
                    report(err);
                    return of(undefined);
                  })
                );
              })
            ).pipe(mapTo({ type: 'UPDATED_ON_TAB_HIDE' }))
        )
      )
    )
  )
);

const startAnnotationTimespentEpic = makeEpic((action$, _) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    tap(() => timeSpent.startAnnotationOpening()),
    switchMap(({ meta: { url } }) =>
      combineLatest([
        action$.pipe(
          // User can start working only when datapoints and their schema
          // is loaded, so that's when we start measuring
          filter(isActionOf(addSchemasToDatapoints)),
          map(() => {
            const result = timeSpent.stopAnnotationOpening();

            timeSpent.startAnnotation(getIDFromUrl(url));

            return result ?? { timeSpentOpening: 0 };
          })
        ),
        // We already have the processing duration response,
        // or we need to wait for it.
        action$.pipe(filter(isActionOf(fetchProcessingDurationFulfilled))),
      ]).pipe(
        take(1),
        tap(([{ timeSpentOpening }, { payload }]) => {
          // Store initial processing duration in the timer,
          // so that we have access to it even if the annotation state
          // is already cleared (e.g. when cancelAnnotation action is dispatched)
          // If the API will accept increments, this won't be necessary.

          timeSpent.setAnnotationInitialProcessingDuration(getIDFromUrl(url), {
            ...payload,
            // To be able to compare opening times of different annotations,
            // we measure only the time when the annotation is opened for the first name.
            // Opening it again, might have different performance characteristics
            // (e.g. caching, some processing going on for the first opening, ...),
            timeSpentOpening: payload.timeSpentOpening || timeSpentOpening,
          });
        }),
        mapTo({ type: 'INITIAL_PROCESSING_DURATION_SET', meta: { url } })
      )
    )
  )
);

const confirmAnnotationEpic = makeEpic(
  (action$, _state$, { authPatch$, authPost$ }) =>
    action$.pipe(
      filter(isActionOf(confirmAnnotation)),
      map(({ meta }) => meta),
      withProcessingDuration(
        ({ annotationUrl }) => annotationUrl,
        action$,
        authPatch$
      ),
      mergeMap(([{ annotationUrl, skipWorkflows }, processingDuration]) =>
        authPost$(`${annotationUrl}/confirm`, {
          ...processingDuration,
          skipWorkflows,
        }).pipe(
          map(() => confirmAnnotationFulfilled(annotationUrl)),
          catchError(confirmErrorHandler(annotationUrl))
        )
      )
    )
);

const navigateAfterChangeAnnotationStatusEpic = makeEpic((action$, state$) =>
  merge(
    action$.pipe(
      filter(
        isActionOf([
          confirmAnnotationFulfilled,
          deleteAnnotationFulfilled,
          postponeAnnotationFulfilled,
          rejectAnnotationFulfilled,
        ])
      )
    )
  ).pipe(
    filter(negate(isEmbedded)),
    filter(({ meta }) => !('skipRedirect' in meta) || !meta.skipRedirect),
    map(({ meta: { url } }) => {
      const documentListQuery = localStorage.getItem(DOCUMENTS_QUERY);
      const documentListUrl = documentListQuery
        ? `/documents?${documentListQuery}`
        : '/documents';

      const onConfirmAction = onConfirmNavigateSelector(state$.value);

      switch (onConfirmAction) {
        case 'sidebarNavigation.next':
          return nextAnnotableAnnotation();
        case 'sidebarNavigation.stay':
          return displayAnnotation({ url });
        case 'sidebarNavigation.leave':
          return replace(documentListUrl);
        default:
          return assertNever(onConfirmAction);
      }
    })
  )
);

const updateAnnotationAfterChangeStatusEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([postponeAnnotationFulfilled, rejectAnnotationFulfilled])
    ),
    filter(({ meta }) => meta.skipRedirect),
    // Skip reloading for actions fired from dashboard (annotation list)
    // TODO: rewrite skipRedirect flag to the three different states instead of boolean
    // (e.g. afterAction: 'skipRedirect' | 'updateAnnotation' | 'goToNextAnnotation')
    filter(() => state$.value.router.location.pathname.includes('/document/')),
    filter(negate(isEmbedded)),
    map(({ meta: { url } }) => displayAnnotation({ url }))
  )
);

const statusesWithPeriodicalCheck: Annotation['status'][] = [
  'exporting',
  'reviewing',
  'importing',
];

/**
 * This epic handles following scenarios:
 *
 * 1. As a viewer, when I open an annotation I want to see reviewer's updates.
 * 2. As an annotator, when I open an annotation that is reviewed by somebody else, I want to see their updates
 *    or when they stop reviewing, I want to start reviewing the annotation myself.
 * 3. When the annotation is still importing, I'm waiting for the data to be available.
 */
const periodicalAnnotationCheck = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([displayAnnotation, ...readOnlyModeEndingActionCreators])
    ),
    switchMap(action =>
      isActionOf(displayAnnotation)(action)
        ? action$.pipe(
            filter(isActionOf(fetchAnnotationFulfilled)),
            filter(
              ({
                payload: {
                  annotation: { status },
                },
              }) => statusesWithPeriodicalCheck.includes(status)
            ),
            switchMap(({ payload: { annotation } }) =>
              (annotation.status === 'importing'
                ? of({ type: 'EMPTY_ACTION' })
                : action$.pipe(filter(isActionOf(fetchDatapointsFulfilled)))
              ).pipe(
                delay(FETCH_INTERVAL),
                map(() => {
                  const hasBeenImported =
                    pagesSelector(state$.value).length > 0;

                  return isUserViewer(state$.value)
                    ? displayAnnotation({
                        id: annotation.id,
                        mode: hasBeenImported ? 'refresh' : 'full-load',
                      })
                    : startAnnotation(
                        annotation.id,
                        hasBeenImported ? 'refresh' : 'full-load'
                      );
                })
              )
            )
          )
        : EMPTY
    )
  )
);

const postponeAnnotationEpic = makeEpic(
  (action$, _state$, { authPost$, authPatch$ }) =>
    action$.pipe(
      filter(isActionOf(postponeAnnotation)),
      map(({ meta }) => meta),
      distinctUntilChanged<PostponeAnnotationMeta>(equal),
      withProcessingDuration(({ url }) => url, action$, authPatch$),
      mergeMap(([{ url, skipRedirect }, processingDuration]) => {
        return authPost$(`${url}/postpone`, processingDuration).pipe(
          map(() => postponeAnnotationFulfilled(url, skipRedirect, false)),
          catchError(errorHandler)
        );
      })
    )
);

const deleteAnnotationEpic = makeEpic((action$, _state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(deleteAnnotation)),
    map(({ meta }) => meta),
    distinctUntilChanged<DeleteAnnotationMeta>(equal),
    withProcessingDuration(({ url }) => url, action$, authPatch$),
    mergeMap(([{ url, skipRedirect, emailId }, processingDuration]) => {
      return authPost$(`${url}/delete`, processingDuration).pipe(
        map(() => deleteAnnotationFulfilled(url, skipRedirect, emailId, false)),
        catchError(errorHandler)
      );
    })
  )
);

const cancelAnnotationEpic = makeEpic((action$, _, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(cancelAnnotation)),
    map(({ payload: { url } }) => url),
    withProcessingDuration(url => url, action$, authPatch$),
    mergeMap(([url, processingDuration]) => {
      return authPost$(`${url}/cancel`, processingDuration).pipe(
        mapTo(cancelAnnotationFulfilled()),
        catchError(error =>
          error.status === 409
            ? of({ type: 'EMPTY_ACTION' })
            : throwError(error)
        ),
        catchError(errorHandler)
      );
    })
  )
);

const checkAnnotationStatus = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    pluck('meta', 'url'),
    switchMap(url =>
      timer(300000, 300000).pipe(
        takeUntil(
          action$.pipe(
            filter(
              isActionOf([
                confirmAnnotation,
                cancelAnnotation,
                deleteAnnotation,
                postponeAnnotation,
                confirmEditModeFulfilled,
                annotationExpired,
              ])
            )
          )
        ),
        mergeMap(() => authGetJSON$<Annotation>(url)),
        filter(({ status }) => status !== 'reviewing'),
        map(({ id }) => annotationExpired(id)),
        catchError(errorHandler)
      )
    )
  )
);

const startValidationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startValidation)),
    pluck('meta', 'url'),
    filter(isTruthy),
    map(url => push(url))
  )
);

const handleStart =
  (
    action$: ActionsObservable<RootActionType>,
    state$: StateObservable<State>
  ) =>
  (id: number): Observable<RootActionType> =>
    userRoleNameSelector(state$.value)
      ? isUserViewer(state$.value)
        ? of(displayAnnotation({ id }))
        : of(startAnnotation(id))
      : zip(
          state$.pipe(
            pluck('user', 'id'),
            filter(_id => _id > -1)
          ),
          state$.pipe(pluck('groups', 'length'), filter(isTruthy)),
          state$.pipe(
            pluck('organizationGroup', 'current'),
            filter(isTruthy),
            filter(() => embeddedFeatureSelector(state$.value))
          )
        ).pipe(
          takeUntil(
            action$.pipe(filter(isActionOf([leaveValidation, nextAnnotation])))
          ),
          first(),
          mergeMap(() => handleStart(action$, state$)(id))
        );

const startAnnotationFromUrl = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(locationChange)),
    pluck('payload', 'location', 'pathname'),
    map(getCurrentAnnotationId),
    distinctUntilChanged(),
    filter(() => !state$.value.annotation.id),
    filter(isTruthy),
    mergeMap(handleStart(action$, state$))
  )
);

// Redirecting between different annotations, doesn't properly start the new annotation.
const startAnnotationModeRedirectEpic = makeEpic((_, state$) =>
  state$.pipe(
    map(s => s.router?.location?.pathname),
    filter(isTruthy),
    distinctUntilChanged(),
    pairwise(),
    filter(([prevPathname, nextPathname]) => {
      const prevAnnotationId = getCurrentAnnotationId(prevPathname);
      const nextAnnotationId = getCurrentAnnotationId(nextPathname);

      const prevIsEditMode = prevPathname.endsWith('/edit');
      const nextIsEditMode = nextPathname.endsWith('/edit');

      const routerAction = state$.value.router.action;

      return (
        !!prevAnnotationId &&
        !!nextAnnotationId &&
        prevAnnotationId !== nextAnnotationId &&
        (prevIsEditMode || nextIsEditMode || routerAction === 'POP')
      );
    }),
    mergeMap(() => {
      return concat(
        // clear local state and cancel in-flight requests for the old annotation
        // nextAnnotation also call handleStart via resetDatapointPath epic which is not correct but it works
        of(nextAnnotation('cancel-previous-annotation'))
      );
    })
  )
);

const checkSuggestedSplitAvailabilityEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchAnnotationFulfilled)),
    map(
      ({
        payload: {
          annotation: { suggestedEdit },
        },
      }) => suggestedEdit
    ),
    filter(isTruthy),
    filter(() => !state$.value.ui.readOnly),
    // We know that it comes as string from backend, will be replaced later
    map(url => fetchSuggestedEdit(url as string))
  )
);

const fetchSuggestedSplitEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchSuggestedEdit)),
    pluck('payload'),
    switchMap(url =>
      authGetJSON$<SuggestedEdit>(url).pipe(
        map(fetchSuggestedEditFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const rejectAnnotationEpic = makeEpic(
  (action$, _state$, { authPost$, authPatch$ }) =>
    action$.pipe(
      filter(isActionOf(rejectAnnotation)),
      withProcessingDuration(({ meta: { url } }) => url, action$, authPatch$),
      mergeMap(
        ([
          {
            meta: { url, skipRedirect },
          },
          processingDuration,
        ]) => {
          return authPost$(`${url}/reject`, {
            noteContent: '',
            ...processingDuration,
          }).pipe(
            map(() => rejectAnnotationFulfilled(url, skipRedirect, false)),
            catchError(errorHandler)
          );
        }
      )
    )
);

const updateProcessingDurationAfterExpiredEpic = makeEpic(
  (action$, _state$, { authPatch$ }) =>
    action$.pipe(
      filter(isActionOf(annotationExpired)),
      withProcessingDuration(({ payload }) => payload, action$, authPatch$),
      mergeMap(([{ payload }, processingDuration]) => {
        if (!processingDuration) {
          return EMPTY;
        }

        return authPatch$(
          `${apiUrl}/annotations/${payload}/processing_duration`,
          processingDuration.processingDuration
        ).pipe(mapTo({ type: 'EMPTY_ACTION' }), catchError(errorHandler));
      })
    )
);

export default combineEpics(
  cancelAnnotationEpic,
  checkAnnotationStatus,
  confirmAnnotationEpic,
  deleteAnnotationEpic,
  fetchAnnotationEpic,
  fetchAnnotationDataEpic,
  resetDatapointPath,
  navigateAfterChangeAnnotationStatusEpic,
  nextAnnotableAnnotationEpic,
  nextAnnotationEpic,
  periodicalAnnotationCheck,
  postponeAnnotationEpic,
  startAnnotationEpic,
  startValidationEpic,
  startAnnotationTimespentEpic,
  setupTimeSpentOnWindowCloseEpic,
  setupTimeSpentOnTabHideEpic,
  redirectAfterFetchAnnotation,
  startAnnotationFromUrl,
  startAnnotationModeRedirectEpic,
  fetchProcessingDurationEpic,
  checkSuggestedSplitAvailabilityEpic,
  fetchSuggestedSplitEpic,
  rejectAnnotationEpic,
  updateAnnotationAfterChangeStatusEpic,
  updateProcessingDurationAfterExpiredEpic,
  refetchAnnotationEmailsEpic
);
