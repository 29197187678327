import { Check, FileDownload, MoreVert } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DOC_SPLIT_LINK } from '../../constants/values';
import { link } from '../../lib/formaterValues';
import {
  hideArticleLinksSelector,
  organizationSelector,
} from '../../redux/modules/organization/selectors';
import { Document } from '../../types/document';
import { State } from '../../types/state';
import { useDownloadOriginalDocuments } from '../tasks/hooks/useDownloadOriginalDocuments';
import { DispatchEdit, EditState } from './editState';

export const EditDocumentHeader = ({
  editState,
  onConfirm,
  onCancel,
  confirmInProgress,
  suggestedSplitsActive,
  dispatchEdit,
  document,
}: {
  editState: EditState;
  onConfirm: () => void;
  onCancel: () => void;
  confirmInProgress: boolean;
  suggestedSplitsActive: boolean;
  dispatchEdit: DispatchEdit;
  document: Document | undefined;
}) => {
  const intl = useIntl();

  const disableDownloadOriginal = useSelector(
    (state: State) =>
      organizationSelector(state).uiSettings?.features
        ?.disableDownloadOriginal ?? false
  );

  const hideArticleLinks = useSelector(hideArticleLinksSelector);
  const showHelpLink =
    !hideArticleLinks && !editState.config.settings.disableSplitting;

  const { mutate: downloadOriginalDocuments } = useDownloadOriginalDocuments();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const numberOfPages = editState.parts
    .map(part => part.pages.length)
    .reduce((a, b) => a + b);

  const numberOfParts = editState.parts.length;

  const numberOfDeletedPages = editState.parts
    .map(part => part.pages.filter(page => page.deleted).length)
    .reduce((a, b) => a + b);

  const haveSuggestedSplits =
    editState.config.initialPartsWithSuggestedSplits !== null;

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', px: 4 }}>
      <Stack>
        <Typography variant="h5">
          {intl.formatMessage({ id: 'containers.editDocument.title' })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'containers.editDocument.subtitle',
          })}
          {showHelpLink && ' '}
          {showHelpLink &&
            intl.formatMessage(
              {
                id: 'containers.editDocument.subtitle.readMore',
              },
              { helplink: link(DOC_SPLIT_LINK) }
            )}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={6}>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography variant="caption">
              {intl.formatMessage({
                id: 'containers.editDocument.pagesCount',
              })}
            </Typography>
            <Typography variant="body1">{numberOfPages}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption">
              {intl.formatMessage({
                id: 'containers.editDocument.partsCount',
              })}
            </Typography>
            <Typography variant="body1">{numberOfParts}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption">
              {intl.formatMessage({
                id: 'containers.editDocument.deletedCount',
              })}
            </Typography>
            <Typography variant="body1">{numberOfDeletedPages}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={confirmInProgress}
            onClick={e => setAnchorEl(e.currentTarget)}
            data-cy="document-edit-more-actions-btn"
            sx={{ p: '5px', minWidth: '36px' }}
          >
            <MoreVert />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() => {
                if (editState.config.initialPartsWithSuggestedSplits) {
                  dispatchEdit({
                    type: 'RESET',
                    newState: {
                      parts: editState.config.initialPartsWithSuggestedSplits,
                      previousState: editState,
                      config: editState.config,
                    },
                  });
                }
                setAnchorEl(null);
              }}
              data-cy="document-edit-show-split-suggestion-action"
              disabled={!haveSuggestedSplits}
            >
              {suggestedSplitsActive ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={!suggestedSplitsActive}>
                {intl.formatMessage({
                  id: 'containers.editDocument.showSplitSuggestion',
                })}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatchEdit({
                  type: 'RESET',
                  newState: {
                    parts: editState.config.initialParts,
                    previousState: editState,
                    config: editState.config,
                  },
                });
                setAnchorEl(null);
              }}
              data-cy="document-edit-without-split-suggestion-action"
              disabled={!haveSuggestedSplits}
            >
              {!suggestedSplitsActive ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={suggestedSplitsActive}>
                {intl.formatMessage({
                  id: 'containers.editDocument.withoutSplitSuggestion',
                })}
              </ListItemText>
            </MenuItem>
            {document && !disableDownloadOriginal && <Divider />}
            {document && !disableDownloadOriginal && (
              <MenuItem
                onClick={() => {
                  downloadOriginalDocuments({
                    documents: [document.url],
                    type: 'document',
                  });
                }}
                data-cy="document-edit-download-original-action"
              >
                <ListItemIcon>
                  <FileDownload />
                </ListItemIcon>
                {intl.formatMessage({
                  id: 'containers.editDocument.downloadOriginal',
                })}
              </MenuItem>
            )}
          </Menu>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onCancel()}
            data-cy="document-edit-cancel-btn"
            disabled={confirmInProgress}
          >
            {intl.formatMessage({
              id: 'containers.editDocument.cancel',
            })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm()}
            startIcon={
              confirmInProgress ? <CircularProgress size={18} /> : null
            }
            data-cy="document-edit-confirm-btn"
            disabled={confirmInProgress}
          >
            {intl.formatMessage({
              id: 'containers.editDocument.confirm',
            })}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
