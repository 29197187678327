import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { ArrowDropDownRounded } from '@rossum/ui/icons';
import {
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { truncate } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import CopyToClipboardButton from '../../../ui/copy-to-clipboard/CopyToClipboardButton';
import { useGetInbox } from '../../document-upload/hooks/useGetInbox';

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const QueueDetails = ({ activeQueue }: { activeQueue: Queue }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: inbox, isLoading } = useGetInbox(
    activeQueue.inbox ? getIDFromUrl(activeQueue.inbox) : 0
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="h5">{activeQueue.name}</Typography>
      <Tooltip
        title={intl.formatMessage({
          id: 'containers.allDocuments.queueDetails.button.tooltip',
        })}
      >
        <IconButton
          onClick={e => setAnchorEl(e.currentTarget)}
          color="secondary"
        >
          <ArrowDropDownRounded />
        </IconButton>
      </Tooltip>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack px={2}>
          {activeQueue.inbox ? (
            <DetailLine
              label={intl.formatMessage({
                id: 'containers.allDocuments.queueDetails.email.label',
              })}
              value={inbox?.email ?? '-'}
              isLoading={isLoading}
            />
          ) : null}

          <DetailLine
            label={intl.formatMessage({
              id: 'containers.allDocuments.queueDetails.queueId.label',
            })}
            value={activeQueue.id.toString()}
          />
        </Stack>
      </Menu>
    </Stack>
  );
};

const DetailLine = ({
  label,
  value,
  isLoading,
}: {
  label: string;
  value: string;
  isLoading?: boolean;
}) => {
  const isEmail = emailRegex.test(value);

  const parsedValue = isEmail
    ? value
        .split('@')
        .map((el, i) => (i === 0 ? truncate(el, { length: 30 }) : el))
        .join('@')
    : value;

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {label}
      </Typography>{' '}
      {isLoading ? (
        <Skeleton width={200} height={20} />
      ) : (
        <Typography variant="body2">{parsedValue}</Typography>
      )}
      <CopyToClipboardButton content={value.toString()} iconColor="secondary" />
    </Stack>
  );
};
