import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { DragIndicatorRounded } from '@rossum/ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SidebarFooterActionIcon } from '../../../features/annotation-view/document-sidebar/sidebar-footer/sidebar-footer-actions/SidebarFooterActionIcon';
import SortableWrapper from '../../Dnd/SortableWrapper';
import { FormValues } from './formValues';

type ActionsOrderControlProps = {
  control: Control<FormValues>;
};

export const ActionsOrderControl = ({ control }: ActionsOrderControlProps) => {
  const [draggedAction, setDraggedAction] = useState<
    (typeof value)[number] | null
  >(null);

  const intl = useIntl();

  const {
    field: { disabled, value, onChange },
  } = useController({ name: 'actionsOrder', control });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = value.findIndex(action => action === active.id);
      const overIndex = value.findIndex(action => action === over?.id);

      const reorderedColumns = arrayMove(value, activeIndex, overIndex);

      onChange(reorderedColumns);
    }
  };

  const renderAction = (
    action: (typeof value)[number],
    isOverlay?: boolean
  ) => (
    <SortableWrapper
      id={action}
      key={action}
      render={dragHandleProps => (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          component={Paper}
          elevation={isOverlay ? 4 : 0}
          sx={{
            boxShadow: 'none',
            userSelect: 'none',
            cursor: isOverlay ? 'grabbing' : 'grab',
          }}
          {...(dragHandleProps.listeners ?? {})}
          {...(dragHandleProps.attributes ?? {})}
        >
          <IconButton disableRipple>
            <DragIndicatorRounded />
          </IconButton>
          <Stack spacing={1} direction="row" alignItems="center">
            <SidebarFooterActionIcon actionId={action} fontSize="small" />
            <Typography variant="body2">
              {intl.formatMessage({
                id: `components.sidebarV2.actions.${action}`,
              })}
            </Typography>
          </Stack>
        </Stack>
      )}
    />
  );

  return (
    <FormControl>
      <FormLabel>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            variant="h6"
            color={disabled ? 'text.disabled' : 'text.primary'}
          >
            {intl.formatMessage({
              id: 'components.lineItemsSettings.sidebarActionsOrder.title',
            })}
          </Typography>
          <Typography
            variant="h6"
            color={disabled ? 'text.disabled' : 'text.secondary'}
          >
            {intl.formatMessage({
              id: 'components.lineItemsSettings.sidebarActionsOrder.subtitle',
            })}
          </Typography>
        </Stack>
      </FormLabel>
      <FormGroup>
        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={({ active }) => {
            setDraggedAction(
              value.find(action => action === active.id) ?? null
            );
          }}
          modifiers={[restrictToVerticalAxis]}
        >
          <Stack spacing={1}>
            <Box sx={{ width: '80%', pl: 6, pt: 1 }}>
              <Button
                variant="contained"
                color="primary"
                disabled
                sx={{ alignSelf: 'flex-start', width: '50%' }}
              >
                {intl.formatMessage({
                  id: 'components.sidebarV2.actions.confirm',
                })}
              </Button>
            </Box>
            <SortableContext items={value}>
              <Stack>{value.map(action => renderAction(action))}</Stack>
            </SortableContext>
          </Stack>
          <DragOverlay dropAnimation={defaultDropAnimation}>
            {draggedAction ? renderAction(draggedAction, true) : null}
          </DragOverlay>
        </DndContext>
      </FormGroup>
    </FormControl>
  );
};
