import * as Sentry from '@sentry/react';
type SentryInstance = typeof Sentry;

/* eslint-disable no-console */
export const initCSPReportingObserver = (sentry: SentryInstance) => {
  if (!('ReportingObserver' in window)) {
    return undefined;
  }

  const handleCSPViolation = (report: Report) => {
    const body = report.body?.toJSON();
    sentry.withScope(scope => {
      scope.setTag('blockedURL', body?.blockedURL || 'none');
      scope.setTag('sourceFile', body?.sourceFile || 'none');
      scope.setTag('effectiveDirective', body?.effectiveDirective || 'none');
      scope.setTag('referrer', body?.referrer || 'none');
      scope.setTag('lineNumber', body?.lineNumber || 'none');
      scope.setTag('columnNumber', body?.columnNumber || 'none');
      scope.setExtras({ report });
      sentry.captureMessage('CSP Violation Detected');
    });
  };

  return new ReportingObserver(
    reports => {
      reports.forEach(report => {
        if (report.type === 'csp-violation') {
          handleCSPViolation(report);
        }
      });
    },
    { types: ['csp-violation'], buffered: true }
  );
};
