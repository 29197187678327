import { LinearProgress, Stack } from '@rossum/ui/material';
import { useIntlDate } from '../../../i18n/useIntlDate';
import { getColor } from '../helpers';
import { LabeledValueInRow } from './LabeledValueInRow';
type Period = {
  beginDate: string;
  endDate: string;
};

type BillingPeriodProgressProps = {
  currentPeriod: Period;
  usedPercentage: number;
};

const BillingPeriodProgress = ({
  currentPeriod,
  usedPercentage,
}: BillingPeriodProgressProps) => {
  const { beginDate, endDate } = currentPeriod;
  const { formatDate } = useIntlDate();

  const usedColor = getColor(usedPercentage);

  return (
    <Stack direction="column" spacing={1}>
      <LinearProgress
        variant="determinate"
        color={usedColor}
        value={Math.min(usedPercentage, 100)}
        sx={{ height: theme => theme.spacing(3) }}
      />
      <Stack direction="row" width="100%" justifyContent="space-between">
        <LabeledValueInRow
          label="containers.billing.overview.started"
          labelColor="text.disabled"
          value={formatDate(beginDate)}
        />
        <LabeledValueInRow
          label="containers.billing.overview.ends"
          labelColor="text.disabled"
          value={formatDate(endDate)}
        />
      </Stack>
    </Stack>
  );
};

export { BillingPeriodProgress };
