import { Queue } from '@rossum/api-client/queues';
import { useSelector } from 'react-redux';
import { schemaLoadedSelector } from '../../../../redux/modules/schema/selectors';
import Loader from '../../../Loader';
import { AutomationPage } from './AutomationPage';

type Props = {
  selectedQueue: Queue;
  parentPath: string;
};

const AutomationDetail = ({ selectedQueue, parentPath }: Props) => {
  const schemaLoaded = useSelector(schemaLoadedSelector);

  if (!schemaLoaded) {
    return <Loader />;
  }

  return (
    <AutomationPage selectedQueue={selectedQueue} parentPath={parentPath} />
  );
};

export default AutomationDetail;
