import clsx from 'clsx';
import { connect } from 'react-redux';
import Find from '../../../components/icons/Find';
import Tooltip from '../../../components/UI/Tooltip/LazyTooltip';
import {
  DisplaySearchPanel,
  displaySearchPanel,
} from '../../../redux/modules/search/actions';
import { State } from '../../../types/state';
import styles from '../style.module.sass';

type DispatchProps = { displaySearchPanel: DisplaySearchPanel };
type StateProps = { active: boolean };
type Props = DispatchProps & StateProps;

const FindButton = ({ displaySearchPanel, active }: Props) => (
  <Tooltip
    data-cy="find-btn"
    id="components.documentValidation.controlPanel.find"
    values={{ meta: navigator.platform.includes('Mac') ? '⌘' : 'Ctrl' }}
  >
    <div
      onClick={() => displaySearchPanel(!active)}
      className={clsx(styles.FindButton, active && styles.FindButtonActive)}
    >
      <Find />
    </div>
  </Tooltip>
);

const mapStateToProps = ({ search: { shouldShow } }: State) => ({
  active: shouldShow,
});
const mapDispatchToProps = { displaySearchPanel };
export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(FindButton);
