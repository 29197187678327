import { endpoints } from '@rossum/api-client';
import { Organization } from '@rossum/api-client/organization';
import { Queue } from '@rossum/api-client/queues';
import { AnnotationListColumns } from '@rossum/api-client/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../lib/apiClient';
import { safeOrganizationSelector } from '../../../../redux/modules/organization/selectors';
import { updateQueueDetail } from '../../../../redux/modules/queues/actions';
import { queuesSelector } from '../../../../redux/modules/queues/selector';
import {
  QUERY_KEY_ORGANIZATION,
  useFetchOrganization,
} from '../../hooks/useFetchOrganization';
import { QUERY_KEY_USE_QUEUE_TABLE, useSetTableConfig } from './useTableConfig';

const MUTATION_KEY_USE_ORG_TABLE = 'organization-table-config';
const MUTATION_KEY_USE_QUEUE_TABLE = 'queue-table-config';

type Props = {
  activeQueue: Queue | null;
};

const updateQueueTable = (
  queue: Queue,
  updatedColumns: AnnotationListColumns
) =>
  update(queue, {
    settings: {
      annotationListTable: table =>
        update(table ?? { columns: [] }, { columns: { $set: updatedColumns } }),
    },
  });

const updateOrgTable = (
  organization: Organization,
  updatedColumns: AnnotationListColumns
) =>
  update(organization, {
    settings: {
      annotationListTable: table =>
        update(table ?? { columns: [] }, { columns: { $set: updatedColumns } }),
    },
  });

export const usePatchTableConfig = ({ activeQueue }: Props) => {
  const queryClient = useQueryClient();

  const queuesInRedux = useSelector(queuesSelector);

  const organizationId = useSelector(safeOrganizationSelector)?.id;

  const dispatch = useDispatch();

  const { data: organization } = useFetchOrganization({
    orgId: organizationId,
  });

  const { setOrganizationTable, setQueueTable } = useSetTableConfig();

  const { mutate: patchOrgTable } = useMutation({
    mutationKey: [MUTATION_KEY_USE_ORG_TABLE],

    mutationFn: ({
      orgId,
      payload,
    }: {
      orgId: number;
      payload: Organization['settings'];
    }) =>
      api.request(
        endpoints.organization.patch(orgId, {
          settings: payload,
        })
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ORGANIZATION],
      });
    },
  });

  const { mutate: patchQueueTable } = useMutation({
    mutationKey: [MUTATION_KEY_USE_QUEUE_TABLE],

    mutationFn: ({
      queueId,
      payload,
    }: {
      queueId: number;
      payload: Queue['settings'];
    }) =>
      api.request(
        endpoints.queues.patch(queueId, {
          settings: payload,
        })
      ),

    onSuccess: response => {
      const queueIdInRedux = queuesInRedux.find(
        queue => queue.id === response.id
      )?.id;

      // we have to update redux state so that the changes we do with RQ are not overridden if the user changes queue settings with redux somehwere else
      if (queueIdInRedux)
        dispatch(
          updateQueueDetail(queueIdInRedux, {
            settings: {
              annotationListTable: response.settings.annotationListTable,
            },
          })
        );
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_USE_QUEUE_TABLE],
      });
    },
  });

  // updateColumns will first optimistically update to reflect the changes immediately.
  // Otherwise the changes are reflected with delays decreasing the quality of UX.
  const updateColumns = useCallback(
    (updatedColumns: AnnotationListColumns) => {
      if (activeQueue) {
        setQueueTable(response => {
          if (!response) return response;

          return updateQueueTable(response, updatedColumns);
        });

        const { settings: payload } = updateQueueTable(
          activeQueue,
          updatedColumns
        );

        patchQueueTable({
          queueId: activeQueue.id,
          payload,
        });
      } else if (organization) {
        setOrganizationTable(response => {
          if (!response) return response;

          return updateOrgTable(response, updatedColumns);
        });

        const { settings: payload } = updateOrgTable(
          organization,
          updatedColumns
        );

        patchOrgTable({
          orgId: organization.id,
          payload,
        });
      }
    },
    [
      activeQueue,
      organization,
      patchOrgTable,
      patchQueueTable,
      setOrganizationTable,
      setQueueTable,
    ]
  );
  return { updateColumns };
};
