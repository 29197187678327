import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { schemaRuleSchema } from '../models/schemaRule.schema';
import { ruleListQuerySchema } from './list.schema';

export type RuleListQuery = PaginationQuery & {
  schema?: ID;
  ruleTemplate?: ID;
};

export const list = (query: RuleListQuery) => {
  return {
    endpoint: `/rules`,
    method: 'GET',
    responseSchema: listResponse(schemaRuleSchema),
    query,
    querySchema: ruleListQuerySchema,
  } as const;
};
