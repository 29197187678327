import { Typography } from '@rossum/ui/material';
import { OverviewGridRowModel } from '../../types/fields';

export const FieldId = () => {
  return ({ value }: { value?: OverviewGridRowModel['fieldId'] }) => (
    <Typography variant="subtitle2" color="text.secondary">
      {' '}
      {value ?? 'No field Id'}
    </Typography>
  );
};
