import { produce } from 'immer';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { setCreatingInboxAction, updateInboxFulfilled } from '../inbox/actions';
import { callHistoryMethod, locationChange } from '../utils';
import {
  clearValidationMessagesAction,
  setValidationMessagesAction,
} from './action';
import { ValidationMessagesState } from './types';

const initialState: ValidationMessagesState = {};

const validationMessagesReducer: Reducer<
  typeof initialState,
  RootActionType
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(setValidationMessagesAction): {
      return { ...state, [action.meta.formId]: action.payload };
    }

    case getType(clearValidationMessagesAction): {
      const { formId, inputId } = action.meta;

      return produce(state, draft => {
        if (draft[formId]) {
          delete draft[formId][inputId];
        }
      });
    }

    case getType(locationChange):
    case getType(callHistoryMethod):
    case getType(setCreatingInboxAction):
    case getType(updateInboxFulfilled):
      return initialState;
    default:
      return state;
  }
};

export default validationMessagesReducer;
