import {
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { enterCreateExtension } from '../../../../redux/modules/ui/actions';
import { ExtensionType } from '../../../../types/extensions';
import { State } from '../../../../types/state';
import { Users } from '../../../../types/users';
import CreateExtensionForm from './components/CreateExtension';

const isExtensionType = (type: string): type is ExtensionType =>
  [extensionWebhookType, extensionFunctionType].includes(type);

type StateProps = {
  defaultExtensionType: ExtensionType | undefined;
  organizationUsers: Users;
};

type DispatchProps = {
  onEnter: typeof enterCreateExtension;
};

type Props = StateProps & DispatchProps;

const CreateExtensionReduxWrapper = ({
  onEnter,
  defaultExtensionType,
  organizationUsers,
}: Props) => {
  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    onEnter();

    return () => {};
  }, [onEnter]);

  return (
    <CreateExtensionForm
      defaultExtensionType={defaultExtensionType}
      organizationUsers={organizationUsers}
    />
  );
};

const mapStateToProps = (state: State): StateProps => {
  const extensionTypeHash = state.router.location.hash;

  const maybeDefaultExtensionType =
    extensionTypeHash && extensionTypeHash.split('#')[1];

  const defaultExtensionType = isExtensionType(maybeDefaultExtensionType)
    ? maybeDefaultExtensionType
    : undefined;

  return {
    organizationUsers: state.users,
    defaultExtensionType,
  };
};

const mapDispatchToProps = {
  onEnter: enterCreateExtension,
};

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(CreateExtensionReduxWrapper);
