import { StackProps, Typography } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { sidebarConfidenceScoresEnabledSelector } from '../../../../../redux/modules/user/selectors';
import { VerticalContainer } from '../../shared/VerticalContainer';

type InactiveValueProps = StackProps & {
  value: string;
  confidence?: string;
  dense?: boolean;
};

export const InactiveValue = ({
  dense = false,
  value,
  confidence,
  ...stackProps
}: InactiveValueProps) => {
  const confidenceScoresEnabled = useSelector(
    sidebarConfidenceScoresEnabledSelector
  );

  const valueNode = (
    <Typography
      width="100%"
      variant="body2"
      color="text.primary"
      whiteSpace="pre-wrap"
      sx={{
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        msLineClamp: '2',
        MozLineClamp: '2',
        overflowWrap: 'anywhere',
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
      }}
    >
      {value || ' '}
    </Typography>
  );

  const confidenceNode =
    confidenceScoresEnabled && confidence ? (
      <Typography variant="caption" color="text.secondary">
        {confidence}
      </Typography>
    ) : null;

  return (
    <VerticalContainer dense={dense} {...stackProps} sx={{ pl: 2 }}>
      {valueNode}
      {confidenceNode}
    </VerticalContainer>
  );
};
