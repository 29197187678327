import { IconBell } from '@rossum/ui/icons/tabler';
import { Badge, IconButton, SvgIcon } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useBeamerContext } from '../../../beamer/BeamerProvider';
import { beamerSelector } from '../../../constants/config';

export const BeamerToggle = () => {
  const [notificationNumber, setNotificationNumber] = useState(
    window.Beamer?.notificationNumber
  );

  const { isBeamerEnabled } = useBeamerContext();

  useEffect(() => {
    if (isBeamerEnabled) {
      window.Beamer?.update({
        onopen: () => setNotificationNumber(0),
        callback: (count: number) => setNotificationNumber(count),
      });
    }
  }, [isBeamerEnabled]);

  if (!isBeamerEnabled) return null;

  return (
    <IconButton size="medium" color="secondary">
      <Badge
        color="primary"
        variant="dot"
        invisible={!notificationNumber}
        id={beamerSelector}
        data-cy="beamer-selector"
      >
        <SvgIcon fontSize="small">
          <IconBell />
        </SvgIcon>
      </Badge>
    </IconButton>
  );
};
