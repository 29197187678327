import { GridOn, List, VisibilityOff } from '@rossum/ui/icons';
import { alpha, Chip, Stack, Typography } from '@rossum/ui/material';
import { get } from 'lodash';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import scrollingSubject from '../lib/scrollingSubject';

export type PreviewProps = Partial<OriginalAnyDatapointSchema> &
  Pick<OriginalAnyDatapointSchema, 'label' | 'id'> & {
    closeCurrent?: () => void;
    inFooter?: boolean;
    isCurrentPath: (path: string[]) => boolean;
    path: string[];
    setCurrent?: (path: string[]) => void;
    toggleCurrent?: (path: string[]) => void;
  };

const MIN_WIDTH_IN_CHIP = '36px';

const Multivalue = ({
  id,
  label,
  children,
  path,
  isCurrentPath,
  toggleCurrent,
  inFooter,
  hidden,
}: PreviewProps) => {
  const isTable = get(children, 'category') === 'tuple';
  const expanded = isCurrentPath(path);

  const handleClick = () => {
    if (isTable && toggleCurrent) toggleCurrent(path);
    scrollingSubject.next(id);
  };

  return (
    <Stack
      onClick={handleClick}
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: '9px 16px',
        fontSize: theme => theme.typography.pxToRem(16),
        cursor: 'pointer',
        transition: 'color 0.1s ease-in-out',
        color: theme =>
          expanded
            ? theme.palette.common.white
            : alpha(theme.palette.text.primary, 0.7),
        backgroundColor: theme =>
          expanded ? theme.palette.primary.main : 'inherit',
        ...(!expanded && {
          '&:hover': {
            backgroundColor: theme =>
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.05)
                : alpha(theme.palette.common.black, 0.05),
          },
        }),
      }}
    >
      {!inFooter && (
        <Stack
          direction="row"
          sx={{
            flex: '0 1 auto',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
          {hidden && (
            <VisibilityOff
              sx={{
                fontSize: theme => theme.typography.pxToRem(17),
                marginLeft: '6px',
              }}
            />
          )}
        </Stack>
      )}
      <Chip
        label={
          isTable ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                color: theme =>
                  expanded ? theme.palette.common.white : 'inherit',
                minWidth: MIN_WIDTH_IN_CHIP,
              }}
            >
              <Typography
                sx={{ fontSize: theme => theme.typography.pxToRem(13) }}
              >
                {get(children, 'children.length')}
              </Typography>

              {/* FIXME: i have no idea why is this icon 1px below axis */}
              <GridOn
                fontSize="small"
                sx={{ position: 'relative', top: '-1px' }}
              />
            </Stack>
          ) : (
            <List fontSize="medium" />
          )
        }
      />
    </Stack>
  );
};

export default Multivalue;
