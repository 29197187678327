/* eslint-disable no-console */
import './polyfills';
import syncLocaleStorage from 'localstorage-vcs';
import {
  beamerProductId,
  beamerSelector,
  currentCodeVersion,
  sentryEnvironment,
  shouldSentry,
} from './constants/config';
import { localStorageVersion } from './constants/values';
import { checkStatus } from './lib/apiHelpers';
import { initCSPReportingObserver } from './lib/initCSPReportingObserver';
import { initSentry } from './logging/sentry';
import { throwError, throwInfo } from './redux/modules/messages/actions';
import { alertNetworkOutage } from './redux/modules/ui/actions';

export default store => {
  // Synchronize Local Storage
  syncLocaleStorage({
    version: localStorageVersion,
    remove: [
      'defaultQueries',
      'annotationsQuery',
      'annotationStack',
      'shouldHideBanner_v1',
      'shouldHideBanner',
    ],
  });

  // Sentry Initialization
  if (shouldSentry) {
    const sentry = initSentry();
    window.Sentry = sentry;

    if (sentryEnvironment?.includes(':prod')) {
      initCSPReportingObserver(sentry)?.observe();
    }
  }

  // https://www.getbeamer.com/docs
  window.beamer_config = {
    button: false,
    product_id: beamerProductId,
    selector: beamerSelector,
    counter: false,
    lazy: true,
  };

  // New version check
  setInterval(() => {
    fetch(`/code_version.json`)
      .then(checkStatus)
      .then(r => r.json())
      .then(({ code_version: latestCodeVersion }) => {
        if (currentCodeVersion !== latestCodeVersion) {
          store.dispatch(
            throwInfo('newVersion', {
              buttonFunction: () => window.location.reload(),
              buttonType: 'link',
              timeOut: 0,
              removeOnHover: false,
              dismissible: false,
            })
          );
        }
      })
      .catch(() => {
        store.dispatch(throwError('clientError'));
      });
  }, 300000);

  // Network stability check
  let isOnline = true;
  setInterval(() => {
    if (isOnline && !window.navigator.onLine) {
      store.dispatch(alertNetworkOutage());
    }
    if (!isOnline && window.navigator.onLine) {
      window.location.reload();
    }
    isOnline = window.navigator.onLine;
  }, 1000);
};
