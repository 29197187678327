import { IconButton, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardShortcut } from '../../../../../components/UI/KeyboardShortcut';
import { insertLine } from '../../../../../redux/modules/datapoints/actions';
import { datapointsSelector } from '../../../../../redux/modules/datapoints/selector';
import { TupleDatapointDataST } from '../../../../../types/datapoints';
import { useCanvasSelectionActions } from '../../../document-store/DocumentStore';
import { AddRowBelow } from '../icons/AddRowBelow';

type InsertLineProps = {
  onLineInserted: () => void;
  disabled?: boolean;
  tupleId: number;
};

export const InsertLine = ({
  tupleId,
  disabled,
  onLineInserted,
}: InsertLineProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const datapoints = useSelector(datapointsSelector);
  const { clearSelectedBboxes } = useCanvasSelectionActions();

  const tuple = datapoints.find(datapoint => datapoint.id === tupleId);

  const handleInsertLine = (insertedTuple: TupleDatapointDataST) => {
    dispatch(insertLine({ tuple: insertedTuple }));
    clearSelectedBboxes();
  };

  return tuple && tuple.category === 'tuple' ? (
    <Tooltip
      title={
        disabled ? (
          ''
        ) : (
          <KeyboardShortcut
            description={intl.formatMessage({
              id: 'components.documentValidation.validationDialog.button.insertLine.tooltip',
            })}
          />
        )
      }
    >
      <IconButton
        color="secondary"
        size="medium"
        data-cy="validation-dialog-insert-line"
        disabled={disabled}
        sx={{ p: 0.5, minWidth: 0 }}
        onClick={() => {
          handleInsertLine(tuple);
          onLineInserted?.();
        }}
      >
        <AddRowBelow />
      </IconButton>
    </Tooltip>
  ) : null;
};
