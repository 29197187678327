import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import { Check } from '@rossum/ui/icons';
import { Button, Card, Stack } from '@rossum/ui/material';
import { useCallback } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LineItemFormModel } from '../utils';
import { DatapointFieldControl } from './DatapointFieldControl';
type LineItemColumnFormProps = {
  control: Control<LineItemFormModel>;
  index: number;
  engineSchema: DedicatedEngineSchema;
  disabled?: boolean;
  onConfirm: (index: number) => void;
  onCancel: (index: number) => void;
  onDelete: (index: number) => void;
  mode: 'add' | 'edit';
};

const LineItemColumnForm = ({
  control,
  index,
  engineSchema,
  disabled,
  onConfirm,
  onCancel,
  onDelete,
  mode,
}: LineItemColumnFormProps) => {
  const intl = useIntl();

  const { isSubmitting, errors } = useFormState({
    control,
    name: `columns.${index}`,
  });

  const invalid = !!errors?.columns?.[index];

  const handleCancelOrDelete = useCallback(() => {
    if (mode === 'edit') {
      onCancel(index);
    } else {
      onDelete(index);
    }
  }, [index, mode, onCancel, onDelete]);

  return (
    <Card elevation={4} sx={{ p: 2, my: 1 }}>
      <Stack spacing={2}>
        <DatapointFieldControl
          ControllerProps={{
            control,
            nameMap: {
              label: `columns.${index}.label`,
              engineOutputId: `columns.${index}.engineOutputId`,
              type: `columns.${index}.type`,
              description: `columns.${index}.description`,
              sources: {
                __rootField: `columns.${index}.sources`,
                nameMap: (i: number) => ({
                  __rootField: `columns.${index}.sources.${i}`,
                  schemaId: `columns.${index}.sources.${i}.schemaId`,
                  queue: `columns.${index}.sources.${i}.queue`,
                }),
              },
            },
          }}
          disabled={disabled || isSubmitting}
          trainingQueues={engineSchema.content.trainingQueues}
          fieldType="lineItemColumn"
        />
        <Stack direction="row-reverse" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{ alignSelf: 'flex-start' }}
            disabled={invalid}
            onClick={() => onConfirm(index)}
            startIcon={<Check />}
          >
            {intl.formatMessage({
              id: 'components.lineItemColumnForm.buttons.submit',
            })}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleCancelOrDelete}
          >
            {intl.formatMessage({
              id: 'components.lineItemColumnForm.buttons.cancel',
            })}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export { LineItemColumnForm };
