import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { OrganizationGroupWorkspaceListQuery } from '@rossum/api-client/organizationGroups';
import { OrganizationGroupWorkspace } from '@rossum/api-client/organizationGroups';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { useOrganizationGroupUrl } from './useOrganizationGroupUrl';

const QUERY_KEY_WORKSPACES_PER_ORG_UNPAGINATED =
  'workspaces-per-organization-unpaginated';

export const useUnpaginatedWorkspacesPerOrg = (
  orgUrl: Url | undefined,
  orgGroupWorkspaceListQuery: OrganizationGroupWorkspaceListQuery = {}
): UseQueryResult<OrganizationGroupWorkspace[]> => {
  const orgGroupUrl = useOrganizationGroupUrl();

  return useQuery({
    queryKey: [
      QUERY_KEY_WORKSPACES_PER_ORG_UNPAGINATED,
      orgGroupUrl,
      orgUrl,
      orgGroupWorkspaceListQuery,
    ] as const,

    queryFn: ({
      queryKey: [, organizationGroupUrl, organizationUrl, query],
    }) =>
      organizationGroupUrl
        ? api.unpaginatedRequest(endpoints.organizationGroups.workspaces)({
            organization: organizationUrl
              ? getIDFromUrl(organizationUrl)
              : undefined,
            organizationGroupId: getIDFromUrl(organizationGroupUrl),
            ...query,
          })
        : api
            .unpaginatedRequest(endpoints.workspaces.list)(query)
            .then(result =>
              result.map(row => ({
                id: row.id,
                name: row.name,
                url: row.url,
                organization: null,
              }))
            ),
  });
};
