import { FontDownloadOutlined } from '@rossum/ui/icons';
import { FormattedMessage } from 'react-intl';
import {
  AUTOMATION_BUILT_IN_CHECKS,
  CONFIDENCE_THRESHOLDS_LINK,
} from '../../../../constants/values';
import { DatapointAutomationBlocker } from '../../../../redux/modules/annotation/types';
import { AnyDatapointDataST } from '../../../../types/datapoints';
import TooltipItem from '../../../DatapointTooltip/components/TooltipItem';
import styles from '../../style.module.sass';

type AutomationBlockersTooltipProps = {
  automationBlockers: DatapointAutomationBlocker[];
  data: AnyDatapointDataST;
};

const AutomationBlockerIcon = () => (
  <FontDownloadOutlined
    fontSize="small"
    sx={{
      fill: theme => theme.palette.error.main,
      mr: 0.7,
    }}
  />
);

export const AutomationBlockersTooltip = ({
  automationBlockers,
  data,
}: AutomationBlockersTooltipProps) => {
  const isMultivalue = data.category === 'multivalue';
  const isSimpleMultivalue = data.meta.isSimpleMultivalue || false;

  const countOfBlockers =
    isMultivalue && isSimpleMultivalue
      ? automationBlockers[0]?.samples?.length
      : automationBlockers.length;

  return isMultivalue ? (
    <span>
      <FormattedMessage
        id="components.documentValidation.automationBlockers.tooltip.multivalue"
        values={{ count: countOfBlockers }}
      />
    </span>
  ) : (
    <>
      {automationBlockers.map(({ type, samples }) => {
        // find the sample for this datapoint id
        const sample = samples.find(sample => sample?.datapointId === data.id);

        if (!sample) {
          return null;
        }

        switch (type) {
          case 'error_message': {
            const messageDetails = sample?.details?.messageContent || [];
            const messageContent: string = messageDetails[0] || '';
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey={`components.documentValidation.automationBlockers.tooltip.${type}`}
              >
                <span className={styles.TooltipMessageContent}>
                  {messageContent}
                </span>
              </TooltipItem>
            );
          }
          case 'failed_checks':
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey={`components.documentValidation.automationBlockers.tooltip.${type}`}
                helpLink={AUTOMATION_BUILT_IN_CHECKS}
              />
            );

          case 'low_score': {
            const score = sample?.details?.score;
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey={`components.documentValidation.automationBlockers.tooltip.${type}`}
                helpLink={CONFIDENCE_THRESHOLDS_LINK}
              >
                <span className={styles.ConfidenceScore}>
                  {score?.toFixed(3) ?? '---'}
                </span>
              </TooltipItem>
            );
          }

          case 'extension': {
            const content = sample?.details?.content?.[0];
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey={`components.documentValidation.automationBlockers.tooltip.${type}`}
              >
                {content ? (
                  <span className={styles.TooltipMessageContent}>
                    {content}
                  </span>
                ) : null}
              </TooltipItem>
            );
          }

          case 'no_validation_sources': {
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey={`components.documentValidation.automationBlockers.tooltip.${type}`}
              />
            );
          }

          default:
            return (
              <TooltipItem
                key={type}
                icon={<AutomationBlockerIcon />}
                localeKey="components.documentValidation.automationBlockers.tooltip.other"
              />
            );
        }
      })}
    </>
  );
};
