import { createReactComponent } from '@tabler/icons-react';

export const IconCustomCrystalBallCheck = createReactComponent(
  'outline',
  'customCrystalBallCheck',
  'CustomCrystalBallCheck',
  [
    [
      'path',
      {
        key: 'customCrystalBallCheck-path',
        d: 'M5.60838 14.1817C4.58804 13.2883 3.86444 12.105 3.53413 10.7897C3.20383 9.47432 3.28253 8.08953 3.75974 6.82009C4.23695 5.55065 5.08997 4.45693 6.20496 3.68491C7.31994 2.91289 8.64387 2.49928 10 2.49928C11.3562 2.49928 12.6801 2.91289 13.7951 3.68491C14.9101 4.45693 15.7631 5.55065 16.2403 6.82009C16.7176 8.08953 16.7963 9.47432 16.466 10.7897M9.16671 17.5H5.83337C5.39135 17.5 4.96742 17.3244 4.65486 17.0118C4.3423 16.6993 4.16671 16.2754 4.16671 15.8333C4.16671 15.3913 4.3423 14.9674 4.65486 14.6548C4.96742 14.3423 5.39135 14.1667 5.83337 14.1667H9.16671M9.16671 5.83333C8.50367 5.83333 7.86778 6.09672 7.39894 6.56556C6.9301 7.0344 6.66671 7.67029 6.66671 8.33333M12.5002 15.8334L14.1669 17.5001L17.5002 14.1667',
      },
    ],
  ]
);
