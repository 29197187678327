import { getIDFromUrl, Url } from '@rossum/api-client';
import { useHistory } from 'react-router';
import { useShallow } from 'zustand/react/shallow';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { DocumentDrawer } from '../../document/DocumentDrawer';
import { useDocumentStore } from '../document-store/DocumentStore';

export const ValidationDocumentDrawer = () => {
  const { currentDrawer, annotationId } = useDocumentStore(
    useShallow(({ currentDrawer, annotationId }) => ({
      currentDrawer,
      annotationId,
    }))
  );
  const closeDrawer = useDocumentStore(useShallow(state => state.closeDrawer));
  const openDrawer = useDocumentStore(useShallow(state => state.openDrawer));
  const history = useHistory();

  const onAnnotationDelete = (annotation: Url) => {
    if (getIDFromUrl(annotation) === annotationId) {
      // Deleting annotation itself via duplicate drawer
      // Go back in this case.
      const backArrowLink = getAnnotationBacklink();
      history.push(backArrowLink);
    }
  };

  return (
    <DocumentDrawer
      onClose={closeDrawer}
      selectedAnnotationId={annotationId}
      selectedAnnotationView={currentDrawer}
      onAnnotationDelete={onAnnotationDelete}
      handleSelectAnnotation={({ annotationUrl, view }) => {
        openDrawer({ drawer: view, annotationId: getIDFromUrl(annotationUrl) });
      }}
    />
  );
};
