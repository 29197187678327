import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Point2D } from '../../document-canvas/utils/geometry';

export type UseRectangleCreatorParams = {
  onRectangleCreated?: (startPoint: Point2D, endPoint: Point2D) => void;
};

export const useRectangleCreator = ({
  onRectangleCreated,
}: UseRectangleCreatorParams = {}) => {
  const [startPoint, setStartPoint] = useState<Point2D | undefined>(undefined);
  const [dragPoint, setDragPoint] = useState<Point2D | undefined>(undefined);

  const handleMouseMove = useCallback(
    (mouseEvent: MouseEvent) => {
      if (startPoint) {
        setDragPoint({ x: mouseEvent.clientX, y: mouseEvent.clientY });
      }
    },
    [startPoint]
  );

  const handleMouseUp = useCallback(
    (mouseEvent: MouseEvent) => {
      const endPoint = { x: mouseEvent.clientX, y: mouseEvent.clientY };
      setStartPoint(undefined);
      setDragPoint(undefined);

      if (startPoint && endPoint && onRectangleCreated) {
        onRectangleCreated(startPoint, endPoint);
      }
    },
    [onRectangleCreated, startPoint]
  );

  const handleMouseDown = useCallback(
    (mouseEvent: ReactMouseEvent<unknown, globalThis.MouseEvent>) => {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      const newStartPoint = { x: mouseEvent.clientX, y: mouseEvent.clientY };
      setStartPoint(newStartPoint);
      return newStartPoint;
    },
    []
  );

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();

      setStartPoint(undefined);
      setDragPoint(undefined);
    }
  }, []);

  const handleReset = useCallback(() => {
    setStartPoint(undefined);
    setDragPoint(undefined);
  }, []);

  // Event listeners
  useEffect(() => {
    if (startPoint) {
      document.addEventListener('visibilitychange', handleReset);
      window.addEventListener('focus', handleReset);
      window.addEventListener('blur', handleReset);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleReset);
      window.removeEventListener('focus', handleReset);
      window.removeEventListener('blur', handleReset);
    };
  }, [handleReset, startPoint]);

  useEffect(() => {
    if (startPoint) {
      document.addEventListener('keyup', handleKeyUp);
    }

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp, startPoint]);

  useEffect(() => {
    if (startPoint) {
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseUp, startPoint]);

  useEffect(() => {
    if (startPoint) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove, startPoint]);

  return {
    startPoint,
    dragPoint,
    handleMouseDown,
  };
};
