import { createReactComponent } from '@tabler/icons-react';

export const IconCustomAlignJustifiedCheck = createReactComponent(
  'outline',
  'customAlignJustifiedCheck',
  'CustomAlignJustifiedCheck',
  [
    [
      'path',
      {
        key: 'customAlignJustifiedCheck-path',
        d: 'M4 6H20M4 12H20M4 18H11.2M15 19.0001L17 21L21 17',
      },
    ],
  ]
);
