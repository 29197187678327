import { UniqueIdentifier } from '@dnd-kit/core';
import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaSimpleMultiValue,
  SchemaItem,
} from '@rossum/api-client/schemas';
import { Delete, Edit } from '@rossum/ui/icons';
import { Stack } from '@rossum/ui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as R from 'remeda';
import { v4 as uuidv4 } from 'uuid';
import {
  getDnDItems,
  isQuickActionDisabled,
  resolveCanExport,
} from '../helpers';
import { FieldsList, FieldsListProps } from './FieldsList';
import { SchemaFieldTileContent } from './SchemaFieldTileContent';

export type FieldChildrenProps = {
  parentId: string;
  data: SchemaItem[];
  onChildrenReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
  onQuickAction: (
    parentId: UniqueIdentifier,
    items: Record<
      UniqueIdentifier,
      {
        prop: 'hidden' | 'canExport' | 'required';
        value: boolean;
      }
    >
  ) => void;
  onItemDelete: (itemId: string) => void;
};

export const FieldChildren = ({
  parentId,
  data,
  onChildrenReorder,
  onQuickAction,
  onItemDelete,
}: FieldChildrenProps) => {
  const history = useHistory();
  const intl = useIntl();

  const childrenMap = useMemo(() => {
    return data.reduce<{
      [key: string]: SchemaItem;
    }>(
      (acc, field) => ({
        ...acc,
        [isSchemaSimpleMultiValue(field) ? field.children.id : field.id]: field,
      }),
      {}
    );
  }, [data]);

  const renderFieldItem: FieldsListProps['renderFieldItem'] = useCallback(
    (fieldId, isActive, isDragging, fieldParentId) => {
      const item = childrenMap[fieldId];

      if (!item) {
        return null;
      }

      const canExportValue = resolveCanExport(item);

      return (
        <SchemaFieldTileContent
          field={item}
          isActive={isActive}
          isDragging={isDragging}
          isFaded={!!item.hidden}
          quickActions={[
            {
              key: 'hidden',
              value: !item.hidden,
              disabled: false,
              onClick: () => {
                if (fieldParentId) {
                  onQuickAction(fieldParentId, {
                    [item.id]: { prop: 'hidden', value: !item.hidden },
                  });
                }
              },
            },
            {
              key: 'exported',
              value: canExportValue,
              disabled: isQuickActionDisabled(item),
              onClick: () => {
                if (fieldParentId) {
                  onQuickAction(fieldParentId, {
                    [item.id]: {
                      prop: 'canExport',
                      value: !canExportValue,
                    },
                  });
                }
              },
            },
            {
              key: 'required',
              value:
                isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                  ? !!item.constraints?.required
                  : false,
              disabled: isQuickActionDisabled(item),
              onClick: () => {
                if (fieldParentId) {
                  onQuickAction(fieldParentId, {
                    [item.id]: {
                      prop: 'required',
                      value:
                        isSchemaDatapoint(item) &&
                        !isSchemaDatapointButton(item)
                          ? !item.constraints?.required
                          : false,
                    },
                  });
                }
              },
            },
          ]}
          contextActions={[
            {
              key: 'delete',
              label: intl.formatMessage({
                id: 'features.queueSettings.fields.contextActions.deleteItem',
              }),
              icon: Delete,
              onClick: () => {
                if (fieldParentId) {
                  onItemDelete(item.id);
                }
              },
            },
            // TODO: How would we make this a link? (So that it can be Cmd opened in a new tab)
            {
              key: 'edit-json',
              label: intl.formatMessage({
                id: 'features.queueSettings.fields.contextActions.editJson',
              }),
              icon: Edit,
              onClick: () => {
                history.push(
                  `${R.pipe(history.location.pathname, R.split('/'), R.dropLast(3), R.join('/'))}/schema?id=${item.id}`,
                  {
                    backLink: history.location.pathname,
                  }
                );
              },
            },
          ]}
        />
      );
    },
    [childrenMap, history, intl, onItemDelete, onQuickAction]
  );

  const items = getDnDItems([
    {
      id: parentId,
      children: data,
    },
  ]);

  const newFieldPathForSection = useCallback(
    () => `${history.location.pathname}/${uuidv4()}`,
    [history.location.pathname]
  );

  const bulkActionsForSection = useCallback(
    (sectionId: UniqueIdentifier) => {
      const itemsInSection = items[sectionId];

      if (!itemsInSection) {
        return [];
      }

      return [
        {
          key: 'hidden',
          value: itemsInSection.every(itemId => !childrenMap[itemId]?.hidden),
          onClick: () =>
            onQuickAction(
              sectionId,
              R.fromEntries(
                R.map(
                  itemsInSection,
                  itemId =>
                    [
                      itemId,
                      {
                        prop: 'hidden' as const,
                        value: itemsInSection.every(
                          childItemId => !childrenMap[childItemId]?.hidden
                        ),
                      },
                    ] as const
                )
              )
            ),
        },
        {
          key: 'exported',
          value: itemsInSection
            .flatMap(itemId => {
              const item = childrenMap[itemId];

              return isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                ? item
                : [];
            })
            .every(item => item.canExport),
          onClick: () =>
            onQuickAction(
              sectionId,
              R.fromEntries(
                itemsInSection.map(itemId => [
                  itemId,
                  {
                    prop: 'canExport' as const,
                    value: !itemsInSection
                      .flatMap(childItemId => {
                        const item = childrenMap[childItemId];

                        return isSchemaDatapoint(item) &&
                          !isSchemaDatapointButton(item)
                          ? item
                          : [];
                      })
                      .every(item => item?.canExport),
                  },
                ])
              )
            ),
        },
        {
          key: 'required',
          value: itemsInSection
            .flatMap(itemId => {
              const item = childrenMap[itemId];

              return isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                ? item
                : [];
            })
            .every(item => item?.constraints?.required),
          onClick: () =>
            onQuickAction(
              sectionId,
              R.fromEntries(
                itemsInSection.map(itemId => [
                  itemId,
                  {
                    prop: 'required' as const,
                    value: !itemsInSection
                      .flatMap(childItemId => {
                        const item = childrenMap[childItemId];

                        return isSchemaDatapoint(item) &&
                          !isSchemaDatapointButton(item)
                          ? item
                          : [];
                      })
                      .every(item => item?.constraints?.required),
                  },
                ])
              )
            ),
        },
      ] as const;
    },
    [items, childrenMap, onQuickAction]
  );

  const handleChildrenReorder: FieldsListProps['onItemsReorder'] = useCallback(
    (from, to) => {
      onChildrenReorder(from, to);
    },
    [onChildrenReorder]
  );

  return (
    <Stack p={4} spacing={4}>
      <FieldsList
        items={items}
        renderFieldItem={renderFieldItem}
        onItemsReorder={handleChildrenReorder}
        sectionsLabels={{
          [parentId]: intl.formatMessage({
            id: 'features.queueSettings.fields.fields.title',
          }),
        }}
        newFieldPathForSection={newFieldPathForSection}
        bulkActionsForSection={bulkActionsForSection}
      />
    </Stack>
  );
};
