import { Serie } from '@rossum/api-client/annotations';
import clsx from 'clsx';
import { get } from 'lodash';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  colorErrorDark,
  colorErrorLight,
  colorPrimary,
  colorSuccess,
  colorWarning,
  gray,
  graySecondary,
  grayTertiary,
} from '../../../../components/Graphs/constants';
import LineGraph from '../../../../components/Graphs/LineGraph';
import styles from '../../../../components/Graphs/styles.module.sass';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { parse } from '../../../../lib/url';
import { State } from '../../../../types/state';
import { GranularityT } from '../Granularity';

type OwnProps = {
  intl: IntlShape;
  isTmpState: boolean;
  series: Array<Serie>;
};

const mapStateToProps = (
  {
    router: {
      location: { search },
    },
  }: State,
  { intl, isTmpState, series }: OwnProps
) => ({
  lines: [
    {
      key: 'imported' as const,
      color: isTmpState ? gray : colorPrimary,
      dotClassName: clsx(styles.BlueDot, isTmpState && styles.GrayDot),
    },
    {
      key: 'confirmed' as const,
      color: isTmpState ? grayTertiary : colorWarning,
      dotClassName: clsx(
        styles.YellowDot,
        isTmpState && styles.GradyDotTertiary
      ),
    },
    {
      key: 'exported' as const,
      color: isTmpState ? graySecondary : colorSuccess,
      dotClassName: clsx(
        styles.GreenDot,
        isTmpState && styles.GrayDotSecondary
      ),
    },
    {
      key: 'rejected' as const,
      color: isTmpState ? grayTertiary : colorErrorLight,
      dotClassName: clsx(
        styles.LightRedDot,
        isTmpState && styles.GradyDotTertiary
      ),
    },
    {
      key: 'deleted' as const,
      color: isTmpState ? grayTertiary : colorErrorDark,
      dotClassName: clsx(styles.RedDot, isTmpState && styles.GradyDotTertiary),
    },
  ],
  name: 'usage' as const,
  data: series.map(
    ({
      beginDate,
      endDate,
      values: {
        importedCount,
        exportedCount,
        deletedCount,
        confirmedCount,
        rejectedCount,
      },
    }) => ({
      date: dateFormatByGranularity(
        intl.locale,
        get(parse(search), 'groupBy') as GranularityT,
        beginDate,
        endDate
      ),
      imported: importedCount || 0,
      exported: exportedCount || 0,
      deleted: deletedCount || 0,
      confirmed: confirmedCount || 0,
      rejected: rejectedCount || 0,
    })
  ),
  showTooltip: !isTmpState,
  fullWidth: true,
});

export default injectIntl(connect(mapStateToProps)(LineGraph));
