import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RULES_QUERY_KEY } from './rulesQueryKeys';

export const useListRules = (query: {
  schema?: number;
  ruleTemplate?: number;
}) => {
  const api = useApiClient();

  return useQuery({
    queryKey: [RULES_QUERY_KEY, 'list', query],
    queryFn: () => api.unpaginatedRequest(endpoints.rules.list)(query),
  });
};
