import { Collapse } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { displayReviewIsNeededSelector } from '../../../../redux/modules/annotation/selectors';
import { ReviewNeeded } from './ReviewNeeded';

export const useReviewNeeded = () => {
  const displayReviewIsNeeded = useSelector(displayReviewIsNeededSelector);

  return (
    <Collapse in={displayReviewIsNeeded}>
      {displayReviewIsNeeded ? <ReviewNeeded /> : <span />}
    </Collapse>
  );
};
