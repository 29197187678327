import { createSelector } from 'reselect';
import { DEV_FEATURES_ENABLED, isEmbedded } from '../../../constants/config';
import { ProductTourName } from '../../../types/productTour';
import { State } from '../../../types/state';
import { isTrialSelector } from '../organization/selectors';
import { userSelector } from '../user/selectors';

export const tourCurrentStepIndexSelector =
  (tourName: ProductTourName) => (state: State) =>
    state.productTour !== null && state.productTour.tourName === tourName
      ? state.productTour.currentStepIndex
      : null;

export const anyOtherTourRunningSelector =
  (tourName: string) => (state: State) =>
    state.productTour !== null && state.productTour.tourName !== tourName;

export const shouldRunDataCaptureTourSelector = createSelector(
  userSelector,
  isTrialSelector,
  (state: State) => state.ui.editModeActive,
  (state: State) => state.router.location.hash,
  (currentUser, isTrial, editModeActive, routeHash) =>
    !isEmbedded() &&
    isTrial &&
    (DEV_FEATURES_ENABLED ||
      !currentUser.email ||
      !currentUser.email.endsWith('rossum.ai')) &&
    !routeHash.includes('editMode') &&
    !editModeActive
);

export const documentValidationIsLoadedSelector = (state: State) =>
  !!(
    state.datapoints.loaded &&
    state.datapoints.content.length &&
    state.schema.content &&
    state.annotation.status === 'reviewing' &&
    state.annotation.sideloads.pages.length > 0
  );
