import { Label } from '@rossum/api-client/labels';
import { IconCircleMinus } from '@rossum/ui/icons/tabler';
import { Chip, Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type LabelsListProps = {
  labels: Label[];
  onLabelDelete: (labelUrl: string) => void;
};

export const LabelsList = ({ labels, onLabelDelete }: LabelsListProps) => {
  const intl = useIntl();

  if (labels.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        {intl.formatMessage({
          id: 'components.sidebarV2.annotationData.labelsList.noLabels',
        })}
      </Typography>
    );
  }

  return (
    <Stack direction="row" spacing={1} useFlexGap sx={{ flexWrap: 'wrap' }}>
      {labels.map(label => (
        <Chip
          key={label.id}
          label={label.name}
          deleteIcon={
            <SvgIcon fontSize="small">
              <IconCircleMinus />
            </SvgIcon>
          }
          onDelete={() => onLabelDelete(label.url)}
          sx={{ alignSelf: 'flex-start' }}
        />
      ))}
    </Stack>
  );
};
