import { SchemaRuleAction } from '@rossum/api-client/schemas';
import { Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { visibleActions } from '../actions';

export const RuleActionIcons = ({
  ruleActions,
}: {
  ruleActions: SchemaRuleAction[];
}) => {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      alignItems="center"
      mx={8}
      spacing={1}
      color="text.secondary"
    >
      {ruleActions.map(action => {
        const schemaAction = action.action;
        const knownAction = schemaAction
          ? visibleActions.find(a => a.resolveAction(schemaAction))
          : undefined;
        const Icon = knownAction?.icon;
        return Icon ? (
          <Tooltip
            title={intl.formatMessage({
              id: `features.queueSettings.rules.actions.${knownAction.intlKey}.name`,
            })}
          >
            <Icon fontSize="small" color="inherit" />
          </Tooltip>
        ) : null;
      })}
    </Stack>
  );
};
