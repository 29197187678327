import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import {
  Divider,
  ListSubheader,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { boldText } from '../../../lib/formaterValues';
import { schemaFieldRefPrefix } from '../FormulaEditor/constants';
import { isValidFieldId } from '../FormulaEditor/helpers';
import { OnInsertCallback } from './insertion-helpers';
import { MenuItemContentField } from './MenuItemContentField';
import { menuListSharedProps } from './shared';

const MenuListFields = ({
  schemaItems,
  setFirstRef,
  onInsert,
  search,
}: {
  schemaItems: Array<SchemaSection | SchemaItem>;
  setFirstRef: React.MutableRefObject<HTMLLIElement | null>;
  onInsert: OnInsertCallback;
  search: string;
}) => {
  const intl = useIntl();

  return schemaItems.length ? (
    <MenuList {...menuListSharedProps}>
      {schemaItems
        .filter(({ id }) => (id ? isValidFieldId(id) : false))
        .map((schemaItem, index) => {
          if (schemaItem.category === 'section') {
            return [
              index !== 0 && (
                <Divider sx={{ pt: 1 }} key={`${schemaItem.id}-divider`} />
              ),
              <ListSubheader key={`${schemaItem.id}-subheader`}>
                {schemaItem.label}
              </ListSubheader>,
            ];
          }

          return (
            <MenuItem
              key={schemaItem.id}
              ref={index === 1 ? setFirstRef : undefined}
              onClick={() =>
                onInsert(`${schemaFieldRefPrefix}.${schemaItem.id}`, {
                  insertMode: 'inline',
                })
              }
            >
              <MenuItemContentField search={search} schemaField={schemaItem} />
            </MenuItem>
          );
        })}
    </MenuList>
  ) : (
    <Stack alignItems="center" p={2}>
      <Typography variant="body1" textAlign="center" color="text.primary">
        {intl.formatMessage({
          id: 'features.formulas.suggester.menuListFields.noResults.title',
        })}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {intl.formatMessage(
          {
            id: 'features.formulas.suggester.menuListFields.noResults.description',
          },
          {
            bold: boldText,
            search,
          }
        )}
      </Typography>
    </Stack>
  );
};

export { MenuListFields };
