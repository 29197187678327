import {
  defaultRadiuses,
  primaryColor,
  setBorderRadiuses,
  setPrimaryColors,
} from '@rossum/rossum-ui/theme';
import { combineEpics } from 'redux-observable';
import { combineLatest, from, merge, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  take,
  tap,
} from 'rxjs/operators';
import * as z from 'zod';
import { apiUrl } from '../../../constants/config';
import { collectionSize } from '../../../constants/values';
import { errorHandler } from '../../../lib/api';
import { parseJsonPreprocessor } from '../../../lib/parseJsonPreprocessor';
import {
  isNonEmptyString,
  isNotNullOrUndefined,
} from '../../../lib/typeGuards';
import { Organization } from '../../../types/organization';
import { fetchGroupsFulfilled } from '../groups/actions';
import { getRoleId } from '../groups/helpers';
import {
  MEMBERSHIP_ID,
  removeItems,
  setItem,
  storedUserData,
} from '../localStorage/actions';
import { fetchUserFulfilled } from '../user/actions';
import { deleteUserFulfilled, fetchAdminUsers } from '../users/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  fetchOrganizationAdminsFulfilled,
  fetchOrganizationFulfilled,
  setOrganizationColor,
  setOrganizationFont,
  setOrganizationRadiuses,
  updateOrganization,
  updateOrganizationFulfilled,
} from './actions';
import { loadFont, loadUnleashFlags } from './helpers';
import { organizationSelector } from './selectors';
import { FetchOrganizationAdminsPayload } from './types';

const assetsMismatchSchema = z.object({
  reloadCount: z.number(),
  failedUrl: z.string(),
  timeStamp: z.string(),
});

const fetchOrganizationEpic = makeEpic((action$, _state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchUserFulfilled)),
    mergeMap(user =>
      authGetJSON$<{ results: Organization[] }>(`${apiUrl}/organizations`).pipe(
        map(({ results }) => results[0]),
        filter(isNotNullOrUndefined),
        mergeMap(organization =>
          from(loadUnleashFlags(organization)).pipe(
            mergeMap(() => {
              const savedMembershipId = localStorage.getItem(MEMBERSHIP_ID);

              const currentMembershipId = `${organization.id}-${user.payload.email}`;

              if (window.Sentry) {
                window.Sentry.setTags({
                  'organization.id': organization.id,
                  'organization.name': organization.name,
                });

                const result = z
                  .preprocess(parseJsonPreprocessor, assetsMismatchSchema)
                  .safeParse(localStorage.getItem('assets_mismatch'));

                if (result.success && result.data.reloadCount > 0) {
                  window.Sentry.withScope(scope => {
                    scope.setExtras({
                      reloadCount: result.data.reloadCount,
                      failedUrl: result.data.failedUrl,
                    });
                    scope.captureMessage('Assets Mismatch Detected');
                  });

                  localStorage.removeItem('assets_mismatch');
                }
              }

              if (!savedMembershipId)
                return of(
                  removeItems(storedUserData),
                  fetchOrganizationFulfilled(organization),
                  setItem(MEMBERSHIP_ID, currentMembershipId)
                );
              if (savedMembershipId !== currentMembershipId)
                return of(
                  removeItems(storedUserData),
                  fetchOrganizationFulfilled(organization)
                );

              return of(fetchOrganizationFulfilled(organization));
            })
          )
        ),
        catchError(errorHandler)
      )
    )
  )
);

const fetchOrganizationAdminsEpic = makeEpic(
  (action$, state$, { authGetJSON$ }) =>
    combineLatest([
      merge(
        action$.pipe(filter(isActionOf(fetchOrganizationFulfilled))),
        action$.pipe(
          filter(isActionOf(deleteUserFulfilled)),
          pluck('meta'),
          filter((meta = {}) => 'role' in meta && meta.role === 'admin')
        ),
        action$.pipe(filter(isActionOf(fetchAdminUsers)))
      ),
      action$.pipe(filter(isActionOf(fetchGroupsFulfilled))),
    ]).pipe(
      mergeMap(() =>
        authGetJSON$<FetchOrganizationAdminsPayload>(`${apiUrl}/users`, {
          query: {
            groups: getRoleId('admin', state$.value.groups),
            pageSize: collectionSize,
            deleted: false,
          },
        }).pipe(map(fetchOrganizationAdminsFulfilled), catchError(errorHandler))
      )
    )
);

const updateOrganizationEpic = makeEpic((action$, state$, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateOrganization)),
    mergeMap(() => {
      const organization = organizationSelector(state$.value);

      return authPatch$<Organization>(
        `${apiUrl}/organizations/${organization.id}`,
        organization
      ).pipe(
        map(_organization => updateOrganizationFulfilled(_organization)),
        catchError(errorHandler)
      );
    })
  )
);

const setOrganizationFontEpic = makeEpic((_action$, state$) =>
  state$.pipe(
    map(state => organizationSelector(state)?.uiSettings?.branding?.font),
    filter(isNonEmptyString),
    take(1),
    tap(font => {
      document.body.style.fontFamily = font;
    }),
    tap(font => loadFont(font)),
    map(font => setOrganizationFont(font))
  )
);

const setOrganizationColorEpic = makeEpic((_, state$) =>
  state$.pipe(
    map(
      state =>
        organizationSelector(state)?.uiSettings?.branding?.colors?.primary
    ),
    distinctUntilChanged(),
    map(color => color || primaryColor),
    distinctUntilChanged(),
    tap(primary => setPrimaryColors(primary)),
    map(color => setOrganizationColor(color))
  )
);

const setOrganizationBorderRadiusesEpic = makeEpic((_, state$) =>
  state$.pipe(
    map(
      state => organizationSelector(state)?.uiSettings?.branding?.borderRadiuses
    ),
    distinctUntilChanged(),
    map(radiuses => radiuses || defaultRadiuses),
    distinctUntilChanged(),
    tap(radiuses => setBorderRadiuses(radiuses)),
    map(() => setOrganizationRadiuses())
  )
);

export default combineEpics(
  fetchOrganizationEpic,
  fetchOrganizationAdminsEpic,
  updateOrganizationEpic,
  setOrganizationFontEpic,
  setOrganizationColorEpic,
  setOrganizationBorderRadiusesEpic
);
