import WebFont from 'webfontloader';
import { Organization } from '../../../types/organization';
import { unleashClient } from '../../../unleash/Unleash';

export const loadFont = (fontFamily: string) => {
  const fonts = fontFamily
    .split(',')
    .filter(font => font !== 'Inter')
    .map(font => `${font.trim()}:400,700`);

  WebFont.load({
    google: {
      families: fonts,
    },
  });
};

export const loadUnleashFlags = (organization: Organization) =>
  unleashClient
    .updateContext({
      userId: organization.url,
    })
    .then(() => {
      const latestFeatureFlags = unleashClient
        .getAllToggles()
        .filter(featureFlag => featureFlag.enabled)
        .map(featureFlag => featureFlag.name)
        .sort();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'unleash feature flags for organization',
        organizationUrl: organization.url,
        flags: latestFeatureFlags,
      });

      return organization;
    });
