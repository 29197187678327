// Generated by ts-to-zod
import { z } from 'zod';

export const translateAnnotationPayloadSchema = z.object({
  granularity: z.literal('lines'),
  pageNumbers: z.array(z.number()),
  targetLanguage: z.string(),
});

export const translateAnnotationResponseSchema = z.object({
  results: z.array(
    z.object({
      pageNumber: z.number(),
      granularity: z.literal('lines'),
      items: z.array(
        z.object({
          position: z.tuple([z.number(), z.number(), z.number(), z.number()]),
          text: z.string(),
        })
      ),
    })
  ),
});
