import { camelCase, isNumber } from 'lodash';
import { push, replace } from 'redux-first-history';
import * as R from 'remeda';
import { defer, from, Observable, of, throwError, timer } from 'rxjs';
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import {
  catchError,
  concatAll,
  concatMap,
  delayWhen,
  map,
  pluck,
  retryWhen,
  scan,
} from 'rxjs/operators';
import { Action } from 'typesafe-actions';
import { getAnnotationBacklink } from '../components/AnnotationInformation/components/useAnnotationBacklink';
import { isEmbedded } from '../constants/config';
import { ErrorPageRouterState } from '../features/error-page';
import { logoutFromWorkflows } from '../features/workflows';
import { displayAnnotation } from '../redux/modules/annotation/actions';
import { signOut } from '../redux/modules/auth/actions';
import { setUnvalidatedContent } from '../redux/modules/datapoints/actions';
import { throwError as throwErrorMessage } from '../redux/modules/messages/actions';
import { repeateRequest } from '../redux/modules/repeatedRequests/actions';
import { setRepeatedStatus } from '../redux/modules/ui/actions';
import { RootActionType } from '../redux/rootActions';
import { createAuthJSONHeaders, report, withAuthorization } from './apiHelpers';
import { APIOptions, EpicDependencies } from './apiTypes';
import { camelToSnake, convertKeys } from './keyConvertor';
import { clearAuthToken, getAuthToken } from './token';
import { constructUrl } from './url';

type ErrorType = AjaxError;

/**
 * Try to retry requests which fail with HTTP status 0.
 * This is in general safe to do for GET requests,
 * and for other idempotent requests (PATCH should be safe, but it dependes on specific call).
 *
 * I believe the root cause for this errors is, that HTTP2 connection is reset every 100 requests (nginx configuration)
 * and some requests will end up with indeterminate state (we are not sure if the backend has processed them or not)
 */
export const retryConnectionError =
  <T>() =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      retryWhen((errors: Observable<AjaxError>) =>
        errors.pipe(
          concatMap((err, index) => {
            // Retry a connection error once.
            // There is no need for a delay, since we just want to ensure
            // that the repeated request will use a new connection
            if (index === 0 && err.status === 0) {
              return of(err);
            }
            return throwError(err);
          })
        )
      )
    );

export const retryExponentialBackoff =
  <T>() =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      retryWhen(errors =>
        errors.pipe(
          scan((retryCount, error) => {
            if (
              retryCount >= 3 ||
              !(error.status === 0 || error.status >= 500)
            ) {
              throw error;
            }
            return retryCount + 1;
          }, 0),
          delayWhen(retryCount => timer(500 * 2 ** retryCount))
        )
      )
    );

const handleNotFoundError = (error: ErrorType): Observable<RootActionType> => {
  if (error.status === 404) return notFoundErrorHandler();

  return throwError(error);
};

export const notFoundErrorHandler = () => {
  if (isEmbedded())
    return from([
      defer(() => clearAuthToken()),
      of(replace('/timeExpired')),
    ]).pipe(concatAll());

  return from(
    R.pipe(
      [
        !window.location.pathname.includes('/annotations') &&
          replace('/annotations'),
        throwErrorMessage('notFound'),
      ],
      R.filter(R.isTruthy)
    )
  );
};

const handleAuthError = (error: ErrorType): Observable<RootActionType> => {
  if (error.status === 401) {
    if (isEmbedded())
      return from([
        defer(() => {
          clearAuthToken();
          logoutFromWorkflows();
        }),
        of(replace('/timeExpired')),
      ]).pipe(concatAll());

    const token = getAuthToken();
    return from(
      R.pipe(
        [signOut({ handled: true }), token && throwErrorMessage('authError')],
        R.filter(R.isTruthy)
      )
    );
  }

  // user is not authorized for this action
  if (error.status === 403) {
    return from(
      R.pipe(
        [
          !isEmbedded() && replace('/annotations'),
          throwErrorMessage('authError'),
        ],
        R.filter(R.isTruthy)
      )
    );
  }

  if (error.status === 400) {
    return of(throwErrorMessage('validationError'));
  }

  // annotation timed out - may happen at many places
  if (error.status === 409) {
    const defaultListLocation = getAnnotationBacklink();
    const url = isEmbedded() ? '/timeExpired' : defaultListLocation;

    return from(
      R.pipe(
        [
          of(replace(url)),
          of(throwErrorMessage('timeOut')),
          isEmbedded() && defer(() => clearAuthToken()),
        ],
        R.filter(R.isTruthy)
      )
    ).pipe(concatAll());
  }

  return throwError(error);
};

class MaintenanceScreenError extends Error {
  constructor() {
    super('Redirecting to head');
  }
}

const handleServerError = (error: ErrorType): Observable<RootActionType> => {
  if (error.status >= 500 || error.status === 0) {
    // eslint-disable-next-line no-console
    console.log('Server Error', error);

    // Let us know every time someone falls on their head
    if (window.Sentry) {
      window.Sentry.captureException(new MaintenanceScreenError(), {
        originalException: error,
      });
    }

    return of(replace('/maintenance'));
  }
  return throwError(error);
};

const handleThrottleError = (error: ErrorType): Observable<RootActionType> => {
  if (error.status === 429) {
    const initialCountdown = parseInt(
      error.response?.headers?.get('Retry-After'),
      10
    );

    const throttleState: ErrorPageRouterState = {
      from: window.location.pathname,
      initialCountdown: Number.isNaN(initialCountdown)
        ? undefined
        : initialCountdown,
    };

    return of(push('/error?reason=too-many-requests', throttleState));
  }
  return throwError(error);
};

const handleOtherError = (error: ErrorType): Observable<RootActionType> => {
  if (isNumber(error.status)) {
    return from(
      R.pipe(
        [
          !isEmbedded() && replace('/annotations'),
          throwErrorMessage('clientError'),
        ],
        R.filter(R.isTruthy)
      )
    );
  }
  return throwError(error);
};

export const confirmErrorHandler =
  (url: string) =>
  (error: ErrorType): Observable<RootActionType> => {
    report(error);
    return from([
      throwErrorMessage('confirmError'),
      displayAnnotation({ url }),
    ]);
  };

export const errorHandler = (error: ErrorType): Observable<RootActionType> => {
  report(error);
  return handleServerError(error).pipe(
    catchError(handleAuthError),
    catchError(handleNotFoundError),
    catchError(handleThrottleError),
    catchError(handleOtherError)
  );
};

// handle HTTP errors from click API - we ignore 503s (timeouts) in the UI, not crashing the app
export const clickApiErrorHandler = (
  error: ErrorType
): Observable<RootActionType> => {
  // If Click API throws 503, just notify user and don't do anything else
  if (error.status === 503) {
    report(error);
    return from([throwErrorMessage('clickApiError')]);
  }

  // otherwise do regular error handling
  return errorHandler(error);
};

// Sometimes extensions can delete a DP between two requests, so we get a 404
// This is valid and we shouldn't crash in this case
export const datapointErrorHandlerIgnoring404 = (error: ErrorType) => {
  if (error.status === 404) {
    return of(setUnvalidatedContent(false));
  }

  return errorHandler(error);
};

export const tryRepeatRequestHandler =
  (
    url: string,
    // TODO rename here and in the action
    query: APIOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fulfilledAction: (payload: any) => Action,
    takeUntilActions: string[]
  ) =>
  (error: ErrorType) => {
    if (error.status === 0) {
      const timestamp = Date.now();
      return of(
        repeateRequest(timestamp, url, query, fulfilledAction, takeUntilActions)
      );
    }

    return throwError(error);
  };

export const repeatedRequestHandler =
  (repeateRequestId: number) => (error: ErrorType) => {
    if (error.status === 0) {
      report(error, { repeateRequestId, repeateStatus: 'failed' });
      return of(setRepeatedStatus('failed'));
    }
    return throwError(error);
  };

const asynchronousJavascriptAndCamel = <T>(
  request: AjaxRequest,
  excludeKeysFromConversion?: string[]
) =>
  ajax(request).pipe(
    pluck('response'),
    map<unknown, T>(convertKeys(camelCase, excludeKeysFromConversion))
  );

export const authGetBlob$ = (
  url: string,
  { getRawResponse, query, headers }: APIOptions = {}
) => {
  const request = {
    method: 'GET',
    url: constructUrl(url, query),
    headers: withAuthorization(headers),
    responseType: 'blob',
  };
  return getRawResponse
    ? ajax(request)
    : asynchronousJavascriptAndCamel(request);
};

export const authPostBlob$ = (
  url: string,
  { getRawResponse, query, headers }: APIOptions = {}
) => {
  const request = {
    method: 'POST',
    url: constructUrl(url, query),
    headers: withAuthorization(headers),
    responseType: 'blob',
  };
  return getRawResponse
    ? ajax(request)
    : asynchronousJavascriptAndCamel(request);
};

const ajaxRawResponse = <T>(request: AjaxRequest) =>
  ajax(request).pipe(pluck<AjaxResponse, T>('response'));

export const authGetJSON$: EpicDependencies['authGetJSON$'] = (
  url: string,
  {
    getRawResponse,
    query,
    headers,
    excludeKeysFromConversion = [],
  }: APIOptions = {}
) => {
  const request = {
    method: 'GET',
    url: constructUrl(url, query),
    responseType: 'json',
    headers: createAuthJSONHeaders(headers),
  };
  return getRawResponse
    ? ajaxRawResponse(request)
    : asynchronousJavascriptAndCamel(request, excludeKeysFromConversion);
};

export const authPost$: EpicDependencies['authPost$'] = (
  url,
  body,
  { getRawResponse, query, headers, excludeKeysFromConversion = [] } = {}
) => {
  const request = {
    method: 'POST',
    url: constructUrl(url, query),
    body: convertKeys(camelToSnake, excludeKeysFromConversion)(body),
    headers: createAuthJSONHeaders(headers),
  };
  return getRawResponse
    ? ajaxRawResponse(request)
    : asynchronousJavascriptAndCamel(request, excludeKeysFromConversion);
};

export const authPatch$: EpicDependencies['authPatch$'] = (
  url,
  body,
  { getRawResponse, query, headers, excludeKeysFromConversion = [] } = {}
) => {
  const request = {
    method: 'PATCH',
    url: constructUrl(url, query),
    body: convertKeys(camelToSnake, excludeKeysFromConversion)(body),
    headers: createAuthJSONHeaders(headers),
  };
  return getRawResponse
    ? ajaxRawResponse(request)
    : asynchronousJavascriptAndCamel(request, excludeKeysFromConversion);
};

export const authDelete$ = (
  url: string,
  { getRawResponse, query, headers }: APIOptions = {}
) => {
  const request = {
    method: 'DELETE',
    url: constructUrl(url, query),
    headers: createAuthJSONHeaders(headers),
  };
  return getRawResponse
    ? ajaxRawResponse(request)
    : asynchronousJavascriptAndCamel(request);
};

export const authPut$: EpicDependencies['authPut$'] = (
  url,
  body,
  { getRawResponse, query, headers } = {}
) => {
  const request = {
    method: 'PUT',
    url: constructUrl(url, query),
    body: convertKeys(camelToSnake)(body),
    headers: createAuthJSONHeaders(headers),
  };
  return getRawResponse
    ? ajaxRawResponse(request)
    : asynchronousJavascriptAndCamel(request);
};
