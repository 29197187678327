import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';

export const EMAIL_THREAD = 'emailThread';

export const useEmailThread = (emailThreadUrl: string | undefined) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [EMAIL_THREAD, emailThreadUrl] as const,

    queryFn: () => {
      if (!emailThreadUrl) {
        return Promise.reject(new Error('Email thread is undefined.'));
      }

      return api.request(
        endpoints.emailThreads.get(getIDFromUrl(emailThreadUrl))
      );
    },
    enabled: !!emailThreadUrl,
  });
};
