import {
  CircularProgress,
  CircularProgressProps,
  Stack,
  styled,
} from '@rossum/ui/material';

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 8,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(27, 25, 34, 0.8)'
      : 'rgba(255, 255, 255, 0.8)',
  zIndex: 1,
}));

const LoadingOverlay = ({ color, size }: CircularProgressProps) => {
  return (
    <Wrapper justifyContent="center" alignItems="center">
      <CircularProgress color={color} size={size} />
    </Wrapper>
  );
};

export { LoadingOverlay };
