import { snakeToCamel } from '@rossum/api-client/utils';
import {
  collapseClasses,
  Divider,
  dividerClasses,
  Stack,
} from '@rossum/ui/material';
import React, { Fragment, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResizablePane } from '../../../components/ResizablePane/ResizablePane';
import { DrawerConfig } from '../../../containers/DocumentValidation/ValidationEmailDrawer';
import UnableToAnnotate from '../../../containers/Sidebar/components/UnableToAnnotate';
import { assertNever } from '../../../lib/typeUtils';
import {
  createDatapoint,
  deleteAllDatapoints,
  deleteDatapointAndNavigate,
  selectDatapoint,
  updateDatapointValue,
  validate,
} from '../../../redux/modules/datapoints/actions';
import { datapointPathSelector } from '../../../redux/modules/datapoints/selector';
import { openPopup } from '../../../redux/modules/popup/actions';
import { addValueActiveSelector } from '../../../redux/modules/router/selectors';
import { readOnlySelector } from '../../../redux/modules/ui/selectors';
import { sidebarAutomationBlockersByDefaultVisibleSelector } from '../../../redux/modules/user/selectors';
import { Annotation } from '../../../types/annotation';
import { State } from '../../../types/state';
import { useDocumentStore } from '../document-store/DocumentStore';
import { useDeleteRecommendations } from './delete-recommendations/useDeleteRecommendations';
import { DocumentSidebarSkeleton } from './DocuemntSidebarSkeleton';
import { isEditableFormulaField } from './formula-helpers';
import { useReviewNeeded } from './review-needed/useReviewNeeded';
import { SidebarSectionTitle } from './shared/SidebarSectionTitle';
import { SidebarFooter } from './sidebar-footer/SidebarFooter';
import { AnnotationData } from './sidebar-infosections/annotation-data/AnnotationData';
import { AutomationBlockers } from './sidebar-infosections/automation-blockers/AutomationBlockers';
import { GlobalMessages } from './sidebar-infosections/GlobalMessages';
import { ButtonItem } from './sidebar-items/ButtonItem';
import { EnumItem } from './sidebar-items/EnumItem';
import { LineItemsItem } from './sidebar-items/LineItemsItem';
import { MultivalueItem } from './sidebar-items/MultivalueItem';
import { SimpleValueItem } from './sidebar-items/SimpleValueItem';
import {
  SIDEBAR_TOPBAR_HEIGHT,
  SidebarTopbar,
} from './sidebar-topbar/SidebarTopbar';
import {
  SidebarEnumFieldModel,
  SidebarMultivalueChildFieldModel,
  SidebarSimplevalueFieldModel,
  useSidebarData,
} from './useSidebarData';

type DocumentSidebarProps = {
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
  annotation: Annotation | undefined;
  loading?: boolean;
  noAccess?: boolean;
};

const DocumentSidebar = React.memo(
  ({
    onEmailThreadOpen,
    annotation,
    loading = false,
    noAccess = false,
  }: DocumentSidebarProps) => {
    const automationBlockersDefault = useSelector(
      sidebarAutomationBlockersByDefaultVisibleSelector
    );

    const automationBlockersExpanded = useDocumentStore(
      state => state.sidebarState.blockersVisible
    );

    const displayAutomationBlockers =
      !!automationBlockersDefault || automationBlockersExpanded;

    const sidebarData = useSidebarData(displayAutomationBlockers);

    const currentDatapointPath = useSelector(datapointPathSelector);

    const readOnly = useSelector(readOnlySelector);

    const addValueActive = useSelector(addValueActiveSelector);

    const loadingDatapoints = useSelector(
      (state: State) => state.datapoints.loadingDatapointIds
    );

    const deleteRecommendations = useDeleteRecommendations();

    const reviewNeeded = useReviewNeeded();

    const dispatch = useDispatch();

    const scrollableRef = useRef<HTMLDivElement | null>(null);

    const handleSelectDatapoint = useCallback(
      (id: number | null) => {
        if (typeof id === 'number') {
          return dispatch(selectDatapoint([id], { noTail: true }));
        }

        return dispatch(selectDatapoint([], { noTail: true }));
      },
      [dispatch]
    );

    const handleDatapointChange = useCallback(
      (
        item:
          | SidebarSimplevalueFieldModel
          | SidebarEnumFieldModel
          | SidebarMultivalueChildFieldModel,
        value: string
      ) => {
        dispatch(
          updateDatapointValue(
            {
              id: item.id,
              index: item.meta?.datapointIndex,
              oldValue: item.value,
              validationSource: 'human',
              reason: isEditableFormulaField(item.valueSource, item.editing)
                ? 'manual-formula-override-sidebar'
                : 'input-sidebar',
              noRecalculation:
                // we basically set only `true | undefined` in onChange here,
                // `false` AKA reconnecting the formula is handled elsewhere
                isEditableFormulaField(item.valueSource, item.editing) ||
                undefined,
            },
            value
          )
        );
      },
      [dispatch]
    );

    const handleButtonDatapointClick = useCallback(
      (id: number, popupUrl?: string | null, canObtainToken?: boolean) => {
        dispatch(
          popupUrl
            ? openPopup(popupUrl, canObtainToken === true)
            : validate([id], undefined, ['user_update', 'updated'])
        );
      },
      [dispatch]
    );

    const handleMultivalueAdd = useCallback(
      (parentIndex: number) => {
        dispatch(createDatapoint(parentIndex));
      },
      [dispatch]
    );

    const handleMultivalueDeleteChild = useCallback(
      (pathToDelete: Array<number>) => {
        // Only allow deleting multivalue children
        // TODO: This would be better done with just datapoint ID
        // Delete epic requires entire path, not just ID, so do this maybe when refactoring state mgmt
        if (pathToDelete.length === 3) {
          dispatch(deleteDatapointAndNavigate(pathToDelete));
        }
      },
      [dispatch]
    );

    const handleMultivalueDeleteAll = useCallback(
      (parentIndex: number) => {
        dispatch(deleteAllDatapoints(parentIndex));
      },
      [dispatch]
    );

    // navigating to next error
    const toggleMessages = useDocumentStore(
      state => state.sidebarActions.setMessagesVisibility
    );

    const handleGoToErrorMessage = useCallback(
      (id: number | 'all') => {
        if (typeof id === 'number') {
          handleSelectDatapoint(id);
        } else {
          handleSelectDatapoint(null);
          toggleMessages(true);
          scrollableRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      },
      [handleSelectDatapoint, toggleMessages]
    );

    return (
      <ResizablePane
        storageKey="annotation-view:sidebar-width"
        paneConfig={{ min: 400, max: 700, initial: 400 }}
      >
        <Stack
          sx={{
            width: '100%',
            borderRight: theme => `1px ${theme.palette.divider} solid`,
          }}
          data-tourstep="dataCaptureProductTour-sidebar"
        >
          {!noAccess ? (
            <SidebarTopbar
              loading={loading}
              scrollableRef={scrollableRef.current}
              annotation={annotation}
              onEmailThreadOpen={onEmailThreadOpen}
            />
          ) : null}
          <Stack
            id="sidebar-scrollable"
            ref={scrollableRef}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'background.paper',
              position: 'relative',
              overflowY: 'scroll',
              display: 'block',
              scrollMarginTop: `calc(${SIDEBAR_TOPBAR_HEIGHT}px + 1px)`,
              pt: theme =>
                `calc(${SIDEBAR_TOPBAR_HEIGHT}px + ${theme.spacing(1)})`,
              // do not render dividers after invisible sections
              [` .${collapseClasses.hidden} + .${dividerClasses.root}`]: {
                display: 'none',
              },
            }}
          >
            {loading ? (
              <DocumentSidebarSkeleton />
            ) : noAccess ? (
              // TODO: Move this here + refactor when removing old sidebar
              <UnableToAnnotate
                // @ts-expect-error Already has correct status because of `noAccess`
                // TODO: Fix this nonsense
                status={snakeToCamel(annotation?.status)}
                annotationMessages={annotation?.messages}
                restrictedAccess={!!annotation?.restrictedAccess}
              />
            ) : (
              <Stack divider={<Divider sx={{ mb: 1, mt: 1 }} />}>
                <AnnotationData annotation={annotation} />
                {deleteRecommendations}
                {reviewNeeded}
                <AutomationBlockers
                  handleSelectDatapoint={handleSelectDatapoint}
                />
                <GlobalMessages />
                {sidebarData.map(item => {
                  return (
                    <Fragment key={item.id}>
                      <SidebarSectionTitle title={item.label} />

                      {/* TODO: Get rid of annotation redux dependency for all items (type) */}
                      {item.children.map(child => {
                        const active = currentDatapointPath.includes(child.id);
                        const itemIsLoading = loadingDatapoints.includes(
                          child.id
                        );
                        switch (child.kind) {
                          case 'sidebar-simplevalue-field': {
                            return (
                              <SimpleValueItem
                                key={child.id}
                                sidebarScrollableRef={scrollableRef.current}
                                annotation={annotation}
                                item={child}
                                active={active}
                                disabled={readOnly}
                                onClick={handleSelectDatapoint}
                                onChange={handleDatapointChange}
                                loading={itemIsLoading}
                              />
                            );
                          }

                          case 'sidebar-button-field': {
                            return (
                              <ButtonItem
                                key={child.id}
                                item={child}
                                onClick={handleButtonDatapointClick}
                              />
                            );
                          }

                          case 'sidebar-enum-field': {
                            return (
                              <EnumItem
                                key={child.id}
                                sidebarScrollableRef={scrollableRef.current}
                                annotation={annotation}
                                item={child}
                                active={active}
                                disabled={readOnly}
                                onClick={handleSelectDatapoint}
                                onChange={handleDatapointChange}
                                loading={itemIsLoading}
                              />
                            );
                          }

                          case 'sidebar-multivalue-field': {
                            return (
                              <MultivalueItem
                                // using `active` to clear internal state of the component
                                // eg. batch selection
                                key={`${child.id}-${active}`}
                                sidebarScrollableRef={scrollableRef.current}
                                annotation={annotation}
                                item={child}
                                active={
                                  active &&
                                  currentDatapointPath.length === 2 &&
                                  !addValueActive
                                }
                                disabled={readOnly}
                                focused={active}
                                addValueActive={addValueActive}
                                onClick={handleSelectDatapoint}
                                onChange={handleDatapointChange}
                                onAddChild={handleMultivalueAdd}
                                onDeleteChild={handleMultivalueDeleteChild}
                                onDeleteAll={handleMultivalueDeleteAll}
                              />
                            );
                          }

                          case 'sidebar-line-item-field': {
                            return (
                              <LineItemsItem
                                key={child.id}
                                sidebarScrollableRef={scrollableRef.current}
                                annotation={annotation}
                                item={child}
                                disabled={readOnly}
                                active={active}
                                onClick={handleSelectDatapoint}
                              />
                            );
                          }

                          default: {
                            return assertNever(child);
                          }
                        }
                      })}
                    </Fragment>
                  );
                })}
              </Stack>
            )}
          </Stack>
          <SidebarFooter
            annotation={annotation}
            onEmailThreadOpen={onEmailThreadOpen}
            onNavigate={handleGoToErrorMessage}
          />
        </Stack>
      </ResizablePane>
    );
  }
);

DocumentSidebar.displayName = 'DocumentSidebar';

export { DocumentSidebar };
