import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { boolify } from '../../../constants/config';
import { UI } from '../../../types/ui';
import { RootActionType } from '../../rootActions';
import {
  confirmAnnotation,
  confirmAnnotationFulfilled,
  deleteAnnotation,
  displayAnnotation,
  postponeAnnotation,
  rejectAnnotationFulfilled,
  startAnnotation,
  startAnnotationFulfilled,
} from '../annotation/actions';
import {
  onDatapointSelection,
  recountDatapointPositionFulfilled,
  selectDatapoint,
  updateDatapointValue,
  updatePosition,
} from '../datapoints/actions';
import {
  acceptSuggestedOperationsAction,
  acceptSuggestedOperationsFulfilledAction,
} from '../datapoints/suggestedOperations/actions';
import {
  applyColumnsToAllGrids,
  applyGridToNextPages,
  createGrid,
  deleteAllGrids,
  deleteGrid,
  deleteGridFulfilled,
  pasteGrid,
  updateGridAction,
  updateGridAfterExtractAllRows,
  updateGridFulfilled,
} from '../grid/actions';
import { NAVIGATE_TO_NEXT } from '../localStorage/actions';
import {
  alertNetworkOutage,
  cancelEditMode,
  closeSelectMenu,
  fetchEmbeddedConfigFullfiled,
  hideBlockers,
  hideHints,
  leaveValidation,
  openSelectMenu,
  setApplicationTabVisibility,
  setGridActionInProgress,
  setNavigateToNext,
  setRepeatedStatus,
  showBlockers,
  showHints,
  startEditingDatapointValue,
  startEditMode,
  startValidation,
  stopEditingDatapointValue,
  toggleFooter,
} from './actions';

const NAVIGATE_TO_NEXT_CACHED = localStorage.getItem(NAVIGATE_TO_NEXT);

const SHOULD_NAVIGATE_TO_NEXT = NAVIGATE_TO_NEXT_CACHED
  ? boolify(localStorage.getItem(NAVIGATE_TO_NEXT))
  : true;

const initialState: UI = {
  applicationTabVisible: true,
  confirming: false,
  editModeActive: false,
  editingDatapointValue: false,
  embeddedConfig: undefined,
  fieldAutomationBlockersVisible: false,
  footerExpanded: false,
  // magicGridMultivalueId: undefined,
  navigateToNext: SHOULD_NAVIGATE_TO_NEXT,
  readOnly: false,
  repeatedRequestStatus: undefined,
  selectMenuIsOpen: false,
  shouldShowHints: true,
  showNetworkAlert: false,
  showRejectionModal: false,
  suggestingOperations: false,
  actionInProgress: false,
  editingBbox: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(startAnnotationFulfilled):
    case getType(leaveValidation):
    case getType(startValidation):
      return {
        ...state,
        actionInProgress: false,
        readOnly: false,
        fieldAutomationBlockersVisible: false,
        suggestingOperations: false,
      };

    case getType(confirmAnnotationFulfilled):
    case getType(postponeAnnotation):
    case getType(displayAnnotation):
    case getType(deleteAnnotation): {
      // skipRedirect doesn't exist in DISPLAY_ANNOTATION action
      return 'skipRedirect' in action.meta && action.meta.skipRedirect
        ? state
        : { ...state, readOnly: true, confirming: false };
    }

    case getType(updateDatapointValue):
    case getType(onDatapointSelection): {
      return {
        ...state,
        isSuggestingPositionsForValue: false,
      };
    }

    case getType(showHints):
      return { ...state, shouldShowHints: true };

    case getType(hideHints):
      return { ...state, shouldShowHints: false };

    case getType(startEditMode):
      return { ...state, editModeActive: true };

    case getType(cancelEditMode):
      return { ...state, editModeActive: false };

    case getType(setNavigateToNext):
      return { ...state, navigateToNext: action.payload };

    case getType(startEditingDatapointValue):
      return { ...state, editingDatapointValue: true };

    case getType(stopEditingDatapointValue):
      return { ...state, editingDatapointValue: false };

    case getType(toggleFooter):
      return { ...state, footerExpanded: !state.footerExpanded };

    case getType(openSelectMenu):
      return { ...state, selectMenuIsOpen: true };

    case getType(closeSelectMenu):
      return { ...state, selectMenuIsOpen: false };

    case getType(selectDatapoint): {
      return {
        ...state,
        selectMenuIsOpen: false,
        editingDatapointValue: false,
      };
    }

    case getType(alertNetworkOutage):
      return { ...state, showNetworkAlert: true };

    case getType(fetchEmbeddedConfigFullfiled):
      return { ...state, embeddedConfig: action.payload };

    case getType(confirmAnnotation):
      return { ...state, confirming: true };

    case getType(startAnnotation):
      return { ...state, confirming: false };

    case getType(setRepeatedStatus): {
      return { ...state, repeatedRequestStatus: action.payload };
    }

    case getType(setApplicationTabVisibility): {
      return { ...state, applicationTabVisible: action.payload };
    }

    case getType(acceptSuggestedOperationsAction): {
      return { ...state, suggestingOperations: true };
    }

    case getType(acceptSuggestedOperationsFulfilledAction): {
      return { ...state, suggestingOperations: false };
    }

    case getType(rejectAnnotationFulfilled):
      return { ...state, readOnly: true };

    case getType(showBlockers):
      return { ...state, fieldAutomationBlockersVisible: true, readOnly: true };

    case getType(hideBlockers): {
      return {
        ...state,
        fieldAutomationBlockersVisible: false,
        readOnly: action.meta.readOnly,
      };
    }
    case getType(updateGridAction):
      return { ...state, actionInProgress: action.meta.actionType || true };

    // TODO: Get rid of this when rewriting batch operations on grid
    case getType(setGridActionInProgress): {
      return { ...state, actionInProgress: action.payload };
    }
    case getType(createGrid):
    case getType(applyGridToNextPages):
    case getType(deleteGrid):
    case getType(deleteAllGrids):
    case getType(pasteGrid):
    case getType(applyColumnsToAllGrids):
    case getType(updateGridAfterExtractAllRows): {
      return { ...state, actionInProgress: true };
    }
    case getType(updateGridFulfilled):
    case getType(deleteGridFulfilled): {
      return { ...state, actionInProgress: false };
    }
    case getType(updatePosition): {
      return {
        ...state,
        editingBbox: true,
        isSuggestingPositionsForValue: false,
      };
    }
    case getType(recountDatapointPositionFulfilled): {
      return { ...state, editingBbox: false };
    }
    default:
      return state;
  }
};

export default reducer;
