import {
  getGridSingleSelectOperators,
  GridFilterInputValueProps,
  GridFilterItem,
} from '@rossum/ui/x-data-grid-pro';
import { partition } from 'remeda';
import { MultiSelectInput } from './MultiSelectInput';

const defaultOperator = 'isAnyOf';

export const queuesOperators = partition(
  getGridSingleSelectOperators(),
  operator => operator.value === defaultOperator
)
  .flat()
  .filter(operator => {
    return ![
      'equals',
      'is',
      'endsWith',
      'isEmpty',
      'isNotEmpty',
      'startsWith',
      'contains',
    ].includes(operator.value);
  })
  .map(operator => ({
    ...operator,
    getApplyFilterFnV7: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (values: Array<string>): boolean => {
        const value = Array.isArray(values) ? values.map(v => String(v)) : null;

        if (!value) {
          return true;
        }

        if (filterItem.operator === 'isAnyOf') {
          return value.some(el => filterItem.value.includes(el));
        }

        if (filterItem.operator === 'not') {
          return !value.some(el => filterItem.value.includes(el));
        }
        return false;
      };
    },

    InputComponent: (props: GridFilterInputValueProps) =>
      MultiSelectInput(props),
  }));
