import { Page } from '@rossum/api-client/pages';
import { parse as queryStringParse } from 'query-string';
import { unique } from 'remeda';
import { of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { z } from 'zod';
import { isEmbedded } from '../../../constants/config';
import { allTabStatuses } from '../../../containers/AnnotationList/helpers';
import {
  DataGridConditionModel,
  filteringSchema,
} from '../../../features/document-list-base/mql/mql';
import { camelToSnake, snakeToCamel } from '../../../lib/keyConvertor';
import { asArray, asScalar } from '../../../lib/url';
import { SnakeCaseStatus, Status } from '../../../types/annotationStatus';
import { parseAndValidate } from '../../../utils/jsonParse';
import { DOCUMENTS_QUERY_FOR_BROWSER_TAB } from '../localStorage/actions';
import { alertNetworkOutage, leaveValidation } from '../ui/actions';
import {
  confirmAnnotationFulfilled,
  deleteAnnotation,
  displayAnnotation,
  nextAnnotation,
  postponeAnnotation,
  rejectAnnotationFulfilled,
  startAnnotationFulfilled,
} from './actions';
import { AnnotationLoadMode } from './types';

export type AnnotationAction =
  | 'downloadFile'
  | 'downloadAndExport'
  | 'download'
  | 'upload'
  | 'validate'
  | 'reprocess'
  | 'reprocessConfirmed'
  | 'postpone'
  | 'move'
  | 'label'
  | 'delete'
  | 'purge'
  | 'reExtract'
  | 'addAttachment';

export const dataDisabled: Status[] = ['failedImport', 'importing'];

export const reprocessable: Status[] = ['failedExport'];

const reExtractable: Status[] = [
  'failedExport',
  'confirmed',
  'exported',
  'postponed',
  'toReview',
  'split',
  'rejected',
  'failedImport',
];
const purgeable: Status[] = ['deleted'];

const exportable: Status[] = ['confirmed'];

const deletable: Status[] = [
  'exported',
  'exporting',
  'failedExport',
  'failedImport',
  'importing',
  'postponed',
  'reviewing',
  'toReview',
  'rejected',
  'confirmed',
];

const attachable: Status[] = [
  'exported',
  'exporting',
  'failedExport',
  'inWorkflow',
  'toReview',
  'reviewing',
  'postponed',
  'rejected',
  'confirmed',
  'split',
];

const postponable: Status[] = ['toReview', 'reviewing', 'confirmed'];

export const reviewable: Status[] = [
  'toReview',
  'reviewing',
  'postponed',
  'confirmed',
];

export const isReExtractable = (status: Status): boolean =>
  reExtractable.includes(status);

export const isDeletable = (status: Status): boolean =>
  deletable.includes(status);

export const isPurgeable = (status: Status): boolean =>
  purgeable.includes(status);

export const isReprocessable = (status: Status): boolean =>
  reprocessable.includes(status);

export const isPostponable = (status: Status): boolean =>
  postponable.includes(status);

export const isReviewable = (status: Status): boolean =>
  reviewable.includes(status);

export const isSwichtableToExport = (status: Status): boolean =>
  exportable.includes(status);

// the condition for attachments and downloadability seem to share the same list of statuses, hence we use the same function.
export const canHaveAttachments = (status: Status): boolean =>
  attachable.includes(status);

export const handleAnnotationStatus =
  (id: number, mode: AnnotationLoadMode) => (error: XMLHttpRequest) => {
    // When the annotation is purged, pretend it does not exist.
    if (error.status === 409 && error.response.annotation_status === 'purged')
      return throwError({ status: 404 });

    if ([403, 409].includes(error.status))
      return of(displayAnnotation({ id, mode }));
    return throwError(error);
  };

type DataShapeT = {
  status: Status | SnakeCaseStatus;
  pages: Array<string | Page>;
  restrictedAccess: boolean;
};

export const hasDataAvailable = ({
  status,
  pages,
  restrictedAccess,
}: DataShapeT) => {
  const _status = snakeToCamel(status);

  return !(
    restrictedAccess === true ||
    dataDisabled.includes(_status) ||
    (status === 'deleted' && pages.length === 0)
  );
};

export const validationEndingActionCreators = [
  confirmAnnotationFulfilled,
  postponeAnnotation,
  deleteAnnotation,
  leaveValidation,
  nextAnnotation,
  rejectAnnotationFulfilled,
];

export const readOnlyModeEndingActionCreators = [
  leaveValidation,
  nextAnnotation,
  startAnnotationFulfilled,
  alertNetworkOutage,
];

const parseAllDocsQuery = (query: string): DataGridConditionModel => {
  const { filtering } = queryStringParse(query, { arrayFormat: 'none' });
  // try to parse stored query and validate it against schema
  // return default shape if it is not valid in catch
  return parseAndValidate(asScalar(filtering ?? ''), filteringSchema, {
    items: [],
  });
};

const filterStatuses = (status: string | string[] | null) =>
  reviewable
    .filter(_status => asArray(status).includes(_status))
    .map(camelToSnake);

type CombinedStatuses = { is: string[]; not: string[] };

const combineStatuses = (
  acc: CombinedStatuses,
  key: keyof CombinedStatuses,
  value: string
) => ({
  ...acc,
  [key]: unique(acc[key].concat(value).flat().map(snakeToCamel)),
});

const resolveStatusesForAllDocumentsDashboard = (): unknown[] => {
  // The value must be read from session storage, since the value in localStorage
  // can be affected by opening the dashboard in a different browser tab
  const cachedDashboardQuery =
    sessionStorage.getItem(DOCUMENTS_QUERY_FOR_BROWSER_TAB) || '';
  const parsed = parseAllDocsQuery(cachedDashboardQuery);

  const statusFilters = parsed.items.filter(({ field }) => field === 'status');

  // split statuses based on operators;
  // combine `is` and `isAnyOf` into `is` field
  // combine `not` and `notIn` into `not` field
  const statuses = statusFilters.reduce<CombinedStatuses>(
    (acc, cur) =>
      combineStatuses(
        acc,
        cur.operator === 'not' || cur.operator === 'notIn' ? 'not' : 'is',
        cur.value
      ),
    { is: [], not: [] }
  );

  // decide which statuses are visible on dashboard, in case statuses.is is an empty array, fallback to allTabStatuses
  // filter those statuses that are included in statuses.not and should be hidden on dashboard
  const statusesToFilter = (
    statuses.is.length ? statuses.is : allTabStatuses
  ).filter(_status => !statuses.not.includes(_status));

  return filterStatuses(statusesToFilter);
};

/**
 * The user starts reviewing annotation from a particular tab on the dashboard.
 * When starting an annotation (via /start or /next endpoint),
 * we need to ensure that the annotation still has the expected status
 * (status which belongs to that tab). Otherwise, the user can repeatedly review
 * annotation which was already postponed or moved to confirmed status.
 */
export const getReviewableStatusesFromAnnotationsQuery = () => {
  // The annotations query local storage is not valid for embedded mode
  if (isEmbedded()) return [];

  return resolveStatusesForAllDocumentsDashboard();
};

const missingAnnotationErrorSchema = z
  .object({
    annotations: z.record(z.array(z.string())),
  })
  .nullable()
  .catch(null);

export const getMissingAnnotationIndexes = (error: AjaxError) => {
  const parsedResponse = missingAnnotationErrorSchema.parse(error.response);
  if (!parsedResponse) return null;

  const missingAnnotationIndexes = Object.entries(parsedResponse.annotations)
    .filter(([_, value]) =>
      value.some(errorMessage => errorMessage.includes('Invalid hyperlink'))
    )
    .flatMap(([key]) =>
      !Number.isNaN(parseInt(key, 10)) ? parseInt(key, 10) : []
    );

  return missingAnnotationIndexes;
};
