import { Hook } from '@rossum/api-client/hooks';
import { ActionType, createAction } from 'typesafe-actions';
import { SelectedEventT } from '../../../containers/Extensions/DependenciesGraph/types';
import { ID } from '../../../types/basic';
import { ExtensionsView } from '../../../types/extensions';
import { Pagination } from '../../../types/pagination';

export type FetchExtensionsQuery = {
  page?: number;
  pageSize?: number;
  id?: number[];
  name?: string;
  queues?: string;
  events?: SelectedEventT;
  view?: ExtensionsView;
};

export const fetchExtensions = createAction(
  'FETCH_EXTENSIONS',
  undefined,
  (query: FetchExtensionsQuery) => ({
    query,
  })
)<undefined, { query: FetchExtensionsQuery }>();

export type FetchExtensionsFulfilledPayload = {
  results: Array<Hook>;
  pagination: Pagination;
};

export const fetchExtensionsFulfilled = createAction(
  'FETCH_EXTENSIONS_FULFILLED',
  (payload: FetchExtensionsFulfilledPayload) => payload
)<FetchExtensionsFulfilledPayload>();

export const clearExtensions = createAction('CLEAR_EXTENSIONS')<void>();

export const updateExtensionDetailFulfilled = createAction(
  'UPDATE_EXTENSION_DETAIL_FULFILLED',
  (payload: Hook) => payload
)<Hook>();

export const fetchExtensionDetail = createAction(
  'FETCH_EXTENSION_DETAIL',
  (extensionId: ID) => extensionId
)<ID>();

export const fetchExtensionDetailFulfilled = createAction(
  'FETCH_EXTENSION_DETAIL_FULFILLED',
  (payload: Hook) => payload
)<Hook>();

export const createExtensionFulfilled = createAction(
  'CREATE_EXTENSION_FULFILLED',
  (payload: Hook) => payload
)<Hook>();

export const deleteExtensionFulfilled = createAction(
  'DELETE_EXTENSION_FULFILLED'
)<void>();

export type ExtensionsActions = ActionType<
  | typeof fetchExtensions
  | typeof fetchExtensionsFulfilled
  | typeof clearExtensions
  | typeof updateExtensionDetailFulfilled
  | typeof fetchExtensionDetail
  | typeof fetchExtensionDetailFulfilled
  | typeof createExtensionFulfilled
  | typeof deleteExtensionFulfilled
>;
