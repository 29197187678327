import { ID } from '../../utils/codecUtils';
import { RuleTemplate } from '../models/ruleTemplate';
import { ruleTemplateSchema } from '../models/ruleTemplate.schema';
import { ruleTemplatePatchPayloadSchema } from './patch.schema';

export type RuleTemplatePatchPayload = Partial<RuleTemplate>;

export const patch = (
  ruleTemplateId: ID,
  payload: RuleTemplatePatchPayload
) => {
  return {
    endpoint: `/rule_templates/${ruleTemplateId}`,
    method: 'PATCH',
    responseSchema: ruleTemplateSchema,
    payloadSchema: ruleTemplatePatchPayloadSchema,
    payload,
  } as const;
};
