import { endpoints } from '@rossum/api-client';
import { HookPatchPayload } from '@rossum/api-client/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { updateExtensionDetailFulfilled } from '../../redux/modules/extensions/actions';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { QUERY_KEY_HOOKS } from './useHooks';

type HookPatchMeta = { withMessage?: boolean };

type HookMutationVariables = {
  hookId: number;
  payload: HookPatchPayload;
  meta?: HookPatchMeta;
};

export const usePatchHook = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ hookId, payload }: HookMutationVariables) =>
      api.request(endpoints.hooks.patch(hookId, payload)),

    onSuccess: (hook, { meta }) => {
      queryClient.invalidateQueries([QUERY_KEY_HOOKS]);

      if (meta?.withMessage) {
        dispatch(throwInfo('extensionUpdated'));
      }

      dispatch(updateExtensionDetailFulfilled(hook));
    },

    onError: () => {
      dispatch(throwError('extensionUpdateFailed'));
    },
  });
};
