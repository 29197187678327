import { getIDFromUrl } from '@rossum/api-client';
import { ErrorOutlineRounded } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { constructDocumentUrl } from '../../../lib/url';
import { clearAnnotationData } from '../../../redux/modules/annotation/actions';
import { startValidation } from '../../../redux/modules/ui/actions';
import { TransformedAnnotation } from '../hooks/useTransformAnnotation';
import { Filename } from './Filename';
import { StyledLink } from './StyledLink';
export const FILE_NAME_WIDTH = 165;
const CELL_PADDING = 20;

type SimpleFilenameRowContext = Pick<
  TransformedAnnotation,
  'status' | 'pages' | 'id' | 'hasAttachmentRelation'
>;

type SimpleFilenameCellValue = TransformedAnnotation['original_file_name'];

export const SimpleFilename = ({
  value: originalFilename,
  row,
  colDef,
  shouldShowAttachmentWarning = false,
}: GridRenderCellParams<SimpleFilenameRowContext, SimpleFilenameCellValue> & {
  shouldShowAttachmentWarning?: boolean;
}) => {
  const intl = useIntl();
  const filenameActionsDisabled =
    row.status === 'failedImport' ||
    row.status === 'importing' ||
    row.pages.length === 0;

  const { width } = colDef;
  const estimatedFilenameLength = width
    ? width - CELL_PADDING
    : FILE_NAME_WIDTH;

  const dispatch = useDispatch();
  const annotate = (url: string) => {
    dispatch(clearAnnotationData());
    return dispatch(startValidation(url, [getIDFromUrl(url)]));
  };
  const url = constructDocumentUrl({ id: row.id });

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {shouldShowAttachmentWarning && row.hasAttachmentRelation ? (
        <Tooltip
          title={intl.formatMessage({
            id: 'components.modal.addAttachments.disabledAnnotation.tooltip',
          })}
        >
          <ErrorOutlineRounded color="warning" fontSize="small" />
        </Tooltip>
      ) : null}
      <Tooltip title={originalFilename}>
        <StyledLink
          to={url}
          sx={{ pointerEvents: filenameActionsDisabled ? 'none' : 'inherit' }}
          onClick={e => {
            if (!e.metaKey) {
              e.preventDefault();
              annotate(url);
            }
          }}
        >
          <Filename
            status={row.status}
            disabled={filenameActionsDisabled}
            originalFileName={originalFilename}
            disableClipboard
            estimatedFilenameLength={estimatedFilenameLength}
          />
        </StyledLink>
      </Tooltip>
    </Stack>
  );
};
