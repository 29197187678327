import { useMutation } from '@tanstack/react-query';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useIntl } from 'react-intl';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';

export const useCreateDataset = () => {
  const intl = useIntl();
  return useMutation({
    mutationKey: ['@MDH', 'createDatasetApiV1DatasetDatasetNamePost'] as const,
    mutationFn: async ({
      datasetName,
      data,
    }: {
      datasetName: string;
      data: DSResultsResponseResultsItem[];
    }) => {
      return datasetApi
        .createDatasetApiV1DatasetDatasetNamePost(
          datasetName,
          {
            encoding: 'UTF-8',
            file: new Blob([JSON.stringify(data)], {
              type: 'application/json',
            }),
          },
          getDatasetRequestConfig()
        )
        .then(response => {
          return { taskId: getTaskIdFromResponse(response), datasetName };
        })
        .catch(err => {
          notify.error({
            title: intl.formatMessage(
              {
                id: 'features.datasets.context.taskNotification.create.error.title',
              },
              { datasetName }
            ),
          });
          throw err;
        });
    },
  });
};
