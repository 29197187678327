import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Annotation } from '@rossum/api-client/annotations';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { uniqueBy } from 'remeda';
import { useApiClient } from '../../../../lib/apiClient';

type UseRequestEmailsPayload = {
  annotation: Pick<Annotation, 'emailThread' | 'relatedEmails'>;
};

export const useInvalidateEmailsForAnnotation = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: ['workflows', 'emailsForAnnotation'],
    });
};

export const useEmailsForAnnotation = ({
  annotation,
}: UseRequestEmailsPayload) => {
  const api = useApiClient();
  const emails = useQuery({
    queryKey: ['workflows', 'emailsForAnnotation', annotation.emailThread],

    queryFn: async () => {
      const emailsPerThread = annotation.emailThread
        ? await api.unpaginatedRequest(endpoints.email.list)({
            emailThread: getIDFromUrl(annotation.emailThread),
          })
        : [];

      return uniqueBy(emailsPerThread, email => email.id);
    },
  });

  const emailIds = emails.data?.map(email => email.id);

  const documents = useQuery({
    queryKey: ['workflows', 'emailDocumentsForAnnotation', emailIds],

    queryFn: () => {
      if (!emailIds) throw new Error('Invalid query');
      return api.unpaginatedRequest(endpoints.documents.list)({
        email: emailIds,
        pageSize: emailIds.length,
      });
    },

    enabled: !!emailIds && emailIds.length > 0,
  });

  return { emails, documents };
};
