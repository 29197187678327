import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import { Engine } from '@rossum/api-client/engines';
import { HttpError } from '@rossum/api-client/errors';
import { Button, Chip, Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as R from 'remeda';
import { mapValues } from 'remeda';
import { z } from 'zod';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { restrictedEngineEditEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { ConnectedQueueTile } from '../components/ConnectedQueueTile';
import { EngineErrorAlert } from '../components/EngineErrorAlert';
import { EngineForm } from '../components/EngineForm';
import { ShowMoreButton } from '../components/ShowMoreButton';
import { FormSkeleton } from '../components/skeletons/FormSkeleton';
import { TilesListSkeleton } from '../components/skeletons/TilesListSkeleton';
import { TileLink } from '../components/TileLink';
import { TilesList } from '../components/TilesList';
import { TilesListEmptyState } from '../components/TilesListEmptyState';
import { ENGINE_FORM_ID } from '../constants';
import { EngineAddHeader } from '../headers/EngineAddHeader';
import { EngineDetailHeader } from '../headers/EngineDetailHeader';
import { useCreateEngine } from '../hooks/useCreateEngine';
import { useEngine } from '../hooks/useEngine';
import { useInfiniteQueueStatsForEngine } from '../hooks/useInfiniteQueueStatsForEngine';
import { usePatchEngine } from '../hooks/usePatchEngine';
import { useUnpaginatedEngineFields } from '../hooks/useUnpaginatedEngineFields';
import { engineDetailPath, engineFieldDetailPath } from '../paths';

const resolveErrorPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const errorSchema = z
      .object({
        name: z.array(z.string()),
        description: z.array(z.string()),
      })
      .partial();

    return errorSchema.catch({}).parse(error.data);
  }

  return {};
};

export const engineFormSchema = (intl: IntlShape) =>
  z.object({
    engine: z.object({
      name: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      description: z.string(),
    }),
  });

const useEngineForm = ({
  error,
  engine,
}: {
  error: unknown;
  engine?: Engine;
}) => {
  const intl = useIntl();
  return useForm<z.TypeOf<ReturnType<typeof engineFormSchema>>>({
    errors: {
      // TODO: Translate some common errors / add some F/E validation.
      engine: mapValues(resolveErrorPayload(error), apiError =>
        apiError ? { type: 'api_error', message: apiError[0] } : undefined
      ),
    },
    defaultValues: {
      engine: engine ?? {
        name: '',
        description: '',
      },
    },
    resolver: zodResolver(engineFormSchema(intl)),
  });
};

const AddFieldButton = ({ engineId }: { engineId: number }) => {
  const intl = useIntl();

  return (
    <Button
      component={Link}
      to={engineFieldDetailPath(engineId, 'new')}
      variant="contained"
      color="primary"
      data-cy="add-engine-field-button"
      sx={{
        '&:hover': { color: theme => theme.palette.primary.contrastText },
      }}
    >
      {intl.formatMessage({
        id: 'features.engines.engineDetail.addField',
      })}
    </Button>
  );
};

export const EngineAddPage = () => {
  const history = useHistory();
  const { mutate, error, isLoading: engineIsCreating } = useCreateEngine();

  const { control, handleSubmit } = useEngineForm({ error });

  return (
    <PageLayoutV2
      renderHeader={params => (
        <EngineAddHeader {...params} isSaving={engineIsCreating} />
      )}
    >
      <Stack
        px={4}
        py={8}
        spacing={8}
        id={ENGINE_FORM_ID}
        component="form"
        onSubmit={handleSubmit(({ engine }) => {
          mutate(
            { ...engine, type: 'extractor' },
            {
              onSuccess: engineData =>
                history.push(engineDetailPath(engineData.id)),
            }
          );
        })}
      >
        <EngineForm control={control} />
      </Stack>
    </PageLayoutV2>
  );
};

type EngineDetailContentProps = {
  engine: Engine;
};

const EngineDetailContent = ({ engine }: EngineDetailContentProps) => {
  const intl = useIntl();

  const {
    mutate,
    error,
    isLoading: engineIsPatching,
  } = usePatchEngine(engine.id);

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const {
    data: queueStats,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status: queuesInfoStatus,
  } = useInfiniteQueueStatsForEngine(engine.id);

  const { data: engineFieldsData, status: fieldsStatus } =
    useUnpaginatedEngineFields(
      {
        engine: getIDFromUrl(engine.url),
      },
      { select: engineFields => R.sortBy(engineFields, R.prop('name')) }
    );

  const { data: unusedEngineFields } = useUnpaginatedEngineFields(
    {
      engine: getIDFromUrl(engine.url),
      used: false,
    },
    {
      select: fields => new Set(fields.map(field => field.id)),
    }
  );

  const { control, handleSubmit } = useEngineForm({
    error,
    engine,
  });

  return (
    <PageLayoutV2
      renderHeader={params => (
        <EngineDetailHeader
          {...params}
          engine={engine}
          isSaving={engineIsPatching}
        />
      )}
    >
      <Stack
        px={4}
        py={4}
        spacing={4}
        id={ENGINE_FORM_ID}
        component="form"
        onSubmit={handleSubmit(formValues => {
          mutate(formValues.engine);
        })}
      >
        <EngineForm
          control={control}
          disabled={isRestrictedEngineEditEnabled}
        />

        <TilesList
          title={intl.formatMessage({
            id: 'features.engines.engineDetail.engineFields.title',
          })}
          items={engineFieldsData}
          renderTile={field => (
            <TileLink
              key={field.id}
              to={engineFieldDetailPath(engine.id, field.id)}
            >
              <Stack direction="row" spacing={4} alignItems="center">
                <Stack>
                  <Typography variant="h6">{field.label}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {field.name}
                  </Typography>
                </Stack>
                {unusedEngineFields?.has(field.id) && (
                  <Chip
                    color="warning"
                    label={intl.formatMessage({
                      id: 'features.engines.engineDetail.engineFields.unusedWarning',
                    })}
                  />
                )}
              </Stack>
            </TileLink>
          )}
          status={fieldsStatus}
          emptyState={
            <TilesListEmptyState
              title={intl.formatMessage({
                id: 'features.engines.engineDetail.engineFields.empty.title',
              })}
              subtitle={intl.formatMessage({
                id: 'features.engines.engineDetail.engineFields.empty.subtitle',
              })}
            >
              {isRestrictedEngineEditEnabled ? null : (
                <AddFieldButton engineId={engine.id} />
              )}
            </TilesListEmptyState>
          }
          buttons={[
            isRestrictedEngineEditEnabled ? null : (
              <AddFieldButton engineId={engine.id} key="add-engine-field" />
            ),
          ]}
        />
        <TilesList
          title={intl.formatMessage({
            id: 'features.engines.engineDetail.connectedQueues.title',
          })}
          items={queueStats.results}
          renderTile={({ queue, workspace, stats }) => (
            <ConnectedQueueTile
              key={queue.id}
              queue={queue}
              workspace={workspace}
              connectedFieldsCount={stats?.numberOfUsedEngineFields}
              totalFieldsCount={engineFieldsData?.length}
            />
          )}
          status={queuesInfoStatus}
          emptyState={
            <TilesListEmptyState
              title={intl.formatMessage({
                id: 'features.engines.engineDetail.connectedQueues.empty.title',
              })}
            />
          }
          buttons={
            hasNextPage
              ? [
                  <ShowMoreButton
                    key="show-more-results"
                    onClick={fetchNextPage}
                    isFetching={isFetching}
                  />,
                ]
              : []
          }
        />
      </Stack>
    </PageLayoutV2>
  );
};

export const EngineDetailPage = () => {
  const { engineId } = useParams<{ engineId: string }>();
  const engineIdNumber = parseInt(engineId, 10);
  const {
    data: engine,
    isLoading: engineIsLoading,
    isError,
  } = useEngine(engineIdNumber);

  if (isError) {
    return (
      <PageLayoutV2>
        <EngineErrorAlert />
      </PageLayoutV2>
    );
  }

  if (engineIsLoading) {
    return (
      <PageLayoutV2>
        <FormSkeleton />
        <TilesListSkeleton />
      </PageLayoutV2>
    );
  }

  if (!engine) {
    return null;
  }

  return <EngineDetailContent engine={engine} />;
};
