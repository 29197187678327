import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { unique } from 'remeda';
import { useApiClient } from '../../../lib/apiClient';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { Email } from '../api-client';

export const EMAIL_THREAD_ATTACHMENTS = 'emailThreadAttachments';

export const documentIdsForEmail = (email: Email): number[] =>
  unique([...(email.documents ?? []), ...(email.relatedDocuments ?? [])]).map(
    getIDFromUrl
  );

export const useEmailThreadDocuments = (emails: Email[]) => {
  const api = useApiClient();
  const emailIds = emails.map(email => email.id);
  return useQuery({
    queryKey: [EMAIL_THREAD_ATTACHMENTS, emailIds] as const,

    queryFn: async () => {
      const documents = await api.unpaginatedRequest(endpoints.documents.list)({
        ordering: '-attachment_status',
        email: emailIds,
      });

      const annotationIds = documents.flatMap(document => {
        const annotationUrl = document.annotations[0];
        return annotationUrl ? [getIDFromUrl(annotationUrl)] : [];
      });

      const annotations = await api.request(
        endpoints.annotations.list({
          id: annotationIds,
          pageSize: annotationIds.length,
        })
      );

      const annotationModifierIds = annotations.results.flatMap(annotation => {
        const { modifier } = annotation;
        return modifier ? [getIDFromUrl(modifier)] : [];
      });

      const annotationModifiers = await api.request(
        endpoints.users.list({
          id: annotationModifierIds,
          pageSize: annotationModifierIds.length,
        })
      );

      const attachments = documents.map(document => {
        const firstAnnotationUrl = document.annotations[0];
        const annotation = firstAnnotationUrl
          ? annotations.results.find(
              annotation => annotation.url === firstAnnotationUrl
            )
          : undefined;
        return {
          annotation: annotation
            ? {
                ...annotation,
                status: snakeToCamel(annotation.status),
              }
            : undefined,
          annotationModifier: annotation
            ? annotationModifiers.results.find(
                modifier => modifier.url === annotation.modifier
              )
            : undefined,
          document,
        };
      });

      return attachments;
    },
    enabled: emailIds.length > 0,
  });
};
