import { CircularProgress, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';
import { TemporaryMessageT } from './hooks';

export type EditorType = 'schema' | 'function';

type Props = {
  isValid: boolean;
  isChanged: boolean;
  editorType: EditorType;
  isPending: boolean;
  temporaryMessage: TemporaryMessageT | undefined;
  savingFailed: boolean;
};

const InfoMessage = ({
  isPending,
  isValid,
  isChanged,
  savingFailed,
  editorType,
  temporaryMessage,
}: Props) => {
  const [tempMessageKey, setTempMessageKey] = useState<
    'saved' | 'reverted' | ''
  >('');
  const [statusMessageKey, setStatusMessageKey] = useState<
    'notSaved' | 'noChanges'
  >(isChanged ? 'notSaved' : 'noChanges');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (temporaryMessage) {
      timeoutId = setTimeout(() => setTempMessageKey(''), 3000);
      setTempMessageKey(temporaryMessage.key);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [temporaryMessage]);

  useEffect(() => {
    setStatusMessageKey(
      isChanged ? ('notSaved' as const) : ('noChanges' as const)
    );
  }, [isChanged]);

  if (isPending && editorType !== 'schema') {
    return (
      <Stack
        direction="row"
        gap={1}
        data-cy={`extensions-${editorType}-pending-message`}
      >
        <CircularProgress size={20} />
        <FormattedMessage
          id={`components.editor.infoMessage.pending.${editorType}`}
        />
      </Stack>
    );
  }

  // isValid refers to the validation in the editor (frontend),
  // savingFailed refers to the result of saving (backend).
  // The message for isValid has priority, since the user needs to fix it first.
  return isValid ? (
    savingFailed ? (
      <div className={styles.InfoError}>
        <FormattedMessage id="components.editor.infoMessage.savingFailed" />
      </div>
    ) : (
      <div className={clsx(tempMessageKey && styles.InfoWhite)}>
        <FormattedMessage
          id={`components.editor.infoMessage.${
            tempMessageKey || statusMessageKey
          }`}
        />
      </div>
    )
  ) : (
    <div className={styles.InfoError}>
      <FormattedMessage
        id={`components.editor.infoMessage.containsErrors.${editorType}`}
      />
    </div>
  );
};

export default InfoMessage;
