import { every, flow, get, isNumber, last, take } from 'lodash';
import React, { Component, ComponentType } from 'react';
import { connect, MapDispatchToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { NEVER, Observable, Subscription } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { ObservableContext } from '../../components/ObservableProvider';
import { FOCUS_CHANGE_DEBOUNCE } from '../../constants/values';
import {
  getEventPath,
  isElementPresent,
  isInModal,
  prevent,
} from '../../lib/DOM';
import {
  A_KEY_CODE,
  ARROW_DOWN_CODE,
  ARROW_LEFT_CODE,
  ARROW_RIGHT_CODE,
  ARROW_UP_CODE,
  BACKSPACE_CODE,
  DELETE_CODE,
  ENTER_CODE,
  ESC_CODE,
  F_KEY_CODE,
  filterKeys,
  TAB_CODE,
} from '../../lib/keyboard';
import { shouldComponentUpdateDeepEqual } from '../../lib/shouldComponentUpdateDeepEqual';
import { getDatapointPathFromSearch } from '../../lib/url';
import {
  CreateDatapoint,
  createDatapoint,
  DeleteDatapointAndNavigate,
  deleteDatapointAndNavigate,
  insertLine,
  NextColumn,
  nextColumn,
  NextDatapoint,
  nextDatapoint,
  NextRow,
  nextRow,
  NextUnvalidatedDatapoint,
  nextUnvalidatedDatapoint,
  PreviousColumn,
  previousColumn,
  PreviousDatapoint,
  previousDatapoint,
  PreviousRow,
  previousRow,
} from '../../redux/modules/datapoints/actions';
import { findDatapointById } from '../../redux/modules/datapoints/navigation/findDatapointIndex';
import {
  currentMultivalueDatapointSelector,
  getCurrentTuple,
  isDeleteRecommendationInPathSelector,
} from '../../redux/modules/datapoints/selector';
import { isFieldSelectable } from '../../redux/modules/schema/helpers';
import { schemaMapSelector } from '../../redux/modules/schema/schemaMapSelector';
import {
  DisplaySearchPanel,
  displaySearchPanel,
  NextSearchResult,
  nextSearchResult,
  PreviousSearchResult,
  previousSearchResult,
} from '../../redux/modules/search/actions';
import {
  closeSelectMenu,
  startEditingDatapointValue,
  stopEditingDatapointValue,
} from '../../redux/modules/ui/actions';
import { TupleDatapointDataST } from '../../types/datapoints';
import { State } from '../../types/state';

type Condition = {
  alt?: boolean;
  editMode?: boolean;
  editbox?: boolean;
  footer?: boolean;
  fromFocused?: boolean;
  meta?: boolean;
  mgSelect?: boolean;
  readonly?: boolean;
  search?: boolean;
  select?: boolean;
  shift?: boolean;
  blockedByDeleteRecommendation?: boolean;
};

type DispatchProps = {
  closeSelectMenu: typeof closeSelectMenu;
  createDatapoint: CreateDatapoint;
  deleteDatapoint: DeleteDatapointAndNavigate;
  nextColumn: NextColumn;
  nextDatapoint: NextDatapoint;
  nextRow: NextRow;
  nextSearchResult: NextSearchResult;
  nextUnvalidatedDatapoint: NextUnvalidatedDatapoint;
  previousColumn: PreviousColumn;
  previousDatapoint: PreviousDatapoint;
  previousRow: PreviousRow;
  previousSearchResult: PreviousSearchResult;
  displaySearchPanel: DisplaySearchPanel;
  startEditingDatapointValue: typeof startEditingDatapointValue;
  stopEditingDatapointValue: typeof stopEditingDatapointValue;
  insertLine: typeof insertLine;
};

type StateProps = {
  addValueFocused: boolean;
  canAddDatapoint: boolean;
  canRemoveDatapoint: boolean;
  currentDatapointType: string;
  currentMultivalueIndex: number | undefined;
  datapointPath: Array<number>;
  editMode: boolean;
  editingDatapointValue: boolean;
  enableRowNavigation: boolean;
  isMultivalueFooter: boolean;
  multivalueChildPath: Array<number> | false;
  readOnly: boolean;
  selectMenuIsOpen: boolean;
  shouldDisableHandlers: boolean;
  showSearchPanel: boolean;
  isDeleteRecommendation: boolean;
  productTourActive: boolean;
  currentTupleDatapoint: TupleDatapointDataST | undefined;
};

type OwnProps = {
  Wrapped?: ComponentType<Props>;
} & RouteComponentProps;

type Props = StateProps & OwnProps & DispatchProps;

const inCase =
  (bool: boolean | null | undefined) =>
  (getVal: () => boolean): boolean =>
    bool === true
      ? getVal() === true
      : bool === false
        ? getVal() === false
        : true;

class ValidationKeyboardHandler extends Component<Props> {
  static contextType = ObservableContext;

  declare context: React.ContextType<typeof ObservableContext>;

  shouldComponentUpdate(nextProps: Props, nextState: unknown): boolean {
    return shouldComponentUpdateDeepEqual(this, nextProps, nextState);
  }

  componentDidMount() {
    this.addAllListeners();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (!this.props.shouldDisableHandlers && props.shouldDisableHandlers) {
      this.removeAllListeners();
    }
    if (this.props.shouldDisableHandlers && !props.shouldDisableHandlers) {
      this.addAllListeners();
    }
  }

  componentWillUnmount() {
    this.removeAllListeners();
  }

  onAddDatapoint: Subscription | null = null;

  onEnter: Subscription | null = null;

  onEscape: Subscription | null = null;

  onFind: Subscription | null = null;

  onNextRow: Subscription | null = null;

  onNextSubscription: Subscription | null = null;

  onPreviousRow: Subscription | null = null;

  onPrevSubscription: Subscription | null = null;

  onRemoveDatapoint: Subscription | null = null;

  onSearchEnter: Subscription | null = null;

  onSearchNextSubscription: Subscription | null = null;

  onSearchPrevSubscription: Subscription | null = null;

  onTab: Subscription | null = null;

  onShiftTab: Subscription | null = null;

  onShiftDownSubscription: Subscription | null = null;

  onShiftUpSubscription: Subscription | null = null;

  onPreviousColumn: Subscription | null = null;

  onNextColumn: Subscription | null = null;

  onEnterOnFooter: Subscription | null = null;

  onEnterOnFooterSelect: Subscription | null = null;

  onDatapointConfirm: Subscription | null = null;

  arrowsSubscription: Subscription | null = null;

  closeSelectSubscription: Subscription | null = null;

  // don't listen to keydowns when MUI modal is open
  getFilteredKeydownObserver = () => {
    if (!this.context) {
      throw new Error('ObservableContext not provided.');
    }

    return this.context.onKeyDownObserver.pipe(
      filter(
        e =>
          !isInModal(e) &&
          !(
            this.props.productTourActive &&
            // PERF:
            // This selector was run on each keydown, and it's quite expensive,
            // especially in huge documents. It must be executed
            // only when necessary (when a product tour is active)
            document.querySelector('[id^=react-joyride-step]')
          )
      )
    );
  };

  addAllListeners = () => {
    const filteredKeydownObserver = this.getFilteredKeydownObserver();

    this.aDownObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([A_KEY_CODE])),
      filter(this.condition({ editMode: false }))
    );

    this.enterObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ENTER_CODE])),
      filter(this.condition({ mgSelect: false }))
    );

    this.escapeObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ESC_CODE]))
    );

    this.tabDownObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([TAB_CODE])),
      filter(this.condition({ editMode: false }))
    );

    this.fDownObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([F_KEY_CODE]))
    );

    this.removingKeysDownObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([DELETE_CODE, BACKSPACE_CODE]))
    );

    this.arrowUpObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ARROW_UP_CODE])),
      filter(() => !isElementPresent('.react-select__menu')),
      filter(() => !isElementPresent('.MuiAutocomplete-listbox'))
    );

    this.arrowDownObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ARROW_DOWN_CODE])),
      filter(() => !isElementPresent('.react-select__menu')),
      filter(() => !isElementPresent('.MuiAutocomplete-listbox'))
    );
    this.arrowLeftObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ARROW_LEFT_CODE]))
    );
    this.arrowRightObserver = filteredKeydownObserver.pipe(
      filter(filterKeys([ARROW_RIGHT_CODE]))
    );

    this.editboxConfirmObserver = this.enterObserver.pipe(
      filter(
        this.condition({
          editbox: true,
          alt: false,
          shift: false,
          search: false,
          footer: true,
          editMode: false,
        })
      )
    );

    this.footerNavigation();
    this.datapointsNavigation();
    this.searchNavigation();
    this.datapointsShortcuts();
    this.selectNavigation();
  };

  removeAllListeners = () => {
    Object.values(this).forEach(val => {
      if (val instanceof Subscription) {
        val.unsubscribe();
      }
    });
  };

  selectNavigation = () => {
    const filteredKeydownObserver = this.getFilteredKeydownObserver();

    this.closeSelectSubscription = filteredKeydownObserver
      .pipe(
        filter(() => this.props.selectMenuIsOpen),
        filter(filterKeys([ESC_CODE]))
      )
      .subscribe(() => this.props.closeSelectMenu());
  };

  footerNavigation = () => {
    this.onNextRow = this.arrowDownObserver
      .pipe(
        filter(
          this.condition({
            editbox: false,
            footer: true,
            fromFocused: false,
          })
        ),
        tap(prevent)
      )
      .subscribe(() => this.props.nextRow());

    this.onPreviousRow = this.arrowUpObserver
      .pipe(
        filter(
          this.condition({
            editbox: false,
            footer: true,
            fromFocused: false,
          })
        ),
        tap(prevent)
      )
      .subscribe(() => this.props.previousRow());

    this.onPreviousColumn = this.arrowLeftObserver
      .pipe(
        filter(
          this.condition({
            alt: false,
            editbox: false,
            footer: true,
            fromFocused: false,
          })
        ),
        tap(prevent)
      )
      .subscribe(() => this.props.previousColumn());

    this.onNextColumn = this.arrowRightObserver
      .pipe(
        filter(
          this.condition({
            alt: false,
            editbox: false,
            footer: true,
            fromFocused: false,
          })
        ),
        tap(prevent)
      )
      .subscribe(() => this.props.nextColumn());

    this.onDatapointConfirm = this.editboxConfirmObserver
      .pipe(tap(prevent), delay(FOCUS_CHANGE_DEBOUNCE))
      .subscribe(() => {
        this.props.stopEditingDatapointValue();
        this.props.nextUnvalidatedDatapoint();
      });

    this.onEnterOnFooter = this.enterObserver
      .pipe(
        filter(() => this.props.currentDatapointType !== 'enum'),
        filter(
          this.condition({
            search: false,
            footer: true,
            shift: false,
            editbox: false,
            readonly: false,
            editMode: false,
            fromFocused: false,
          })
        ),
        tap(prevent)
      )
      .subscribe(() => {
        this.props.startEditingDatapointValue();
      });

    this.onEnterOnFooterSelect = this.enterObserver
      .pipe(
        filter(
          this.condition({
            select: true,
            footer: true,
            search: false,
            shift: false,
            editbox: false,
            readonly: false,
            editMode: false,
            fromFocused: false,
          })
        ),
        filter(() => this.props.selectMenuIsOpen),
        tap(prevent)
      )
      .subscribe(() => {
        this.props.nextUnvalidatedDatapoint();
      });
  };

  datapointsNavigation = () => {
    this.onTab = this.tabDownObserver
      .pipe(filter(this.condition({ shift: false })), tap(prevent))
      .subscribe(() => this.props.nextDatapoint());

    this.onShiftTab = this.tabDownObserver
      .pipe(filter(this.condition({ shift: true })), tap(prevent))
      .subscribe(() => this.props.previousDatapoint());

    this.onEnter = this.enterObserver
      .pipe(
        filter(
          event =>
            this.condition({
              fromFocused: false,
              footer: false,
              shift: false,
              search: false,
              editMode: false,
              blockedByDeleteRecommendation: false,
            })(event) || this.condition({ fromFocused: true })(event)
        ),
        filter(() => !this.props.selectMenuIsOpen),
        tap(prevent)
      )
      .subscribe(() => this.props.nextUnvalidatedDatapoint());
  };

  searchNavigation = () => {
    this.onFind = this.fDownObserver
      .pipe(filter(this.condition({ shift: true, meta: true })), tap(prevent))
      .subscribe(() =>
        this.props.displaySearchPanel(!this.props.showSearchPanel)
      );

    this.onSearchPrevSubscription = this.enterObserver
      .pipe(filter(this.condition({ search: true, shift: true })), tap(prevent))
      .subscribe(() => this.props.previousSearchResult());

    this.onSearchNextSubscription = this.enterObserver
      .pipe(
        filter(this.condition({ search: true, shift: false })),
        tap(prevent)
      )
      .subscribe(() => this.props.nextSearchResult());

    this.onEscape = this.escapeObserver
      .pipe(filter(this.condition({ search: true })), tap(prevent))
      .subscribe(() => this.props.displaySearchPanel(false));
  };

  datapointsShortcuts = () => {
    this.onAddDatapoint = this.aDownObserver
      .pipe(
        filter(
          this.condition({
            search: false,
            meta: true,
            shift: true,
            readonly: false,
          })
        ),
        filter(() => this.props.canAddDatapoint),
        tap(prevent)
      )
      .subscribe(() => {
        const { currentMultivalueIndex, currentTupleDatapoint } = this.props;
        if (currentTupleDatapoint) {
          this.props.insertLine({ tuple: currentTupleDatapoint });
        } else if (currentMultivalueIndex) {
          this.props.createDatapoint(currentMultivalueIndex);
        }
      });

    this.onRemoveDatapoint = this.removingKeysDownObserver
      .pipe(
        // editbox: false disables removing datapoint with Cmd + Backspace when blue border is active
        filter(this.condition({ editbox: false, meta: true, readonly: false })),
        filter(() => this.props.canRemoveDatapoint),
        tap(prevent)
      )
      .subscribe(
        () =>
          this.props.multivalueChildPath &&
          this.props.deleteDatapoint(this.props.multivalueChildPath)
      );
  };

  condition =
    ({
      footer,
      search,
      select,
      shift,
      alt,
      editbox,
      meta,
      readonly,
      editMode,
      fromFocused,
      mgSelect,
      blockedByDeleteRecommendation,
    }: Condition) =>
    (event: KeyboardEvent) =>
      every([
        inCase(shift)(() => event.shiftKey),
        inCase(alt)(() => event.altKey),
        inCase(search)(() => this.props.showSearchPanel),
        inCase(editbox)(() => this.props.editingDatapointValue),
        inCase(footer)(() => this.props.isMultivalueFooter),
        inCase(meta)(() => event.metaKey || event.ctrlKey),
        inCase(readonly)(() => this.props.readOnly),
        inCase(editMode)(() => this.props.editMode),
        inCase(select)(() =>
          (get(event.target, 'id') || '').includes('react-select')
        ),
        inCase(mgSelect)(() =>
          getEventPath(event).some(
            element =>
              'className' in element &&
              element.className === 'select-wrapper gridControl'
          )
        ),
        inCase(fromFocused)(() => this.props.addValueFocused),
        inCase(blockedByDeleteRecommendation)(
          () => this.props.isDeleteRecommendation
        ),
      ]);

  arrowUpObserver: Observable<KeyboardEvent> = NEVER;

  arrowDownObserver: Observable<KeyboardEvent> = NEVER;

  editboxConfirmObserver: Observable<KeyboardEvent> = NEVER;

  aDownObserver: Observable<KeyboardEvent> = NEVER;

  enterObserver: Observable<KeyboardEvent> = NEVER;

  escapeObserver: Observable<KeyboardEvent> = NEVER;

  tabDownObserver: Observable<KeyboardEvent> = NEVER;

  removingKeysDownObserver: Observable<KeyboardEvent> = NEVER;

  fDownObserver: Observable<KeyboardEvent> = NEVER;

  arrowLeftObserver: Observable<KeyboardEvent> = NEVER;

  arrowRightObserver: Observable<KeyboardEvent> = NEVER;

  render() {
    const { Wrapped, ...props } = this.props;
    return Wrapped ? <Wrapped {...props} /> : null;
  }
}

const mapStateToProps = (
  state: State,
  { location: { search } }: OwnProps
): StateProps => {
  const datapointPath = getDatapointPathFromSearch(search);

  const currentMultivalueDatapoint = currentMultivalueDatapointSelector(state);
  const currentTupleDatapoint = getCurrentTuple(state);

  const enableRowNavigation = datapointPath.length === 4;
  const lastDatapointPathItem: number | undefined = last(datapointPath);

  const multivalueChildPath: Array<number> | false =
    !!currentMultivalueDatapoint &&
    lastDatapointPathItem !== undefined &&
    currentMultivalueDatapoint.id !== lastDatapointPathItem &&
    flow(
      (path: number[]) => path.indexOf(currentMultivalueDatapoint.id),
      (parentIndex: number) => parentIndex + 1,
      (childIndex: number) => take(datapointPath, childIndex + 1)
    )(datapointPath);

  const currentDatapointType = lastDatapointPathItem
    ? get(findDatapointById(state.datapoints.content, lastDatapointPathItem), [
        'schema',
        'type',
      ])
    : undefined;

  const multivalueParentSchema = get(currentMultivalueDatapoint, 'schema');

  const minOccurrences = get(multivalueParentSchema, 'minOccurrences') || 0;
  const maxOccurrences =
    get(multivalueParentSchema, 'maxOccurrences') || Infinity;

  const countOfChildrens = get(currentMultivalueDatapoint, [
    'children',
    'length',
  ]);

  const isMultivalueFooter =
    !!currentMultivalueDatapoint &&
    !currentMultivalueDatapoint?.meta.isSimpleMultivalue;

  const addValueFocused =
    !!currentMultivalueDatapoint && datapointPath.length === 2;

  const schemas = schemaMapSelector(state);
  const simpleMultivalueChildrenSchemaId = currentMultivalueDatapoint?.meta
    .isSimpleMultivalue
    ? currentMultivalueDatapoint?.schema?.children?.[0]
    : undefined;

  const simpleMultivalueChildrenSchema = simpleMultivalueChildrenSchemaId
    ? schemas.get(simpleMultivalueChildrenSchemaId)
    : undefined;

  const isEditablePerType = isFieldSelectable(simpleMultivalueChildrenSchema);

  const canAddDatapoint =
    isEditablePerType &&
    isNumber(countOfChildrens) &&
    countOfChildrens < maxOccurrences;

  const canRemoveDatapoint =
    isEditablePerType &&
    multivalueChildPath &&
    isNumber(countOfChildrens) &&
    countOfChildrens > minOccurrences;

  const shouldDisableHandlers =
    state.modal.isOpen ||
    !!state.searchAndReplace.currentColumn ||
    state.ui.showRejectionModal;

  return {
    addValueFocused,
    canAddDatapoint,
    canRemoveDatapoint,
    currentDatapointType,
    currentMultivalueIndex: get(currentMultivalueDatapoint, ['meta', 'index']),
    currentTupleDatapoint,
    datapointPath,
    editMode: state.ui.editModeActive,
    editingDatapointValue: state.ui.editingDatapointValue,
    enableRowNavigation,
    isMultivalueFooter,
    multivalueChildPath,
    readOnly: state.ui.readOnly,
    selectMenuIsOpen: state.ui.selectMenuIsOpen,
    isDeleteRecommendation: isDeleteRecommendationInPathSelector(state),
    shouldDisableHandlers,
    showSearchPanel: state.search.shouldShow,
    productTourActive: state.productTour != null,
  };
};

const mapDispatchToProps = {
  createDatapoint,
  deleteDatapoint: deleteDatapointAndNavigate,
  insertLine,
  nextColumn,
  nextDatapoint,
  nextRow,
  closeSelectMenu,
  nextSearchResult,
  nextUnvalidatedDatapoint,
  previousColumn,
  previousDatapoint,
  previousRow,
  previousSearchResult,
  displaySearchPanel,
  startEditingDatapointValue,
  stopEditingDatapointValue,
};

export default (Wrapped: ComponentType<Props>) =>
  connect<
    StateProps,
    MapDispatchToPropsParam<DispatchProps, OwnProps>,
    OwnProps,
    State
  >(
    mapStateToProps,
    mapDispatchToProps
  )((props: Props) => {
    return <ValidationKeyboardHandler Wrapped={Wrapped} {...props} />;
  });
