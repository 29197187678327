import { getIDFromUrl, Url } from '@rossum/api-client';
import { Hook } from '@rossum/api-client/hooks';
import { Add, Close } from '@rossum/ui/icons';
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import { includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useHooks } from '../../business/hooks/useHooks';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import HelpLinkTooltip from '../../components/UI/HelpLinkTooltip';
import InfoBox from '../../components/UI/InfoBox/index';
import {
  FUNCTIONS_LINK,
  pageSizes,
  WEBHOOKS_LINK,
} from '../../constants/values';
import { UnpaidFeatureOverlay } from '../../features/pricing/components/UnpaidFeatureOverlay';
import { useExtensionsFeatureSubscription } from '../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { useWorkspacesWithQueues } from '../../features/queues/hooks/useWorkspacesWithQueues';
import { QueueSelect } from '../../features/queues/select-queue/QueueSelect';
import { ResearchCallSurvey } from '../../features/surveys/ResearchCallSurvey/ResearchCallSurvey';
import { link } from '../../lib/formaterValues';
import { asScalar, parse, setQueryCreator, stringify } from '../../lib/url';
import { HOOK_LIST_QUERY } from '../../redux/modules/localStorage/actions';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { enterExtensionsList } from '../../redux/modules/ui/actions';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { displayExtensionsIntroBannerSelector } from '../../redux/modules/user/selectors';
import { State } from '../../types/state';
import { HeaderTitle } from '../../ui/header/Header';
import SearchInput from '../../ui/search-input/SearchInput';
import { useStoredState } from '../../utils/hooks/useStoredState';
import EventsSelect from './components/EventsSelect';
import IntroToExtensions from './components/IntroToExtensions';
import ViewSwitcher from './components/ViewSwitcher';
import DependenciesGraph from './DependenciesGraph';
import { useGraphHooks } from './DependenciesGraph/hooks';
import { toSelectedEvent } from './DependenciesGraph/types';
import ExtensionsFooter from './ExtensionsFooter';
import { ExtensionsHeader } from './ExtensionsHeader';
import ExtensionsList from './ExtensionsList';
import { extensionsCreatePath } from './helpers';
import { assureCorrectView, webhookNormalizedEvents } from './lib/helpers';
import { myExtensionsDefaultQuery, MyExtensionsQuery } from './lib/routes';

type StateProps = {
  extensions: Array<Hook>;
  extensionsLoaded: boolean;
  displayIntroBanner: boolean;
  isTrial: boolean;
};

type DispatchProps = {
  setHideIntroBanner: () => void;
};

type Props = StateProps & DispatchProps;

const Extensions = ({
  extensions,
  extensionsLoaded,
  displayIntroBanner,
  isTrial,
  setHideIntroBanner,
}: Props) => {
  const { data: activeHooks } = useHooks({
    active: true,
  });

  const activeExtensionsCount = activeHooks?.pagination.total;

  const history = useHistory();
  const location = useLocation();
  const { name: search, events, queues, view } = parse(location.search);
  const [defaultStoredQuery, setStoredQuery] = useStoredState({
    name: HOOK_LIST_QUERY,
    stringKeys: ['pageSize', 'page', 'name', 'queues', 'events', 'view'],
    defaultValues: myExtensionsDefaultQuery,
  });

  const parsedQueueId = Number(asScalar(queues));

  // Technically, we don't need all queues but they will be loaded in the select component anyways.
  const { queues: allQueues, workspacesWithQueues } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const {
    pageSize: pageSizeStored,
    page: pageStored,
    name: nameStored,
    events: eventsStored,
    queues: queuesStored,
    view: viewStored,
  } = defaultStoredQuery || {};

  const isGraphView = view === 'graph';

  const filtersActive = !!(search || events !== 'all' || queues);

  const [displaySearch, setDisplaySearch] = useState(!!search);

  const extensionsSubscription = useExtensionsFeatureSubscription();
  const isFeatureEnabled = extensionsSubscription.canAccess;

  const dispatch = useDispatch();

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    dispatch(enterExtensionsList());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    const searchShouldBeVisible = isGraphView
      ? false
      : !!extensions.length || !!filtersActive || !extensionsLoaded;

    setDisplaySearch(searchShouldBeVisible);
  }, [isGraphView, extensions.length, filtersActive, extensionsLoaded]);

  useEffect(() => {
    const { page, pageSize, ...parsedQuery } = parse(location.search) || {};

    if (
      pageStored !== asScalar(page) ||
      pageSizeStored !== asScalar(pageSize) ||
      viewStored !== asScalar(parsedQuery.view) ||
      eventsStored !== asScalar(parsedQuery.events)
    ) {
      setStoredQuery({
        page: page ? asScalar(page) : myExtensionsDefaultQuery.page,
        pageSize: pageSize
          ? asScalar(pageSize)
          : myExtensionsDefaultQuery.pageSize,
        name: nameStored,
        events: eventsStored,
        queues: queuesStored,
        view: viewStored,
      });
    }
    if (
      !page ||
      !pageSize ||
      !parsedQuery.view ||
      !parsedQuery.events ||
      !includes(pageSizes, pageSize)
    ) {
      history.replace({
        search: stringify(defaultStoredQuery),
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFilterChange = (filter: Partial<MyExtensionsQuery>) => {
    setStoredQuery({
      ...defaultStoredQuery,
      page: myExtensionsDefaultQuery.page,
      ...filter,
    });

    return setQueryCreator(history)(filter);
  };

  const intl = useIntl();

  const [selectedQueueUrl, setSelectedQueueUrl] = useState<Url | null>(null);
  useEffect(() => {
    setSelectedQueueUrl(
      allQueues?.find(queue => queue.id === parsedQueueId)?.url ?? null
    );
  }, [allQueues, parsedQueueId]);

  const eventsAsScalar = asScalar(events);
  const selectedEvent =
    eventsAsScalar !== null ? toSelectedEvent(eventsAsScalar) : 'all';

  const {
    isLoading,
    isFetching,
    graphDataGroupedPerEvents,
    originalExtensions,
  } = useGraphHooks({
    queueId: selectedQueueUrl ? getIDFromUrl(selectedQueueUrl) : undefined,
    enabled: isGraphView,
  });

  return (
    <PageLayoutV2
      fullWidth={isFeatureEnabled}
      renderHeader={params => (
        <ExtensionsHeader
          {...params}
          value="myExtensions"
          title={hasScrolled => (
            <Stack direction="row" alignItems="center" gap={1}>
              <HeaderTitle
                hasScrolled={hasScrolled}
                title={intl.formatMessage({
                  id: `containers.settings.sidebar.myExtensions`,
                })}
              />
              {activeExtensionsCount !== undefined && (
                <Chip
                  label={`${activeExtensionsCount}`}
                  data-cy="extensions-active-count"
                />
              )}
              <HelpLinkTooltip
                id="containers.settings.extensions.helpTooltip"
                values={{
                  webhooksLink: link(WEBHOOKS_LINK),
                  functionsLink: link(FUNCTIONS_LINK),
                }}
              />
            </Stack>
          )}
          buttons={
            isFeatureEnabled
              ? [
                  <ViewSwitcher
                    key="view-switcher"
                    activeView={assureCorrectView(view)}
                    onViewChange={value => onFilterChange({ view: value })}
                  />,
                  <Stack
                    direction="row"
                    alignItems="center"
                    key="create-extension"
                  >
                    <Tooltip
                      title={
                        extensionsSubscription.purchased
                          ? ''
                          : intl.formatMessage({
                              id: 'containers.settings.extensions.createExtensionDisabled',
                            })
                      }
                    >
                      <span>
                        <Button
                          component={Link}
                          disabled={!extensionsSubscription.purchased}
                          to={extensionsCreatePath()}
                          variant="contained"
                          startIcon={<Add />}
                          data-cy="extensions-add-extension"
                          sx={{
                            '&:hover': {
                              color: theme =>
                                theme.palette.primary.contrastText,
                            },
                          }}
                        >
                          {intl.formatMessage({
                            id: 'containers.settings.extensions.createExtension',
                          })}
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>,
                ]
              : []
          }
        />
      )}
      renderFooter={() => {
        if (view !== 'list' || !isFeatureEnabled) return null;
        return <ExtensionsFooter />;
      }}
    >
      {isFeatureEnabled ? (
        <>
          <Stack spacing={3} p={4}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack minWidth={300}>
                  <QueueSelect
                    onChange={queueUrl => {
                      setSelectedQueueUrl(queueUrl);
                      onFilterChange({
                        queues: queueUrl
                          ? `${getIDFromUrl(queueUrl)}`
                          : undefined,
                      });
                    }}
                    value={selectedQueueUrl}
                  />
                </Stack>
                {view === 'list' && (
                  <EventsSelect
                    value={selectedEvent}
                    onChange={({ target: { value } }) =>
                      onFilterChange({ events: toSelectedEvent(value) })
                    }
                    options={['all', ...webhookNormalizedEvents] as const}
                    closeIcon={
                      selectedEvent !== 'all' && (
                        <IconButton
                          size="small"
                          onClick={() => onFilterChange({ events: 'all' })}
                          sx={{ position: 'absolute', right: 30 }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      )
                    }
                  />
                )}
              </Stack>
              {displaySearch && (
                <SearchInput
                  placeholder={intl.formatMessage({
                    id: 'containers.settings.extensions.search',
                  })}
                  value={asScalar(search) || ''}
                  onChange={search =>
                    onFilterChange({ name: search || undefined })
                  }
                  inputProps={{
                    'data-cy': 'search-input',
                  }}
                />
              )}
            </Stack>
            {isTrial && (
              <InfoBox
                title="containers.settings.extensions.upgrade.title"
                text="containers.settings.extensions.upgrade.text"
              />
            )}
            {displayIntroBanner && (
              <IntroToExtensions
                dataCy="extensions-intro-banner"
                onClose={setHideIntroBanner}
              />
            )}
            {view === 'list' ? (
              <ExtensionsList
                extensions={extensions}
                extensionsLoaded={extensionsLoaded}
                filtersActive={filtersActive}
                workspacesWithQueues={workspacesWithQueues}
              />
            ) : (
              <DependenciesGraph
                isLoading={isLoading}
                isFetching={isFetching}
                graphDataGroupedPerEvents={graphDataGroupedPerEvents}
                selectedQueueId={
                  selectedQueueUrl ? getIDFromUrl(selectedQueueUrl) : undefined
                }
                originalExtensions={originalExtensions}
              />
            )}
          </Stack>
          <ResearchCallSurvey surveyPlacement="extensions" />
        </>
      ) : (
        <Stack component={Paper} m={4} p={4} justifyContent="center">
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensions',
            })}
            dataCy="unpaid-overlay-extensions"
          />
        </Stack>
      )}
    </PageLayoutV2>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  extensions: state.extensions.list,
  extensionsLoaded: state.extensions.loaded,
  displayIntroBanner: displayExtensionsIntroBannerSelector(state),
  isTrial: !!isTrialSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  setHideIntroBanner: () =>
    updateUiSettings({
      hideExtensionsIntroBanner: true,
    }),
};

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Extensions);
