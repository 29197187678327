import { Box, Skeleton, Stack, Typography } from '@rossum/ui/material';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  isLoading: boolean;
  numberOfSkeletons?: number;
  children: ReactNode;
};

const EmailTemplateList = ({
  isLoading,
  numberOfSkeletons = 3,
  children,
}: Props) => {
  const intl = useIntl();
  return (
    <Stack spacing={1}>
      {isLoading ? (
        [...Array(numberOfSkeletons).keys()].map(index => (
          <Skeleton
            key={index}
            variant="rectangular"
            width="100%"
            height={34}
          />
        ))
      ) : React.Children.count(children) ? (
        children
      ) : (
        <Box sx={{ my: `7px` }}>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.settings.queues.email.emailTemplates.noTemplates',
            })}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export { EmailTemplateList };
