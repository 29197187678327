import { Skeleton, Stack } from '@rossum/ui/material';
import { ContentWrapper } from '../../../ui/content-group/ContentWrapper';
import { pageSpacingStyles } from '../styles';

export const LoadingContent = () => {
  return (
    <Stack sx={pageSpacingStyles} gap={2}>
      {[1, 2].map(val => (
        <ContentWrapper key={val}>
          <Stack direction="row" gap={3}>
            <Stack gap={1} flex={0.5}>
              <Skeleton height={20} width="70%" variant="rounded" />
              <Skeleton height={20} width="100%" variant="rounded" />
            </Stack>
            <Skeleton height={60} variant="rounded" sx={{ flex: 0.5 }} />
          </Stack>
        </ContentWrapper>
      ))}
    </Stack>
  );
};
