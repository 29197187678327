import clsx from 'clsx';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import sidebarStyles from '../../../../Sidebar/style.module.sass';
import scrollingSubject from '../lib/scrollingSubject';
import styles from '../styles.module.sass';
import Datapoint from './Datapoint';
import Enum from './Enum';
import Multivalue, { PreviewProps } from './Multivalue';

export const categorySwitch = ({
  category,
  children,
  closeCurrent,
  constraints,
  format,
  hidden,
  id,
  inFooter,
  isCurrentPath,
  label,
  max_occurrences,
  min_occurrences,
  options,
  path,
  setCurrent,
  toggleCurrent,
  type,
}: PreviewProps) => {
  const isOpen = isCurrentPath(path);
  const toggleSimpleDatapoint = () =>
    toggleCurrent
      ? toggleCurrent(path)
      : isOpen
        ? closeCurrent?.()
        : setCurrent?.(path);

  if (type === 'enum') {
    return (
      <Enum
        type={type}
        constraints={constraints}
        hidden={hidden}
        id={id}
        inFooter={inFooter}
        isOpen={isOpen}
        key={id}
        label={label}
        optionsCount={(options || []).length}
        toggleCurrent={toggleSimpleDatapoint}
      />
    );
  }

  switch (category) {
    case 'datapoint':
      return (
        <Datapoint
          constraints={constraints}
          format={format}
          hidden={hidden}
          id={id}
          inFooter={inFooter}
          key={id}
          label={label}
          type={type}
          toggleCurrent={toggleSimpleDatapoint}
        />
      );
    case 'multivalue':
      return (
        <Multivalue
          isCurrentPath={isCurrentPath}
          id={id}
          hidden={hidden}
          constraints={constraints}
          inFooter={inFooter}
          key={id}
          label={label}
          max_occurrences={max_occurrences}
          min_occurrences={min_occurrences}
          path={path}
          toggleCurrent={toggleCurrent}
        >
          {children}
        </Multivalue>
      );
    default:
      return null;
  }
};

const Section = ({
  children = [],
  isCurrentPath,
  hidden,
  id,
  label,
  path,
  toggleCurrent,
}: PreviewProps) => {
  const kinder = Array.isArray(children) ? children : [children];

  return (
    <div>
      <div
        className={clsx(sidebarStyles.SectionTitle, styles.TitleEncapsulor)}
        onClick={() => scrollingSubject.next(id)}
      >
        {label}
        {hidden && <EyeOffIcon className={styles.HiddenIcon} />}
      </div>
      {kinder.map((datapoint, index: number) =>
        categorySwitch({
          ...datapoint,
          toggleCurrent,
          isCurrentPath,
          path: [...path, index.toString()],
        })
      )}
    </div>
  );
};

export default Section;
