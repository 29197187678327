import { DateRange as DateRangeIcon } from '@rossum/ui/icons';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  InputAdornment,
  lighten,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { format } from 'date-fns';
import { get, set, update } from 'lodash';
import { useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { useAutomation } from '../../../../business/queues/useAutomation';
import {
  getLastMonthEnd,
  getLastMonthStart,
  getLastWeekEnd,
  getLastWeekStart,
  getThisMonthStart,
  getThisWeekStart,
  getToday,
  getYesterday,
} from '../../../../components/Datepicker/helpers';
import { linebreak } from '../../../../lib/formaterValues/index';
import { schemaMapSelector } from '../../../../redux/modules/schema/schemaMapSelector';
import {
  OriginalAnyDatapointSchema,
  OriginalDatapointSchema,
} from '../../../../types/schema';
import AutomationBlockers from './AutomationBlockers';
import ThresholdInput, {
  getLeftPosition,
  rightPadding,
} from './ThresholdInput';
import { roundToFixedPercents } from './utils';

type Props = {
  queueId: number;
  queueThreshold: number;
  schema: OriginalAnyDatapointSchema[] | OriginalDatapointSchema[];
  updateSchema: (schema: OriginalAnyDatapointSchema[]) => void;
};

const RANGE_FORMAT = 'yyyy-MM-dd';

type DateRangeOption = {
  getBeginDate: () => Date;
  getEndDate: () => Date;
  uniqueId: string;
  label: string;
};

const getActiveDateRange = ({
  getBeginDate,
  getEndDate,
  label,
}: Pick<DateRangeOption, 'getBeginDate' | 'label' | 'getEndDate'>) => {
  return {
    beginDate: format(getBeginDate(), RANGE_FORMAT),
    endDate: format(getEndDate(), RANGE_FORMAT),
    label,
  };
};

const getRangeOptions = (intl: IntlShape): Array<DateRangeOption> => [
  {
    getBeginDate: getLastMonthStart,
    getEndDate: getLastMonthEnd,
    label: intl.formatMessage({
      id: 'components.datepicker.shortcuts.lastMonth',
    }),
    uniqueId: 'automationDataOptions-lastMonth',
  },
  {
    getBeginDate: getThisMonthStart,
    getEndDate: getToday,
    label: intl.formatMessage({
      id: 'components.datepicker.shortcuts.thisMonth',
    }),
    uniqueId: 'automationDataOptions-thisMonth',
  },
  {
    getBeginDate: getLastWeekStart,
    getEndDate: getLastWeekEnd,
    label: intl.formatMessage({
      id: 'components.datepicker.shortcuts.lastWeek',
    }),
    uniqueId: 'automationDataOptions-lastWeek',
  },
  {
    getBeginDate: getThisWeekStart,
    getEndDate: getToday,
    label: intl.formatMessage({
      id: 'components.datepicker.shortcuts.thisWeek',
    }),
    uniqueId: 'automationDataOptions-thisWeek',
  },
  {
    getBeginDate: getYesterday,
    getEndDate: getYesterday,
    label: intl.formatMessage({
      id: 'components.datepicker.shortcuts.yesterday',
    }),
    uniqueId: 'automationDataOptions-yesterday',
  },
  {
    getBeginDate: getToday,
    getEndDate: getToday,
    label: intl.formatMessage({ id: 'components.datepicker.shortcuts.today' }),
    uniqueId: 'automationDataOptions-today',
  },
];

const Required = styled('span')({
  cursor: 'default',
});

const ColumnLabel = ({
  type,
}: {
  type: 'scoreThreshold' | 'confidenceMean' | 'automationRate';
}) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={intl.formatMessage({
        id: `containers.settings.automation.automationData.table.${type}.tooltip`,
      })}
      placement="top"
    >
      <Box
        sx={{
          position: 'relative',
          cursor: 'default',
          display: 'inline',
          // partially solve issue with hiding tooltip when hovering multiline text
          '&:before': {
            content: "''",
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ display: 'inline', textDecoration: 'underline dotted' }}
        >
          {intl.formatMessage(
            {
              id: `containers.settings.automation.automationData.table.${type}`,
            },
            { linebreak }
          )}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const PREFIX = 'AutomationTable';

const classes = {
  row: `${PREFIX}-row`,
  cellWithInput: `${PREFIX}-cell-with-input`,
};

const StyledRow = styled(TableRow)(({ theme }) => ({
  transition: 'background 0.15s',
  '&:hover': {
    [`& .${classes.cellWithInput}`]: {
      position: 'relative',
      cursor: 'text',
      '&:after': {
        content: "''",
        position: 'absolute',
        right: `-${rightPadding}px`,
        left: getLeftPosition(theme.spacing),
        top: '-8px',
        bottom: '-8px',
        border: `1px solid ${lighten(theme.palette.background.paper, 0.3)}`,
        borderRadius: theme.shape.borders.medium,
        transition: '0.15s border',
      },
      '&:hover:after': {
        borderColor: `${lighten(theme.palette.background.paper, 0.3)}`,
      },
    },
  },
}));

const AutomationDataTable = ({
  schema,
  updateSchema,
  queueId,
  queueThreshold,
}: Props) => {
  const schemaMap = useSelector(schemaMapSelector);

  const intl = useIntl();
  const options = useMemo(() => getRangeOptions(intl), [intl]);

  const [activeRange, setActiveRange] = useState(() =>
    getActiveDateRange(options[1])
  );

  const { data, isFetching, isPreviousData } = useAutomation(
    queueId,
    {
      beginDate: activeRange.beginDate,
      endDate: activeRange.endDate,
    },
    {
      keepPreviousData: true,
    }
  );

  return (
    <Stack divider={<Divider />}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ paddingBottom: 1 }}
        alignItems="center"
      >
        <Stack direction="row" spacing={1}>
          {data && (
            <Typography color="text.secondary" variant="body2">
              {intl.formatMessage(
                {
                  id: 'containers.settings.automation.automationData.automatedDocuments',
                },
                {
                  total: data.totals.documentCount,
                  automated: (
                    <Tooltip
                      placement="top"
                      title={roundToFixedPercents(data.totals.automation, 0)}
                    >
                      <Chip
                        component="span"
                        label={(
                          data.totals.documentCount * data.totals.automation
                        ).toFixed(0)}
                      />
                    </Tooltip>
                  ),
                }
              )}
            </Typography>
          )}

          {isFetching && <CircularProgress size={20} />}
        </Stack>

        <Select
          disableUnderline
          startAdornment={
            <InputAdornment position="start">
              <DateRangeIcon fontSize="inherit" />
            </InputAdornment>
          }
          variant="standard"
          value={activeRange.label}
          onChange={e => {
            const option = options.find(
              option => option.label === e.target.value
            );

            if (option) {
              setActiveRange(getActiveDateRange(option));
            }
          }}
        >
          {options.map(option => (
            <MenuItem value={option.label} key={option.label}>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack spacing={5} sx={{ opacity: isPreviousData ? 0.3 : 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '30%' }}>
                <Typography variant="body2" color="text.secondary">
                  {intl.formatMessage({
                    id: 'containers.settings.automation.automationData.table.fieldLabel',
                  })}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ width: '25%' }}>
                <ColumnLabel type="scoreThreshold" />
              </TableCell>
              <TableCell align="right" sx={{ width: '21%' }}>
                <ColumnLabel type="automationRate" />
              </TableCell>
              <TableCell align="right" sx={{ width: '24%' }}>
                <ColumnLabel type="confidenceMean" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schema && data && data.datapoints.length ? (
              data.datapoints.map(
                ({ id, uuid, automation, confidenceMean }) => {
                  const schemaField = schemaMap.get(id);

                  if (!schemaField) {
                    return null;
                  }

                  const uiConfiguration =
                    schemaField.category === 'datapoint'
                      ? schemaField.uiConfiguration
                      : undefined;

                  const uiFieldType = uiConfiguration?.type;

                  // WORKAROUND: This doesn't have to be correct, but otherwise getIn/updateIn complains
                  const schemaFieldPath = schemaField.path as string[];

                  const scoreThresholdPath = [
                    ...schemaFieldPath,
                    'scoreThreshold',
                  ];

                  return (
                    <StyledRow className={classes.row} key={uuid} hover>
                      <TableCell component="th" scope="row">
                        <Tooltip
                          placement="top"
                          title={intl.formatMessage(
                            {
                              id: 'containers.settings.automation.automationData.table.fieldId',
                            },
                            { schemaId: id }
                          )}
                        >
                          <span>{schemaField.label}</span>
                        </Tooltip>
                        {schemaField.constraints?.required && (
                          <Tooltip
                            placement="top"
                            title={intl.formatMessage({
                              id: 'containers.settings.queues.schema.sidebar.required',
                            })}
                          >
                            <Required>&nbsp;*</Required>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <ThresholdInput
                          editingValue={get(schema, scoreThresholdPath) ?? ''}
                          queueThreshold={queueThreshold}
                          onChange={(value: number | null) =>
                            updateSchema(
                              (value === null || value === queueThreshold
                                ? update(
                                    R.clone(schema),
                                    schemaFieldPath,
                                    ({
                                      scoreThreshold,
                                      ...rest
                                    }: OriginalAnyDatapointSchema) => rest
                                  )
                                : set(
                                    R.clone(schema),
                                    scoreThresholdPath,
                                    value
                                  )) as OriginalAnyDatapointSchema[]
                            )
                          }
                          className={classes.cellWithInput}
                          value={
                            get(schema, scoreThresholdPath) ?? queueThreshold
                          }
                          uiFieldType={uiFieldType}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Typography color="text.secondary" variant="body2">
                          {roundToFixedPercents(automation)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color="text.secondary" variant="body2">
                          {roundToFixedPercents(confidenceMean)}
                        </Typography>
                      </TableCell>
                    </StyledRow>
                  );
                }
              )
            ) : (
              <tr>
                <TableCell align="center" colSpan={4}>
                  {intl.formatMessage({
                    id: 'containers.settings.automation.automationData.table.noData',
                  })}
                </TableCell>
              </tr>
            )}
          </TableBody>
        </Table>

        <AutomationBlockers
          schemaMap={schemaMap}
          data={data}
          dateRangeLabel={activeRange.label}
        />
      </Stack>
    </Stack>
  );
};

export default AutomationDataTable;
