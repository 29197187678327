import { Chip, ChipProps } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { AuroraIcon } from './Icons';

const AURORA_KEYWORD = 'aurora';

export const shouldDisplayAuroraChipByName = (
  name: string,
  auroraEngineEnabled: boolean
) => {
  const isAuroraInName = name.toLowerCase().includes(AURORA_KEYWORD);
  return isAuroraInName && auroraEngineEnabled;
};

export type AuroraChipProps = Omit<ChipProps, 'label'> & {
  label?: string;
};

// Consider using ConditionalAuroraChip instead of AuroraChip
const AuroraChip = ({ label }: AuroraChipProps) => {
  const intl = useIntl();

  const resolvedLabel =
    label ??
    intl.formatMessage({
      id: 'component.auroraChip.label',
    });

  return (
    <Chip
      color="aurora"
      icon={<AuroraIcon sx={{ color: t => t.palette.text.primary }} />}
      label={resolvedLabel}
    />
  );
};

export default AuroraChip;
