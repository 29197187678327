import { Box, Stack } from '@rossum/ui/material';
import { Redirect, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { parse } from '../../lib/url';
import { LoginFailed } from './LoginFailed';
import { NoPermission } from './NoPermission';
import { NotFound } from './NotFound';
import { TooManyRequests } from './TooManyRequests';
const reasonSchema = z.union([
  z.literal('login-failed'),
  z.literal('too-many-requests'),
  z.literal('not-found'),
  z.literal('no-permission'),
]);

const componentsMap = {
  'too-many-requests': TooManyRequests,
  'login-failed': LoginFailed,
  'not-found': NotFound,
  'no-permission': NoPermission,
};

const ErrorPage = () => {
  const location = useLocation();

  const { search } = location;
  const { reason } = parse(search);

  const parsed = reasonSchema.safeParse(reason);

  if (!parsed.success) {
    return <Redirect to="/" />;
  }

  const CustomReasonComponent = componentsMap[parsed.data];

  return (
    <Stack data-page-title={parsed.data}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          padding: 2,
          gap: 2,
        }}
      >
        <CustomReasonComponent />
      </Box>
    </Stack>
  );
};

export { ErrorPage };
