import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { LoadingOverlay } from './components/LoadingOverlay';
import { BillingEntity } from './hooks/useBillingEntity';
import { useBillingHistoryExport } from './hooks/useBillingHistoryExport';

export const ExportHistoryButton = ({
  billingEntity,
}: {
  billingEntity: BillingEntity;
}) => {
  const intl = useIntl();
  const { triggerExport, isExporting } = useBillingHistoryExport(billingEntity);

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={() => triggerExport()}
      disabled={isExporting}
    >
      {isExporting && <LoadingOverlay size={16} />}
      {intl.formatMessage({
        id: 'containers.billing.export',
      })}
    </Button>
  );
};
