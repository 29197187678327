import { HttpError } from '@rossum/api-client/errors';
import { ErrorEvent, EventHint } from '@sentry/react';
import { AxiosError } from 'axios';
import { AjaxError } from 'rxjs/ajax';

const getRequestIdFromError = (error: unknown) => {
  if (error instanceof HttpError) {
    return error.headers?.['x-request-id'] ?? null;
  }

  if (error instanceof AjaxError) {
    return error.xhr?.getResponseHeader('x-request-id') ?? null;
  }

  if (error instanceof AxiosError) {
    return error.response?.headers?.['x-request-id'] ?? null;
  }
  return null;
};

export const withRequestId = ([event, hint]: [ErrorEvent, EventHint]): [
  ErrorEvent,
  EventHint,
] => {
  const { originalException } = hint;

  const requestId = getRequestIdFromError(originalException);

  if (requestId) {
    return [
      {
        ...event,
        contexts: {
          ...event.contexts,
          response: {
            requestId,
          },
        },
      },
      hint,
    ];
  }

  return [event, hint];
};
