import { endpoints, ID } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RULE_TEMPLATES_QUERY_KEY } from './useListRuleTemplates';

export const useGetRuleTemplate = (ruleTemplateId: ID) => {
  const api = useApiClient();

  return useQuery({
    queryKey: [RULE_TEMPLATES_QUERY_KEY, 'detail', ruleTemplateId],
    queryFn: () => api.request(endpoints.ruleTemplates.get(ruleTemplateId)),
  });
};
