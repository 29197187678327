import { Hook } from '@rossum/api-client/hooks';

export const getExtensionConfigAppUrl = (extension?: Hook) =>
  extension?.config.app?.url;

export const getConfigAppOrigin = (configAppUrl: string) => {
  try {
    const { origin } = new URL(configAppUrl);
    return origin;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
};

export const fullscreenConfigAppPath = '/app';
