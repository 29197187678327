import { createReactComponent } from '@tabler/icons-react';

export const IconCustomSuggestFile = createReactComponent(
  'outline',
  'customSuggestFile',
  'CustomSuggestFile',
  [
    [
      'path',
      {
        key: 'customSuggestFile-path',
        d: 'M14 3V7C14 7.26522 14.1054 7.51957 14.2932 7.70736C14.4809 7.89514 14.7353 8.0005 15 8.0005H19M14 3H7C6.44772 3 5.92099 3.21071 5.53761 3.59409C5.15423 3.97747 4.94351 4.5042 4.94351 5.05648V11.1M14 3L19 8.0005M19 8.0005V11.1M17 21C17.5523 21 18.079 20.7893 18.4624 20.4059C18.6517 20.2166 18.7986 19.9869 18.8894 19.735M7 21C6.44772 21 5.92099 20.7893 5.53761 20.4059C5.34832 20.2166 5.20144 19.9869 5.11065 19.735M13 21H11H9M19 15.05V16.425M5 15.05V16.425',
      },
    ],
  ]
);
