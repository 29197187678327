import { endpoints } from '@rossum/api-client';
import { Label } from '@rossum/api-client/labels';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { labelsFeatureSelector } from '../../pricing/selectors';

export const UNPAGINATED_LABELS_QUERY_KEY = 'unpaginatedLabels';

export const useRequestUnpaginatedLabels = <TReturnedData = Array<Label>>(
  options: {
    select?: (data: Label[]) => TReturnedData;
  } = {}
) => {
  const labelsEnabled = useSelector(labelsFeatureSelector);

  return useQuery({
    queryKey: [UNPAGINATED_LABELS_QUERY_KEY] as const,
    queryFn: () => api.unpaginatedRequest(endpoints.labels.list)(),
    enabled: labelsEnabled,
    // Labels are fairly static - we shouldn't need to refetch them.
    staleTime: 3600 * 1000,
    ...options,
  });
};
