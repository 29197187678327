import { Stack } from '@rossum/ui/material';
import { SidebarItemLayout } from '../../shared/SidebarItemLayout';
import { SidebarMultivalueChildFieldModel } from '../../useSidebarData';
import { InactiveValue } from './InactiveValue';

type MultivalueInactiveChildrenProps = {
  childItems: SidebarMultivalueChildFieldModel[];
};

export const MultivalueInactiveChildren = ({
  childItems,
}: MultivalueInactiveChildrenProps) => {
  const visibleChildItems = childItems.slice(0, 3);

  const showEllipsis = childItems.length > 4;

  return (
    <Stack sx={{ width: '100%' }}>
      {visibleChildItems.map(childItem => (
        <SidebarItemLayout
          key={childItem.id}
          valueSlot={<InactiveValue value={childItem.value} dense />}
        />
      ))}
      {showEllipsis ? (
        <SidebarItemLayout valueSlot={<InactiveValue value="…" dense />} />
      ) : null}
    </Stack>
  );
};
