import { Queue } from '@rossum/api-client/queues';
import { RuleTemplate } from '@rossum/api-client/rule-templates';
import { SchemaRule } from '@rossum/api-client/schemas';
import { Circle } from '@rossum/ui/icons';
import {
  IconCircleCheck,
  IconEqualNot,
  IconTransfer,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import {
  IconButton,
  LinearProgress,
  Stack,
  SvgIcon,
  Switch,
  Typography,
} from '@rossum/ui/material';
import {
  DataGridPro,
  GridNoRowsOverlay,
  GridRenderCellParams,
} from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type ComparisonResult = {
  label: string;
  rule: string;
  template: string;
};

export type TemplateDistributionRuleRowModel = {
  rule: SchemaRule;
  queue: Queue;
  workspaceName?: string;
};

type TemplateDistributionSchemaToAddRowModel = {
  rule?: undefined;
  queue: Queue;
  workspaceName?: string;
};

export type TemplateDistributionRowModel =
  | TemplateDistributionRuleRowModel
  | TemplateDistributionSchemaToAddRowModel;

type TemplateDistributionGridProps = {
  rows: TemplateDistributionRowModel[];
  onRemoveRow: (row: TemplateDistributionRowModel) => void;
  onChangeSynchronizationOfRow: (row: TemplateDistributionRowModel) => void;
  isChangingSynchronizationSettings: boolean;
  ruleTemplateDraft: Partial<RuleTemplate>;
};

const compareRuleWithTemplate = (
  rule: SchemaRule,
  template: Partial<RuleTemplate>,
  intl: IntlShape
): ComparisonResult[] => {
  const toCompare = [
    [
      intl.formatMessage({
        id: 'features.ruleTemplates.distribution.column.differences.label.name',
      }),
      rule.name,
      template.name,
    ],
    [
      intl.formatMessage({
        id: 'features.ruleTemplates.distribution.column.differences.label.description',
      }),
      rule.description,
      template.description,
    ],
    [
      intl.formatMessage({
        id: 'features.ruleTemplates.distribution.column.differences.label.condition',
      }),
      rule.triggerCondition,
      template.triggerCondition,
    ],
    [
      intl.formatMessage({
        id: 'features.ruleTemplates.distribution.column.differences.label.actions',
      }),
      JSON.stringify(rule.ruleActions),
      JSON.stringify(template.ruleActions),
    ],
  ] as const;

  return toCompare.flatMap(([label, ruleValue, templateValue]) =>
    ruleValue === templateValue
      ? []
      : [
          {
            label,
            rule:
              label ===
              intl.formatMessage({
                id: 'features.ruleTemplates.distribution.column.differences.label.actions',
              })
                ? intl.formatMessage({
                    id: 'features.ruleTemplates.distribution.column.differences.label.actions.actionsDiffer',
                  })
                : ruleValue,
            template: templateValue ?? '',
          },
        ]
  );
};

export const TemplateDistributionGrid = ({
  rows,
  onRemoveRow,
  onChangeSynchronizationOfRow,
  isChangingSynchronizationSettings,
  ruleTemplateDraft,
}: TemplateDistributionGridProps) => {
  const intl = useIntl();

  const distributionColumns = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: intl.formatMessage({
          id: 'features.ruleTemplates.distribution.column.queue',
        }),
        renderCell: ({
          row,
        }: GridRenderCellParams<TemplateDistributionRowModel>) => {
          return (
            <Stack direction="column">
              <Typography>{row.queue.name}</Typography>
              <Typography color="text.disabled">{`(${row.workspaceName})`}</Typography>
            </Stack>
          );
        },
        flex: 0.5,
      },
      {
        field: 'differences',
        sortable: true,
        headerName: intl.formatMessage({
          id: 'features.ruleTemplates.distribution.column.differences',
        }),
        sortComparator: (
          value1: Array<unknown> | undefined = [],
          value2: Array<unknown> | undefined = []
        ) => value1.length - value2.length,
        valueGetter: ({
          row,
        }: GridRenderCellParams<TemplateDistributionRowModel>) => {
          const differences = row.rule
            ? compareRuleWithTemplate(row.rule, ruleTemplateDraft, intl)
            : [];

          return differences;
        },
        renderCell: ({
          row,
        }: GridRenderCellParams<TemplateDistributionRowModel>) => {
          // TODO: it would be probably better to move it to parent in order to be able to remove rules without differences from rulesToSync
          const differences = row.rule
            ? compareRuleWithTemplate(row.rule, ruleTemplateDraft, intl)
            : [];

          const isRuleSynchronizationEnabled =
            row.rule?.synchronizedFromTemplate === true;

          return (
            <Stack direction="column" spacing={0.5} py={1}>
              {differences.length ? (
                differences.map(item => (
                  <Stack
                    key={item.rule}
                    direction="row"
                    spacing={1}
                    divider={
                      <Circle sx={{ width: 5, height: 5 }} color="disabled" />
                    }
                    alignItems="center"
                  >
                    <Stack direction="row" spacing={1}>
                      {isRuleSynchronizationEnabled ? (
                        <SvgIcon color="inherit">
                          <IconTransfer />
                        </SvgIcon>
                      ) : (
                        <SvgIcon color="warning">
                          <IconEqualNot />
                        </SvgIcon>
                      )}
                      <Typography variant="body2">{item.label}</Typography>
                    </Stack>
                  </Stack>
                ))
              ) : row.rule !== undefined ? (
                <Stack spacing={1} direction="row" alignItems="center">
                  <SvgIcon color="disabled">
                    <IconCircleCheck />
                  </SvgIcon>
                  <Typography variant="body2" color="text.disabled">
                    {intl.formatMessage({
                      id: 'features.ruleTemplates.distribution.column.differences.uptodate.label',
                    })}
                  </Typography>
                </Stack>
              ) : (
                <Stack spacing={1} direction="row" alignItems="center">
                  <SvgIcon color="inherit">
                    <IconTransfer />
                  </SvgIcon>
                  <Typography variant="body2" color="text.primary">
                    {intl.formatMessage({
                      id: 'features.ruleTemplates.distribution.column.differences.toBeDistributed.label',
                    })}
                  </Typography>
                </Stack>
              )}
            </Stack>
          );
        },
        flex: 1,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: ({
          row,
        }: GridRenderCellParams<TemplateDistributionRowModel>) => {
          const rowRule = row.rule;

          const removeButton = (
            <IconButton onClick={() => onRemoveRow(row)}>
              <SvgIcon>
                <IconTrash />
              </SvgIcon>
            </IconButton>
          );

          const isRuleSynchronizedWithTemplate =
            rowRule?.synchronizedFromTemplate ?? false;

          const syncButton = rowRule ? (
            <Switch
              size="small"
              key={row.rule.id}
              checked={isRuleSynchronizedWithTemplate}
              disabled={isChangingSynchronizationSettings}
              onChange={() => onChangeSynchronizationOfRow(row)}
            />
          ) : null;
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              {syncButton}
              {removeButton}
            </Stack>
          );
        },
        flex: 0.25,
      },
    ],
    [
      intl,
      isChangingSynchronizationSettings,
      onChangeSynchronizationOfRow,
      onRemoveRow,
      ruleTemplateDraft,
    ]
  );

  return (
    <DataGridPro
      getRowHeight={() => 'auto'}
      getRowId={row => row.queue.id}
      disableColumnSelector
      columns={distributionColumns}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: GridNoRowsOverlay,
      }}
      slotProps={{
        noRowsOverlay: {
          sx: { backgroundColor: 'inherit' },
        },
      }}
      autoHeight
      localeText={
        rows.length === 0
          ? {
              noRowsLabel: intl.formatMessage({
                id: 'features.ruleTemplates.distribution.tableEmpty',
              }),
            }
          : undefined
      }
      rows={rows}
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
    />
  );
};
