import { Message } from '@rossum/api-client/shared';
import equal from 'fast-deep-equal/es6/react';
import { connect } from 'react-redux';
import {
  ConnectedProps,
  RefTrackerProps,
} from '../../decorators/DatapointRefTracker';
import {
  datapointBlockerSelector,
  datapointBlockerVisibleSelector,
} from '../../redux/modules/annotation/selectors';
import { DatapointAutomationBlocker } from '../../redux/modules/annotation/types';
import {
  replaceSuggestedOperationsWithDatapointSelector,
  suggestedOperationsOptionsSelector,
  suggestedOperationsSelector,
} from '../../redux/modules/datapoints/suggestedOperations/selector';
import {
  AnyDatapointDataST,
  MatchedTriggerRuleDatapoint,
  SimpleDatapointDataST,
  SuggestedOperationWithMeta,
} from '../../types/datapoints';
import { State } from '../../types/state';
import { isFeatureFlagEnabled } from '../../unleash/useFeatureFlag';
import Datapoint, { OwnProps } from './index';

type Props = Omit<OwnProps, keyof StateProps> &
  ConnectedProps &
  RefTrackerProps & {
    message: Message;
  };

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
  const { data } = ownProps;

  const suggestedOperation =
    replaceSuggestedOperationsWithDatapointSelector(state)[ownProps.data.id];

  const suggestedOperationsSchemaIds =
    suggestedOperationsOptionsSelector(state).schemaIds;

  const automationBlockers = isFeatureFlagEnabled('ac-5499-new-sidebar')
    ? datapointBlockerSelector(state)[data.schemaId]
    : datapointBlockerVisibleSelector(state)[data.schemaId];

  return {
    automationBlockers,
    matchedRules:
      state.datapoints.matchedTriggerRules.datapointLevel[data.id]?.[0],
    currentColumn: state.searchAndReplace.currentColumn,
    data,
    editingDatapointValue: state.ui.editingDatapointValue,
    searchPanelShown: state.search.shouldShow,
    suggestedOperation,
    suggestedOperationsSchemaIds,
    documentAutomated: state.annotation.automated ?? false,
  };
};

type StateProps = {
  automationBlockers: DatapointAutomationBlocker[];
  currentColumn: string;
  data: AnyDatapointDataST;
  documentAutomated: boolean;
  editingDatapointValue: boolean;
  matchedRules: MatchedTriggerRuleDatapoint | undefined;
  searchPanelShown: boolean;
  suggestedOperation: SuggestedOperationWithMeta | undefined;
  suggestedOperationsSchemaIds: string[];
};

export default connect<StateProps, null, Props, State>(
  mapStateToProps,
  null,
  null,
  {
    areOwnPropsEqual: (next: Props, prev: Props) =>
      !(
        next.active ||
        prev.active ||
        next.message?.content !== prev.message?.content ||
        !equal(
          (next.data as SimpleDatapointDataST).validationSources,
          (prev.data as SimpleDatapointDataST).validationSources
        ) ||
        next.isLoading !== prev.isLoading ||
        (next.data as SimpleDatapointDataST).content !==
          (prev.data as SimpleDatapointDataST).content
      ),
    areStatesEqual: (next, prev) =>
      next.searchAndReplace.currentColumn ===
        prev.searchAndReplace.currentColumn &&
      next.datapoints.updatedTimestamp === prev.datapoints.updatedTimestamp &&
      suggestedOperationsSelector(next) === suggestedOperationsSelector(prev) &&
      next.ui.fieldAutomationBlockersVisible ===
        prev.ui.fieldAutomationBlockersVisible,
  }
)(Datapoint);
