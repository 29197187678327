import { UniqueIdentifier } from '@dnd-kit/core';
import {
  isSchemaItem,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { Add, MoreVert, NavigateNextRounded } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  capitalize,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SchemaFieldIcon } from '../../../../ui/schema-field-icon/SchemaFieldIcon';
import { resolveIconType } from '../../../formulas/FormulaPreview/hooks/utils';
import { SchemaFieldContextAction } from '../types';
import { getUpdatedPathname } from '../utils';
import { QuickActionField, QuickActionIcon } from './form/QuickActionIcon';

export const TILE_HEIGHT = 56;

type SchemaFieldQuickAction = {
  key: QuickActionField;
  value: boolean;
  disabled: boolean;
  onClick: () => void;
};

type SchemaFieldTileContentProps = {
  isActive: boolean;
  isDragging: boolean;
  isFaded?: boolean;
} & {
  field: SchemaItem | SchemaSection;
  // TODO: Me no likey this
  parentId?: UniqueIdentifier;
  quickActions: SchemaFieldQuickAction[];
  contextActions?: SchemaFieldContextAction[];
};

const getActionTooltip = (
  intl: IntlShape,
  actionKey: QuickActionField,
  value: boolean
) => {
  const action = intl
    .formatMessage({
      id: `features.queueSettings.fields.quickActions.${actionKey}`,
    })
    .toLowerCase();

  return intl.formatMessage(
    {
      id: `features.queueSettings.fields.quickActions.quick`,
    },
    {
      action,
      newValue: intl
        .formatMessage({
          id: `features.queueSettings.fields.quickActions.${
            value ? 'off' : 'on'
          }`,
        })
        .toLowerCase(),
    }
  );
};

export const SchemaFieldTileContent = ({
  field,
  isActive,
  isFaded,
  parentId,
  quickActions,
  contextActions,
}: SchemaFieldTileContentProps) => {
  const history = useHistory();
  const intl = useIntl();

  const type = resolveIconType(field);

  const pathToDetail = useMemo(() => {
    // for simple multivalues, we are using the child's id to identify it
    const fieldId = isSchemaSimpleMultiValue(field)
      ? field.children.id
      : field.id;

    if (isSchemaItem(field)) {
      if (parentId) {
        return `${history.location.pathname}/${parentId}/${fieldId}`;
      }
      return `${history.location.pathname}/${fieldId}`;
    }

    return getUpdatedPathname({
      pathname: history.location.pathname,
      segmentsToDrop: 1,
      newSegmentToAdd: field.id,
    });
  }, [field, history.location.pathname, parentId]);

  const caption = useMemo(
    () =>
      isSchemaItem(field) ? (
        isSchemaSimpleMultiValue(field) ? (
          field.children.id
        ) : (
          field.id
        )
      ) : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          divider={
            <Box
              sx={{
                width: 4,
                height: 4,
                borderRadius: '50%',
                backgroundColor: t => t.palette.divider,
              }}
            />
          }
        >
          {field.children.slice(0, 5).map(({ label, id }, index) => (
            <Typography variant="caption" key={id}>
              {`${label}${
                index === 4 && field.children.length > 5 ? '...' : ''
              }`}
            </Typography>
          ))}
        </Stack>
      ),
    [field]
  );

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        height: TILE_HEIGHT,
      }}
      data-cy="field-tile"
    >
      <Stack
        direction="row"
        spacing={2}
        component={Link}
        to={pathToDetail}
        tabIndex={-1}
        sx={{
          color: t => t.palette.text[isFaded ? 'secondary' : 'primary'],
          height: '100%',
          flexGrow: 1,
          cursor: 'pointer',
          alignItems: 'center',
          '&:hover': {
            color: theme => theme.palette.text.primary,
            textDecoration: 'none',
          },
        }}
      >
        {type ? (
          <Tooltip title={`${capitalize(type ?? '')} field type`}>
            <Stack
              width={36}
              height={36}
              justifyContent="center"
              alignItems="center"
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: t => t.palette.divider,
                borderRadius: '50%',
              }}
            >
              <SchemaFieldIcon type={type} fontSize="small" />
            </Stack>
          </Tooltip>
        ) : null}
        <Stack flex={1} data-cy="field-tile-description">
          <Typography variant="h6">{field.label}</Typography>
          <Typography variant="caption" color="text.secondary">
            {caption}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        {quickActions.map(({ key, value, disabled, onClick }) => (
          <Tooltip
            key={key}
            title={disabled ? '' : getActionTooltip(intl, key, value)}
          >
            <span>
              {/* TODO: Can this override cursor from the tile? :thinking: */}
              <IconButton
                data-cy={`quick-action-${key}-button`}
                onClickCapture={e => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={disabled}
              >
                <QuickActionIcon
                  fieldKey={key}
                  state={value}
                  disabled={disabled}
                />
              </IconButton>
            </span>
          </Tooltip>
        ))}
        {contextActions ? (
          <>
            <IconButton
              size="small"
              onClick={e => setAnchorEl(e.currentTarget)}
              data-cy="context-action-button"
            >
              <MoreVert
                sx={{
                  color: theme => alpha(theme.palette.action.active, 0.56),
                }}
              />
            </IconButton>
            <Menu
              open={anchorEl !== null}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              {isSchemaTableMultiValue(field)
                ? [
                    <MenuItem
                      key="add-column"
                      data-cy="context-action-add-column-button"
                      onClick={() => {
                        const temporaryColumnId = uuidv4();
                        history.push(`${pathToDetail}/${temporaryColumnId}`, {
                          backLink: history.location.pathname,
                        });
                      }}
                    >
                      <ListItemIcon>
                        <Add fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body2' }}
                      >
                        {intl.formatMessage({
                          id: 'features.queueSettings.fields.contextActions.addColumn',
                        })}
                      </ListItemText>
                    </MenuItem>,
                  ]
                : []}
              {contextActions.map(action => (
                <MenuItem
                  key={action.key}
                  data-cy={`context-action-${action.key}-button`}
                  onClick={() => {
                    action.onClick?.();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <action.icon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {action.label}
                  </ListItemText>
                </MenuItem>
              ))}
              {isSchemaTableMultiValue(field)
                ? [
                    <Divider key="divider" />,
                    <ListSubheader
                      key="subheader"
                      sx={{ backgroundColor: 'transparent', py: 0, my: 0 }}
                    >
                      {intl.formatMessage({
                        id: 'features.queueSettings.fields.contextActions.columns',
                      })}
                    </ListSubheader>,
                    field.children.children.map(column => {
                      const columnIconType = resolveIconType(column);

                      return (
                        <MenuItem
                          key={column.id}
                          data-cy="context-action-child-detail-button"
                          onClick={() => {
                            history.push(`${pathToDetail}/${column.id}`, {
                              backLink: history.location.pathname,
                            });
                          }}
                        >
                          {columnIconType ? (
                            <ListItemIcon>
                              <SchemaFieldIcon
                                fontSize="small"
                                type={columnIconType}
                              />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                          >
                            {column.label}
                          </ListItemText>
                        </MenuItem>
                      );
                    }),
                  ]
                : null}
            </Menu>
          </>
        ) : null}
        <IconButton
          key="navigate"
          component={Link}
          to={pathToDetail}
          data-cy="field-detail-button"
          sx={{
            opacity: isActive ? 1 : 0,
            transition: t =>
              t.transitions.create('opacity', {
                duration: t.transitions.duration.short,
              }),
            color: theme => theme.palette.text.primary,
            '&:hover': {
              color: theme => theme.palette.text.primary,
              textDecoration: 'none',
            },
          }}
        >
          <NavigateNextRounded
            fontSize="small"
            sx={{ color: t => alpha(t.palette.action.active, 0.56) }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};
