import { Queue } from '@rossum/api-client/queues';
import { Box, Paper, Stack, styled, Typography } from '@rossum/ui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import HiddingButton from '../../../../components/UI/HiddingButton';
import {
  ChildrenOrFormattedMessage,
  childrenOrFormattedMessage,
} from '../../../../components/UI/utils/childrenOrFormattedMessage';
import { devHub, helpCenter } from '../../../../constants/values';
import { QueueSettingsBreadcrumbs } from '../../../../features/queue-settings/components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../../../../features/queue-settings/components/QueueSettingsTabs';
import { link } from '../../../../lib/formaterValues';
import { updateQueueDetail } from '../../../../redux/modules/queues/actions';
import { updateSchemaContent } from '../../../../redux/modules/schema/actions';
import { originalSchemaSelector } from '../../../../redux/modules/schema/selectors';
import { Header } from '../../../../ui/header/Header';
import AutomationDataTable from './AutomationDataTable';
import AutomationLevels from './AutomationLevels';
import DefaultScoreThreshold, {
  clampThresholdValue,
} from './DefaultScoreThreshold';
import styles from './style.module.sass';

const SecondaryText = (props: ChildrenOrFormattedMessage) => (
  <Typography variant="body2" color="text.secondary" paragraph>
    {childrenOrFormattedMessage(props)}
  </Typography>
);

const Li = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type Props = {
  selectedQueue: Queue;
  parentPath: string;
};

export const AutomationPage = ({ selectedQueue, parentPath }: Props) => {
  const dispatch = useDispatch();

  const originalSchema = useSelector(originalSchemaSelector);
  const [updatedSchemaTree, setUpdatedSchemaTree] = useState(
    () => originalSchema ?? []
  );

  useEffect(() => {
    setUpdatedSchemaTree(originalSchema ?? []);
  }, [originalSchema]);

  const defaultValues = {
    automationLevel: selectedQueue.automationLevel,
    defaultScoreThreshold: selectedQueue.defaultScoreThreshold,
  };

  const { control, handleSubmit, watch } = useForm<
    Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>
  >({
    mode: 'onSubmit',
    defaultValues,
  });

  const watchValues = watch();

  const queueSettingsChanged = !isEqual(watchValues, defaultValues);
  const schemaChanged = !isEqual(originalSchema, updatedSchemaTree);

  const noChanges = !queueSettingsChanged && !schemaChanged;

  const onSubmit = (data: Partial<Queue>) => {
    if (queueSettingsChanged) {
      dispatch(
        updateQueueDetail(selectedQueue.id, {
          ...data,
          automationEnabled: data.automationLevel !== 'never',
        })
      );
    }
    if (schemaChanged) {
      dispatch(updateSchemaContent(updatedSchemaTree, { withMessage: false }));
    }
  };

  const clampedThresholdValue = clampThresholdValue(
    watchValues.defaultScoreThreshold
  );

  const intl = useIntl();

  const history = useHistory();

  // TODO: This should probably not be copy-pasted around
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              queueName={selectedQueue.name}
              settingsPath={parentPath}
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'containers.settings.queues.queue.automation',
                  }),
                  to: `${parentPath}/automation`,
                },
              ]}
            />
          }
          tabs={
            <QueueSettingsTabs parentPath={parentPath} value="automation" />
          }
          title={intl.formatMessage({
            id: 'containers.settings.queues.queue.automation',
          })}
          description={intl.formatMessage({
            id: 'containers.settings.queues.queue.automation.description',
          })}
          onBackButtonClicked={onBackButtonClicked}
        />
      )}
    >
      <Paper sx={{ py: 2, m: 3 }}>
        <div className={styles.WidthLimit}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={5} sx={{ marginTop: 4 }}>
              <Box component="section">
                <Typography variant="h6">
                  {intl.formatMessage({
                    id: 'containers.settings.automation.documentAutomation.title',
                  })}
                </Typography>
                <SecondaryText
                  id="containers.settings.automation.documentAutomation.description"
                  values={{
                    link: link(devHub('docs/data-capture-automation')),
                  }}
                />
                <AutomationLevels control={control} />
              </Box>
              <Box component="section">
                <Typography variant="h6">
                  {intl.formatMessage({
                    id: 'containers.settings.automation.fieldAutomation.title',
                  })}
                </Typography>
                <SecondaryText
                  id="containers.settings.automation.fieldAutomation.description"
                  values={{
                    link: link(
                      helpCenter('/article/fields-with-grey-or-green-ticks/')
                    ),
                  }}
                />
                <DefaultScoreThreshold
                  control={control}
                  defaultValue={defaultValues.defaultScoreThreshold}
                />
              </Box>
              <SecondaryText
                id="containers.settings.automation.fieldAutomation.threshold.description"
                values={{
                  link: link(`${parentPath}/fields`),
                }}
              />
              <Box component="section">
                <Typography variant="h6">
                  {intl.formatMessage({
                    id: 'containers.settings.automation.automationData.title',
                  })}
                </Typography>

                <SecondaryText id="containers.settings.automation.automationData.description_1" />
                <SecondaryText id="containers.settings.automation.automationData.description_2" />

                <ol>
                  {(
                    [
                      'containers.settings.automation.automationData.li_1',
                      'containers.settings.automation.automationData.li_2',
                      'containers.settings.automation.automationData.li_3',
                    ] as const
                  ).map(key => (
                    <Li key={key}>{intl.formatMessage({ id: key })}</Li>
                  ))}
                </ol>
              </Box>
              <AutomationDataTable
                queueId={selectedQueue.id}
                queueThreshold={clampedThresholdValue}
                schema={updatedSchemaTree}
                updateSchema={setUpdatedSchemaTree}
              />
            </Stack>

            <div className={styles.ButtonWrapper}>
              <HiddingButton
                hideCondition={noChanges}
                messageId="containers.settings.automation.save"
                type="submit"
              />
            </div>
          </form>
        </div>
      </Paper>
    </PageLayoutV2>
  );
};
