import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type LabeledValueInRowProps = {
  label: LocalizationKeys;
  labelColor?: string;
  value: string;
  valueColor?: string;
  delimiter?: string;
};

const LabeledValueInRow = ({
  labelColor = 'text.secondary',
  valueColor = 'text.white',
  delimiter = '',
  label,
  value,
}: LabeledValueInRowProps) => {
  const intl = useIntl();

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography variant="body2" color={labelColor}>
        {intl.formatMessage({
          id: label,
        })}
        {delimiter}
      </Typography>
      <Typography variant="body2" color={valueColor}>
        {value}
      </Typography>
    </Stack>
  );
};

export { LabeledValueInRow };
