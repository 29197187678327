import { forwardRef, MouseEventHandler, useCallback } from 'react';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox, BoundingBoxProps } from './BoundingBox';

type SuggestedOperationBoxProps = {
  operationId: number;
  position: Rectangle2DCoordinates;
  active: boolean;
  onClick: (e: React.MouseEvent<SVGRectElement>, operationId: number) => void;
  onMoveEnd: (operationId: number, position: Rectangle2DCoordinates) => void;
  onResizeEnd: (operationId: number, position: Rectangle2DCoordinates) => void;
  boundingBoxProps?: Partial<BoundingBoxProps>;
};

const SuggestedOperationBox = forwardRef<
  SVGRectElement,
  SuggestedOperationBoxProps
>(
  (
    {
      operationId,
      position,
      boundingBoxProps,
      active,
      onClick,
      onMoveEnd,
      onResizeEnd,
    },
    ref
  ) => {
    const setHoveredBbox = useDocumentStore(state => state.setHoveredBbox);

    const handleClick: MouseEventHandler<SVGRectElement> = useCallback(
      e => {
        onClick(e, operationId);
      },
      [onClick, operationId]
    );

    return (
      <BoundingBox
        ref={ref}
        position={position}
        canResize={active}
        interactive
        onClick={handleClick}
        onResizeEnd={newPosition => onResizeEnd(operationId, newPosition)}
        onMoveEnd={newPosition => onMoveEnd(operationId, newPosition)}
        onMouseOver={() => setHoveredBbox(operationId)}
        onMouseOut={() => setHoveredBbox(undefined)}
        {...boundingBoxProps}
      />
    );
  }
);

SuggestedOperationBox.displayName = 'SuggestedOperationBox';

export { SuggestedOperationBox };
