import * as R from 'remeda';
import { MQLGt, MQLIn, MQLOr, MQLRegex, parseMQLOr } from './mql';

type RegexType = string;
type SchemaIdType = string;

// Client model

type TriggerClientModelEnumField = {
  field: SchemaIdType;
  values: string[];
};

export type TriggerClientModel = {
  enumFields: TriggerClientModelEnumField[];
  numberOfPages: number;
  filenameRegex: string;
};

type FieldKey = `field.${SchemaIdType}`;
type EnumFieldCondition = Record<FieldKey, MQLIn<string>>;
type NumberOfPagesCondition = { number_of_pages: MQLGt };
type FilenameCondition = {
  filename: MQLOr<MQLRegex<RegexType>> | MQLRegex<RegexType>;
};

type RootCondition =
  | NumberOfPagesCondition
  | FilenameCondition
  | EnumFieldCondition;

export type TriggerConditionApiModel =
  | MQLOr<RootCondition>
  | Record<string, never>;

const isFilenameCondition = (
  condition: RootCondition
): condition is FilenameCondition => {
  return 'filename' in condition;
};

const isNumberOfPagesCondition = (
  condition: RootCondition
): condition is NumberOfPagesCondition => {
  return 'number_of_pages' in condition;
};

const isEnumFieldCondition = (
  condition: RootCondition
): condition is EnumFieldCondition => {
  return Object.keys(condition).every(key => key.startsWith('field.'));
};

const toClientCondition = (
  apiCondition: EnumFieldCondition
): TriggerClientModelEnumField[] => {
  return Object.keys(apiCondition).map(key => {
    const field = key.replace(/^field\./, '');
    return {
      field,
      values: apiCondition[key as FieldKey].$in,
    };
  });
};

export const toTriggerClientModel = (
  triggerCondition: TriggerConditionApiModel
): TriggerClientModel => {
  const conditions =
    triggerCondition && '$or' in triggerCondition
      ? parseMQLOr(triggerCondition)
      : [];
  const filenameRegex = parseMQLOr(
    conditions.find(isFilenameCondition)?.filename
  )
    .map(clause => clause.$regex)
    .join('\n');
  const numberOfPages =
    conditions.find(isNumberOfPagesCondition)?.number_of_pages.$gt ?? 0;
  const enumFields =
    conditions.filter(isEnumFieldCondition).flatMap(toClientCondition) || [];

  return {
    filenameRegex,
    numberOfPages,
    enumFields,
  };
};

const toTriggerApiFieldConditions = (
  conditions: TriggerClientModelEnumField[]
): EnumFieldCondition[] => {
  return (
    conditions
      // Filter out empty conditions
      .filter(condition => condition.values.length)
      .map(condition => ({
        [`field.${condition.field}`]: {
          $in: condition.values,
        },
      }))
  );
};

export const toTriggerApiModel = (
  clientModel: TriggerClientModel
): TriggerConditionApiModel => {
  const numberOfPagesCondition = clientModel.numberOfPages && {
    number_of_pages: { $gt: clientModel.numberOfPages },
  };

  const filenameRegexCondition = clientModel.filenameRegex && {
    filename: {
      $or: clientModel.filenameRegex
        .split(/\r?\n/)
        .filter(R.isTruthy)
        .map($regex => ({ $regex })),
    },
  };

  const enumFieldConditions = toTriggerApiFieldConditions(
    clientModel.enumFields
  );

  const clauses: RootCondition[] = R.pipe(
    [...enumFieldConditions, filenameRegexCondition, numberOfPagesCondition],
    R.filter(R.isTruthy)
  );

  return clauses.length ? { $or: clauses } : {};
};
