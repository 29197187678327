import { matchPath } from 'react-router';
import { fieldManagerPath } from '../features/field-manager/constants';
import { fieldCreateRoute } from '../features/field-manager/field-manager/routes';

const routeKeyMap = {
  '/settings': 'settings',
  '/extensions/store': 'store',
  '/extensions/logs': 'extensions.logs',
  '/extensions/my-extensions': 'extensions',
  '/extensions/my-extensions/create': 'extensions.create',
  '/automation': 'automation',
  '/automation/engines': 'automation',
  '/requests': 'requests',
  '/documents': 'documents',
  '/statistics': 'statistics',
  '/account/personalInfo': 'account',
  '/account/changePassword': 'account',
  '/settings/users': 'settings.users',
  '/settings/labels': 'settings.labels',
  '/billing/overview': 'billing',
  '/billing/statistics': 'billing',
  '/billing/history': 'billing',
  [fieldCreateRoute(fieldManagerPath())]: 'settings.fieldManager.create',
  [fieldManagerPath()]: 'settings.fieldManager',
} as const;

const isMatching = (pathname: string) => (route: string) => {
  const match = matchPath(pathname, {
    path: route,
    exact: true,
  });
  return match ? match.isExact : false;
};

export const resolvePageTitle = (pathname: string) => {
  const [_, routeKey] =
    Object.entries(routeKeyMap).find(([key]) => isMatching(pathname)(key)) ??
    [];

  return routeKey;
};
