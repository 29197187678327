import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IconInfoCircle } from '@rossum/ui/icons/tabler';
import { SvgIcon, Tooltip } from '@rossum/ui/material';
import clsx from 'clsx';
import { chain, get, isEmpty, take } from 'lodash';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ContentPasteIcon from 'mdi-react/ContentPasteIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { from, fromEvent, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ENUM_EDITOR_LINK } from '../../../../../../constants/values';
import { getEventPath } from '../../../../../../lib/DOM';
import { linebreak, link, white } from '../../../../../../lib/formaterValues';
import { C_KEY_CODE, V_KEY_CODE } from '../../../../../../lib/keyboard';
import {
  insertInArray,
  swapItemsInArray,
  updateInArray,
} from '../../../../../../redux/modules/utils';
import { EnumOption } from '../../../../../../types/schema';
import { useOpenModal } from '../../../../../../utils/hooks/useOpenModal';
import { useCopyPastePermissions } from '../../lib/enumEditorHooks';
import sharedStyles from '../EnumEditor/styles.module.sass';
import EnumEditorItem from './EnumEditorItem';
import styles from './styles.module.sass';

const defaultOption = { value: '', label: '' };

const isLabel = <T extends EventTarget>(path: T[]) =>
  path.some(
    el =>
      'className' in el &&
      typeof el.className === 'string' &&
      el.className.includes('LabelInput')
  );

const textToOptions = (text: string) =>
  chain(text)
    .split('\n')
    .map(row => {
      const option = row.split('\t');
      return { value: get(option, '[0]', ''), label: get(option, '[1]', '') };
    })
    .filter(item => {
      return !(isEmpty(item.value) && isEmpty(item.label));
    })
    .value();

type Props = {
  options: Array<EnumOption>;
  onCurrentSchemaPartChange: (_options: Array<EnumOption>) => void;
  showPasteText?: boolean;
};

const EnumEditorInDrawer = ({
  options,
  onCurrentSchemaPartChange,
  showPasteText = true,
}: Props) => {
  const [pasteIsHovered, setPasteIsHovered] = useState<boolean>(false);
  const [clipboardText, setClipboardText] = useState<string>('');
  const [optionsVersion, setOptionsVersion] = useState<number>(Date.now);
  const [previewOptions, setPreviewOptions] = useState<Array<EnumOption>>([]);
  const [currentLine, setCurrentLine] = useState<number | undefined>(undefined);

  const { pasteIsAllowed, pasteIsSupported } = useCopyPastePermissions();

  const [DialogElement, openModal] = useOpenModal();

  useEffect(() => {
    const clipboardTextSubscription =
      pasteIsAllowed &&
      from(navigator.clipboard.readText())
        .pipe(catchError(() => of('')))
        .subscribe((text: string) => setClipboardText(text));

    return () => {
      if (clipboardTextSubscription) clipboardTextSubscription.unsubscribe();
    };
  });

  useEffect(
    () => setPreviewOptions(!clipboardText ? [] : textToOptions(clipboardText)),
    [clipboardText]
  );

  const showPreviewOption = pasteIsHovered && previewOptions.length;
  const currentOptions = showPreviewOption ? previewOptions : options;
  const emptyOptions = !currentOptions.length;
  const withTmpOption = emptyOptions ? [] : currentOptions;
  const sortableOptions = withTmpOption.map(
    (item, index) => `${item.value}-${item.label}-${index}`
  );

  useEffect(() => {
    if (!currentLine) return undefined;

    const copySubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        filter(e => e.metaKey || e.ctrlKey),
        filter(({ keyCode }) => keyCode === C_KEY_CODE),
        map(getEventPath),
        map(path => {
          return get(withTmpOption[currentLine], [
            isLabel(path) ? 'label' : 'value',
          ]);
        })
      )
      .subscribe((value: string) =>
        navigator.clipboard.writeText(value).catch(() => {})
      );
    return () => {
      copySubscription.unsubscribe();
    };
  });

  useEffect(() => {
    if (!currentLine) return undefined;

    const pasteSubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        filter(e => e.metaKey || e.ctrlKey),
        filter(({ keyCode }) => keyCode === V_KEY_CODE),
        filter(() => pasteIsAllowed),
        filter(() => !!clipboardText),
        tap(event => event.preventDefault()),
        map(getEventPath)
      )
      .subscribe(path => {
        if (clipboardText.includes('\n')) {
          const newOptions = textToOptions(clipboardText);
          const headOptions = take(options, currentLine + 1) || [];
          onCurrentSchemaPartChange([...headOptions, ...newOptions]);
        } else if (clipboardText.includes('\t')) {
          const option = clipboardText.split('\t');
          const newOption = {
            value: get(option, '[0]', ''),
            label: get(option, '[1]', ''),
          };
          onCurrentSchemaPartChange(
            updateInArray(options, currentLine, _option => newOption)
          );
        } else
          onCurrentSchemaPartChange(
            updateInArray(options, currentLine, _option => ({
              ..._option,
              [isLabel(path) ? 'label' : 'value']: clipboardText,
            }))
          );
      });

    return () => {
      pasteSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    const onBlurSubscription = fromEvent(window, 'click')
      .pipe(
        filter(() => optionsVersion !== undefined),
        filter(
          event =>
            !getEventPath(event).some(element =>
              ['addOption', 'option'].includes(
                'id' in element && typeof element.id === 'string'
                  ? element.id
                  : ''
              )
            )
        )
      )
      .subscribe(() => setCurrentLine(undefined));
    return () => onBlurSubscription.unsubscribe();
  });

  const reRenderInputs = () => setOptionsVersion(Date.now);

  useEffect(() => {
    if (currentLine !== undefined && currentLine > withTmpOption.length - 1) {
      setCurrentLine(undefined);
    }
  }, [currentLine, options.length, withTmpOption.length]);

  const addLine = () => {
    const index = currentLine ? currentLine + 1 : options.length;

    onCurrentSchemaPartChange(insertInArray(options, index, defaultOption));

    setCurrentLine(index);
    reRenderInputs();
  };

  const onCopy = () => {
    const optionsText = options
      .map(({ value, label: _label }) => `${value}\t${_label}`)
      .join('\n');
    navigator.clipboard.writeText(optionsText).catch(() => {});
  };

  const onPaste = () => {
    if (pasteIsHovered && previewOptions.length) {
      onCurrentSchemaPartChange(previewOptions);
    }
  };

  const removeAll = () => {
    onCurrentSchemaPartChange([]);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const activeFieldIndex = sortableOptions.indexOf(`${active.id}`);
      const overFieldIndex = sortableOptions.indexOf(`${over.id}`);

      onCurrentSchemaPartChange(
        swapItemsInArray(options, activeFieldIndex, overFieldIndex)
      );
    }
  };

  const pasteTooltip = pasteIsAllowed ? (
    previewOptions.length ? (
      <FormattedMessage
        id="components.enumEditor.paste"
        values={{
          linebreak,
          hint: (msg: ReactNode) => <span>{msg}</span>,
        }}
      />
    ) : (
      <FormattedMessage id="components.enumEditor.noContent" />
    )
  ) : pasteIsSupported ? (
    <FormattedMessage id="components.enumEditor.pasteIsNotAllowed" />
  ) : (
    <FormattedMessage id="components.enumEditor.pasteIsNotSupported" />
  );

  return (
    <div className={styles.EnumEditorInDrawer}>
      <div className={styles.ControlBar}>
        <div className={styles.OptionsCount}>
          <FormattedMessage id="components.enumEditor.enumOptions" />
          <div className={styles.CountNumber}>
            {showPreviewOption ? previewOptions.length : options.length}
          </div>
          <Tooltip
            title={
              <FormattedMessage
                id="components.enumEditor.helpTooltip"
                values={{ link: link(ENUM_EDITOR_LINK), linebreak }}
              />
            }
          >
            <SvgIcon>
              <IconInfoCircle />
            </SvgIcon>
          </Tooltip>
        </div>
        <div className={styles.ActionButtons}>
          <Tooltip
            title={<FormattedMessage id="components.enumEditor.addItem" />}
          >
            <div
              className={styles.ActionButton}
              onClick={addLine}
              id="addOption"
            >
              <PlusIcon size={14} />
            </div>
          </Tooltip>
          <Tooltip
            title={<FormattedMessage id="components.enumEditor.copyToClip" />}
          >
            <div className={styles.ActionButton} onClick={onCopy}>
              <ContentCopyIcon size={14} />
            </div>
          </Tooltip>
          <div
            onClick={() => pasteIsAllowed && onPaste()}
            onMouseEnter={() => pasteIsAllowed && setPasteIsHovered(true)}
            onMouseLeave={() => pasteIsAllowed && setPasteIsHovered(false)}
          >
            <Tooltip
              key={`{pasteTooltip-${!!previewOptions}`}
              title={previewOptions === undefined ? '' : pasteTooltip}
            >
              <div
                className={clsx(
                  styles.ActionButton,
                  (!pasteIsAllowed || !previewOptions.length) &&
                    sharedStyles.PasteDisabled
                )}
              >
                <ContentPasteIcon size={14} />
              </div>
            </Tooltip>
          </div>
          <Tooltip
            title={<FormattedMessage id="components.enumEditor.deleteAll" />}
          >
            <div
              onClick={() => {
                if (emptyOptions) return;

                openModal({
                  textId: 'enumEditor.removeAll',
                  onConfirm: removeAll,
                  values: {
                    white,
                  },
                });
              }}
              className={clsx(
                styles.ActionButton,
                sharedStyles.DeleteIcon,
                emptyOptions && sharedStyles.Disabled
              )}
            >
              <DeleteIcon size={14} />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.Editor}>
        {showPasteText && pasteIsAllowed && (
          <div className={sharedStyles.InfoWithLink}>
            <FormattedMessage
              id="components.enumEditor.info"
              values={{ link: link(`${ENUM_EDITOR_LINK}`), linebreak }}
            />
          </div>
        )}
        <div>
          {!!withTmpOption.length && (
            <div className={clsx(styles.OptionInputs, styles.Labels)}>
              <span className={styles.Input}>
                <FormattedMessage id="components.enumEditor.id" />
                <Tooltip
                  title={
                    <FormattedMessage id="components.enumEditor.id.tooltip" />
                  }
                >
                  <SvgIcon fontSize="small">
                    <IconInfoCircle />
                  </SvgIcon>
                </Tooltip>
              </span>
              <span className={styles.Input}>
                <FormattedMessage id="components.enumEditor.label" />
              </span>
            </div>
          )}
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={sortableOptions}
              strategy={verticalListSortingStrategy}
            >
              {withTmpOption.map((option: EnumOption, i: number) => (
                <EnumEditorItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  enumId={sortableOptions[i]}
                  enumIndex={i}
                  enumItem={option}
                  optionsAreEmpty={emptyOptions}
                  options={options}
                  currentLine={currentLine}
                  setCurrentLine={setCurrentLine}
                  onCurrentSchemaPartChange={onCurrentSchemaPartChange}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
      {DialogElement}
    </div>
  );
};

export default EnumEditorInDrawer;
