import {
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { OperationLog } from 'libs/mdh-api-client/src';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DATASETS_TASKS_KEY } from '../../../redux/modules/localStorage/actions';
import { datasetApi, getDatasetRequestConfig } from '../api';

const DATASET_TASK_QUERY_KEY = 'dataset-task';

type DatasetContext = {
  datasetTaskResults: UseQueryResult<OperationLog>[];
  registerTask: (task: Task) => void;
  unregisterTask: (id: string) => void;
  datasetTasks: Task[];
};

export type TaskTypes = 'delete' | 'update' | 'create';

type Task = {
  taskId: string;
  type: TaskTypes;
  datasetName: string;
  onSuccess?: (res: AxiosResponse<OperationLog, unknown>) => void;
  onFailed?: (res: AxiosResponse<OperationLog, unknown>) => void;
};

const Context = createContext<DatasetContext>({
  datasetTaskResults: [],
  registerTask: () => {},
  unregisterTask: () => {},
  datasetTasks: [],
});

export const DatasetContext = ({ children }: { children: ReactNode }) => {
  const [datasetTasks, setDatasetTasks] = useState<Task[]>([]);

  const datasetTaskResults = useQueries<UseQueryOptions<OperationLog>[]>({
    queries: datasetTasks.map(({ taskId, onSuccess, onFailed }) => ({
      queryKey: [DATASET_TASK_QUERY_KEY, taskId] as const,
      queryFn: () =>
        datasetApi
          .getOperationApiV2OperationOperationIdGet(
            taskId,
            getDatasetRequestConfig()
          )
          .then(res => {
            if (res.data.status === 'FINISHED') {
              unregisterTask(taskId);
              onSuccess?.(res);
            }
            if (res.data.status === 'FAILED') {
              unregisterTask(taskId);
              onFailed?.(res);
            }

            return res.data;
          }),
      refetchInterval: (data, query) => {
        if (
          query?.state.status === 'error' ||
          data?.status === 'FINISHED' ||
          data?.status === 'FAILED'
        )
          return 0;

        return 2000 * 1;
      },
    })),
  });

  const registerTask = useCallback(
    (task: Task) => {
      const updatedTasks = datasetTasks.concat(task);
      setDatasetTasks(updatedTasks);
      localStorage.setItem(DATASETS_TASKS_KEY, JSON.stringify(updatedTasks));
    },
    [datasetTasks]
  );

  const unregisterTask = useCallback(
    (id: string) => {
      const updatedTasks = datasetTasks.filter(task => task.taskId !== id);
      setDatasetTasks(updatedTasks);
      localStorage.setItem(DATASETS_TASKS_KEY, JSON.stringify(updatedTasks));
    },
    [datasetTasks]
  );

  const value = useMemo<DatasetContext>(() => {
    return {
      datasetTaskResults,
      datasetTasks,
      registerTask,
      unregisterTask,
    };
  }, [datasetTaskResults, datasetTasks, registerTask, unregisterTask]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDatasetContext = () => {
  const context = useContext(Context);

  if (context === undefined)
    throw new Error('Dataset context must be used wihin Dataset Context');

  return context;
};
