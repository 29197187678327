import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../i18n';
import styles from './styles.module.sass';

type Props = {
  onLeft?: ReactNode;
  title: LocalizationKeys;
  onRight?: ReactNode;
  className?: string;
};

const Header = ({ onLeft, title, onRight = null, className = '' }: Props) => (
  <div className={clsx(styles.Header, className)}>
    <div className={styles.HeaderPanel}>
      {onLeft && <div className={styles.OnLeftPlaceholder}>{onLeft}</div>}
      <span className={styles.PageTitle}>
        <FormattedMessage id={title} />
      </span>
    </div>
    {onRight && <div className={styles.OnRightPlaceholder}>{onRight}</div>}
  </div>
);

export default Header;
