import { Page } from '@rossum/api-client/pages';
import { useMemo } from 'react';
import * as R from 'remeda';
import { isFeatureFlagEnabled } from '../../../unleash/useFeatureFlag';
import { Rectangle2D } from '../document-canvas/utils/geometry';
import { Dimensions } from '../document-store/documentGeometry';

// Document canvas settings
/** Spaces in between pages */
const DOCUMENT_CANVAS_PAGE_SPACING = 50;

/** Top and bottom free space on canvas, next to pages */
const DOCUMENT_CANVAS_PAGE_MARGIN_TOP = isFeatureFlagEnabled(
  'ac-5499-new-sidebar'
)
  ? 52
  : 100;
const DOCUMENT_CANVAS_PAGE_MARGIN_BOTTOM = 200;

/** Left and right free space on canvas, next to pages */
const DOCUMENT_CANVAS_PAGE_MARGIN_HORIZONTAL = 200;

export type PageRectangle = {
  pageNumber: number;
  pageIndex: number;
  dimensions: Rectangle2D;
};

export type UseCanvasDimensions = {
  canvas: Dimensions;
  pages: PageRectangle[];
};

export const useCanvasDimensions = (pages: Page[]): UseCanvasDimensions =>
  useMemo(() => {
    const canvasWidth =
      Math.max(0, ...pages.map(page => page.width)) +
      DOCUMENT_CANVAS_PAGE_MARGIN_HORIZONTAL;

    const getPageY = (previousPage: PageRectangle | undefined) =>
      !previousPage
        ? DOCUMENT_CANVAS_PAGE_MARGIN_TOP
        : previousPage.dimensions.y +
          DOCUMENT_CANVAS_PAGE_SPACING +
          previousPage.dimensions.height;

    const pageDimensions = pages.reduce<PageRectangle[]>(
      (acc, page, i) => [
        ...acc,
        {
          pageIndex: i,
          pageNumber: page.number,
          dimensions: {
            x: (canvasWidth - page.width) / 2,
            y: getPageY(acc[i - 1]),
            width: page.width,
            height: page.height,
          },
        },
      ],
      []
    );

    const lastPage = R.last(pageDimensions);
    const canvasHeight = lastPage
      ? lastPage.dimensions.y +
        lastPage.dimensions.height +
        DOCUMENT_CANVAS_PAGE_MARGIN_BOTTOM
      : 0;

    const canvasDimensions = {
      width: canvasWidth,
      height: canvasHeight,
    };

    return {
      canvas: canvasDimensions,
      pages: pageDimensions,
    };
  }, [pages]);
