import { WarningAmber as WarningAmberIcon } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../i18n';
import { Severity } from './helpers';

type Props = {
  messageKey: LocalizationKeys;
  severity: Severity;
};

const bannerBackground: Record<Severity, string> = {
  low: 'action.disabledBackground',
  medium: 'warning.main',
} as const;

const bannerColor: Record<Severity, string> = {
  low: 'warning.light',
  medium: 'warning.contrastText',
};

const bannerIcon: Record<Severity, ReactNode> = {
  low: <WarningAmberIcon />,
  medium: <WarningAmberIcon />,
};

const getUIConfig = (severity: Severity) => {
  return {
    backgroundColor: bannerBackground[severity],
    color: bannerColor[severity],
    icon: bannerIcon[severity],
  };
};

const OverduePaymentsBanner = ({ severity, messageKey }: Props) => {
  const intl = useIntl();
  const { icon, backgroundColor, color } = getUIConfig(severity);

  return (
    <Stack
      sx={{ backgroundColor, minHeight: 40 }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography color={color} component="div" variant="body2">
        <Stack direction="row" spacing={1} sx={{ svg: { fontSize: '1.5em' } }}>
          {icon}
          <span>{intl.formatMessage({ id: messageKey })}</span>
        </Stack>
      </Typography>
    </Stack>
  );
};

export { OverduePaymentsBanner };
