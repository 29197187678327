import { CancelRounded } from '@rossum/ui/icons';
import {
  Chip,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  areColumnsEqual,
  isFilterAdvanced,
  isFilterSimple,
} from '../../../../document-list-base/mql/helpers';
import { FilterOperator } from '../../../../document-list-base/mql/operators';
import {
  FilterComponentProps,
  FilterItem,
  FilterValues,
} from '../../../../document-list-base/mql/types';
import { useFetchAsyncChipValues } from '../../../hooks/useFetchAsyncChipValues';
import { getChipLabel, getChipTooltip } from './utils';

export const FilterChip = ({
  onRemove,
  children,
  incomingFilterItem,
}: {
  incomingFilterItem: FilterItem;
  onRemove: () => void;
  children: (params: {
    onClose: () => void;
    convertToAdvancedFilter: FilterComponentProps['convertToAdvancedFilter'];
    onOperatorChange: (operator: FilterOperator) => void;
    filterItem: FilterItem;
  }) => ReactNode;
}) => {
  const intl = useIntl();
  const [localFilterItem, setLocalFilterItem] = useState<FilterItem | null>(
    incomingFilterItem
  );

  const { column } = incomingFilterItem;

  const filterItem = useFetchAsyncChipValues({
    filterItem: incomingFilterItem,
  });

  if (localFilterItem && !areColumnsEqual(column, localFilterItem.column)) {
    setLocalFilterItem({ ...localFilterItem, column });
  }

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { columnLabel, selectedOptions } = getChipLabel(filterItem);

  const onClose = () => setAnchorEl(null);

  const tooltip = getChipTooltip(filterItem);

  const onOperatorChange = useCallback(
    (operator: FilterOperator, value?: unknown) => {
      if (localFilterItem && isFilterSimple(localFilterItem))
        setLocalFilterItem({
          ...localFilterItem,
          filterContext: { ...localFilterItem.filterContext, value, operator },
        });
    },
    [localFilterItem]
  );

  const convertToAdvancedFilter = (
    operator: FilterOperator,
    existingInput?: string | string[]
  ) => {
    if (localFilterItem && isFilterSimple(localFilterItem)) {
      const newFilterContext: FilterValues[] = [
        {
          operator,
          value: existingInput ?? localFilterItem.filterContext.value,
        },
      ];

      setLocalFilterItem({
        ...localFilterItem,
        filterContext: newFilterContext,
      });
    }
  };

  return (
    <>
      <Tooltip
        title={tooltip}
        placement="top"
        slotProps={{
          tooltip: { sx: { maxHeight: 100, overflowY: 'auto' } },
          arrow: { sx: { display: 'none' } },
        }}
      >
        <Chip
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setLocalFilterItem(filterItem);
          }}
          label={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                {columnLabel}
              </Typography>

              {selectedOptions ? (
                selectedOptions.map(({ operatorKey, selectedOption }) => (
                  <Stack direction="row" key={operatorKey}>
                    {operatorKey ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'secondary.light',
                          mr: 0.5,
                        }}
                      >
                        {intl.formatMessage({
                          id: `containers.filtering.operators.${operatorKey}`,
                        })}
                      </Typography>
                    ) : null}
                    {filterItem.column.areValuesLoading ? (
                      <Skeleton variant="text" width={50} />
                    ) : (
                      <Typography
                        variant="body2"
                        maxWidth={150}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        fontWeight="bold"
                      >
                        {selectedOption}
                      </Typography>
                    )}
                  </Stack>
                ))
              ) : isFilterAdvanced(filterItem) ? (
                <Typography variant="body2">
                  {intl.formatMessage(
                    { id: 'containers.filtering.chip.ruleCount' },
                    { count: filterItem.filterContext.length }
                  )}
                </Typography>
              ) : null}
            </Stack>
          }
          onDelete={onRemove}
          deleteIcon={<CancelRounded />}
          data-cy={`all-documents-filter-chip-${filterItem.column.field}`}
        />
      </Tooltip>
      <Popover
        open={!!anchorEl}
        onClose={onClose}
        onTransitionExited={() => setLocalFilterItem(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {children({
          onClose,
          convertToAdvancedFilter,
          filterItem: localFilterItem ?? filterItem,
          onOperatorChange,
        })}
      </Popover>
    </>
  );
};
