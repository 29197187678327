import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../../lib/apiClient';

const QUERY_KEY_SUGGESTED_RECIPIENTS = 'suggested-queue-recipients';

type Params = {
  emailThreadUrl: string | undefined;
  queueUrl: string;
  annotationUrl: Url | null | undefined;
};

export const useSuggestedRecipients = (params: Params) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [
      QUERY_KEY_SUGGESTED_RECIPIENTS,
      params.emailThreadUrl,
      params.annotationUrl,
      params.queueUrl,
    ] as const,

    queryFn: ({ queryKey: [, emailThreadUrl, annotationUrl, queueUrl] }) => {
      return api.request(
        endpoints.queues.suggestedRecipients(getIDFromUrl(queueUrl), {
          annotations: annotationUrl ? [annotationUrl] : [],
          emailThreads: emailThreadUrl ? [emailThreadUrl] : [],
        })
      );
    },
    enabled: !!params.emailThreadUrl || !!params.annotationUrl,
    select: response => response.results,
  });
};
