import { getIDFromUrl } from '@rossum/api-client';
import { Page } from '@rossum/api-client/pages';
import { LinearProgress, useTheme } from '@rossum/ui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { BoundingBoxesLayer } from '../../../../components/DocumentPage/BoundingBoxesLayer';
import { PageSpaceProviderSVG } from '../../../../components/DocumentPage/PageSpaceContext';
import { currentMultivalueDatapointSelector } from '../../../../redux/modules/datapoints/selector';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { useFeatureFlag } from '../../../../unleash/useFeatureFlag';
import { ComplexLineItemsLabels } from '../../document-canvas/components/ComplexLineItemsLabels/ComplexLineItemsLabels';
import { Rectangle2D } from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { useTranslateDocument } from '../../document-translation/hooks/useTranslateDocument';
import { useTranslationEnabled } from '../../document-translation/hooks/useTranslationEnabled';
import { TranslationBoxesLayer } from '../../document-translation/TranslationBoxesLayer';
import { useKeyPressed } from '../useKeyPressed';

export type PageContentProps = {
  page: Page;
  pageRef: React.MutableRefObject<SVGSVGElement | null>;
  contentBlob: string;
  canvasCoordinates: Rectangle2D;
};

export const PageContent = ({
  page,
  pageRef,
  contentBlob,
  canvasCoordinates,
}: PageContentProps) => {
  const theme = useTheme();

  const location = useLocation();
  const suggestionsEnabled = useKeyPressed({ keys: ['Alt'] });

  const isTranslationEnabled = useTranslationEnabled();

  const isNewSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');

  const hideTranslations = useKeyPressed({
    keys: ['t'],
    metaKey: 'ctrlKey',
    options: {
      enabled: isTranslationEnabled,
    },
  });

  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const { targetLanguage } = useDocumentStore(state => state.translationState);

  const annotationId = getIDFromUrl(location.pathname);

  const { data, isFetching: isTranslating } = useTranslateDocument({
    annotationId,
    targetLanguage,
    pageNumber: page.number,
  });

  const translatedPage = targetLanguage
    ? data?.results.find(
        translatedPage => translatedPage.pageNumber === page.number
      )
    : undefined;

  return (
    <PageSpaceProviderSVG
      canvasDimensions={canvasCoordinates}
      pageRef={pageRef}
    >
      <defs>
        <clipPath id={`clip-${page.number}`}>
          <rect
            x={0}
            y={0}
            width={canvasCoordinates.width}
            height={canvasCoordinates.height}
            rx={theme.shape.borders.large}
            ry={theme.shape.borders.large}
          />
        </clipPath>
      </defs>
      {isNewSidebarEnabled ? (
        <rect
          x={-1}
          y={-1}
          width={canvasCoordinates.width + 2}
          height={canvasCoordinates.height + 2}
          fill="none"
          stroke={theme.palette.divider}
          rx={theme.shape.borders.large}
          ry={theme.shape.borders.large}
        />
      ) : null}
      <image
        href={contentBlob}
        width={canvasCoordinates.width}
        height={canvasCoordinates.height}
        data-page-number={page.number}
        filter={isTranslating ? 'blur(3px)' : ''}
        clipPath={isNewSidebarEnabled ? `url(#clip-${page.number})` : undefined}
      />
      {isTranslating ? (
        <foreignObject width={canvasCoordinates.width} height={2}>
          <LinearProgress />
        </foreignObject>
      ) : null}

      <BoundingBoxesLayer
        page={page}
        activeBboxRef={undefined}
        suggestionsEnabled={suggestionsEnabled}
      />
      {isTranslationEnabled && !hideTranslations && translatedPage ? (
        <TranslationBoxesLayer translations={translatedPage.items} />
      ) : null}

      {complexLineItemsEnabled &&
        currentMultivalueDatapoint &&
        !currentMultivalueDatapoint.meta.isSimpleMultivalue && (
          <ComplexLineItemsLabels
            multivalue={currentMultivalueDatapoint}
            page={page.number}
          />
        )}
    </PageSpaceProviderSVG>
  );
};
