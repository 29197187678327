import { Stack } from '@rossum/ui/material';
import { SchemaFieldIconType } from '../../../../ui/schema-field-icon/SchemaFieldIcon';
import { CellIcons } from './CellIcons';

type HeaderCellProps = {
  iconType?: SchemaFieldIconType | null;
  headerName?: string;
};

const HeaderCell = ({ headerName, iconType }: HeaderCellProps) => (
  <Stack
    spacing={0.5}
    direction="row"
    component="span"
    sx={{ fontWeight: 500 }}
    alignItems="center"
  >
    {iconType ? <CellIcons type={iconType} /> : null}
    <span>{headerName}</span>
  </Stack>
);

export { HeaderCell };
