import { Link, Typography } from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Breadcrumb = ({
  to,
  children,
}: {
  to?: LocationDescriptor;
  children: ReactNode;
}) => {
  return to ? (
    <Link
      component={RouterLink}
      to={to}
      variant="body2"
      fontWeight={600}
      color="text.secondary"
      underline="none"
      sx={{ '&:hover': { textDecoration: 'none' } }}
    >
      {children}
    </Link>
  ) : (
    <Typography
      variant="body2"
      color="text.secondary"
      fontWeight={400}
      lineHeight="normal"
    >
      {children}
    </Typography>
  );
};
