import { ArrowRightRounded } from '@rossum/ui/icons';
import { Divider, Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { Fragment, memo } from 'react';
import { LIST_ITEM_PADDINGS } from '../../sidebar/styles';

const WorkspacesSkeletons = memo(() => {
  return (
    <Stack flexDirection="column" alignItems="center" width={1}>
      {Array.from({ length: 10 }, (_value, index) => (
        <Fragment key={index}>
          <Divider flexItem />
          <Stack
            width={1}
            gap={1}
            flexDirection="row"
            justifyContent="space-between"
            px={LIST_ITEM_PADDINGS.px}
            py={LIST_ITEM_PADDINGS.py}
          >
            <Stack gap={1} flexDirection="row">
              <ArrowRightRounded sx={{ color: t => t.palette.action.active }} />
              <Skeleton width={random(70, 190)} height={24} />
            </Stack>
            <Skeleton width={20} variant="circular" />
          </Stack>
        </Fragment>
      ))}
    </Stack>
  );
});

export { WorkspacesSkeletons };
