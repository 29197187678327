import {
  Paper,
  PaperProps,
  Stack,
  StackProps,
  Typography,
} from '@rossum/ui/material';
import { forwardRef, ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  tooltip?: ReactNode;
  dataCy?: string;
  sx?: StackProps['sx'];
} & PaperProps;

const PaperSection = forwardRef<HTMLDivElement, Props>(
  ({ title, dataCy, children, tooltip, sx, elevation }, ref) => (
    <Stack
      ref={ref}
      data-cy={dataCy}
      component={Paper}
      spacing={1}
      p={2}
      pb={4}
      alignItems="center"
      sx={sx}
      elevation={elevation ?? 0}
    >
      <Stack direction="row" gap={1} width={1}>
        <Typography
          variant="body2"
          fontWeight={600}
          sx={{ alignSelf: 'flex-start' }}
        >
          {title}
        </Typography>
        {tooltip && tooltip}
      </Stack>
      {children}
    </Stack>
  )
);

export default PaperSection;
