import { Url } from '@rossum/api-client';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from '@rossum/ui/x-data-grid-pro';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { filter, isNonNullish } from 'remeda';
import {
  RestrictedAccessIcon,
  restrictedAccessIconLabel,
} from '../../pricing/components/RestrictedAccessIcon';
import { labelsFeatureSelector } from '../../pricing/selectors';
import { RenderDate } from '../components/RenderDate';
import { RenderDetails } from '../components/RenderDetails';
import { RenderStatus } from '../components/RenderStatus';
import { FILE_NAME_WIDTH, SimpleFilename } from '../components/SimpleFilename';
import { SimpleLabels } from '../components/SimpleLabels';
import { SimpleQueue } from '../components/SimpleQueue';
import { dateValueGetter } from '../helpers/dateValueGetter';
import { SupportedAnnotationView } from '../supportedAnnotationViews';
import { SimpleColumnsLocation } from '../types';
import { TransformedAnnotation } from './useTransformAnnotation';
import { useUnpaginatedWorklowRuns } from './useUnpaginatedWorkflowRuns';

const LABELS_WIDTH = 150;
const EMPTY_SPACE = 115;

const getFileNameColumnWidth = (
  hasRemoveAction: boolean,
  isLabelsEnabled: boolean
) => {
  const labelsColumnOffset = isLabelsEnabled ? 0 : LABELS_WIDTH;
  const removeColumnOffset = hasRemoveAction ? 0 : EMPTY_SPACE;

  return FILE_NAME_WIDTH + removeColumnOffset + labelsColumnOffset;
};

const defaultColumnConfig = {
  filterable: false,
  sortable: false,
  pinnable: false,
  disableReorder: true,
};

type Props = {
  removeProps?: {
    onRemove: (annotationUrl: Url) => void;
    dataCyPrefix: string;
    Icon: FC<object>;
    disableButton?: (annotation: TransformedAnnotation) => boolean;
  };
  columnsLocation?: SimpleColumnsLocation;
  detailsColumnProps?: {
    handleSelectAnnotation: (params: {
      annotationUrl: string;
      view: SupportedAnnotationView;
    }) => void;
    annotations: TransformedAnnotation[];
  };
};

export type SimpleColumnFieldNames =
  | 'status'
  | 'original_file_name'
  | 'queueName'
  | 'labels'
  | 'createdAt'
  | 'actions'
  | 'details';

export type SimpleColumn = Omit<GridColDef<TransformedAnnotation>, 'field'> & {
  field: SimpleColumnFieldNames;
  getActions?: GridActionsColDef['getActions'];
};

export const useSimpleColumns = ({
  removeProps,
  columnsLocation,
  detailsColumnProps,
}: Props = {}) => {
  const intl = useIntl();
  const labelsEnabled = useSelector(labelsFeatureSelector);

  const { data: workflowRuns } = useUnpaginatedWorklowRuns({
    annotationIds: detailsColumnProps?.annotations?.map(a => a.id) ?? [],
  });

  const columns = R.pipe<Array<SimpleColumn | undefined>, Array<SimpleColumn>>(
    [
      {
        field: 'status',
        width: 140,
        renderCell: RenderStatus,
        headerName: intl.formatMessage({
          id: 'components.documentOverview.status',
        }),
      },
      {
        field: 'original_file_name',
        width: getFileNameColumnWidth(!!removeProps, labelsEnabled),
        headerName: intl.formatMessage({
          id: 'components.documentOverview.document__original_file_name',
        }),
        renderCell: props => (
          <SimpleFilename
            {...props}
            shouldShowAttachmentWarning={columnsLocation === 'attachmentsModal'}
          />
        ),
      },
      detailsColumnProps && {
        field: 'details',
        headerName: intl.formatMessage({
          id: 'components.documentOverview.details',
        }),
        width: 140,
        renderCell: props => {
          const hasWorkflowRun =
            workflowRuns?.some(
              ({ annotation }) => annotation === props.row.url
            ) ?? false;

          return (
            <RenderDetails
              {...props}
              hasWorkflowRun={hasWorkflowRun}
              handleSelectAnnotation={detailsColumnProps.handleSelectAnnotation}
              componentLocation={columnsLocation}
              row={{
                ...props.row,
                hasAttachments: props.row.hasAttachmentRelation,
              }}
            />
          );
        },
      },
      {
        field: 'queueName',
        headerName: intl.formatMessage({
          id: 'components.documentOverview.queue',
        }),
        width: 145,
        renderCell: SimpleQueue,
      },
      labelsEnabled
        ? {
            field: 'labels',
            headerName: intl.formatMessage({
              id: 'components.documentOverview.labels',
            }),
            width: LABELS_WIDTH,
            renderCell: SimpleLabels,
          }
        : undefined,
      {
        field: 'createdAt',
        headerName: intl.formatMessage({
          id: 'components.documentOverview.createdAtAlt',
        }),
        width: 100,
        renderCell: RenderDate,
        valueGetter: dateValueGetter,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        align: 'right',
        getActions: ({ row }: GridRowParams<TransformedAnnotation>) => {
          if (!removeProps)
            return filter(
              [row.restricted_access ? <RestrictedAccessIcon /> : null],
              isNonNullish
            );

          const { onRemove, dataCyPrefix, Icon, disableButton } = removeProps;

          return filter(
            [
              row.restricted_access ? <RestrictedAccessIcon /> : null,
              <GridActionsCellItem
                key={`actions-${row.id}`}
                data-cy={`${dataCyPrefix}-remove-${row.id}`}
                icon={<Icon />}
                onClick={() => {
                  onRemove?.(row.url);
                }}
                label="Remove"
                disabled={disableButton?.(row) ?? false}
                className={
                  row.restricted_access ? restrictedAccessIconLabel : ''
                }
              />,
            ],
            isNonNullish
          );
        },
      },
    ],
    R.filter(R.isTruthy)
  );

  return columns.map(col => ({ ...defaultColumnConfig, ...col }));
};
