import { Output } from '@rossum/ui/icons';
import { CircularProgress } from '@rossum/ui/material';
import { DownloadAllButton } from '../download-buttons/DownloadAllButton';
import { useExportAnnotationsCrossQueue } from '../hooks/useExportAnnotationsCrossQueue';
import { useSetDashboardData } from '../hooks/useFetchDashboardData';

type Props = {
  annotationIds: number[];
  queueName: string;
};

export const ExportCTA = ({ annotationIds, queueName }: Props) => {
  const { mutate: exportAnnotations, isLoading } =
    useExportAnnotationsCrossQueue();
  const setDashboardData = useSetDashboardData();

  return (
    <DownloadAllButton
      ButtonProps={{
        disabled: isLoading || annotationIds.length === 0,
        variant: 'contained',
        startIcon: isLoading ? <CircularProgress size={16} /> : <Output />,
        sx: { height: 1 },
      }}
      downloadAll={payload => {
        exportAnnotations(
          {
            format: payload.format,
            selectedAnnotationsIds: annotationIds,
            toExport: true,
            queueName,
          },
          {
            onSuccess: () => {
              setDashboardData(response => {
                if (!response) return response;

                return {
                  ...response,
                  results: response.results.filter(
                    ({ id }) => !annotationIds.includes(id)
                  ),
                };
              });
            },
          }
        );
      }}
      label="export"
    />
  );
};
