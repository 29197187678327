import { approve } from './approve';
import { assign } from './assign';
import { cancel } from './cancel';
import { cancelEditPages } from './cancelEditPages';
import { confirm } from './confirm';
import { contentSelect } from './contentSelect';
import { copy } from './copy';
import { deleteEndpoint } from './delete';
import { editPages } from './editPages';
import { editPagesInPlace } from './editPagesInPlace';
import { exportAnnotationCrossQueue } from './exportAnnotationCrossQueue';
import { get } from './get';
import { getContent } from './getContent';
import { getPageData } from './getPageData';
import { list } from './list';
import { patch } from './patch';
import { postpone } from './postpone';
import { purge } from './purge';
import { reject } from './reject';
import { search } from './search';
import { start } from './start';
import { startEditPages } from './startEditPages';
import { startEmbedded } from './startEmbedded';
import { translate } from './translate';
import { updateContent } from './updateContent';
import { usageReport, usageReportExport } from './usageReportExport';
import { validate } from './validate';
export { type AnnotationListQuery } from './list';
export { type UsageReportPayload } from './usageReportExport';
export {
  type AnnotationsSearchPayload,
  type AnnotationsSearchQuery,
} from './search';
export { type EditPagesPayload, type EditPagesResponse } from './editPages';
export { type AnnotationPayload } from './patch';
export { type PurgePayload } from './purge';
export { type AnnotationWorkflowPayload } from './approve';
export { type AssignPayload } from './assign';
export {
  type TranslateAnnotationResponse,
  type TranslateAnnotationPayload,
} from './translate';

export const annotations = {
  copy,
  patch,
  deleteEndpoint,
  exportAnnotationCrossQueue,
  getContent,
  startEmbedded,
  get,
  list,
  postpone,
  start,
  startEditPages,
  cancelEditPages,
  editPages,
  editPagesInPlace,
  confirm,
  validate,
  updateContent,
  purge,
  reject,
  search,
  approve,
  assign,
  usageReport,
  usageReportExport,
  getPageData,
  contentSelect,
  cancel,
  translate,
};
