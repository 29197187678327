import { Queue } from '@rossum/api-client/queues';
import { Close as CloseIcon } from '@rossum/ui/icons';
import {
  Box,
  Button,
  Grow,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { GridRowSelectionModel } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { canUserPurgeSelector } from '../../../redux/modules/user/selectors';
import {
  actionIcons,
  getSupportedBatchActions,
  panelButtonMeta,
  SelectionPanelButtonAction,
} from '../annotation-actions/config';
import { usePermittedActions } from '../annotation-actions/hooks/usePermittedActions';
import { BatchLabels } from '../components/BatchLabels';
import { DownloadAllOption } from '../download-buttons/DownloadAllOption';
import { AllDocsAnnotation } from '../types';
import { useAnnotationActionsContext } from './AnnotationActionContext';

const SELECTION_TEXT_MIN_WIDTH = 90;
const TOOLTIP_DELAY = 600;

type Props = {
  selectedAnnotations: Array<AllDocsAnnotation>;
  setSelectedAnnotations: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  queues: Queue[] | undefined;
};

const panelButtons: SelectionPanelButtonAction[] = [
  'validate',
  'download',
  'downloadAndExport',
  'reprocess',
  'reprocessConfirmed',
  'postpone',
  'label',
  'move',
  'reExtract',
  'delete',
  'purge',
];

const SelectionPanel = ({
  selectedAnnotations,
  setSelectedAnnotations,
  queues = [],
}: Props) => {
  const intl = useIntl();
  const itemsCount = selectedAnnotations.length;
  const showSelectionPanel = itemsCount > 0;

  const canUserPurge = useSelector(canUserPurgeSelector);

  const { permittedActions, isLabelsEnabled } = usePermittedActions();

  const supportedBatchActions = getSupportedBatchActions(
    selectedAnnotations,
    queues.map(({ url, settings, useConfirmedState }) => ({
      url,
      hideExportButton: Boolean(settings?.hideExportButton),
      useConfirmedState,
    })),
    isLabelsEnabled,
    canUserPurge
  );

  const { annotationActions: actions } = useAnnotationActionsContext();

  const allowedActions = panelButtons.filter(button =>
    permittedActions.includes(button)
  );

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Grow
        in={showSelectionPanel}
        mountOnEnter
        unmountOnExit
        timeout={{ exit: 0, enter: 200 }}
      >
        <Paper
          elevation={4}
          component={Stack}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={{
            position: 'absolute',
            px: 2,
            minWidth: '490px',
            minHeight: theme => theme.spacing(6.75),
            borderRadius: 2,
            zIndex: 2,
          }}
          data-cy="all-documents-selection-panel"
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                minWidth: SELECTION_TEXT_MIN_WIDTH,
              }}
            >
              {intl.formatMessage(
                {
                  id: 'containers.allDocuments.selectionPanel.selected',
                },
                {
                  count: itemsCount,
                }
              )}
            </Typography>
            {actions
              ? allowedActions
                  .filter(action => !!supportedBatchActions[action])
                  .map(action => {
                    const Icon = actionIcons[action];
                    const label = intl.formatMessage({
                      id: panelButtonMeta[action].localization,
                    });

                    if (supportedBatchActions[action] === 'disabled') {
                      return (
                        <Tooltip
                          key={action}
                          title={intl.formatMessage({
                            id: 'components.annotationOverview.actionButtons.disabled',
                          })}
                        >
                          <Box>
                            <Button
                              startIcon={<Icon />}
                              color="secondary"
                              disabled
                            >
                              {label}
                            </Button>
                          </Box>
                        </Tooltip>
                      );
                    }

                    if (action === 'label') {
                      return (
                        <BatchLabels
                          key={action}
                          selectedAnnotations={selectedAnnotations}
                          onLabel={actions[action]}
                        >
                          {({ isHovered }) => (
                            <Button
                              sx={{
                                backgroundColor: isHovered
                                  ? theme => theme.palette.action.hover
                                  : undefined,
                              }}
                              color="secondary"
                              startIcon={<Icon />}
                              data-cy={`all-documents-selection-panel-${action}-button`}
                            >
                              {label}
                            </Button>
                          )}
                        </BatchLabels>
                      );
                    }

                    if (
                      action === 'download' ||
                      action === 'downloadAndExport'
                    ) {
                      return (
                        <DownloadAllOption
                          key={action}
                          downloadAll={({ format }) =>
                            actions[action]({
                              selectedAnnotationsIds: selectedAnnotations.map(
                                ({ id }) => id
                              ),
                              format,
                            })
                          }
                          asyncDownload={
                            action !== 'downloadAndExport'
                              ? () => {
                                  return actions.asyncDownload({
                                    annotations: selectedAnnotations,
                                  });
                                }
                              : undefined
                          }
                          selectedItemsCount={selectedAnnotations.length}
                        >
                          {(setAnchor, isVisible) => (
                            <Button
                              data-cy={`all-documents-selection-panel-${action === 'download' ? 'download' : 'export'}-button`}
                              color="secondary"
                              startIcon={<Icon />}
                              onClick={setAnchor}
                              sx={{
                                backgroundColor: isVisible
                                  ? theme => theme.palette.action.hover
                                  : undefined,
                              }}
                            >
                              {intl.formatMessage({
                                id: `${panelButtonMeta[action].localization}`,
                              })}
                            </Button>
                          )}
                        </DownloadAllOption>
                      );
                    }

                    return (
                      <Button
                        key={action}
                        data-cy={`all-documents-selection-panel-${action}-button`}
                        color="secondary"
                        startIcon={<Icon />}
                        onClick={() =>
                          actions[action]({
                            annotations: selectedAnnotations,
                            onSuccess: () => setSelectedAnnotations([]),
                          })
                        }
                      >
                        {label}
                      </Button>
                    );
                  })
              : null}
          </Stack>

          <Stack onClick={() => setSelectedAnnotations([])}>
            <Tooltip
              title={intl.formatMessage({
                id: 'containers.annotationList.selectionPanel.buttons.deselect',
              })}
              enterDelay={TOOLTIP_DELAY}
            >
              <CloseIcon
                sx={{
                  opacity: 0.6,
                  cursor: 'pointer',
                  transition: 'opacity 0.2s',
                  ':hover': { opacity: 1 },
                }}
                data-cy="cancel-all-documents-selection-clear-icon"
              />
            </Tooltip>
          </Stack>
        </Paper>
      </Grow>
    </Box>
  );
};

export { SelectionPanel };
