import { Tab, Tabs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { tabs } from '../../components/NavBar/navigationStructure';
import { Header, HeaderProps } from '../../ui/header/Header';
import { ExtensionsBreadcrumbs } from './ExtensionsBreadcrumbs';

type ExtensionsPageKeys = 'rossumStore' | 'myExtensions' | 'logs';

const ExtensionsTabs = ({ value }: { value: ExtensionsPageKeys }) => {
  const intl = useIntl();
  const headerTabs = tabs.find(tab => tab.name === 'extensions')?.menu ?? [];
  const { push } = useHistory();

  return (
    <Tabs
      data-cy="extensions-tabs"
      value={value}
      onChange={(_, value) => {
        const link = headerTabs.find(tab => tab.name === value)?.link;
        if (link) {
          push(link);
        }
      }}
    >
      {headerTabs.map(tab => (
        <Tab
          key={tab.name}
          label={intl.formatMessage({
            id: `components.appBar.menu.${tab.translationKey}`,
          })}
          data-cy={`tab-${tab.translationKey}`}
          value={tab.name}
        />
      ))}
    </Tabs>
  );
};

type ExtensionsHeaderProps = {
  value: ExtensionsPageKeys;
} & Pick<HeaderProps, 'scrollableDivRef' | 'title' | 'buttons'>;

export const ExtensionsHeader = ({
  value,
  ...params
}: ExtensionsHeaderProps) => {
  const intl = useIntl();

  return (
    <Header
      {...params}
      description={null}
      breadcrumbs={
        <ExtensionsBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: `components.appBar.menu.extensions.${value}`,
              }),
            },
          ]}
        />
      }
      tabs={<ExtensionsTabs value={value} />}
    />
  );
};
