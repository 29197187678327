import { getIDFromUrl } from '@rossum/api-client';
import { ListItemProps, Skeleton, Stack } from '@rossum/ui/material';
import deepEqual from 'fast-deep-equal/es6/react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  sidebarFieldIdsEnabledSelector,
  sidebarHiddenFieldsVisibleSelector,
} from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { SchemaFieldIconType } from '../../../../ui/schema-field-icon/SchemaFieldIcon';
import { SidebarItemLabel } from '../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../shared/SidebarItemLayout';
import { SidebarSimplevalueFieldModel } from '../useSidebarData';
import { InactiveValue } from './shared/InactiveValue';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { SidebarItemActiveValue } from './shared/SidebarItemActiveValue';
import { formatConfidenceScore, getFieldsSettingsPath } from './utils';

type SimplevalueItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarSimplevalueFieldModel;
  active: boolean;
  selected?: boolean;
  disabled: boolean;
  loading: boolean;
  onChange: (item: SidebarSimplevalueFieldModel, newValue: string) => void;
  onClick: (datapointId: number) => void;
};

const SimpleValueItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    active,
    selected,
    disabled,
    onChange,
    onClick,
    loading,
    ...listItemProps
  }: SimplevalueItemProps) => {
    const handleChange = useCallback(
      (newValue: string) => {
        onChange(item, newValue);
      },
      [item, onChange]
    );

    const handleClick = useCallback(() => {
      onClick(item.id);
    }, [item.id, onClick]);

    const isEdited = !!('noRecalculation' in item && item.noRecalculation);

    const hiddenFieldsVisible = useSelector(sidebarHiddenFieldsVisibleSelector);

    const isHidden = item.hidden && hiddenFieldsVisible;

    const isConfirmed = useMemo(() => {
      return item.validationSources.includes('human');
    }, [item.validationSources]);

    const iconType: SchemaFieldIconType = item.valueSource;

    const iconVariant = useMemo(() => {
      if (isEdited) {
        return 'edited';
      }

      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed, isEdited]);

    const iconColor = useMemo(() => {
      // isEdited is always also isConfirmed because we set `human` validation source but we might want to change it in the future :shrug:
      if (isEdited || isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed, isEdited]);

    const isDisabled = disabled || item.editing === 'disabled';

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <MagicContainer
        active={active}
        selected={selected}
        disabled={isDisabled}
        sidebarScrollableRef={sidebarScrollableRef}
        onClick={active ? undefined : handleClick}
        {...listItemProps}
      >
        <SidebarItemLayout
          iconSlot={
            <ItemContextActions
              item={item}
              active={active}
              disabled={isDisabled}
              iconProps={{
                type: iconType,
                variant: iconVariant,
                color: iconColor,
              }}
              fieldSettingsPath={getFieldsSettingsPath(queueId, item.schemaId)}
              variant={isHidden ? 'secondary' : 'primary'}
            />
          }
          labelSlot={
            <SidebarItemLabel
              label={item.label}
              variant={isHidden ? 'secondary' : 'primary'}
              sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
              isRequired={item.required}
            />
          }
          valueSlot={
            loading ? (
              <Stack height={1} justifyContent="center">
                <Skeleton height="100%" />
              </Stack>
            ) : active ? (
              <SidebarItemActiveValue
                value={item.value}
                onChange={handleChange}
                disabled={isDisabled}
              />
            ) : (
              <InactiveValue
                value={item.value}
                confidence={formatConfidenceScore(item.confidenceScore)}
              />
            )
          }
          decorationSlot={
            active || !item.messagesStats ? null : (
              <MessageIndicator messagesStats={item.messagesStats} />
            )
          }
        />
        {active
          ? item.messages.length > 0 && <Messages messages={item.messages} />
          : null}
      </MagicContainer>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

SimpleValueItem.displayName = 'SimpleValueItem';

export { SimpleValueItem };
