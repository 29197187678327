import { IconCopy, IconTrash } from '@rossum/ui/icons/tabler';
import { IconButton, SvgIcon } from '@rossum/ui/material';
import {
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from '@rossum/ui/x-data-grid-pro';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { removeIdColumnKeys } from '../utils';
import { RowWithIndex } from './types';

type Props = {
  data: DSResultsResponseResultsItem[] | undefined;
  disabled?: boolean;
  onDuplicate: (params: GridRowParams<RowWithIndex>) => void;
  onDelete: (params: GridRowParams<RowWithIndex>) => void;
};

export const ACTIONS_COLUMN_NAME = 'actions';

export const useColumns = ({
  data,
  disabled,
  onDuplicate,
  onDelete,
}: Props) => {
  return useMemo<GridColDef[]>(() => {
    const firstRow = data?.[0];
    if (!firstRow) return [];

    const columnKeys = Object.keys(firstRow);

    const datasetColumns = removeIdColumnKeys(columnKeys).map(
      key =>
        ({
          field: key,
          flex: 1,
          editable: true,
        }) as const satisfies GridColDef
    );

    const actionsColumn: GridActionsColDef = {
      field: ACTIONS_COLUMN_NAME,
      type: 'actions',
      resizable: false,
      filterable: false,
      getActions: (params: GridRowParams<RowWithIndex>) => [
        <IconButton
          key={v4()}
          onClick={() => onDuplicate(params)}
          sx={{ color: disabled ? 'text.disabled' : 'inherit' }}
        >
          <SvgIcon>
            <IconCopy />
          </SvgIcon>
        </IconButton>,
        <IconButton
          sx={{ color: disabled ? 'text.disabled' : 'inherit' }}
          key={v4()}
          onClick={() => onDelete(params)}
        >
          <SvgIcon>
            <IconTrash />
          </SvgIcon>
        </IconButton>,
      ],
    };

    return [...datasetColumns, actionsColumn];
  }, [data, disabled, onDelete, onDuplicate]);
};
