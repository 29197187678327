import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptBatchOfSuggestions } from '../../redux/modules/datapoints/actions';
import { hasSuggestionsSelector } from '../../redux/modules/datapoints/suggestedOperations/selector';
import { State } from '../../types/state';

export const useAcceptSuggestions = ({
  multivalueId,
}: {
  multivalueId: number;
}) => {
  const dispatch = useDispatch();

  const [acceptingSuggestions, setAcceptingSuggestions] = useState(false);

  const hasSuggestions = useSelector(hasSuggestionsSelector);

  const pendingAcceptRequest = useSelector(
    (state: State) => state.datapoints.pendingMaterialization
  );

  const waitingForSuggestions = useSelector(
    (state: State) => state.datapoints.waitingForSuggestions
  );

  const canAcceptSuggestions =
    hasSuggestions && !acceptingSuggestions && !waitingForSuggestions;

  // Set component to a "hungry" mode, when it's accepting suggestions automatically whenever not loading.
  const acceptSuggestions = useCallback(() => {
    setAcceptingSuggestions(true);
  }, []);

  useEffect(() => {
    // When not loading and in "hungry" mode, ask for the next batch if possible.
    if (!pendingAcceptRequest && acceptingSuggestions && hasSuggestions) {
      // I don't know if timeout is actually needed.
      // But it might be a good idea to give B/E a rest and to make sure all redux actions get resolved.
      setTimeout(
        () =>
          dispatch(
            acceptBatchOfSuggestions({ multivalueId, maxOperations: 250 })
          ),
        100
      );
    }

    // If done, turn off the "hungry" mode.
    if (!pendingAcceptRequest && !hasSuggestions) {
      setAcceptingSuggestions(false);
    }
  }, [
    acceptingSuggestions,
    dispatch,
    hasSuggestions,
    multivalueId,
    pendingAcceptRequest,
  ]);

  return {
    acceptSuggestions,
    canAcceptSuggestions,
    loading: acceptingSuggestions,
  };
};
