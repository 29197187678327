import { Button, CircularProgress, Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type ShowMoreButtonProps = {
  onClick: () => void;
  isFetching: boolean;
};

const ShowMoreButton = ({ onClick, isFetching }: ShowMoreButtonProps) => {
  const intl = useIntl();

  return (
    <Button
      variant="outlined"
      color="secondary"
      disabled={isFetching}
      onClick={onClick}
    >
      <Stack gap={1} direction="row">
        {isFetching ? <CircularProgress size={20} color="secondary" /> : null}
        {intl.formatMessage({
          id: 'features.engines.list.showMore',
        })}
      </Stack>
    </Button>
  );
};

export { ShowMoreButton };
