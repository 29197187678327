import {
  Card,
  CardActionArea,
  CardProps,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { DocumentType } from './data';
import { DisabledDocumentCard } from './DisabledDocumentCard';

type Props = CardProps & {
  documentType: DocumentType;
  loading?: boolean;
  disabled: boolean;
  boldTitle?: boolean;
  onClick?: () => void;
};

const DocumentTypeCard = ({
  documentType,
  onClick,
  loading,
  disabled,
  boldTitle,
  ...cardProps
}: Props) => {
  const intl = useIntl();
  return (
    <Grid item xs={4}>
      <Card
        component={Paper}
        sx={{
          height: '100%',
          position: 'relative',
          borderRadius: '4px',
          '&:hover': { backgroundColor: t => t.palette.action.hover },
        }}
        {...cardProps}
      >
        <CardActionArea
          sx={{
            minHeight: '80px',
            height: '100%',
            p: 2,
          }}
          disabled={disabled}
          onClick={onClick}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={boldTitle ? 'subtitle2' : 'body2'}>
              {intl.formatMessage({
                id: `components.selectDocumentType.documentTypeId.${snakeToCamel(
                  documentType.id
                )}`,
              })}
            </Typography>
            {documentType.tag !== 'none' && (
              <Chip
                color={documentType.tag === 'popular' ? 'info' : undefined}
                label={intl.formatMessage({
                  id: `components.selectDocumentType.${documentType.tag}`,
                })}
              />
            )}
          </Stack>
        </CardActionArea>
        {disabled && <DisabledDocumentCard loading={loading} />}
      </Card>
    </Grid>
  );
};

export { DocumentTypeCard };
