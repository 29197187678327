import { useSelector } from 'react-redux';
import * as R from 'remeda';
import {
  currentDatapointIdSelector,
  datapointsSelector,
} from '../../../../../redux/modules/datapoints/selector';
import { useDocumentStore } from '../../../document-store/DocumentStore';

export const useHighlightedDatapoints = () => {
  const hoveredBbox = useDocumentStore(state => state.hoveredBbox);
  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);
  const currentDatapointId = useSelector(currentDatapointIdSelector);

  const highlightedDatapointIds = R.pipe(
    [...selectedDatapointIds, currentDatapointId, hoveredBbox],
    R.filter(R.isTruthy),
    R.unique()
  );

  const datapoints = useSelector(datapointsSelector);
  const highlightedDatapoints = datapoints.flatMap(dp =>
    highlightedDatapointIds.includes(dp.id) ? [dp] : []
  );

  return { highlightedDatapoints };
};
