import * as z from 'zod';

const formSchema = z.object({
  width: z.number().optional(),
  schema: z.unknown(),
  uiSchema: z.unknown(),
  defaultValue: z.unknown(),
  hook: z.string().optional(),
});
const errorSchema = z.object({
  message: z.string(),
});

const infoSchema = z.object({
  message: z.string(),
});

const downloadSchema = z.object({
  filename: z.string(),
  content: z.unknown(),
});

const redirectSchema = z.object({
  url: z.string(),
});

const openTabSchema = z.object({
  url: z.string(),
});

const userStateSchema = z.object({
  scope: z.string(),
  payload: z.unknown(),
});

export const intentSchema = z
  .object({
    form: formSchema.nullable(),
    error: errorSchema,
    info: infoSchema,
    download: downloadSchema,
    redirect: redirectSchema,
    open_tab: openTabSchema,
    user_state: userStateSchema,
  })
  .partial();

export type ShowFormSchema = z.TypeOf<typeof formSchema>;
