import { createReactComponent } from '@tabler/icons-react';

export const IconCustomChevronDownBar = createReactComponent(
  'outline',
  'customChevronDownbar',
  'CustomChevronDownbar',
  [
    [
      'path',
      {
        key: 'customChevronDownbar-path',
        d: 'M6.24998 17.25H18.25M6.24998 6.25L12.25 12.25L18.25 6.25',
      },
    ],
  ]
);
