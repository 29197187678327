import { DescriptionOutlined } from '@rossum/ui/icons';
import { Chip, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type CollapsibleListHeaderProps = {
  count: number | undefined;
};

export const CollapsibleListHeader = ({
  count,
}: CollapsibleListHeaderProps) => {
  const intl = useIntl();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography
        variant="subtitle2"
        color="text.secondary"
        data-cy="attachements-list-title"
      >
        <Stack direction="row" component="span" spacing={2}>
          <DescriptionOutlined fontSize="small" color="inherit" />
          <span>
            {intl.formatMessage({
              id: 'components.emailDocumentsPreview.title',
            })}
          </span>
        </Stack>
      </Typography>
      {count && <Chip label={count} />}
    </Stack>
  );
};
