const EXTENSION_PARAM_KEY = 'extensionId';
export type ExtensionParams = Record<typeof EXTENSION_PARAM_KEY, string>;

export const extensionsPath = () => '/extensions';
export const myExtensionsPath = () => `${extensionsPath()}/my-extensions`;
export const extensionsLogsPath = () => `${extensionsPath()}/logs`;
export const extensionsStorePath = () => `${extensionsPath()}/store`;

export const extensionsCreatePath = () => `${myExtensionsPath()}/create`;

export const extensionDetailsRoute = `${myExtensionsPath()}/:${EXTENSION_PARAM_KEY}`;
export const extensionDetailPath = (extensionId: number) =>
  `${myExtensionsPath()}/${extensionId}`;

export const extensionCodeRoute = `${extensionDetailsRoute}/code`;
export const extensionCodePath = (extensionId: number) =>
  `${extensionDetailPath(extensionId)}/code`;
