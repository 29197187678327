import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbedded } from '../../../constants/config';
import {
  assertMutable,
  sidebarFooterActions,
} from '../../../features/annotation-view/document-sidebar/sidebar-footer/sidebar-footer-actions/utils';
import {
  automaticSuggestionsEnabledSelector,
  complexLineItemsEnabledSelector,
  complexTablesEnabledOnOrganization,
} from '../../../redux/modules/ui/selectors';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import {
  footerLayoutSelector,
  onConfirmNavigateSelector,
  sidebarActionsOrderSelector,
  sidebarAdditionalInfoSelector,
  sortFooterColumnsSelector,
} from '../../../redux/modules/user/selectors';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import ConditionalAuroraChip from '../../conditional-aurora-chip/ConditionalAuroraChip';
import { NonAdminRestrictor } from '../../Restrictors';
import { ActionsOrderControl } from './ActionsOrderControl';
import { AdditionalInfoControl } from './AdditionalInfoControl';
import {
  annotationMethodTypes,
  footerLayoutTypes,
  FormValues,
  getOption,
  showSuggestionsTypes,
  sidebarAdditionalInfoTypes,
  sortFooterColumnsTypes,
} from './formValues';
import { LineItemSettingsControl } from './LineItemSettingsControl';
import { NavigationControl } from './NavigationControl';

type LineItemSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const LineItemSettingsDialog = ({
  open,
  onClose,
}: LineItemSettingsDialogProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cliEnabled = useSelector(complexTablesEnabledOnOrganization);

  const newSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');

  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);
  const automaticSuggestionsEnabled = useSelector(
    automaticSuggestionsEnabledSelector
  );
  const footerLayout = useSelector(footerLayoutSelector);
  const sortFooterColumns = useSelector(sortFooterColumnsSelector);

  const sidebarAdditionalInfo = useSelector(sidebarAdditionalInfoSelector);

  const sidebarOnConfirmNavigation = useSelector(onConfirmNavigateSelector);

  const sidebarFooterActionsOrder = useSelector(sidebarActionsOrderSelector);

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      actionsOrder:
        sidebarFooterActionsOrder ?? assertMutable(sidebarFooterActions),
      additionalInfo: newSidebarEnabled ? sidebarAdditionalInfo : undefined,
      navigation: newSidebarEnabled ? sidebarOnConfirmNavigation : undefined,
      annotationMethod: complexLineItemsEnabled
        ? 'annotationMethod.suggestions'
        : 'annotationMethod.gridDesign',
      showSuggestions: automaticSuggestionsEnabled
        ? 'showSuggestions.automatically'
        : 'showSuggestions.onDemand',
      footerLayout:
        footerLayout === 'table-per-page'
          ? 'footerLayout.table-per-page'
          : 'footerLayout.one-table',
      sortFooterColumns:
        sortFooterColumns === 'automatically'
          ? 'sortColumns.automatically'
          : 'sortColumns.queueSettings',
    },
  });

  const annotationMethodOptions = annotationMethodTypes.map(type => {
    const option = getOption(intl, type);

    // custom title for complex tables
    if (type === 'annotationMethod.suggestions') {
      return {
        ...option,
        chip: <ConditionalAuroraChip />,
      };
    }
    return option;
  });

  const showSuggestionsOptions = showSuggestionsTypes.map(type =>
    getOption(intl, type)
  );

  const sortColumnsOptions = sortFooterColumnsTypes.map(type =>
    getOption(intl, type)
  );

  const footerLayoutOptions = footerLayoutTypes.map(type =>
    getOption(intl, type)
  );

  const additionalInfoOptions = sidebarAdditionalInfoTypes.map(type =>
    getOption(intl, type)
  );

  const onSubmit = (values: FormValues) => {
    const newFooterLayout =
      values.footerLayout === 'footerLayout.table-per-page'
        ? 'table-per-page'
        : 'one-table';
    const newSortFooterColumns =
      values.sortFooterColumns === 'sortColumns.automatically'
        ? 'automatically'
        : 'queue-settings';

    const newComplexLineItems =
      values.annotationMethod === 'annotationMethod.suggestions';

    const newAutomaticSuggestions =
      values.showSuggestions === 'showSuggestions.automatically';

    dispatch(
      updateUiSettings({
        footerLayout: newFooterLayout,
        sortFooterColumns: newSortFooterColumns,
        automaticSuggestions: newAutomaticSuggestions,
        sidebarAdditionalInfo: newSidebarEnabled
          ? values.additionalInfo
          : undefined,
        sidebarActionsOrder: newSidebarEnabled
          ? values.actionsOrder
          : undefined,

        sidebarNavigation: newSidebarEnabled
          ? { onConfirm: values.navigation }
          : undefined,
        // To prevent reload.
        ...(newComplexLineItems !== complexLineItemsEnabled
          ? { complexLineItems: newComplexLineItems }
          : {}),
      })
    );
    onClose();
  };

  const values = useWatch({
    control,
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
      <DialogTitle
        title={intl.formatMessage({
          // TODO: When removing sidebar, take care of these translations
          id:
            newSidebarEnabled && !isEmbedded()
              ? 'components.lineItemsSettings.title2'
              : 'components.lineItemsSettings.title',
        })}
        onClose={onClose}
      />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="line-item-settings">
          <Stack mt={3} spacing={2}>
            {newSidebarEnabled && !isEmbedded() ? (
              <>
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: 'components.lineItemsSettings.leftSidebar.title',
                  })}
                </Typography>
                <NonAdminRestrictor>
                  <AdditionalInfoControl
                    control={control}
                    options={additionalInfoOptions}
                  />
                </NonAdminRestrictor>
                <NavigationControl control={control} />
                <ActionsOrderControl control={control} />
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: 'components.lineItemsSettings.lineItems.title',
                  })}
                </Typography>
              </>
            ) : null}
            <Stack mt={3} spacing={2}>
              {cliEnabled && (
                <Controller
                  control={control}
                  name="annotationMethod"
                  render={({ field }) => (
                    <LineItemSettingsControl
                      name={intl.formatMessage({
                        id: 'components.lineItemsSettings.annotationMethod.title',
                      })}
                      id="annotation-method"
                      options={annotationMethodOptions}
                      field={field}
                    />
                  )}
                />
              )}
              {cliEnabled && (
                <Controller
                  control={control}
                  name="showSuggestions"
                  render={({ field }) => (
                    <LineItemSettingsControl
                      name={intl.formatMessage({
                        id: 'components.lineItemsSettings.showSuggestions.title',
                      })}
                      id="show-suggestions"
                      options={showSuggestionsOptions}
                      field={field}
                      disabled={
                        values.annotationMethod ===
                        'annotationMethod.gridDesign'
                      }
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name="footerLayout"
                render={({ field }) => (
                  <LineItemSettingsControl
                    name={intl.formatMessage({
                      id: 'components.lineItemsSettings.footerLayout.title',
                    })}
                    id="footer-layout"
                    options={footerLayoutOptions}
                    field={field}
                  />
                )}
              />
              <Controller
                control={control}
                name="sortFooterColumns"
                render={({ field }) => (
                  <LineItemSettingsControl
                    name={intl.formatMessage({
                      id: 'components.lineItemsSettings.sortColumns.title',
                    })}
                    id="sort-columns"
                    options={sortColumnsOptions}
                    field={field}
                  />
                )}
              />
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {intl.formatMessage({
              id: 'components.lineItemsSettings.close',
            })}
          </Button>
          <Button type="submit" variant="contained" form="line-item-settings">
            {intl.formatMessage({
              id: 'components.lineItemsSettings.save',
            })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
