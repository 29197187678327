import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { snakeToCamel } from '../../../../../lib/keyConvertor';
import { popAnnotationFromStack } from '../../../../../redux/modules/annotations/actions';
import { canResetOnMove } from '../../../../../redux/modules/annotations/selector';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import { isUserViewer } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { MoveAnnotationDialog } from '../../../../document-list/selection-panel/MoveAnnotationDialog';
import { SidebarFooterAction } from './utils';

type UseMoveActionResult = {
  modal: JSX.Element | null;
  action: SidebarFooterAction | null;
};

export const useMoveAction = (annotation: Annotation): UseMoveActionResult => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const userIsViewer = useSelector(isUserViewer);

  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  const handleMoveDialogOpen = useCallback(() => {
    setMoveDialogOpen(true);
  }, []);

  const handleMoveConfirm = useCallback(() => {
    setMoveDialogOpen(false);
    dispatch(
      pauseValidation({
        nextAction: popAnnotationFromStack(annotation),
        trigger: 'moveAnnotation',
        reason: 'surveys',
      })
    );
  }, [annotation, dispatch]);

  const handleMoveCancel = useCallback(() => {
    setMoveDialogOpen(false);
  }, []);

  const moveDialog = useMemo(() => {
    return (
      <MoveAnnotationDialog
        key={annotation.id}
        selectedAnnotations={
          moveDialogOpen
            ? [{ ...annotation, status: snakeToCamel(annotation.status) }]
            : []
        }
        canResetData={canResetOnMove([
          { ...annotation, status: snakeToCamel(annotation.status) },
        ])}
        onCancel={handleMoveCancel}
        onSuccess={handleMoveConfirm}
      />
    );
  }, [annotation, handleMoveCancel, handleMoveConfirm, moveDialogOpen]);

  const moveVisible = !isEmbedded() && !userIsViewer;

  const result = useMemo(() => {
    return {
      modal: moveDialog,
      action: moveVisible
        ? {
            id: 'move' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.move',
            }),
            onClick: handleMoveDialogOpen,
            dataCy: 'annotation-sidebar-move',
          }
        : null,
    };
  }, [handleMoveDialogOpen, intl, moveDialog, moveVisible]);

  return result;
};
