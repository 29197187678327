import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useIntl } from 'react-intl';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';

export const useUpdateDataset = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationKey: ['@MDH', 'replaceDatasetApiV1DatasetDatasetNamePut'],
    mutationFn: async ({
      datasetName,
      data,
    }: {
      datasetName: string;
      data: DSResultsResponseResultsItem[];
    }) => {
      return datasetApi
        .replaceDatasetApiV1DatasetDatasetNamePut(
          datasetName,
          {
            encoding: 'UTF-8',
            file: new Blob([JSON.stringify(data)], {
              type: 'application/json',
            }),
          },
          getDatasetRequestConfig()
        )
        .then(response => {
          const taskId = getTaskIdFromResponse(response);

          return { taskId, datasetName };
        });
    },
    onSuccess: datasetName => {
      queryClient.invalidateQueries({
        queryKey: ['@MDH', 'dsProxyFindApiV1DataFindPost', datasetName],
      });
    },
    onError: () => {
      notify.error({
        title: intl.formatMessage({
          id: 'features.datasets.hooks.error.update.title',
        }),
      });
    },
  });
};
