import { NavigateNextRounded } from '@rossum/ui/icons';
import { alpha, Box, Paper, Stack, StackProps } from '@rossum/ui/material';
import { Link } from 'react-router-dom';

const NAVIGATION_CLASSNAME = 'navigate';

type TileLinkProps = {
  children: React.ReactNode;
  to?: string;
} & StackProps;

const TileLink = ({ to, children, ...props }: TileLinkProps) => {
  return (
    <Box
      component={to ? Link : 'div'}
      to={to}
      sx={{
        color: 'inherit',
        '&:hover': to
          ? {
              textDecoration: 'none',
              color: 'inherit',
            }
          : {},
      }}
    >
      <Stack
        {...props}
        component={Paper}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 4,
          py: 2,
          borderRadius: 1,
          transition: t => [t.transitions.create('background-color')],
          '&:hover': to
            ? {
                textDecoration: 'none',
                backgroundColor: t => t.palette.action.selected,
                [`& .${NAVIGATION_CLASSNAME}`]: {
                  opacity: 1,
                },
              }
            : {},
        }}
      >
        {children}
        {to ? (
          <NavigateNextRounded
            fontSize="small"
            className={NAVIGATION_CLASSNAME}
            sx={{
              color: t => alpha(t.palette.action.active, 0.56),
              opacity: 0,
              transition: t =>
                t.transitions.create('opacity', {
                  duration: t.transitions.duration.short,
                }),
            }}
          />
        ) : null}
      </Stack>
    </Box>
  );
};

export { TileLink };
