// Generated by ts-to-zod
import { z } from 'zod';

export const extensionEventGroupSchema = z.union([
  z.literal('annotation_content'),
  z.literal('annotation_status'),
  z.literal('upload'),
  z.literal('email'),
  z.literal('invocation'),
]);

export const annotationContentActionSchema = z.union([
  z.literal('confirm'),
  z.literal('export'),
  z.literal('initialize'),
  z.literal('started'),
  z.literal('updated'),
  z.literal('user_update'),
]);

export const uploadActionSchema = z.literal('created');

export const annotationStatusActionSchema = z.literal('changed');

export const emailActionSchema = z.literal('received');

export const invocationActionSchema = z.union([
  z.literal('manual'),
  z.literal('scheduled'),
]);

export const extensionEventSchema = z.union([
  z.literal('annotation_content.confirm'),
  z.literal('annotation_content.export'),
  z.literal('annotation_content.initialize'),
  z.literal('annotation_content.started'),
  z.literal('annotation_content.updated'),
  z.literal('annotation_content.user_update'),
  z.literal('annotation_content'),
  z.literal('annotation_status.changed'),
  z.literal('annotation_status'),
  z.literal('upload.created'),
  z.literal('upload'),
  z.literal('email.received'),
  z.literal('email'),
  z.literal('invocation.manual'),
  z.literal('invocation.scheduled'),
  z.literal('invocation'),
]);

export const functionRuntimeSchema = z.union([
  z.literal('nodejs22.x'),
  z.literal('nodejs18.x'),
  z.literal('python3.12'),
  z.literal('python3.8'),
]);

export const extensionSourceSchema = z.union([
  z.literal('rossum_store'),
  z.literal('custom'),
]);

export const extensionStatusSchema = z.union([
  z.literal('pending'),
  z.literal('ready'),
  z.literal('failed'),
]);
