import { Queue } from '@rossum/api-client/queues';
import { MetaField } from '@rossum/api-client/shared';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { IntlShape, useIntl } from 'react-intl';
import { RenderDate } from '../../document-list-base/components/RenderDate';
import { dateValueGetter } from '../../document-list-base/helpers/dateValueGetter';
import {
  dateOperators,
  userSelectOperators,
} from '../../document-list-base/mql/operators';
import {
  MetaColDef,
  TypedGridColDef,
} from '../../document-list-base/mql/types';
import { ColumnHeader } from '../components/ColumnHeader';
import { useStatusTabs } from '../statuses/hooks/useStatusTabs';
import { StatusTabKeys, statusTabsColumnMap } from '../statuses/tabs';
import { getMatchingStatusFilter, isStatusTabColumn } from '../statuses/utils';
import { RenderAssignees } from './RenderAssignees';
import { RenderUser } from './RenderUser';

type StatusColumns = Record<StatusTabKeys, MetaColDef[]>;

type CommonFieldProps = Omit<
  TypedGridColDef,
  'field' | 'headerName' | 'operators' | 'renderCell'
>;
const commonFieldProps: CommonFieldProps = {
  filterable: true,
};

const TIMESTAMP_WIDTH = 150;
const USER_WIDTH = 170;

const createColumns = (intl: IntlShape, isOnQueueLevel: boolean) => {
  return {
    modified_at: {
      columnType: 'meta',
      metaName: 'modified_at',
      visible: true,
      field: 'modified_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.modifiedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    modifier: {
      columnType: 'meta',
      metaName: 'modifier',
      visible: true,
      field: 'modifier' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.modifiedBy',
      }),
      operators: userSelectOperators,
      renderCell: RenderUser,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
    deleted_at: {
      columnType: 'meta',
      metaName: 'deleted_at',
      visible: isOnQueueLevel,
      field: 'deleted_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.deletedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    deleted_by: {
      columnType: 'meta',
      metaName: 'deleted_by',
      visible: isOnQueueLevel,
      field: 'deleted_by' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.deletedBy',
      }),
      operators: userSelectOperators,
      renderCell: RenderUser,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
    rejected_at: {
      columnType: 'meta',
      metaName: 'rejected_at',
      visible: isOnQueueLevel,
      field: 'rejected_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.rejectedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    rejected_by: {
      columnType: 'meta',
      metaName: 'rejected_by',
      visible: isOnQueueLevel,
      field: 'rejected_by' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.rejectedBy',
      }),
      operators: userSelectOperators,
      renderCell: RenderUser,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
    confirmed_at: {
      columnType: 'meta',
      metaName: 'confirmed_at',
      visible: isOnQueueLevel,
      field: 'confirmed_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.confirmedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    confirmed_by: {
      columnType: 'meta',
      metaName: 'confirmed_by',
      visible: isOnQueueLevel,
      field: 'confirmed_by' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.confirmedBy',
      }),
      operators: userSelectOperators,
      renderCell: RenderUser,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
    exported_at: {
      columnType: 'meta',
      metaName: 'exported_at',
      visible: isOnQueueLevel,
      field: 'exported_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.exportedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    export_failed_at: {
      columnType: 'meta',
      metaName: 'export_failed_at',
      visible: isOnQueueLevel,
      field: 'export_failed_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.exportFailedAt',
      }),
      operators: [],
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
    },
    exported_by: {
      columnType: 'meta',
      metaName: 'exported_by',
      visible: isOnQueueLevel,
      field: 'exported_by' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.exportedBy',
      }),
      operators: userSelectOperators,
      renderCell: RenderUser,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
    assigned_at: {
      columnType: 'meta',
      metaName: 'assigned_at',
      visible: isOnQueueLevel,
      field: 'assigned_at' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.assignedAt',
      }),
      operators: dateOperators,
      renderCell: RenderDate,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      valueGetter: dateValueGetter,
      width: TIMESTAMP_WIDTH,
      ...commonFieldProps,
    },
    assignees: {
      columnType: 'meta',
      metaName: 'assignees',
      visible: isOnQueueLevel,
      field: 'assignees' satisfies MetaField,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.assignees',
      }),
      operators: userSelectOperators,
      renderCell: RenderAssignees,
      renderHeader: ({ colDef }) => <ColumnHeader colDef={colDef} />,
      width: USER_WIDTH,
      ...commonFieldProps,
    },
  } as const satisfies Partial<Record<MetaField, MetaColDef>>;
};

export const createStatusColumns = (
  intl: IntlShape,
  isOnQueueLevel: boolean
): StatusColumns => {
  const columns = createColumns(intl, isOnQueueLevel);

  return {
    all_statuses: Object.values(columns),
    in_workflow: [columns.assigned_at, columns.assignees],
    postponed: [columns.modified_at, columns.modifier],
    reviews: [columns.modified_at, columns.modifier],
    deleted: [columns.deleted_at, columns.deleted_by],
    exports: [
      columns.exported_at,
      columns.export_failed_at,
      columns.exported_by,
    ],
    rejected: [columns.rejected_at, columns.rejected_by],
    confirmed: [columns.confirmed_at, columns.confirmed_by],
  };
};

const excludeDisabledQueueColumns = ({
  statusColumns,
  statusTabKeys,
}: {
  statusTabKeys: StatusTabKeys[];
  statusColumns: MetaColDef[];
}) => {
  return statusColumns.flatMap(column => {
    const columnName = column.field;
    if (!isStatusTabColumn(columnName)) return [column];

    const assignedStatusTabs = statusTabsColumnMap[columnName];
    const shouldIncludeColumn = assignedStatusTabs.every(assignedTab =>
      statusTabKeys.includes(assignedTab)
    );

    return shouldIncludeColumn ? [column] : [];
  });
};

type DynamicTabConfig = {
  isRejectedTabEnabled: boolean;
  isConfirmedTabEnabled: boolean;
  isWorkflowsTabEnabled: boolean;
};

const defaultDynamicTabCongif: DynamicTabConfig = {
  isConfirmedTabEnabled: false,
  isRejectedTabEnabled: false,
  isWorkflowsTabEnabled: false,
};

// if every queue of the organisation has rejected tab disabled, then we do not display rejected tab columns on all docs level.
// we do the same for confirmed and workflows tabs

export const excludeDisabledAllDocsColumns = ({
  queues,
  statusColumns,
}: {
  queues: Queue[] | undefined;
  statusColumns: MetaColDef[];
}) => {
  const { isRejectedTabEnabled, isConfirmedTabEnabled, isWorkflowsTabEnabled } =
    queues?.reduce<DynamicTabConfig>((acc, queue) => {
      if (!acc.isRejectedTabEnabled)
        acc.isRejectedTabEnabled = Boolean(
          queue.settings.rejectionConfig?.enabled
        );

      if (!acc.isConfirmedTabEnabled)
        acc.isConfirmedTabEnabled = Boolean(queue.useConfirmedState);

      if (!acc.isWorkflowsTabEnabled)
        acc.isWorkflowsTabEnabled = Boolean(queue.settings.workflows?.enabled);

      return acc;
    }, defaultDynamicTabCongif) ?? defaultDynamicTabCongif;

  return statusColumns.filter(column => {
    const columnName = column.field;
    if (!isStatusTabColumn(columnName)) return true;

    const columnTabs = statusTabsColumnMap[columnName];
    if (columnTabs.some(tabName => tabName === 'rejected'))
      return isRejectedTabEnabled;

    if (columnTabs.some(tabName => tabName === 'confirmed'))
      return isConfirmedTabEnabled;

    if (columnTabs.some(tabName => tabName === 'in_workflow'))
      return isWorkflowsTabEnabled;

    return true;
  });
};

/*
  useStatusColumns returns the columns we will add when the user changes tabs on queue level.
  For example, after clicking on exported tab, we will, by default, show the user the following columns: exported by, exported at.
  The columns will only be shown if the user hadn't hid them using the column panel.
  The visibility information of columns will be in queue.settings.
*/

export const useStatusColumns = ({
  existingFilter,
  activeQueue,
  queues,
}: {
  existingFilter: GridFilterModel | null;
  activeQueue: Queue | null;
  queues: Queue[] | undefined;
}): MetaColDef[] => {
  const intl = useIntl();
  const statusColumns = createStatusColumns(intl, !!activeQueue);

  const statusTabs = useStatusTabs(activeQueue);

  if (!activeQueue)
    return excludeDisabledAllDocsColumns({
      statusColumns: statusColumns.all_statuses,
      queues,
    });

  const matchingStatusFilter = getMatchingStatusFilter({
    existingFilter,
    statusTabs,
  });

  const filteredStatusColumns = excludeDisabledQueueColumns({
    statusColumns: statusColumns[matchingStatusFilter.key],
    statusTabKeys: statusTabs.map(tab => tab.key),
  });

  return filteredStatusColumns;
};
