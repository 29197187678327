import { groupBy } from 'remeda';
import { DatapointColumn, RowModel } from '../rows/helpers';

export const resolveMessagesByPriority = (
  message: NonNullable<RowModel[DatapointColumn]>['messages'][number]
) => {
  const messagesByType = groupBy(message, m => m.type);

  return messagesByType.error || messagesByType.warning || messagesByType.info;
};

export const resolveMessagesPerDatapoints = ({
  messages,
  datapoints,
}: NonNullable<RowModel[DatapointColumn]>) => {
  const messagesSortedByDatapoints = datapoints.flatMap(
    ({ id }) => messages[String(id)] ?? []
  );

  return resolveMessagesByPriority(messagesSortedByDatapoints);
};

export const resolveAutomationBlockersPerDatapoints = ({
  datapoints,
  automationBlockers,
}: NonNullable<RowModel[DatapointColumn]>) => {
  const blockersSortedByDatapoints = datapoints.flatMap(
    ({ id }) => automationBlockers[String(id)] ?? []
  );

  return blockersSortedByDatapoints;
};
