import { PersonRemove } from '@rossum/ui/icons';
import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import { LocalizationKeys } from '../../../../i18n';
import { boldText } from '../../../../lib/formaterValues';
import { reportProblem } from '../../../../redux/modules/report/actions';
import { OrganizationUser, User } from '../../../../types/user';
import { getUserName } from '../../../Extensions/components/TokenOwnerSelect';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
  user: OrganizationUser | User;
} & (
  | { canRemoveUser: true }
  | { canRemoveUser: false; reason: 'lastAdmin' | 'orgGroupAdmin' }
);

const DeleteUserDialogWithoutCheckboxes = ({
  onDelete,
  onCancel,
  user,
  canRemoveUser,
  ...props
}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const handleContactSupportClick = () => {
    dispatch(reportProblem());
    onCancel();
  };

  const dialogText = (): LocalizationKeys => {
    if ('reason' in props) {
      return `components.modal.deleteUser.${props.reason}.text`;
    }
    return 'components.modal.deleteUser.text';
  };

  const buttonProps = canRemoveUser
    ? ({
        onClick: onDelete,
        color: 'error',
        confirmButtonText: 'components.modal.deleteUser.button.delete',
        hideCancelButton: false,
      } as const)
    : ({
        onClick: () => handleContactSupportClick(),
        color: 'primary',
        confirmButtonText: 'components.modal.deleteUser.button.contactSupport',
        hideCancelButton: true,
      } as const);

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'components.modal.deleteUser.title',
      })}
      onCancel={() => onCancel()}
      dialogColor="error"
      DialogTitleIcon={PersonRemove}
      dialogActions={
        <ActionDialogActions
          confirmButtonProps={{
            onClick: buttonProps.onClick,
            color: buttonProps.color,
          }}
          confirmButtonText={intl.formatMessage({
            id: buttonProps.confirmButtonText,
          })}
          dataCyConfirm="delete-account-confirm-btn-footer"
          dataCyCancel="delete-account-cancel-btn-footer"
          hideCancelButton={buttonProps.hideCancelButton}
        />
      }
      dataCy="delete-account-cancel-btn"
    >
      <Typography variant="body1">
        {intl.formatMessage(
          {
            id: dialogText(),
          },
          {
            boldText,
            userName: getUserName(user),
          }
        )}
      </Typography>
    </ActionDialog>
  );
};

export default DeleteUserDialogWithoutCheckboxes;
