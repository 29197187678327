import { endpoints } from '@rossum/api-client';
import { Organization } from '@rossum/api-client/organization';
import { ListResponse } from '@rossum/api-client/utils';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_CURRENT_ORGANIZATION = 'current-organization';

const getFirstResult = (data: ListResponse<Organization>) => data.results[0];

export const useCurrentOrganization = () => {
  return useQuery({
    queryKey: [QUERY_KEY_CURRENT_ORGANIZATION] as const,
    queryFn: () => api.request(endpoints.organization.list({})),
    select: getFirstResult,
  });
};
