import { Components, Theme } from '../../material';

export const muiAutocomplete = (
  _theme: Theme
): Components['MuiAutocomplete'] => ({
  defaultProps: {
    ChipProps: {
      size: 'small',
    },
    slotProps: {
      paper: {
        elevation: 6,
      },
    },
  },
});
