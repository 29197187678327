import { Tooltip } from '@rossum/ui/material';
import clsx from 'clsx';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import FooterCell from '../../../../../components/footerCell';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import { isFieldRequired } from '../helpers';
import styles from './style.module.sass';

type HeaderInJsonSchemaEditorProps = {
  columns: Array<OriginalAnyDatapointSchema>;
  style: CSSProperties;
};

export const HeaderInJsonSchemaEditor = ({
  columns,
  style,
}: HeaderInJsonSchemaEditorProps) => {
  const intl = useIntl();
  return (
    <div style={style} className={styles.HeaderRow}>
      <div className={styles.HeaderWrapper} style={style}>
        {columns.map(
          ({ hidden, constraints, width, type = '', label, stretch, id }) => (
            <FooterCell
              key={id}
              width={width}
              stretch={!!stretch}
              label={label}
              type={type}
              uiFieldType={null}
            >
              {type === 'button' || (
                <div
                  className={clsx(styles.HeaderItem)}
                  style={{
                    flexDirection: type === 'number' ? 'row-reverse' : 'row',
                  }}
                >
                  <div>{label}</div>
                  {isFieldRequired(constraints, type) && (
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'containers.settings.queues.schema.sidebar.required',
                      })}
                    >
                      <span className={styles.Requirer}>&nbsp;*</span>
                    </Tooltip>
                  )}
                  {hidden && <EyeOffIcon className={styles.HiddenIcon} />}
                </div>
              )}
            </FooterCell>
          )
        )}
        <div className={styles.CollapseButtonPlaceholder} />
      </div>
    </div>
  );
};
