import { zip } from 'lodash';
import { clamp } from 'remeda';
import { usePageSpaceContext } from '../../../../components/DocumentPage/PageSpaceContext';
import {
  Point2D,
  Point2DCoordinates,
  pointFromTuple,
  Rectangle2DCoordinates,
} from '../utils/geometry';
import { ResizingDirection } from '../utils/resizing';

type UseResizeClampsOptions = {
  // if the rectangle can invert (cross edges)
  minSize?: number;
};
export const useResizeClamps = (
  originalRectangle: Rectangle2DCoordinates,
  options?: UseResizeClampsOptions
): ((direction: ResizingDirection) => (diff: Point2D) => Point2D) => {
  const { pageWidth, pageHeight } = usePageSpaceContext();

  const { minSize = 0 } = options ?? {};

  const topClamp = (dy: number): number =>
    clamp(Math.round(dy), {
      min: -originalRectangle[1],
      max: !(minSize > 0)
        ? pageHeight - originalRectangle[1]
        : originalRectangle[3] - originalRectangle[1] - minSize,
    });

  const rightClamp = (dx: number): number =>
    clamp(Math.round(dx), {
      min: !(minSize > 0)
        ? -originalRectangle[2]
        : -(originalRectangle[2] - originalRectangle[0] - minSize),
      max: pageWidth - originalRectangle[2],
    });

  const bottomClamp = (dy: number): number =>
    clamp(Math.round(dy), {
      min: !(minSize > 0)
        ? -originalRectangle[3]
        : -(originalRectangle[3] - originalRectangle[1] - minSize),
      max: pageHeight - originalRectangle[3],
    });

  const leftClamp = (dx: number): number =>
    clamp(Math.round(dx), {
      min: -originalRectangle[0],
      max: !(minSize > 0)
        ? pageWidth - originalRectangle[0]
        : originalRectangle[2] - originalRectangle[0] - minSize,
    });

  const noDeltaClamp = (_d: number) => 0;

  const clampMap: Record<
    ResizingDirection,
    [(x: number) => number, (y: number) => number]
  > = {
    top: [noDeltaClamp, topClamp],
    'top-right': [rightClamp, topClamp],
    right: [rightClamp, noDeltaClamp],
    'bottom-right': [rightClamp, bottomClamp],
    bottom: [noDeltaClamp, bottomClamp],
    'bottom-left': [leftClamp, bottomClamp],
    left: [leftClamp, noDeltaClamp],
    'top-left': [leftClamp, topClamp],
  };

  return (direction: ResizingDirection) => (diff: Point2D) =>
    pointFromTuple(
      // IBB: Why does TS think `f` or `x` could be undefined here?
      zip(clampMap[direction], [diff.x, diff.y]).map(([f, x]) =>
        f!(x!)
      ) as Point2DCoordinates
    );
};
