import { Skeleton, Stack } from '@rossum/ui/material';

export const SkeletonLoaders = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }} spacing={0.5}>
      {Array.from({ length: 5 }, (_, index) => (
        <Stack
          key={index}
          flexDirection="row"
          gap={6}
          sx={{
            padding: '13px',
            backgroundColor: t => t.palette.background.paper,
          }}
        >
          {Array.from({ length: 5 }, (_inner, nestedIndex) => (
            <Skeleton key={nestedIndex} width="20%" />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
