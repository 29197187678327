import { createReactComponent } from '@tabler/icons-react';

export const IconCustomLayoutListCheck = createReactComponent(
  'outline',
  'customLayoutListCheck',
  'CustomLayoutListCheck',
  [
    [
      'path',
      {
        key: 'customLayoutListCheck-path',
        d: 'M18 14H6C5.46957 14 4.96086 14.2108 4.58579 14.5859C4.21071 14.961 4 15.4696 4 16V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H10.8M15 19L17 21L21 17M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V8C20 8.53043 19.7893 9.03914 19.4142 9.41421C19.0391 9.78929 18.5304 10 18 10H6C5.46957 10 4.96086 9.78929 4.58579 9.41421C4.21071 9.03914 4 8.53043 4 8V6Z',
      },
    ],
  ]
);
