import { createIntl, createIntlCache } from 'react-intl';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { messagesCache } from '../../containers/ConnectedIntlProvider';
import { notify } from '../../features/toaster';
import { defaultLocales, IntlLocale, intlLocales } from '../../i18n';
import { Next } from '../../types/redux';
import { State } from '../../types/state';
import { MessageId, throwError, throwInfo } from '../modules/messages/actions';
import { RootActionType } from '../rootActions';

const cache = createIntlCache();

const initIntl = (locale: IntlLocale) => {
  return createIntl(
    { locale, messages: messagesCache[locale] ?? defaultLocales },
    cache
  );
};

const isIntlLocale = (value: string | undefined): value is IntlLocale =>
  value ? value in intlLocales : false;

const hasKeyInTranslations = <K extends string>(
  id: string,
  trailingKey: K
): id is MessageId<K> => {
  return `containers.message.${id}.${trailingKey}` in defaultLocales;
};

export default (store: MiddlewareAPI<Dispatch<AnyAction>, State>) =>
  (next: Next) =>
  (action: RootActionType) => {
    if (isActionOf([throwInfo, throwError])(action)) {
      const state = store.getState();

      const intlLocale = isIntlLocale(state.user.uiSettings.locale)
        ? state.user.uiSettings.locale
        : 'en';

      const intl = initIntl(intlLocale);

      const {
        id,
        values,
        options: { dismissible, timeOut, buttonFunction },
      } = action.payload;

      const message = intl.formatMessage(
        {
          id: `containers.message.${id}.title`,
        },
        values
      );

      const description = hasKeyInTranslations(id, 'text')
        ? intl.formatMessage({ id: `containers.message.${id}.text` }, values)
        : undefined;

      notify[action.meta.type](
        {
          title: message,
          description,
          isDismissible: dismissible,
          // the only buttonFunction instance I found is for refreshing
          toastAction: buttonFunction
            ? { key: 'refresh', props: { onClick: buttonFunction, intl } }
            : undefined,
        },
        // previous implementation assumes Infinity when timeout is zero
        timeOut ? { duration: timeOut } : {}
      );
    }
    return next(action);
  };
