import { useTheme } from '@rossum/ui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import ReactJoyride, {
  CallBackProps,
  EVENTS,
  Props as JoyrideProps,
} from 'react-joyride';
import { useProductTour } from '../../../redux/modules/productTour/useProductTour';
import { ProductTourName } from '../../../types/productTour';
import { TourTooltipDialog } from '../TourTooltipDialog';

type TourDialogProps = {
  tourName: ProductTourName;
  disableOverlay?: boolean;
} & JoyrideProps;

const TourDialog = ({
  tourName,
  steps,
  disableOverlay,
  ...props
}: TourDialogProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const {
    isRunning,
    currentStepIndex,
    setNextStepOfProductTour,
    startProductTour,
    finishProductTour,
    skipProductTour,
  } = useProductTour(tourName, steps);

  useEffect(() => {
    startProductTour();
  }, [startProductTour]);

  const handleInnerStateChange = (data: CallBackProps) => {
    const { type } = data;

    if (type === EVENTS.TARGET_NOT_FOUND) {
      setNextStepOfProductTour();
    }
  };

  return (
    <ReactJoyride
      continuous
      disableScrolling
      disableOverlayClose
      disableCloseOnEsc
      steps={steps}
      stepIndex={currentStepIndex ?? undefined}
      run={isRunning}
      callback={handleInnerStateChange}
      spotlightPadding={0}
      disableOverlay={disableOverlay}
      tooltipComponent={props => (
        <TourTooltipDialog
          {...props}
          onSkip={skipProductTour}
          onFinish={finishProductTour}
          onNext={setNextStepOfProductTour}
        />
      )}
      locale={{
        next: intl.formatMessage({
          id: `components.tourDialog.nextButtonLabel`,
        }),
        close: intl.formatMessage({
          id: `components.tourDialog.closeButtonLabel`,
        }),
        last: intl.formatMessage({
          id: `components.tourDialog.lastButtonLabel`,
        }),
        skip: intl.formatMessage({
          id: `components.tourDialog.skipButtonLabel`,
        }),
      }}
      styles={{
        options: {
          zIndex: 12000,
          arrowColor:
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : '#1a1620',
          width: '100%',
        },
        spotlight: {
          borderRadius: 0,
        },
      }}
      {...props}
    />
  );
};

export default TourDialog;
