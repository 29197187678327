import { useIntl } from 'react-intl';
import { Breadcrumb } from '../../../components/BreadcrumbsV2/Breadcrumbs';
import { SettingsBreadcrumbs } from '../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { datasetsPath } from '../routes';

type DatasetsHeaderProps = Omit<HeaderProps, 'breadcrumbs'> & {
  breadcrumbs: Breadcrumb[];
};

export const DatasetsHeader = ({
  breadcrumbs,
  ...props
}: DatasetsHeaderProps) => {
  const intl = useIntl();

  const datasetBreadcrumbs: Breadcrumb[] = [
    {
      label: intl.formatMessage({
        id: 'features.datasets.list.header.title',
      }),
      to: datasetsPath(),
    },
    ...breadcrumbs,
  ];

  return (
    <Header
      {...props}
      breadcrumbs={<SettingsBreadcrumbs breadcrumbs={datasetBreadcrumbs} />}
    />
  );
};
