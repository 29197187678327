import { SchemaAction } from '@rossum/api-client/schemas';
import { getIDFromUrl } from '@rossum/api-client/utils';
import { LabelOffOutlined, LabelOutlined } from '@rossum/ui/icons';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { useRequestUnpaginatedLabels } from '../../labels/hooks/useRequestLabels';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const labelActionPayloadSchema = z.object({
  label_id: z.number().nullable().default(null),
});

const LabelActionForm = createForm(({ value, onChange }) => {
  const intl = useIntl();
  const { isSuccess, data } = useRequestUnpaginatedLabels();

  const stableValue = useMemo(
    () => data?.find(label => value.label_id === label.id) ?? null,
    [data, value]
  );

  return isSuccess ? (
    <Autocomplete
      size="small"
      options={data}
      getOptionLabel={option => `${option.name}`}
      value={stableValue}
      onChange={(_e, v) => {
        onChange({ ...value, label_id: v ? getIDFromUrl(v.url) : null });
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={intl.formatMessage({
            id: 'features.queueSettings.rules.actions.label.form.label',
          })}
          placeholder={intl.formatMessage({
            id: 'features.queueSettings.rules.actions.label.form.labelPlaceholder',
          })}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  ) : null;
}, labelActionPayloadSchema);

const LabelActionHeader = createHeader(({ value }) => {
  const { data } = useRequestUnpaginatedLabels();

  const stableValue = useMemo(
    () => data?.find(label => value.label_id === label.id) ?? null,
    [data, value]
  );

  return (
    <ActionHeader
      entries={[{ key: 'label', value: `${stableValue?.name ?? ''}` }]}
    />
  );
}, labelActionPayloadSchema);

export const addLabelAction = {
  icon: LabelOutlined,
  intlKey: 'addLabel' as const,
  resolveAction: (a: SchemaAction) => a.type === 'add_label',
  serialized: { type: 'add_label' },
  groupIntlKey: 'labels' as const,
  form: LabelActionForm,
  header: LabelActionHeader,
};

export const removeLabelAction = {
  icon: LabelOffOutlined,
  intlKey: 'removeLabel' as const,
  resolveAction: (a: SchemaAction) => a.type === 'remove_label',
  serialized: { type: 'remove_label' },
  groupIntlKey: 'labels' as const,
  form: LabelActionForm,
  header: LabelActionHeader,
};

export const labelActions = [addLabelAction, removeLabelAction];
